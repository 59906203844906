import React, { useEffect, useRef, useState } from "react";
import "./SellGetCard.scss";
import reverseIcon from "../../../../assets/images/big_down_arrow.svg";
import moment from "moment";
import Select from "react-select";
import { Button, Form, Modal } from "react-bootstrap";
import {
  ChainIdp2p,
  EHX_DESIMAL,
  EXPIRES_OPTIONS,
  P2P_CONTRACT_ADDRESS,
  USDT_DESIMAL,
  convert,
} from "../../../../constants/constants";
import CustomTooltip from "../../../../common/Tooltips/CustomTooltip";
import {
  useTotalP2PBalanceFromContract,
  useTotalP2PUSDTBalanceFromContract,
} from "../../../../hooks/hooks.service.js";
import Web3 from "web3";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import ConnectWalletComp from "../../../../common/Header/connectWallet";
import P2PABI from "../../../../ABI/p2pTestnet/P2P.abi.json";
import { socket_p2p } from "../../../../App";
import { TOKEN_INFO } from "../../../../constants/wallet";
import { toast } from "react-toastify";

const options = [
  { value: "ehx", label: "EHX" },
  { value: "usdt", label: "USDT" },
];

const SellGetCard = (props, { className }) => {
  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const rightNavRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [secondselectedOption, setSecondSelectedOption] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isFirstSelect, setFirstSelect] = useState(Boolean);
  const [isOptionSelectedFirst, setIsOptionSelectedFrist] = useState(Boolean);
  const [selectedOptionExp, setSelectedOptionExp] = useState("");
  const [buyTokenAmount, setBuyTokenAmount] = useState("");
  const [sellTokenAmount, setSellTokenAmount] = useState("");
  const [amountBalanceError, setAmounBalanceError] = useState("");
  const [saltValue, setSaltValue] = useState("");
  const [isLoader, setIsloader] = useState(false);
  const [showmodal, sethowModal] = useState(false);

  useEffect(() => {
    saveSocketId();
  }, [walletAddress]);

  /************** GETTING FIRST SELECTED COIN BALANCE FROM WALLET ***************/
  const { totalP2PBalance } = useTotalP2PBalanceFromContract(
    "P2P",
    selectedOption.label
  );
  let newBalance =
    selectedOption.label === "USDT"
      ? totalP2PBalance / USDT_DESIMAL
      : totalP2PBalance / EHX_DESIMAL;
  /*************************** END ********************************/

  /************** GETTING SECOND SELECTED COIN BALANCE FROM WALLET ***************/
  const { totalP2PBalanceUSDT } = useTotalP2PUSDTBalanceFromContract(
    "P2P",
    secondselectedOption.label
  );

  let newBalanceUsdt =
    secondselectedOption.label === "USDT"
      ? totalP2PBalanceUSDT / USDT_DESIMAL
      : totalP2PBalanceUSDT / EHX_DESIMAL;
  /*************************** END ********************************/

  /************** FIRST SELECT OPTIONS ***************/
  const handleChange = (selectData) => {
    if (selectData.value === secondselectedOption.value) {
      setSelectedOption(selectedOption);
      setFirstSelect(true);
      setErrorMessage("*Your not allow to select same token");
    } else {
      setErrorMessage("");
      setSelectedOption(selectData);
    }
  };
  /*************************** END ********************************/

  /************** SECOND SELECT OPTIONS ***************/
  const handleChange2 = (selectData) => {
    if (selectData.value === selectedOption.value) {
      setSecondSelectedOption(secondselectedOption);
      setFirstSelect(false);
      setErrorMessage("*Your not allow to select same token");
    } else {
      setErrorMessage("");
      setSecondSelectedOption(selectData);
    }
  };
  /*************************** END ********************************/

  /************** FUNCTION FOR SWAPPING THE SELECTED COINS ***************/
  const functionChangeToken = (e) => {
    e.preventDefault();
    setIsOptionSelectedFrist(false);
    setErrorMessage("");
    setSelectedOption(secondselectedOption);
    setSecondSelectedOption(selectedOption);
    setSellTokenAmount("");
    setBuyTokenAmount("");
    setAmounBalanceError("");
  };
  /*************************** END ********************************/

  /************** FUNCTION TO GENERATE RANDOM NUMBER FOR SALT ***************/
  const generateRandomNumber = () => {
    let generateSaltValue = Math.ceil(Math.random() * 100000000);
    setSaltValue(generateSaltValue);
  };
  /*************************** END ********************************/

  /************** API CALL SUBMIT FUNCTION ***************/
  const handelSubmited = async (e) => {
    e.preventDefault();

    let time = moment.utc().unix();
    let deadLine =
      selectedOptionExp.type === "minutes"
        ? time + 60 * Number(selectedOptionExp.value)
        : selectedOptionExp.type === "hours"
          ? time + 60 * 60 * Number(selectedOptionExp.value)
          : selectedOptionExp.type === "days"
            ? time + 60 * 60 * 24 * Number(selectedOptionExp.value)
            : "";

    try {
      const { ethereum } = window;
      // console.log("isMetamask", ethereum.isMetaMask);
      const web3 = new Web3(ethereum);
      const walletAddress = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setIsloader(true);

      const msgParams = JSON.stringify({
        domain: {
          name: "Eterna",
          version: "1",
          chainId: web3.utils.toHex(ChainIdp2p),
          verifyingContract: P2P_CONTRACT_ADDRESS,
        },
        message: {
          SenderAddress: walletAddress[0],
          SendingToken:
            selectedOption.label.toUpperCase() === "EHX"
              ? TOKEN_INFO[1].token_address
              : TOKEN_INFO[0].token_address,
          ReceivingToken:
            secondselectedOption.label.toUpperCase() === "USDT"
              ? TOKEN_INFO[0].token_address
              : TOKEN_INFO[1].token_address,
          SendingAmount:
            selectedOption.label.toUpperCase() === "EHX"
              ? convert(sellTokenAmount * 10 ** 18).toString()
              : convert(sellTokenAmount * 10 ** 6).toString(),
          ReceivingAmount:
            secondselectedOption.label.toUpperCase() === "USDT"
              ? convert(buyTokenAmount * 10 ** 6).toString()
              : convert(buyTokenAmount * 10 ** 18).toString(),
          Deadtime: deadLine,
          salt: saltValue,
        },
        primaryType: "P2pOrder",
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],
          P2pOrder: [
            { name: "SenderAddress", type: "address" },
            { name: "SendingToken", type: "address" },
            { name: "ReceivingToken", type: "address" },
            { name: "SendingAmount", type: "uint256" },
            { name: "ReceivingAmount", type: "uint256" },
            { name: "Deadtime", type: "uint256" },
            { name: "salt", type: "uint256" },
          ],
        },
      });
      const params = [walletAddress[0], msgParams];
      const method = "eth_signTypedData_v4";
      const from = walletAddress[0];
      // setIsloader(false);

      let signedMessage = await new Promise((resolve, reject) => {
        web3.currentProvider.sendAsync(
          {
            method,
            params,
            from,
          },

          async function (err, result) {
            if (err) {
              console.dir("ERROR---1", err);
              toast.error(err.message);
              setIsloader(false);
            }
            if (err) {
              return console.dir(err);
            }
            if (result.error) {
              reject(result.error);
              alert("error------", result.error.message);
            }
            if (result.error) {
              return console.error("ERROR------2", result);
            }
            if (result.result) {
              resolve(result.result);
            }
          }
        );
      });

      const data = {
        sell_token_name: selectedOption.label,
        buy_token_name: secondselectedOption.label,
        buy_amount: buyTokenAmount,
        sell_amount: sellTokenAmount,
        buyer_address: props.buyerAddres,
        seller_address: walletAddress[0],
        utc_time: deadLine,
        expiry_time: selectedOptionExp.value,
        expiry_type: selectedOptionExp.type,
        signature: signedMessage,
        salt_value: saltValue,
      };
      // calling aprove function
      ApproveTokeSeller(data);
      return signedMessage;
    } catch (error) {
      // setIsloader(false);
      // toast.error(error.message)
      // console.error('invalid ethereum address', error.message)

      throw error;
    }
  };
  /*************************** END ********************************/

  /************** APPROVE FUNCTION ***************/
  const ApproveTokeSeller = async (data) => {
    try {
      const { ethereum } = window;
      const web3 = new Web3(ethereum);
      const fromAddress = await ethereum.request({
        method: "eth_requestAccounts",
      });
      let seller = fromAddress[0];
      const instanceWeb3 = new Web3(window.ethereum);
      const contract =
        data.sell_token_name === "EHX"
          ? new instanceWeb3.eth.Contract(
            TOKEN_INFO[1].token_abi,
            TOKEN_INFO[1].token_address
          )
          : new instanceWeb3.eth.Contract(
            TOKEN_INFO[0].token_abi,
            TOKEN_INFO[0].token_address
          );

      // console.log("contract", contract);
      const allowance = await contract.methods
        .allowance(seller, P2P_CONTRACT_ADDRESS)
        .call({ from: seller });
      // checking the allowance of USDT if it was set to any number, we are going to set fristly to zero then again we will add our desier allowance
      if (data.sell_token_name === "USDT") {
        if (Number(allowance) > 0) {
          const instanceWeb3 = new Web3(ethereum);
          const contract = new instanceWeb3.eth.Contract(
            TOKEN_INFO[0].token_abi,
            TOKEN_INFO[0].token_address
          );
          await contract.methods
            .approve(P2P_CONTRACT_ADDRESS, 0)
            .send({ from: seller });
        }
      }

      // Here we are checking the toke and sending then as string to avoid the bigNumber issue
      let newAmountApproved =
        data.sell_token_name === "EHX"
          ? convert(data.sell_amount * 10 ** 18).toString()
          : data.sell_token_name === "USDT" &&
          convert(data.sell_amount * 10 ** 6).toString();

      const estimatedGas1 = await contract.methods
        .approve(P2P_CONTRACT_ADDRESS, newAmountApproved)
        .estimateGas({ from: seller });
      // setIsloader(false);
      const res = await contract.methods
        .approve(P2P_CONTRACT_ADDRESS, newAmountApproved)
        .send({ from: seller, gas: estimatedGas1 });

      // setIsloader(true);
      // We are calling Lock function to Lock the balance of user before creating order
      LockTheBalance(data);
      return res;
    } catch (error) {
      // console.log('ERROR--------3')
      toast.error(error.message);
      setIsloader(false);
      throw error;
    }
  };
  /*************************** END ********************************/

  /************** LOCK THE BALANCE IN SMART CONTRACT ***************/

  const LockTheBalance = async (data) => {
    try {
      // setIsloader(true);
      const { ethereum } = window;
      const fromAddress = await ethereum.request({
        method: "eth_requestAccounts",
      });
      let seller = fromAddress[0];
      const instanceWeb3 = new Web3(window.ethereum);
      const contract = new instanceWeb3.eth.Contract(
        P2PABI,
        P2P_CONTRACT_ADDRESS
      );
      // Here we are checking the format and stracture of Voucher that are muching our voucher
      const estimatedGas1 = await contract.methods
        .lock([
          seller,
          data.sell_token_name === "EHX"
            ? TOKEN_INFO[1].token_address
            : TOKEN_INFO[0].token_address,
          data.buy_token_name === "USDT"
            ? TOKEN_INFO[0].token_address
            : TOKEN_INFO[1].token_address,
          data.sell_token_name === "EHX"
            ? convert(data.sell_amount * 10 ** 18).toString()
            : convert(data.sell_amount * 10 ** 6).toString(),
          data.buy_token_name === "USDT"
            ? convert(data.buy_amount * 10 ** 6).toString()
            : convert(data.buy_amount * 10 ** 18).toString(),
          data.utc_time,
          data.salt_value,
          data.signature,
        ])
        .estimateGas({ from: seller });

      // setIsloader(false);

      const res = await contract.methods
        .lock([
          data.seller_address,
          data.sell_token_name === "EHX"
            ? TOKEN_INFO[1].token_address
            : TOKEN_INFO[0].token_address,
          data.buy_token_name === "USDT"
            ? TOKEN_INFO[0].token_address
            : TOKEN_INFO[1].token_address,
          data.sell_token_name === "EHX"
            ? convert(data.sell_amount * 10 ** 18).toString()
            : convert(data.sell_amount * 10 ** 6).toString(),
          data.buy_token_name === "USDT"
            ? convert(data.buy_amount * 10 ** 6).toString()
            : convert(data.buy_amount * 10 ** 18).toString(),
          data.utc_time,
          data.salt_value,
          data.signature,
        ])
        .send({ from: seller, gas: estimatedGas1 });
      // calling API at the end after successfully created order on metamask.
      createOrderAPI(data);
      return res;
    } catch (error) {
      toast.error(error.message);
      setIsloader(false);
      throw error;
    }
  };
  /*************************** END ********************************/

  /************** API FUNCTION OF CREATE ORDER ***************/
  const createOrderAPI = (data) => {
    handleShowmodal();
    props.APICreateOrder(data);
    setIsloader(false);
  };
  /*************************** END ********************************/

  /************** CHECKING FOR NETWORK CHANGE ***************/
  useEffect(() => {
    window?.ethereum?.on("networkChanged", (networkId) => {
      localStorage.setItem("chainIds", JSON.stringify(networkId));
    });
  });
  /*************************** END ********************************/

  /************** CALLING SOCKET TO SAVE SOCKET ID  ***************/
  const saveSocketId = async () => {
    socket_p2p.emit("saveSocketId", {
      address: walletAddress,
    });
  };
  /*************************** END ********************************/

  /************** POPUP AFTER ORDER CREATED SUCCESSFULLY  ***************/
  const handleClosemodal = () => sethowModal(false);
  const handleShowmodal = () => {
    sethowModal(true);
    setTimeout(() => {
      sethowModal(false);
    }, 5000);
  };
  /*************************** END ********************************/

  return (
    <>
      {isLoader === true && <Loader className="inner" />}
      <div className={`SetOrder_Card ${className}`}>
        <h2 className="Order_Title">Set P2P order</h2>
        <Form onSubmit={(e) => handelSubmited(e)}>
          <div className="Reverse_Token_card">
            <div className="Commom_card_Box">
              <div className="Card_Lebel d-flex align-items-center justify-content-between">
                <h5>You Sell</h5>
                <h6>
                  {"Available Balance: " + newBalance}
                  <span className="text_blue max_text">
                    {selectedOption.label}
                  </span>
                </h6>
              </div>
              <div className="Card_Token">
                <div className="Select_Token_List common_select">
                  <Select
                    isDisabled={!walletAddress}
                    value={selectedOption}
                    onChange={handleChange}
                    options={options}
                    classNamePrefix="react-select"
                    isSearchable={false}
                  // menuIsOpen={true}
                  />
                </div>
                <div className="Card_Token_Bal">
                  <Form.Control
                    disabled={!walletAddress}
                    type="number"
                    placeholder="Enter amount"
                    name="sell_amount"
                    onChange={(e) => {
                      if (/^\d*(\.\d{0,8})?$/.test(e.target.value)) {
                        setSellTokenAmount(e.target.value);
                        props.handleUsdtDollarPrice(e.target.value);
                        setIsOptionSelectedFrist(true);
                      }
                      if (newBalance <= 0) {
                        setAmounBalanceError("You do not have enough balance");
                      }
                    }}
                    value={sellTokenAmount}
                    required
                  />
                  {/* <span>
                    {" "}
                    {selectedOption
                      ? selectedOption.label === "EHX"
                        ? `$ ${props.ehxDollarConversion * sellTokenAmount}`
                        : `$ ${props.usdtDollarConversion * sellTokenAmount}`
                      : "0.0"}
                  </span> */}
                </div>
                {(isFirstSelect == true &&
                  errorMessage &&
                  errorMessage.length && (
                    <div className="text-danger">{errorMessage}</div>
                  )) ||
                  (isOptionSelectedFirst === true &&
                    newBalance === 0 &&
                    sellTokenAmount.length
                    ? amountBalanceError &&
                    amountBalanceError.length && (
                      <div className="text-danger">{amountBalanceError}</div>
                    )
                    : "")}
              </div>
            </div>
            <div
              className="Reverse_btn"
              disabled={!walletAddress}
              onClick={(e) => functionChangeToken(e)}
            >
              <img src={reverseIcon} alt="" />
            </div>
            <div className="Commom_card_Box">
              <div className="Card_Lebel d-flex align-items-center justify-content-between">
                <h5>You Buy</h5>
                <h6>
                  {"Available Balance: " + newBalanceUsdt}
                  <span className="text_blue max_text">
                    {secondselectedOption.label}
                  </span>
                </h6>
              </div>
              <div className="Card_Token">
                <div className="Select_Token_List common_select">
                  <Select
                    isDisabled={!walletAddress}
                    value={secondselectedOption}
                    onChange={handleChange2}
                    options={options}
                    classNamePrefix="react-select"
                    isSearchable={false}
                  />
                </div>
                <div className="Card_Token_Bal ">
                  <Form.Control
                    disabled={!walletAddress}
                    type="number"
                    placeholder="Enter amount"
                    name="buy_amount"
                    onChange={(e) => {
                      if (/^\d*(\.\d{0,8})?$/.test(e.target.value)) {
                        setBuyTokenAmount(e.target.value);
                      }
                    }}
                    value={buyTokenAmount}
                    required
                  />
                  <span>
                    {" "}
                    {/* {console.log(
                      "USDTpRICE",
                      props.usdtDollarConversion,
                      buyTokenAmount
                    )} */}
                    {/* {secondselectedOption
                      ? secondselectedOption.label === "USDT"
                        ? `$ ${Number(
                          props.usdtDollarConversion * buyTokenAmount
                        )}`
                        : `$ ${Number(
                          props.ehxDollarConversion * buyTokenAmount
                        )}`
                      : "0.0"} */}
                  </span>
                </div>
                {isFirstSelect == false &&
                  errorMessage &&
                  errorMessage.length && (
                    <div className="text-danger">{errorMessage}</div>
                  )}
              </div>
            </div>
          </div>
          <div className="Get_Expires_card">
            <div className="Commom_card_Box">
              <h5>Buyer Address</h5>
              <Form.Control
                disabled={!walletAddress}
                className="custom_input"
                type="text"
                placeholder="0x...."
                onChange={(e) => {
                  props.setBuyerAddres(e.target.value);
                }}
                value={props.buyerAddres}
                required
              />
            </div>
            <div className="Expires_Box">
              <div className="Commom_card_Box">
                <h5>
                  Expires in{" "}
                  <span>
                    <CustomTooltip
                      disabled={!walletAddress}
                      tooltipClass="Expires_tooltip"
                      align="bottom"
                      text="The P2P order will automatically become inactive after the specified time has elapsed"
                    />
                  </span>
                </h5>
                <div className="common_select arrow_options">
                  <Select
                    required
                    placeholder="Select Expire"
                    isDisabled={!walletAddress}
                    defaultValue={EXPIRES_OPTIONS}
                    onChange={setSelectedOptionExp}
                    options={EXPIRES_OPTIONS}
                    classNamePrefix="react-select"
                    value={selectedOptionExp}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p2p_buttons mt-3">
            {/* {console.log("amountBalanceError", amountBalanceError)} */}

            <Button
              type="submit"
              className="w-100 "
              onClick={generateRandomNumber}
              disabled={
                !selectedOption ||
                !secondselectedOption ||
                amountBalanceError ||
                !walletAddress
              }
            >
              Create Order
            </Button>
          </div>
        </Form>
        {/* <div className="p2p_buttons mt-3">
          {!walletAddress && <ConnectWalletComp ref={rightNavRef} />}
        </div> */}
      </div>
      <Modal
        className="Order_modal"
        centered
        show={showmodal}
        onHide={handleClosemodal}
      >
        {/* <Modal.Header></Modal.Header> */}
        <Modal.Body className="text-center">
          <h6>
            Yor order Created, Please contact your counterpart for confirming
            the order.
          </h6>
        </Modal.Body>
      </Modal>
    </>
  );
};
const Loader = ({ className }) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center loader loader--${className}`}
    >
      <h2>Waiting confirmation...</h2>
    </div>
  );
};
export default SellGetCard;
