import React from 'react'
import "./LandingDashboard.scss";
import { Container } from 'react-bootstrap';
import DashboardBanner from './DashboardBanner/DashboardBanner';
import { Header } from '../../../../common';
import Footer from '../../../../common/Footer/Footer';
import DashboardSlider from './DashboardSlider/DashboardSlider';
import LandingAssets from './LandingAssets/LandingAssets';

const LandingDashboard = () => {
  return (
    <>
      <Header />
      <div className='dashboard_Page pt-4'>
        <Container fluid>
          <div className='Common_admin_Contentarea'>
            <DashboardBanner />
            <LandingAssets />
            <DashboardSlider />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  )
}

export default LandingDashboard