import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Tab, Tabs } from "react-bootstrap";
import TradeBook from "../TradeBook/TradeBook";
import OrderBook from "./OrderBook";
import { DEFAULT_PAIR } from "../../../../constants/constants";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { useTranslation } from "react-i18next";
import SocketOrderBooks from "./SocketOrderBooks";
import DefaultOrderBook from "./DefaultOrderBook";
import SocketOrderBtc from "./SocketOrderBtc";
import SocketOrdersRecords from "./SocketOrdersRecords";
import {
  DefaultLogger,
  isWsOrderbookEventV5,
  WebsocketClient,
  WSOrderbookEventV5,
} from "bybit-api";
import {
  OrderBookLevel,
  OrderBookLevelState,
  OrderBooksStore,
} from "orderbooks";
import { useDispatch } from "react-redux";
import {
  saveBuyOrderBook,
  saveDefaultStatistics,
  saveSellOrderBook,
  saveStatistics,
} from "../../redux/_slices/spotExchange.slice";
import { throttle } from "lodash";
import { updateMarketTradesOnTrade } from "../../redux/_actions/exchange.action";

const OrderBooks = new OrderBooksStore({
  traceLog: false,
  checkTimestamps: false,
});

DefaultLogger.silly = () => {};

const ws = new WebsocketClient({
  market: "v5",
  testnet: false,
});

const OrderBookDefault = () => {
  const dispatch = useDispatch();
  const statistics = useAppSelector((state) => state.spotExchange.statistics);
  const selectPair = useAppSelector((state) => state?.user?.selectedPairKey);
  const selectedPairKey = selectPair ? selectPair : DEFAULT_PAIR;
  const prevSelectedPairKey = useAppSelector(
    (state) => state.user.prevSelectedPairKey
  );
  const [changePair, setChangePair] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [orderBookRecord, setOrderBookRecord] = useState(12);
  const [parentTab, setParentTab] = useState("orderbook");
  const [defaultStats, setDefaultStats] = useState(false);
  const buyDepth = useRef();
  const sellDepth = useRef();

  const orderBook = useRef();
  // const [throttledFunction, setThrottledFunction] = useState(() => throttle(setOrderbookData, 0));
  const throttledFunction = throttle(() => {
    setOrderbookData();
  }, 500);

  const handleTradeThrottled = throttle((getDta) => {
    getTradeDataFormatted(getDta);
  }, 500);

  const handleTickerThrottled = throttle((extdata, type) => {
    getTickerDataFormatted(extdata, type);
  }, 500);

  const parentTabRef = useRef(parentTab);
  parentTabRef.current = parentTab;
  const { t } = useTranslation();

  const formattedPair = (pairName) => {
    let pName;
    let pair = pairName.split("_");
    if (pair && pair.length > 0) {
      if (pair.length > 1) {
        return (pName = `${pair[0]}${pair[1]}`);
      } else {
        return (pName = pair[0]);
      }
    }
  };

  useEffect(() => {
    if (statistics && Object.keys(statistics).length > 9 && defaultStats) {
      dispatch(saveDefaultStatistics(statistics));
      setDefaultStats(false);
    }
  }, [defaultStats, statistics]);

  useLayoutEffect(() => {
    connectWebsocket();
    return () => {
      console.log("unmounts");
      unsubscribeTopics(selectedPairKey);
      unsubscribeTopics(prevSelectedPairKey);
    };
  }, [selectedPairKey]);

  useLayoutEffect(() => {
    return () => {
      unsubscribeTopics(selectedPairKey);
      unsubscribeTopics(prevSelectedPairKey);
    };
  }, []);

  const connectWebsocket = () => {
    unsubscribeTopics(selectedPairKey);
    unsubscribeTopics(prevSelectedPairKey);
    const pairName = formattedPair(selectedPairKey);
    ws.on("update", (message) => {
      switch (message?.topic) {
        case `orderbook.50.${pairName}`:
          if (isWsOrderbookEventV5(message)) {
            // console.log('message', JSON.stringify(message, null, 2));
            handleOrderbookUpdate(message);
            return;
          }
          break;
        case `publicTrade.${pairName}`:
          handleTradeThrottled(message?.data);
          break;
        case `tickers.${pairName}`:
          handleTickerThrottled(message?.data, message?.type);
          break;
      }
    });

    ws.on("error", (message) => {
      console.error(`bybit ws error: `, message);
    });

    ws.subscribeV5(["orderbook.50." + pairName], "spot");

    ws.subscribeV5(["publicTrade." + pairName], "spot");

    ws.subscribeV5(["tickers." + pairName], "spot");
  };

  // parse orderbook messages, detect snapshot vs delta, and format properties using OrderBookLevel
  function handleOrderbookUpdate(message) {
    const { topic, type, data, cts } = message;
    const [topicKey, symbol] = topic.split(".");

    const bidsArray = data.b.map(([price, amount]) => {
      return OrderBookLevel(symbol, +price, "Buy", +amount);
    });

    const asksArray = data.a.map(([price, amount]) => {
      return OrderBookLevel(symbol, +price, "Sell", +amount);
    });

    const allBidsAndAsks = [...bidsArray, ...asksArray];

    if (type === "snapshot") {
      // store inititial snapshot
      const storedOrderbook = OrderBooks.handleSnapshot(
        symbol,
        allBidsAndAsks,
        cts
      );

      // log book state to screen
      //storedOrderbook.print();
      return;
    }

    if (type === "delta") {
      const upsertLevels = [];
      const deleteLevels = [];

      // Seperate "deletes" from "updates/inserts"
      allBidsAndAsks.forEach((level) => {
        const [_symbol, _price, _side, qty] = level;

        if (qty === 0) {
          deleteLevels.push(level);
        } else {
          upsertLevels.push(level);
        }
      });

      // Feed delta into orderbook store
      const storedOrderbook = OrderBooks.handleDelta(
        symbol,
        deleteLevels,
        upsertLevels,
        [],
        cts
      );

      orderBook.current = storedOrderbook;
      throttledFunction();
      return;
    }

    console.error("unhandled orderbook update type: ", type);
  }

  const compareTotalDepth = (buyDepth, sellDepth) => {
    if (buyDepth >= sellDepth) {
      return buyDepth;
    } else {
      return sellDepth;
    }
  };

  const setOrderbookData = () => {
    const buyOrders = orderBook.current.book.slice(-50);
    const sellOrders = orderBook.current.book.slice(0, 50);
    buyDepth.current = buyOrders.reduce((a, c) => a + Number(c[3]), 0);
    sellDepth.current = sellOrders.reduce((a, c) => a + Number(c[3]), 0);
    dispatch(saveBuyOrderBook(orderBook.current.book.slice(-50)));
    dispatch(saveSellOrderBook(orderBook.current.book.slice(0, 50)));
  };

  const getTradeDataFormatted = async (dataObj) => {
    try {
      if (dataObj && dataObj.length > 0) {
        const newArr = dataObj.map(({ p, v, S: side, T: created_at }) => ({
          price: p,
          total: p * v,
          filled_amount: v,
          side: side === "Buy" ? 0 : 1,
          created_at,
        }));
        dispatch(updateMarketTradesOnTrade(newArr));
      }
    } catch (error) {
      // Handle the error here
    }
  };

  const getTickerDataFormatted = async (extdata, type = "") => {
    let retdata = {};
    try {
      if (extdata) {
        retdata.symbol = extdata.symbol;
        if (extdata.lastPrice) {
          retdata.lastPrice = extdata.lastPrice;
        }
        if (extdata.tickDirection) {
          retdata.last_tick_direction = extdata.tickDirection;
        }

        if (extdata.markPrice) {
          retdata.mark_price = extdata.markPrice;
        }
        if (extdata.indexPrice) {
          retdata.index_price = extdata.indexPrice;
        }

        if (extdata.highPrice24h) {
          retdata.high_24h = extdata.highPrice24h;
        }

        if (extdata.lowPrice24h) {
          retdata.low_24h = extdata.lowPrice24h;
        }

        if (extdata.volume24h) {
          retdata.volume_24h = extdata.volume24h;
        }

        if (extdata.nextFundingTime) {
          retdata.funding_countdown = extdata.nextFundingTime;
        }

        if (extdata.fundingRate) {
          retdata.funding_rate = extdata.fundingRate;
          retdata.predicted_funding_rate = extdata.fundingRate;
        }

        if (extdata.price24hPcnt) {
          retdata.change_24_h = extdata.price24hPcnt;
        }

        if (extdata.turnover24h) {
          retdata.turnover = extdata.turnover24h;
        }
        if (retdata && Object.keys(retdata).length > 0) {
          dispatch(saveStatistics({ ...statistics, ...retdata }));
          setDefaultStats(true);
        }
      }
    } catch (error) {
      // console.log(
      //   "=================== websocket socket catch error instrument data 2222222222222222222222222 ============",
      //   error
      // );
    }
  };
  useLayoutEffect(() => {
    setChangePair(true);
    setTimeout(() => {
      setChangePair(false);
    }, 500);
  }, [selectedPairKey, activeTab]);

  useLayoutEffect(() => {
    return () => {
      unsubscribeTopics(selectedPairKey);
      unsubscribeTopics(prevSelectedPairKey);
    };
  }, [selectedPairKey]);

  const unsubscribeTopics = (pair) => {
    const pairName = formattedPair(pair);
    ws.unsubscribeV5(["orderbook.50." + pairName], "spot");
    ws.unsubscribeV5(["publicTrade." + pairName], "spot");
    ws.unsubscribeV5(["tickers." + pairName], "spot");
  };

  const handleOnSelect = (tab) => {
    setActiveTab(tab);
    if (tab === "all") {
      setOrderBookRecord(12);
    } else {
      setOrderBookRecord(20);
    }
  };

  const handleOnTab = (tab) => {
    setParentTab(tab);
  };

  return (
    <div className="orderBook_index_V2 nondraggable_card">
      <Tabs
        onSelect={handleOnTab}
        defaultActiveKey="orderbook"
        id="uncontrolled-tab-example"
        className="Bottom_Border_Tabs"
        unmountOnExit={true}
        mountOnEnter={true}
        transition={false}
      >
        <Tab eventKey="orderbook" title={t("orderBook.orderBook_text")}>
          {/* {changePair ? ( */}
          <DefaultOrderBook
            orderBookRecord={orderBookRecord}
            setOrderBooKRecod={setOrderBookRecord}
            parentTab={parentTabRef.current}
            activeTab={activeTab}
            handleOnSelect={handleOnSelect}
            totalDepth={compareTotalDepth(buyDepth.current, sellDepth.current)}
          />
          {/* ) : orderBookRecord === 12 ? (
            <SocketOrderBtc
              selectedPairKey={selectedPairKey}
              orderBookRecord={orderBookRecord}
              setOrderBooKRecod={setOrderBookRecord}
              parentTab={parentTabRef.current}
              activeTab={activeTab}
              handleOnSelect={handleOnSelect}
            />
          ) : (
            <SocketOrdersRecords
              orderBookRecord={orderBookRecord}
              setOrderBooKRecod={setOrderBookRecord}
              parentTab={parentTabRef.current}
              activeTab={activeTab}
              handleOnSelect={handleOnSelect}
              selectedPairKey={selectedPairKey}
            />
          )} */}
        </Tab>
        <Tab eventKey="marketTrades" title="Trade History">
          <TradeBook />
        </Tab>
      </Tabs>
    </div>
  );
};

export default memo(OrderBookDefault);
