import React, { useState } from 'react'
import "./DepositCard.scss"
import TokenIcon from "../../../../../assets/images/tether-usdt.svg";
import TokenIcon1 from "../../../../../assets/images/btc.svg";
import TokenIcon2 from "../../../../../assets/images/ALGO.svg";
import TokenIcon3 from "../../../../../assets/images/ADA.svg";
import TokenIcon4 from "../../../../../assets/images/AVAX.svg";
import TokenIcon5 from "../../../../../assets/images/XRP.svg";
import TokenIcon6 from "../../../../../assets/images/btc.svg";
// import Select, { components } from "react-select";
import { Col, Dropdown, Row } from 'react-bootstrap';
import fileIcon from "../../../../../assets/icons/file-list-check.svg";
import CommonButton from '../../../../../common/CommonButton/CommonButton';
import SelectNetworkModal from '../SelectNetworkModal';
import ConfirmTransactionModal from '../ConfirmTransactionModal';
import CustomTooltip from '../../../../../common/Tooltips/CustomTooltip';
import DepositSuccessfulModal from '../DepositSuccessfulModal';
import RecentDepositTable from '../RecentDepositTable/RecentDepositTable';

const DepositCard = () => {

    const coinList = [
        {
            TokenIcon: TokenIcon1,
            tokenname: "BTC",
            fullname: "Bitcoin",
        },
        {
            TokenIcon: TokenIcon,
            tokenname: "USDT",
            fullname: "Tether",
        },
        {
            TokenIcon: TokenIcon2,
            tokenname: "ALGO",
            fullname: "Algorand",
        },
        {
            TokenIcon: TokenIcon3,
            tokenname: "ADA",
            fullname: "Cardano",
        },
        {
            TokenIcon: TokenIcon4,
            tokenname: "AVAX",
            fullname: "Avalanche",
        },
        {
            TokenIcon: TokenIcon5,
            tokenname: "XRP",
            fullname: "Ripple",
        },
        {
            TokenIcon: TokenIcon6,
            tokenname: "TRX",
            fullname: "Tron",
        },
        {
            TokenIcon: TokenIcon,
            tokenname: "BTC",
            fullname: "Bitcoin",
        },
    ];

    const orderdata = [
        {
            heading: "Expected arrival",
            text: "-- --",
        },
        {
            heading: "Expected unlock",
            text: "-- --",
        },
        {
            heading: "Minimum deposit",
            text: "-- --",
        },
        {
            heading: "Selected wallet",
            text: "Spot Wallet",
            textnone: "d-inline-block",
            subtext: "Change",
        },
    ];

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showconfirm, setShowconfirm] = useState(false);

    const handleCloseconfirm = () => setShowconfirm(false);
    const handleShowconfirm = () => setShowconfirm(true);

    const [showsuccessful, setShowsuccessful] = useState(false);

    const handleClosesuccessful = () => setShowsuccessful(false);
    const handleShowsuccessful = () => setShowsuccessful(true);

    return (
        <>
            <div className='Deposit_Card'>
                <Row>
                    <Col xs={12} md={3} xl={3} xxl={2}>
                        <p className='Label_Text'>Select Coin</p>
                    </Col>
                    <Col xs={12} md={9} xl={7} xxl={6}>
                        <div className='Deposit_Card_Inputgroup'>
                            <label>Coin</label>
                            <Dropdown className='Common_Dropdown'>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    <img src={TokenIcon} alt='Token' /> <span>USDT</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {coinList.map((item, i) => {
                                        return (
                                            <Dropdown.Item key={i} href="#">
                                                <img src={item.TokenIcon} alt='Token' /> <span>{item.tokenname}</span> <small>{item.fullname}</small>
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3} xl={3} xxl={2}>
                        <p className='Label_Text'>Deposit to</p>
                    </Col>
                    <Col xs={12} md={9} xl={7} xxl={6}>
                        <div className='Deposit_Card_Inputgroup'>
                            <label>Network</label>
                            <div onClick={handleShow} className='Deposit_Card_Network'>
                                Please select the network
                            </div>
                        </div>

                        <div className='Deposit_Card_OrderInfo'>
                            <h6><img src={fileIcon} alt='icon' /> Order information</h6>
                            <ul className='Deposit_Card_OrderInfo_List'>
                                {orderdata.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <p>{item.heading}</p>
                                            <h4>{item.text} <span className={`Sub_text text_blue ${item.textnone}`}>{item.subtext}</span> </h4>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div className='Deposit_Card_OrderInfo_Fee d-flex align-items-center'>
                                <span>Fee</span>
                                <span className='cardgreen_btn'>-- --</span>
                            </div>

                            <ul className='Deposit_Card_OrderInfo_sendList'>
                                <li>Send only USDT to this deposit address.</li>
                                <li>Ensure the network is <span className='text_red'>Ethereum (ERC20)</span>.</li>
                            </ul>
                            <div className='d-md-flex align-items-end'>
                                <div className='netweork_fee me-md-4'>
                                    <h3>1000.00 USDT</h3>
                                    <p className='d-md-flex align-items-end'>10.00 USDT Network Fee is included <CustomTooltip text="Hello" /></p>
                                </div>
                                <CommonButton onClick={handleShowconfirm} title="Deposit" className="Deposit_btn mt-4 mt-md-0" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <RecentDepositTable />
                <SelectNetworkModal show={show} handleClose={handleClose} />
                <ConfirmTransactionModal show={showconfirm} handleClose={handleCloseconfirm} />
                <DepositSuccessfulModal show={showsuccessful} handleClose={handleClosesuccessful} />
            </div>
        </>
    )
}

export default DepositCard
 