import React, { useState } from "react";
import "react-rangeslider/lib/index.css";
import "./RangeSlider.scss";
import Slider from "react-rangeslider";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hooks";
import { useEffect } from "react";
import { saveLeverage } from "../../redux/_slices/user.slice";

const RangeSlider = ({
  min,
  max,
  format,
  handleSetRange,
  rangeData,
  type_field,
  setLossRoi,
  rangeAndROIValue,
  setROIValue,
  setRoi,
}) => {
  const [range, setRange] = useState(min);

  const horizontalLabels = {
    0: `${min}${format}`,
    50: "",
    100: `${max}${format}`,
  };

  const formatkg = (value) => value + format;

  const handleSetRangeChange = (values) => {
    // setRange(value);
    handleSetRange(values);
    const newValue = type_field === "SL_ET" ? -Math.abs(values) : values;
    if (type_field === "SL_ET") {
      setLossRoi(newValue);
    } else {
      if (newValue) {
        setROIValue(newValue);
      }
    }
    setRoi(newValue);

    const negativeValue = -Math.abs(values);
    const positiaveANDnagativeValue =
      type_field === "SL_ET" ? negativeValue : newValue;
    handleSetRange(Number(newValue));
    rangeAndROIValue(Number(positiaveANDnagativeValue));
  };

  useEffect(() => {
    if (rangeData) {
      const value = Math.abs(rangeData);
      if (value > max) {
        setRange([max]);
      } else if (value < min) {
        setRange([min]);
      } else {
        setRange([value]);
      }
    } else {
      setRange([min]);
    }
  }, [rangeData]);

  return (
    <>
      <div className="Range_Slider">
        <div className="slider custom-labels">
          <Slider
            min={min}
            max={max}
            value={range}
            labels={horizontalLabels}
            format={formatkg}
            handleLabel={range}
            onChange={handleSetRangeChange}
          />
          <div className="RangeSlider_Value" style={{ userSelect: "none" }}>
            {formatkg(range)}
          </div>
        </div>
      </div>
    </>
  );
};

export default RangeSlider;
