import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./BoundCard.scss";
import OutBound from "./OutBound";


const BoundCard = ({userOrderListOutbound, className,cancelFunction,cancelAllOrders,userOrderListInbound ,tab,onchangeTab,userAllOrderList,InboundSokectFUnction }) => {
  return (
    <>
      <div className={`Bound_Card ${className}`}>
        <Tabs
          defaultActiveKey="outbound"
          id="uncontrolled-tab-example"
          className="Bottom_Border_Tabs"
          onSelect={(e) => onchangeTab(e)}
        >
          <Tab eventKey="outbound" title="Outbound">
            <div className="Bound_Card_Box">
              <OutBound   cancelAllOrders={cancelAllOrders} tab={tab} userOrderList={userOrderListOutbound} InboundSokectFUnction={InboundSokectFUnction} />
            </div>
          </Tab>
          <Tab eventKey="inbound" title="Inbound">
            <div className="Bound_Card_Box">
              <OutBound   cancelAllOrders={cancelAllOrders} tab={tab} userOrderList={userOrderListInbound}  InboundSokectFUnction={InboundSokectFUnction}/>
            </div>
          </Tab>
          <Tab eventKey="history" title="History">
            <div className="Bound_Card_Box">
              <OutBound   cancelAllOrders={cancelAllOrders} tab={tab} userOrderList={userAllOrderList} InboundSokectFUnction={InboundSokectFUnction}/>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default BoundCard;
