import React, { memo, useEffect, useRef, useState } from "react";
import {
  CrossIcon,
  DownarrowIcon,
  SearchIcon,
  StarIcon,
  UparrowIcon,
} from "../../../../assets/Svg/SvgImages";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TokenIcon from "../../../../assets/images/btc.svg";
import { toggleClass } from "rsuite/esm/DOMHelper";
import { useDispatch, useSelector } from "react-redux";
import millify from "millify";
import {
  getDecimalString,
  numberSystemFormate,
  showDecimal,
} from "../../../../_utils/comman";
import {
  saveOrderSize,
  savePairDecimals,
} from "../../redux/_slices/exchange.slice";
import {
  savePrevSelectedPairKey,
  saveSelectedPair,
  saveSelectedPairKey,
} from "../../../../redux/_slices/user.slice";
import { saveFavPairsList } from "../../../../redux/_actions/user.action";
import Modal from "react-bootstrap/Modal";
import { Dropdown, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import TopPairs from "./TopPairs";
import InfiniteScroll from "react-infinite-scroll-component";
import dummyToken from "../../../../assets/images/dummyToken.png";
const TradingPairs = ({ toggleClass, handleClose, show }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const exchange = useSelector((state) => state.exchange);
  const selectedPairKey = useSelector((state) => state.user.selectedPairKey);
  const selectedPair = useSelector((state) => state.user.selectedPair);
  const [selectMarket, setSelectMarket] = useState("all");
  const [search, setSearch] = useState("");
  const [listOfPairs, setListOfPairs] = useState([]);
  const [toggelSortprice, setToggelSortprice] = useState("");
  const [toggelSortChange, setToggelSortChange] = useState("");
  const [toggelSortPair, setToggelSortPair] = useState("");
  const [modalPair, setModalPair] = useState(false);
  const pairList = useSelector((state) => state?.exchange?.listOfPairs);
  const [toggelSortVolume, setToggleSortVolume] = useState("");
  const [visibleItems, setVisibleItems] = useState(pairList.slice(0, 20));
  const [hasMore, setHasMore] = useState(true);
  const isVisibleArraySet = useRef(false);

  const [sort, setSort] = useState({
    pair_name: "",
    low: "",
    change: "",
  });

  useEffect(() => {
    if (!isVisibleArraySet.current) {
      setVisibleItems(pairList.slice(0, 20));
      if (pairList.length > 0) {
        isVisibleArraySet.current = true;
      }
    }
  }, [pairList]);

  const fetchMoreData = () => {
    if (visibleItems.length >= pairList.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setVisibleItems(pairList.slice(0, visibleItems.length + 20));
    }, 500);
  };

  useEffect(() => {
    if (selectMarket === "fav" && search == "") {
      const favArray = pairList.filter((el) => {
        return user.favPairList.find((element) => {
          return element === el.pair_name;
        });
      });
      setListOfPairs(favArray);
    } else if (
      search == "" &&
      selectMarket == "all" &&
      toggelSortprice == "" &&
      toggelSortChange == "" &&
      toggelSortPair == ""
    ) {
      setListOfPairs(exchange?.listOfPairs);
    }
  }, [exchange?.listOfPairs, search]);

  const handleSearch = async (event) => {
    setVisibleItems(pairList.slice(0, 20));
    const { value } = event.target;
    setSearch(value);
    if (selectMarket == "all") {
      const filteredPersons = pairList.filter((data) => {
        return data.pair_name.toLowerCase().includes(value.toLowerCase());
      });
      setListOfPairs(filteredPersons);
      setVisibleItems(filteredPersons.slice(0, 20));
    } else if (selectMarket == "fav") {
      const favArray = pairList.filter((el) => {
        return user.favPairList.find((element) => {
          return element === el.pair_name;
        });
      });
      if (value == "") {
        setListOfPairs(favArray);
        setVisibleItems(favArray.slice(0, 20));
      } else {
        const favArrayList = favArray.filter((el) => {
          return el.pair_name.toLowerCase().includes(value.toLowerCase());
        });
        setListOfPairs(favArrayList);
        setVisibleItems(favArrayList.slice(0, 20));
      }
    } else if (selectMarket == "usdt") {
      const keyArray = pairList.filter((doc) =>
        doc?.pair_name.toLowerCase().includes("usdt")
      );
      if (value == "") {
        setListOfPairs(keyArray);
        setVisibleItems(keyArray.slice(0, 20));
      } else {
        const usdtArray = keyArray.filter((el) => {
          return el.pair_name.toLowerCase().includes(value.toLowerCase());
        });
        setListOfPairs(usdtArray);
        setVisibleItems(usdtArray.slice(0, 20));
      }
    } else if (value == "") {
      setListOfPairs(pairList);
      setVisibleItems(pairList.slice(0, 20));
    }
  };

  const handleSelectMarket = (event, key) => {
    if (key === "all") {
      if (search != "") {
        const filteredPersons = pairList.filter((data) => {
          return data.pair_name
            ?.replace("_", "")
            .toLowerCase()
            .includes(search.toLowerCase());
        });
        setListOfPairs(filteredPersons);
        setVisibleItems(filteredPersons.slice(0, 20));
      } else {
        setListOfPairs(pairList);
        setVisibleItems(pairList.slice(0, 20));
      }
    } else if (key === "fav") {
      const favArray = pairList.filter((el) => {
        return user.favPairList.find((element) => {
          return element === el.pair_name;
        });
      });

      if (search != "") {
        const favSearch = favArray.filter((data) => {
          return data.pair_name
            ?.replace("_", "")
            .toLowerCase()
            .includes(search.toLowerCase());
        });
        setListOfPairs(favSearch);
        setVisibleItems(favSearch.slice(0, 20));
      } else {
        setListOfPairs(favArray);
        setVisibleItems(favArray.slice(0, 20));
      }
    } else if (key === "usdt") {
      const keyArray = pairList.filter((doc) =>
        doc?.pair_name.toLowerCase().includes("usdt")
      );
      if (search != "") {
        const ustdSearch = keyArray.filter((data) => {
          return data.pair_name
            ?.replace("_", "")
            .toLowerCase()
            .includes(search.toLowerCase());
        });
        setListOfPairs(ustdSearch);
        setVisibleItems(ustdSearch.slice(0, 20));
      } else {
        setListOfPairs(keyArray);
        setVisibleItems(keyArray.slice(0, 20));
      }
    } else {
      const keyArray = pairList.filter(
        (doc) => doc.price_currency == key || doc.base_currency === key
      );
      setListOfPairs(keyArray);
      setVisibleItems(keyArray.slice(0, 20));
    }
  };

  const handleFavorite = (data) => {
    dispatch(saveFavPairsList({ pair_name: data?.pair_name, type: "perp" }));
  };

  const handleOnChange = (value) => {
    // handleModal();
    // ModalClose();
    const pairData = pairList?.find((doc) => doc.pair_name === value);
    // console.log("pairData=", pairData, pairList);

    const priceDecimalLength = getDecimalString(
      pairData?.min_price_increment,
      pairData?.price_decimal
    );

    const baseDecimalLength = getDecimalString(
      pairData?.min_size_increment,
      pairData?.base_decimal
    );

    dispatch(savePairDecimals({ priceDecimalLength, baseDecimalLength }));
    dispatch(savePrevSelectedPairKey(selectedPairKey));
    dispatch(saveSelectedPairKey(value));
    dispatch(saveOrderSize(""));

    dispatch(saveSelectedPair(pairData));
    handleClose();
  };

  const handleToggleSort = (type, name) => {
    console.log("handleToggleSort", type, name);
    setSort({ ...sort, [name]: type });
    // let pairLists = exchange.listOfPairs;
    switch (name) {
      case "lastPrice":
        if (type === "") {
          let obj = [...listOfPairs];
          obj.sort((a, b) => {
            if (Number(a.low) > Number(b.low)) {
              return -1;
            } else if (Number(a.low) < Number(b.low)) {
              return 1;
            }
            return 0;
          });
          setListOfPairs(obj);
          setVisibleItems(obj.slice(0, 20));

          setToggelSortprice("up");
        } else if (type === "up") {
          let obj = [...listOfPairs];
          obj.sort((a, b) => {
            if (Number(a.low) < Number(b.low)) {
              return -1;
            } else if (Number(a.low) > Number(b.low)) {
              return 1;
            }
            return 0;
          });
          setListOfPairs(obj);
          setVisibleItems(obj.slice(0, 20));
          setToggelSortprice("down");
        } else {
          setListOfPairs(listOfPairs);
          setVisibleItems(listOfPairs.slice(0, 20));
          setToggelSortprice("");
        }
        break;
      case "change":
        if (type === "") {
          let obj = [...listOfPairs];
          obj.sort((a, b) => {
            if (Number(a.change) > Number(b.change)) {
              return -1;
            } else if (Number(a.change) < Number(b.change)) {
              return 1;
            }
            return 0;
          });
          setListOfPairs(obj);
          setVisibleItems(obj.slice(0, 20));

          setToggelSortChange("up");
        } else if (type === "up") {
          let obj = [...listOfPairs];
          obj.sort((a, b) => {
            if (Number(a.change) < Number(b.change)) {
              return -1;
            } else if (Number(a.change) > Number(b.change)) {
              return 1;
            }
            return 0;
          });
          setListOfPairs(obj);
          setVisibleItems(obj.slice(0, 20));
          setToggelSortChange("down");
        } else {
          setListOfPairs(listOfPairs);
          setVisibleItems(listOfPairs.slice(0, 20));

          setToggelSortChange("");
        }
        break;
      case "pair_name":
        if (type === "") {
          let obj = [...listOfPairs];
          obj.sort((a, b) => {
            if (a.pair_name > b.pair_name) {
              return -1;
            } else if (a.pair_name < b.pair_name) {
              return 1;
            }
            return 0;
          });
          setListOfPairs(obj);
          setVisibleItems(obj.slice(0, 20));
          setToggelSortPair("up");
        } else if (type === "up") {
          let obj = [...listOfPairs];
          obj.sort((a, b) => {
            if (a.pair_name < b.pair_name) {
              return -1;
            } else if (a.pair_name > b.pair_name) {
              return 1;
            }
            return 0;
          });
          setListOfPairs(obj);
          setVisibleItems(obj.slice(0, 20));
          setToggelSortPair("down");
        } else {
          setListOfPairs(listOfPairs);
          setVisibleItems(listOfPairs.slice(0, 20));
          setToggelSortPair("");
        }
        break;
      case "volume_24":
        if (type === "" || type === "down") {
          let obj = [...listOfPairs];
          obj.sort((first, second) => {
            let a = { ...first };
            let b = { ...second };

            if (!first?.volume) {
              a["volume"] = 0;
            }
            if (!second?.volume) {
              b["volume"] = 0;
            }

            if (Number(a.volume) < Number(b.volume)) {
              return -1;
            } else if (Number(a.volume) > Number(b.volume)) {
              return 1;
            }
            return 0;
          });
          setListOfPairs(obj);
          setVisibleItems(obj.slice(0, 20));
          setToggleSortVolume("up");
        } else if (type === "up") {
          let obj = [...listOfPairs];
          obj.sort((first, second) => {
            let a = { ...first };
            let b = { ...second };

            if (!first?.volume) {
              a["volume"] = 0;
            }
            if (!second?.volume) {
              b["volume"] = 0;
            }
            if (Number(a.volume) > Number(b.volume)) {
              return -1;
            } else if (Number(a.volume) < Number(b.volume)) {
              return 1;
            }
            return 0;
          });
          setListOfPairs(obj);
          setVisibleItems(obj.slice(0, 20));
          setToggleSortVolume("down");
        } else {
          setListOfPairs(listOfPairs);
          setVisibleItems(listOfPairs.slice(0, 20));
          setToggleSortVolume("");
        }
        break;
    }
  };

  const Currencybtns = [
    { btnname: "Layer1" },
    { btnname: "Layer2" },
    { btnname: "Gaming" },
    { btnname: "Community" },
    { btnname: "Layer3" },
    { btnname: "Trending" },
    { btnname: "Layer4" },
    { btnname: "Stablecoin" },
    { btnname: "Layer5" },
    { btnname: "Layer6" },
    { btnname: "Layer7" },
    { btnname: "Layer8" },
    { btnname: "Layer9" },
    { btnname: "Layer10" },
    { btnname: "Layer11" },
  ];

  const addDefaultSrc = (ev) => {
    ev.target.src = dummyToken;
  };
  return (
      <>
          <Modal
              centered
              className="TradingPairs_Modal nondraggable_card"
              show={show}
              onHide={handleClose}
          >
              <Modal.Header closeButton>
                  <Modal.Title>Trading Pairs</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="TradingPairs_dropdown">
                      <div className="TradingPairs_Tabs">
                          <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="spot"
                          >
                              <Nav variant="pills" className="">
                                  {tradingList.map((item, i) => {
                                      return (
                                          <Nav.Item key={i}>
                                              <Nav.Link
                                                  eventKey={item.id}
                                                  onClick={(event) => {
                                                      if (item.id === "star") {
                                                          handleSelectMarket(
                                                              event,
                                                              "fav"
                                                          );
                                                          setSelectMarket(
                                                              "fav"
                                                          );
                                                      } else {
                                                          handleSelectMarket(
                                                              event,
                                                              "all"
                                                          );
                                                          setSelectMarket(
                                                              "all"
                                                          );
                                                      }
                                                  }}
                                              >
                                                  {item.id === "star" ? (
                                                      <StarIcon />
                                                  ) : (
                                                      item.name
                                                  )}
                                                  {item.isNew && (
                                                      <span className="new_btn">
                                                          New
                                                      </span>
                                                  )}
                                              </Nav.Link>
                                          </Nav.Item>
                                      );
                                  })}
                                  {/* <Nav.Item><Nav.Link eventKey="star"><StarIcon /></Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="spot">Spot</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="margin">Margin</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="futures">Futures <span className='new_btn'>New</span></Nav.Link></Nav.Item> */}
                              </Nav>
                              <div className="Currency">
                                  <div className="Currency_SelectBox">
                                      <Dropdown>
                                          <Dropdown.Toggle
                                              variant=""
                                              id="dropdown-basic"
                                          >
                                              Currency
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                              {marketList.map((data, i) => {
                                                  return (
                                                      <Dropdown.Item
                                                          key={i}
                                                          onClick={
                                                              handleSelectMarket
                                                          }
                                                          to="javascript:;"
                                                      >
                                                          {data.name}
                                                      </Dropdown.Item>
                                                  );
                                              })}
                                          </Dropdown.Menu>
                                      </Dropdown>
                                  </div>
                                  <div className="Currency_TabBtns">
                                      {Currencybtns.map((data, i) => {
                                          return (
                                              <button
                                                  key={i}
                                                  type="button"
                                                  className="Currency_TabBtns_Btns"
                                              >
                                                  {data.btnname}
                                              </button>
                                          );
                                      })}
                                  </div>
                              </div>
                              <div className="TradingPairs_search mb-4">
                                  <Form.Control
                                      className="Custom_Search"
                                      placeholder="Search pair"
                                      onChange={handleSearch}
                                      value={search}
                                  />
                                  <SearchIcon />
                              </div>
                              <Tab.Content className="list_pairs_content">
                                  {tradingList.map((item, i) => {
                                      return (
                                          <Tab.Pane eventKey={item.id}>
                                              <div className="Table_Pairs">
                                                  <div className="Table_Pairs_Head">
                                                      <ul>
                                                          <li className="Table_th">
                                                              <ul>
                                                                  <li
                                                                      onClick={() => {
                                                                          handleToggleSort(
                                                                              toggelSortPair,
                                                                              "pair_name"
                                                                          );
                                                                      }}
                                                                  >
                                                                      <span>
                                                                          Market
                                                                      </span>
                                                                  </li>
                                                                  <li
                                                                      onClick={() => {
                                                                          handleToggleSort(
                                                                              toggelSortprice,
                                                                              "lastPrice"
                                                                          );
                                                                      }}
                                                                  >
                                                                      Last price{" "}
                                                                      <span className="Up_down_arrow">
                                                                          <DownarrowIcon />{" "}
                                                                          <DownarrowIcon />
                                                                      </span>
                                                                  </li>
                                                                  <li
                                                                      onClick={() => {
                                                                          handleToggleSort(
                                                                              toggelSortChange,
                                                                              "change"
                                                                          );
                                                                      }}
                                                                  >
                                                                      24h Chg{" "}
                                                                      <span className="Up_down_arrow">
                                                                          <DownarrowIcon />{" "}
                                                                          <DownarrowIcon />
                                                                      </span>
                                                                  </li>
                                                                  <li
                                                                      onClick={() => {
                                                                          handleToggleSort(
                                                                              toggelSortVolume,
                                                                              "volume_24"
                                                                          );
                                                                      }}
                                                                  >
                                                                      24h Volume{" "}
                                                                      <span className="Up_down_arrow">
                                                                          <DownarrowIcon />{" "}
                                                                          <DownarrowIcon />
                                                                      </span>
                                                                  </li>
                                                              </ul>
                                                          </li>
                                                          <li className="Table_star"></li>
                                                      </ul>
                                                  </div>
                                                  <div className="Table_Pairs_Data">
                                                      <InfiniteScroll
                                                          dataLength={
                                                              visibleItems.length
                                                          }
                                                          next={fetchMoreData}
                                                          hasMore={hasMore}
                                                          height={350}
                                                          loader={
                                                              <p>Loading...</p>
                                                          }
                                                          endMessage={
                                                              <p
                                                                  style={{
                                                                      textAlign:
                                                                          "center",
                                                                  }}
                                                              >
                                                                  <b>
                                                                      Yay! You
                                                                      have seen
                                                                      it all
                                                                  </b>
                                                              </p>
                                                          }
                                                      >
                                                          {visibleItems.map(
                                                              (data, i) => {
                                                                  return (
                                                                      <div
                                                                          key={
                                                                              i
                                                                          }
                                                                      >
                                                                          <ul>
                                                                              <li
                                                                                  className="table_td"
                                                                                  onClick={() =>
                                                                                      handleOnChange(
                                                                                          data?.pair_name
                                                                                      )
                                                                                  }
                                                                              >
                                                                                  <ul>
                                                                                      <li>
                                                                                          <div className="d-flex align-items-center Pairs_Token">
                                                                                              {data?.icon ? (
                                                                                                  <img
                                                                                                      className="Coin_Icon"
                                                                                                      src={
                                                                                                          data?.icon
                                                                                                      }
                                                                                                      alt="img"
                                                                                                      onError={
                                                                                                          addDefaultSrc
                                                                                                      }
                                                                                                  />
                                                                                              ) : (
                                                                                                  <img
                                                                                                      className="Coin_Icon"
                                                                                                      src={
                                                                                                          dummyToken
                                                                                                      }
                                                                                                      alt="img"
                                                                                                  />
                                                                                              )}
                                                                                              <h5>
                                                                                                  {
                                                                                                      data?.pair_name
                                                                                                  }{" "}
                                                                                                  <small>
                                                                                                      {
                                                                                                          "Perpetual"
                                                                                                      }
                                                                                                  </small>
                                                                                              </h5>
                                                                                          </div>
                                                                                      </li>
                                                                                      <li>
                                                                                          <p className="Last_Price">
                                                                                              {
                                                                                                  data?.last_price
                                                                                              }
                                                                                          </p>
                                                                                      </li>
                                                                                      <li>
                                                                                          <p
                                                                                              className={`Change_hrs ${
                                                                                                  data?.change >
                                                                                                  0
                                                                                                      ? "text_green"
                                                                                                      : "text_red"
                                                                                              }`}
                                                                                          >{`${numberSystemFormate(
                                                                                              showDecimal(
                                                                                                  data?.change *
                                                                                                      100,
                                                                                                  2
                                                                                              )
                                                                                          )}%`}</p>
                                                                                      </li>
                                                                                      <li>
                                                                                          <p className="Last_Price">
                                                                                              {data?.volume >
                                                                                              0
                                                                                                  ? millify(
                                                                                                        data?.volume,
                                                                                                        {
                                                                                                            precision: 2,
                                                                                                            // locales: "de-DE",
                                                                                                        }
                                                                                                    )
                                                                                                  : data?.volume}{" "}
                                                                                              USDT
                                                                                          </p>
                                                                                      </li>
                                                                                  </ul>
                                                                              </li>
                                                                              <li
                                                                                  className="table_star"
                                                                                  onClick={() => {
                                                                                      handleFavorite(
                                                                                          data
                                                                                      );
                                                                                  }}
                                                                              >
                                                                                  <span
                                                                                      className={
                                                                                          user?.favPairList?.find(
                                                                                              (
                                                                                                  doc
                                                                                              ) =>
                                                                                                  doc ===
                                                                                                  data.pair_name
                                                                                          )
                                                                                              ? "Star_Data fav-star"
                                                                                              : "Star_Data"
                                                                                      }
                                                                                  >
                                                                                      <StarIcon />
                                                                                  </span>
                                                                              </li>
                                                                          </ul>
                                                                      </div>
                                                                  );
                                                              }
                                                          )}
                                                      </InfiniteScroll>
                                                  </div>
                                              </div>
                                          </Tab.Pane>
                                      );
                                  })}

                                  {/* <Tab.Pane eventKey="margin">
                                        <div className='Table_Pairs'>
                                            <div className='Table_Pairs_Head'>
                                                <ul>
                                                    <li className='Table_th'>
                                                        <ul>
                                                            <li><span>Market</span></li>
                                                            <li>Last price <span className='Up_down_arrow'><DownarrowIcon /> <DownarrowIcon /></span></li>
                                                            <li>24h Chg <span className='Up_down_arrow'><DownarrowIcon /> <DownarrowIcon /></span></li>
                                                            <li>24h Volume <span className='Up_down_arrow'><DownarrowIcon /> <DownarrowIcon /></span></li>
                                                        </ul>
                                                    </li>
                                                    <li className='Table_star'></li>
                                                </ul>
                                            </div>
                                            <div className='Table_Pairs_Data'>
                                                {listOfPairs.map((data, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <ul>
                                                                <li className='table_td' onClick={() => handleOnChange(data?.pair_name)}>
                                                                    <ul>
                                                                        <li>
                                                                            <div className='d-flex align-items-center Pairs_Token'>
                                                                                <img className='Coin_Icon' src={data?.icon} alt='img' />
                                                                                <h5>{data?.pair} <small>{'Perpetual'}</small></h5>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <p className='Last_Price'>{data?.last_price}</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className={`Change_hrs ${data?.change > 0 ? "text_green" : "text_red"}`}>{`${numberSystemFormate(showDecimal(data?.change * 100, 2))}%`}</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className='Last_Price'>{data?.last_price}</p>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <li className='table_star' onClick={() => {
                                                                    handleFavorite(data);
                                                                }}>
                                                                    <span className={
                                                                        user?.favPairList?.find(
                                                                            (doc) => doc === data.pair_name
                                                                        )
                                                                            ? "Star_Data fav-star"
                                                                            : "Star_Data"
                                                                    }><StarIcon />
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="futures">
                                        <div className='Table_Pairs'>
                                            <div className='Table_Pairs_Head'>
                                                <ul>
                                                    <li className='Table_th'>
                                                        <ul>
                                                            <li><span>Market</span></li>
                                                            <li>Last price <span className='Up_down_arrow'><DownarrowIcon /> <DownarrowIcon /></span></li>
                                                            <li>24h Chg <span className='Up_down_arrow'><DownarrowIcon /> <DownarrowIcon /></span></li>
                                                            <li>24h Volume <span className='Up_down_arrow'><DownarrowIcon /> <DownarrowIcon /></span></li>
                                                        </ul>
                                                    </li>
                                                    <li className='Table_star'></li>
                                                </ul>
                                            </div>
                                            <div className='Table_Pairs_Data'>
                                                {listOfPairs.map((data, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <ul>
                                                                <li className='table_td' onClick={() => handleOnChange(data?.pair_name)}>
                                                                    <ul>
                                                                        <li>
                                                                            <div className='d-flex align-items-center Pairs_Token'>
                                                                                <img className='Coin_Icon' src={data?.icon} alt='img' />
                                                                                <h5>{data?.pair} <small>{'Perpetual'}</small></h5>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <p className='Last_Price'>{data?.last_price}</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className={`Change_hrs ${data?.change > 0 ? "text_green" : "text_red"}`}>{`${numberSystemFormate(showDecimal(data?.change * 100, 2))}%`}</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className='Last_Price'>{data?.last_price}</p>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <li className='table_star' onClick={() => {
                                                                    handleFavorite(data);
                                                                }}>
                                                                    <span className={
                                                                        user?.favPairList?.find(
                                                                            (doc) => doc === data.pair_name
                                                                        )
                                                                            ? "Star_Data fav-star"
                                                                            : "Star_Data"
                                                                    }><StarIcon />
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </Tab.Pane> */}
                              </Tab.Content>
                          </Tab.Container>

                          <TopPairs
                              listOfPairs={listOfPairs}
                              handleClose={handleClose}
                          />
                      </div>
                  </div>
              </Modal.Body>
          </Modal>
      </>
  );
};

export default memo(TradingPairs);

const marketList = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "usdt",
    name: "USDT",
  },
];

const tradingList = [
  {
    id: "star",
    name: "",
    isNew: false,
  },
  {
    id: "spot",
    name: "Spot",
    isNew: false,
  },
  {
    id: "margin",
    name: "Margin",
    isNew: false,
  },
  {
    id: "futures",
    name: "Futures",
    isNew: true,
  },
];
