import React, { useState, useEffect } from "react";
import BoundCard from "../BoundCard/BoundCard";

import SellGetCard from "../SellGetCard/SellGetCard";
import "./SetOrderCard.scss";
import { toast } from "react-toastify";
import { ApiServices } from "../../services/api.srvices";
import { toFixed } from "../../../../constants/constants";
import { useSelector } from "react-redux";
import { socket_p2p } from "../../../../App";

const SetOrderCard = () => {
  const [userOrderListOutbound, setUserOrderListOutbound] = useState("");
  const [userOrderListInbound, setUserOrderListInbound] = useState("");
  const [userAllOrderList, setUserAllOrderList] = useState("");
  const [ehxDollarConversion, setEhxDollarConversion] = useState(0);
  const [usdtDollarConversion, setUsdtDollarConversion] = useState(0);
  const [tab, setTab] = useState("outbound");
  const walletAddress = useSelector((state) => state.user.walletAddress);
  const [buyerAddres, setBuyerAddres] = useState("");

  // console.log("userOrderListOutbound-parent", userOrderListInbound);

  /************** CREATE ORDER API ***************/
  const APICreateOrder = async (data) => {
    const response = await ApiServices.createP2POrder(data);
    if (response && response.status === 200) {
      toast.success(response?.message);
      // outboundSocketfunc();
    } else {
      toast.error(response?.message);
    }
  };
  /*************************** END ********************************/

  /************** GET ALL HISTORY API ***************/
  const AllHistoryApi = async () => {
    const data = {
      address: walletAddress,
    };
    const response = await ApiServices.HistoryAllList(data);
    if (response && response.status === 200) {
      let newData = response.data;
      setUserAllOrderList(newData);
    } else {
      toast.error(response?.message);
    }
  };
  /*************************** END ********************************/

  useEffect(() => {
    if (walletAddress) {
      if (tab === "outbound") {
        OutboundApi();
      }
      if (tab === "inbound") {
        InboundApi();
      }
      if (tab === "history") {
        AllHistoryApi();
      }
    }
  }, [tab, walletAddress]);

  useEffect(() => {
    getOrderRecords();
  }, [walletAddress]);

  /************** GET EHX PRICE IN USDT FROM API ***************/
  const handleEhxprice = async () => {
    const response = await ApiServices.getEHXDollarPrice();
    // console.log("asdfasdfadsfeeeeee", response);
    if (response && response.status === 200) {
      setEhxDollarConversion(response?.data?.EhxDollarPrice);
    } else {
      toast.error(response?.message);
    }
  };

  /************** GET USDT PRICE IN USDT FROM API ***************/
  const handleUsdtDollarPrice = async (data) => {
    const response = await ApiServices.getUSDTDollarPrice(data);
    console.log("responseresponse", response);
    if (response) {
      let conversionPrice = response;
      const finalPrice = toFixed(conversionPrice, 6);
      setUsdtDollarConversion(finalPrice);
    }
  };
  useEffect(() => {
    handleEhxprice();
    if (usdtDollarConversion) {
      handleUsdtDollarPrice(0);
    }
  }, []);
  /*************************** END ********************************/

  // changing tab in bound component and thats why this function need to be control from here
  const onchangeTab = (tab) => {
    setTab(tab);
  };

  /************** :::: LISTENER :::: GETTING ALL ORDER INOUND AND OUTBOUND FROM SOCKET CALL ***************/
  const getOrderRecords = () => {
    // console.log("Socket_called");
    socket_p2p.removeListener("getOrdersDetails");
    socket_p2p.on("getOrdersDetails", (response) => {
      console.log("getOrdersDetails=", response);
      setUserOrderListOutbound(response?.outBoundOrder);
      setUserOrderListInbound(response?.indoundOrder);
    });
  };
  /*************************** END ********************************/

  /************** EMITTING SOCKET TO GET ORDERS ***************/
  const outboundSocketfunc = () => {
    // socket_p2p.emit("getOrders", {
    //   seller_address: walletAddress,
    //   buyer_address: buyerAddres,
    // });
  };
  /*************************** END ********************************/

  /************** GETTING ORDER FROM OUTBOUND API ***************/
  const OutboundApi = async () => {
    const data = {
      address: walletAddress,
    };
    const response = await ApiServices.listP2POrders(data);
    if (response && response.status === 200) {
      let newData = response.data;
      setUserOrderListOutbound(newData);
    } else {
      toast.error(response?.message);
    }
  };
  /*************************** END ********************************/

  /************** GETTING ORDER FROM INBOUND API ***************/
  const InboundApi = async () => {
    const data = {
      address: walletAddress,
    };
    const response = await ApiServices.inboundOrderList(data);
    if (response && response.status === 200) {
      let newData = response.data;
      setUserOrderListInbound(newData);
    } else {
      toast.error(response?.message);
    }
  };
  /*************************** END ********************************/

  // useEffect(()=>{
  //   if (tab === "inbound") {
  //   InboundApi()
  //   }
  // },[tab,walletAddress])

  //   const InboundApi = async ()=>{
  //     const data = {
  //       address: walletAddress,
  //     };
  //     const response = await ApiServices.inboundOrderList(data);
  //     if (response && response.status === 200) {
  //       let newData = response.data;
  //       setUserOrderListInbound(newData);
  //     } else {
  //       toast.error(response?.message);
  //     }
  //   }

  return (
    <>
      <SellGetCard
        APICreateOrder={APICreateOrder}
        handleEhxprice={handleEhxprice}
        ehxDollarConversion={ehxDollarConversion}
        handleUsdtDollarPrice={handleUsdtDollarPrice}
        usdtDollarConversion={usdtDollarConversion}
        setBuyerAddres={setBuyerAddres}
        buyerAddres={buyerAddres}
      />
      <BoundCard
        userOrderListOutbound={userOrderListOutbound}
        userOrderListInbound={userOrderListInbound}
        userAllOrderList={userAllOrderList}
        tab={tab}
        onchangeTab={onchangeTab}
        InboundSokectFUnction={getOrderRecords}
      />
    </>
  );
};

export default SetOrderCard;
