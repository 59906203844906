import React from "react";
import { Dropdown } from "react-bootstrap";
import CommonButton from "../../../../../../common/CommonButton/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

const PnlHistoryExport = ({ data }) => {
    const closedPnLFilter = useSelector(
        (state) => state.exchange.userClosedPnl
    );

    const fetchDataAndExportCsv = () => {
        try {
            let closedPnl = closedPnLFilter.map((item) => ({
                Contracts: item?.symbol,
                "Closing Direction": item?.side,
                Qty: item?.qty,
                "Entry Price": roundToTwoDecimals(item?.avgEntryPrice),
                "Exit Price": roundToTwoDecimals(item?.avgExitPrice),
                "Closed P&L": item?.closedPnl,
                "Exit Type": item?.execType,
                "Order Time": moment(Number(item?.createdTime)).format(
                    "DD-MM-YYYY  HH:mm:ss"
                ),
            }));
            exportToCsv(closedPnl, "closedPnL.csv");
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const convertToCsv = (data) => {
        const csvRows = [];
        if (data.length === 0) {
            return "";
        }
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(","));

        data.forEach((row) => {
            const values = headers.map((header) => {
                const escaped = ("" + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(","));
        });

        return csvRows.join("\n");
    };

    const exportToCsv = (data, filename) => {
        const csvData = convertToCsv(data);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const fetchDataAndExportPdf = () => {
        try {
            let dataToExport = [];
            let fileName = "";

            dataToExport = closedPnLFilter.map((item) => ({
                Contracts: item?.symbol,
                "Closing Direction": item?.side,
                Qty: item?.qty,
                "Entry Price": roundToTwoDecimals(item?.avgEntryPrice),
                "Exit Price": roundToTwoDecimals(item?.avgExitPrice),
                "Closed P&L": item?.closedPnl,
                "Exit Type": item?.execType,
                "Order Time": moment(Number(item?.createdTime)).format(
                    "DD-MM-YYYY  HH:mm:ss"
                ),
            }));
            fileName = "closedPnL.pdf";

            exportToPdf(dataToExport, fileName);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const exportToPdf = (data, filename) => {
        const customWidth = 335;
        const standardHeight = 297;

        const doc = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: [customWidth, standardHeight],
        });

        if (data.length === 0) {
            doc.text("No record found", 20, 20);
        } else {
            const tableHeaders = Object.keys(data[0]);
            const tableData = data.map((item) => Object.values(item));
            const commonWidth = 30;
            doc.autoTable({
                head: [tableHeaders],
                body: tableData,
                startY: 20,
                styles: { halign: "center", overflow: "linebreak" },
                columnStyles: {
                    0: { cellWidth: commonWidth },
                    1: { cellWidth: commonWidth },
                    2: { cellWidth: 40 },
                    3: { cellWidth: 40 },
                    4: { cellWidth: commonWidth },
                    5: { cellWidth: 45 },
                    6: { cellWidth: 45 },
                    7: { cellWidth: 45 },
                },
            });
        }

        doc.save(filename);
    };
    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    return (
        <div className="ordersExport">
            <Dropdown align="start">
                <Dropdown.Toggle variant="" id="dropdown-basic">
                    <CommonButton
                        title="Export"
                        className="btn-primary fullHieght ms-md-4"
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="export_list">
                        <ul>
                            <li onClick={fetchDataAndExportCsv}>
                                Export to CSV
                            </li>
                            <li onClick={fetchDataAndExportPdf}>
                                Export to PDF
                            </li>
                        </ul>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
export default PnlHistoryExport;
