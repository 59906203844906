import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Col, Form, Row } from "react-bootstrap";
import "./CryptoAssetsTable.scss";
import { useDispatch } from "react-redux";
import { SearchIcon } from "../../../../../assets/Svg/SvgImages";
import { updateLoader } from "../../../../Exchange/redux/_slices/exchange.slice"

const CryptoAssetsTable = ({ coins, search }) => {
    const [searchCoin, setSearchCoin] = useState("");
    const dispatch = useDispatch();

    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    const onSearchChange = (e) => {
        setSearchCoin(e.target.value);
    };

    const onClickHandler = () => {
        search(searchCoin);
        dispatch(updateLoader(true));
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onClickHandler();
        }
    };

    return (
        <>
            <div className="Convert_Coin d-md-flex align-items-center mt-4 pt-xl-2">
                <div className="Dash_SearchBar">
                    <div className="searchBar_input ms-0">
                        <input
                            type="text"
                            name=""
                            placeholder="Search Coin"
                            className="control_searchBar"
                            onChange={onSearchChange}
                            value={searchCoin}
                            onKeyDown={handleKeyDown}
                        />
                        <SearchIcon onClick={onClickHandler} tabIndex="0" />
                    </div>
                </div>
                <Form.Check
                    label="Hide small Balances"
                    name="ddd"
                    type="checkbox"
                    className="checkbtn_btn ms-md-4 my-3 py-xl-0"
                />
                {/* <span className="Convert_Blnc text_blue ms-md-4">
                        <img src={ConvertIcon} alt="eye" /> Convert Small
                        Balance to EHX
                    </span> */}
            </div>
            <div className="CryptoAssets_Table">
                <h2 className="heading">Crypto Assets</h2>

                <Table responsive size="sm">
                    <thead>
                        <tr>
                            <th>
                                <div className="d-flex align-items-center">
                                    Coin
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    Trans Balance
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    Available
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            {/* <th>
                                <div className="d-flex align-items-center">
                                    In Order
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    BTC Value
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {coins !== undefined && coins?.length > 0 ? (
                            coins?.map((data, i) => {
                                return (
                                    <tr>
                                        <td>
                                            <div className="coin_data d-flex align-items-center">
                                                {/* <img
                                                    className="coin_Icon"
                                                    src={data.btcicon}
                                                    alt="eye"
                                                /> */}
                                                <div className="upDown_arrow">
                                                    <h6>{data.coin}</h6>
                                                    {/* <span>{data.coinname}</span> */}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {roundToTwoDecimals(
                                                data.transferBalance
                                            )}
                                        </td>
                                        <td>
                                            {roundToTwoDecimals(
                                                data.walletBalance
                                            )}
                                        </td>
                                        {/* <td>{data.order}</td>
                                        <td>
                                            {data.btcprice}
                                            <p className="btc_vlaue">
                                                {data.btcvalue}
                                            </p>
                                        </td>
                                        <td>
                                            <div className="action_links">
                                                <Link to="#">Buy</Link>
                                                <Link to="#">Deposit</Link>
                                                <Link to="#">Withdraw</Link>
                                                <Link to="#">Trade</Link>
                                                <Link to="#">Earn</Link>
                                                <Link to="#">Convert</Link>
                                            </div>
                                        </td> */}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <p style={{ textAlign: "center" }}>
                                        No result found
                                    </p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default CryptoAssetsTable;
