import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import "./DepthChart.scss"
import { DepthChart } from "pennant";
import "pennant/dist/style.css";
import { useSelector } from 'react-redux';
import { throttle } from 'lodash';

const DepthvChart = () => {
  const buyOrders = useSelector((state) => state.exchange.buyOrders)
  const sellOrders = useSelector((state) => state.exchange.sellOrders)
  const orderBook = useSelector((state) => state.exchange.orderBook)
  const currentLtp = useSelector(
    (state) => state.exchange.orderBook.currentLtp
  );
  const [orderList, setOrderList] = useState({})
  const buyOrderRef = useRef([])
  const sellOrderRef = useRef([])
  const [ltpValue, setLtp] = useState({})

  const setDepthChartData = () => {
    let buy = []
    let sell = []

    for (let element of buyOrders.slice(0, 50)) {
      if (Number(element[1]) < Number(Number(currentLtp) - 0)) {
        buy.push({
          price: element[1],
          volume: element[3]
        })
      }

    }
    buyOrderRef.current = buy

    for (let element of sellOrders.slice(0, 50)) {
      if (Number(element[1]) > Number(Number(currentLtp) - 0)) {
        sell.push({
          price: element[1],
          volume: element[3]
        })
      }
    }
    sellOrderRef.current = sell
  }

  const SetLTP = () => {
    setLtp({ ltp:currentLtp })
  }
  // State to keep track of throttled function
  // const [throttledFunction, setThrottledFunction] = useState(() => throttle(setDepthChartData, 1000));
  // const [throttledFunction2, setThrottledFunction2] = useState(() => throttle(SetLTP, 1000));

  const throttledFunction = throttle(() => {
    setDepthChartData()
  }, 500);

  const throttledFunction2 = throttle(() => {
    SetLTP()
  }, 500);

  useEffect(() => {
    throttledFunction();
    throttledFunction2();
    // // Update throttled function whenever dependencies change
    // setThrottledFunction(() => throttle(setDepthChartData, 1000));
    // setThrottledFunction2(() => throttle(SetLTP,1000));


  }, [buyOrders, sellOrders])

  // useEffect(() => {
  //   // Call the throttled function
  //   // throttledFunction();
  //   // throttledFunction2();

  //   // Clean up the throttled function on component unmount
  //   return () => {
  //     throttledFunction.cancel(); // Cancel any pending invocations
  //   };
  // }, [throttledFunction]); // Only run effect when the throttled function changes

  return (
    <>
      <div className='Trade_Detail'>
        {buyOrderRef?.current?.length > 0 && sellOrderRef?.current?.length && <DepthChart data={{ buy: buyOrderRef.current, sell: sellOrderRef.current }} midPrice={ltpValue.ltp - 0} />}
      </div>
    </>
  )
}

export default DepthvChart
