import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { SearchIcon, UserIcon } from '../../assets/Svg/SvgImages';
import CommonButton from '../CommonButton/CommonButton';
import "./ChatRoom.scss"
import lockicon from "../../assets/icons/lock.svg"
import ArrowsExpand from "../../assets/icons/arrows-expand.svg"
import sendicon from "../../assets/icons/send_icon.svg"
import Mujiicon from "../../assets/icons/smile_moji.svg"
import Arrowscompress from "../../assets/icons/arrows-compress.svg"

const ChatRoom = ({ show, handleClose }) => {
    const userData = [
        {
            namebtn: "Official",
            title: "Futures Chat...",
            content: "English Language chat room for futures traders",
        },
        {
            namebtn: "Official",
            title: "EHX Guild Chat...",
            content: "EHX guild chat room for guild members",
            iconclass: "d-block",
            activeuser: "d-block",
            icon: lockicon,
        },
        {
            namebtn: "Non-official",
            title: "Copy Trading Tips...",
            content: "General Trading Strategies Discussion",
        },
        {
            namebtn: "Non-official",
            title: "Trading Strategies...",
            content: "General Trading Strategies Discussion",
        },
        {
            namebtn: "Non-official",
            title: "Spot Trading...",
            content: "Spot Trading Chat room",
        },
        {
            namebtn: "Non-official",
            title: "Copy Trading Tips...",
            content: "General Trading Strategies Discussion",
        },
        {
            namebtn: "Non-official",
            title: "Trading Strategies...",
            content: "General Trading Strategies Discussion",
        },
        {
            namebtn: "Non-official",
            title: "Spot Trading...",
            content: "Spot Trading Chat room",
        },
    ];
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };
    return (
        <>
            <Offcanvas
                placement="bottom"
                className={
                    isActive
                        ? "Futures_Chat_Room Expand_box"
                        : "Futures_Chat_Room"
                }
                show={show}
                onHide={handleClose}
            >
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="Chat_User">
                        <div className="Chat_User_List">
                            <div className="Chat_User_List_Searchbar d-flex align-items-center">
                                <span>
                                    <UserIcon />
                                </span>
                                <div className="chat_Searchbar">
                                    <input
                                        type="text"
                                        name=""
                                        placeholder="Search Room Name"
                                        className="control_searchBar"
                                    />
                                    <SearchIcon />
                                </div>
                            </div>
                            <ul className="Chat_User_List_Boxs">
                                <li>
                                    <div className="d-flex align-items-center">
                                        <h5 className="d-flex align-items-center">
                                            Coming Soon...
                                        </h5>
                                    </div>
                                </li>
                                {/* {userData.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <div className='d-flex align-items-center'>
                                                <CommonButton title={item.namebtn} className="Official_btn" />
                                                <h5 className='d-flex align-items-center'><img className={`not_show ${item.iconclass}`} src={item.icon} alt='lock' /> {item.title}</h5>
                                            </div>
                                            <div className='mt-3 d-flex align-items-center justify-content-between'>
                                                <p>{item.content}</p>
                                                <h6 className={`not_show ${item.activeuser}`}>New <span></span></h6>
                                            </div>
                                        </li>
                                    )
                                })} */}
                            </ul>
                        </div>
                        <div className="Chat_User_Room">
                            <div className="Chat_User_Room_Header d-flex align-items-center">
                                <span
                                    className="Arrows-Expand"
                                    onClick={toggleClass}
                                >
                                    <img
                                        className="Expd1_icon"
                                        src={ArrowsExpand}
                                        alt="lock"
                                    />
                                    <img
                                        className="Expd2_icon"
                                        src={Arrowscompress}
                                        alt="lock"
                                    />
                                </span>
                                <h3>Futures Chat Room</h3>
                            </div>
                            <div className="Chat_User_Room_ChatBox">
                                <h6 style={{textAlign:"center",color:"#fff",marginTop:"20px"}}>Coming Soon...</h6>
                                {/* <ul>
                                    <li>
                                        <div className="User_Send_Msg Send_Msg d-flex align-items-center">
                                            <span className="User_pic">
                                                <UserIcon />
                                            </span>
                                            <div className="Chat_Content">
                                                <h6>
                                                    Lorem Ipsum has been the
                                                    industry's standard dummy
                                                    text ever since the 1500s,
                                                </h6>
                                                <p>
                                                    8:00 PM{" "}
                                                    <span>User-0923421</span>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="User_Send_Msg Receive_Msg d-flex align-items-center">
                                            <span className="User_pic">
                                                <UserIcon />
                                            </span>
                                            <div className="Chat_Content">
                                                <h6>
                                                    Lorem Ipsum has been the
                                                    industry's standard dummy
                                                    text ever since the 1500s,
                                                </h6>
                                                <p>
                                                    8:00 PM{" "}
                                                    <span>User-6783192</span>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="User_Send_Msg Send_Msg d-flex align-items-center">
                                            <span className="User_pic">
                                                <UserIcon />
                                            </span>
                                            <div className="Chat_Content">
                                                <h6>
                                                    Lorem Ipsum has been the
                                                    industry's standard dummy
                                                    text ever since the 1500s,
                                                </h6>
                                                <p>
                                                    8:00 PM{" "}
                                                    <span>User-0923421</span>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="User_Send_Msg Receive_Msg d-flex align-items-center">
                                            <span className="User_pic">
                                                <UserIcon />
                                            </span>
                                            <div className="Chat_Content">
                                                <h6>
                                                    Lorem Ipsum has been the
                                                    industry's standard dummy
                                                    text ever since the 1500s,
                                                </h6>
                                                <p>
                                                    8:00 PM{" "}
                                                    <span>User-6783192</span>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="User_Send_Msg Send_Msg d-flex align-items-center">
                                            <span className="User_pic">
                                                <UserIcon />
                                            </span>
                                            <div className="Chat_Content">
                                                <h6>
                                                    Lorem Ipsum has been the
                                                    industry's standard dummy
                                                    text ever since the 1500s,
                                                </h6>
                                                <p>
                                                    8:00 PM{" "}
                                                    <span>User-0923421</span>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul> */}
                            </div>
                            {/* <div className="Chat_User_Room_ChatInput">
                                <div className="Chat_ChatInput_Box d-flex align-items-center justify-content-between">
                                    <input
                                        type="text"
                                        className="send_msg_input"
                                        placeholder="Enter a message"
                                    />
                                    <div className="d-flex align-items-center">
                                        <span className="Moji_Icons">
                                            <img src={Mujiicon} alt="lock" />
                                        </span>
                                        <span className="send_msg_btn">
                                            <img src={sendicon} alt="lock" />
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default ChatRoom
