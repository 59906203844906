import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SubHeader.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { DownarrowIcon } from "../../../../assets/Svg/SvgImages";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { saveCheckBox } from "../../../../redux/_slices/user.slice";
import AllMarket from "../AllMarket/AllMarket";
import TradingPairs from "../AllMarket/TradingPairs";
import millify from "millify";
import {
  ltpUpDown,
  numberSystemFormate,
  showDecimal,
} from "../../../../_utils/comman";
import { showDecimalHead } from "../../../../services/HelperServices";
import { LTP_SIGNELS } from "../../../../constants/exchange.constant";
import { StarIconOutlined } from "../../../../assets/Svg/SvgImages";
import { saveFavPairsList } from "../../../../redux/_actions/user.action";
const SubHeader = () => {
  const [show, setShow] = useState(false);
  const [volume, setVolume] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const statisticsObj = useAppSelector((state) => state.exchange.statistics);
  const defaultStatistics = useAppSelector(
    (state) => state.exchange.defaultStatistics
  );

  const statistics =
    statisticsObj &&
    Object.keys(statisticsObj).length > 0 &&
    statisticsObj?.index_price
      ? statisticsObj
      : defaultStatistics &&
        Object.keys(defaultStatistics).length &&
        defaultStatistics?.index_price > 0
      ? defaultStatistics
      : "";

  const indexprice =
    statistics && statistics?.index_price ? statistics?.index_price : "";

  const markprice =
    statistics && statistics?.mark_price ? statistics?.mark_price : "";

  const currentPrice =
    statistics && statistics?.lastPrice ? statistics?.lastPrice : "";

  const funding =
    statistics && statistics?.funding_rate
      ? statistics?.funding_rate * 100
      : "";

  const selectedPairKey = useSelector((state) => state.user.selectedPairKey);
  const selectedPair = useAppSelector((state) => state.user.selectedPair);
  const pairList = useSelector((state) => state?.exchange?.listOfPairs);
  const user = useSelector((state) => state.user);

  const marketViewCheckBox = useSelector(
    (state) => state.user.marketViewCheckBox
  );

  const current_breakpoint = useSelector(
    (state) => state.layout.current_breakpoint
  );

  const orderBook = useSelector((state) => state.exchange.orderBook);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    let marketcheckbox = [...marketViewCheckBox];
    if (checked) {
      const marketIndex = marketcheckbox.findIndex((doc) => doc === name);
      marketcheckbox.splice(marketIndex, 1);
    } else {
      marketcheckbox.push(name);
    }
    dispatch(saveCheckBox(marketcheckbox));
  };

  const [currentCount, setCount] = useState("");

  let interval;
  const getCountdown = (target_date) => {
    let days, hours, minutes, seconds; // variables for time units
    let current_date = new Date().getTime();
    let seconds_left = (target_date - current_date) / 1000;
    seconds_left = seconds_left % 86400;
    hours = pad(parseInt(seconds_left / 3600));
    seconds_left = seconds_left % 3600;
    minutes = pad(parseInt(seconds_left / 60));
    seconds = pad(parseInt(seconds_left % 60));
    if (seconds < 0) {
      clearInterval(interval);
      return;
    }
    if (hours >= 0 && minutes >= 0 && seconds >= 0) {
      let finlTime = hours + ":" + minutes + ":" + seconds;
      setCount(finlTime);

      if (hours == 0 && minutes == 0 && seconds == 0) {
        let newdate = new Date().getTime() + 1000 * 3600 * 8;
        clearInterval(interval);
        interval = setInterval(function () {
          getCountdown(newdate);
        }, 1000);
      }
    }
  };

  const pad = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  useEffect(() => {
    if (statistics?.funding_countdown) {
      clearInterval(interval);
      interval = setInterval(function () {
        getCountdown(statistics?.funding_countdown);
      }, 1000);
    }
  }, [statistics?.funding_countdown]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFavorite = (data) => {
    dispatch(
      saveFavPairsList({ pair_name: selectedPair?.pair_name, type: "perp" })
    );
  };

  const getPairDataByName = (pairList, pairName) => {
      return pairList.find((pair) => pair.pair_name === pairName);
  }

  useEffect(() => {
      const selectedVolume = getPairDataByName(pairList, selectedPair?.pair_name);
      if (selectedVolume !== undefined) {
          setVolume(selectedVolume?.volume);
      }
  }, [pairList, selectedPair?.pair_name]);



  return (
      <>
          <AllMarket />
          <div className="SubHeader_V2 nondraggable_card">
              <div
                  className={`${
                      LTP_SIGNELS[
                          ltpUpDown(orderBook.currentLtp, orderBook.lastLtp)
                      ].color
                  } Ntp_Value`}
              >
                  <h5>
                      $
                      {numberSystemFormate(
                          orderBook?.currentLtp != 0
                              ? orderBook?.currentLtp
                              : currentPrice,
                          selectedPair?.price_scale
                      )}
                  </h5>
              </div>
              <div className="Ticker_data">
                  <ul id="subHeaderList">
                      <li>
                          <span>Index price</span>
                          <p>
                              $
                              {numberSystemFormate(
                                  indexprice,
                                  selectedPair.price_scale
                              )}
                          </p>
                      </li>
                      {/* <li>
              <span>Open Interest</span>
              <p>132.45 <small>BTC</small></p>
            </li>
            <li>
              <span>Oracle price</span>
              <p>$38,624</p>
            </li> */}
                      <li>
                          <span>Funding Countdown</span>
                          <p className="text_red">
                              {funding ? Number(funding).toFixed(4) : 0}%{" "}
                              {currentCount ? currentCount : 0}
                          </p>
                      </li>
                      <li>
                          <span>24h change</span>
                          <p
                              className={`${
                                  statistics?.change_24_h > 0
                                      ? "text_green"
                                      : "text_red"
                              }`}
                          >
                              {/* $924.46  */}
                              {statistics?.change_24_h
                                  ? showDecimal(
                                        Number(statistics?.change_24_h) * 100,
                                        selectedPair.price_scale
                                    )
                                  : defaultStatistics?.change_24_h
                                  ? showDecimal(
                                        Number(defaultStatistics?.change_24_h) *
                                            100,
                                        selectedPair.price_scale
                                    )
                                  : "0"}
                              %
                          </p>
                      </li>
                      <li>
                          <span>24h High</span>
                          <p className="text_green">
                              {statistics?.high_24h
                                  ? statistics?.high_24h < 1
                                      ? statistics?.high_24h
                                      : numberSystemFormate(
                                            showDecimalHead(
                                                statistics?.high_24h,
                                                selectedPair?.price_scale
                                            )
                                        )
                                  : defaultStatistics?.high_24h < 1
                                  ? defaultStatistics?.high_24h
                                  : defaultStatistics?.high_24h
                                  ? numberSystemFormate(
                                        showDecimalHead(
                                            defaultStatistics?.high_24h,
                                            selectedPair?.price_scale
                                        )
                                    )
                                  : 0}
                          </p>
                      </li>
                      <li>
                          <span>24h Low</span>
                          <p className="text_green">
                              {statistics?.low_24h
                                  ? statistics?.low_24h < 1
                                      ? statistics?.low_24h
                                      : numberSystemFormate(
                                            showDecimalHead(
                                                statistics?.low_24h,
                                                selectedPair?.price_scale
                                            )
                                        )
                                  : defaultStatistics?.low_24h < 1
                                  ? defaultStatistics?.low_24h
                                  : defaultStatistics?.low_24h
                                  ? numberSystemFormate(
                                        showDecimalHead(
                                            defaultStatistics?.low_24h,
                                            selectedPair?.price_scale
                                        )
                                    )
                                  : 0}
                          </p>
                      </li>
                      {/* <li>
              <span>1hr funding</span>
              <p className="text_green">0.001250%</p>
            </li> */}
                      <li>
                          <span>24h volume</span>
                          <p>
                              $
                              {selectedPair?.volume > 0
                                  ? millify(volume, {
                                        precision: 2,
                                        // locales: "de-DE",
                                    })
                                  : millify(volume)}{" "}
                          </p>
                        {/* <p>
                          $
                          {statistics?.volume_24h
                            ? numberSystemFormate(
                                showDecimal(
                                  statistics?.volume_24h,
                                  selectedPair?.price_scale
                                )
                              )
                            : defaultStatistics?.volume_24h
                            ? numberSystemFormate(
                                showDecimalHead(
                                  defaultStatistics?.volume_24h,
                                  selectedPair?.price_scale
                                )
                              )
                            : 0}
                        </p>                           */}
                      </li>
                      {/* <li>
              <span>24h trades</span>
              <p>2,719</p>
            </li> */}
                  </ul>
              </div>
              <span
                  className={`${"Star_Data"} ${
                      user?.favPairList?.find(
                          (doc) => doc === selectedPair.pair_name
                      ) && "show"
                  }`}
                  onClick={handleFavorite}
              >
                  <StarIconOutlined />
              </span>
              <div className="TradingPairs_List">
                  <TradingPairs show={show} handleClose={handleClose} />
              </div>
          </div>
      </>
  );
};
export default SubHeader;
