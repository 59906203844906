import React from 'react'
import "./Breadcrumbs.scss"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import homeicon from "../../../../assets/images/admin/home.svg";
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ pagename, mainpage, pagelink, pagelinktwo, pagenametwo, className }) => {
    return (
        <>
            <div className='Common_Breadcrumbs'>
                <div className='Breadcrumb'>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={pagelink}><img src={homeicon} alt='home icon' /> {mainpage}</Link></li>
                        <li className={`breadcrumb-item displaynone ${className}`}><Link to={pagelinktwo}>{pagenametwo}</Link></li>
                        <li className="breadcrumb-item active">{pagename}</li>
                    </ol>
                </div>
                {/* <Breadcrumb>
                    <Breadcrumb.Item ><img src={homeicon} alt='' /> {mainpage}</Breadcrumb.Item>
                    <Breadcrumb.Item active>{pagename}</Breadcrumb.Item>
                </Breadcrumb> */}
            </div>
        </>
    )
}

export default Breadcrumbs
