import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Header } from "../../../common";
import { useDispatch } from "react-redux";
import "./Exchange.scss";
import "../../../common/Mobilenav/MobilenavStyle.scss";
import { saveFlashOrders } from "../redux/_slices/exchange.slice";
import ResponsiveExchange from "./ResponsiveLayout";
import { saveCurrentBrackpoint } from "../../../redux/_slices/layout.slice";
// import Workspace from "./Workspace/Workspace";
import { usePairListAPi } from "../hooks/hooks.service";
import { useSocketService } from "../hooks/socket.hooks.service";
import MobileLayout from "./MobileLayout";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import ExFooter from "../../../common/ExFooter/ExFooter";
import FavouriteTab from "./BuySell/FavouriteTab/FavouriteTab";
import { useParams } from "react-router-dom";

import {
    saveOrderSize,
    savePairDecimals,
} from "../redux/_slices/exchange.slice";
import {
    saveSelectedPair,
    saveSelectedPairKey,
} from "../../../redux/_slices/user.slice";

import {
    getDecimalString,
} from "../utils/comman";

const Exchange = () => {
  const { pair } = useParams(); 
  let flashBarInterval = null;

  const dispatch = useDispatch();

  const lg = useMediaQuery({ query: "(min-width: 1480px)" });
  const md = useMediaQuery({ query: "(min-width: 991px)" });
  const sm = useMediaQuery({ query: "(min-width: 767px)" });
  const xs = useMediaQuery({ query: "(min-width: 320px)" });

  const current_breakpoint = useSelector(
    (state) => state.layout.current_breakpoint
  );

  const flashOrders = useSelector((state) => state.exchange?.flashOrders);
  const favPairList = useSelector((state) => state.user?.favPairList);
  const pairList = useSelector((state) => state?.exchange?.listOfPairs);
  const selectedPairKey = useSelector((state) => state.user.selectedPairKey);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const socketHook = useSocketService(splitLocation[1]);
  // Use Custume hooks
  usePairListAPi();
  // useSocketService(splitLocation[1]);

  const handleBreakpoint = (_lg, _md, _sm, _xs) => {
    if (!_lg && !_md && !_sm && _xs) {
      // console.log(" current barakpoint : xs");
      dispatch(saveCurrentBrackpoint("xs"));
    } else if (!_lg && !_md && _sm && _xs) {
      // console.log(" current barakpoint : sm");
      dispatch(saveCurrentBrackpoint("sm"));
    } else if (!_lg && _md && _sm && _xs) {
      // console.log(" current barakpoint : md");
      dispatch(saveCurrentBrackpoint("md"));
    } else if (_lg && _md && _sm && _xs) {
      // console.log(" current barakpoint : lg");
      dispatch(saveCurrentBrackpoint("lg"));
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (flashOrders?.length > 0) {
      flashBarInterval = setInterval(() => {
        if (flashOrders?.length === 0) {
          clearInterval(flashBarInterval);
          flashBarInterval = null;
        }
        dispatch(saveFlashOrders("sub", {}));
      }, 100);
    }

    return () => {
      clearInterval(flashBarInterval);
      flashBarInterval = null;
    };
  }, [flashOrders?.length]);

  useEffect(() => {
    handleBreakpoint(lg, md, sm, xs);
  }, [lg, md, sm, xs]);


useEffect(() => {
    if (pair && pairList && pairList.length > 0) {
        let pairName = pair.toUpperCase() + "_USDT";
        const pairData = pairList?.find((doc) => doc.pair_name === pairName);

        if (pairData) {
            const priceDecimalLength = getDecimalString(
                pairData.min_price_increment,
                pairData.price_decimal
            );

            const baseDecimalLength = getDecimalString(
                pairData.min_size_increment,
                pairData.base_decimal
            );
            dispatch(savePairDecimals({ priceDecimalLength, baseDecimalLength }));
           // dispatch(savePrevSelectedPairKey(selectedPairKey));
            dispatch(saveSelectedPairKey(pairName));
            dispatch(saveOrderSize(""));
            dispatch(saveSelectedPair(pairData));
        }
    }
}, [pairList, pair]);


  return (
    <>
      <div className="V2_Exchange_Layout">
        {/* <Workspace /> */}
        <div className="V2_Exchange p-0">
          <Header />
          <div className="V2_Exchange_Wrapper grid-stack">
            <div className="V2_Exchange_row">
              {favPairList.length > 0 && <FavouriteTab />}
              {current_breakpoint === "sm" || current_breakpoint === "xs" ? (
                <MobileLayout />
              ) : (
                <ResponsiveExchange />
              )}
            </div>
          </div>
          <ExFooter />
        </div>
      </div>
    </>
  );
};

export default Exchange;
