import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./Orderbook.scss";
import OrderBookData from "./OrderBookData";
import { WebsocketClient } from "bybit-api";
import { useDispatch } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import {
  saveStatistics,
  saveDefaultStatistics,
  saveLtp,
  saveMarketTrades,
} from "../../redux/_slices/exchange.slice";
import { DEFAULT_PAIR } from "../../../../constants/constants";
import { savePrevSelectedPairKey } from "../../../../redux/_slices/user.slice";
import { throttle } from "lodash";

const WEBSOCKET_URL = {
  CONTRACT: {
    PUBLIC: "wss://stream.bybit.com/contract/usdt/public/v3",
    PRIVATE: "wss://stream.bybit.com/contract/private/v3",
  },
};

const BYBIT_API_TOPIC_NAMES = {
  LINEAR: {},
  DERIVATIVES: {},
  MARKET: {
    CONTRACT_USDT: "contractUSDT",
  },
};

const wsconfig = {
  key: "6yYjVA2N41KBrrks80",
  market: BYBIT_API_TOPIC_NAMES.MARKET.CONTRACT_USDT,
  wsUrl: WEBSOCKET_URL.CONTRACT.PUBLIC,
};
const ws = new WebsocketClient(wsconfig);

const OrderBook = ({ activeTab, handleOnSelect, orderBookRecord }) => {
  const [sellOrderBook, setSellOrderBook] = useState([]);
  const [buyOrderBook, setBuyOrderBook] = useState([]);
  const [sellDepth, setSellDepth] = useState([]);
  const [buyDepth, setBuyDepth] = useState([]);
  const [ltpPrice, setLtpPrice] = useState(0);

  const sellOrderBookRef = useRef([]);
  const buyOrderBookRef = useRef([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectPair = useAppSelector((state) => state?.user?.selectedPairKey);
  const selectedPairKey = selectPair || DEFAULT_PAIR;

  useEffect(() => {
    const initializePair = async () => {
      // Clearing previous state
      setBuyOrderBook([]);
      setSellOrderBook([]);
      setLtpPrice(0);
      dispatch(saveLtp(0, 0));
      dispatch(saveMarketTrades([]));
      ws.removeAllListeners();
      await unsubscribeByBit(selectedPairKey);
      ws.unsubscribe("update");
    };
    initializePair();

    return () => {
      initializePair();
    };
  }, [selectedPairKey]);

  useEffect(() => {
    const subscribeWebSocket = async () => {
      await initializeWebSocket(selectedPairKey);
    };

    subscribeWebSocket();

    return () => {
      ws.unsubscribe("update");
    };
  }, [selectedPairKey]);

  const initializeWebSocket = async (selectedPair) => {
    // WebSocket initialization code
    // Event listeners setup
  };

  const unsubscribeByBit = async (selectedPair) => {
    // Unsubscribe code
  };

  const handleBuyThrottled = throttle((getDtOneSingle) => {
    // Handling buy orders
  }, 100);

  const handleClickThrottled = throttle((getDtOneSingle) => {
    // Handling sell orders
  }, 100);

  const handleTradeThrottled = throttle((getDta) => {
    // Handling trade data
  }, 100);

  const handleTickerThrottled = throttle((extdata, type) => {
    // Handling ticker data
  }, 100);

  return (
    <div className="orderbookHeader">
      <Tabs
        onSelect={handleOnSelect}
        className=""
        transition={false}
        unmountOnExit={true}
        mountOnEnter={true}
      >
        <Tab eventKey="all" title="All">
          <OrderBookData
            orderBookSell={sellOrderBook}
            orderBookBuy={buyOrderBook}
            side="BS"
          />
        </Tab>
        <Tab className="Asks_tab" eventKey="asks" title="Asks">
          <OrderBookData orderBookSell={sellOrderBook} side="S" />
        </Tab>
        <Tab className="Bids_tab" eventKey="bids" title="Bids">
          <OrderBookData orderBookBuy={buyOrderBook} side="B" />
        </Tab>
      </Tabs>
    </div>
  );
};

export default OrderBook;
