import React, { memo, useEffect, useState } from "react";
import "./DashboardMoveFunds.scss";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import CommonButton from "../../../../common/CommonButton/CommonButton";
import CrossIcon from "../../../../assets/icons/red_cross.svg";
import {
    amountValidationOnInput,
    showDecimal,
} from "../../../../_utils/comman";
import { useDispatch } from "react-redux";
import { toastAlert, toastMsg } from "../../../../common/Toast/Toast";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import TxnSuccessfulModal from "../../../Exchange/common/TxnSuccessModal";
import { inputDecimal } from "../../../../services/HelperServices";
import { TRADE_OPTIONS } from "../../../../constants/wallet";
import { useTolalUsdBalance } from "../../../Exchange/hooks/hooks.service";
import { ApiServices } from "../../../Exchange/services/api.services";
import { saveUserSpotBalances } from "../../../Exchange/redux/_slices/exchange.slice";
import swapIcon from "../../../../assets/icons/swap.svg";

const DashboardMoveFunds = ({ handleClose, show }) => {
    const dispatch = useDispatch();
    const [showsuccessful, setShowsuccessful] = useState(false);
    const [amount, setAmount] = useState("");
    const [error, setError] = useState(null);
    const [coinList, setCoinList] = useState([]);
    const [selectedCoin, setSelectedCoin] = useState("");
    const walletBalance = useAppSelector(
        (state) => state.user.userWalletBalances
    );
    const { totalUsdBalance } = useTolalUsdBalance("single");
    const [wallet, setWallet] = useState({
        from: TRADE_OPTIONS[0].label,
        to: TRADE_OPTIONS[1].label,
    });

    const handleClosesuccessful = () => setShowsuccessful(false);
    const walletAddress = useAppSelector((state) => state.user.walletAddress);

    const amountMaxOnInput = async (event) => {
        handleOnChangeAmount({
            target: {
                value:
                    wallet.from === TRADE_OPTIONS[1].label
                        ? walletBalance?.derivativeTransferBalance
                            ? showDecimal(
                                  walletBalance?.derivativeTransferBalance,
                                  2
                              )
                            : 0
                        : walletBalance?.spotTransferBalance
                        ? showDecimal(walletBalance?.spotTransferBalance, 2)
                        : 0,
            },
        });
    };
    /**
     * Handle User transfer funds
     */
    const handleTransfer = async () => {
        let balance =
            wallet.from === TRADE_OPTIONS[1].label
                ? walletBalance?.derivativeTransferBalance
                    ? walletBalance?.derivativeTransferBalance
                    : 0
                : walletBalance?.spotTransferBalance
                ? walletBalance?.spotTransferBalance
                : 0;
        // console.log("test", balance);
        if (Number(balance) <= 0) {
            toastMsg.error(
                "You have insufficient balance please add funds to your account!",
                { toastId: "error1" }
            );
            return;
        }
        // setIsLoader(true);
        try {
            let dataObj = {
                address: walletAddress,
                amount: amount,
            };
            if (
                wallet.to === TRADE_OPTIONS[0].label &&
                wallet.from === TRADE_OPTIONS[1].label
            ) {
                dataObj["withdraw"] = true;
            }
            // console.log(dataObj);
            const response = await toastAlert.waitingPromis(
                ApiServices.transferSpotToContract(dataObj),
                "Awaiting confirmation...",
                "Transfer Successful.",
                "funds"
            );
            if (response?.status == 202) {
                // props.assetsBalanceApiFunction();
            }
            if (response) {
                handleClose();
                setTimeout(() => {
                    // setIsLoader(false);
                }, 2500);

                const params = {
                    address: walletAddress,
                };
                let balances = await ApiServices.getAssestsBalance(params);
                dispatch(saveUserSpotBalances(balances?.data));
            }
            if (response == undefined) {
                setTimeout(() => {
                    // setIsLoader(false);
                }, 2500);
            }
        } catch (error) {
            setTimeout(() => {
                // setIsLoader(false);
            }, 2500);
            throw error;
        }
    };

    /**
     * Set max Balance for deposit or withdral
     */
    const handleMax = async () => {
        if (wallet.from === TRADE_OPTIONS[1]) {
            setAmount(showDecimal(walletBalance?.derivativeTransferBalance, 1));
            if (walletBalance?.derivativeTransferBalance > 0) {
                setError("");
            }
        } else {
            setAmount(showDecimal(walletBalance?.spotTransferBalance, 2));
            if (walletBalance?.spotTransferBalance > 0) {
                setError("");
            }
        }
    };

    /**
     * handle on change for input amount
     * @param {*} event
     */
    const handleOnChangeAmount = (event) => {
        let maxAmount;
        if (wallet.from === TRADE_OPTIONS[1].label) {
            maxAmount = walletBalance?.derivativeTransferBalance;
        } else {
            maxAmount = walletBalance?.spotTransferBalance;
        }
        const { value } = event.target;
        let valData = value;
        if (wallet.from === TRADE_OPTIONS[1].label) {
            valData = inputDecimal(value, 1);
        } else {
            valData = inputDecimal(value, 2);
        }
        // console.log(">>>>>>>>", event.target.value, Number(valData));
        if (Number(valData) === 0) {
            setError("Please enter amount greater than 0");
        } else if (Number(valData) > Number(maxAmount)) {
            if (Number(maxAmount) === 0) {
                setError("You have no balance to transfer");
            } else {
                setError(
                    `Entered amount shouldn't be more than maximum amount`
                );
            }
        } else {
            setError("");
        }
        setAmount(valData);
    };

    const handleWalletChange = (event, type) => {
        let from = event;
        let to;
        if (from === TRADE_OPTIONS[1].label) {
            to = TRADE_OPTIONS[0].label;
        } else {
            to = TRADE_OPTIONS[1].label;
        }
        setWallet({ from, to });
        setAmount("");
    };

    const swapWallets = () => {
        let from;
        let to;
        if (wallet.from === TRADE_OPTIONS[1].label) {
            to = TRADE_OPTIONS[1].label;
            from = TRADE_OPTIONS[0].label;
        } else {
            to = TRADE_OPTIONS[0].label;
            from = TRADE_OPTIONS[1].label;
        }
        setWallet({ from, to });
    };

    const getCoinList = async () => {
        let from = getAccountType(wallet.from);
        let to = getAccountType(wallet.to);
        if (walletAddress) {
            let transferableCoins = await ApiServices.getTransferableCoinList({
                address: walletAddress,
                fromAccount: from,
                toAccount: to,
            });

            if (transferableCoins) {
                setCoinList(transferableCoins?.data?.list);
                setSelectedCoin(transferableCoins?.data?.list[0]);
            }
        }
    };

    const getAccountType = (accountType) => {
        switch (accountType) {
            case "Funding Wallet":
                return "FUND";
            case "Derivative Wallet":
                return "CONTRACT";
            default:
                return "FUND";
        }
    };

    useEffect(() => {
        getCoinList();
    }, [wallet, walletAddress]);

    return (
        <>
            <Modal
                className="add_profit_Modal deposit_modal"
                centered
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Move Funds</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="pt-2">
                        <Col xs={12}>
                            <div className="Input_Group mb-3">
                                <label>From</label>
                                <Dropdown
                                    className="profit_dropdown"
                                    onSelect={(event) =>
                                        handleWalletChange(event, "from")
                                    }
                                >
                                    <Dropdown.Toggle
                                        variant=""
                                        id="dropdown-basic"
                                    >
                                        <p>{wallet.from}</p>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {TRADE_OPTIONS.map((data, i) => {
                                            return (
                                                <Dropdown.Item
                                                    eventKey={data.label}
                                                    key={i}
                                                    to="javascript:;"
                                                    active={
                                                        data.label ===
                                                        wallet.from
                                                    }
                                                >
                                                    <p>{data.label}</p>
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <span onClick={swapWallets} className="swapIcon">
                                <img src={swapIcon} alt="swap icon" />
                            </span>
                            <div className="Input_Group mb-3">
                                <label>To</label>
                                <Dropdown className="profit_dropdown" disabled>
                                    <Dropdown.Toggle
                                        variant=""
                                        id="dropdown-basic"
                                    >
                                        <p>{wallet.to}</p>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {TRADE_OPTIONS.map((data, i) => {
                                            return (
                                                <Dropdown.Item
                                                    eventKey={data.label}
                                                    key={i}
                                                    to="javascript:;"
                                                    active={
                                                        data.label ===
                                                        wallet.from
                                                    }
                                                >
                                                    <p>{data.label}</p>
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="Input_Group mb-3">
                                <label>Coin</label>
                                <Dropdown
                                    className="profit_dropdown"
                                    onSelect={(event) => setSelectedCoin(event)}
                                >
                                    <Dropdown.Toggle
                                        variant=""
                                        id="dropdown-basic"
                                    >
                                        <p>{selectedCoin}</p>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {coinList.map((data, i) => {
                                            return (
                                                <Dropdown.Item
                                                    eventKey={data}
                                                    key={i}
                                                    to="javascript:;"
                                                    active={
                                                        data === selectedCoin
                                                    }
                                                >
                                                    <p>{data}</p>
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="Input_Group">
                                <label>
                                    Amount Available:{" "}
                                    <span>
                                        {wallet.from === TRADE_OPTIONS[1].label
                                            ? walletBalance?.derivativeTransferBalance
                                                ? showDecimal(
                                                      walletBalance?.derivativeTransferBalance,
                                                      2
                                                  )
                                                : 0
                                            : walletBalance?.spotTransferBalance
                                            ? showDecimal(
                                                  walletBalance?.spotTransferBalance,
                                                  2
                                              )
                                            : 0}{" "}
                                        USDT
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    className="input-type2"
                                    placeholder="0.00"
                                    value={amount}
                                    onChange={(event) => {
                                        if (
                                            /^\d*(\.\d{0,8})?$/.test(
                                                event.target.value
                                            )
                                        ) {
                                            setError("");
                                            handleOnChangeAmount(event);
                                        }
                                    }}
                                    onInput={(event) => {
                                        amountValidationOnInput(event, 8);
                                    }}
                                />
                                <span className="span-max-2">
                                    <span
                                        className="maxAmount"
                                        onClick={amountMaxOnInput}
                                    >
                                        Max{" "}
                                    </span>
                                    <span className="selectedCoin">
                                        {selectedCoin}
                                    </span>
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <hr className="common_hr my-4" />

                    {error && (
                        <div className="error_msg d-flex align-items-center mt-4">
                            <img src={CrossIcon} alt="cross" />
                            <p className="mb-0 text_red ms-3">
                                {error}
                                {/* <span className="d-block">Please fund your account.</span> */}
                            </p>
                        </div>
                    )}

                    <div className="mt-4 pt-2">
                        <CommonButton
                            disabled={error || Number(amount) <= 0}
                            onClick={() => handleTransfer()}
                            // onClick={handleDepositSubmit}
                            title="Confirm"
                            className="w-100"
                        />
                    </div>
                </Modal.Body>
            </Modal>
            <TxnSuccessfulModal
                show={showsuccessful}
                amount={amount}
                modalType="Deposit"
                handleClose={handleClosesuccessful}
            />
            {/* <DepositConversionModal
        show={showsuccessful}
        handleClose={handleClosesuccessful}
      /> */}
        </>
    );
};

export default memo(DashboardMoveFunds);
