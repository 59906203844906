import React, { useEffect, useMemo, useState } from "react";
import "./PlaceOrder.scss";
import WalletIcon from "../../../../../assets/images/bl_Wallet_icon.svg";
import SwitchButton from "../../../../../common/SwitchButton/SwitchButton";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import greenArrow from "../../../../../assets/icons/greenarrow.svg";
import redArrow from "../../../../../assets/icons/redarrow.svg";
import MarginRatio from "../../MarginRatio/MarginRatio";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux.hooks";
import { showDecimal } from "../../../../../_utils/comman";
import {
  saveMakrTkrFee,
  saveMarketType,
  saveOrderPref,
  saveOrderPrice,
  saveOrderSize,
  saveOtherFee,
} from "../../../redux/_slices/exchange.slice";
import { useSelector } from "react-redux";
import {
  useTolalUsdBalance,
  useUserAllCoinBalances,
} from "../../../hooks/hooks.service";
import { useTranslation } from "react-i18next";
import { toastMsg } from "../../../../../common/Toast/Toast";
import { ApiServices } from "../../../services/api.services";
import {
  saveLeverage,
  saveUserLeverage,
} from "../../../../../redux/_slices/user.slice";
import OrderType from "./OrderType";
import LeverageSlider from "../../../../../common/LeverageSlider/LeverageSlider";
import { getMarkPrice } from "../../../utils/comman";
import TPSLModal from "./AddProfitBSModal/TPSLModal";
import {
  calculateLiquidationPrice,
  getFeeByPercent,
} from "../../../../../services/HelperServices";
import { Form } from "react-bootstrap";

const PlaceOrder = (props) => {
  const triggerByMemo = [
    {
      value: "LastPrice",
      label: "Last Traded Price",
    },
    {
      value: "MarkPrice",
      label: "Mark Price",
    },
    {
      value: "IndexPrice",
      label: "Index Price",
    },
  ];

  const { type } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isActive, setActive] = useState(false);
  const [price, setPrice] = useState("");
  const [inputError, setInputError] = useState({
    price: false,
    size: false,
  });
  const leverage = useSelector((state) => state.user.leverage);
  const [range, setRange] = useState(leverage ? leverage : 1);
  const [size, setSize] = useState("");
  const [checkOrder, setCheckOrder] = useState(false);
  const [levSlider, setLevSlider] = useState(true);
  const [profitAmount, setProfitAmount] = useState("");
  const [lossAmount, setLossAmount] = useState("");
  const [showTpSl, setShowTpSl] = useState(false);
  const [activeCta, setActiveCta] = useState("Buy");
  const [rangetp, setRangetp] = useState(0);
  const [rangesl, setRangesl] = useState(0);
  const [roiLoss, setLossRoi] = useState("");
  const [profitTriggerValue, setProfitTriggerValue] = useState(
    triggerByMemo[0]
  );
  const [lossTriggerValue, setLossTriggerValue] = useState(triggerByMemo[0]);

  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const orderSize = useAppSelector((state) => state.exchange.orderSize);
  const currentPair = useSelector((state) => state.user.currentPair);
  const selectedPairKey = useSelector((state) => state.user.selectedPairKey);
  const selectedPair = useSelector((state) => state.user.selectedPair);
  const activeCoins = useSelector((state) => state.exchange.activeCoins);
  const chainId = useSelector((state) => state.user.chainId);
  const orderPrice = useSelector((state) => state.exchange.orderPrice);
  const listOfPairs = useSelector((state) => state?.exchange.listOfPairs);
  const userPaymentCurrency = useSelector(
    (state) => state.exchange.userPaymentCurrency
  );
  const currentLtp = useSelector(
    (state) => state.exchange.orderBook.currentLtp
  );
  const orderPrefDropdown = useSelector((state) => state.exchange.orderPref);

  const leverageBar = useAppSelector((state) => state.user.leverageInfo);
  const userLeverage = useAppSelector((state) => state.user.userLeveragee);
  const leverageInfo = useAppSelector((state) => state.exchange.minMaxLeverage);
  const walletBalance = useAppSelector(
    (state) => state.user.userWalletBalances
  );
  const coinArray = selectedPairKey.split("_");
  const [selectedOption, setSelectedOption] = useState(coinArray[0]);
  let decimalLimitAmount = currentPair?.min_order_qty.toString().includes(".")
    ? currentPair?.min_order_qty.toString().split(".")
    : "";
  const { userBalance, getBalances } = useUserAllCoinBalances();
  const [minMaxLeverage, setMinMaxLeverage] = useState(leverageInfo);
  const [profitTriggerPrice, setProfitTriggerPrice] = useState("");
  const [lossTriggerPrice, setLossTriggerPrice] = useState("");
  const [selectedCoinOption, setSelectedCoinOption] = useState(null);
  const [checkedTpsl, setCheckedprofit] = useState(false);
  const [finalAmount, setFinalAmount] = useState(0);
  const [ROIValue, setROIValue] = useState("");
  const [profitUsdt, setProfitUsdt] = useState("");
  const [lossUsdt, setLossUsdt] = useState("");
  const [side, setSide] = useState(0);
  const [newROILoss, setNewROILoss] = useState("");
  const [successFlag, setSuccessFlag] = useState(false);
  const { totalUsdBalance } = useTolalUsdBalance("single");
  const [mmRate, setMMRate] = useState("");
  const makrTkrFee = useSelector((state) => state.exchange.makrTkrFee);

  const pairList = useSelector((state) => state.exchange.pairList);
  const userAddress = useSelector((state) => state?.user?.walletAddress);

  const triggerBy = useMemo(() => triggerByMemo, []);

  const pairDetails = pairList?.find(
    (item) => item.pair_name == selectedPairKey
  );

  const toFix = (value, n) => {
    const v = value ? value.toString().split(".") : "0";
    if (n <= 0) return v[0];
    if (v.length == 1) return Number(v[0]).toFixed(n);
    let f = v[1] || "";
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < 2) f += "0";
    return `${v[0]}.${f}`;
  };

  useEffect(() => {
    if (walletAddress) {
      getUnifiedTradingBalance();
    }
  }, [walletAddress]);

  useEffect(() => {
    if (profitAmount || lossAmount) {
      getFeeAmount();
    }
  }, [profitAmount, lossAmount]);

  const getUnifiedTradingBalance = async () => {
    let unifiedBalance = await ApiServices.getUnifiedTrading({
      address: walletAddress,
    });
    setMMRate(unifiedBalance?.data?.accountMMRate);
  };

  const getFeeAmount = () => {
    let buyMaker = 0;
    let buyTaker = 0;

    let lossFee = side === 0 ? makrTkrFee?.cts : makrTkrFee?.glr;

    if (lossAmount) {
      buyTaker = getFeeByPercent(orderSize, lossAmount, lossFee, leverage);
    }

    let finalSum =
      Number(showDecimal(buyMaker, 8)) + Number(showDecimal(buyMaker, 8));
    setFinalAmount(showDecimal(finalSum, 8));
    if (Number(showDecimal(finalSum, 8)) > totalUsdBalance) {
      toastMsg.error(
        "You have insufficient balance please add funds to your account!",
        { toastId: "error1" }
      );
    }
  };

  const setTpslHandler = async () => {
    let ltp = getMarkPrice(selectedPairKey, listOfPairs);
    const profitCompPrice =
      Number(orderPrice) > Number(ltp) ? Number(orderPrice) : Number(ltp);
    const lossCompPrice =
      Number(orderPrice) < Number(ltp) ? Number(orderPrice) : Number(ltp);

    const compProfitPriceName =
      Number(orderPrice) > Number(ltp) ? "OrderPrice" : "LTP";
    const compLossPriceName =
      Number(orderPrice) < Number(ltp) ? "OrderPrice" : "LTP";
    if (
      (profitAmount === "" || Number(profitAmount) > Number(profitCompPrice)) &&
      (lossAmount === "" || Number(lossAmount) < Number(lossCompPrice))
    ) {
      try {
        let dataObj = {
          isEdit:
            makrTkrFee?.stop_loss > 0 ||
            makrTkrFee?.stopLoss > 0 ||
            makrTkrFee?.take_profit > 0 ||
            makrTkrFee?.takeProfit > 0
              ? true
              : false,
          address: userAddress,
          takeProfit: profitAmount,
          stopLoss: lossAmount,
          symbol: selectedPairKey,
          slTriggerBy: lossTriggerValue.value,
          tpTriggerBy: profitTriggerValue.value,
        };

        const response = await ApiServices.takeProfiStoptLoss(dataObj);
        if (response) {
          toastMsg.success(response?.message);
          setSuccessFlag(true);
          setTimeout(() => {
            setSuccessFlag(false);
          }, 2000);
          setLossAmount("");
          setProfitAmount("");
          setLossUsdt("");
          setProfitUsdt("");
          setNewROILoss("");
          setROIValue("");
          setLossRoi("");
          // handleClose();
        }
      } catch (error) {
        //   setTimeout(() => {}, 2500);
        toastMsg.error(error.data.message);
      }
    } else {
      if (
        profitAmount !== "" &&
        Number(profitAmount) < Number(profitCompPrice)
      ) {
        toastMsg.error(
          `Take Profit USDT must be greater than ${compProfitPriceName}`
        );
      }
      if (lossAmount !== "" && Number(lossAmount) > Number(lossCompPrice)) {
        toastMsg.error(
          `Stop Loss USDT must be less than  ${compLossPriceName}`
        );
      }
      // toastMsg.error(
      //   "Entered take profit USDT or stop loss USDT value is not valid"
      // );
    }
  };

  const toggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    dispatch(saveMarketType(type));
    if (type === "M") {
      dispatch(saveOrderPrice(currentLtp));
    }
  }, [type]);

  useEffect(() => {
    setMinMaxLeverage(leverageInfo);
  }, [leverageInfo]);

  const getEternaFee = async () => {
    let data = {
      side: "",
    };
    const response = await ApiServices.mkrTkrFee();
    if (response && response.status === 200) {
      dispatch(saveMakrTkrFee(response?.data));
    } else if (response) {
      toastMsg.error(response.message, { toastId: "errormsg" });
    }

    const responseOtherFee = await ApiServices.otherFee();
    if (responseOtherFee && responseOtherFee.status === 200) {
      dispatch(saveOtherFee(responseOtherFee?.data));
    } else if (responseOtherFee) {
      toastMsg.error(response.message, { toastId: "errormsg" });
    }
  };

  useEffect(() => {
    dispatch(saveUserLeverage(leverageBar?.leverage));
  }, [leverageBar]);

  useEffect(() => {
    getEternaFee();
  }, []);

  const handleOnChange = (event, type) => {
    if (type == "last") {
      setPrice(event);
      setInputError({ ...inputError, price: false });
      dispatch(saveOrderPrice(event));
    } else {
      const { name, value } = event.target;

      let valData;

      if (
        decimalLimitAmount.length > 0 &&
        name == "size" &&
        selectedOption != "USDT"
      ) {
        valData = showDecimal(value, decimalLimitAmount[1].length);
      } else if (
        value?.toString().includes(".") &&
        name == "size" &&
        selectedOption != "USDT"
      ) {
        return;
      } else {
        // console.log("selectedPair", selectedPair?.price_scale, value);
        if (selectedOption == "USDT") {
          valData = showDecimal(value, 2);
        } else {
          valData = showDecimal(value, selectedPair?.price_scale);
        }
      }

      if (name === "price") {
        valData = showDecimal(value, selectedPair?.price_scale);
        setPrice(valData);
        setInputError({ ...inputError, price: false });
        dispatch(saveOrderPrice(valData));
      } else {
        setInputError({ ...inputError, size: false });
        setSize(valData);
        dispatch(saveOrderSize(valData));
      }
    }
  };

  // useEffect(() => {
  //   if (currentPrice) {
  //     setPrice(currentPrice);
  //     dispatch(saveOrderPrice(currentPrice));
  //   }
  // }, [currentPrice]);

  // Handle order validation
  const orderValidation = () => {
    if (orderPrice && Number(orderPrice) <= 0) {
      setInputError({ size: false, price: true });
      return true;
    } else {
      console.log("not valid1");
    }
    if (orderSize && Number(orderSize) <= 0) {
      setInputError({ price: false, size: true });
      return true;
    } else {
      console.log("not valid2");
    }
    setInputError({ price: false, size: false });
    return false;
  };

  const handleKeyPress = (e) => {
    // setRangeSlider();
    //don't allow decimal in qty if min_order_qty is whole number
    if (
      selectedOption != "USDT" &&
      Number.isInteger(currentPair?.min_order_qty)
    ) {
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  // Create order Buy or Sell
  const handleCreateOrder = async () => {
    const maxLiquidation = await getLiquidationPrice();
    // console.log("MMMMMMMMMMMMMMMMMMMMM", maxLiquidation, lossAmount);
    if (showTpSl && lossAmount && Number(lossAmount) > Number(maxLiquidation)) {
      toastMsg.error(
        "Stop loss must be less than " +
          toFix(maxLiquidation, pairDetails?.price_scale)
      );
      return;
    }

    let side = activeCta;
    console.log("handle create order", side);
    // if (totalUsdBalance <= 0) {
    //   toast.error(t("comman_word.AddFundstoAccount"), {
    //     toastId: "errorfunds",
    //   });
    //   return;
    // }
    try {
      const paymentSymbol = activeCoins.find(
        (doc) =>
          doc.chain_id === Number(chainId) &&
          doc.currency_address?.toLowerCase() ===
            userPaymentCurrency?.toLowerCase()
      )?.symbol;
      if (!orderValidation()) {
        setCheckOrder(true);
        let qty;
        if (orderPrefDropdown === coinArray[1]) {
          qty = Number(orderSize) / Number(orderPrice);
        } else {
          qty = Number(orderSize);
        }
        if (qty < selectedPair.min_order_qty) {
          toastMsg.error(
            `Minimum order quantity must be greater than ${selectedPair.min_order_qty}`,
            { toastId: "errormsg" }
          );
          setCheckOrder(false);
          return;
        }
        const data = {
          symbol: selectedPairKey,
          side,
          order_type: type === "L" ? "Limit" : "Market",
          price: type === "L" ? Number(orderPrice) : Number(currentLtp),
          leverage: leverage,
          qty: qty,
          address: walletAddress,
          takeProfit: showTpSl ? profitAmount : "",
          stopLoss: showTpSl ? lossAmount : "",
          slTriggerBy: showTpSl ? lossTriggerValue.value : "",
          tpTriggerBy: showTpSl ? profitTriggerValue.value : "",
        };
        console.log("set>>>>>>>>>>>>>>>>>", data);
        // return;
        //set leverage
        const dataObj = {
          address: walletAddress,
          leverage: leverage?.toString(),
          symbol: selectedPairKey,
        };
        dispatch(saveOrderSize(""));
        setRange(0);
        if (Number(leverage) !== Number(userLeverage)) {
          const leverageResp = await ApiServices.setLeverage(dataObj);
          if (leverageResp && leverageResp.status === 200) {
            dispatch(saveUserLeverage(dataObj.leverage));
          }
        }

        // if (leverageResp && leverageResp.status === 200) {
        const response = await ApiServices.createOrder(data);
        if (response && response.status === 200) {
          setCheckOrder(false);
          toastMsg.success("Order Created Successfully.", {
            toastId: "successMsg",
          });
          setSuccessFlag(true);
          setTimeout(() => {
            setSuccessFlag(false);
          }, 2000);
          getBalances();
          setLossAmount("");
          setProfitAmount("");
          setLossUsdt("");
          setProfitUsdt("");
          setNewROILoss("");
          setROIValue("");
          setLossRoi("");
          // setShowTpSl(false);
          setCheckedprofit(false);
        } else {
          setCheckOrder(false);
          toastMsg.error(response.message, { toastId: "errormsg" });
        }
        // }
      }
    } catch (error) {
      toastMsg.error(error.data.message, { toastId: "errormsg" });
      setCheckOrder(false);
      dispatch(saveOrderSize(""));
      setRange(0);
    }
  };

  const handleLeverageSwitch = (e) => {
    setLevSlider(e.target.checked);
  };

  const handleSetLeverage = (value) => {
    setRange(value);
    dispatch(saveLeverage(value));
  };

  useEffect(() => {
    dispatch(saveOrderPref(coinArray[1]));
    setSelectedOption(coinArray[1]);
    dispatch(saveOrderPrice(currentLtp));
  }, [coinArray[0]]);

  useEffect(() => {
    setSuccessFlag(true);
    setTimeout(() => {
      setSuccessFlag(false);
    }, 2000);
    setLossAmount("");
    setProfitAmount("");
    setLossUsdt("");
    setProfitUsdt("");
    setNewROILoss("");
    setROIValue("");
    setLossRoi("");
    // setShowTpSl(false);
    setCheckedprofit(false);
  }, [selectedPairKey]);

  const handleSetSelectedOption = (value) => {
    setSelectedOption(value);
    dispatch(saveOrderPref(value));
  };

  const handleSetTpsl = (e) => {
    if (e.target.checked) {
      if (orderPrice && orderSize) {
        // setShowprofit(true);
        // setCheckedprofit(true);
        setShowTpSl((prev) => !prev);
        setCheckedprofit((prev) => !prev);
      } else {
        if (!orderPrice && !orderSize) {
          toastMsg.error("Price and Size is required");
        } else {
          if (!orderPrice) {
            toastMsg.error("Price is required");
          }
          if (!orderSize) {
            toastMsg.error("Size is required");
          }
        }
      }
    } else {
      setShowTpSl((prev) => !prev);
    }
  };

  useEffect(() => {
    resetTkSl();
    setRange(leverage);
  }, [leverage, activeCta]);

  useEffect(() => {
    dispatch(saveOrderSize(""));
    dispatch(saveLeverage(1));
    setRange(1);
    setShowTpSl(false);
  }, [activeCta]);

  const resetTkSl = () => {
    setLossAmount("");
    setProfitAmount("");
    setLossUsdt("");
    setProfitUsdt("");
    setNewROILoss("");
    setROIValue("");
    setLossRoi("");
    setRangetp(0);
    setRangesl(0);
  };

  const getLiquidationPrice = async () => {
    try {
      const data = {
        entryPrice: orderPrice,
        leverage: leverage,
        size: orderSize,
        extraMargin: 0,
        isLong: activeCta == "Buy" ? true : false,
      };
      const result = await calculateLiquidationPrice(data);

      const liquidationPrice = result?.liquidationPrice;
      console.log("result1234", liquidationPrice);
      //get 95% of liquidation price
      return 0.95 * Number(liquidationPrice);
    } catch (error) {
      console.log("error liquidation price", error);
    }
  };
  const walletConnectMsg = () => {
      toastMsg.error("Please first connect or create a wallet.", {
          toastId: "walletConnectError",
      });
  };
  
  return (
      <>
          <div className="Market_Tab ">
              <div
                  className={
                      isActive ? "Advanced_Long openbtns" : "Advanced_Long"
                  }
              >
                  <div className="Advanced_btns Custom_Order_Tab">
                      <CommonButton
                          title="LONG"
                          // icon={greenArrow}
                          className={`${"logn-btn w-50 me-1 hideIcon"} ${
                              activeCta == "Buy" && "active"
                          }`}
                          btnicon="icon_block"
                          onClick={() => {
                              // handleCreateOrder("Buy");
                              setActiveCta("Buy");
                          }}
                      />
                      <CommonButton
                          title="SHORT"
                          // icon={redArrow}
                          className={`${"short-btn w-50 ms-1 hideIcon"} ${
                              activeCta == "Sell" && "active"
                          }`}
                          btnicon="icon_block"
                          onClick={() => {
                              // handleCreateOrder("Sell");
                              setActiveCta("Sell");
                          }}
                      />
                  </div>
              </div>

              <div className="Available_balance d-flex justify-content-between">
                  <h5>Trading Balance</h5>
                  <p>
                      <img src={WalletIcon} alt="icon" />{" "}
                      {walletBalance?.derivativeTransferBalance}{" "}
                      <span>USDT</span>
                  </p>
              </div>

              {/* ////////////////////////////////////////////////// */}

              <OrderType
                  eventKey={type}
                  walletAddress={walletAddress}
                  totalUsdBalance={walletBalance}
                  //  handleCalculatorModal={(value) =>
                  //    calculatorModalRef.current.handleModal(value)
                  //  }
                  setSelectedCoinOption={setSelectedCoinOption}
                  orderPrice={orderPrice}
                  fieldError={inputError}
                  currentPair={currentPair}
                  selectedPair={selectedPair}
                  coinArray={coinArray}
                  handleSetSelectedOption={handleSetSelectedOption}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setRange={setRange}
                  range={range}
                  resetTkSl={resetTkSl}
              />

              {/* ////////////////////////////////////////////// */}
              <div className="Leverage_Slider">
                  <div className="d-flex align-items-center">
                      <h5 className="common_heading">Leverage</h5>
                      <SwitchButton
                          title="Slider"
                          handleSwitchChange={handleLeverageSwitch}
                          checked={levSlider}
                      />
                  </div>

                  {levSlider && (
                      <LeverageSlider
                          min={minMaxLeverage?.min_leverage || 0}
                          max={minMaxLeverage?.max_leverage || 1}
                          format="x"
                          handleSetRange={handleSetLeverage}
                          rangeData={range}
                      />
                  )}
              </div>
              <div className="d-flex align-items-center">
                  <h5 className="common_heading">Cost:</h5>
                  <strong>
                      {orderPrice && orderSize
                          ? isFinite(Number(orderSize) / Number(leverage))
                              ? toFix(
                                    Number(orderSize) / Number(leverage),
                                    pairDetails?.price_scale
                                )
                              : 0
                          : 0}{" "}
                      USDT
                  </strong>
              </div>
              <div
                  className={
                      isActive ? "Advanced_Long openbtns" : "Advanced_Long"
                  }
              >
                  <div className="tradeBtn">
                      <CommonButton
                          title={
                              activeCta === "Buy" ? "Buy/Long" : "Sell/Short"
                          }
                          className={
                              activeCta === "Buy"
                                  ? "tradeBtnCtaLong"
                                  : "tradeBtnCtaShort"
                          }
                          onClick={
                              walletAddress
                                  ? () => handleCreateOrder()
                                  : () => walletConnectMsg()
                          }
                      />
                  </div>
                  <div className="d-flex align-items-center">
                      <h5 className="common_heading">Set TP/SL</h5>
                      <Form.Check
                          checked={showTpSl}
                          onClick={(e) => {
                              handleSetTpsl(e);
                          }}
                          className="form-check blue_check_box blueBorder"
                          aria-label="option 1"
                      />
                  </div>
                  {/* <h5 className="common_heading" onClick={toggleClass}>
            Advanced <DownarrowIcon />
          </h5> */}
                  {showTpSl && (
                      <div className="addTpSlSection">
                          <div className="addTpSlSectionInnner">
                              <TPSLModal
                                  orderPrice={orderPrice}
                                  orderSize={orderSize}
                                  profitAmount={profitAmount}
                                  pairDetails={pairDetails}
                                  setProfitAmount={setProfitAmount}
                                  label="Take Profit Price"
                                  type={"profit"}
                                  triggerBy={triggerBy}
                                  setProfitTriggerValue={setProfitTriggerValue}
                                  setLossTriggerValue={setLossTriggerValue}
                                  profitTriggerValue={profitTriggerValue}
                                  lossTriggerValue={lossTriggerValue}
                                  modalType={"order"}
                                  type_field="TP_ET"
                                  activeTab={
                                      activeCta == "Buy" ? "long" : "short"
                                  }
                                  lastTradedPrice={orderPrice}
                                  profitUsdt={profitUsdt}
                                  setProfitUsdt={setProfitUsdt}
                                  ROIValue={ROIValue}
                                  setROIValue={setROIValue}
                                  lossUsdt={lossUsdt}
                                  setLossUsdt={setLossUsdt}
                                  leverage={leverage}
                                  roiLoss={roiLoss}
                                  setLossRoi={setLossRoi}
                                  successFlag={successFlag}
                                  range={rangetp}
                                  setRange={setRangetp}
                                  tpslPositionType="createOrder"
                                  selectedPair={selectedPair}
                                  triggeredPrice={profitTriggerPrice}
                                  setTriggeredPrice={setProfitTriggerPrice}
                              />
                          </div>

                          <div className="addTpSlSectionInnner">
                              <TPSLModal
                                  orderPrice={orderPrice}
                                  orderSize={orderSize}
                                  lossAmount={lossAmount}
                                  pairDetails={pairDetails}
                                  setLossAmount={setLossAmount}
                                  label="Stop Loss Price"
                                  type={"loss"}
                                  triggerBy={triggerBy}
                                  setProfitTriggerValue={setProfitTriggerValue}
                                  setLossTriggerValue={setLossTriggerValue}
                                  profitTriggerValue={profitTriggerValue}
                                  lossTriggerValue={lossTriggerValue}
                                  modalType={"order"}
                                  type_field="SL_ET"
                                  activeTab={
                                      activeCta == "Buy" ? "long" : "short"
                                  }
                                  lastTradedPrice={orderPrice}
                                  profitUsdt={profitUsdt}
                                  setProfitUsdt={setProfitUsdt}
                                  ROIValue={ROIValue}
                                  setROIValue={setROIValue}
                                  lossUsdt={lossUsdt}
                                  setLossUsdt={setLossUsdt}
                                  leverage={leverage}
                                  roiLoss={roiLoss}
                                  setLossRoi={setLossRoi}
                                  successFlag={successFlag}
                                  range={rangesl}
                                  setRange={setRangesl}
                                  tpslPositionType="createOrder"
                                  selectedPair={selectedPair}
                                  triggeredPrice={lossTriggerPrice}
                                  setTriggeredPrice={setLossTriggerPrice}
                              />
                          </div>
                      </div>
                  )}
              </div>
              <MarginRatio />
          </div>
      </>
  );
};

export default PlaceOrder;
