import React, { useEffect, useState } from "react";
import "./MarginModal.scss";
import { Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import CommonButton from "../../../../common/CommonButton/CommonButton";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux.hooks";
import { ApiServices } from "../../services/api.services";
import { toastMsg } from "../../../../common/Toast/Toast";
import { saveMarginMode } from "../../../../redux/_slices/user.slice";

const MarginModal = ({ handleClose, show, marginMode }) => {
  const dispatch = useAppDispatch();
  const selectedPairKey = useAppSelector((state) => state.user.selectedPairKey);
  const leverage = useAppSelector((state) => state.user.leverage);
  const walletAddress = useAppSelector((state) => state.user.walletAddress);

  const [mode, setMode] = useState(marginMode);

  useEffect(() => {
    if (show) {
      setMode(marginMode);
    }
  }, [show]);

  const handleCloseSubmit = () => {
    handleClose();
  };

  const handleMode = (name) => {
    setMode(name);
  };

  const handleConfirm = async () => {
    try {
      const dataObj = {
        address: walletAddress,
        leverage: leverage?.toString(),
        symbol: selectedPairKey,
        tradeMode: mode,
      };
      const response = await ApiServices.switchTrade(dataObj);
      if (response && response.status === 200) {
        dispatch(saveMarginMode(mode));
      } else {
        // toastMsg.error("Something went wrong!", { toastId: "errormsg" });
      }
      // }
      handleCloseSubmit();
    } catch (error) {
      // toastMsg.error(error?.data?.message);
    }
  };

  return (
    <>
      <Modal
        className="marginModal"
        centered
        show={show}
        onHide={handleCloseSubmit}
      >
        <Modal.Header className="marginModalHeader" closeButton>
          <Modal.Title>
            {selectedPairKey?.replace("_", "")} Perpetual Margin Mode
          </Modal.Title>
        </Modal.Header>
        <div className="seperator"></div>
        <Modal.Body>
          <Row className="pt-2">
            <div className="switchButtonssection">
              <div className="buttons">
                <button
                  className={`switchButtons ${
                    mode === "Cross" ? "selected" : ""
                  }`}
                  onClick={() => handleMode("Cross")}
                >
                  Cross
                </button>
                <button
                  className={`switchButtons ${
                    mode === "Isolated" ? "selected" : ""
                  }`}
                  onClick={() => handleMode("Isolated")}
                >
                  Isolated
                </button>
              </div>
              <p>Switching the margin mode applies to the specific</p>
            </div>

            <div className="modalDescriptin">
              <div className="seperator"></div>
              <p>
                Cross Margin Mode: All cross positions under the same margin
                asset share the same asset cross margin balance. In the event of
                liquidation, your assets full margin balance along with any
                remaining open positions under the asset may be forfeited.
                <br />
              </p>
              <p>
                Isolated Margin Mode: Allows traders to limit their risk by
                controlling the margin allocated to a specific contract. Traders
                can adjust the margin ratio allocated to each contract using
                this mode. However, the position will be liquidated if the
                margin ratio reaches 100%.
              </p>
            </div>
          </Row>

          <div className="mt-4 pt-2">
            <CommonButton
              title="Confirm"
              className="w-100"
              onClick={handleConfirm}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MarginModal;
