import React, { memo, useEffect, useState } from "react";
import "./PositionHistory.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Col, Row } from "react-bootstrap";
import tokenIcon from "../../../../../assets/images/btc.svg";
import tokenIcon1 from "../../../../../assets/images/admin/Ethereum-ETH.svg";
import { useSelector } from "react-redux";
import { ApiServices } from "../../../services/api.services";
import moment from "moment";

const PositionHistory = () => {
  const walletAddress = useSelector((state) => state?.user?.walletAddress);
  const filters = useSelector((state) => state.user.userLedgerFilters);
  const listOfPairs = useSelector((state) => state?.exchange.listOfPairs);
  const [positionList, setPositionList] = useState([]);

  useEffect(() => {
    if (walletAddress) {
      getPositionHistory();
    } else {
      setPositionList([]);
    }
  }, [walletAddress, filters]);

  const getPositionHistory = async () => {
    try {
      const dataObj = {
          ...filters,
          address: walletAddress,
          limit: 10,
          endTime: null,
          startDate: null,
      };

      const response = await ApiServices.positionsHistoryList(dataObj);
      if (response.status === 200) {
        // console.log("response position history", response);
        setPositionList(response?.data?.list);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="Orders_Table position_history">
        <div className="position_history_Table">
          {positionList?.length > 0 &&
            positionList?.map((data, i) => {
              const pairDetails = listOfPairs?.find(
                (element) =>
                  element?.pair_name.split("_").join("") == data?.symbol
              );
              return (
                <ul key={i}>
                  <li>
                    <Row>
                      <Col xs={12} sm={8}>
                        <div className="token_content d-flex align-items-center">
                          <div className="d-flex align-items-center">
                            <img src={pairDetails?.icon} alt="btc" />
                            <h6>{data?.symbol}</h6>
                          </div>
                          <p>
                            Perpetual {data?.leverage}x{" "}
                            <span
                              className={
                                data?.side === 0 ? "text_red" : "text_green"
                              }
                            >
                              {data?.side === 0 ? "Short" : "Long"}
                            </span>
                          </p>
                        </div>
                      </Col>
                      <Col xs={12} sm={4} className="text-sm-end mt-2 mt-sm-0">
                        <div className="closetext">
                          <span className="cus_radio"></span> Closed
                        </div>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col xs={6} sm={5} md={4}>
                        <p className="open_date">
                          <strong>Opened</strong>{" "}
                          {moment(Number(data?.createdTime)).format(
                            "DD/MM/YYYY  HH:mm:ss"
                          )}
                        </p>
                      </Col>
                      <Col xs={6} sm={4} md={4}>
                        <p className="open_date_status">
                          <strong>Avg. Entry Price</strong>{" "}
                          {data?.avgEntryPrice}
                        </p>
                      </Col>
                      <Col
                        xs={12}
                        sm={3}
                        md={4}
                        className="text-md-end mt-2 mt-sm-0"
                      >
                        <p className="close_pnl">Closing PNL</p>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col xs={6} sm={5} md={4}>
                        <p className="open_date_status">
                          <strong>Closed Size</strong> {data?.closedSize}
                        </p>
                      </Col>
                      <Col xs={6} sm={4} md={4}>
                        <p className="open_date_status">
                          <strong>Avg. Exit Price</strong> {data?.avgExitPrice}
                        </p>
                      </Col>
                      <Col
                        xs={12}
                        sm={3}
                        md={4}
                        className="text-md-end mt-2 mt-sm-0"
                      >
                        <p
                          className={
                            "token_pnl " +
                            (data?.closedPnl > 0 ? "text_green" : "text_red")
                          }
                        >
                          {data?.closedPnl} USDT
                        </p>
                      </Col>
                    </Row>
                  </li>
                </ul>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default memo(PositionHistory);
