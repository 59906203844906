import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import CommonButton from '../../../../common/CommonButton/CommonButton'
import Admin from '../../Admin'
import TopbarHeading from '../../common/TopbarHeading/TopbarHeading'
import "./ApiManagement.scss"
import apiIcon from "../../../../assets/images/admin/api-img-icon.svg";
import { Link } from 'react-router-dom'
import { DeleteIcon, RightarrowIcon } from '../../../../assets/Svg/SvgImages'
import nodataicon from "../../../../assets/images/admin/no_data_icon.svg";
import ApiTypeModal from './ApiTypeModal'
import qacodeimg from "../../../../assets/icons/QR-Code.svg"

const ApiManagement = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Apidata = [
    {
      createapiname:"System",
      apiname: "Test API",
    },
    {      
      createapiname:"Self-Generated",
      apiname:"Another Test API",
    },
  ];

  return (
    <Admin>
      <div className='Common_TopBar'>
        <Row className='align-items-center'>
          <Col xs={12} xl={8}>
            <TopbarHeading title="Api Management" />
          </Col>
          <Col xs={12} xl={4} className="d-md-flex justify-content-xl-end">
            <div className='Common_Header_Btns d-flex align-items-center'>
              <CommonButton onClick={handleShow} title="Create API" className="grey-btn2" />
              <CommonButton title="Delete all API’s" className="grey-btn2" />
            </div>
          </Col>
        </Row>
      </div>
      <div className='Common_admin_Contentarea Api_Management_Content'>
        <div className='api_content d-md-flex align-items-center'>
          <div className='Api_icon'>
            <img src={apiIcon} alt="img" />
          </div>
          <ol className='ms-md-5 mt-3 mt-md-0'>
            <li>Each account can create up to 30 API Keys.</li>
            <li>Do not disclose your API Key, Secret Key (HMAC) or Private Key (RSA) to anyone to avoid asset losses. You should treat your API Key and your Secret Key (HMAC) or Private Key (RSA) like your passwords.</li>
            <li>It is recommended to restrict access to trusted IPs only to increase your account security.</li>
            <li>You will not be able to create an API Key if KYC is not completed.</li>
          </ol>
        </div>
        <div className='Naviblue_Card d-flex align-items-center'>
          <Form.Check className='form-check blue_check_box' aria-label="option 1" />
          <p className='font14 ps-3'>By checking this box you agree to our API Terms & Conditions <strong><Link to="#">Read T&C</Link> <RightarrowIcon /></strong></p>
        </div>

        <div className='Create_Api'>
          {Apidata.map((item, i) => {
            return (
              <div key={i} className='Create_Api_List'>
                <div className='Create_Api_List_TopApibtn d-md-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <span className='textgreen_btn'>{item.createapiname}</span>
                    <h5 className='font18 mb-0 ms-3'>{item.apiname}</h5>
                  </div>
                  <div className='d-flex align-items-center mt-3 mt-md-0'>
                    <span className='textblue_btn'>Edit restrictions</span>
                    <span className='textblue_btn mx-3'>Delete</span>
                    <DeleteIcon />
                  </div>
                </div>
                <div className='d-md-flex align-items-center mt-3 mt-md-4'>
                  <div className="Api_Scancode text-center">
                    <p className='font14 mb-3'>Scan tte code to get API address</p>
                    <img src={qacodeimg} alt="QA code" />
                  </div>
                  <div className="Create_Api_List_Apikey ps-md-5">
                    <div>
                      <p>API Key</p>
                      <h5 className='font18'>Pz39sJO3j2392JK430yzt291001m12j4dd45ght12 <span className='textyellow_btn ms-2'>Copy</span></h5>
                    </div>
                    <div className='mt-4'>
                      <p>Secret Key</p>
                      <h5 className='font18'>SOsAuk88qhqHQb7A8QYtr78t34SB034aaw321dq</h5>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <div className='No_Order_data text-center pt-xl-5 mt-4 mt-md-5'>
          <img src={nodataicon} alt='icon' />
          <p className='text-white h5 mt-4'>Your account has not created any API’s</p>
        </div>
      </div>
      <ApiTypeModal show1={show} handleClose1={handleClose} />
    </Admin>
  )
}

export default ApiManagement