import React from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonButton from '../../../../common/CommonButton/CommonButton';
import errorIcon from "../../../../assets/icons/info_redIcon.svg"

const SelectNetworkModal = ({ show, handleClose }) => {
    const orderdata = [
        {
            heading: "ETH",
            netwrok: "Ethereum Network (ERC20)",
            time: "Arrival time ≈ 5-10 mins",
            fee: "Fee ≈ 10 USDT ($10.00)",
        },
        {
            heading: "TRX",
            netwrok: "Tron Network (TRC20)",
            time: "Arrival time ≈ 1-5 mins",
            fee: "Fee ≈ 1 USDT ($1.00)",
        },
        {
            heading: "BSC",
            netwrok: "BNB Smart Chain (BEP20)",
            time: "Arrival time ≈ 5-10 mins",
            fee: "Fee ≈ 5 USDT ($5.00)",
        },
        {
            heading: "BSC",
            netwrok: "BNB Smart Chain (BEP20)",
            time: "Arrival time ≈ 5-10 mins",
            fee: "Fee ≈ 5 USDT ($5.00)",
        },
    ];
    return (
        <>
            <Modal className='Admin_Modal Select_Network_modal' centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='Select_Network_modal_Content'>
                        <div className='error_msg d-flex align-items-center justify-content-center'>
                            <img src={errorIcon} alt='icon' />
                            <p>Ensure the network you are depositing to matches the network of the address entered to avoid any withdrawal losses.</p>
                        </div>
                        <div className='Select_Network_Information'>
                            <ul>
                                {orderdata.map((item, i) => {
                                    return (
                                        <li className='d-md-flex align-items-center justify-content-between' key={i}>
                                            <p>{item.heading} <span>{item.netwrok} </span></p>
                                            <h4>{item.time} <span>{item.fee}</span></h4>
                                        </li>
                                    )
                                })}
                            </ul>
                            <CommonButton className="w-100" title="Confirm" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SelectNetworkModal
