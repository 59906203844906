import React, { useState, useEffect } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Admin from "../../Admin";
import TopbarHeading from "../../common/TopbarHeading/TopbarHeading";
import "./Orders.scss";
import SpotOrders from "./SpotOrders/SpotOrders";
import Perpetual from "./Perpetual/Perpetual";
const Orders = () => {
    const [activeTab, setActiveTab] = useState('spotorders');

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    return (
        <Admin>
            <div className="Common_TopBar">
                <Row className="align-items-center Spot_Header">
                    <Col xs={12} xl={8}>
                        <TopbarHeading title="Orders" hideEyeIcon = {true}/>
                    </Col>
                </Row>
            </div>
            <div className="Common_admin_Contentarea future_assets_content">
                <Tab.Container
                    activeKey={activeTab} 
                    onSelect={handleTabSelect}
                >
                    <div className="Futures_Tabs_Container">
                        <Nav variant="pills" className="Common_button_tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="spotorders">
                                    Spot Orders
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="perpetual">
                                    USDT Perpetual
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="spotorders">
                                <SpotOrders isActive = {activeTab === "spotorders" ? true:false}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="perpetual">
                                <Perpetual isActive = {activeTab === "perpetual" ? true:false}/>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </Admin>
    );
};

export default Orders;
