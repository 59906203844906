import React, {
  forwardRef,
  // useImperativeHandle,
  // useRef,
  useState,
  useEffect,
  memo,
} from "react";
import { GAS_LIMIT } from "../../constants/constants";
import { NETWORK_INFO } from "../../constants/wallet";
import { useAppSelector } from "../../hooks/redux.hooks";
import { TXN_HASH_URL } from "../../pages/Staking/constants/constants";
import { depositToContract, getUSDTDecimals } from "../../redux/_actions/web3.actions";
import { web3Services } from "../../services/web3.service";
import CommonButton from "../CommonButton/CommonButton";
import ModalComponet from "../ModalComponet";
import { toastAlert } from "../Toast/Toast";
import "./DepositFeeModal.scss";
import BigNumber from "bignumber.js";
import store from "../../redux/_store/configureStore";
import { callWeb3 } from "../../pages/Staking/services/walletServices";
// import { BigNumber } from "./node_modules/bignumber.js/bignumber.mjs";
const wallettype = store.getState()?.user?.wallet;

const DepositFeeModal = forwardRef((props, ref) => {
  const {
    setAmount,
    handleClose,
    handleCloseMain,
    isLoader,
    setIsLoader,
    assetsBalanceApiFunction,
  } = props;
  // const currentProvider = useCurrentProvider();
  const chainId = useAppSelector((state) => state.user.chainId);
  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const userBalances = useAppSelector((state) => state.exchange.userBalances);
  const [gasPrice, setGasPrice] = useState(0);
  const [value, setValue] = useState(0);
  const [decimal, setDecimal] = useState(0);
  const [modalData, setModalData] = useState({
    approval: false,
    approvalError: false,
    mainFunction: false,
    status: "",
    message: "",
    transactionHash: "",
    error: false,
    sendFunction: "",
  });

  useEffect(() => {
    getGasPrice();
    getDecimals();
    const value = NETWORK_INFO.find(
      (doc) => doc.chain_id === Number(chainId)
    )?.depositValue;
    setValue(value);
  }, [chainId]);

  const confirmDeposit = async () => {
    props.handleClose();
    setIsLoader(true);

    try {
      const currentProvider = "";

      let fee = GAS_LIMIT * gasPrice;
      let x = new BigNumber(fee);

      // props.handleClose()
      const res = await toastAlert.waitingPromis(
        depositToContract(
          currentProvider,
          walletAddress,
          props.amount,
          setAmount,
          handleClose,
          handleCloseMain,
          userBalances[0],
          chainId,
          x.toFixed(),
          setIsLoader
        ),
        "Awaiting confirmation...",
        "Deposit Successful.  Your funds will be available momentarily."
      );
      if (res) {
        assetsBalanceApiFunction();

        setAmount("");
        setModalData({
          approval: true,
          mainFunction: false,
          status: "success",
          transactionHash: "",
          sendFunction: confirmDeposit,
        });
        setIsLoader(false);
      }
    } catch (error) {
      // console.log("depo", error);
      setAmount("");
      setIsLoader(false);
      throw error;
    }
  };

  const transactionConfirmationFunction = (e, key) => {
    if (key == 1) {
      e.preventDefault();
      window.open(TXN_HASH_URL + modalData?.transactionHash, "_blank");
    }
  };

  const getGasPrice = async () => {
    const gasPrice = await web3Services.getGasPrice();
    setGasPrice(gasPrice);
  };

  const getDecimals = async () => {
    const wallettype = store.getState()?.user?.wallet;
    const currentProvider = await callWeb3(wallettype);
    const decimals = await getUSDTDecimals(
      currentProvider,
      chainId
    );
    setDecimal(decimals);
  };

  return (
    <>
      <ModalComponet
        hide={props?.handleClose}
        backdrop={false}
        // ref={modalRef}
        show={props?.showModal}
        title="Deposit Confirmation"
        className="confirm_modal"
        direction="staking"
      >
        <div className="d-flex justify-content-between">
          <div>Amount : {" " + props?.amount} USDT</div>
          {/* <div>Value : {" " + value}$</div> */}
        </div>

        <div className="d-flex justify-content-between confirm_modal__status">
          <span>Status</span>
          {/* <span className="text-danger">Error</span> */}
          <span className={modalData?.error ? "text-danger" : "text-light"}>
            {modalData?.mainFunction
              ? "Confirmed"
              : modalData?.error
                ? "Error"
                : "Waiting for you to confirm"}
          </span>
        </div>
        <a
          onClick={(e) => {
            modalData?.mainFunction
              ? transactionConfirmationFunction(e, 1)
              : modalData?.error
                ? modalData?.sendFunction(e)
                : transactionConfirmationFunction(e, 2);
          }}
        >
          <CommonButton
            title="Confirm"
            className="w-100"
            onClick={confirmDeposit}
          />
        </a>
      </ModalComponet>
    </>
  );
});

const Loader = ({ className }) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center loader loader--${className}`}
    >
      <div className="dbl-spinner" />
    </div>
  );
};

export default memo(DepositFeeModal);
