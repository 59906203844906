import React, { useEffect, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import "./TransferListHistory.scss";
import charticon1 from "../../../../../assets/icons/redline_trade.svg";
import charticon2 from "../../../../../assets/icons/linechat_arrow.svg";
import { useSelector, useDispatch } from "react-redux";
import { saveUserTransactionHistory } from "../../../../Exchange/redux/_slices/exchange.slice";
import { ApiServices } from "../../../../Exchange/services/api.services";
import { saveUserTransferListHistory } from "../../../../Exchange/redux/_slices/exchange.slice";
import Select from "react-select";
import moment from "moment";
import { updateLoader } from "../../../../Exchange/redux/_slices/exchange.slice";
import { useAppSelector } from "../../../../../hooks/redux.hooks";
import Loader from "../../../common/Loader/Loader";

const TransferListHistory = () => {
    const [selectOptionStatus, setSelectOptionStatus] = useState("");
    const walletAddress = useSelector((state) => state?.user?.walletAddress);
    const loader = useAppSelector((state) => state.exchange.loader);
    const optionsStatus = [
        { value: "", label: "All" },
        { value: "SUCCESS", label: "Success" },
        { value: "PENDING", label: "Pending" },
        { value: "FAILED", label: "Failed" },
    ];

    const transferHistory = useSelector(
        (state) => state?.exchange?.userTransferListHistory
    );
    const dispatch = useDispatch();
    useEffect(() => {
        if (walletAddress) {
            getTransferTransactionHistory();
        } else {
            dispatch(saveUserTransferListHistory([]));
        }
    }, [selectOptionStatus]);

    const getTransferTransactionHistory = async () => {
        try {
            const dataObject = {
                address: walletAddress,
                limit: 100,
                status: selectOptionStatus,
            };
            const response = await ApiServices.getTransferListHistory(
                dataObject
            );
            dispatch(saveUserTransferListHistory(response?.data?.list));
            dispatch(updateLoader(false));
        } catch (error) {
            console.log(error);
        }
    };

    const handleStatusChange = (selectedOption) => {
        setSelectOptionStatus(selectedOption.value);
        dispatch(updateLoader(true));
    };

    return (
        <>
             {loader && <Loader/>}
            <div className="CryptoAssets_Table History_Table mt-4 mt-xl-5">
                <div className="Transaction_History_selectBox d-flex flex-wrap mt-4 mt-xl-5">
                    <div className="common_select">
                        <label>Status</label>
                        <Select
                            placeholder="All"
                            value={optionsStatus.find(
                                (option) => option.value === selectOptionStatus
                            )}
                            onChange={handleStatusChange}
                            options={optionsStatus}
                            classNamePrefix="react-select"
                        />
                    </div>
                </div>
                <div className="CryptoAssets_Table History_Table mt-4 mt-xl-5 internaltransferHistory">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th style={{ minWidth: "220px" }}>
                                    <div className="d-flex align-items-center">
                                        Coin
                                        <div className="upDown_arrow">
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </th>
                                <th style={{ minWidth: "150px" }}>
                                    <div className="d-flex align-items-center">
                                        Amount
                                        <div className="upDown_arrow">
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </th>
                                <th style={{ minWidth: "150px" }}>
                                    <div className="d-flex align-items-center">
                                        From Account
                                        <div className="upDown_arrow">
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </th>

                                <th>
                                    <div className="d-flex align-items-center">
                                        To Account
                                        <div className="upDown_arrow">
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        Time
                                        <div className="upDown_arrow">
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        Status
                                        <div className="upDown_arrow">
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {transferHistory?.map((data, i) => {
                                let amount = data?.amount / 100000000;
                                return (
                                    <tr key={i}>
                                        <td style={{ minWidth: "220px" }}>
                                            <div className="coin_data d-flex align-items-center">
                                                {/* <img
                                                className="coin_Icon"
                                                src={data.btcicon}
                                                alt="eye"
                                            /> */}
                                                <div className="d-flex align-items-center">
                                                    <span>{data?.coin}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{ minWidth: "150px" }}>
                                            <div className="d-flex align-items-center">
                                                <span>{data?.amount}</span>
                                            </div>
                                        </td>
                                        <td>
                                            {" "}
                                            <div className="d-flex align-items-center">
                                                <span>
                                                    {data?.fromAccountType}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            {" "}
                                            <div className="d-flex align-items-center">
                                                <span>
                                                    {data?.toAccountType}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Type_Data d-flex align-items-center">
                                                {moment(
                                                    Number(data?.timestamp)
                                                ).format(
                                                    "DD-MM-YYYY  HH:mm:ss"
                                                )}
                                            </div>
                                        </td>
                                        <td
                                            className={`${
                                                data?.status == "SUCCESS"
                                                    ? "text_green"
                                                    : "text_red"
                                            }  text-end`}
                                        >
                                            <div className="Type_Data d-flex align-items-center">
                                                {data?.status}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {/* <div className="transactions_Pagination d-flex align-items-center justify-content-between">
                    <p>52 transactions</p>

                    <Pagination className="common_pagination">
                        <Pagination.Prev />
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>

                        <Pagination.Ellipsis className="ellipsis_text" />
                        <Pagination.Item>{6}</Pagination.Item>
                        <Pagination.Next />
                    </Pagination>
                </div> */}
                </div>
            </div>
        </>
    );
};

export default TransferListHistory;
