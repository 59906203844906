import React from 'react'
import CommonButton from '../../../../../common/CommonButton/CommonButton'
import "./DashboardBanner.scss"
import featureimg from "../../../../../assets/images/admin/feature-img.svg";

const DashboardBanner = () => {
    return (
        <>
            <div className='Overview_Banner'>
                <div className='Overview_Banner_Content'>
                    <h1>Welcome to Eterna</h1>
                    <h5>The #1 decentralized exchange with deep reserve liquidity</h5>
                    <h2>Get started</h2>
                    <p>Useful links to help you get started with trading on EHX</p>
                    <div className='Overview_Banner_Content_btns'>
                        <CommonButton title="Beginner’s guide" />
                        <CommonButton title="Trading Tips" className="mx-2 mx-xl-3 mx-xxl-4" />
                        <CommonButton title="What are perpetuals?" />
                    </div>
                </div>
                <div className='Overview_Banner_featureImg'>
                    <img src={featureimg} alt="feature img" />
                </div>
            </div>
        </>
    )
}

export default DashboardBanner