import React, { useState } from "react";
import "./AllMarket.scss";
import TokenIcon from "../../../../assets/images/btc.svg";
import { DownarrowIcon } from "../../../../assets/Svg/SvgImages";
import TradingPairs from "./TradingPairs";
import { useSelector } from "react-redux";

const AllMarket = () => {
  // const [isActive, setActive] = useState(false);
  const selectedPair = useSelector((state) => state?.user?.selectedPair);

  // const toggleClass = () => {
  //   setActive(!isActive);
  // };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="AllMarket_V2 nondraggable_card">
        <div className="AllMarket_TradingPairs" onClick={handleShow}>
          <div className="Token_Name_Icon">
            <img
              src={`${selectedPair?.icon}`}
              alt={
                selectedPair &&
                Object.keys(selectedPair).length > 0 &&
                selectedPair?.pair_name.replace("_", "-")
              }
            />{" "}
            <span className="Token_name">
              {selectedPair &&
                Object.keys(selectedPair).length > 0 &&
                selectedPair?.pair_name.replace("_", "-")}
              <DownarrowIcon />
            </span>
          </div>
          {/* <span className="All_Market_title">All markets <DownarrowIcon /></span> */}
        </div>
        <div className="TradingPairs_List">
          <TradingPairs show={show} handleClose={handleClose} />
        </div>
      </div>
    </>
  );
};
export default AllMarket;
