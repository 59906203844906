import React, { useEffect, useState } from "react";
import "./AccountUpdate.scss";
import HrsImg from "../../../../assets/images/hrsimg.svg";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { ApiServices } from "../../services/api.services";
import { showDecimal } from "../../../../_utils/comman";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "../../common/Separator";
import {
  saveUserFundingBalance,
  saveUserUnifiedTradingBalance,
} from "../../../../redux/_slices/user.slice";
import { useDispatch } from "react-redux";

const AccountUpdate = () => {
  const dispatch = useDispatch();
  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const walletBalance = useAppSelector(
    (state) => state.user.userWalletBalances
  );
  const userFundingBalance = useAppSelector(
    (state) => state.user.userFundingBalance
  );
  const userUnifiedTradingBalance = useAppSelector(
    (state) => state.user.userUnifiedTradingBalance
  );
  const percentage = 45;

  useEffect(() => {
    if (walletAddress) {
      getAccountBalance();
    }
  }, [walletAddress]);

  //get account balances
  const getAccountBalance = async () => {
    let fundingBalance = await ApiServices.getFundingBalance({
      address: walletAddress,
    });
    dispatch(
      saveUserFundingBalance({
        availableBalance: fundingBalance?.data?.totalWalletBalance,
      })
    );
    let unifiedBalance = await ApiServices.getUnifiedTrading({
      address: walletAddress,
    });
    dispatch(
      saveUserUnifiedTradingBalance({
        availableBalance: unifiedBalance?.data?.totalWalletBalance,
      })
    );
  };

  return (
    <>
      <div className="AccountUpdate_V2 nondraggable_card">
        <div className="Ticker_data">
          <ul>
            <li>
              <span>Funding Balance</span>
              <p
                title={`$ ${
                  walletAddress
                    ? userFundingBalance?.availableBalance
                      ? showDecimal(userFundingBalance?.availableBalance, 6)
                      : 0
                    : "0"
                }`}
              >
                $
                {walletAddress
                  ? userFundingBalance?.availableBalance
                    ? showDecimal(userFundingBalance?.availableBalance, 6)
                    : 0
                  : "0"}
              </p>
            </li>
            <li>
              <span>Unified Balance</span>
              <p
                title={`$ ${
                  walletAddress
                    ? showDecimal(
                        userUnifiedTradingBalance?.availableBalance,
                        6
                      )
                    : "0"
                }`}
              >
                $
                {walletAddress
                  ? userUnifiedTradingBalance?.availableBalance
                    ? showDecimal(
                        userUnifiedTradingBalance?.availableBalance,
                        6
                      )
                    : 0
                  : "0"}
              </p>
            </li>

            <li className="Hr_Pl">
              <span>24HR P&L:</span>
              <p className="text_green">--</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default AccountUpdate;
