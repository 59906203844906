import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import Admin from '../../Admin'
import TopbarHeading from '../../common/TopbarHeading/TopbarHeading'
import "./HelpDesk.scss"
import rooboticon from "../../../../assets/images/admin/helpdesk/rooboticon.svg"
import icon1 from "../../../../assets/images/admin/helpdesk/FAQ.svg"
import icon2 from "../../../../assets/images/admin/helpdesk/tickets.svg"
import icon3 from "../../../../assets/images/admin/helpdesk/live-support.svg"
import { SearchIcon } from '../../../../assets/Svg/SvgImages'
import CommonButton from '../../../../common/CommonButton/CommonButton'
import { Link } from 'react-router-dom'

const HelpDesk = () => {
  const helpdata = [
    {
      icon: icon1,
      btnname: "F.A.Q",
      link: "/faq",
    },
    {
      icon: icon2,
      btnname: "Support Tickets",
      link: "/support-ticket",
    },
    {
      icon: icon3,
      btnname: "Live Support",
      link: "/live-support",
    },
  ];
  return (
    <Admin>
      <div className='Common_TopBar'>
        <Row className='align-items-center'>
          <Col xs={12} xl={8}>
            <TopbarHeading title="HelpDesk" />
          </Col>
          {/* <Col xs={12} xl={4} className="d-md-flex justify-content-xl-end">
          </Col> */}
        </Row>
      </div>
      <div className='Common_admin_Contentarea HelpDesk'>
        <Container>
          <Row className='align-items-center'>
            <Col xs={12} md={4} xxl={3}>
              <img src={rooboticon} alt='icon' />
            </Col>
            <Col xs={12} md={8} xxl={7} className="HelpDesk_help mt-4 mt-md-0">
              <h3 className='font28'>How can we help?</h3>
              <h5 className='font18 mb-4'>Stuck? Questions? Technical issues? </h5>
              <p>Find the answers you’re looking for here, submit a ticket through our Customer Service Portal or connect with a Live Customer Support assistant.</p>
              <div className='Common_search mt-4'>
                <Form.Control className="Common_search_Input" placeholder="Search for a topic you need assistance with" />
                <SearchIcon />
              </div>
            </Col>
          </Row>
        </Container>

        <Container className='mt-4 mt-md-5 pb-0 pb-xl-5'>
          <Row className='justify-content-center'>
            {helpdata.map((item, i) => {
              return (
                <Col key={i} xs={12} md={6} xl={4} className='px-xl-3 mb-3 mb-xl-0'>
                  <div className='HelpDesk_Boxs'>
                    <figure>
                      <img src={item.icon} alt='icon' />
                    </figure>
                    <Link to={item.link}><CommonButton title={item.btnname} /></Link>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </Admin>
  )
}

export default HelpDesk