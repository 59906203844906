import Web3 from "web3";

// @ts-ignore
import {
  CHAIN_ID,
  RPCURL,
  RPCURLEXCHBSC,
  mainnet,
  metadata,
  projectId,
} from "../constants/constants";
import { NETWORK_INFO, NETWORK_INFOMATION } from "../../../constants/wallet";
import store from "../../../redux/_store/configureStore";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { toast } from "react-toastify";
import { saveUserWalletAddress } from "../../../redux/_slices/user.slice";

import { isMobile } from "react-device-detect";
export const wallets = [
  "metamask",
  "walletconnect",
  "binance_wallet",
  "trust_wallet",
  "Coinbase_Wallet",
  "Token_Pocket",
  "Brave",
  "metamask",
];
const mappingNetwork = {
  1: "eth-mainnet",
  56: "bsc-mainnet",
};

const rpcmapping = {
  1: RPCURL,
  56: RPCURLEXCHBSC,
};
export let binanceProvider;
let web3Object;

//coinbase wallet connect

export const coinbaseWallet = new CoinbaseWalletSDK({
  options: {
    appName: "eterna",
    infuraId: RPCURL,
  },
});

export const coinbase_ethereum_provider = coinbaseWallet.makeWeb3Provider(
  RPCURL,
  CHAIN_ID
);
export let provider = "";

const WALLET_CONNECT_PROJECT_ID = "06cd73c01555f937a6963abee3431312";

export const wcprovider = async () => {
  const chain_id_number = store?.getState()?.user?.chainId;
  // provider = await UniversalProvider.init({
  //   logger: "info",
  //   relayUrl: "ws://<relay-url>",
  //   projectId: WALLET_CONNECT_PROJECT_ID,
  //   metadata: {
  //     name: "Eterna Exchange",
  //     description: "Eterna Exchange",
  //     url: "https://test.eterna.exchange/",
  //     icons: ["https://avatars.githubusercontent.com/u/37784886"],
  //   },
  //   client: undefined, // optional instance of @walletconnect/sign-client
  // });
  return null;
};
const walletServices = {
  //service for coinbase wallet

  coinbaseconnect: async (path) => {
    const networkInfo = path != "/staking" ? NETWORK_INFO : NETWORK_INFOMATION;
    const chain_id = await coinbase_ethereum_provider.getChainId();
    const chainidBlockchain = store?.getState()?.user?.chainId;
    let chain =
      path === "/staking" ? networkInfo[0]?.chain_id : chainidBlockchain;
    if (chain_id != chain) {
      // await ChangeNetwork(path);
      await walletConnectnetworkChange(coinbase_ethereum_provider);
    }
    const res = await coinbase_ethereum_provider.enable();
    return res[0];
  },

  // services for wallet connect

  walletConnect: async () => {
    try {
      let res = "";
      // const res = await wcprovider();
      return res;
    } catch (error) {
      console.log("error", error);
    }
  },
  //, "trust"
  killWalletConnect: async () => {
    let v2 = "";
    // let v2 = await wcprovider();
    await v2?.disconnect();
  },

  BinanceWalletconnect: async (path) => {
    try {
      const networkInfo =
        path != "/staking" ? NETWORK_INFO : NETWORK_INFOMATION;
      const { BinanceChain } = window;
      const chainid = Web3.utils.hexToNumber(BinanceChain.chainId);
      const chainidBlockchain = store?.getState()?.user?.chainId;
      let chain =
        path === "/staking" ? networkInfo[0]?.chain_id : chainidBlockchain;
      if (chainid != chain) {
        // await ChangeNetwork(path);
        await BinanceChain.switchNetwork(mappingNetwork?.[chain]);
        // store?.dispatch(saveNetworkChainId(chain));
      }
      const bscProvider = await BinanceChain.request({
        method: "eth_accounts",
      });
      return bscProvider[0];
    } catch (error) {
      setTimeout(() => {
        window.open(
          "https://www.binance.com/en/download",
          "_blank" // <- This is what makes it open in a new window.
        );
      }, 2000);
    }
  },
  // services for metamask

  metaMaskConnect: async (path) => {
    const { ethereum } = window;
    try {
      const networkInfo =
        path != "/staking" ? NETWORK_INFO : NETWORK_INFOMATION;
      const metaOverData = await metamaskOverRideFunction(ethereum);
      if (metaOverData) {
        return;
      }
      const result = Boolean(ethereum && ethereum?.isMetaMask);

      if (result) {
        try {
          let chainid;
          if (ethereum?.overrideIsMetaMask) {
            const web3New = new Web3(window.ethereum.providers[2]);
            chainid = await web3New.eth.getChainId();
          } else {
            chainid = await getChainid();
          }
          const chainidBlockchain = store?.getState()?.user?.chainId;
          let chain =
            path === "/staking" ? networkInfo[0]?.chain_id : chainidBlockchain;
          if (
            // chainid != networkInfo[0]?.chain_id ||
            chainid != chain
          ) {
            const switchRes = await switchNetwork(chain, path);
            if (
              switchRes != true &&
              switchRes?.includes("User rejected the request.")
            ) {
              return;
            }
          }
          if (ethereum?.overrideIsMetaMask) {
            const accounts = await ethereum.providers[2].request({
              method: "eth_accounts",
            });

            if (accounts && accounts.length > 0) return accounts[0];
            else {
              const accounts = await ethereum.providers[2].request({
                method: "eth_requestAccounts",
              });

              return accounts[0];
            }
          } else {
            const accounts = await ethereum.request({
              method: "eth_accounts",
            });

            if (accounts && accounts.length > 0) return accounts[0];
            else {
              const accounts = await ethereum.request({
                method: "eth_requestAccounts",
              });

              return accounts[0];
            }
          }
        } catch (err) {
          // console.log("eth_requestAccountsrr", err);
          return false;
        }
      } else {
        const accounts = await ethereum.request({
          method: "eth_accounts",
        });

        if (accounts && accounts.length > 0) return accounts[0];
        else {
          const accounts = await ethereum.request({
            method: "eth_requestAccounts",
          });

          return accounts[0];
        }
      }
    } catch (error) {
      if (!isMobile) {
        const quote =
          "Please install and initialize Metamask wallet extension first";
        setTimeout(() => {
          window.open(
            "https://metamask.io/",
            "_blank" // <- This is what makes it open in a new window.
          );
        }, 2000);
      }
      return false;
    }
  },

  TrustWallet: async (path) => {
    const { trustwallet, ethereum } = window;
    try {
      const networkInfo =
        path != "/staking" ? NETWORK_INFO : NETWORK_INFOMATION;
      const chainidBlockchain = store?.getState()?.user?.chainId;
      let chain =
        path === "/staking" ? networkInfo[0]?.chain_id : chainidBlockchain;
      if (trustwallet.networkVersion != chain) {
        await ChangeNetwork(path);
      }
      // const accounts = await trustwallet.enable();

      const accounts = await trustwallet.request({
        method: "eth_requestAccounts",
      });
      return accounts[0];
    } catch (error) {
      // console.log(error);
      setTimeout(() => {
        window.open(
          "https://trustwallet.com/",
          "_blank" // <- This is what makes it open in a new window.
        );
      }, 1000);
    }
  },

  tokenPocket: async (path) => {
    const { ethereum } = window;
    try {
      const networkInfo =
        path != "/staking" ? NETWORK_INFO : NETWORK_INFOMATION;
      if (ethereum.isTokenPocket) {
        const chainid = await getChainid();
        const chainidBlockchain = store?.getState()?.user?.chainId;
        let chain =
          path === "/staking" ? networkInfo[0]?.chain_id : chainidBlockchain;
        if (chainid != chain) {
          await switchNetwork(chain, path);
        }
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        return accounts[0];
      } else {
        setTimeout(() => {
          window.open(
            "https://www.tokenpocket.pro/en",
            "_blank" // <- This is what makes it open in a new window.
          );
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        window.open(
          "https://www.tokenpocket.pro/en",
          "_blank" // <- This is what makes it open in a new window.
        );
      }, 1000);
    }
  },

  safpalWallet: async (path) => {
    const { ethereum } = window;
    const networkInfo = path != "/staking" ? NETWORK_INFO : NETWORK_INFOMATION;

    if (ethereum.isSafePal) {
      const chainid = await getChainid();
      const chainidBlockchain = store?.getState()?.user?.chainId;
      let chain =
        path === "/staking" ? networkInfo[0]?.chain_id : chainidBlockchain;
      if (chainid != chain) {
        await switchNetwork(chain, path);
      }
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      return accounts[0];
    }
  },

  braveWallet: async (path) => {
    const { ethereum } = window;
    try {
      const networkInfo =
        path != "/staking" ? NETWORK_INFO : NETWORK_INFOMATION;
      if (ethereum.isBraveWallet) {
        const chainid = await getChainid();
        const chainidBlockchain = store?.getState()?.user?.chainId;
        let chain =
          path === "/staking" ? networkInfo[0]?.chain_id : chainidBlockchain;
        if (chainid != chain) {
          await switchNetwork(chain, path);
        }
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        return accounts[0];
      } else {
        setTimeout(() => {
          window.open(
            "https://brave.com/en-in/wallet/",
            "_blank" // <- This is what makes it open in a new window.
          );
        }, 1000);
      }
    } catch (error) {
      // console.log("error", error);
      setTimeout(() => {
        window.open(
          "https://brave.com/en-in/wallet/",
          "_blank" // <- This is what makes it open in a new window.
        );
      }, 1000);
    }
  },
};

export const walletConnectnetworkChange = async (walletProvider) => {
  try {
    const address = await walletProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
    return true;
  } catch (error) {}
};
// wallet services finished

export const switchNetwork = async (data, path) => {
  const wallettype = store.getState()?.user?.wallet;
  let newEthereum = ethereumOverlap();
  // console.log("newEthereum", newEthereum, Web3.utils.toHex(data), data);
  try {
    const address = await newEthereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: Web3.utils.toHex(data) }],
    });
    return true;
  } catch (switchError) {
    if (switchError.code == 4001) {
      return switchError.message;
    }
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        const res = await networkChangeHelper(newEthereum, path);
        return res;
      } catch (addError) {
        return addError.message;
      }
    }
  }
};

export const getAccountBalance = async (walletAddress) => {
  const wallettype = store.getState()?.user?.wallet;
  const web3Object = await callWeb3(wallettype);
  const result = await web3Object.eth.getBalance(walletAddress);
  console.log(">>>>>>>>>>>>>", result.toString());
  const resultFormated = web3Object.utils.fromWei((result.toString()));
  console.log(">>>>>>>>>>>>resultFormated", resultFormated);

  return resultFormated;
};

export const ChangeNetwork = async (path) => {
  if (store.getState()?.user?.wallet == wallets[4]) {
    try {
      await networkChangeHelper(coinbase_ethereum_provider, path);
    } catch (error) {
      // console.log(error);
    }
  } else if (store.getState()?.user?.wallet == wallets[3]) {
    try {
      const { trustwallet } = window;

      await networkChangeHelper(trustwallet, path);
    } catch (error) {
      // console.log(error);
    }
  } else if (store.getState()?.user?.wallet == wallets[0]) {
    try {
      const { ethereum } = window;
      await networkChangeHelper(ethereum, path);
    } catch (error) {
      // console.log(error);
    }
  } else {
    try {
      const { BinanceChain } = window;
      await networkChangeHelper(BinanceChain, path);
    } catch (error) {
      // console.log(error);
    }
  }
};

export const networkChangeHelper = async (connector, path) => {
  try {
    const networkInfo = NETWORK_INFO; // path != "/staking" ? NETWORK_INFO : NETWORK_INFOMATION;
    const chainidBlockchain = store?.getState()?.user?.chainId;
    let chainIndex = chainidBlockchain == 1 ? 0 : 0;
    let chainId = path === "/staking" ? 0 : chainIndex;
    await connector.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: Web3.utils.toHex(networkInfo[chainId].chain_id),
          rpcUrls: [networkInfo[chainId].rpc_url],
          chainName: networkInfo[chainId].label,
          nativeCurrency: {
            name: networkInfo[chainId].label,
            symbol: networkInfo[chainId].symbol,
            decimals: 18,
          },
          blockExplorerUrls: [networkInfo[chainId].explorer_url],
        },
      ],
    });
    return true;
  } catch (error) {
    // console.log("network change helper error", error);
    return false;
  }
};

export const accountsChanged = (walletname) => {
  return async (dispatch) => {
    const { ethereum } = window;

    if (ethereum) {
      ethereum.on("accountsChanged", (accounts) => {
        store.dispatch(saveUserWalletAddress(accounts[0]));
      });
    }
  };
};
// export const provider = async () => {
//   const provider = await UniversalProvider.init({
//     logger: "info",
//     projectId: walletConnectProjectId,
//     metadata: {
//       name: "React App",
//       description: "React App for WalletConnect",
//       url: "https://walletconnect.com/",
//       icons: ["https://avatars.githubusercontent.com/u/37784886"],
//     },
//     client: undefined, // optional instance of @walletconnect/sign-client
//   });
//   return provider;
// };

export const callWeb3 = async (walletType) => {
  const chain_id_number = store?.getState()?.user?.chainId;
  console.log("walletTypewalletType", walletType, wallets[1]);
  const { ethereum, web3, BinanceChain, trustwallet } = window;
  switch (walletType) {
    case wallets[0]:
      return new Web3(ethereumOverlap());
    case wallets[1]:
      // const modal = createWeb3Modal({
      //   ethersConfig: defaultConfig({ metadata }),
      //   chains: [mainnet],
      //   projectId,
      // });

      // let aa = await modal?.walletConnectProviderInitPromise;

      const v2 = await wcprovider();
      console.log("wcasasa", v2);
      return new Web3(v2);
    case wallets[2]:
      return new Web3(BinanceChain);
    case wallets[3]:
      return new Web3(trustwallet);
    case wallets[4]:
      return new Web3(coinbase_ethereum_provider);
    case wallets[5]:
      return new Web3(ethereum);
    case wallets[6]:
      return new Web3(ethereum);
    case wallets[7]:
      return new Web3(ethereum);
    default:
      return new Web3(RPCURL);
  }
};

export const getChainid = async () => {
  const wallettype = store.getState()?.user?.wallet;
  const web3 = await callWeb3(wallettype);
  let networkChainId = await web3.eth.getChainId();
  return networkChainId;
};

export const ethereumOverlap = () => {
  const { ethereum } = window;
  if (ethereum?.overrideIsMetaMask) {
    return ethereum.providers[2];
  } else {
    return ethereum;
  }
};

export const metamaskOverRideFunction = async (ethereumObj) => {
  if (ethereumObj?.isTokenPocket || ethereumObj?.isTrustWallet) {
    toast.info("Cannot connect to metamask Please try with another wallet.");
    // toast.info("");
    return true;
  }
};

export default walletServices;
