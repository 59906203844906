import { dynamicContractDetails } from "../../../assets/dynamicContract";
import { errorHelperContract } from "../ContractHelper";
import { commonAbiInstances } from "../GlobalCall";
import tokenABI from "../../../assets/abi/REWARDTOKEN.json";
import Web3 from "web3";
import { RPCURL } from "../../../constants/constants";

export const balanceOfFunction = async (waddress, tokenType) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == tokenType
    );
    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.balanceOf(waddress).call();
      return Number(res);
    }
  } catch (error) {
    errorHelperContract(error, "call", "balanceOf");
    return 0;
  }
};

export const stakeToken = async (waddress, stakeType) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == stakeType
    );
    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.userInfo(waddress).call();
      console.log("setStakeEXH cont", res);
      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "userInfo");
    return 0;
  }
};

export const LpstakeuserInfo = async (waddress, stakeType) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == "LPSTAKE"
    );
    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.userInfo(waddress).call();
      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "userInfo");
    return 0;
  }
};

export const rewardPendingEXH = async (waddress) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == "SOLOSTAKE"
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.rewardsPending(waddress).call();
      return Number(res);
    }
  } catch (error) {
    errorHelperContract(error, "call", "rewardsPending");
    return 0;
  }
};

export const WithdrawDividendof = async (waddress) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == "Dividend_Tracker"
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods
        .withdrawableDividendOf(waddress)
        .call();
      return Number(res);
    }
  } catch (error) {
    errorHelperContract(error, "call", "withdrawableDividendOf");
    return 0;
  }
};

export const EXHCollectt = async (waddress, stakedata) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == stakedata
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.rewardsClaimed(waddress).call();
      return Number(res);
    }
  } catch (error) {
    errorHelperContract(error, "call", "rewardsClaimed");
    return 0;
  }
};

export const USDCCollecttoDate = async (waddress) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == "Dividend_Tracker"
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );

    if (contract) {
      const res = await contract.methods.amountEarn(waddress).call();

      return Number(res);
    }
  } catch (error) {
    errorHelperContract(error, "call", "amountEarn");
    return 0;
  }
};
export const USDCLastCollectedTime = async (waddress) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == "Dividend_Tracker"
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );

    if (contract) {
      const res = await contract.methods.lastClaimTimes(waddress).call();
      return Number(res);
    }
  } catch (error) {
    errorHelperContract(error, "call", "lastClaimTimes");
    return 0;
  }
};

//Token_Allowance Function Calling

export const TokenAllowance = async (waddress, tokenKey, key) => {
  try {
    // const token = key == 1 ? "REWARDTOKEN" : "REWARDTOKEN";
    // const tokenAddress = key == 1 ? "SOLOSTAKE" : "LPSTAKE";
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == tokenKey
    );
    const stakeData = dynamicContractDetails.filter((a) => a.symbol == key);
    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods
        .allowance(waddress, stakeData[0]?.address)
        .call();
      return Number(res);
    }
  } catch (error) {
    errorHelperContract(error, "call", "allowance");
    return 0;
  }
};

export const getUserLpToken = async (waddress) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == "LPSTAKE"
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.stakedTokens(waddress).call();

      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "stakedTokens");
    return 0;
  }
};

export const balanceOfForReserveFunction = async (tokenType) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == tokenType
    );
    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );

    const pairData = dynamicContractDetails.filter((a) => a.symbol == "PAIR");
    if (contract) {
      const res = await contract.methods.balanceOf(pairData[0]?.address).call();
      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "balanceOf");
    return 0;
  }
};

//getStaked Amount function calling

export const getStakedAmount = async (waddress, stakedata) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == stakedata
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );

    if (contract) {
      const res = await contract.methods.userInfo(waddress).call();
      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "userInfo");
    return 0;
  }
};

//getPoolInfo Function

export const getPollInfo = async (stakedata) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == stakedata
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.pool().call();

      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "pool");
    return 0;
  }
};

//getRewardRecovery function calling

export const getRewardRecovery = async (stakedata) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == stakedata
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.rewardsRemaining().call();

      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "rewardsRemaining");
    return 0;
  }
};

//getTotalSupplyLP function calling

export const getTotalSupplyLP = async () => {
  try {
    const tokenData = dynamicContractDetails.filter((a) => a.symbol == "PAIR");

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.totalSupply().call();

      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "totalSupply");
    return 0;
  }
};

//getAPY function calling

export const getAPY = async () => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == "SOLOSTAKE"
    );

    // const contract = await commonAbiInstances(
    //   tokenData[0]?.address,
    //   tokenData[0]?.abi
    // );
    let rpc = RPCURL;
    let _web3Instance = new Web3(rpc);
    let contract = new _web3Instance.eth.Contract(
      tokenData[0]?.abi,
      tokenData[0]?.address
    );
    if (contract) {
      const res = await contract.methods.apy().call();

      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "rewardsRemaining");
    return null;
  }
};

//Total USDC distributed function calling

export const USDCdistributed = async () => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == "Dividend_Tracker"
    );

    // const contract = await commonAbiInstances(
    //   tokenData[0]?.address,
    //   tokenData[0]?.abi
    // );
    let rpc = RPCURL;
    let _web3Instance = new Web3(rpc);
    let contract = new _web3Instance.eth.Contract(
      tokenData[0]?.abi,
      tokenData[0]?.address
    );

    if (contract) {
      const res = await contract.methods.totalDividendsDistributed().call();
      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "totalDividendsDistributed");
    return 100;
  }
};

export const TotalStaked = async (stakedata) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == stakedata
    );
    // const contract = await commonAbiInstances(
    //   tokenData[0]?.address,
    //   tokenData[0]?.abi
    // );
    let rpc = RPCURL;
    let _web3Instance = new Web3(rpc);
    let contract = new _web3Instance.eth.Contract(
      tokenData[0]?.abi,
      tokenData[0]?.address
    );
    if (contract) {
      const res = await contract.methods.totalStaked().call();
      return res.toString();
    }
  } catch (error) {
    errorHelperContract(error, "call", "totalStaked");
    return 100;
  }
};

//soloholderUnlocktime

export const soloholderUnlockTime = async (waddress) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == "SOLOSTAKE"
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.holderUnlockTime(waddress).call();
      console.log("holderUnlockTime", res);

      return Number(res);
    }
  } catch (error) {
    errorHelperContract(error, "call", "holderUnlockTime");
    return 100;
  }
};

//amountWithdrawable function calling

export const amountWithdrawableFunction = async (waddress) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == "SOLOSTAKE"
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.amountWithdrawable(waddress).call();

      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "amountWithdrawable");
    return 100;
  }
};

//PendingrewardsFunction calling

export const PendingrewardsFunction = async (waddress, stakedata) => {
  try {
    const tokenData = dynamicContractDetails.filter(
      (a) => a.symbol == stakedata
    );

    const contract = await commonAbiInstances(
      tokenData[0]?.address,
      tokenData[0]?.abi
    );
    if (contract) {
      const res = await contract.methods.pendingReward(waddress).call();

      return res;
    }
  } catch (error) {
    errorHelperContract(error, "call", "pendingReward");
    return 100;
  }
};

export const balanceOfFunctionToken = async (waddress, tokenAddress) => {
  try {
    const contract = await commonAbiInstances(tokenAddress, tokenABI);
    if (contract) {
      const res = await contract.methods.balanceOf(waddress).call();
      // console.log("reswewewewew", res);
      return res;
    }
  } catch (error) {
    // console.log("balanceOferror", error);
    // errorHelperContract(error, "call", "balanceOf");
    return false;
  }
};
