import { createSlice } from "@reduxjs/toolkit";
import { FONT_LAYOUT, GRID_LAYOUT } from "../../constants/grid.layout";

const initialState = {
  // Grid Layout Information
  grid_layout: GRID_LAYOUT,
  old_grid_layout: {},
  font_layout: FONT_LAYOUT,
  mobile_tab: "MARKET",
  current_breakpoint: "lg",
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    saveMobileTab: (state, action) => {
      state.mobile_tab = action.payload;
    },

    saveChangeGridLayout: (state, action) => {
      state.grid_layout = { ...action.payload };
    },

    saveOldGridLayout: (state, action) => {
      state.old_grid_layout = { ...action.payload };
    },

    saveCurrentBrackpoint: (state, action) => {
      state.current_breakpoint = action.payload;
    },

    savefontLayout: (state, action) => {
      state.font_layout = { ...action.payload };
    },

    saveResetLayout: (state, action) => {
      state.grid_layout = GRID_LAYOUT;
      state.font_layout = FONT_LAYOUT;
      state.mobile_tab = "MARKET";
      state.current_breakpoint = "lg";
      state.old_grid_layout = {};
    },
  },
});
export const {
  saveMobileTab,
  saveChangeGridLayout,
  saveOldGridLayout,
  saveCurrentBrackpoint,
  savefontLayout,
  saveResetLayout,
} = layoutSlice.actions;

export default layoutSlice.reducer;
