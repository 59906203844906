import React, { useState, useEffect } from "react";
import { Pagination, Table } from "react-bootstrap";
import "./OrderHistory.scss";
import OrderHistoryFilter from "./OrderHistoryFilter";
import iconSell from "../../../../../../assets/icons/redline_trade.svg";
import iconBuy from "../../../../../../assets/icons/linechat_arrow.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ApiServices } from "../../../../../Exchange/services/api.services";
import { saveUserSpotOrderHistory } from "../../../../../Exchange/redux/_slices/exchange.slice";
import { updateLoader } from "../../../../../Exchange/redux/_slices/exchange.slice";
import Loader from "../../../../common/Loader/Loader";
const OrderHistory = ({currentTab, isActive}) => {
    const dispatch = useDispatch();
   const walletAddress = useSelector((state) => state?.user?.walletAddress);
    const listOrderHistory = useSelector(
        (state) => state.exchange.userSpotOrderHistory
    );
    const orderFilter = useSelector(
        (state) => state.user.userSpotTradeFilters?.orderFilter
    );
     const loader = useSelector((state) => state.exchange.loader);

    const limit = 10;

    useEffect(() => {
        if (walletAddress && currentTab == 'spotOrderHistory' && isActive) {
            dispatch(updateLoader(true));
            getOrderHistoryList();
        } else {
            dispatch(saveUserSpotOrderHistory([]));
        }
    }, [walletAddress, orderFilter, currentTab, isActive]);

    const getOrderHistoryList = async () => {
        try {
            const dataObject = {
                address: walletAddress,
                category: "spot",
                dateFilter: orderFilter,
            };
            const response = await ApiServices.ordersHistoryList(dataObject);
            if(response){
                dispatch(saveUserSpotOrderHistory(response?.data?.list));
                dispatch(updateLoader(false));
            }
            
        } catch (err) {
            console.log(err);
            dispatch(updateLoader(false));
        }
    };

    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    return (
        <>
            {loader && <Loader/>}
            <OrderHistoryFilter />
            <div className="CryptoAssets_Table History_Table">
                <Table responsive>
                    <thead>
                        <tr>
                            <th style={{ minWidth: "150px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Spot Pairs
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "150px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Trade Type
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "150px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Order Type
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    Direction
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Order Value
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Order Price
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "210px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Filled/Order Quantity
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    TP/SL
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "370px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Order Time
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "370px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Order ID
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {listOrderHistory !== undefined &&
                        listOrderHistory?.length > 0 ? (
                            listOrderHistory.map((data, index) => (
                                <tr key={index}>
                                    <td>{data?.symbol}</td>
                                    <td>Trade</td>
                                    <td>
                                        {data?.type == 1 ? "Market" : "Limit"}
                                    </td>
                                    <td>
                                        <div className="Type_Data d-flex align-items-center">
                                            <div
                                                className={`type_Icon ${
                                                    data?.side == "Buy"
                                                        ? "greenbg"
                                                        : "redbg"
                                                }`}
                                            >
                                                <img
                                                    src={
                                                        data?.side == "Buy"
                                                            ? iconBuy
                                                            : iconSell
                                                    }
                                                    alt="icon"
                                                />
                                            </div>
                                            <div className="type_Text">
                                                <h6>
                                                    {data?.side == 0
                                                        ? "Buy"
                                                        : "Sell"}
                                                </h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {roundToTwoDecimals(data?.cumExecValue)}
                                    </td>
                                    <td>{roundToTwoDecimals(data?.price)}</td>
                                    <td>
                                        {roundToTwoDecimals(
                                            data?.executed_price
                                        )}
                                        {"/"}
                                        {roundToTwoDecimals(data?.cumExecQty)}
                                    </td>
                                    <td>
                                        {roundToTwoDecimals(
                                            data?.trigger_price
                                        )}
                                        {"/"}
                                        {roundToTwoDecimals(data.stopLoss)}
                                    </td>
                                    <td>
                                        {moment(
                                            Number(data?.created_at)
                                        ).format("DD-MM-YYYY  HH:mm:ss")}
                                    </td>
                                    <td>{data?.id}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={11}>
                                    <p style={{ textAlign: "center" }}>
                                        No result found
                                    </p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {/* <div className="transactions_Pagination d-flex align-items-center justify-content-between">
                    <Pagination className="common_pagination">
                        <Pagination.Prev />
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>

                        <Pagination.Ellipsis className="ellipsis_text" />
                        <Pagination.Item>{6}</Pagination.Item>
                        <Pagination.Next />
                    </Pagination>
                </div> */}
            </div>
        </>
    );
};

export default OrderHistory;
