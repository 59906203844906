import React, { memo } from "react";
import { Modal } from "react-bootstrap";
import CommonButton from "../../common/CommonButton/CommonButton";

const ConfirmModal = ({
    handleClose,
    show,
    handleSubmit,
    tab
}) => {

    return (
        <>
            <Modal
                className="close_position_modal"
                centered
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirm Close {tab === "orders" ? "Orders" : "Positions"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to close all open {tab}?
                    </p>
                    <div className="groupbtn d-flex mt-4 pt-2">
                        <CommonButton
                            onClick={handleSubmit}
                            title="Confirm"
                            className="me-2 w-50"
                        />
                        <CommonButton
                            title="Cancel"
                            className="grey-btn2 ms-2 w-50"
                            onClick={handleClose}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default memo(ConfirmModal);
