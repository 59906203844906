import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import ToastNotification from "../Toast/ToastNotification";

import crossIcon from "../../assets/images/crossIcon.svg";
import ConnectModel from "../ConnectModel/ConnectModel";
import WalletInfoDrop from "./WalletinfoDrop";
import DepositAndWithdrawal from "../../pages/Exchange/common/Assets";
import { Link, useLocation } from "react-router-dom";
import {
  saveMarginMode,
  saveNetworkChainId,
  saveNotificationCount,
  saveProvider,
  saveUserWalletAddress,
} from "../../redux/_slices/user.slice";
import "./Header.scss";
import { UserIcon } from "../../assets/Svg/SvgImages";
import { NotificationIcon } from "../../assets/Svg/SvgImages";
import NotificationList from "./NotificationList";
import UserAdminList from "./UserAdminList";
import { useAccount, useDisconnect } from "wagmi";
import { onMessageListener } from "../../_utils/firebase";

import {
  BSC_CHAIN_ID,
  CHAIN_ID,
  ETH_CHAIN_ID,
} from "../../constants/constants";
import { toastMsg } from "../Toast/Toast";
import NetworkSwitchModal from "../../pages/Exchange/common/NetworkSwitchModal";
import CalculatorModal from "../CalculatorModal/CalculatorModal";
import { ApiServices } from "../../pages/Exchange/services/api.services";
import { saveUserProfile } from "../../redux/_slices/user.slice";
import WalletIcon from "../../assets/icons/wallet-icon.png";
import WalletIconOpen from "../../assets/icons/wallet-open-icon.png";
import {
  DynamicWidget,
  getNetwork,
  useDynamicContext,
} from "@dynamic-labs/sdk-react-core";
const RightNav = forwardRef((props, ref, label, smallLabel, icon) => {
  const { t } = useTranslation;
  const chainId1 = useSelector((state) => state.user.chainId);
  const walletAddress = useSelector((state) => state.user.walletAddress);
  const leverage = useSelector((state) => state.user.leverage);
  const selectedPairKey = useSelector((state) => state.user.selectedPairKey);
  const current_breakpoint = useSelector(
    (state) => state.layout.current_breakpoint
  );
  const [showCalculator, setShowCalculator] = useState(false);
  const [show, setShow] = useState(false);

  const depositModalRef = useRef();
  const withdrawalModalRef = useRef();

  const dispatch = useDispatch();
  const connectModalRef = useRef();

  const [notification, setNotification] = useState({
    title: "test",
    body: "notification",
  });

  const [sideShow, setSideshow] = useState(false);
  const [chainIcon, setChainIcon] = useState("");
  const [totalListCount, setTotalListCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const [page, setPage] = useState(1);
  const newCount = useSelector((state) => state.user.notificationCount);
  const [networkChangeModal, setNetworkChangeModal] = useState(false);
  const slideShow = () => setSideshow(true);
  const { disconnect } = useDisconnect();
  const {
    connector: activeConnector,
    address,
    isDisconnected,
    isConnected,
    chainId,
  } = useAccount();
  const { primaryWallet } = useDynamicContext();
  const location = useLocation();

  useEffect(() => {
    if (address) {
      getTradeMode();
    }
  }, [address]);

  const getTradeMode = async () => {
    try {
      const dataObj = {
        address: address,
      };
      const response = await ApiServices.getTradeMode(dataObj);
      if (response && response.status === 200) {
        const tradeMode = response?.data?.tradeMode;
        if (tradeMode === "Cross") {
          switchTradeMode();
        }
      }
    } catch (error) {}
  };

  const switchTradeMode = async () => {
    try {
      const dataObj = {
        address: address,
        leverage: leverage?.toString(),
        symbol: selectedPairKey,
        tradeMode: "Isolated",
      };
      const response = await ApiServices.switchTrade(dataObj);
      if (response && response.status === 200) {
        dispatch(saveMarginMode("Isolated"));
      } else {
        // toastMsg.error("Something went wrong!", { toastId: "errormsg" });
      }
      // }
    } catch (error) {
      // toastMsg.error(error?.data?.message);
    }
  };

  // useEffect(() => {
  //   console.log("chainIdchainId", chainId, address);

  //   try {
  //     if (primaryWallet?.connector) {
  //       console.log("primaryWallet?.address", primaryWallet?.address);

  //       dispatch(saveUserWalletAddress(primaryWallet?.address));
  //       getNetwork(primaryWallet.connector)
  //         .then((network) => {
  //           console.log(">>>>>>>>>>>>>>>network", network);
  //           dispatch(saveNetworkChainId(network));
  //           // setCurrentNetwork(network);
  //         })
  //         .catch((err) => {
  //           console.log("network switch error", err);
  //         });
  //     }
  //   } catch (error) {
  //     console.log("network switch error22", error);
  //   }
  // }, [chainId]);

  useEffect(() => {
    if (address) {
      dispatch(saveUserWalletAddress(address));
    }
    if (chainId) {
      dispatch(saveNetworkChainId(chainId));
    }
  }, [address, chainId]);

  useImperativeHandle(ref, () => ({
    handleConnectWallet() {
      connectModalRef.current.handleModal(true);
    },
  }));

  const memoizedDisconnect = useCallback(() => {
    disconnect();
  }, [disconnect]);

  useEffect(() => {
    getProvider();
  }, [activeConnector]);

  const getProvider = async () => {
    const provider = await activeConnector?.getProvider();
    dispatch(saveProvider(provider));
  };

  // useEffect(() => {
  //   // alert(selectedNetworkId);
  //   if (address) {
  //     // setIsConnecting(true);
  //     let chain_Id = 1;
  //     console.log("this is chain ID", chainId);
  //     const debounceTimeout = setTimeout(() => {
  //       if (
  //         chain_Id &&
  //         Number(chain_Id) !== BSC_CHAIN_ID &&
  //         Number(chain_Id) !== ETH_CHAIN_ID &&
  //         Number(chain_Id) !== CHAIN_ID
  //       ) {
  //         console.log(`incorrect network`, chain_Id);
  //         toastMsg.error(
  //           "Unsupported network please pick Ethereum Main Network or BNB Smart Chain on your device before scanning the QR code"
  //         );
  //         dispatch(saveUserWalletAddress(""));
  //         memoizedDisconnect();

  //         // setTimeout(() => {
  //         //   window.location.reload();
  //         // }, 1500);
  //       } else if (
  //         (address && Number(chain_Id) === BSC_CHAIN_ID) ||
  //         Number(chain_Id) === ETH_CHAIN_ID ||
  //         Number(chain_Id) === CHAIN_ID
  //       ) {
  //         if (walletAddress === "") {
  //           console.log("Wallet connected");
  //           getProvider();
  //           // dispatch(saveWallet("metamask"))
  //         } else if (
  //           (walletAddress !== address && Number(chain_Id) === BSC_CHAIN_ID) ||
  //           Number(chain_Id) === ETH_CHAIN_ID
  //         )
  //           console.log("Wallet changed || network changed");
  //         getProvider();
  //         console.log("this is address", address);
  //         dispatch(saveUserWalletAddress(address));
  //         // dispatch(saveNetworkChainId(chain_Id));
  //         updateUserLoginIp(address);
  //       }

  //       // setIsConnecting(false);
  //     }, 1500);

  //     // prevSelectedNetworkIdRef.current = chain_Id;

  //     return () => clearTimeout(debounceTimeout);
  //   }
  // }, [address, chainId, memoizedDisconnect]);

  // useEffect(() => {
  //   if (isDisconnected) {
  //     console.log("disconnected");

  //     setTimeout(() => {
  //       if (walletAddress) console.log("Wallet disconnected");
  //       dispatch(saveUserWalletAddress(""));
  //       memoizedDisconnect();
  //     }, 1000);
  //   }
  // }, [isDisconnected]);

  useEffect(() => {
    if (!isConnected) {
      // if (walletAddress) {
      dispatch(saveUserWalletAddress(""));
      // }
    } else {
      dispatch(saveUserWalletAddress(primaryWallet?.address));
    }
  }, [isConnected]);

  /**
   *  Handle on Change Network
   * @param {*} param0  // Chian id
   */
  // const handleOnNetwork = async (item) => {
  //   const chain_id = item?.chain_id;
  //   try {
  //     if (activeConnector?.name === "WalletConnect") {
  //       setNetworkChangeModal(true);
  //     }
  //     if (walletAddress) {
  //       await switchNetwork({ chainId: chain_id });
  //     }
  //     // dispatch(saveNetworkChainId(chain_id));
  //     setChainIcon(item?.icon);
  //   } catch (error) {
  //     console.log("error switching", error);
  //   }
  // };

  const handleDW = (name) => {
    if (name === "deposite") {
      depositModalRef.current.handleModal(true);
    } else {
      withdrawalModalRef.current.handleModal(true);
    }
  };

  // const handleDWCal = () => {
  //   CalculatorModal.current.handleModal(true);
  // };

  if (!isMobile) {
    onMessageListener()
      .then((payload) => {
        if (payload) {
          dispatch(saveNotificationCount(payload?.data?.count));
          // setNewCount((prev) => prev + 1);
          setShow(true);
          setNotification({
            title: payload?.notification.title,
            body: payload?.notification.body,
          });
        }
      })
      .catch((err) => {
        // console.log("failed: ", err)
      });
  }

  const getNotification = async (paveVal, markas) => {
    let data = {
      address: walletAddress,
      page: paveVal ? paveVal : 1,
      // mark: markas,
    };
    try {
      const response = await ApiServices.getNotificationList(data);
      if (response && response.status === 200) {
        setTotalListCount(response?.data?.count);
        dispatch(saveNotificationCount(response?.data?.unseenCount));
        // setNewCount(response?.data?.unseenCount);
        setNotificationList([
          // ...notificationList,
          ...response?.data?.userNotifications,
        ]);
      } else {
        toastMsg.error(response.message, { toastId: "errormsg" });
      }
    } catch {}
  };

  const readNotification = async () => {
    let data = {
      address: walletAddress,
    };
    try {
      const response = await ApiServices.getReadNotification(data);
      if (response && response.status === 200) {
        setPage(1);
        getNotification(1, 1);
      } else {
        toastMsg.error(response.message, { toastId: "errormsg" });
      }
    } catch (error) {
      throw error;
    }
  };

  const updateUserLoginIp = async (wallletAddress) => {
    try {
      let ipAddress = await ApiServices.getUserIP();
      let response = ApiServices.updateUserIp({
        address: wallletAddress,
        ipAddress: ipAddress,
      });
      if (response) {
        saveUserProfile({
          ipAddress: ipAddress,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const walletConnectMsg = () => {
    toastMsg.error("Please first connect or create a wallet.", {
      toastId: "walletConnectError",
    });
  };
  return (
    <>
      <div className="Right_Header">
        {/* <SettingsIcon /> */}
        {/* <div className="wallet_area sectionCalc">
                  <CommonButton
                      type="button"
                      className="btn-primary calcBtn"
                      onClick={() => {
                          setShowCalculator(true);
                      }}
                      title="Calculator"
                  />
              </div> */}
        {/* <Settingmodal img={Setting} /> */}

        <div className="wallet_area">
          {/* <CommonButton
            className={
              walletAddress ? "connect_wlt Disconnect_wlt" : "connect_wlt"
            }
            icon={WallectIcon}
            btnicon="icon_block"
            title={
              walletAddress ? getWalletAddressFormate(walletAddress) : "Connect"
            }
            // onClick={() => open()}
          /> */}
          <DynamicWidget innerButtonComponent={<h6>Connect Wallet</h6>} />

          {/* {walletAddress &&
            (current_breakpoint === "md" || current_breakpoint === "lg") && (
              <>
                <WalletInfoDrop
                  walletAddress={walletAddress}
                  id={1000000186292}
                  handleDisconnectWallet={(e) => handleDisconnectWallet(e)}
                  current_breakpoint={current_breakpoint}
                  handleDW={handleDW}
                />
              </>
            )} */}
        </div>
        {/* <div className="Network_Dropdown d-block">
          <Dropdown align="end">
            <Dropdown.Toggle id="dropdown-basic">
              <span>
                <img src={chainId == 1 ? TokenIcon : TokenBNB} alt="img" />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {networkInfo.map((item) => {
                return (
                  <Dropdown.Item to="#" onClick={() => handleOnNetwork(item)}>
                    <div className="seleted-label">
                      <div className="thumb">
                        <img src={item?.icon} alt="img" />
                        <span className="small-label">{item?.label}</span>
                      </div>
                    </div>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
        {walletAddress ? (
          <>
            <div className="Header_Setting UserAdmin_Dropdown d-flex align-items-center">
              <div className="Lang_Into_Set">
                <Dropdown align="end">
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <UserIcon />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <UserAdminList />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* <Link className="calculatorBtn" to={"/dashboard/account-statement"}>
                  <img
                      src={
                          location.pathname.includes("account-statement")
                              ? WalletIconOpen
                              : WalletIcon
                      }
                      alt="calculatorIcon"
                      width={30}
                      height={30}
                  />
              </Link> */}

            <div className="Header_Setting d-flex align-items-center">
              {/* <div className="Lang_Into_Set">
            <Dropdown className="Language_Dropdown" align="end">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <GlobalIcon />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Tabs
                  defaultActiveKey="language"
                  id="uncontrolled-tab-example"
                  className=""
                >
                  <Tab eventKey="language" title="Language">
                    <LanguageList />
                  </Tab>
                  <Tab eventKey="currency" title="Currency">
                    <LanguageList />
                  </Tab>
                </Tabs>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
              <span className="Lang_Into_Set">
                {show ? (
                  <ToastNotification
                    walletAddress={walletAddress}
                    notification={notification}
                    show={show}
                    setShow={setShow}
                    newCount={newCount}
                    handleRead={readNotification}
                  />
                ) : (
                  <NotificationList
                    walletAddress={walletAddress}
                    getNotification={getNotification}
                    newCount={newCount}
                    setPage={setPage}
                    page={page}
                    notificationList={notificationList}
                    totalListCount={totalListCount}
                    handleRead={readNotification}
                  />
                )}

                {/* <NotificationList
              walletAddress={walletAddress}
              getNotification={getNotification}
              newCount={newCount}
              setPage={setPage}
              page={page}
              notificationList={notificationList}
              totalListCount={totalListCount}
              handleRead={readNotification}
            /> */}
              </span>
              {/* <span className="Lang_Into_Set">
     
            <Settingmodal img={Setting} />
          </span> */}
            </div>
          </>
        ) : (
          <>
            <div className="Header_Setting UserAdmin_Dropdown d-flex align-items-center">
              <div className="Lang_Into_Set">
                <div className="dropdown" onClick={walletConnectMsg}>
                  <div className="dropdown-toggle">
                    <UserIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="Header_Setting d-flex align-items-center">
              <span className="Lang_Into_Set">
                <div className="dropdown" onClick={walletConnectMsg}>
                  <div className="dropdown-toggle">
                    <NotificationIcon />
                  </div>
                </div>
              </span>
            </div>
          </>
        )}
      </div>
      {(current_breakpoint === "sm" || current_breakpoint === "xs") && (
        <Offcanvas
          className="WalletConnect_RightBar"
          placement="end"
          show={sideShow}
        >
          <Offcanvas.Header>
            <button className="btnCloseIcon" onClick={() => setSideshow(false)}>
              <img src={crossIcon} alt="crossIcon" />
            </button>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <WalletInfoDrop
              walletAddress={walletAddress}
              id={1000000186292}
              // handleDisconnectWallet={(e) => handleDisconnectWallet(e)}
              current_breakpoint={current_breakpoint}
              handleDW={handleDW}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <ConnectModel ref={connectModalRef} />
      <DepositAndWithdrawal ref={depositModalRef} title="deposit" />
      <DepositAndWithdrawal ref={withdrawalModalRef} title="withdrawal" />
      <NetworkSwitchModal
        show={networkChangeModal}
        handleClose={() => setNetworkChangeModal(false)}
      />
      <CalculatorModal
        show={showCalculator}
        handleClose={() => setShowCalculator(false)}
      />
    </>
  );
});
export default RightNav;
