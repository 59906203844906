import { Form } from "react-bootstrap";
import ehx from "../../assets/icons/ehx.svg";
import usdt from "../../assets/icons/tether-usdt.svg";
import CustomTooltip from "../../common/StakingComponents/CustomTooltip/CustomTooltip";
import CustomInput from "../../common/StakingComponents/CustomInput/CustomInput";
import CustomButton from "../../common/StakingComponents/CustomButton/CustomButton";
import "./Staking.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  approve,
  CollectEXH,
  CollectUSDC,
  localeStringFunction,
  stakeDeposit,
  stakeWithdraw,
} from "./services/ContractServices/ContractSendService/ContractSendService";
import { toastMsg } from "../../common/Toast/Toast";
import {
  balanceOfForReserveFunction,
  EXHCollectt,
  getPollInfo,
  getRewardRecovery,
  getStakedAmount,
  getTotalLP,
  PendingrewardsFunction,
  rewardPendingEXH,
  TokenAllowance,
  USDCCollect,
  USDCCollecttoDate,
  USDCdistributed,
  USDCLastCollectedTime,
  WithdrawDividendof,
} from "./services/ContractServices/ContractCallService/ContractCallservices";
import moment from "moment";
import {
  actualValue,
  fixAndConvertFunction,
  toCustomFixedFunction,
  toFixedNumber,
  toFunctionBigNumber,
  valueWithDollarFormat,
} from "./services/HelperServices";
import ConfirmationModal from "../../common/ConfirmationModal/ConfirmationModal";
import { toCustomFixed } from "./services/ContractServices/ContractHelper";
// import {
//   toFixed,
//   // toFunctionBigNumber,
// } from "./services/ContractServices/ContractHelper";

const tokenKeys = ["REWARDTOKEN", "USDCTOKEN", "LPTOKEN"];
const stakeKeys = ["SOLOSTAKE", "LPSTAKE"];
let counter = false;
const StakingReward = (props) => {
  const walletAddress = useSelector((state) => state.user.walletAddress);

  const userTab = useSelector((state) => state?.user?.usertab);
  const [rewardInput, setRewardInput] = useState("");
  const [usdcCollect, setUsdcCollect] = useState(0);
  const [usdcDt, setUsdcDt] = useState(0);
  const [ECollectdt, setECollectdt] = useState(0);
  const [LastEXHtime, setLastEXHtime] = useState(0);
  const [LastUsdcTime, setLastUsdcTime] = useState("");
  const [rewardEXH, setrewardEXH] = useState(0);
  const [earntoDateFix, setEarntoDateFix] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [collectHide, setCollectHide] = useState(true);
  const [pendingRewards, setpendingRewards] = useState(0);
  const [pendingRewardsRaw, setpendingRewardsRaw] = useState(0);
  const [chainConfirmation, setChainConfirmation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [collectAndStakeRewardDisable, setcollectAndStakeRewardDisable] =
    useState(false);

  const [modalData, setModalData] = useState({
    approval: false,
    mainFunction: false,
    withdrawFunction: false,
    status: "",
    message: "",
    transactionHash: "",
    error: false,
    sendFunction: "",
    stakeEhxReward: {
      stakeEhx: false,
      stakeEhxWithdraw: false,
      stakeEhxWithdrawError: false,
      stakeEhxDepositError: false,
      // stakeEhxdeposit: false,
      stakeEhxText: "",
      stakeEhxTxHash: "",
    },
  });

  const confirmationModalRef = useRef();
  const stakedata = useMemo(() => {
    return userTab == "stakeehx" ? stakeKeys[0] : stakeKeys[1];
    // return stakeKeys[0];
  }, [userTab]);

  useEffect(() => {
    if (walletAddress) {
      withdrawDividend();
      getBalance();
      calculateRewardFunction();
      usdcTotalDistributed();
    } else {
      setUsdcCollect(0);
      setUsdcDt(0);
      setECollectdt(0);
      setLastEXHtime(0);
      setLastUsdcTime(0);
      setEarntoDateFix(0);
      setpendingRewardsRaw(0);
      setpendingRewards(0);
    }
  }, [walletAddress, userTab]);

  const handleModalClose = (value) => {
    setShowModal(value);
  };

  const tokenReward = async (e) => {
    try {
      e.preventDefault();
      if (walletAddress && Number(usdcCollect) != 0) {
        setChainConfirmation(true);
        setShowModal(true);
        setModalData({
          approval: true,
          status: "",
          message: "",
          transactionHash: "",
        });
        const res = await CollectUSDC(walletAddress);

        if (res?.status) {
          setModalData({
            approval: true,
            mainFunction: true,
            transactionHash: res?.transactionHash,
            error: false,
          });
          withdrawDividend();
          usdcTotalDistributed();
          getBalance();
          calculateRewardFunction();
        } else {
          setModalData({
            approval: true,
            mainFunction: false,
            transactionHash: "",
            error: true,
            sendFunction: tokenReward,
          });
        }
      } else {
        toastMsg.error("Your USDT reward is zero.");
      }
    } catch (error) {
      setModalData({
        mainFunction: false,
        transactionHash: "",
        error: true,
        sendFunction: tokenReward,
      });
    }
  };

  const usdcTotalDistributed = async () => {
    const USDCdistribute = await USDCdistributed();
    props?.getusdcDistrubuted(toFixedNumber(Number(USDCdistribute)));
  };

  const withdrawDividend = async () => {
    const res = await WithdrawDividendof(walletAddress);
    // setUsdcCollect(toFixedNumber(res));
    setUsdcCollect(toCustomFixed(Number(res) / 10 ** 6, 4));
    return res;
  };

  const approvalAndAllowanceCheckStatus = async () => {
    try {
      const allowance = await TokenAllowance(
        walletAddress,
        tokenKeys[0],
        stakeKeys[0]
      );
      setChainConfirmation(true);
      setShowModal(true);

      setModalData({
        approval: false,
        status: "",
        message: "",
        transactionHash: "",
        stakeEhxReward: {
          stakeEhx: true,
          // stakeEhxWithdraw: false,
          stakeEhxWithdrawError: false,
          stakeEhxDepositError: false,
          stakeEhxdeposit: false,
          stakeEhxText: "",
          stakeEhxTxHash: "",
        },
      });

      // console.log("allowance", allowance);
      if (!allowance || allowance < localeStringFunction(pendingRewardsRaw)) {
        const approveContract = await approve(
          walletAddress,
          tokenKeys[0],
          stakeKeys[0],
          pendingRewardsRaw
        );

        if (approveContract?.status) {
          return approveContract?.status;
        } else {
          setModalData({
            approval: false,
            status: "",
            message: "",
            transactionHash: "",
            stakeEhxReward: {
              stakeEhx: true,
              stakeEhxWithdraw: false,
              stakeEhxWithdrawError: false,
              stakeEhxDepositError: false,
              stakeEhxdeposit: false,
              stakeEhxText: "",
              stakeEhxTxHash: "",
            },
          });
        }
      } else {
        setModalData({
          approval: false,
          status: "",
          message: "",
          transactionHash: "",
          stakeEhxReward: {
            stakeEhx: true,
            // stakeEhxWithdraw: false,
            stakeEhxWithdrawError: false,
            stakeEhxDepositError: false,
            stakeEhxdeposit: false,
            stakeEhxText: "",
            stakeEhxTxHash: "",
          },
        });
      }

      return allowance != 0 ? true : false;
    } catch (error) {
      // console.log("errorallowance", error);
    }
  };

  const stakeEhxReward = async (e) => {
    try {
      e.preventDefault();
      if (stakedata == "LPSTAKE") {
        return;
      }

      if (walletAddress) {
        // let stakedata = userTab == "stakeehx" ? stakeKeys[0] : stakeKeys[1];
        setChainConfirmation(true);
        setShowModal(true);
        setModalData({
          approval: false,
          status: "",
          message: "",
          transactionHash: "",
          stakeEhxReward: {
            stakeEhx: true,
            stakeEhxWithdrawError: false,
            stakeEhxDepositError: false,
            stakeEhxdeposit: false,
            stakeEhxText: "",
            stakeEhxTxHash: "",
          },
        });
        if (counter) {
          let allowance = await approvalAndAllowanceCheckStatus();

          setModalData({
            approval: allowance,
            status: "",
            message: "",
            transactionHash: "",
            stakeEhxReward: {
              stakeEhx: true,
              stakeEhxWithdraw: true,
              stakeEhxWithdrawError: false,
              stakeEhxDepositError: false,
              stakeEhxdeposit: false,
              stakeEhxText: "",
              stakeEhxTxHash: "",
            },
          });
          if (allowance) {
            const res = await stakeDeposit(
              walletAddress,
              pendingRewardsRaw,
              2,
              stakedata
            );
            if (res?.status) {
              setModalData({
                approval: true,
                mainFunction: true,
                transactionHash: res?.transactionHash,
                error: false,
                stakeEhxReward: {
                  stakeEhx: true,
                  // stakeEhxWithdraw: false,
                  stakeEhxdeposit: true,
                  stakeEhxText: "",
                  stakeEhxTxHash: res?.transactionHash,
                },
              });
              withdrawDividend();
              getBalance();
              calculateRewardFunction();
              props?.totalStakeinPlatform();
              setRewardInput("");
              toastMsg.success("Your EXH reward is Staked");
            } else {
              setModalData({
                approval: true,
                mainFunction: false,
                transactionHash: "",
                error: true,
                sendFunction: stakeEhxReward,
                stakeEhxReward: {
                  stakeEhx: true,
                  stakeEhxWithdraw: true,
                  stakeEhxWithdrawError: true,
                  stakeEhxDepositError: true,
                  stakeEhxdeposit: false,
                  stakeEhxText: "",
                  stakeEhxTxHash: "",
                },
              });
            }
          } else {
            setModalData({
              approval: false,
              status: "",
              message: "",
              transactionHash: "",
              stakeEhxReward: {
                stakeEhx: true,
                stakeEhxWithdraw: true,
                stakeEhxWithdrawError: false,
                stakeEhxDepositError: false,
                stakeEhxdeposit: false,
                stakeEhxText: "",
                stakeEhxTxHash: "",
              },
            });
          }
        } else {
          let allowance = await approvalAndAllowanceCheckStatus();

          if (allowance) {
            const res = await stakeWithdraw(walletAddress, 0, stakedata);

            if (res?.status) {
              withdrawDividend();
              getBalance();
              calculateRewardFunction();
              props?.totalStakeinPlatform();

              setModalData({
                approval: allowance,
                mainFunction: true,
                error: false,
                stakeEhxReward: {
                  stakeEhx: true,
                  // stakeEhxWithdraw: false,
                  stakeEhxWithdrawError: false,
                  stakeEhxDepositError: false,
                  stakeEhxdeposit: false,
                  stakeEhxText: "",
                  stakeEhxTxHash: "",
                },
              });
              // console.log("pendingRewardsRaw", pendingRewardsRaw);

              const res = await stakeDeposit(
                walletAddress,
                pendingRewardsRaw,
                2,
                stakedata
              );
              if (res?.status) {
                setModalData({
                  approval: true,
                  mainFunction: true,
                  transactionHash: res?.transactionHash,
                  error: false,
                  stakeEhxReward: {
                    stakeEhx: true,
                    stakeEhxWithdraw: true,
                    stakeEhxdeposit: true,
                    stakeEhxText: "",
                    stakeEhxTxHash: res?.transactionHash,
                  },
                });
                withdrawDividend();
                getBalance();
                calculateRewardFunction();
                props?.totalStakeinPlatform();
                setRewardInput("");
                toastMsg.success("Your EXH reward is Staked");
              } else {
                setModalData({
                  approval: true,
                  mainFunction: false,
                  transactionHash: "",
                  error: true,
                  sendFunction: stakeEhxReward,
                  stakeEhxReward: {
                    stakeEhx: true,
                    stakeEhxWithdraw: true,
                    stakeEhxWithdrawError: true,
                    stakeEhxDepositError: true,
                    stakeEhxdeposit: false,
                    stakeEhxText: "",
                    stakeEhxTxHash: "",
                  },
                });
              }
            } else {
              setModalData({
                approval: true,
                mainFunction: false,
                transactionHash: "",
                error: true,
                sendFunction: stakeEhxReward,
                stakeEhxReward: {
                  stakeEhx: true,
                  // stakeEhxWithdraw: false,
                  stakeEhxWithdrawError: true,
                  stakeEhxDepositError: true,
                  stakeEhxdeposit: false,
                  stakeEhxText: "",
                  stakeEhxTxHash: "",
                },
              });
            }
          } else {
            setModalData({
              approvalError: true,
              mainFunction: false,
              transactionHash: "",
              error: true,
              sendFunction: stakeEhxReward,
              stakeEhxReward: {
                stakeEhx: true,
                stakeEhxWithdraw: false,
                stakeEhxWithdrawError: true,
                stakeEhxDepositError: true,
                stakeEhxdeposit: false,
                stakeEhxText: "",
                stakeEhxTxHash: "",
              },
            });
          }
        }
      } else {
        toastMsg.info("Please connect your wallet to continue");
      }
    } catch (error) {
      setModalData({
        mainFunction: false,
        stakeEhxWithdrawError: true,
        stakeEhxDepositError: true,
        transactionHash: "",
        error: true,
        sendFunction: stakeEhxReward,
      });
    }
  };

  // const stakeEhxReward = async (e) => {
  //   try {
  //     e.preventDefault();
  //     if (stakedata == "LPSTAKE") {
  //       return;
  //     }

  //     if (walletAddress) {
  //       // let stakedata = userTab == "stakeehx" ? stakeKeys[0] : stakeKeys[1];
  //       setChainConfirmation(true);
  //       setShowModal(true);
  //       setModalData({
  //         approval: true,
  //         status: "",
  //         message: "",
  //         transactionHash: "",
  //         stakeEhxReward: {
  //           stakeEhx: true,
  //           // stakeEhxWithdraw: false,
  //           stakeEhxWithdrawError: false,
  //           stakeEhxDepositError: false,
  //           stakeEhxdeposit: false,
  //           stakeEhxText: "",
  //           stakeEhxTxHash: "",
  //         },
  //       });
  //       if (counter) {
  //         setModalData({
  //           approval: true,
  //           status: "",
  //           message: "",
  //           transactionHash: "",
  //           stakeEhxReward: {
  //             stakeEhx: true,
  //             stakeEhxWithdraw: true,
  //             stakeEhxWithdrawError: false,
  //             stakeEhxDepositError: false,
  //             stakeEhxdeposit: false,
  //             stakeEhxText: "",
  //             stakeEhxTxHash: "",
  //           },
  //         });

  //         console.log("pendingRewardsRaw", pendingRewardsRaw);
  //         const res = await stakeDeposit(
  //           walletAddress,
  //           pendingRewardsRaw,
  //           2,
  //           stakedata
  //         );
  //         if (res?.status) {
  //           setModalData({
  //             approval: true,
  //             mainFunction: true,
  //             transactionHash: res?.transactionHash,
  //             error: false,
  //             stakeEhxReward: {
  //               stakeEhx: true,
  //               // stakeEhxWithdraw: false,
  //               stakeEhxdeposit: true,
  //               stakeEhxText: "",
  //               stakeEhxTxHash: res?.transactionHash,
  //             },
  //           });
  //           withdrawDividend();
  //           getBalance();
  //           calculateRewardFunction();
  //           props?.totalStakeinPlatform();
  //           setRewardInput("");
  //           toastMsg.success("Your EXH reward is Staked");
  //         } else {
  //           setModalData({
  //             approval: true,
  //             mainFunction: false,
  //             transactionHash: "",
  //             error: true,
  //             sendFunction: stakeEhxReward,
  //             stakeEhxReward: {
  //               stakeEhx: true,
  //               stakeEhxWithdraw: true,
  //               stakeEhxWithdrawError: true,
  //               stakeEhxDepositError: true,
  //               stakeEhxdeposit: false,
  //               stakeEhxText: "",
  //               stakeEhxTxHash: "",
  //             },
  //           });
  //         }
  //       } else {
  //         const res = await stakeWithdraw(walletAddress, 0, stakedata);

  //         if (res?.status) {
  //           withdrawDividend();
  //           getBalance();
  //           calculateRewardFunction();
  //           props?.totalStakeinPlatform();
  //           setModalData({
  //             approval: true,
  //             mainFunction: true,
  //             error: false,
  //             stakeEhxReward: {
  //               stakeEhx: true,
  //               // stakeEhxWithdraw: false,
  //               stakeEhxWithdrawError: false,
  //               stakeEhxDepositError: false,
  //               stakeEhxdeposit: false,
  //               stakeEhxText: "",
  //               stakeEhxTxHash: "",
  //             },
  //           });
  //           console.log("pendingRewardsRaw", pendingRewardsRaw);

  //           const res = await stakeDeposit(
  //             walletAddress,
  //             pendingRewardsRaw,
  //             2,
  //             stakedata
  //           );
  //           if (res?.status) {
  //             setModalData({
  //               approval: true,
  //               mainFunction: true,
  //               transactionHash: res?.transactionHash,
  //               error: false,
  //               stakeEhxReward: {
  //                 stakeEhx: true,
  //                 stakeEhxWithdraw: true,
  //                 stakeEhxdeposit: true,
  //                 stakeEhxText: "",
  //                 stakeEhxTxHash: res?.transactionHash,
  //               },
  //             });
  //             withdrawDividend();
  //             getBalance();
  //             calculateRewardFunction();
  //             props?.totalStakeinPlatform();
  //             setRewardInput("");
  //             toastMsg.success("Your EXH reward is Staked");
  //           } else {
  //             setModalData({
  //               approval: true,
  //               mainFunction: false,
  //               transactionHash: "",
  //               error: true,
  //               sendFunction: stakeEhxReward,
  //               stakeEhxReward: {
  //                 stakeEhx: true,
  //                 stakeEhxWithdraw: true,
  //                 stakeEhxWithdrawError: true,
  //                 stakeEhxDepositError: true,
  //                 stakeEhxdeposit: false,
  //                 stakeEhxText: "",
  //                 stakeEhxTxHash: "",
  //               },
  //             });
  //           }
  //         } else {
  //           setModalData({
  //             approval: true,
  //             mainFunction: false,
  //             transactionHash: "",
  //             error: true,
  //             sendFunction: stakeEhxReward,
  //             stakeEhxReward: {
  //               stakeEhx: true,
  //               // stakeEhxWithdraw: false,
  //               stakeEhxWithdrawError: true,
  //               stakeEhxDepositError: true,
  //               stakeEhxdeposit: false,
  //               stakeEhxText: "",
  //               stakeEhxTxHash: "",
  //             },
  //           });
  //         }
  //       }
  //     } else {
  //       toastMsg.info("Please connect your wallet to continue");
  //     }
  //   } catch (error) {
  //     setModalData({
  //       mainFunction: false,
  //       stakeEhxWithdrawError: true,
  //       stakeEhxDepositError: true,
  //       transactionHash: "",
  //       error: true,
  //       sendFunction: stakeEhxReward,
  //     });
  //   }
  // };
  const collectEXH = async (e) => {
    try {
      e.preventDefault();
      if (stakedata == "LPSTAKE") {
        return;
      }

      if (walletAddress) {
        setChainConfirmation(true);
        setShowModal(true);
        setModalData({
          approval: true,
          status: "",
          message: "",
          transactionHash: "",
        });
        const res = await CollectEXH(walletAddress, stakedata);
        if (res?.status) {
          setModalData({
            approval: true,
            mainFunction: true,
            transactionHash: res?.transactionHash,
            error: false,
          });
          withdrawDividend();
          getBalance();
          calculateRewardFunction();
        } else {
          setModalData({
            approval: true,
            mainFunction: false,
            transactionHash: "",
            error: true,
            sendFunction: collectEXH,
          });
        }
      }
    } catch (error) {
      setModalData({
        mainFunction: false,
        transactionHash: "",
        error: true,
        sendFunction: collectEXH,
      });
    }
  };

  const getBalance = async () => {
    if (stakedata == "LPSTAKE") {
      return;
    }

    const resReward = await rewardPendingEXH(walletAddress);
    setrewardEXH(resReward);

    const usdcCollectRes = await USDCCollecttoDate(walletAddress);
    // setUsdcDt(toFixedNumber(usdcCollectRes));
    setUsdcDt(toCustomFixed(Number(usdcCollectRes) / 10 ** 6, 4));

    // calculating reward time
    // let stakedata = userTab == "stakeehx" ? stakeKeys[0] : stakeKeys[1];

    const stakeAmount = await getStakedAmount(walletAddress, stakedata);
    const time = moment(Number(stakeAmount?.rewardsClaimTime) * 1000).format(
      "MMMM Do YYYY, h:mm:ss a"
    );
    setLastEXHtime(
      Number(stakeAmount?.rewardsClaimTime) &&
        Number(stakeAmount?.rewardsClaimTime) != 0
        ? time
        : 0
    );

    // calculating usdc time

    const usdctime = await USDCLastCollectedTime(walletAddress);
    const usdcTime = moment(Number(usdctime) * 1000).format(
      "MMMM Do YYYY, h:mm:ss a"
    );
    setLastUsdcTime(usdctime && usdctime != 0 ? usdcTime : 0);
  };

  const calculateRewardFunction = async () => {
    // let stakedata = userTab == "stakeehx" ? stakeKeys[0] : stakeKeys[1];
    if (stakedata == "LPSTAKE") {
      return;
    }

    const EXHrewardClaimed = await EXHCollectt(walletAddress, stakedata);

    const stakeAmount = await getStakedAmount(walletAddress, stakedata);
    const poolInfo = await getPollInfo(stakedata);
    const getRewardRecovering = await getRewardRecovery(stakedata);
    const Pendingreward = await PendingrewardsFunction(
      walletAddress,
      stakedata
    );
    // const getTotalLPtoken = await getTotalLP();
    // ==========================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    // calculating stake
    const pendingRewardLHS = (
      (Number(stakeAmount?.amount) * Number(poolInfo?.accTokensPerShare)) /
      10 ** 12
    ).toLocaleString("fullwide", {
      useGrouping: !1,
    });
    const pendingRewards = (
      Number(pendingRewardLHS) - Number(stakeAmount?.rewardDebt)
    ).toLocaleString("fullwide", {
      useGrouping: !1,
    });

    if (toFixedNumber(Number(Pendingreward)) == 0) {
      setcollectAndStakeRewardDisable(true);
      counter = true;
    } else {
      counter = false;
      setcollectAndStakeRewardDisable(false);
    }
    let resReward;

    if (Number(Pendingreward) >= 0) {
      if (Number(Pendingreward) < Number(getRewardRecovering)) {
        setpendingRewards(toFixedNumber(Number(Pendingreward)));
        setpendingRewardsRaw(Number(Pendingreward));
        resReward = Number(Pendingreward);
      } else {
        setpendingRewards(toFixedNumber(Number(getRewardRecovering)));
        setpendingRewardsRaw(Number(getRewardRecovering));
        // 1.4929715265265964e23;
        resReward = Number(getRewardRecovering);
      }
    } else {
      resReward = 0;
    }
    // ==========================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    setECollectdt(toFixedNumber(Number(EXHrewardClaimed)));

    setEarntoDateFix(
      toFixedNumber(Number(Pendingreward) * 1 + Number(EXHrewardClaimed) * 1)
    );
  };

  return (
    <div className="staking-card">
      {!userTab || userTab == "stakeehx" ? (
        <Form>
          {chainConfirmation && (
            <ConfirmationModal
              ref={confirmationModalRef}
              modalData={modalData}
              showModal={showModal}
              handleModalClose={handleModalClose}
            />
          )}
          <div className="d-flex justify-content-between align-items-center staking-card__title">
            <h5>Your Profit Sharing (USDT) Reward</h5>
          </div>
          <CustomInput
            value={usdcCollect}
            icon={usdt}
            coin={`${usdcCollect}  USDT`}
            className="mb-30"
            disabled={disabled}
            collectHide={collectHide}
          />
          <div className="staking-card__btns staking-card__btns--single">
            <CustomButton
              title="Collect USDT"
              onClick={(e) => tokenReward(e)}
            />
          </div>
          <ul className="staking-info__list mb-30">
            <li>
              Last USDT collection date:
              <span>{LastUsdcTime || "--"}</span>
            </li>
            <li
              title={
                `$ ${toCustomFixed(Number(usdcCollect) + Number(usdcDt), 8)}` ||
                "0.00"
              }
            >
              USDT earned to date:
              <span>
                {`$ ${Number(usdcCollect) + Number(usdcDt)}` || "0.00"}
              </span>
            </li>
            <li title={(usdcDt && "$" + toCustomFixed(usdcDt, 8)) || "0.00"}>
              USDT collected to date:
              <span>{(usdcDt && "$" + usdcDt) || "0.00"}</span>
            </li>
          </ul>
        </Form>
      ) : null}
      <Form>
        {chainConfirmation && (
          <ConfirmationModal
            ref={confirmationModalRef}
            modalData={modalData}
            showModal={showModal}
            handleModalClose={handleModalClose}
          />
        )}
        <div className="d-flex justify-content-between align-items-center staking-card__title">
          <h5>Your EHX Reward</h5>
        </div>
        <CustomInput
          value={pendingRewards}
          icon={ehx}
          coin={`${pendingRewards || 0}  EHX`}
          className="mb-30"
          disabled={disabled}
          dollarValue={true}
        />

        <div className="staking-card__btns">
          <CustomButton
            title="Stake your EHX Reward"
            onClick={(e) => stakeEhxReward(e)}
            // disabled={collectAndStakeRewardDisable}
            // className={collectAndStakeRewardDisable ? "disabled" : ""}
          />
          <CustomButton
            title="Collect EHX"
            onClick={(e) => collectEXH(e)}
            // disabled={collectAndStakeRewardDisable}
            // className={collectAndStakeRewardDisable ? "disabled" : ""}
          />
        </div>
        <ul className="staking-info__list">
          <li>
            Last EHX collection date:
            <span>{LastEXHtime || "--"}</span>
          </li>
          <li title={toCustomFixedFunction(Number(earntoDateFix), 8) || "0.00"}>
            EHX earned to date:
            <span>
              {valueWithDollarFormat(
                toFunctionBigNumber(Number(earntoDateFix))
              ) || "0.00"}
            </span>
          </li>

          <li title={toCustomFixedFunction(Number(ECollectdt), 8) || "0.00"}>
            EHX collected to date:
            <span>
              {valueWithDollarFormat(toFunctionBigNumber(Number(ECollectdt))) ||
                "0.00"}
            </span>
          </li>
        </ul>
      </Form>
    </div>
  );
};

export default StakingReward;
