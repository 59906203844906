import React, { useRef, useState } from "react";
import { Navbar, Offcanvas } from "react-bootstrap";
import Logotext from "../../assets/images/Logo-Text.svg";
import MobileLogo from "../../assets/images/logo_icon.svg";
import "./Header.scss";
import RightNav from "./RightNav";
import { useDispatch } from "react-redux";
import { reset } from "../../redux/_slices/xy.slice";

import NavbarMenu from "./NavbarMenu";
import { NETWORK_INFO, NETWORK_INFOMATION } from "../../constants/wallet";

const Header = () => {
  const rightNavRef = useRef(null);

  const [showmenu, setShowMenu] = useState(false);

  const handleClose = () => setShowMenu(false);
  const handleShow = () => setShowMenu(true);

  return (
    <>
      <div className="site_header">
        <Navbar collapseOnSelect expand="xl" id="navbarSupportedContent">
          <Navbar.Brand className="d-flex align-items-center" href="/">
            <img
              className="Logo_Icon_animate d-block"
              src={MobileLogo}
              alt={"logo-icon"}
            />
            <img
              className="d-none d-md-block ms-3"
              src={Logotext}
              alt={"logo-icon"}
            />
          </Navbar.Brand>
          <Navbar.Collapse className="d-none d-xl-block">
            <NavbarMenu />
          </Navbar.Collapse>

          <div className="d-flex headerNav">
            <RightNav ref={rightNavRef} />
            <Navbar.Toggle onClick={handleShow} />
          </div>
        </Navbar>
        <Offcanvas
          className="site_header d-block d-xl-none"
          placement="end"
          show={showmenu}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <NavbarMenu />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export { Header };
