import React, { useEffect, useState } from "react";
import "./WithdrawModal.scss";
import { Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import icon1 from "../../../../../assets/images/tether-usdt.svg";
import CrossIcon from "../../../../../assets/icons/red_cross.svg";
import { Link } from "react-router-dom";
import {
  amountValidationOnInput,
  showDecimal,
} from "../../../../../_utils/comman";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../hooks/redux.hooks";
import { ApiServices } from "../../../services/api.services";
import TxnSuccessfulModal from "../../../common/TxnSuccessModal";
import { useChainInfo } from "../../../hooks/hooks.service";
import { NETWORK_INFO } from "../../../../../constants/wallet";

const WithdrawModal = ({ handleClose, show }) => {
  const setTokenList = [
    {
      coinIcon: icon1,
      tokenname: "USDT",
    },
  ];
  const [showsuccessful, setShowsuccessful] = useState(false);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);
  const [termCheck, setTermCheck] = useState(false);
  const [isTxnComplete, setIsTxnComplete] = useState(true);
  const [spotBalance, setSpotBalance] = useState(0);
  const [networkName, setNetworkName] = useState("");
  const [coinInfo, setCoinInfo] = useState(null);
  const [defaultNetwork, setDefaultNetwork] = useState({});
  const [isNetworkChanged, setIsNetworkChanged] = useState(false);

  const chainInfo = useChainInfo();
  const handleClosesuccessful = () => setShowsuccessful(false);
  const walletBalance = useAppSelector(
    (state) => state.user.userWalletBalances
  );
  const chainId = useAppSelector((state) => state.user.chainId);
  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const [address, setAddress] = useState(walletAddress);
  const [withdrawAddress, setWithdrawAddress] = useState(walletAddress);
  const [eternaFee, setEternaFee] = useState(0);

  /**
   * handle on change for input amount
   * @param {*} event
   */

  const handleCloseSubmit = () => {
    handleClose();
    setError(null);
  };

  useEffect(() => {
    setWithdrawAddress(walletAddress);
    setAddress(walletAddress);
  }, [walletAddress]);

  useEffect(() => {
    setCoinInfo(chainInfo.dataObj.find((doc) => doc.chain === networkName));
  }, [networkName, chainInfo]);

  useEffect(() => {
    if (!isNetworkChanged) {
      setNetworkName(
        NETWORK_INFO.find((doc) => doc.chain_id === Number(chainId))?.symbol
      );
      setDefaultNetwork(
        NETWORK_INFO.find((doc) => doc.chain_id === Number(chainId))
      );
    }
  }, [chainId, chainInfo]);

  useEffect(() => {
    getEternaFee();
  }, [walletBalance]);

  const getEternaFee = async () => {
    let widthdrawFee = await ApiServices.getWithdrawFee();
    if (widthdrawFee?.status == 200 && widthdrawFee.data) {
      setEternaFee(widthdrawFee?.data);
    }
  };

  const handleAddressChange = (event) => {
    const { value } = event.target;
    setWithdrawAddress(value);
  };

  const handleOnChangeAmount = (event) => {
    let maxAmount;
    maxAmount = showDecimal(walletBalance?.spotTransferBalance, 8);
    // maxAmount = 0.1;
    const { value } = event.target;
    let valData = value;

    if (Number(valData) === 0) {
      setError("Please enter amount greater than 0");
    } else if (Number(valData) > Number(maxAmount)) {
      if (maxAmount === 0) {
        setError(`You have no balance to withdraw`);
      } else {
        setError(`Please enter an amount less than max withdraw amount.`);
      }
    } else if (Number(valData) < coinInfo?.withdrawMin) {
      setError(`You must withdraw minimum ${coinInfo?.withdrawMin} USDT`);
    } else {
      setError(null);
    }
    setAmount(valData);
  };

  const amountMaxOnInput = async (event) => {
    handleOnChangeAmount({
      target: { value: walletBalance?.spotTransferBalance },
    });
  };

  const handleWithdrawSubmit = async () => {
    try {
      let dataObj = {
        address: address,
        amount: Number(amount),
        chainName: networkName,
        withdrawAddress: withdrawAddress,
      };
      const res = await ApiServices.withdraw(dataObj);
      if (res) {
        handleClose();
        setShowsuccessful(true);
        setIsTxnComplete(true);
      }
    } catch (error) {
      setIsTxnComplete(true);
    }
  };

  const handleWalletNetworkChange = (event) => {
    setIsNetworkChanged(true);
    setNetworkName(event.symbol);
    setDefaultNetwork(event);
  };

  return (
    <>
      <Modal
        className="add_profit_Modal withdraw_modal"
        centered
        show={show}
        onHide={() => {
          handleCloseSubmit();
          setAmount("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="pt-2">
            <Col xs={12}>
              <div className="Input_Group mb-3">
                <label>Select Coin</label>
                <Dropdown className="profit_dropdown" disabled>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <span>
                      <img src={icon1} alt="coin icon" />
                    </span>
                    <p>USDT</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu disabled>
                    {setTokenList.map((data, i) => {
                      return (
                        <Dropdown.Item key={i} to="javascript:;">
                          <span>
                            <img src={data.coinIcon} alt="coin icon" />
                          </span>
                          <p>{data.tokenname}</p>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
            <Col xs={12}>
              <div className="Input_Group mb-3">
                <label>Select Wallet Network</label>
                <Dropdown
                  className="profit_dropdown"
                  disabled
                  onChange={(event) => handleWalletNetworkChange(event)}
                >
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    {/* <span>
                      <img src={icon1} alt="coin icon" />
                    </span> */}
                    <p>{defaultNetwork?.label}</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {NETWORK_INFO.map((item, i) => {
                      return (
                        <Dropdown.Item key={i} to="javascript:;">
                          <p>{item.label}</p>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
            <Col xs={12}>
              <div className="Input_Group">
                <label>Wallet Address</label>
                <input
                  type="text"
                  className="input-type2"
                  placeholder="Wallet Address"
                  autoComplete="off"
                  defaultValue={walletAddress}
                  onChange={(event) => handleAddressChange(event)}
                />
              </div>
            </Col>
            <Col xs={12} className="pt-2">
              <div className="Input_Group">
                <label>
                  <div>
                    Amount Available:{" "}
                    <span>
                      {walletBalance?.spotTransferBalance
                        ? showDecimal(walletBalance?.spotTransferBalance, 2)
                        : 0}{" "}
                      USDT
                    </span>
                  </div>
                  <div>
                    Withdraw Fee:{" "}
                    <span>{coinInfo ? coinInfo.withdrawFee : 0} USDT</span>
                  </div>
                  <div>
                    Min Withdraw:{" "}
                    <span>{coinInfo ? coinInfo.withdrawMin : 0} USDT</span>
                  </div>
                </label>
                <input
                  type="text"
                  className="input-type2"
                  placeholder="0.00"
                  onChange={(event) => {
                    if (/^\d*(\.\d{0,8})?$/.test(event.target.value)) {
                      handleOnChangeAmount(event);
                    }
                  }}
                  onInput={(event) => {
                    amountValidationOnInput(event, 8);
                  }}
                  value={amount}
                />
                <span className="span-max-2" onClick={amountMaxOnInput}>
                  Max
                </span>
              </div>
            </Col>
          </Row>

          <h6 className="mt-4"></h6>

          <hr className="common_hr my-4" />

          <h6>
            Before withdrawing, please accept our Terms & Conditions and Privacy
            Policy.
          </h6>

          <div className="agree_checkBox d-flex align-items-center my-3">
            <Form.Check
              checked={termCheck}
              onClick={() => {
                setTermCheck(!termCheck);
              }}
              className="form-check blue_check_box"
              aria-label="option 1"
            />
            <p>
              I agree to the{" "}
              <Link to="/">
                <u>Terms </u>
              </Link>
              and
              <Link to="/">
                <u> Privacy Policy</u>
              </Link>
            </p>
          </div>

          {/* <div className="agree_checkBox d-flex align-items-center my-2">
            <Form.Check
              className="form-check blue_check_box"
              aria-label="option 1"
            />
            <p>
              I agree to the{" "}
              <Link to="/">
                <u>Terms & Conditions</u>
              </Link>
            </p>
          </div> */}

          {error && (
            <div className="error_msg d-flex align-items-center mt-4">
              <img src={CrossIcon} alt="cross" />
              <p className="mb-0 text_red ms-3">
                {error}
                {/* <span className="d-block">Please fund your account.</span> */}
              </p>
            </div>
          )}

          <div className="mt-4 pt-2">
            <CommonButton
              disabled={error || amount === "" || !termCheck || !isTxnComplete}
              onClick={handleWithdrawSubmit}
              title="Confirm"
              className="w-100"
            />
          </div>
        </Modal.Body>
      </Modal>
      <TxnSuccessfulModal
        show={showsuccessful}
        amount={amount}
        modalType="Withdraw"
        handleClose={handleClosesuccessful}
      />
    </>
  );
};

export default WithdrawModal;
