import React from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import "./Faq.scss"
import faqicon from "../../../../../assets/images/admin/helpdesk/FAQ.svg"
import TopbarHeading from '../../../common/TopbarHeading/TopbarHeading'
import Admin from '../../../Admin'
import guideimg from "../../../../../assets/images/admin/helpdesk/guide_img.svg"
import Breadcrumbs from '../../../common/Breadcrumbs/Breadcrumbs'
import ArrowIcon from "../../../../../assets/images/admin/blue-arrow-right-circle.svg";

const Faq = () => {

  return (
    <Admin>
      <div className='Common_TopBar'>
        <Row className='align-items-center'>
          <Col xs={12} xl={8}>
            <TopbarHeading title="Frequently Asked Questions" />
          </Col>
          {/* <Col xs={12} xl={4} className="d-md-flex justify-content-xl-end">
          </Col> */}
        </Row>
      </div>
      <Breadcrumbs pagelink="/helpdesk" mainpage="Helpdesk" pagename="Frequently Asked Questions" />
      <div className='Common_admin_Contentarea FaQ'>
        <Container className='mb-4 mb-md-5'>
          <Row className='justify-content-center'>
            <Col xs={12} md={6} xxl={5} className='text-center'>
              <img src={faqicon} alt='icon' />
              <h5 className='font18 mt-4'>Find answers and solutions to common questions, problem and difficulties using the exchange platform</h5>
            </Col>
            <Col xs={12} md={6} xxl={6} className="text-xl-end mt-4 mt-md-0">
              <img src={guideimg} alt='icon' />
            </Col>
          </Row>
        </Container>
        <hr />

        <Tab.Container id="left-tabs-example" defaultActiveKey="tab1">
          <Row className='mt-4 mt-md-5'>
            <Col md={4} xxl={3}>
              <Nav variant="pills" className="flex-md-column FaQ_Tabs">
                <Nav.Item><Nav.Link eventKey="tab1">Getting started</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="tab2">Account Funding</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="tab3">Account Security</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="tab4">Spot Trading</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="tab5">Futures Trading</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="tab6">EHX Pro</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="tab7">EHX Lite</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="tab8">Earn & Reward Tiers</Nav.Link></Nav.Item>
              </Nav>
            </Col>
            <Col md={8} xxl={9}>
              <Tab.Content className='FaQ_Content'>
                <Tab.Pane eventKey="tab1">
                  <div className='FaQ_Content_Tabs'>
                    <h2>Getting started</h2>
                    <ul>
                      <li>How do I deposit or withdraw funds? <img src={ArrowIcon} alt='icon' /></li>
                      <li>What does the status of my deposit or withdrawal mean? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is my deposit reference important? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is there a withdrawal hold on my account? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Can I deposit or withdraw using physical cash, debit or credit card - or similar? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab2">
                  <div className='FaQ_Content_Tabs'>
                    <h2>Account Funding</h2>
                    <ul>
                      <li>How do I deposit or withdraw funds? <img src={ArrowIcon} alt='icon' /></li>
                      <li>What does the status of my deposit or withdrawal mean? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is my deposit reference important? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab3">
                  <div className='FaQ_Content_Tabs'>
                    <h2>Account Security</h2>
                    <ul>
                      <li>How do I deposit or withdraw funds? <img src={ArrowIcon} alt='icon' /></li>
                      <li>What does the status of my deposit or withdrawal mean? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is my deposit reference important? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is there a withdrawal hold on my account? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Can I deposit or withdraw using physical cash, debit or credit card - or similar? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab4">
                  <div className='FaQ_Content_Tabs'>
                    <h2>Account Funding</h2>
                    <ul>
                      <li>How do I deposit or withdraw funds? <img src={ArrowIcon} alt='icon' /></li>
                      <li>What does the status of my deposit or withdrawal mean? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is my deposit reference important? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab5">
                  <div className='FaQ_Content_Tabs'>
                    <h2>Getting started</h2>
                    <ul>
                      <li>How do I deposit or withdraw funds? <img src={ArrowIcon} alt='icon' /></li>
                      <li>What does the status of my deposit or withdrawal mean? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is my deposit reference important? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is there a withdrawal hold on my account? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Can I deposit or withdraw using physical cash, debit or credit card - or similar? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab6">
                  <div className='FaQ_Content_Tabs'>
                    <h2>Account Funding</h2>
                    <ul>
                      <li>How do I deposit or withdraw funds? <img src={ArrowIcon} alt='icon' /></li>
                      <li>What does the status of my deposit or withdrawal mean? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is my deposit reference important? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab7">
                  <div className='FaQ_Content_Tabs'>
                    <h2>Getting started</h2>
                    <ul>
                      <li>How do I deposit or withdraw funds? <img src={ArrowIcon} alt='icon' /></li>
                      <li>What does the status of my deposit or withdrawal mean? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is my deposit reference important? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is there a withdrawal hold on my account? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Can I deposit or withdraw using physical cash, debit or credit card - or similar? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab8">
                  <div className='FaQ_Content_Tabs'>
                    <h2>Account Funding</h2>
                    <ul>
                      <li>How do I deposit or withdraw funds? <img src={ArrowIcon} alt='icon' /></li>
                      <li>What does the status of my deposit or withdrawal mean? <img src={ArrowIcon} alt='icon' /></li>
                      <li>Why is my deposit reference important? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                      <li>How can I earn referral rewards? <img src={ArrowIcon} alt='icon' /></li>
                    </ul>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div >
    </Admin >
  )
}

export default Faq