import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { RightarrowIcon } from '../../../../../assets/Svg/SvgImages';
import "./LandingAssets.scss";
import ReferIcon from "../../../../../assets/images/admin/news.svg";
import coinIcon from "../../../../../assets/images/admin/coinsIcon.svg"
import newsicon from "../../../../../assets/icons/menuicon12.svg"
import { Link } from 'react-router-dom';
import CommonButton from '../../../../../common/CommonButton/CommonButton';
import WalletIcon from "../../../../../assets/icons/wallet_icon.svg";
import tradeIcon from "../../../../../assets/images/admin/trade-btc-color-var.svg";
import mobileImg from "../../../../../assets/images/admin/iphoneEHX-app.svg"
import iosIcon from "../../../../../assets/images/admin/apple-icon.svg";
import androidIcon from "../../../../../assets/images/admin/android-icon.svg";

const LandingAssets = () => {
    const counterdata = [
        {
            title: "$28+ Billion",
            content: "in total pooled and cross-chain liquidity",
        },
        {
            title: "500+",
            content: "cryptocurrencies listed",
        },
        {
            title: "6 million",
            content: "users and rapidly growing",
        },
        {
            title: "Sub-Zero",
            content: "transaction fees",
        },
    ];
    return (
        <>
            <Row>
                <Col xs={12} lg={7} xl={8} xxl={9}>
                    <div className='Dashboard_Overview'>
                        <div className='Dashboard_Overview_Heading mb-5'>
                            <h2>Buy, sell & trade crypto <span>on a truly decentralized exchange</span></h2>
                            <h4 className='font24 mt-3 mb-3 mb-md-4 pb-2'><img src={coinIcon} alt="icon" /> Trade Bitcoin, Ethereum + much more quickly and easily</h4>
                            <div className='d-sm-flex align-items-center'>
                                <CommonButton title="Connect your wallet" icon={WalletIcon} btnicon="d-block" />
                                <div className='d-flex align-items-center mt-3 mt-sm-0 ms-sm-3'>
                                    <h3 className='font24 mb-0 me-3'>to start trading now!</h3>
                                    <img src={tradeIcon} alt="icon" />
                                </div>
                            </div>
                        </div>
                        <Row className='justify-content-center my-4 m-xl-5 py-2 py-xl-5'>
                            {counterdata.map((item, i) => {
                                return (
                                    <Col xs={6} md={6} xl={3}>
                                        <div className='Dashboard_Overview_counter'>
                                            <h3>{item.title}</h3>
                                            <p>{item.content}</p>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row className='justify-content-center m'>                            
                            <Col xs={12} xl={5} className='order-xl-last'>
                                <div className='coming_Exh'>
                                    <h2>COMING SOON</h2>
                                    <h3>EHX iOS & Android <i>Pro</i></h3>
                                    <p className='mt-4'>Take your mobile trading to the next level with the new Pro version of our iOS and Android exchange app - coming to Apple and Google Store soon!</p>

                                    <div className='subscribe_input mt-4 mt-xl-5'>
                                        <h5>SUBSCRIBE TO THE WAITING LIST</h5>
                                        <div className='emial_Input'>
                                            <input type='email' className='custom_input' placeholder="Enter your email address" />
                                            <RightarrowIcon />
                                        </div>
                                    </div>
                                    
                                    <div className='d-flex justify-content-center'>
                                        <div className='apple_android'>
                                            <img src={iosIcon} alt="Apple ios" />
                                            <h5 className='font20 mt-3'>Apple iOS</h5>
                                        </div>
                                        <div className='apple_android'>
                                            <img src={androidIcon} alt="Apple ios" />
                                            <h5 className='font20 mt-3'>Android</h5>
                                        </div>
                                    </div>

                                    <CommonButton title="SEE A DEMO" className="mt-4 mt-md-5 mx-auto d-table"/>
                                </div>
                            </Col>
                            <Col xs={12} xl={7}>
                                <figure className='mb-0'>
                                    <img src={mobileImg} alt='Mobile img' />
                                </figure>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={12} lg={5} xl={4} xxl={3}>
                    <div className='Refer_Code'>
                        <div className=''>
                            <img src={ReferIcon} alt='icon' />
                        </div>

                        <div className='Refer_Code_Newscontent'>
                            <div className='Refer_News'>
                                <img src={newsicon} alt='icon' />
                                <h4> News & Announcements</h4>
                            </div>
                            <ul className='News_List mb-4 mt-3'>
                                <li>
                                    <p>EHX lists new trading pairs: <strong>EHX, CGPT, MMX and GRM</strong></p>
                                    <p>New trading pairs will be listed <span>2023-04-05 at 08:01:10 UTC</span></p>
                                </li>
                                <li>
                                    <p>EHX to delists trading pairs: <strong>EVA, TRX, DRM</strong></p>
                                    <p>Please note that these trading pairs will be delisted <span>2023-04-05 at 08:01:10 UTC</span></p>
                                </li>
                                <li>
                                    <p>EHX to delists trading pairs: <strong>EVA, TRX, DRM</strong></p>
                                    <p>Please note that these trading pairs will be delisted <span>2023-04-05 at 08:01:10 UTC</span></p>
                                </li>
                                <li>
                                    <p>EHX to delists trading pairs: <strong>EVA, TRX, DRM</strong></p>
                                    <p>Please note that these trading pairs will be delisted <span>2023-04-05 at 08:01:10 UTC</span></p>
                                </li>
                                <li>
                                    <p>EHX to delists trading pairs: <strong>EVA, TRX, DRM</strong></p>
                                    <p>Please note that these trading pairs will be delisted <span>2023-04-05 at 08:01:10 UTC</span></p>
                                </li>
                            </ul>
                            <Link className='text_link' to="/">View All <RightarrowIcon /></Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default LandingAssets