import Web3 from "web3";
import {
  callWeb3,
  provider,
  coinbase_ethereum_provider,
} from "./walletServices";
import store from "../redux/_store/configureStore";
import { RPCURL } from "../constants/constants";

const wallets = [
  "metamask",
  "walletconnect",
  "binance_wallet",
  "trust_wallet",
  "Coinbase_Wallet",
  "Token_Pocket",
  "Brave",
  "metamask",
];
export const commonAbiInstances = async (address, abi) => {
  try {
    const { ethereum, web3, BinanceChain, trustwallet } = window;

    let _web3Instance;
    const account = store.getState()?.user?.walletAddress;
    const wallettype = store.getState()?.user?.wallet;
    // if (wallettype == wallets[0] && ethereum && !!account) {
    //   let _web3Instance = await callWeb3(wallettype);
    //   let Instance = new _web3Instance.eth.Contract(abi, address);
    //   return Instance;
    // } else if (wallettype == wallets[1] && provider && !!account) {
    //   _web3Instance = await callWeb3(wallettype);
    //   let Instance = new _web3Instance.eth.Contract(abi, address);
    //   return Instance;
    // } else if (wallettype == wallets[2] && BinanceChain && !!account) {
    //   _web3Instance = await callWeb3(wallettype);
    //   let Instance = new _web3Instance.eth.Contract(abi, address);
    //   return Instance;
    // } else if (wallettype == wallets[3] && trustwallet && !!account) {
    //   _web3Instance = await callWeb3(wallettype);
    //   let Instance = new _web3Instance.eth.Contract(abi, address);
    //   return Instance;
    // } else if (
    //   wallettype == wallets[4] &&
    //   coinbase_ethereum_provider &&
    //   !!account
    // ) {
    //   _web3Instance = await callWeb3(wallettype);
    //   let Instance = new _web3Instance.eth.Contract(abi, address);
    //   return Instance;
    // } else if (wallettype == wallets[5] && ethereum && !!account) {
    //   _web3Instance = await callWeb3(wallettype);
    //   let Instance = new _web3Instance.eth.Contract(abi, address);
    //   return Instance;
    // } else if (wallettype == wallets[6] && ethereum && !!account) {
    //   _web3Instance = await callWeb3(wallettype);
    //   let Instance = new _web3Instance.eth.Contract(abi, address);
    //   return Instance;
    // } else if (wallettype == wallets[7] && ethereum && !!account) {
    //   _web3Instance = await callWeb3(wallettype);
    //   let Instance = new _web3Instance.eth.Contract(abi, address);
    //   return Instance;
    // } else {
    //   let rpc = RPCURL;
    //   _web3Instance = new Web3(rpc);
    _web3Instance = await callWeb3(wallettype);
    let Instance = new _web3Instance.eth.Contract(abi, address);
    return Instance;
    // }
  } catch (error) {
    console.log("e_web3Instancerror", error);
  }
};

export const toWeiConvert = async (amount) => {
  return await Web3.utils.toWei(amount, "ether");
};

export const fromWeiConvert = async (amount) => {
  return await Web3.utils.fromWei(amount, "ether");
};
