import React from "react";
import Modal from "react-bootstrap/Modal";
import CommonButton from "../../../common/CommonButton/CommonButton";
import SuccessfulIcon from "../../../assets/images/admin/transaction-success.svg";
import usdtIcon from "../../../assets/images/tether-usdt.svg";
import { isMobile } from "react-device-detect";

const NetworkSwitchModal = ({ show, handleClose }) => {
  return (
    <>
      <Modal
        className="Admin_Modal Conversion_successful_modal"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header
          className="text-center justify-content-center"
          closeButton
        >
          {/* <Modal.Title>Switch Network</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="Conversion_successful mb-0 text-center">
            <img className="my-5" src={SuccessfulIcon} alt="icon" />
            <div className="d-flex align-items-center justify-content-center">
              {!isMobile ? (
                <p className="font24 me-3">
                  To change network please change it on the device used to scan
                  the QR code.
                </p>
              ) : (
                <p className="font24 me-3">
                  Please open your Wallet application to change the network.
                </p>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <CommonButton
              className="w-50 me-2"
              title="Done"
              onClick={handleClose}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NetworkSwitchModal;
