import React, { useEffect, useState } from "react";
import "./DepositModal.scss";
import { Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import icon1 from "../../../../../assets/images/tether-usdt.svg";
import CrossIcon from "../../../../../assets/icons/red_cross.svg";
import { Link } from "react-router-dom";
import DepositConversionModal from "./DepositConversionModal";
import {
  amountValidationOnInput,
  showDecimal,
} from "../../../../../_utils/comman";
import { web3Services } from "../../../../../services/web3.service";
import { useDispatch } from "react-redux";
import { saveUserUSDTBalance } from "../../../../../redux/_slices/user.slice";
import { useAppSelector } from "../../../../../hooks/redux.hooks";
import { GAS_LIMIT } from "../../../../../constants/constants";
import { depositToContract } from "../../../../../redux/_actions/web3.actions";
import BigNumber from "bignumber.js";
import { toastAlert, toastMsg } from "../../../../../common/Toast/Toast";
import TxnSuccessfulModal from "../../../common/TxnSuccessModal";
import { inputDecimal } from "../../../../../services/HelperServices";
import { NETWORK_INFO } from "../../../../../constants/wallet";
import { useChainInfo } from "../../../hooks/hooks.service";
import { useAccount } from "wagmi";

const DepositModal = ({ handleClose, show }) => {
  const setTokenList = [
    {
      coinIcon: icon1,
      tokenname: "USDT",
    },
  ];
  const dispatch = useDispatch();
  const [showsuccessful, setShowsuccessful] = useState(false);
  const [coin, setCoin] = useState("USDT");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);
  const [userBalance, setUserBalance] = useState(0);
  const [termCheck, setTermCheck] = useState(false);
  const [gasPrice, setGasPrice] = useState(0);
  const [isTxnComplete, setIsTxnComplete] = useState(true);
  const [networkName, setNetworkName] = useState("");
  const chainInfo = useChainInfo();
  const { connector } = useAccount();

  const handleClosesuccessful = () => setShowsuccessful(false);

  const chainId = useAppSelector((state) => state.user.chainId);
  const walletAddress = useAppSelector((state) => state.user.walletAddress);

  /**
   * handle on change for input amount
   * @param {*} event
   */

  const handleCloseSubmit = () => {
    handleClose();
    setError(null);
  };

  const getUserBalance = async () => {
    let res = await web3Services.getUserUSDTBalance();
    dispatch(saveUserUSDTBalance(res));
    setUserBalance(res);
  };

  useEffect(() => {
    if (walletAddress) {
      getGasPrice();
    }
  }, [walletAddress]);

  useEffect(() => {
    if (!walletAddress) {
      handleClose();
    }
  }, [walletAddress]);

  useEffect(() => {
    setNetworkName(
      NETWORK_INFO.find((doc) => doc.chain_id === Number(chainId))?.symbol
    );
  }, [chainId, chainInfo]);

  useEffect(() => {
    if (walletAddress && show) {
      getUserBalance();
    }
  }, [chainId, walletAddress, show]);

  const getGasPrice = async () => {
    const gasPrice = await web3Services.getGasPrice();
    setGasPrice(gasPrice);
  };

  const handleOnChangeAmount = (event) => {
    let maxAmount;
    maxAmount = showDecimal(userBalance, 8);
    console.log("maxAmount", maxAmount);

    const { value } = event.target;
    let valData = value;
    if (networkName.toUpperCase() == "ETH") {
      valData = inputDecimal(value, 6);
    }
    console.log("valData", valData);

    if (Number(valData) === 0) {
      setError("Please enter amount greater than 0");
    } else if (Number(valData) > Number(maxAmount)) {
      if (maxAmount === 0) {
        setError(`You have no balance to deposit`);
      } else {
        console.log("maxAmount", maxAmount);
        setError(`Please enter an amount less than max withdraw amount.`);
      }
    } else {
      setError(null);
    }
    setAmount(valData);
  };

  const amountMaxOnInput = async (event) => {
    if (userBalance != 0) {
      handleOnChangeAmount({
        target: { value: userBalance ? userBalance : 0 },
      });
    }
  };

  const handleDepositSubmit = async () => {
    console.log("deposit now");
    setIsTxnComplete(false);
    let fee = GAS_LIMIT * gasPrice;
    let x = new BigNumber(fee);
    let awaitingMsg = "Awaiting confirmation...";
    if (connector?.name === "WalletConnect") {
      awaitingMsg = "Confirm on the device you scanned the QR code with.";
    }
    console.log("xxxxxxxxxxxxxxx", x, GAS_LIMIT, gasPrice);
    try {
      const depositResponse = await toastAlert.waitingPromis(
        depositToContract(amount, x.toFixed()),
        awaitingMsg
      );
      console.log("afterdeposit>", depositResponse);
      if (depositResponse) {
        getUserBalance();
        handleClose();
        setShowsuccessful(true);
        setIsTxnComplete(true);
      }
    } catch (error) {
      setIsTxnComplete(true);
    }
  };

  return (
    <>
      <Modal
        className="add_profit_Modal deposit_modal"
        centered
        show={show}
        onHide={handleCloseSubmit}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deposit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="pt-2">
            <Col xs={12}>
              <div className="Input_Group mb-3">
                <label>Select Coin</label>
                <Dropdown className="profit_dropdown" disabled>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <span>
                      <img src={icon1} alt="coin icon" />
                    </span>
                    <p>USDT</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu disabled>
                    {setTokenList.map((data, i) => {
                      return (
                        <Dropdown.Item key={i} to="javascript:;">
                          <span>
                            <img src={data.coinIcon} alt="coin icon" />
                          </span>
                          <p>{data.tokenname}</p>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {coin !== "USDT" && (
                <div className="agree_checkBox d-flex align-items-center mb-4">
                  <Form.Check
                    className="form-check blue_check_box"
                    aria-label="option 1"
                  />
                  <p>Convert my deposit into USDT</p>
                </div>
              )}
            </Col>
            <Col xs={12}>
              <div className="Input_Group">
                <label>
                  Amount Available: <span>{userBalance} USDT</span>
                </label>
                <input
                  type="text"
                  className="input-type2"
                  placeholder="0.00"
                  value={amount}
                  onChange={(event) => {
                    if (/^\d*(\.\d{0,8})?$/.test(event.target.value)) {
                      handleOnChangeAmount(event);
                    }
                  }}
                  onInput={(event) => {
                    amountValidationOnInput(event, 8);
                  }}
                />
                <span className="span-max-2" onClick={amountMaxOnInput}>
                  Max
                </span>
              </div>
            </Col>
          </Row>

          <h6 className="mt-4">
            Adding funds to your exchange account may take a few minutes,so
            please don’t close this window. You’ll receive a notification once
            it is complete.
          </h6>

          <hr className="common_hr my-4" />

          <h6>
            Before depositing, please accept our Terms & Conditions and Privacy
            Policy.
          </h6>

          <div className="agree_checkBox d-flex align-items-center my-3">
            <Form.Check
              checked={termCheck}
              onClick={() => {
                setTermCheck(!termCheck);
              }}
              className="form-check blue_check_box"
              aria-label="option 1"
            />
            <p>
              I agree to the{" "}
              <Link to="/">
                <u>Terms </u>
              </Link>
              and
              <Link to="/">
                <u> Privacy Policy</u>
              </Link>
            </p>
          </div>

          {/* <div className="agree_checkBox d-flex align-items-center my-2">
            <Form.Check
              className="form-check blue_check_box"
              aria-label="option 1"
            />
            <p>
              I agree to the{" "}
              <Link to="/">
                <u>Terms & Conditions</u>
              </Link>
            </p>
          </div> */}

          {error && (
            <div className="error_msg d-flex align-items-center mt-4">
              <img src={CrossIcon} alt="cross" />
              <p className="mb-0 text_red ms-3">
                {error}
                {/* <span className="d-block">Please fund your account.</span> */}
              </p>
            </div>
          )}

          <div className="mt-4 pt-2">
            <CommonButton
              disabled={error || amount === "" || !termCheck || !isTxnComplete}
              onClick={handleDepositSubmit}
              title="Confirm"
              className="w-100"
            />
          </div>
        </Modal.Body>
      </Modal>

      <TxnSuccessfulModal
        show={showsuccessful}
        amount={amount}
        modalType="Deposit"
        handleClose={handleClosesuccessful}
      />
      {/*<DepositConversionModal
            show={showsuccessful}
        handleClose={handleClosesuccessful}
      /> */}
    </>
  );
};

export default DepositModal;
