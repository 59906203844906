import ETHXLP_ABI from "./abi/ETHXLpstaking.json";
import ETHXSOLO_ABI from "./abi/ETHXSolostaking.json";
import REWARDTOKEN_ABI from "./abi/REWARDTOKEN.json";
import ROUTER_ABI from "./abi/ROUTER.json";

import LPTOKEN_ABI from "./abi/LPtoken.json";
import DividendTracker_ABI from "./abi/DividendTracker.json";
import DividendPaying_ABI from "./abi/DividendPaying.json";
import USDC_ABI from "./abi/USDC.json";
import SwapperABI from "./abi/x_swapper.json"
import {
  Dividend_paying_token,
  Dividend_Tracker,
  LP_STAKE,
  LP_TOKEN,
  PAIR,
  REWARD_TOKEN,
  SOLO_STAKE,
  USDC_TOKEN,
  WETH,
} from "../constants/constants";

// export let dynamicContractDetails;
// switch (chainName) {
//   case "BNB":
//     dynamicContractDetails = [
//       {
//         symbol: "LPSTAKE",
//         address: "0x00c335ccb9dd1256146BCf8A9F6a90407AB67F82",
//         abi: ETHXLP_ABI,
//       },
//       {
//         symbol: "SOLOSTAKE",
//         address: "0xD03d8FB47c4F7bD3b0c370b9eaec6d3e45bDF322",
//         abi: ETHXSOLO_ABI,
//       },
//       {
//         symbol: "REWARDTOKEN",
//         address: "0x6d5e259469d7cb240d84d215722b84f52299d21a",
//         abi: REWARDTOKEN_ABI,
//       },
//       {
//         symbol: "WETH",
//         address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
//         abi: REWARDTOKEN_ABI,
//       },
//       {
//         symbol: "LPTOKEN",
//         address: "0xB7903F2434A81ea062f411174f9e51926F495e1e",
//         abi: LPTOKEN_ABI,
//       },
//       {
//         symbol: "Dividend_Tracker",
//         address: "0x78119176DDB162BAb65276ee643A8b358D818861",
//         abi: DividendTracker_ABI,
//       },
//       {
//         symbol: "Dividend_paying_token",
//         address: "0x3E1559A035f31dD0F118ED2656c5C36Dc0ff5de0",
//         abi: DividendPaying_ABI,
//       },
//       {
//         symbol: "USDCTOKEN",
//         address: "0xD0Db716Bff27bc4cDc9dD855Ed86f20c4390BEd6",
//         abi: USDC_ABI,
//       },
//       {
//         symbol: "PAIR",
//         address: "0xb7903f2434a81ea062f411174f9e51926f495e1e",
//         // abi: USDC_ABI,
//       },
//     ];

//     break;
//   case "Cronos":
//     dynamicContractDetails = [
//       {
//         symbol: "LPSTAKE",
//         address: "0x7684720A4D7ACBE5208755A9cEc4917b27917d58",
//         abi: ETHXLP_ABI,
//       },
//       {
//         symbol: "SOLOSTAKE",
//         address: "0xDCADD9966D4478BfdDdD9c8eeEAB297a1F9b5951",
//         abi: ETHXSOLO_ABI,
//       },
//       {
//         symbol: "REWARDTOKEN",
//         address: "0x6d5e259469d7cb240d84d215722b84f52299d21a",
//         abi: REWARDTOKEN_ABI,
//       },
//       {
//         symbol: "WETH",
//         address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
//         abi: REWARDTOKEN_ABI,
//       },
//       {
//         symbol: "LPTOKEN",
//         address: "0x3aAA84153a5115a5A4B1D3df4749eB2A4847b1A7",
//         abi: LPTOKEN_ABI,
//       },
//       {
//         symbol: "PAIR",
//         address: "0xb7903f2434a81ea062f411174f9e51926f495e1e",
//         // abi: USDC_ABI,
//       },
//     ];
//     break;
//   default:
//     break;
// }

export const dynamicContractDetails = [
  {
    symbol: "LPSTAKE",
    address: LP_STAKE,
    abi: ETHXLP_ABI,
  },
  {
    symbol: "SOLOSTAKE",
    address: SOLO_STAKE,
    abi: ETHXSOLO_ABI,
  },
  {
    symbol: "REWARDTOKEN",
    address: REWARD_TOKEN,
    abi: REWARDTOKEN_ABI,
  },
  {
    symbol: "WETH",
    address: WETH,
    abi: REWARDTOKEN_ABI,
  },
  {
    symbol: "LPTOKEN",
    address: LP_TOKEN,
    abi: LPTOKEN_ABI,
  },
  {
    symbol: "Dividend_Tracker",
    address: Dividend_Tracker,
    abi: DividendTracker_ABI,
  },
  {
    symbol: "Dividend_paying_token",
    address: Dividend_paying_token,
    abi: DividendPaying_ABI,
  },
  {
    symbol: "USDCTOKEN",
    address: USDC_TOKEN,
    abi: USDC_ABI,
  },
  {
    symbol: "PAIR",
    address: PAIR,
    abi: ROUTER_ABI,
  },
  {
    symbol: "X_SWAPPER",
    address: "0x77777777772cf0455fB38eE0e75f38034dFa50DE",
    abi:SwapperABI ,
  },
];
