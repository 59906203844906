import React, { useState } from "react";
import close from "../../assets/images/bar_close.svg";
import "./AdjustmentTools.scss";

const AdjustmentTools = (props) => {
  const { name, onClose, onExtend, onReduce } = props;

  const [extend, setExtend] = useState(false);

  const handleOnClose = () => {
    onClose(name);
  };

  const handleOnExtend = () => {
    onExtend(name);
    setExtend(true);
  };

  const handleOnReduce = () => {
    onReduce(name);
    setExtend(false);
  };

  return (
    <ul className="card_adjustment_btn">
      <li>
        <button className="card_close" onClick={handleOnClose}>
          <img src={close} alt="icon" />
        </button>
      </li>
    </ul>
  );
};

export default AdjustmentTools;
