import React, { useEffect, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import "./WalletHistory.scss";
import charticon1 from "../../../../../assets/icons/redline_trade.svg";
import charticon2 from "../../../../../assets/icons/linechat_arrow.svg";
import { useSelector, useDispatch } from "react-redux";
import { saveUserTransactionHistory } from "../../../../Exchange/redux/_slices/exchange.slice";
import { ApiServices } from "../../../../Exchange/services/api.services";
import moment from "moment";
import Select from "react-select";
import { updateLoader } from "../../../../Exchange/redux/_slices/exchange.slice";
import { useAppSelector } from "../../../../../hooks/redux.hooks";
import Loader from "../../../common/Loader/Loader";

const WalletHistory = () => {
    const [selectOptionType, setSelectOptionType] = useState("");
    const [selectOptionStatus, setSelectOptionStatus] = useState("");
    const [historyList, setHistoryList] = useState([]);
    const [filteredHistory, setFilteredHistory] = useState([]);
    const loader = useAppSelector((state) => state.exchange.loader);
    const optionsType = [
        { value: "", label: "All" },
        { value: "DEPOSIT", label: "DEPOSIT" },
        { value: "WITHDRAW", label: "WITHDRAW" },
    ];

    const optionsStatus = [
        { value: "", label: "All" },
        { value: "Success", label: "Success" },
        { value: "Failed", label: "Failed" },
    ];

    const walletAddress = useSelector((state) => state?.user?.walletAddress);
    let recentTransactions = useSelector(
        (state) => state?.exchange?.userTransactionHistory
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (walletAddress) {
            getTransactionHistory();
        } else {
            dispatch(saveUserTransactionHistory([]));
        }
    }, [walletAddress, dispatch]);

    const getTransactionHistory = async () => {
        try {
            const dataObject = {
                address: walletAddress,

            };
            const response = await ApiServices.transactionHistory(dataObject);
            dispatch(saveUserTransactionHistory(response?.data));
            setHistoryList(response?.data);
            setFilteredHistory(response?.data);
            dispatch(updateLoader(false));
        } catch (error) {
            console.log(error);
        }
    };

    const handleTypeChange = (type) => {
        setSelectOptionType(type?.value);
        dispatch(updateLoader(true));
    };

    const handleStatusType = (status) => {
        setSelectOptionStatus(status?.value);
         dispatch(updateLoader(true));
    };

    useEffect(() => {
        let filtered = historyList;

        if (selectOptionType) {
            filtered = filtered.filter(
                (data) => data.transaction_type === selectOptionType
            );
        }

        if (selectOptionStatus) {
            filtered = filtered.filter((data) => {
                const historyStatus = data.status == 2 ? "Success" : "Failed";
                return historyStatus === selectOptionStatus;
            });
        }

        setFilteredHistory(filtered);
    }, [selectOptionType, selectOptionStatus, historyList]);

    return (
        <>
            <div className="Transaction_History_selectBox d-flex flex-wrap mt-4 mt-xl-5">
                <div className="common_select">
                    <label>Type</label>
                    <Select
                        placeholder="All"
                        defaultValue={selectOptionType}
                        onChange={handleTypeChange}
                        options={optionsType}
                        classNamePrefix="react-select"
                        value={optionsType.find(
                            (option) => option.value === selectOptionType
                        )}
                    />
                </div>
                <div className="common_select">
                    <label>Status</label>
                    <Select
                        placeholder="All"
                        defaultValue={selectOptionStatus}
                        onChange={handleStatusType}
                        options={optionsStatus}
                        classNamePrefix="react-select"
                        value={optionsStatus.find(
                            (option) => option.value === selectOptionStatus
                        )}
                    />
                </div>
            </div>
            <div className="CryptoAssets_Table History_Table mt-4 mt-xl-5 walletHistory">
                <Table responsive>
                    <thead>
                        <tr>
                            <th style={{ minWidth: "220px" }}>
                                <div className="d-flex align-items-center">
                                    Coin, token or asset
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "150px" }}>
                                <div className="d-flex align-items-center">
                                    Type
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>

                            <th>
                                <div className="d-flex align-items-center">
                                    Amount
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    Status
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredHistory?.map((data, i) => {
                            let amount = data?.amount / 100000000;
                            return (
                                <tr key={i}>
                                    <td style={{ minWidth: "220px" }}>
                                        <div className="coin_data d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                                <span>{data.token_name}</span>
                                                <h6>{data.chain_name}</h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: "150px" }}>
                                        <div className="Type_Data d-flex align-items-center">
                                            <div
                                                className={`type_Icon ${
                                                    data.transaction_type ===
                                                    "DEPOSIT"
                                                        ? "greenbg"
                                                        : "redbg"
                                                }`}
                                            >
                                                <img
                                                    src={
                                                        data.transaction_type ===
                                                        "DEPOSIT"
                                                            ? charticon2
                                                            : charticon1
                                                    }
                                                    alt="eye"
                                                />
                                            </div>
                                            <div className="type_Text">
                                                <h6>{data.transaction_type}</h6>
                                                <span>
                                                    {moment(
                                                        data?.created_at
                                                    ).format(
                                                        "DD-MM-YYYY  HH:mm:ss"
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{amount}</td>
                                    <td
                                        className={`${
                                            data.status === 2
                                                ? "text_green"
                                                : "text_red"
                                        }  text-end`}
                                    >
                                        <div className="Type_Data d-flex align-items-center">
                                            {data.status === 2
                                                ? "Success"
                                                : "Failed"}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default WalletHistory;
