import React, { useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import "./SportOrders.scss";
import CurrentOrder from "./CurrentOrder/CurrentOrder";
import OrderHistory from "./OrderHistory/OrderHistory";
import TradeHistory from "./TradeHistory/TradeHistory";

const SpotOrders = ({isActive}) => {
    const [activeTab, setActiveTab] = useState('spotCurrentHistory');

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    return (
        <div className="Transaction_History">
            <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={handleTabSelect}>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} xl={12}>
                                <Nav
                                    variant="pills"
                                    className="Bottom_Border_Tabs"
                                >
                                    <Nav.Item>
                                        <Nav.Link eventKey="spotCurrentHistory">
                                            Current History
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="spotOrderHistory">
                                            Order HIstory
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="spotTradeHistory">
                                            Trade History
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="spotCurrentHistory">
                                <CurrentOrder  currentTab = {activeTab} isActive = {isActive}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="spotOrderHistory">
                                <OrderHistory currentTab = {activeTab} isActive = {isActive}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="spotTradeHistory">
                                <TradeHistory currentTab = {activeTab} isActive = {isActive}/>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
};

export default SpotOrders;
