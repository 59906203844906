export const API_HOST = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const P2P_SOCKET_URL = process.env.REACT_APP_P2P_SOCKET_URL;

export const EXCHANGE_SITE_URL = process.env.REACT_APP_EXCHANGE_URL;
export const REACT_APP_SITE_URL = process.env.REACT_APP_SITE_URL;
export const BINANCE_CONTRACT_ADDRESS =
  process.env.REACT_APP_BINANCE_CONTRACT_ADDRESS;
export const CRONOS_CONTRACT_ADDRESS =
  process.env.REACT_APP_CRONOS_CONTRACT_ADDRESS;
export const NETWORK = process.env.REACT_APP_NETWORK;
export const EXCHANGE_NAME = "Eterna";
export const DEFAULT_PAIR = "BTC_USDT";
export const LEARN_CONNECTING_WALLET_URL =
  "https://apollox.zendesk.com/hc/en-us/articles/4408402550809";
export const LEVERAGE_HELP_ARTICAL =
  "https://apollox.zendesk.com/hc/en-us/articles/4404852092441";
export const GLOWL_DECIMAL = 4;
export const PRICE_DECIMAL = 4;
export const ETERNA_FEE = 0.01;
export const DATA_ENCRYPT_KEY = process.env.REACT_APP_DATA_ENCRYPT_KEY;
export const ALGO_SERVER = process.env.REACT_APP_ALGO_SERVER;
export const ALGO_INDEXER = process.env.REACT_APP_ALGO_INDEXER;
export const INDEXER_API = process.env.REACT_APP_INDEXER_API;
export const ALGOD_TOKEN = process.env.REACT_APP_ALGOD_TOKEN;
export const DISPENSER_LINK = process.env.REACT_APP_DISPENSER_LINK;
export const ORDER_BOOK_SIZE = 30;
export const MARKET_TRADES_SIZE = 30;
export const ORDER_BOOK_BUFFER = 50;
export const WITHDRAW_FEE = 0.001 * 10 ** 6;
export const APP_CLOSEOUT_FEE = 0.002 * 10 ** 6;
export const API_URL = process.env.REACT_APP_TEMP_URL;
export const GOERLI_CONTRACT_ADDRESS = process.env.REACT_APP_GOERLI_TESTNET;
export const EXHV1_URL = `https://eterna.exchange`;
// export const BSC_CONTRACT_ADDRESS = process.env.REACT_APP_BSC_TESTNET;
export const GAS_LIMIT = 77925;
export const USDT_TOKEN_ADDRESS = process.env.REACT_APP_USDT_TOKEN_ADDRESS;
export const BSC_CONTRACT_ADDRESS = process.env.REACT_APP_BSC_CONTRACT_ADDRESS;
export const ETH_CONTRACT_ADDRESS = process.env.REACT_APP_ETH_CONTRACT_ADDRESS;
export const ETH_TOKEN_ADDRESS = process.env.REACT_APP_ETH_TOKEN_ADDRESS;
export const BSC_TOKEN_ADDRESS = process.env.REACT_APP_BSC_TOKEN_ADDRESS;
export const RPCURL = process.env.REACT_APP_RPC_URL;
export const P2P_CONTRACT_ADDRESS = process.env.REACT_APP_P2P_CONTRACT;
export const EHX_TOKEN_ADDRESS = process.env.REACT_APP_EHX_TOKEN_ADDRESS;

export const RPCURLEXCHETH = process.env.REACT_APP_RPC_ETH_URL;
export const RPCURLEXCHBSC = process.env.REACT_APP_RPC_BSC_URL;

export const CHAIN_ID = 97;
export const CHAIN_ID_BSC = process.env.REACT_APP_CHAIN_ID_BSC;
export const BSC_CHAIN_ID = 56;

export const ETH_CHAIN_ID = 1;
export const walletConnectProjectId = "c3b49c55d1b303325d4c139c24268550";
export const convert = (n) => {
  var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, "")
    .replace(/^([0-9]+)(e.*)/, "$1.$2")
    .split(/e|\./);
  return +pow < 0
    ? sign +
    "0." +
    "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
    lead +
    decimal
    : sign +
    lead +
    (+pow >= decimal.length
      ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
      : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
};

export const showDecimal = (amountVal, decimals) => {
  let decimalVal = scientificToDecimal(amountVal);
  // console.log("resultresult=", amountVal, decimalVal);
  let updateVal = decimalVal?.toString();
  if (updateVal?.includes(".")) {
    let getDicimal = updateVal.split(".");
    let result = getDicimal[1].substring(0, Number(decimals));
    decimalVal = getDicimal[0] + "." + result;
  }
  return decimalVal;
};
export const inputDecimal = (value, decimals) => {
  let valData = value;
  // console.log("inputDecimal", value?.toString().split(".")[1] !== "");
  if (
    value?.toString().includes(".") &&
    value?.toString().split(".")[1] !== ""
  ) {
    let getDicimal = value?.toString().split(".");
    let result = getDicimal[1].substring(0, Number(decimals));
    valData = getDicimal[0] + "." + result;
  }
  return valData;
};
export const USDT_DESIMAL = 1000000;
export const EHX_DESIMAL = 1000000000000000000;

export const formattedPair = (pairName) => {
  let pName = "";
  let pair = pairName.split("_");
  if (pair && pair.length > 0) {
    if (pair.length > 1) {
      return (pName = `${pair[0]}${pair[1]}`);
    } else {
      return (pName = pair[0]);
    }
  }
};
export const P2P_NETWORK = process.env.REACT_APP_NETWORK_P2P;

export const ChainIdp2p = P2P_NETWORK === "testnet" ? 97 : 1;
export const EXPIRES_OPTIONS = [
  { value: "5", label: "5 minute", type: "minutes" },
  { value: "1", label: "1 Hour", type: "hours" },
  { value: "1", label: "1 day", type: "days" },
  { value: "3", label: "3 day", type: "days" },
];
export const toFixed = (num, fixed) => {
  fixed = fixed || 0;
  fixed = Math.pow(10, fixed);
  return Math.floor(num * fixed) / fixed;
};

export const BASE_URL_P2P = process.env.REACT_APP_P2P_BASE_URL;
// export const BASE_URL = `http://10.1.5.46:3005`;
export const BASE_URL = process.env.REACT_APP_BASE_P2P_URL;
export const scientificToDecimal = function (num) {
  var nsign = Math.sign(num);
  //remove the sign
  num = Math.abs(num);
  //if the number is in scientific notation remove it
  if (/\d+\.?\d*e[\+\-]*\d+/i.test(num)) {
    var zero = "0",
      parts = String(num).toLowerCase().split("e"), //split into coeff and exponent
      e = parts.pop(), //store the exponential part
      l = Math.abs(e), //get the number of zeros
      sign = e / l,
      coeff_array = parts[0].split(".");
    if (sign === -1) {
      l = l - coeff_array[0].length;
      if (l < 0) {
        num =
          coeff_array[0].slice(0, l) +
          "." +
          coeff_array[0].slice(l) +
          (coeff_array.length === 2 ? coeff_array[1] : "");
      } else {
        num = zero + "." + new Array(l + 1).join(zero) + coeff_array.join("");
      }
    } else {
      var dec = coeff_array[1];
      if (dec) l = l - dec.length;
      if (l < 0) {
        num = coeff_array[0] + dec.slice(0, l) + "." + dec.slice(l);
      } else {
        num = coeff_array.join("") + new Array(l + 1).join(zero);
      }
    }
  }

  return nsign < 0 ? "-" + num : num;
};
