import React from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import CommonButton from '../../../../common/CommonButton/CommonButton'
import Admin from '../../Admin'
import TopbarHeading from '../../common/TopbarHeading/TopbarHeading'
import "./DepositCrypto.scss"
import Noteicon from "../../../../assets/icons/note_icon.svg";
import DepositCard from './DepositCard/DepositCard'

const DepositCrypto = () => {
    return (
        <>
            <Admin>
                <div className='deposit_crypto_page'>

                    <div className='Common_TopBar'>
                        <TopbarHeading title="Deposit Crypto" />
                    </div>
                    <div className='Common_admin_Contentarea'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="deposit">
                            <div className="Admin_Tabs_sec">
                                <Row className='align-items-center'>
                                    <Col xs={8}>
                                        <Nav variant="pills" className="Common_button_tabs">
                                            <Nav.Item>
                                                <Nav.Link eventKey="deposit">Deposit</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="withdraw">Withdraw</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col xs={4} className="d-md-flex justify-content-sm-end">
                                        <CommonButton icon={Noteicon} btnicon="d-block" title="Pending Deposits" className="grey-btn2" />
                                    </Col>
                                </Row>
                                <Tab.Content>
                                    <Tab.Pane eventKey="deposit">
                                        <DepositCard />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="withdraw">
                                        <DepositCard />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </Admin>

        </>
    )
}

export default DepositCrypto
