import React from 'react'
import "./TopbarHeading.scss";
import HideShowBalance from '../HideShowBalance/HideShowBalance';
const TopbarHeading = ({
  title, 
  hideEyeIcon = false
}) => {
  return (
    <>
      <h2 className='Topbar_heading'>{title} {!hideEyeIcon && <HideShowBalance />}</h2>
    </>
  )
}

export default TopbarHeading
