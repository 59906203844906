import React, { useEffect, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import "./CoinDetailTable.scss";
import btcicon from "../../../../../assets/images/btc.svg";
import btcicon1 from "../../../../../assets/images/admin/Ethereum-ETH.svg";
import btcicon3 from "../../../../../assets/images/admin/Coin.svg";
import btcicon4 from "../../../../../assets/images/admin/AAVE.svg";
import btcicon5 from "../../../../../assets/images/admin/AGIX.svg";
import btcicon6 from "../../../../../assets/images/admin/ARETH.svg";
import btcicon7 from "../../../../../assets/images/admin/DOGE-BNB.svg";
import btcicon8 from "../../../../../assets/images/admin/HBAR-Hedera.svg";
import btcicon9 from "../../../../../assets/images/XRP.svg";
import charticon1 from "../../../../../assets/icons/redline_trade.svg";
import charticon2 from "../../../../../assets/icons/linechat_arrow.svg";
import charticon3 from "../../../../../assets/images/admin/money-bill-send.svg";
import charticon4 from "../../../../../assets/images/admin/arrow-left-arrow-right.svg";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { ApiServices } from "../../../../Exchange/services/api.services";
import { saveUserCoinsDetail } from "../../../../Exchange/redux/_slices/exchange.slice";
const CoinDetailTable = () => {
    const [coins, setCoins] = useState([]);
    const listCoins = useSelector((state) => state.exchange.userCoinsDetail);
    const dispatch = useDispatch();

    useEffect(() => {
        getUserCoins();
    }, []);

    const getUserCoins = async () => {
        const response = await ApiServices.getCoinsDetail();
        if (response) {
            dispatch(saveUserCoinsDetail(response.data));
        }
    };

    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    return (
        <>
            <div className="CryptoAssets_Table History_Table">
                <Table className="coinDetailTable" responsive>
                    <thead>
                        <tr>
                            <th style={{ minWidth: "220px" }}>
                                <div className="d-flex align-items-center">
                                    Name
                                </div>
                            </th>
                            <th style={{ minWidth: "180px" }}>
                                <div className="d-flex align-items-center">
                                    Price USD
                                </div>
                            </th>
                            <th style={{ minWidth: "180px" }}>
                                <div className="d-flex align-items-center">
                                    Amount EHX
                                </div>
                            </th>
                            <th style={{ minWidth: "180px" }}>
                                <div className="d-flex align-items-center">
                                    24h Volume Change
                                </div>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listCoins && Object.keys(listCoins).length > 0 ? (
                            Object.keys(listCoins).map((key) => {
                                const coin = listCoins[key];
                                return (
                                    <tr>
                                        <td style={{ minWidth: "220px" }}>
                                            <div className="coin_data d-flex align-items-center">
                                                {/* <div className="coinIcon">
                                                    <img
                                                        className="coin_Icon"
                                                        src={data.btcicon}
                                                        alt="icon"
                                                    />
                                                </div> */}
                                                <div className="d-flex align-items-center">
                                                    <span className="coinName">
                                                        {coin?.symbol}
                                                    </span>
                                                    <span className="coinType">
                                                        {coin?.platform?.symbol}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {roundToTwoDecimals(
                                                coin?.quote?.USD?.price
                                            )}
                                        </td>
                                        <td>{"0"}</td>
                                        <td>
                                            <span
                                                className={
                                                    coin?.quote?.USD
                                                        ?.volume_change_24h < 0
                                                        ? "priceRed"
                                                        : "priceGreen"
                                                }
                                            >
                                                {roundToTwoDecimals(
                                                    coin?.quote?.USD
                                                        ?.volume_change_24h
                                                )}
                                            </span>
                                        </td>
                                        <td>
                                            <CommonButton
                                                title="Trade"
                                                className="btn-primary"
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="5">No data available</td>
                            </tr>
                        )}
                    </tbody>

                    <tbody>
                        {/* {listCoins.map((data, i) => {
                            return (
                                <tr>
                                    <td style={{ minWidth: "220px" }}>
                                        <div className="coin_data d-flex align-items-center">
                                            <div className="coinIcon">
                                                <img
                                                    className="coin_Icon"
                                                    src={data.btcicon}
                                                    alt="icon"
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <span className="coinName">
                                                    {data.symbol}
                                                </span>
                                                <span className="coinType">
                                                    {data.platform.symbol}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{data.priceUSD}</td>
                                    <td>{data.amountEHX}</td>
                                    <td>
                                        <span
                                            className={
                                                data.priceChange == "down"
                                                    ? "priceRed"
                                                    : data.priceChange == "up"
                                                    ? "priceGreen"
                                                    : "priceWhite"
                                            }
                                        >
                                            {data.amount}
                                        </span>
                                    </td>
                                    <td>
                                        <CommonButton
                                            title="Trade"
                                            className="btn-primary"
                                        />
                                    </td>
                                </tr>
                            );
                        })} */}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default CoinDetailTable;
