import React from 'react'
import { Col, Dropdown, Form, Nav, Row, Tab } from 'react-bootstrap'
import "./SupportTicket.scss"
import TopbarHeading from '../../../common/TopbarHeading/TopbarHeading'
import Admin from '../../../Admin'
import Breadcrumbs from '../../../common/Breadcrumbs/Breadcrumbs'
import nodataicon from "../../../../../assets/images/admin/no_data_icon.svg";
import CommonButton from '../../../../../common/CommonButton/CommonButton'
import { SearchIcon } from '../../../../../assets/Svg/SvgImages'
import { Link } from 'react-router-dom'
import commentIcon from "../../../../../assets/images/admin/comment-dots-plus.svg"

const SupportTicket = () => {
  const prioritydata = [
    {
      name: "New Tickets",
      className: "blueclr",
    },
    {
      name: "In progress",
      className: "greyclr",
    },
    {
      name: "Resolved Tickets",
      className: "greenclr",
    },
    {
      name: "Closed tickets",
      className: "redclr",
    },
  ];

  const TicketsList = [
    {
      title: "Cannot access the platform",
      content: "Lorem ipsum dolor sit amet consectetur. Gravida nunc varius dui vulputate mauris scelerisque pharetra velit consectetur. Nulla faucibus egestas cursus sed nec. Nisi quisque id dignissim orci turpis ultrices ac ultrices. Eros laoreet in id suscipit. Facilisis phasellus ante suspendisse sit bibendum eu sed id placerat.",
      indicatbtn: "greybtn",
      indicatname: "In progress",
      number: "#122356",
      numberlike: "6",
      ticket: "Re-open ticket",
    },
    {
      title: "Cannot access the platform",
      content: "Lorem ipsum dolor sit amet consectetur. Gravida nunc varius dui vulputate mauris scelerisque pharetra velit consectetur. Nulla faucibus egestas cursus sed nec. Nisi quisque id dignissim orci turpis ultrices ac ultrices. Eros laoreet in id suscipit. Facilisis phasellus ante suspendisse sit bibendum eu sed id placerat.",
      indicatbtn: "redbtn",
      indicatname: "Closed",
      number: "#122356",
      numberlike: "6",
      ticket: "Re-open ticket",
    },
    {
      title: "Cannot access the platform",
      content: "Lorem ipsum dolor sit amet consectetur. Gravida nunc varius dui vulputate mauris scelerisque pharetra velit consectetur. Nulla faucibus egestas cursus sed nec. Nisi quisque id dignissim orci turpis ultrices ac ultrices. Eros laoreet in id suscipit. Facilisis phasellus ante suspendisse sit bibendum eu sed id placerat.",
      indicatbtn: "greenbtn",
      indicatname: "Resolved",
      number: "#122356",
      numberlike: "6",
      ticket: "Re-open ticket",
    },
    {
      title: "Cannot access the platform",
      content: "Lorem ipsum dolor sit amet consectetur. Gravida nunc varius dui vulputate mauris scelerisque pharetra velit consectetur. Nulla faucibus egestas cursus sed nec. Nisi quisque id dignissim orci turpis ultrices ac ultrices. Eros laoreet in id suscipit. Facilisis phasellus ante suspendisse sit bibendum eu sed id placerat.",
      indicatbtn: "greybtn",
      indicatname: "In progress",
      number: "#122356",
      numberlike: "6",
      ticket: "Re-open ticket",
    },
  ]

  return (
    <Admin>
      <div className='Common_TopBar'>
        <Row className='align-items-center'>
          <Col xs={12} xl={4}>
            <TopbarHeading title="Support Tickets" />
          </Col>
          <Col xs={12} xl={8} className="d-md-flex justify-content-xl-end">
            <div className='Common_Header_Btns d-sm-flex align-items-center'>
              <Link to="/support-ticket-new"><CommonButton title="Create ticket" className="grey-btn2" /></Link>
              <CommonButton title="Delete all API’s" className="grey-btn2 ms-2 ms-md-3 me-sm-3" />
              <div className='Common_search'>
                <Form.Control className="Common_search_Input" placeholder="Search for a ticket" />
                <SearchIcon />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Breadcrumbs pagelink="/helpdesk" mainpage="Helpdesk" pagename="Support Tickets" />
      <div className='Common_admin_Contentarea SupportTicket'>

        <div className='SupportTicket_Tabs'>
          <Tab.Container id="left-tabs-example" defaultActiveKey="alltickets">
            <Row>
              <Col md={8}>
                <Nav variant="pills" className="Bottom_Border_Tabs">
                  <Nav.Item><Nav.Link eventKey="alltickets">All tickets</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="New">New</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="progress">In progress</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="Resolved">Resolved</Nav.Link></Nav.Item>
                </Nav>
              </Col>
              <Col md={4} className='justify-content-md-end d-flex'>

              </Col>
            </Row>
            <Tab.Content>
              <Tab.Pane className='show' eventKey="alltickets">
                <div className='SupportTicket_List'>
                  {TicketsList.map((data, i) => {
                    return (
                      <div className='SupportTicket_Box' key={i}>
                        <h3>{data.title} <span className='daysclass'>2 days ago</span></h3>
                        <p>{data.content}</p>
                        <div className='d-sm-flex align-items-center'>
                          <div className='d-flex align-items-center'>
                            <Link to="/" className={`common_clrbtn ${data.indicatbtn}`}>{data.indicatname}</Link>
                            <Link to="/" className="numberbtn">{data.number}</Link>
                          </div>
                          <div className='d-flex align-items-center comment_boxname mt-3 mt-sm-0'>
                            <img src={commentIcon} alt='comment' />
                            <span>{data.numberlike}</span>
                            <h6>{data.ticket}</h6>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="New">
                <div className='SupportTicket_List'>
                  {TicketsList.map((data, i) => {
                    return (
                      <div className='SupportTicket_Box' key={i}>
                        <h3>{data.title} <span className='daysclass'>2 days ago</span></h3>
                        <p>{data.content}</p>
                        <div className='d-sm-flex align-items-center'>
                          <div className='d-flex align-items-center'>
                            <Link to="/" className={`common_clrbtn ${data.indicatbtn}`}>{data.indicatname}</Link>
                            <Link to="/" className="numberbtn">{data.number}</Link>
                          </div>
                          <div className='d-flex align-items-center comment_boxname mt-3 mt-sm-0'>
                            <img src={commentIcon} alt='comment' />
                            <span>{data.numberlike}</span>
                            <h6>{data.ticket}</h6>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="progress">
                <div className='SupportTicket_List'>
                  {TicketsList.map((data, i) => {
                    return (
                      <div className='SupportTicket_Box' key={i}>
                        <h3>{data.title} <span className='daysclass'>2 days ago</span></h3>
                        <p>{data.content}</p>
                        <div className='d-sm-flex align-items-center'>
                          <div className='d-flex align-items-center'>
                            <Link to="/" className={`common_clrbtn ${data.indicatbtn}`}>{data.indicatname}</Link>
                            <Link to="/" className="numberbtn">{data.number}</Link>
                          </div>
                          <div className='d-flex align-items-center comment_boxname mt-3 mt-sm-0'>
                            <img src={commentIcon} alt='comment' />
                            <span>{data.numberlike}</span>
                            <h6>{data.ticket}</h6>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Resolved">
                <div className='SupportTicket_List'>
                  {TicketsList.map((data, i) => {
                    return (
                      <div className='SupportTicket_Box' key={i}>
                        <h3>{data.title} <span className='daysclass'>2 days ago</span></h3>
                        <p>{data.content}</p>
                        <div className='d-sm-flex align-items-center'>
                          <div className='d-flex align-items-center'>
                            <Link to="/" className={`common_clrbtn ${data.indicatbtn}`}>{data.indicatname}</Link>
                            <Link to="/" className="numberbtn">{data.number}</Link>
                          </div>
                          <div className='d-flex align-items-center comment_boxname mt-3 mt-sm-0'>
                            <img src={commentIcon} alt='comment' />
                            <span>{data.numberlike}</span>
                            <h6>{data.ticket}</h6>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <div className='priority_Dropdown'>
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic">Select priority</Dropdown.Toggle>

              <Dropdown.Menu>
                {prioritydata.map((data, i) => {
                  return (
                    <Dropdown.Item to="#"><span className={data.className}></span> {data.name}</Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className='No_Order_data text-center pt-xl-5 mt-4 mt-md-5'>
          <img src={nodataicon} alt='icon' />
          <p className='text-white h5 mt-4'>No Data</p>
        </div>
      </div >
    </Admin >
  )
}

export default SupportTicket