import React, { useState, useEffect, memo } from "react";
import { Pagination, Table } from "react-bootstrap";
import "./ClosedProfitandLoss.scss";
import FiltersClosedProfitandLoss from "./FiltersClosedProfitandLoss";
import { ApiServices } from "../../../../../Exchange/services/api.services";
import moment from "moment";
import iconSell from "../../../../../../assets/icons/redline_trade.svg";
import iconBuy from "../../../../../../assets/icons/linechat_arrow.svg";
import Loader from "../../../../common/Loader/Loader";
import { updateLoader } from "../../../../../Exchange/redux/_slices/exchange.slice";
import { useDispatch, useSelector } from "react-redux";
import { saveUserClosedPnl } from "../../../../../Exchange/redux/_slices/exchange.slice";

const ClosedProfitandLoss = ({currentTab, isActive}) => {
    const dispatch = useDispatch();
    const walletAddress = useSelector((state) => state?.user?.walletAddress);
    const closedPnl = useSelector((state) => state.exchange.userClosedPnl);
    const closedPnLFilter = useSelector(
        (state) => state.user.userTradeFilters?.closedPnLFilter
    );
    const loader = useSelector((state) => state.exchange.loader);
    //const [positionList, setPositionList] = useState([]);

    useEffect(() => {

        if (walletAddress && currentTab === 'closedprofitandloss' && isActive) {
            dispatch(updateLoader(true));
            getPositionHistory();
        } else {
            // setPositionList([]);
            dispatch(saveUserClosedPnl([]));
        }
    }, [walletAddress, closedPnLFilter, currentTab, isActive]);

    const getPositionHistory = async () => {
        try {
            const dataObj = {
                address: walletAddress,
                dateFilter: closedPnLFilter,
            };

            const response = await ApiServices.positionsHistoryList(dataObj);
            if (response) {
                //setPositionList(response?.data?.list);
                dispatch(saveUserClosedPnl(response?.data?.list));
                dispatch(updateLoader(false));
            }
        } catch (error) {
            dispatch(updateLoader(false));
        }
    };

    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    return (
        <>
            {loader && <Loader />}
            <FiltersClosedProfitandLoss />
            <div className="CryptoAssets_Table History_Table">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    Contracts
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "170px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Closing Direction
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    Qty
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "130px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Entry Price
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "130px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Exit Price
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "130px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Closed P&L
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            {/* <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Opening Fee
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Closing Fee
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Funding Fee
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th> */}
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Exit Type
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Trade Time
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {closedPnl !== undefined && closedPnl?.length > 0 ? (
                            closedPnl?.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{ minWidth: "150px" }}>
                                            <div class="coin_data d-flex align-items-center">
                                                {/* <img
                                                src={pairDetails?.icon}
                                                alt={data?.symbol}
                                            /> */}
                                                <div class="d-flex align-items-center">
                                                    <span>{data?.symbol}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{ minWidth: "150px" }}>
                                            <div className="Type_Data d-flex align-items-center">
                                                <div
                                                    className={`type_Icon ${
                                                        data?.side == "Buy"
                                                            ? "greenbg"
                                                            : "redbg"
                                                    }`}
                                                >
                                                    <img
                                                        src={
                                                            data?.side == "Buy"
                                                                ? iconBuy
                                                                : iconSell
                                                        }
                                                        alt="icon"
                                                    />
                                                </div>
                                                <div className="type_Text">
                                                    <h6>{data?.side}</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{data?.qty}</td>
                                        <td>
                                            {roundToTwoDecimals(
                                                data?.avgEntryPrice
                                            )}
                                        </td>
                                        <td>
                                            {roundToTwoDecimals(
                                                data?.avgExitPrice
                                            )}
                                        </td>
                                        <td>
                                            {roundToTwoDecimals(
                                                data?.closedPnl
                                            )}
                                        </td>
                                        {/* <td>--</td>
                                        <td>--</td>
                                        <td>--</td> */}
                                        <td>{data?.execType}</td>
                                        <td>
                                            {moment(
                                                Number(data?.createdTime)
                                            ).format("DD/MM/YYYY  HH:mm:ss")}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={11}>
                                    <p style={{ textAlign: "center" }}>
                                        No result found
                                    </p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {/* <div className="transactions_Pagination d-flex align-items-center justify-content-between">
                    <p>52 transactions</p>

                    <Pagination className="common_pagination">
                        <Pagination.Prev />
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>

                        <Pagination.Ellipsis className="ellipsis_text" />
                        <Pagination.Item>{6}</Pagination.Item>
                        <Pagination.Next />
                    </Pagination>
                </div> */}
            </div>
        </>
    );
};

export default memo(ClosedProfitandLoss);
