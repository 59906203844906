import React, { memo } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab } from "react-bootstrap";
import "./Trading.scss";
// import TradeChartImg from "../../../../assets/images/T_chart.svg";
// import depthChartImg from "../../../../assets/images/depth.svg";
import DetailCoin from "./DetailCoin/DetailCoin";
import TradingChart from "./TradingChart/TradingChart";
import TradingChart2 from "./TradingChart2/TradingChart";
import OriginalChart from "./OriginalChart/OriginalChart";
import DepthvChart from "./Depth/DepthChart";

const Trading = () => {
  return (
      <>
          <div className="TradeChart_V2">
              <div className="Trading_Tabs nondraggable_card">
                  <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="tradingview"
                  >
                      <div className="d-flex justify-content-between align-items-center">
                          <Nav variant="pills" className="Nav_Trading_Tabs">
                              <Nav.Item>
                                  <Nav.Link eventKey="chart">Chart</Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item>
                  <Nav.Link eventKey="tradingview">Trading view</Nav.Link>
                </Nav.Item> */}
                              <Nav.Item>
                                  <Nav.Link eventKey="depth">Depth</Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item>
                                  <Nav.Link eventKey="detail">Details</Nav.Link>
                              </Nav.Item> */}
                          </Nav>
                      </div>
                      <Tab.Content className="Content_Trading_Tabs">
                          <Tab.Pane eventKey="chart" target="tradingview">
                              {/* <img src={TradeChartImg} alt="Chart" /> */}
                              <TradingChart2 />
                              {/* <OriginalChart /> */}
                          </Tab.Pane>
                          <Tab.Pane eventKey="tradingview">
                              {/* <img src={TradeChartImg} alt="Chart" /> */}
                              <TradingChart />
                          </Tab.Pane>
                          <Tab.Pane eventKey="depth">
                              {/* <div className='Depthv_Page'>
                  <img src={depthChartImg} alt="Chart" />
                </div> */}
                              <DepthvChart />
                          </Tab.Pane>
                          <Tab.Pane eventKey="detail">
                              <DetailCoin />
                          </Tab.Pane>
                      </Tab.Content>
                  </Tab.Container>
              </div>
          </div>
      </>
  );
};

export default memo(Trading);
