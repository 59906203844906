import React, { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useSelector, useDispatch } from "react-redux";
import TradeChart from "./Trading/Trading";
import OrderBook from "./OrderBook";
import UserLedger from "./UserLedger/UserLedger";
// import MarketTrades from "./MarketTrades/MarketTrades";
import AdjustmentTools from "../../../common/AdjustmentTools";
import {
  saveChangeGridLayout,
  saveCurrentBrackpoint,
  saveMobileTab,
  saveOldGridLayout,
} from "../../../redux/_slices/layout.slice";

import { GRID_LAYOUT } from "../../../constants/grid.layout";
import SubHeader from "./SubHeader/SubHeader";
import MarginRatio from "./MarginRatio/MarginRatio";
// import AnalysisWidget from "./AnalysisWidget/AnalysisWidget";
import AccountUpdate from "./AccountUpdate/AccountUpdate";
import AllMarket from "./AllMarket/AllMarket";
import BuySell from "./BuySell/BuySell";
import AnalysisWidget from "./AnalysisWidget/AnalysisWidget";

const ReactGridLayout = WidthProvider(Responsive);

const ResponsiveExchange = () => {
  const components = {
    AccountUpdate: <AccountUpdate />,
   // AllMarket: <AllMarket />,
    SubHeader: <SubHeader />,
    TradeChart: <TradeChart />,
    MarginRatio: <MarginRatio />,
    OrderBook: <OrderBook />,
    BuySell: <BuySell />,
    UserLedger: <UserLedger />,
    // MarketTrades: <MarketTrades />,
    AnalysisWidget: <AnalysisWidget />,
  };

  let props = {
    className: "layout",
    isDraggable: false,
    isResizable: false,
    onLayoutChange: function () { },
    breakpoints: { lg: 1480, md: 996, sm: 768, xs: 480 },
    cols: { lg: 30, md: 30, sm: 30, xs: 30 },
    rowHeight: 30,
    margin: [8, 8],
    draggableCancel: ".nondraggable_card",
    autoSize: true,
    containerPadding: [8, 8],
  };

  const gridLayout = useSelector((state) => state.layout.grid_layout);
  const oldGridLayout = useSelector((state) => state.layout.old_grid_layout);
  const mobile_tab = useSelector((state) => state.layout.mobile_tab);
  const current_breakpoint = useSelector(
    (state) => state.layout.current_breakpoint
  );

  const dispatch = useDispatch();

  const [isExpand, setIsExpand] = useState(false);
  const [isInitial, setIsInitial] = useState(false);

  useEffect(() => {
    // console.log("gridLayout>>>", gridLayout);
    if (oldGridLayout !== undefined && oldGridLayout !== null) {
      if (Object.keys(oldGridLayout).length > 0) {
        dispatch(saveChangeGridLayout(oldGridLayout));
        dispatch(saveOldGridLayout({}));
      }
    }
  }, []);

  const hanldeResetLayout = () => {
    dispatch(saveMobileTab("MARKET"));
    dispatch(saveChangeGridLayout(GRID_LAYOUT));
  };

  const onBreakpointChange = (breakpoint) => {
    if (breakpoint === "lg" || breakpoint === "md") {
      if (isInitial) {
        hanldeResetLayout();
      }
    }
    if (current_breakpoint !== breakpoint) {
      dispatch(saveCurrentBrackpoint(breakpoint));
    }
    setIsInitial(true);
  };

  const onLayoutChange = (layout, layouts) => {
    // console.log("layout", layout, layouts);
    let newLayouts = JSON.parse(JSON.stringify(layouts));
    let newLayout = JSON.parse(JSON.stringify(layout));
    if (!isExpand) {
      if (current_breakpoint === "lg" || current_breakpoint === "md") {
        dispatch(saveChangeGridLayout(newLayouts));
      }
    }
    props.onLayoutChange(newLayout, newLayouts);
  };

  const onRemoveItem = (i) => {
    let lg_grid_layout = gridLayout.lg.slice();
    let md_grid_layout = gridLayout.md.slice();
    let sm_grid_layout = gridLayout.sm.slice();
    let xs_grid_layout = gridLayout.xs.slice();

    const lg_index = lg_grid_layout.findIndex((doc) => doc.i === i);
    const md_index = md_grid_layout.findIndex((doc) => doc.i === i);
    const sm_index = sm_grid_layout.findIndex((doc) => doc.i === i);
    const xs_index = xs_grid_layout.findIndex((doc) => doc.i === i);

    lg_grid_layout.splice(lg_index, 1);
    md_grid_layout.splice(md_index, 1);
    sm_grid_layout.splice(sm_index, 1);
    xs_grid_layout.splice(xs_index, 1);

    let new_grid_layout = {
      lg: lg_grid_layout,
      md: md_grid_layout,
      sm: sm_grid_layout,
      xs: xs_grid_layout,
    };

    dispatch(saveChangeGridLayout(new_grid_layout));
  };

  const onExtendItem = (i) => {
    dispatch(saveOldGridLayout(gridLayout));

    setIsExpand(true);

    if (current_breakpoint === "lg" || current_breakpoint === "md") {
      let currentLayout = { ...gridLayout };

      dispatch(saveChangeGridLayout(currentLayout));
    }
  };

  const onReduceItem = (i) => {
    setIsExpand(false);
    dispatch(saveChangeGridLayout(oldGridLayout));
    dispatch(saveOldGridLayout({}));
  };

  const showResponsiveView = (blockType, currentPoint) => {
    if (currentPoint === "sm" || currentPoint === "xs") {
      switch (blockType) {
        // Market Tab
        case "FavoriteBar": {
          if (mobile_tab === "MARKET") {
            return true;
          }
          return false;
        }
        case "SubHeader": {
          if (mobile_tab === "MARKET") {
            return true;
          }
          return false;
        }
        case "TradeChart": {
          if (mobile_tab === "MARKET") {
            return true;
          }
          return false;
        }

        case "MarginRatio": {
          if (mobile_tab === "MARKET") {
            return true;
          }
          return false;
        }

        case "Asset": {
          if (mobile_tab === "MARKET") {
            return true;
          }
          return false;
        }

        case "UserLedger": {
          if (mobile_tab === "MARKET") {
            return true;
          }
          return false;
        }

        // Trade Tab
        case "MarketTrades": {
          if (mobile_tab === "TRADE") {
            return true;
          }
          return false;
        }

        // Order Tab

        // Book Tab
        case "OrderBook": {
          if (mobile_tab === "BOOK") {
            return true;
          }
          return false;
        }

        // Wallet

        case "BuySellTab": {
          if (mobile_tab === "WALLET") {
            return true;
          }
          return false;
        }

        // TechnicalAnalysis Tab
        case "AnalysisWidget": {
          if (mobile_tab === "MARKET") {
            return false;
          }
          return false;
        }

        default:
          return false;
      }
    } else {
      return true;
    }
  };

  const generateDOM = () => {
    const layoutObject = [];
    // console.log("responsivelayout,",current_breakpoint, gridLayout);
    gridLayout[
      current_breakpoint === "sm" || current_breakpoint === "xs"
        ? "lg"
        : current_breakpoint
    ]?.map((item) => {
      if (item !== null) {
        // console.log("itemmmmmm", item);
        const isComponent = showResponsiveView(item.i, current_breakpoint);
        if (isComponent) {
          layoutObject.push(
            <div key={item.i} className={`cardStyle ${item.i}`}>
              {(current_breakpoint === "lg" || current_breakpoint === "md") && (
                <div className="head_move">
                  <AdjustmentTools
                    name={item.i}
                    onClose={onRemoveItem}
                    onExtend={onExtendItem}
                    onReduce={onReduceItem}
                  />
                </div>
              )}
              <div className={`card_body_style`}>{components[item.i]}</div>
            </div>
          );
        }
      }
    });

    return layoutObject;
  };

  return (
    <>
      <ReactGridLayout
        {...props}
        layouts={gridLayout}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={onLayoutChange}
      // allowOverlap={true}
      // useCSSTransforms={true}
      >
        {generateDOM()}
      </ReactGridLayout>
    </>
  );
};

export default ResponsiveExchange;
