import { useRef } from "react";
import ConnectModel from "../../ConnectModel/ConnectModel";
import CommonButton from "../CustomButton/CustomButton";

const ConnectViewWrap = (props) => {
  const modalRef = useRef();

  const handleConnect = () => {
    modalRef.current.handleModal(true);
  };

  return (
    <div className="wallet-wrap d-flex flex-column flex-sm-row align-items-center justify-content-between">
      <h3 className="mb-30 mb-sm-0">{props?.AccessStaking}</h3>
      {/* <CommonButton title="Connect Wallet" onClick={() => handleConnect()} /> */}
      <ConnectModel ref={modalRef} />
    </div>
  );
};

export default ConnectViewWrap;
