import React from "react";
import "./SwitchButton.scss";
import Form from "react-bootstrap/Form";

const SwitchButton = ({ title, className, handleSwitchChange, checked }) => {
  return (
    <>
      <div className="SwitchButton">
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            label={title}
            className={className}
            onChange={handleSwitchChange}
            checked={checked}
          />
        </Form>
      </div>
    </>
  );
};

export default SwitchButton;
