import React from 'react'
import TierIcon1 from "../../../../assets/images/admin/tier_emerald.svg"
import Dblarrow from "../../../../assets/images/dubl_arrow.svg"

import TierIcon2 from "../../../../assets/images/admin/tier_saphire_icon.svg"
import Dblarrow1 from "../../../../assets/images/admin/star_b.svg"
import TierIcon3 from "../../../../assets/images/admin/tier_diamond.svg"
import Dblarrow2 from "../../../../assets/images/admin/lock.svg"

const UnlockProgressCard = () => {
    const TierData = [
        {
            TierIcon1: TierIcon1,
            Dblarrow: Dblarrow,
            name: "Aquamarine",
            text: "Tier 0",
        },
        {
            TierIcon1: TierIcon1,
            Dblarrow: Dblarrow,
            name: "Emerald",
            text: "Tier 0",
        },
        {
            TierIcon1: TierIcon1,
            Dblarrow: Dblarrow,
            name: "Ruby",
            text: "Tier 0",
        },
        {
            TierIcon1: TierIcon2,
            Dblarrow: Dblarrow1,
            name: "Sapphire",
            text: "Tier 0",
            className:"tier_blue",
        },
        {
            TierIcon1: TierIcon3,
            Dblarrow: Dblarrow2,
            name: "Diamond",
            text: "Tier 0",
            className:"tier_yellow",
        },
    ];
    return (
        <>
            <div className='Tier_Unlock_Progress'>
                <h3>Tier unlock progress</h3>

                <ul>
                    {TierData.map((item, i) => {
                        return (
                            <li key={i}>
                                <figure className={`TierProgress_Icon ${item.className}`}>
                                    <img className="Tier_img" src={item.TierIcon1} alt="img" />
                                    <span className='Tier_Icon'><img src={item.Dblarrow} alt="img" /></span>
                                </figure>
                                <h6>{item.name}</h6>
                                <p>{item.text}</p>
                            </li>
                        )
                    })}
                </ul> 
            </div>
        </>
    )
}

export default UnlockProgressCard
