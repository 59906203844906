import React, { memo, useState } from "react";
import { useMedia } from "react-use";
import "./PositionCard.scss";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import {
  calUnrealizedPNL,
  getMarkPrice,
  numberSystemFormate,
} from "../../../utils/comman";
import { showDecimal } from "../../../../../_utils/comman";
import ClosePositionModal from "../ClosePositionModal/ClosePositionModal";
import AddProfitModal from "../AddProfitModal/AddProfitModal";
import { useAppSelector } from "../../../../../hooks/redux.hooks";
import { ApiServices } from "../../../services/api.services";
import { toastMsg } from "../../../../../common/Toast/Toast";
import { saveDeletePostion } from "../../../redux/_slices/exchange.slice";
import NoImage from "../../../../../assets/images/Logo-eterna-full.svg";

const PositionCard = () => {
  let dispatch = useDispatch();
  const listOfPairs = useSelector((state) => state?.exchange.listOfPairs);
  const allMarkPrice = useSelector((state) => state?.exchange?.allMarkPrice);
  const walletAddress = useSelector((state) => state.user.walletAddress);
  const otherFee = useSelector((state) => state.exchange.otherFee);
  const leverage = useAppSelector((state) => state.user.leverage);
  const chainId = useAppSelector((state) => state.user.chainId);
  var userPosition = useAppSelector((state) => state.exchange.userPosition);

  const [marketPrice, setMarketPrice] = useState("");
  const [marketSize, setMarketSize] = useState("");
  const [currentPosition, setCurrentPosition] = useState({});
  const [closePositionType, setClosePositionType] = useState(""); //Limit or Market
  const [closePositionModal, setClosePositionModal] = useState(false);
  const [showprofit, setShowprofit] = useState(false);
  const [checkOrder, setCheckOrders] = useState(false);

  const handleCloseprofit = () => setShowprofit(false);
  const isDesktop = useMedia("(min-width: 996px)", true);

  // const userPosition = [
  //   {
  //     pair: "BANDUSDT",
  //     id: 1,
  //     side: 0,
  //     user_id: "161863631",
  //     quantity: "8.8",
  //     position_value: "12.3248",
  //     price: "1.40054545",
  //     entry_price: "1.40054545",
  //     mark_price: "1.367",
  //     order_size: "8.8",
  //     liquidation_price: "0.30026855",
  //     realised_pnl: "-11.5382918",
  //     unrealised_pnl: "-0.2952",
  //     trailing_stop: "0",
  //     take_profit: 0,
  //     stop_loss: 0,
  //     margin_mode: "",
  //     leverage: "11",
  //     status: 1,
  //     trade_mode: "Cross",
  //     created_at: "1711723225711",
  //     updated_at: "23-07-2024 4:01:57",
  //     position_margin: "0",
  //     position_initial_margin: "1.27466551",
  //     position_maintainance_margin: "0.17297776",
  //     active_price: 0,
  //   },
  //   {
  //     pair: "SHIB1000USDT",
  //     id: 521,
  //     side: 0,
  //     user_id: "161863631",
  //     quantity: "890",
  //     position_value: "16.462",
  //     price: "0.01849663",
  //     entry_price: "0.01849663",
  //     mark_price: "0.017376",
  //     order_size: "890",
  //     liquidation_price: "0.00589172",
  //     realised_pnl: "-0.0261696",
  //     unrealised_pnl: "-0.99736",
  //     trailing_stop: "0",
  //     take_profit: 0,
  //     stop_loss: 0,
  //     margin_mode: "",
  //     leverage: "13",
  //     status: 1,
  //     trade_mode: "Cross",
  //     created_at: "1713004393001",
  //     updated_at: "24-07-2024 4:00:00",
  //     position_margin: "0",
  //     position_initial_margin: "1.27466551",
  //     position_maintainance_margin: "0.17297776",
  //     active_price: 0,
  //   },
  // ];
  let entry_price = currentPosition?.entry_price
    ? Number(currentPosition?.entry_price)
    : currentPosition?.price;

  let orderPair = currentPosition?.pair
    ? currentPosition?.pair
    : currentPosition?.symbol;

  const handleClosePositionButton = (item, type) => {
    setCurrentPosition(item);
    setClosePositionType(type);
    setClosePositionModal(true);
  };

  const handleShowTPSL = (item) => {
    setCurrentPosition(item);
    setShowprofit(true);
  };

  const calculateUnrealizedPnl = (itemPosition, last_price) => {
    let pnl = calUnrealizedPNL(
      itemPosition.side,
      itemPosition.order_size,
      last_price,
      itemPosition?.entry_price,
      itemPosition?.position_margin
    );
    return pnl;
  };

  const getUnrealizedPercentage = (item, markP) => {
    // let percentage;
    // percentage = (unrealizedPnl / positionMargin) * 1;
    // return showDecimal(percentage, 4);

    //new formula 25 july 2024
    // if (item?.side === 0) {
    //   const percantage =
    //     ((Number(getMarkPrice(item?.pair, allMarkPrice)) -
    //       Number(item?.entry_price)) /
    //       Number(item?.entry_price)) *
    //     item?.leverage *
    //     100;
    //   return showDecimal(percantage, 4);
    // } else {
    //   const percantage =
    //     ((Number(item?.entry_price) -
    //       Number(getMarkPrice(item?.pair, allMarkPrice))) /
    //       Number(item?.entry_price)) *
    //     item?.leverage *
    //     100;
    //   return showDecimal(percantage, 4);

    //new formula 06-august
    try {
      let unrealisedPnl;
      if (item?.side === 0) {
        unrealisedPnl = showDecimal(
          (Number(getMarkPrice(item?.pair, allMarkPrice)) -
            Number(item?.entry_price)) *
            item?.order_size,
          4
        );
      } else {
        unrealisedPnl = showDecimal(
          (Number(item?.entry_price) -
            Number(getMarkPrice(item?.pair, allMarkPrice))) *
            item?.order_size,
          4
        );
      }
      const initialMargin = showDecimal(
        (Number(item?.entry_price) * item?.order_size) / item?.leverage,
        4
      );

      return showDecimal((unrealisedPnl / initialMargin) * 100, 4);
    } catch (error) {}
  };

  const calculateUnrealizedPnlNew = (item, markP) => {
    // if (
    //   typeof unrealizedPnl !== "" &&
    //   cop !== "" &&
    //   Math.sign(unrealizedPnl) === 1
    // ) {
    //   // Calculate 99% of the value
    //   let pnl = (unrealizedPnl * (100 - cop)) / 100;
    //   // Return the result
    //   return showDecimal(pnl, 4);
    // } else return showDecimal(unrealizedPnl, 4);

    //New formula //25july2024
    try {
      let unrealisedPnl;
      if (item?.side === 0) {
        unrealisedPnl =
          (Number(getMarkPrice(item?.pair, allMarkPrice)) -
            Number(item?.entry_price)) *
          item?.order_size;
      } else {
        unrealisedPnl =
          (Number(item?.entry_price) -
            Number(getMarkPrice(item?.pair, allMarkPrice))) *
          item?.order_size;
      }

      return showDecimal(unrealisedPnl, 4);
    } catch (error) {}
  };

  const handleType = async (type, orderType, dataObj) => {
    setMarketSize(dataObj.marketSize);
    setMarketPrice(dataObj?.marketPrice || "");
    setClosePositionModal(false);
    if (walletAddress) {
      setCheckOrders(true);
      const data = {
        side: currentPosition.side === 1 ? "Buy" : "Sell", //Buy ==> 0, Sell ==> 1
        type: closePositionType === "Limit" ? 0 : 1, //Limit ==> 0, Market ==> 1
        pairName: currentPosition?.pair,
        leverage: leverage,
        trigger_price: Number(marketPrice),
        orderSize: Number(dataObj.marketSize),
        price: Number(
          dataObj.marketPrice ? dataObj.marketPrice : currentPosition?.price
        ),
        address: walletAddress,
        chainId: chainId,
        // symbol: paymentSymbol ? paymentSymbol?.toLowerCase() : "",
        symbol: currentPosition.pair,
        order_type: closePositionType,
        positionId: currentPosition.id,
        qty: Number(dataObj.marketSize),
      };
      // console.log("marketSize=", dataObj, dataObj.marketSize);
      try {
        if (currentPosition?.order_size == dataObj.marketSize) {
          dispatch(
            saveDeletePostion({
              ...currentPosition,
              order_type: orderType,
            })
          );
          removePostion(dataObj);
        } else {
          setCheckOrders(false);
        }
      } catch (error) {
        setMarketSize("");
        setMarketPrice("");
        setCheckOrders(false);
      }
    } else {
      setCheckOrders(false);
    }
  };

  const removePostion = async (dataObj) => {
    try {
      setMarketSize(dataObj?.marketSize);
      setMarketPrice(dataObj?.marketPrice || "");
      setClosePositionModal(false);
      if (walletAddress) {
        setCheckOrders(true);
        const data = {
          side: currentPosition.side === 1 ? "Buy" : "Sell", //Buy ==> 0, Sell ==> 1
          type: closePositionType === "Limit" ? 0 : 1, //Limit ==> 0, Market ==> 1
          pairName: currentPosition?.pair,
          leverage: leverage,
          trigger_price: Number(marketPrice),
          orderSize: Number(dataObj.marketSize),
          price: Number(
            dataObj.marketPrice ? dataObj.marketPrice : currentPosition?.price
          ),
          address: walletAddress,
          chainId: chainId,
          // symbol: paymentSymbol ? paymentSymbol?.toLowerCase() : "",
          symbol: currentPosition.pair,
          order_type: closePositionType,
          positionId: currentPosition.id,
          qty: Number(dataObj.marketSize),
        };
        // console.log("marketSize=", dataObj, dataObj.marketSize);

        console.log("position close>>>>>>>>>>>>>>>>", data);
        let response = await ApiServices.closeOrderPosition(data);
        setCheckOrders(false);
        if (response && response.status === 200) {
          setMarketSize("");
          setMarketPrice("");
          toastMsg.success("Order Submitted Successfully.", {
            toastId: "successMsg",
          });
        } else {
          setCheckOrders(false);
        }
      } else {
        setCheckOrders(false);
      }
    } catch (error) {
      console.log(error);
      setMarketSize("");
      setMarketPrice("");
      setCheckOrders(false);
      toastMsg.error(error.data.message);
    }
  };

  const getMarginRatio = (item) => {
    const marginRatio =
      Number(item?.position_maintainance_margin) /
        Number(item?.position_initial_margin) +
      Number(item?.position_maintainance_margin);
    return marginRatio;
  };

  //   useEffect(() => {
  //     if (deletePostion && Object.keys(deletePostion).length > 0 && checkOrder) {
  //       removePostion();
  //     }
  //   }, [deletePostion]);

  return (
    <>
      <div className="Position_Table_card">
        {walletAddress && userPosition.length > 0 ? (
          userPosition?.map((item, index) => {
            const pairDetails = listOfPairs?.find(
              (element) => element?.pair_name.split("_").join("") == item?.pair
            );

            const markPrice = allMarkPrice?.find(
              (element) => element?.pair_name.replace("_", "") === item?.pair
            );
            let pnlNew = calculateUnrealizedPnlNew(item, markPrice?.mark_price);

            let unrealizedPercentage = getUnrealizedPercentage(
              item,
              markPrice?.mark_price
            );

            let marginRatio = getMarginRatio(item);

            return (
              <div className="Position_Card">
                {isDesktop ? (
                  <>
                    <div
                      className={`Position_Card_Box desktopView ${
                        item.side === 1 && "Gradient_Red"
                      }`}
                    >
                      <div className="Position_Card_Tabledata Unrealized_Pn">
                        <ul className="Perpetual_Pnl">
                          <li>
                            <h4>
                              <img src={pairDetails?.icon} alt="icon" />{" "}
                              {item?.pair}{" "}
                              <span
                                className={
                                  item.side === 0 ? "long_btn" : "Short_btn"
                                }
                              >
                                {item.side === 0 ? "Long" : "Short"}
                              </span>
                            </h4>
                            <h6>
                              Perpetual <span>{item.leverage}x</span>
                            </h6>
                          </li>
                          <li>
                            <h5>Entry price</h5>
                            <p>{item.entry_price}</p>
                          </li>
                          <li>
                            <h5>Mark price</h5>
                            <p>
                              {getMarkPrice(item?.pair, allMarkPrice)
                                ? getMarkPrice(item?.pair, allMarkPrice)
                                : "--"}
                            </p>
                          </li>
                          <li className="liquiedPrice">
                            <h5>Liquidation Price</h5>
                            <p>
                              {numberSystemFormate(
                                item?.liquidation_price,
                                pairDetails?.price_scale
                              )}
                            </p>
                          </li>
                          <li className="liquiedPrice">
                            <h3
                              className={`${
                                pnlNew >= 0 ? "text_green" : "text_red"
                              } h4_heading mb-0`}
                            >
                              {pnlNew} ({unrealizedPercentage}
                              %)
                            </h3>
                            <h6>Unrealized PNL</h6>
                          </li>
                          <li>
                            <h5>
                              Size{" "}
                              <span className="Btc_btn">
                                {item?.pair?.replace("USDT", "")}
                              </span>
                            </h5>
                            <p>{item.order_size}</p>
                          </li>
                          <li>
                            <h5>Margin Ratio</h5>
                            <p className="text_green">
                              {Number(marginRatio).toFixed(4)}%
                            </p>
                          </li>
                          <li>
                            <h5>TP/SL</h5>
                            <p>
                              <span className="text_green">
                                {item?.take_profit}
                              </span>
                              /
                              <span className="text_red">
                                {item?.stop_loss}
                              </span>
                            </p>
                          </li>
                          {/* <li>
                        <h5>Trigger Conditions</h5>
                        <p>Mark &gt;= 44,567.80</p>
                      </li> */}
                        </ul>
                      </div>
                      <div className="Position_Card_Btns">
                        <CommonButton
                          title="Market Close"
                          onClick={() =>
                            handleClosePositionButton(item, "Market")
                          }
                          className="grey-border-btn"
                        />
                        <CommonButton
                          title="Limit Close"
                          text={item.side === 0 ? "Short" : "Long"}
                          onClick={() =>
                            handleClosePositionButton(item, "Limit")
                          }
                          className="grey-border-btn"
                        />
                        <CommonButton
                          title="Take Profit/Stop Loss"
                          className="grey-border-btn"
                          onClick={() => handleShowTPSL(item)}
                        />
                        <ClosePositionModal
                          show={closePositionModal}
                          handleClose={() => setClosePositionModal(false)}
                          closePositionType={closePositionType}
                          marketInfo={{
                            marketSize,
                            marketPrice,
                            currentPosition,
                            markPrice,
                          }}
                          handleSubmit={handleType}
                          lastPrice={pairDetails?.last_price}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`Position_Card_Box ${
                        item.side === 1 && "Gradient_Red"
                      }`}
                    >
                      <div className="Position_Card_Tabledata">
                        <ul className="Perpetual_Pnl">
                          <li>
                            <h4>
                              <img src={pairDetails?.icon} alt="icon" />{" "}
                              {item?.pair}{" "}
                              <span
                                className={
                                  item.side === 0 ? "long_btn" : "Short_btn"
                                }
                              >
                                {item.side === 0 ? "Long" : "Short"}
                              </span>
                            </h4>
                            <h6>
                              Perpetual <span>{item.leverage}x</span>
                            </h6>
                          </li>
                          <li>
                            <h5>Entry price</h5>
                            <p>{item.entry_price}</p>
                          </li>
                          <li>
                            <h5>Mark price</h5>
                            <p>
                              {getMarkPrice(item?.pair, allMarkPrice)
                                ? getMarkPrice(item?.pair, allMarkPrice)
                                : "--"}
                            </p>
                          </li>
                          <li>
                            <h5>Liquidation Price</h5>
                            <p>
                              {numberSystemFormate(
                                item?.liquidation_price,
                                pairDetails?.price_scale
                              )}
                            </p>
                          </li>
                        </ul>
                        <ul className="Unrealized_Pnl">
                          <li>
                            <h3
                              className={`${
                                pnlNew >= 0 ? "text_green" : "text_red"
                              } h4_heading mb-0`}
                            >
                              {pnlNew} ({unrealizedPercentage}
                              %)
                            </h3>
                            <h6>Unrealized PNL</h6>
                          </li>
                          <li>
                            <h5>
                              Size{" "}
                              <span className="Btc_btn">
                                {item?.pair?.replace("USDT", "")}
                              </span>
                            </h5>
                            <p>{item.order_size}</p>
                          </li>
                          <li>
                            <h5>Margin Ratio</h5>
                            <p className="text_green">
                              {Number(item?.position_margin)?.toFixed(2)}%
                            </p>
                          </li>
                          <li>
                            <h5>TP/SL</h5>
                            <p>
                              <span className="text_green">
                                {item?.take_profit}
                              </span>
                              /
                              <span className="text_red">
                                {item?.stop_loss}
                              </span>
                            </p>
                          </li>
                          {/* <li>
                        <h5>Trigger Conditions</h5>
                        <p>Mark &gt;= 44,567.80</p>
                      </li> */}
                        </ul>
                      </div>
                      <div className="Position_Card_Btns">
                        <CommonButton
                          title="Market Close"
                          onClick={() =>
                            handleClosePositionButton(item, "Market")
                          }
                          className="grey-border-btn"
                        />
                        <CommonButton
                          title="Limit Close"
                          text="Short"
                          onClick={() =>
                            handleClosePositionButton(item, "Limit")
                          }
                          className="grey-border-btn"
                        />
                        <CommonButton
                          title="Take Profit/Stop Loss"
                          className="grey-border-btn"
                          onClick={() => handleShowTPSL(item)}
                        />
                        <ClosePositionModal
                          show={closePositionModal}
                          handleClose={() => setClosePositionModal(false)}
                          closePositionType={closePositionType}
                          marketInfo={{
                            marketSize,
                            marketPrice,
                            currentPosition,
                            markPrice,
                          }}
                          handleSubmit={handleType}
                          lastPrice={pairDetails?.last_price}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })
        ) : (
          <span className="no_data">
            {/* You have no open positions. */}
            {walletAddress && <img src={NoImage} alt="" />}
          </span>
        )}
        {/* 
        <ClosePositionModal
          show={closePositionModal}
          handleClose={() => setClosePositionModal(false)}
          closePositionType={closePositionType}
          marketInfo={{ marketSize, marketPrice, currentPosition }}
          handleSubmit={handleType}
          lastPrice={pairDetails?.last_price}
        /> */}
        <AddProfitModal
          show={showprofit}
          handleClose={handleCloseprofit}
          componentType="Positions"
          orderPrice={entry_price}
          quantity={currentPosition.quantity}
          ltp={getMarkPrice(orderPair, listOfPairs)}
          orderPair={orderPair}
          currentPosition={currentPosition}
        />

        {/* <div className='Position_Card'>
                    <div className='Position_Card_Box Gradient_Red'>
                        <div className='Position_Card_Tabledata'>
                            <ul className='Perpetual_Pnl'>
                                <li>
                                    <h4><img src={BtcIcon} alt='icon' /> BTC-USD <span className='Short_btn'>Short</span></h4>
                                    <h6>Perpetual <span>20x</span></h6>
                                </li>
                                <li>
                                    <h5>Entry price</h5>
                                    <p>38,468.00</p>
                                </li>
                                <li>
                                    <h5>Mark price</h5>
                                    <p>44,567.80</p>
                                </li>
                                <li>
                                    <h5>Liquidation Price</h5>
                                    <p>32,678.25</p>
                                </li>
                            </ul>
                            <ul className='Unrealized_Pnl'>
                                <li>
                                    <h3 className='text_red h4_heading mb-0'>+128.95 (+186.74%)</h3>
                                    <h6>Unrealized PNL</h6>
                                </li>
                                <li>
                                    <h5>Size <span className='Btc_btn'>BTC</span></h5>
                                    <p>1.2500</p>
                                </li>
                                <li>
                                    <h5>Margin Ratio</h5>
                                    <p className='text_green'>4.68%</p>
                                </li>
                                <li>
                                    <h5>Trigger Conditions</h5>
                                    <p>Mark &gt;= 44,567.80</p>
                                </li>
                            </ul>
                        </div>
                        <div className='Position_Card_Btns'>
                            <CommonButton title="Close Positions" onClick={handleShowlimit} className="grey-border-btn text_red" />
                            <CommonButton title="Convert to" text="Long" onClick={handleShowlimit} textclass="text_green" className="grey-border-btn" />
                            <CommonButton title="Stop Profit & Loss" onClick={handleShowprofit} className="grey-border-btn" />
                        </div>
                    </div>
                </div> */}
      </div>

      {/* <ClosePositionModal show={show} handleClose={handleClose} />
            <LimitPositionModal show={showlimit} handleClose={handleCloselimit} />
            <AddProfitModal show={showprofit} handleClose={handleCloseprofit} /> */}
    </>
  );
};

export default memo(PositionCard);
