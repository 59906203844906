import React from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonButton from '../../../../common/CommonButton/CommonButton';
import SuccessfulIcon from "../../../../assets/images/admin/transaction-success.svg"

const DepositSuccessfulModal = ({ show, handleClose }) => {
    const orderdata = [
        {
            heading: "Transaction type",
            text: "Deposit",
        },
        {
            heading: "Date",
            text: "Mon 13:03:44",
        },
        {
            heading: "Payment method",
            text: "Spot Wallet",
        },
        {
            heading: "Currency",
            text: "1000.00 USDT",
        },
    ];
    return (
        <>
            <Modal className='Admin_Modal Deposit_successful_modal' centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Deposit Successful</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='Deposit_successful text-center'>
                        <img src={SuccessfulIcon} alt='icon' />
                        <h3>1000.00 USDT</h3>
                        <p>Your transaction was successful</p>
                    </div>
                    <div className='Deposit_successful_Information'>
                        <ul>
                            {orderdata.map((item, i) => {
                                return (
                                    <li className='d-md-flex align-items-center justify-content-between' key={i}>
                                        <p>{item.heading}</p>
                                        <h4>{item.text}</h4>
                                    </li>
                                )
                            })}
                        </ul>
                        <CommonButton className="w-100" title="Back to My Account" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DepositSuccessfulModal
