import React, { useRef, useState, useEffect } from "react";
import stagIcon from "../../../../../assets/icons/star-icon.svg";
import "./FavouriteTab.scss";
import { Responsive, WidthProvider } from "react-grid-layout";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";
import { useDispatch, useSelector } from "react-redux";
import { saveFavPairList } from "../../../../../redux/_slices/user.slice";
import { arrayMoveImmutable } from "array-move";
import {
    savePrevSelectedPairKey,
    saveSelectedPair,
    saveSelectedPairKey,
} from "../../../../../redux/_slices/user.slice";
import { showDecimalHead } from "../../../../../services/HelperServices";
import {
    saveOrderSize,
    savePairDecimals,
} from "../../../redux/_slices/exchange.slice";
import { getDecimalString } from "../../../utils/comman";
import SortableList, { SortableItem } from "react-easy-sort";

const FavouriteTab = () => {
    const dispatch = useDispatch();
    const scrollContainerRef = useRef(null);
    const ReactGridLayout = WidthProvider(Responsive);
    const favPairList = useSelector((state) => state.user?.favPairList);
    const pairList = useSelector((state) => state.exchange.listOfPairs);
    const selectedPairKey = useSelector((state) => state.user.selectedPairKey);

    const onSortEnd = (oldIndex, newIndex) => {
        const updatedFavPairList = arrayMove(favPairList, oldIndex, newIndex);
        dispatch(saveFavPairList(updatedFavPairList));
    };

    const arrayMove = (arr, from, to) => {
        const newArr = arr.slice();
        const [removed] = newArr.splice(from, 1);
        newArr.splice(to, 0, removed);
        return newArr;
    };

    const handleOnSelect = (value) => {
        const pairData = pairList?.find((doc) => doc.pair_name === value);
        const priceDecimalLength = getDecimalString(
            pairData?.min_price_increment,
            pairData?.price_decimal
        );

        const baseDecimalLength = getDecimalString(
            pairData?.min_size_increment,
            pairData?.base_decimal
        );

        dispatch(savePairDecimals({ priceDecimalLength, baseDecimalLength }));
        dispatch(savePrevSelectedPairKey(selectedPairKey));
        dispatch(saveSelectedPairKey(value));
        dispatch(saveOrderSize(""));

        dispatch(saveSelectedPair(pairData));
    };

    const getPrices = (pairKey) => {
        return pairList?.find((data) => data.pair_name === pairKey);
    };

    const scroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 50;
            scrollContainerRef.current.scrollBy({
                left: direction === "left" ? -scrollAmount : scrollAmount,
                behavior: "smooth",
            });
        }
    };

    const leftScrollHandler = () => {
        scroll("left");
    };

    const rightScrollHandler = () => {
        scroll("right");
    };

    const handleOnChanges = (currentPrice, price24) => {
        let priceDiff = 0;
        if (currentPrice > price24) {
            priceDiff = currentPrice - price24;
        } else {
            priceDiff = price24 - currentPrice;
        }
        if (price24 === 0) {
            return 0;
        }
        if (currentPrice && priceDiff) {
            return ((priceDiff / price24) * 100).toFixed(2);
        }

        return 0;
    };

    const layout = [{ i: "favourite-card-body", x: 0, y: 0, w: 12, h: 1 }];

    return (
        <div className="layout">
            <div key="favourite-card-body" className="favourite-card-body">
                <div className="favorite-main">
                    <div className="container-fluid">
                        <div className="d-flex align-items flex-nowrap">
                            <div className="fav-left">
                                <img src={stagIcon} alt="Star icon" />
                            </div>
                            <div className="favourite-scroll-section-right">
                                <div className="favourite-scroll-main">
                                    <LeftArrow onclick={leftScrollHandler} />

                                    <div
                                        className="scroll-controller"
                                        ref={scrollContainerRef}
                                    >
                                        <SortableList
                                            onSortEnd={onSortEnd}
                                            draggedItemClassName="dragged"
                                        >
                                            {favPairList?.map(
                                                (pairKey, index) => (
                                                    <SortableItem key={pairKey}>
                                                        <button
                                                            type="button"
                                                            className="favourite-item"
                                                            key={index}
                                                            onClick={() => {
                                                                handleOnSelect(
                                                                    pairKey
                                                                );
                                                            }}
                                                        >
                                                            <span className="favourite-item-symbol">
                                                                {pairKey
                                                                    ?.replace(
                                                                        "_",
                                                                        ""
                                                                    )
                                                                    ?.toUpperCase()}
                                                            </span>
                                                            <span
                                                                className={`${
                                                                    getPrices(
                                                                        pairKey
                                                                    )?.change >
                                                                    0
                                                                        ? "favGreen"
                                                                        : "favAskRed"
                                                                } `}
                                                            >
                                                                {handleOnChanges(
                                                                    getPrices(
                                                                        pairKey
                                                                    )
                                                                        ?.lastPrice,
                                                                    getPrices(
                                                                        pairKey
                                                                    )
                                                                        ?.close_24hour
                                                                ) !== 0
                                                                    ? getPrices(
                                                                          pairKey
                                                                      )
                                                                          ?.lastPrice >
                                                                      getPrices(
                                                                          pairKey
                                                                      )
                                                                          ?.close_24hour
                                                                        ? "+"
                                                                        : "-"
                                                                    : ""}{" "}
                                                                {getPrices(
                                                                    pairKey
                                                                )?.change
                                                                    ? showDecimalHead(
                                                                          getPrices(
                                                                              pairKey
                                                                          )
                                                                              ?.change *
                                                                              100,
                                                                          2
                                                                      )
                                                                    : 0}
                                                                %
                                                            </span>
                                                        </button>
                                                    </SortableItem>
                                                )
                                            )}
                                        </SortableList>
                                        {/* <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">ADAUSDT</span>
                      <span className="favGreen"> 1.98%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">
                        1000PEPEUSDT
                      </span>
                      <span className="favAskRed"> -3.00%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">ADAUSDT</span>
                      <span className="favGreen"> 1.98%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">
                        1000PEPEUSDT
                      </span>
                      <span className="favAskRed"> -3.00%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">ADAUSDT</span>
                      <span className="favGreen"> 1.98%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">
                        1000PEPEUSDT
                      </span>
                      <span className="favAskRed"> -3.00%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">ADAUSDT</span>
                      <span className="favGreen"> 1.98%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">
                        1000PEPEUSDT
                      </span>
                      <span className="favAskRed"> -3.00%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">ADAUSDT</span>
                      <span className="favGreen"> 1.98%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">
                        1000PEPEUSDT
                      </span>
                      <span className="favAskRed"> -3.00%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">ADAUSDT</span>
                      <span className="favGreen"> 1.98%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">ADAUSDT</span>
                      <span className="favGreen"> 1.98%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">
                        1000PEPEUSDT
                      </span>
                      <span className="favAskRed"> -3.00%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">ADAUSDT</span>
                      <span className="favGreen"> 1.98%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">ADAUSDT</span>
                      <span className="favGreen"> 1.98%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">
                        1000PEPEUSDT
                      </span>
                      <span className="favAskRed"> -3.00%</span>
                    </button>
                    <button type="button" className="favourite-item">
                      <span className="favourite-item-symbol">ADAUSDT</span>
                      <span className="favGreen"> 1.98%</span>
                    </button> */}
                                    </div>

                                    <RightArrow onclick={rightScrollHandler} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FavouriteTab;
