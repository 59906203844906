import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import TradeChart from "./Trading/Trading";
import UserLedger from "./UserLedger/UserLedger";
import SubHeader from "./SubHeader/SubHeader";
// import FavoriteBar from "./FavoriteBar/FavoriteBar";
import MarginRatio from "./MarginRatio/MarginRatio";
import "./MobileLayout.scss";
import CommonButton from "../../../common/CommonButton/CommonButton";
import OrderBook from "./OrderBook";
// import AnalysisWidget from "./AnalysisWidget/AnalysisWidget";
import AccountUpdate from "./AccountUpdate/AccountUpdate";
import BuySellMobileModal from "./BuySell/BuySellMobileModal/BuySellMobileModal";
import AllMarket from "./AllMarket/AllMarket";
import AnalysisWidget from "./AnalysisWidget/AnalysisWidget";
import BuySell from "./BuySell/BuySell";

const MobileLayout = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="Mobile_view_design">
        <div className="cardStyle FavoriteBar card">
          <div className="card_body_style card-body">
            <AccountUpdate />
          </div>
        </div>
        {/* <div className="cardStyle AllMarket card">
          <div className="card_body_style card-body">
            <div className="AllMarket_V2 nondraggable_card">
              <AllMarket />
            </div>
          </div>
        </div> */}
        <div className="cardStyle BuySell card">
          <div className="card_body_style card-body">
            {/* <BuySellTab /> */}
            <div className='BuySell_V2'>
              <BuySell />
            </div>
          </div>
        </div>
        <div className="cardStyle SubHeader card">
          <div className="card_body_style card-body">
            <SubHeader />
          </div>
        </div>
        <Tabs
          defaultActiveKey="chart"
          id="uncontrolled-tab-example"
          className="Mobile_Chart_Tabs"
        >
          <Tab eventKey="chart" title="Chart">
            <div className="cardStyle TradeChart card">
              <div className="card_body_style card-body">
                <TradeChart />
              </div>
            </div>
            <div className="cardStyle UserLedger card">
              <div className="card_body_style card-body">
                <UserLedger />
              </div>
            </div>
            {/* <div className="cardStyle AnalysisWidget card">
              <div className="card_body_style card-body">
                <AnalysisWidget />
              </div>
            </div> */}
            {/* <div className="cardStyle MarginRatio card my-10">
              <div className="card_body_style card-body">
                <MarginRatio />
              </div>
            </div>  */}
          </Tab>
          <Tab eventKey="order-book" title="Order Book">
            <div className="cardStyle OrderBook card">
              <div className="card_body_style card-body mobile_inner">
                <OrderBook />
              </div>
            </div>
          </Tab>
          <Tab eventKey="contact" title="Trades">
            <div className="cardStyle MarketTrades card">
              <div className="card_body_style card-body">MarketTrades</div>
            </div>
          </Tab>
        </Tabs>

        {/* <div className="buy_Sell_mobile_btns d-flex">
          <div className="w-50">
            <CommonButton
              onClick={handleShow}
              title="Buy"
              className="w-100 buy-btn"
            />
          </div>
          <div className="w-50">
            <CommonButton
              onClick={handleShow}
              title="Sell"
              className="w-100 sell-btn"
            />
          </div>
        </div> */}
      </div>
      {/* <BuySellMobileModal show={show} handleClose={handleClose} /> */}
    </>
  );
};

export default MobileLayout;
