import React, { useEffect } from "react";
import Admin from "../../Admin";
import "./Overview.scss";
import Hicon from "../../../../assets/icons/menuicon6.svg";
import {
    RightarrowIcon,
    SearchIcon,
    UserIcon,
} from "../../../../assets/Svg/SvgImages";
import { Col, Row } from "react-bootstrap";
import AssetsDashboard from "./AssetsDashboard/AssetsDashboard";
import { Link } from "react-router-dom";
import { ApiServices } from "../../../Exchange/services/api.services";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { saveUserProfile } from "../../../../redux/_slices/user.slice";
import { getWalletAddressFormate } from "../../../../_utils/comman";
import moment from "moment";
import CommonButton from "../../../../common/CommonButton/CommonButton";

const Overview = () => {
    const userInfo = useAppSelector((state) => state.user.userProfile);
    const walletAddress = useAppSelector((state) => state.user.walletAddress);
    const dispatch = useDispatch();

    const getUserProfileInfo = async () => {
        if (walletAddress) {
            let profile = await ApiServices.getUserProfile({
                address: walletAddress,
            });
            if (profile?.data) {
                dispatch(
                    saveUserProfile({
                        addres: profile?.data?.addres,
                        userId: profile?.data?.userId,
                        userName: profile?.data?.userName,
                        lastLogin: profile?.data?.lastLogin,
                        ipAddress: profile?.data?.ipAddress,
                        rank: profile?.data?.rank,
                    })
                );
            }
        } else {
            dispatch(
                saveUserProfile({
                    addres: "",
                    userId: "",
                    userName: "",
                    lastLogin: "",
                    ipAddress: "",
                    rank: "",
                })
            );
        }
    };

    useEffect(() => {
        getUserProfileInfo();
    }, [walletAddress]);

    return (
        <>
            <Admin>
                <div className="Common_TopBar">
                    <Row className="align-items-center">
                        <Col xs={12} xl={8}>
                            <div className="UserId_Time d-md-flex align-items-center">
                                <figure className="mb-0 me-4">
                                    <UserIcon />
                                </figure>
                                <div className="UserId_Time_Rank mt-3 mt-md-0">
                                    <div className="d-flex align-items-center mb-2">
                                        <span className="TokenAdds">
                                            {getWalletAddressFormate(
                                                userInfo?.addres
                                            )}
                                        </span>
                                        <p>
                                            <span>User ID:</span>{" "}
                                            {userInfo?.userId}
                                        </p>
                                        <h5>
                                            <Link to="/referral">
                                                <img src={Hicon} alt="icon" />
                                                <span>RANK </span>
                                                {userInfo?.rank}{" "}
                                                <RightarrowIcon />
                                            </Link>
                                        </h5>
                                    </div>
                                    <div className="d-sm-flex align-items-center UserId_Time_LastLogin mt-2 mt-md-0">
                                        <p>
                                            <span>Last Login time:</span>{" "}
                                            {moment(userInfo?.lastLogin).format(
                                                "DD-MM-YYYY HH:mm:ss"
                                            )}
                                        </p>
                                        <p className="mt-2 mt-sm-0">
                                            <span>IP:</span>{" "}
                                            {userInfo?.ipAddress}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col
                            xs={12}
                            xl={4}
                            className="d-md-flex justify-content-xl-end mt-3 mt-xl-0"
                        >
                            {/* <div className="Dash_SearchBar d-md-flex align-items-center justify-content-end">
                                <Link to="/coin-details">
                                    <CommonButton
                                        title="Coin Details"
                                        className="btn-primary btn btn-primary"
                                    />
                                </Link>
                            </div> */}
                        </Col>
                    </Row>
                </div>
                <div className="Common_admin_Contentarea">
                    <AssetsDashboard />
                </div>
            </Admin>
        </>
    );
};

export default Overview;
