import React, { useRef, useState, useEffect } from "react";
import stagIcon from "../../../../../assets/icons/star-icon.svg";
import "./FavouriteTab.scss";
import { Responsive, WidthProvider } from "react-grid-layout";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";
import { useDispatch, useSelector } from "react-redux";
import {
  savePrevSelectedPairKey,
  saveSelectedPair,
  saveSelectedPairKey,
} from "../../../../../redux/_slices/user.slice";
import { showDecimalHead } from "../../../../../services/HelperServices";
import { getDecimalString } from "../../../utils/comman";
import {
  saveOrderSize,
  savePairDecimals,
} from "../../../redux/_slices/spotExchange.slice";
import SortableList, { SortableItem } from "react-easy-sort";
import { saveSpotFavPairList } from "../../../../../redux/_slices/user.slice";

const FavouriteTab = () => {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const ReactGridLayout = WidthProvider(Responsive);
  const favPairList = useSelector((state) => state.user?.spotFavPairList);
  const spotPairs = useSelector((state) => state?.spotExchange?.spotPairList);
  const selectedPairKey = useSelector((state) => state.user.selectedPairKey);

  const handleOnSelect = (value) => {
    const pairData = spotPairs?.find((doc) => doc.pair === value);
    const priceDecimalLength = getDecimalString(
      pairData?.min_price_increment,
      pairData?.price_decimal
    );

    const baseDecimalLength = getDecimalString(
      pairData?.min_size_increment,
      pairData?.base_decimal
    );

    dispatch(savePairDecimals({ priceDecimalLength, baseDecimalLength }));
    dispatch(savePrevSelectedPairKey(selectedPairKey));
    dispatch(saveSelectedPairKey(value));
    dispatch(saveOrderSize(""));

    dispatch(saveSelectedPair(pairData));
  };

  const getPrices = (pairKey) => {
    return spotPairs?.find((data) => data.pair_name === pairKey);
  };

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 50;
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const leftScrollHandler = () => {
    scroll("left");
  };

  const rightScrollHandler = () => {
    scroll("right");
  };

  const handleOnChanges = (currentPrice, price24) => {
    let priceDiff = 0;
    if (currentPrice > price24) {
      priceDiff = currentPrice - price24;
    } else {
      priceDiff = price24 - currentPrice;
    }
    if (price24 === 0) {
      return 0;
    }
    if (currentPrice && priceDiff) {
      return ((priceDiff / price24) * 100).toFixed(2);
    }

    return 0;
  };

  const onSortEnd = (oldIndex, newIndex) => {
      const updatedFavPairList = arrayMove(favPairList, oldIndex, newIndex);
      dispatch(saveSpotFavPairList(updatedFavPairList));
  };

  const arrayMove = (arr, from, to) => {
      const newArr = arr.slice();
      const [removed] = newArr.splice(from, 1);
      newArr.splice(to, 0, removed);
      return newArr;
  };  

  const layout = [{ i: "favourite-card-body", x: 0, y: 0, w: 12, h: 1 }];
  
  return (
      <ReactGridLayout
          className="layout"
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={20}
          isResizable={false}
          isDraggable={false}
          autoSize={true}
          useCSSTransforms={true}
      >
          <div key="favourite-card-body" className="favourite-card-body">
              <div className="favorite-main">
                  <div className="container-fluid">
                      <div className="d-flex align-items flex-nowrap">
                          <div className="fav-left">
                              <img src={stagIcon} alt="Star icon" />
                          </div>
                          <div className="favourite-scroll-section-right">
                              <div className="favourite-scroll-main">
                                  <LeftArrow onclick={leftScrollHandler} />
                                  <div
                                      className="scroll-controller"
                                      ref={scrollContainerRef}
                                  >
                                      <SortableList
                                          onSortEnd={onSortEnd}
                                          draggedItemClassName="dragged"
                                      >
                                          {favPairList?.map(
                                              (pairKey, index) => (
                                                  <SortableItem key={pairKey}>
                                                      <button
                                                          type="button"
                                                          className="favourite-item"
                                                          onClick={() => {
                                                              handleOnSelect(
                                                                  pairKey
                                                              );
                                                          }}
                                                      >
                                                          <span className="favourite-item-symbol">
                                                              {pairKey
                                                                  ?.replace(
                                                                      "_",
                                                                      ""
                                                                  )
                                                                  ?.toUpperCase()}
                                                          </span>
                                                          <span
                                                              className={`${
                                                                  getPrices(
                                                                      pairKey
                                                                  )?.change > 0
                                                                      ? "favGreen"
                                                                      : "favAskRed"
                                                              } `}
                                                          >
                                                              {handleOnChanges(
                                                                  getPrices(
                                                                      pairKey
                                                                  )?.lastPrice,
                                                                  getPrices(
                                                                      pairKey
                                                                  )
                                                                      ?.close_24hour
                                                              ) !== 0
                                                                  ? getPrices(
                                                                        pairKey
                                                                    )
                                                                        ?.lastPrice >
                                                                    getPrices(
                                                                        pairKey
                                                                    )
                                                                        ?.close_24hour
                                                                      ? "+"
                                                                      : "-"
                                                                  : ""}{" "}
                                                              {getPrices(
                                                                  pairKey
                                                              )?.change
                                                                  ? showDecimalHead(
                                                                        getPrices(
                                                                            pairKey
                                                                        )
                                                                            ?.change *
                                                                            100,
                                                                        2
                                                                    )
                                                                  : 0}
                                                              %
                                                          </span>
                                                      </button>
                                                  </SortableItem>
                                              )
                                          )}
                                      </SortableList>
                                  </div>

                                  <RightArrow onclick={rightScrollHandler} />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </ReactGridLayout>
  );
};

export default FavouriteTab;
