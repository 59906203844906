import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { RightarrowIcon } from "../../../../../assets/Svg/SvgImages";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import "./AssetsDashboard.scss";
import RecentTransactionTable from "./RecentTransactionTable";
import AssetsProgressbar from "./AssetsProgressbar";
import ReferIcon from "../../../../../assets/images/gfx-rewards.svg";
import ehxIcon from "../../../../../assets/icons/menuicon6.svg";
import newsicon from "../../../../../assets/icons/menuicon12.svg";
import TrendingPairs from "../TrendingPairs/TrendingPairs";
import OverviewAssetsTable from "../../Overview/OverviewAssetsTable/OverviewAssetsTable";
import { useAppSelector } from "../../../../../hooks/redux.hooks";
import { ApiServices } from "../../../../Exchange/services/api.services";
import hideEyeIcon from "../../../../../assets/images/admin/eye-slash.svg";
import { useDispatch } from "react-redux";
import { saveHideBalance } from "../../../../../redux/_slices/user.slice";
import HideShowBalance from "../../../common/HideShowBalance/HideShowBalance";
import DepositModal from "../../../../Exchange/components/BuySell/DepositModal/DepositModal";
import WithdrawModal from "../../../../Exchange/components/BuySell/WithdrawModal/WithdrawModal";
import DashboardMoveFunds from "../../../components/MoveFunds/DashboardMoveFunds";
import HideShowBadge from "../../../common/HideShowBalance/HideShowBadge";
import { saveUsdValue } from "../../../../../redux/_slices/user.slice";
import Loader from "../../../common/Loader/Loader";
import { updateLoader } from "../../../../Exchange/redux/_slices/exchange.slice";
import {
    saveUserSpotBalance,
    saveUserUnifiedTradingBalance,
    saveUserFundingBalance,
} from "../../../../../redux/_slices/user.slice";
import { Link } from "react-router-dom";
const AssetsDashboard = () => {
    const [totalAssetBalance, setTotalAssetBalance] = useState(0);
    const [showDeposit, setShowDeposit] = useState(false);
    const [showWithdraw, setShowWithdraw] = useState(false);
    const [showMoveFunds, setShowMoveFunds] = useState(false);
    const [fundingGraphValue, setfundingGraphValue] = useState(0);
    const [unifiedGraphValue, setunifiedGraphValue] = useState(0);
    const [inverseGraphValue, setInverseGraphValue] = useState(0);
    const [searchCoin, setSearchCoin] = useState("");
    const walletAddress = useAppSelector((state) => state.user.walletAddress);
    const hideBalance = useAppSelector((state) => state.user.hideBalance);
    const usdValue = useAppSelector((state) => state.user.usdValue);
    const loader = useAppSelector((state) => state.exchange.loader);
    const userFundingBalance = useAppSelector(
        (state) => state.user.userFundingBalance
    );
    const userUnifiedBalance = useAppSelector(
        (state) => state.user.userUnifiedTradingBalance
    );
    const userSpotBalance = useAppSelector(
        (state) => state.user.userSpotBalance
    );
    const dispatch = useDispatch();

    const getAllBalance = async () => {
        if (walletAddress) {
            let getUsdpPrice = 0;
            let fundingBalance = await ApiServices.getFundingBalance({
                address: walletAddress,
            });
            let spotResponse = await ApiServices.getUnifiedTrading({
                address: walletAddress,
                coin: searchCoin,
            });


            try {
                getUsdpPrice = await ApiServices.getUsdpPrice();
            } catch (error) {}

            if (fundingBalance) {
                dispatch(
                    saveUserFundingBalance({
                        totalEquity: fundingBalance?.data?.totalEquity,
                        availableBalance:
                            fundingBalance?.data?.totalWalletBalance,
                        coins: fundingBalance?.data?.coins,
                    })
                );
                fundingBalance?.data?.totalEquity > 0 &&
                    setfundingGraphValue(
                        (fundingBalance?.data?.totalWalletBalance /
                            fundingBalance?.data?.totalEquity) *
                            100
                    );
            } else {
                dispatch(
                    saveUserFundingBalance({
                        totalEquity: 0,
                        availableBalance: 0,
                        coins: [],
                    })
                );
            }

            if (spotResponse) {
                const filteredCoins = spotResponse?.data?.coins.filter(
                    (coin) => !coin.coin.includes("USDT")
                );

                const totalEquityValue = filteredCoins.reduce((total, coin) => {
                    const equity = Number(coin.usdValue) || 0;
                    return total + equity;
                }, 0);

                const unrealisedPnl = filteredCoins.reduce((total, coin) => {
                    const pnl = Number(coin.unrealisedPnl) || 0;
                    return total + pnl;
                }, 0);
                
                dispatch(
                    saveUserSpotBalance({
                        totalEquity: totalEquityValue,
                        unrealizedPnl: unrealisedPnl,
                        coins: filteredCoins,
                    })
                );
                spotResponse?.data?.totalEquity > 0 &&
                    setInverseGraphValue(
                        (spotResponse?.data?.totalWalletBalance /
                            spotResponse?.data?.totalEquity) *
                            100
                    );
            } else {
                dispatch(
                    saveUserSpotBalance({
                        totalEquity: 0,
                        unrealizedPnl: 0,
                        coins: [],
                    })
                );
            }
            try {
                if (getUsdpPrice) {
                    dispatch(saveUsdValue(getUsdpPrice?.data?.usdtPrice));
                } else {
                    dispatch(saveUsdValue(0));
                }
            } catch (error) {}
        }
    };

    const getUnifiedTradingBalance = async () => {
        if (walletAddress) {
            let unifiedBalance = await ApiServices.getUnifiedTrading({
                address: walletAddress,
                coin: searchCoin,
            });

            if (unifiedBalance) {
                dispatch(
                    saveUserUnifiedTradingBalance({
                        totalEquity: unifiedBalance?.data?.totalEquity,
                        marginBalance: unifiedBalance?.data?.totalMarginBalance,
                        PnL: unifiedBalance?.data?.unrealisedPnl,
                        coins: unifiedBalance?.data?.coins,
                        initialMargin: unifiedBalance?.data?.totalInitialMargin,
                        maintenanceMargin:
                            unifiedBalance?.data?.totalMaintenanceMargin,
                        usdValue: unifiedBalance?.data?.usdValue,
                    })
                );
                unifiedBalance?.data?.totalEquity > 0 &&
                    setunifiedGraphValue(
                        (unifiedBalance?.data?.totalWalletBalance /
                            unifiedBalance?.data?.totalEquity) *
                            100
                    );
                    dispatch(updateLoader(false));
            } else {
                dispatch(
                    saveUserUnifiedTradingBalance({
                        totalEquity: 0,
                        marginBalance: 0,
                        PnL: 0,
                        coins: [],
                        initialMargin: 0,
                        maintenanceMargin: 0,
                        usdValue: 0,
                    })
                );
                dispatch(updateLoader(false));
            }
        }
    };

    const hideBalanceHandler = () => {
        dispatch(saveHideBalance(!hideBalance));
    };

    useEffect(() => {
        getAllBalance();
    }, [walletAddress]);

    useEffect(() => {
        getUnifiedTradingBalance();
    }, [walletAddress, searchCoin]);

    useEffect(() => {
        setTotalAssetBalance(
            (parseFloat(userFundingBalance?.totalEquity) || 0) + (parseFloat(userUnifiedBalance?.totalEquity) || 0));
    }, [
        walletAddress,
        userFundingBalance,
        userUnifiedBalance,
        userSpotBalance,
    ]);

    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };
    const handleSearchCoin = (data) => {
        setSearchCoin(data);
    };
    return (
        <>
            {loader && <Loader />}
            <Row>
                <Col xs={12} lg={7} xl={8}>
                    <div className="Spot_blnc_Card">
                        <div className="d-flex align-items-center">
                            <h5>
                                Assets Overview <HideShowBalance />
                            </h5>
                        </div>
                        <h3>
                            {hideBalance ? (
                                <>
                                    {"********"}
                                    <span> {"********"} </span>
                                </>
                            ) : (
                                <>
                                    {`${roundToTwoDecimals(
                                        totalAssetBalance
                                    )} USDT`}
                                    <span>
                                        {" "}
                                        ≈ USD $
                                        {roundToTwoDecimals(
                                            totalAssetBalance * usdValue
                                        )}
                                    </span>
                                </>
                            )}
                        </h3>
                    </div>
                    <div className="Assets_Overview mt-4 pt-xl-2">
                        <div className="Assets_Overview_Header">
                            <h3>My Assets</h3>
                            <div className="Assets_Overview_Header_Btns">
                                <CommonButton
                                    onClick={() => setShowDeposit(true)}
                                    title="Deposit"
                                    className="grey-btn2"
                                />
                                <CommonButton
                                    title="Withdraw"
                                    className="grey-btn2"
                                    onClick={() => setShowWithdraw(true)}
                                />
                                <CommonButton
                                    title="Transfer"
                                    className="grey-btn2"
                                    onClick={() => setShowMoveFunds(true)}
                                />
                                <span className="Assets_Arrow">
                                    <RightarrowIcon />
                                </span>
                                <DepositModal
                                    show={showDeposit}
                                    handleClose={() => setShowDeposit(false)}
                                />
                                <WithdrawModal
                                    show={showWithdraw}
                                    handleClose={() => setShowWithdraw(false)}
                                />
                                <DashboardMoveFunds
                                    show={showMoveFunds}
                                    handleClose={() => setShowMoveFunds(false)}
                                />
                            </div>
                        </div>
                        <div className="Assets_Overview_Tabs">
                            <Tabs
                                defaultActiveKey="funding"
                                id="uncontrolled-tab-example"
                                className="Bottom_Border_Tabs"
                            >
                                <Tab eventKey="funding" title="Funding">
                                    <Row>
                                        <Col xs={12} xl={7} xxl={8}>
                                            <div className="Spot_Account">
                                                <div className="d-flex align-items-center">
                                                    <h6>Assets Overview</h6>
                                                    <span
                                                        className="hide_Blnc"
                                                        onClick={
                                                            hideBalanceHandler
                                                        }
                                                    >
                                                        <HideShowBadge />
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center mt-2">
                                                    <h5>
                                                        {hideBalance
                                                            ? "********"
                                                            : `${roundToTwoDecimals(
                                                                  userFundingBalance?.totalEquity
                                                              )} USDT`}{" "}
                                                        <div className="convertedAmount">
                                                            {hideBalance
                                                                ? "********"
                                                                : ` ≈ USD $${roundToTwoDecimals(
                                                                      userFundingBalance?.totalEquity *
                                                                          usdValue
                                                                  )}`}
                                                        </div>
                                                    </h5>
                                                </div>
                                            </div>
                                            <RecentTransactionTable />
                                        </Col>
                                        <Col xs={12} xl={5} xxl={4}>
                                            <AssetsProgressbar
                                                progress={Number(
                                                    fundingGraphValue
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab
                                    eventKey="unified-trading"
                                    title="Unified Trading"
                                >
                                    <Row>
                                        <Col xs={12} xl={7} xxl={8}>
                                            <div className="Spot_Account">
                                                <div className="d-flex align-items-center">
                                                    <h6>Trading Balance</h6>
                                                    <span
                                                        className="hide_Blnc"
                                                        onClick={
                                                            hideBalanceHandler
                                                        }
                                                    >
                                                        <HideShowBadge />
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center mt-2">
                                                    <h5>
                                                        {hideBalance
                                                            ? "********"
                                                            : `${
                                                                  userUnifiedBalance?.totalEquity !==
                                                                  undefined
                                                                      ? roundToTwoDecimals(
                                                                            userUnifiedBalance?.totalEquity
                                                                        )
                                                                      : 0
                                                              } USDT`}

                                                        <div className="convertedAmount">
                                                            {hideBalance
                                                                ? "********"
                                                                : ` ≈ USD $${
                                                                      userUnifiedBalance?.usdValue
                                                                          ? roundToTwoDecimals(
                                                                                userUnifiedBalance?.totalEquity *
                                                                                    usdValue
                                                                            )
                                                                          : 0
                                                                  }`}
                                                        </div>
                                                    </h5>
                                                </div>
                                            </div>
                                            <RecentTransactionTable />
                                        </Col>
                                        <Col xs={12} xl={5} xxl={4}>
                                            <AssetsProgressbar
                                                progress={Number(
                                                    unifiedGraphValue
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Tab>
                                {/* <Tab
                                    eventKey="inverse-derivatives"
                                    title="Spot"
                                >
                                    <Row>
                                        <Col xs={12} xl={7} xxl={8}>
                                            <div className="Spot_Account">
                                                <div className="d-flex align-items-center">
                                                    <h6>Trading Balance</h6>
                                                    <span
                                                        className="hide_Blnc"
                                                        onClick={
                                                            hideBalanceHandler
                                                        }
                                                    >
                                                        <HideShowBadge />
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center mt-2">
                                                    <h5>
                                                        {hideBalance
                                                            ? "********"
                                                            : `${
                                                                  userSpotBalance?.totalEquity !==
                                                                  undefined
                                                                      ? roundToTwoDecimals(
                                                                            userSpotBalance?.totalEquity
                                                                        )
                                                                      : 0
                                                              } USDT`}
                                                        <div className="convertedAmount">
                                                            {hideBalance
                                                                ? "********"
                                                                : ` ≈ USD $${roundToTwoDecimals(
                                                                      userSpotBalance?.totalEquity *
                                                                          usdValue
                                                                  )}`}
                                                        </div>
                                                    </h5>
                                                </div>
                                            </div>
                                            <RecentTransactionTable />
                                        </Col>
                                        <Col xs={12} xl={5} xxl={4}>
                                            <AssetsProgressbar
                                                progress={Number(
                                                    inverseGraphValue
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Tab> */}
                            </Tabs>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={5} xl={4}>
                    <div className="Refer_Code">
                        <div className="Refer_Code_Box">
                            <Link
                                to={"https://eterna.exchange/referral-system/"}
                                target="_blank"
                            >
                                <h3>
                                    Refer your friends and <em>level up</em>
                                </h3>
                            </Link>
                            <Link
                                to={"https://eterna.exchange/referral-system/"}
                                target="_blank"
                            >
                                {" "}
                                <img src={ReferIcon} alt="icon" />
                            </Link>
                        </div>
                        <div className="Refer_Code_Join">
                            <img src={ehxIcon} alt="icon" />
                            <h4>JOIN THE EHX GUILD</h4>
                            <RightarrowIcon />
                        </div>

                        <div className="Refer_Code_Newscontent">
                            <div className="Refer_News">
                                <img src={newsicon} alt="icon" />
                                <h4> News & Announcements</h4>
                                <RightarrowIcon />
                            </div>
                            {/* <ul className='News_List'>
                                <li>
                                    <p>EHX lists new trading pairs: <strong>EHX, CGPT, MMX and GRM</strong></p>
                                    <p>New trading pairs will be listed 2023-04-05 at 08:01:10 UTC</p>
                                </li>
                                <li>
                                    <p>EHX to delists trading pairs: <strong>EVA, TRX, DRM</strong></p>
                                    <p>Please note that these trading pairs will be delisted 2023-04-05 at 08:01:10 UTC</p>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </Col>
            </Row>
            <TrendingPairs />
            <Row className="mt-4">
                <Col xs={12} xl={12}>
                    <OverviewAssetsTable
                        coins={userUnifiedBalance?.coins}
                        search={handleSearchCoin}
                    />
                </Col>
            </Row>
        </>
    );
};

export default AssetsDashboard;
