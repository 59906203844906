import React, { memo, useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux.hooks";
import {
  saveOrderPref,
  saveOrderPrice,
  saveOrderSize,
} from "../../../redux/_slices/exchange.slice";
import { inputDecimal } from "../../../../../services/HelperServices";
import { amountValidationOnInput } from "../../../utils/comman";
import SolidIcon from "../../../../../assets/icons/Solid_Arrow.svg";
import SolidDownIcon from "../../../../../assets/icons/Solid_Down_Arrow.svg";
import { saveLeverage } from "../../../../../redux/_slices/user.slice";
import LeverageSlider from "../../../../../common/LeverageSlider/LeverageSlider";
import { showDecimal } from "../../../../../constants/constants";

const OrderType = (props) => {
  const {
    eventKey,
    walletAddress,
    totalUsdBalance,
    orderPrice,
    fieldError,
    currentPair,
    selectedPair,
    coinArray,
    handleSetSelectedOption,
    selectedOption,
    setRange,
    range,
    resetTkSl,
  } = props;

  const dispatch = useAppDispatch();
  const usdBalance = useAppSelector((state) => state.exchange.userBalances);
  // const selectedPairKey = useAppSelector((state) => state.user.selectedPairKey);
  const orderPrefDropdown = useAppSelector((state) => state.exchange.orderPref);
  const orderSize = useAppSelector((state) => state.exchange.orderSize);
  const currentLtp = useAppSelector(
    (state) => state.exchange.orderBook.currentLtp
  );
  const currentPrice = useAppSelector((state) => state?.exchange?.currentPrice);
  // console.log("currentPrice=", currentPrice);
  let decimalLimitAmount = currentPair?.min_order_qty?.toString().includes(".")
    ? currentPair?.min_order_qty?.toString().split(".")
    : "";

  const [size, setSize] = useState("");
  const [count, setCount] = useState(0);
  const [qtySlider, setQtySlider] = useState(0);
  const [inputError, setInputError] = useState({
    price: false,
    size: false,
  });
  const [price, setPrice] = useState("");
  // const coinArray = selectedPairKey.split("_");
  const btcoptions = [
    { value: coinArray[1], label: coinArray[1] },
    { value: coinArray[0], label: coinArray[0] },
  ];
  const transferModalRef = useRef();

  const handleOnChange = (event, type) => {
    // resetTkSl();
    if (type == "last") {
      setPrice(event);
      setInputError({ ...inputError, price: false });
      dispatch(saveOrderPrice(event));
    } else {
      const { name, value } = event.target;

      let valData = "";

      if (
        decimalLimitAmount.length > 0 &&
        name == "size" &&
        selectedOption != "USDT"
      ) {
        valData = inputDecimal(value, decimalLimitAmount[1].length);
      } else if (
        value?.toString().includes(".") &&
        name == "size" &&
        selectedOption != "USDT"
      ) {
        return;
      } else if (value != "") {
        if (selectedOption == "USDT") {
          valData = inputDecimal(value, 2);
        } else {
          valData = inputDecimal(value, selectedPair?.price_scale);
        }
      }

      if (name === "price") {
        valData = inputDecimal(value, selectedPair?.price_scale);
        setPrice(valData);
        setInputError({ ...inputError, price: false });
        dispatch(saveOrderPrice(valData));
      } else {
        setInputError({ ...inputError, size: false });
        setSize(valData);
        dispatch(saveOrderSize(valData));
      }
    }
  };

  const handleKeyPress = (e) => {
    //don't allow decimal in qty if min_order_qty is whole number
    if (
      selectedOption != "USDT" &&
      Number.isInteger(currentPair.min_order_qty)
    ) {
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    if (currentPrice) {
      setPrice(currentPrice);
      dispatch(saveOrderPrice(currentPrice));
    }
  }, [currentPrice]);

  useEffect(() => {
    if (orderSize === "") {
      setQtySlider(0);
    }
    setSize(orderSize);
  }, [orderSize]);

  useEffect(() => {
    dispatch(saveOrderPref(selectedOption));
    // setSelectedOption(coinArray[0]);
    dispatch(saveOrderPrice(currentPrice));
    setPrice(currentPrice);
  }, [coinArray[0]]);

  useEffect(() => {
    setInputError(fieldError);
  }, [fieldError]);

  const handleSetRange = (range) => {
    setQtySlider(range);
    const balance = totalUsdBalance?.derivativeTransferBalance;
    // const balance = 10000;
    if (Number(orderPrice) > 0 && Number(balance) > 0) {
      const percentBalance = showDecimal(
        balance * (range / 100),
        decimalLimitAmount[1] ? decimalLimitAmount[1].length : 6
      );

      const percentQty = showDecimal(percentBalance / Number(orderPrice), 6);
      if (orderPrefDropdown === coinArray[1]) {
        dispatch(saveOrderSize(percentBalance));
      } else {
        dispatch(saveOrderSize(percentQty));
      }
    }
  };

  const handleOrderPrefChange = () => {
    resetTkSl();
    dispatch(saveOrderSize(""));
    handleSetSelectedOption(
      selectedOption === coinArray[1] ? coinArray[0] : coinArray[1]
    );
    dispatch(
      saveOrderPref(
        selectedOption === coinArray[1] ? coinArray[0] : coinArray[1]
      )
    );
  };

  return (
      <>
          {eventKey === "L" && (
              <div className="custom_Usdt d-flex align-items-center">
                  <div className="Usdt_Ipnut d-flex align-items-center fullWidth">
                      <input
                          type="text"
                          name="price"
                          value={price}
                          defaultValue={price}
                          onChange={(event) => {
                              if (
                                  /^\d*(\.\d{0,8})?$/.test(event.target.value)
                              ) {
                                  handleOnChange(event);
                              }
                          }}
                          onInput={(event) => amountValidationOnInput(event, 8)}
                          className="custom_input"
                      />
                      {/* <span className='btc_btn'>BTC</span> */}
                      <span className="usdt_btn">{coinArray[1]}</span>
                  </div>
              </div>
          )}
          <div className="custom_Usdt d-flex align-items-center">
              <div className="Usdt_Ipnut d-flex align-items-center fullWidth">
                  <input
                      type="text"
                      className="custom_input"
                      name="size"
                      value={orderSize}
                      onChange={(event) => {
                          if (/^\d*(\.\d{0,8})?$/.test(event.target.value)) {
                              handleOnChange(event);
                          }
                      }}
                      onInput={(event) => amountValidationOnInput(event, 8)}
                      onKeyPress={(event) => handleKeyPress(event)}
                  />
                  <span className="btc_btn" onClick={handleOrderPrefChange}>
                      {selectedOption === coinArray[1]
                          ? coinArray[0]
                          : coinArray[1]}
                  </span>
                  <span
                      className="usdt_btn"
                      // onClick={() => handleSetSelectedOption(selectedOption===coinArray[1] ? coinArray[0] : coinArray[1])}
                  >
                      {selectedOption}
                  </span>
              </div>
              {/* <div className="input_arrow">
          <span onClick={() => setCount(count + 1)}>
            <img src={SolidIcon} alt="icon" />
          </span>
          <span onClick={() => setCount(count + -1)}>
            <img src={SolidDownIcon} alt="icon" />
          </span>
        </div> */}
          </div>
          <LeverageSlider
              min={0}
              max={100}
              format="%"
              handleSetRange={handleSetRange}
              rangeData={qtySlider}
          />
          <div className="custom_Usdt d-flex align-items-center">
              <div className="Usdt_Ipnut d-flex align-items-center leverage-box">
                  <input
                      type="text"
                      name="leverage"
                      placeholder="Enter leverage"
                      value={range === 0 ? "" : range}
                      onChange={(event) => {
                          if (/^\d*(\.\d{0,8})?$/.test(event.target.value)) {
                              setRange(event.target.value);
                              dispatch(saveLeverage(event.target.value));
                          }
                      }}
                      onInput={(event) => amountValidationOnInput(event, 1)}
                      className="custom_input"
                  />
                  <span
                      className="leverage-label"
                      // onClick={() => handleSetSelectedOption(coinArray[0])}
                  >
                      x Leverage
                  </span>
                  {/* <span className='btc_btn'>BTC</span> */}
              </div>
          </div>
      </>
  );
};

export default memo(OrderType);

const options = [
  { value: "mark", label: "Mark" },
  { value: "last", label: "Last" },
];
