import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

import copytext from "../../assets/images/copying_Icon.svg";
import withdraw from "../../assets/images/withdraw.svg";
import deposite from "../../assets/images/deposite.svg";
import disconnect from "../../assets/images/disconnect.svg";
import apimanage from "../../assets/images/management.svg";

import { toastMsg } from "../Toast/Toast";
import { getWalletAddressFormate } from "../../_utils/comman";
import AssetModule from "./AssetModule";
import { DepositIcon, DisconnectIcon, ManagementIcon, WithdrawIcon } from "../../assets/Svg/SvgImages";

const WalletInfoDrop = (props) => {
  const {
    walletAddress,
    id,
    handleDisconnectWallet,
    current_breakpoint,
    handleDW,
  } = props;
  const { t } = useTranslation(); 

  const [isCopy, setIsCopy] = useState(false); 

  return ( 
    <>
      <div className="walladdress__drop ">
        <div className="walladdress__drop__copy">
          <CopyToClipboard
            text={walletAddress}
            onCopy={() => {
              setIsCopy(true);
              toastMsg.success(t("success_msg.copy_address"));
            }}
          >
            <button disabled={isCopy}>
              <h3>{getWalletAddressFormate(walletAddress)}</h3>
              <span>
                <img src={copytext} alt="copy_icon" className="cpy_icon" />
              </span>
            </button>
          </CopyToClipboard>

          <p>ID: {id}</p>
        </div>
        <div className="walladdress__drop__list">
          <ul>
            {/* {current_breakpoint === "xs" || current_breakpoint === "" ? ( */}
            
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      handleDW("deposite");
                    }}
                  >
                    <span className="wll_icon">
                      <DepositIcon />
                    </span>
                    Deposit
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      handleDW("withdrawal");
                    }}
                  >
                    <span className="wll_icon">
                      <WithdrawIcon />
                    </span>
                    Withdraw
                  </button>
                </li>
                {/* <li>
                  <button>
                    <span className="wll_icon">
                      <ManagementIcon />
                    </span>
                    API management
                  </button>
                </li> */}
              
       

            <li className="bdrAdding">
              <button type="=button" onClick={handleDisconnectWallet}>
                <span className="wll_icon">
                  {/* <img src={disconnect} alt="icon" /> */}
                  <DisconnectIcon />
                </span>
                Disconnect
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default WalletInfoDrop;
