import React, { memo, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { NotificationIcon } from "../../assets/Svg/SvgImages";
import infoIcon from "../../assets/icons/chart-line.svg";
import moment from "moment";

const NotificationList = (props) => {
  const {
    notificationList,
    setPage,
    page,
    totalCount,
    newCount,
    totalListCount,
    getNotification,
    handleRead,
    walletAddress,
  } = props;

  const listInnerRef = useRef();

  const handleBellIcon = async () => {
    if (newCount > 0) {
      await handleRead();
      // Assuming handleRead updates the state, no need to fetch notifications here
    } else {
      setPage(1);
      await getNotification(1, 1);
    }
  };

  const handleNotification = async () => {
    let newPage = page + 1;
    setPage(newPage);
    await getNotification(newPage, newPage);
  };
  useEffect(() => {
    if (walletAddress) {
      getNotification(page, page);
    }
  }, [walletAddress, page]);

  return (
    <>
      <Dropdown
        className={
          !walletAddress
            ? "disableBell Notification_Dropdown"
            : "Notification_Dropdown"
        }
        align="end"
      >
        <Dropdown.Toggle variant="" id="dropdown-basic">
          <span onClick={handleBellIcon}>
            <NotificationIcon />
            {newCount > 0 ? (
              <span className="Show_Notification_No">{newCount}</span>
            ) : (
              ""
            )}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <ul ref={listInnerRef} style={{ height: "50vh", overflowY: "auto" }}>
            {walletAddress &&
              notificationList &&
              notificationList.length > 0 &&
              notificationList.map((data, i) => {
                if (data.title) {
                  return (
                    <li key={i} className="notili">
                      <span className="Info_Icon">
                        <img src={infoIcon} alt="Icon" />
                      </span>
                      <h5>
                        {data.title}{" "}
                        {data.created_at
                          ? moment(data?.created_at).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )
                          : ""}
                        <small>
                          {data.description}{" "}
                          {data.created_at
                            ? moment(data?.created_at).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )
                            : ""}
                        </small>
                      </h5>
                    </li>
                  );
                }
              })}
            <>
              {walletAddress &&
              notificationList &&
              notificationList.length === 0 ? (
                <p className="No_found">No record found!</p>
              ) : (
                walletAddress &&
                notificationList &&
                notificationList.length < totalListCount && (
                  <li
                    key={totalCount}
                    className="notili"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <h5 onClick={handleNotification}>View more</h5>
                  </li>
                )
              )}

              {!walletAddress && (
                <p className="No_found">Connect your wallet</p>
              )}
            </>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default memo(NotificationList);
