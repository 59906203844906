import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import "./index.scss";

const ModalComponet = forwardRef((props, ref) => {
  const { title, className, children, backdrop, closeModal, show } = props;
  const [showState, setShowState] = useState(false);
  const modalRef = useRef();

  useImperativeHandle(ref, () => ({
    handleModal(value) {
      setShowState(value);
    },
  }));
  return (
    <Modal
      data-testid={props.dataTestid || ""}
      backdrop={backdrop}
      scrollable={true}
      className={className}
      show={props?.direction == "staking" ? show : showState}
      onHide={() => {
        props?.direction == "staking"
          ? props?.hide(false)
          : setShowState(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
});

export default ModalComponet;
