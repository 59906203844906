import React, { useState } from "react";
import { Alert, Col, Nav, Row, Tab } from "react-bootstrap";
import CommonButton from "../../../../common/CommonButton/CommonButton";
import Admin from "../../Admin";
import TopbarHeading from "../../common/TopbarHeading/TopbarHeading";
import "./TransactionHistory.scss";
import Noteicon from "../../../../assets/images/admin/file-export.svg";
import WalletHistory from "./WalletHistory/WalletHistory";
import TransferListHistory from "./TransferListHistory/TransferListHistory";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
const TransactionHistory = () => {
    const [activeTab, setActiveTab] = useState("Wallet");
    const transferHistory = useSelector(
        (state) => state?.exchange?.userTransferListHistory
    );
    let recentTransactions = useSelector(
        (state) => state?.exchange?.userTransactionHistory
    );

    const fetchDataAndExportCsv = () => {
        try {
            let transferList = transferHistory.map((item) => ({
                Coin: item?.coin,
                Amount: item?.amount,
                FromAccount: item?.fromAccountType,
                ToAccount: item?.toAccountType,
                Time: moment(Number(item?.timestamp)).format(
                    "DD-MM-YYYY  HH:mm:ss"
                ),
                Status: item?.status,
            }));

            let transactionHistory = recentTransactions.map((item) => ({
                Coin: item?.token_name,
                Type: item?.transaction_type,
                Amount: item?.amount / 100000000,
                Status: item.status === 2 ? "Success" : "Failed",
                Time: moment(item?.created_at).format("DD-MM-YYYY  HH:mm:ss"),
            }));

            if (activeTab === "Wallet") {
                exportToCsv(transactionHistory, "wallet_history.csv");
            } else {
                exportToCsv(transferList, "internal_transfer_history.csv");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const convertToCsv = (data) => {
        const csvRows = [];
        if (data.length === 0) {
            return "";
        }
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(","));

        data.forEach((row) => {
            const values = headers.map((header) => {
                const escaped = ("" + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(","));
        });

        return csvRows.join("\n");
    };

    const exportToCsv = (data, filename) => {
        const csvData = convertToCsv(data);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const fetchDataAndExportPdf = () => {
        try {
            let dataToExport = [];
            let fileName = "";

            if (activeTab === "Wallet") {
                dataToExport = recentTransactions.map((item) => ({
                    Coin: item?.token_name,
                    Type: item?.transaction_type,
                    Amount: item?.amount / 100000000,
                    Status: item.status === 2 ? "Success" : "Failed",
                    Time: moment(item?.created_at).format(
                        "DD-MM-YYYY  HH:mm:ss"
                    ),
                }));
                fileName = "wallet_history.pdf";
            } else {
                dataToExport = transferHistory.map((item) => ({
                    Coin: item?.coin,
                    Amount: item?.amount,
                    FromAccount: item?.fromAccountType,
                    ToAccount: item?.toAccountType,
                    Time: moment(Number(item?.timestamp)).format(
                        "DD-MM-YYYY  HH:mm:ss"
                    ),
                    Status: item?.status,
                }));
                fileName = "internal_transfer_history.pdf";
            }

            exportToPdf(dataToExport, fileName);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const exportToPdf = (data, filename) => {
        const doc = new jsPDF();

        if (data.length === 0) {
            // If there are no records, add a "No record found" message
            doc.text("No record found", 20, 20);
        } else {
            const tableHeaders = Object.keys(data[0]);
            const tableData = data.map((item) => Object.values(item));

            doc.autoTable({
                head: [tableHeaders],
                body: tableData,
                startY: 20,
                styles: { halign: "center" },
            });
        }

        doc.save(filename);
    };

    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    };

    return (
        <Admin>
            <div className="Common_TopBar">
                <Row className="align-items-center">
                    <Col xs={12} sm={6}>
                        <TopbarHeading
                            title="Transaction History"
                            hideEyeIcon={true}
                        />
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        className="d-sm-flex justify-content-sm-end mt-3 mt-sm-0"
                    >
                        <div className="export_transaction">
                            <Dropdown align="end">
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    <CommonButton
                                        title="Export transaction history"
                                        className="grey-btn2 exportBtn"
                                        icon={Noteicon}
                                        btnicon="d-block"
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="export_list">
                                        <ul>
                                            <li onClick={fetchDataAndExportCsv}>
                                                Export to CSV
                                            </li>
                                            <li onClick={fetchDataAndExportPdf}>
                                                Export to PDF
                                            </li>
                                        </ul>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="Common_admin_Contentarea">
                <div className="Transaction_History">
                    <h4 className="font22 mb-4 mb-md-5">Transaction History</h4>
                    <Tab.Container
                        id="left-tabs-example"
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                    >
                        <Row className="mt-4 mt-xl-5">
                            <Col xs={12}>
                                <Row>
                                    <Col
                                        xs={12}
                                        xl={5}
                                        className="order-xl-last"
                                    ></Col>
                                    <Col xs={12} xl={7}>
                                        <Nav
                                            variant="pills"
                                            className="Bottom_Border_Tabs"
                                        >
                                            <Nav.Item>
                                                <Nav.Link eventKey="TransferList">
                                                    Internal Transfer History
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Wallet">
                                                    Wallet History
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="Wallet">
                                        <WalletHistory />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="TransferList">
                                        <TransferListHistory />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </Admin>
    );
};

export default TransactionHistory;
