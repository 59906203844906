import React from "react";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import hideEyeIcon from "../../../../assets/images/admin/eye-slash.svg";
import eysIcon from "../../../../assets/images/admin/eye-slash.svg";

const HideShowBadge = () => {
    const hideBalance = useAppSelector((state) => state.user.hideBalance);

    if (hideBalance) {
        return (
            <>
                <img src={hideEyeIcon} alt="eye" /> Show Balance
            </>
        );
    } else {
        return (
            <>
                <img src={eysIcon} alt="eye" /> Hide Balance
            </>
        );
    }
};

export default HideShowBadge;
