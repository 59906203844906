import React, { useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import "./Perpetual.scss";
import ClosedProfitandLoss from "./ClosedProfitandLoss/ClosedProfitandLoss";
import CurrentOrder from "./CurrentOrder/CurrentOrder";
import TradeHistory from "./TradeHistory/TradeHistory";
import OrderHistory from "./OrderHistory/OrderHistory";
const Perpetual = ({isActive}) => {
    const [activeTab, setActiveTab] = useState('closedprofitandloss');

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    return (
        <div className="Transaction_History">
            <Tab.Container
                id="left-tabs-example"
                activeKey={activeTab}
                onSelect={handleTabSelect}
            >
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} xl={12}>
                                <Nav
                                    variant="pills"
                                    className="Bottom_Border_Tabs"
                                >
                                    <Nav.Item>
                                        <Nav.Link eventKey="closedprofitandloss">
                                            Closed P&L
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="precurrentorder">
                                            Current Order
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="preorderhistory">
                                            Order History
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pretradeHistory">
                                            Trade History
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="closedprofitandloss" currentTab ={activeTab}>
                                <ClosedProfitandLoss currentTab={activeTab} isActive = {isActive}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="precurrentorder" currentTab ={activeTab}>
                                <CurrentOrder currentTab={activeTab} isActive = {isActive}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="preorderhistory" currentTab ={activeTab}>
                                <OrderHistory currentTab={activeTab} isActive = {isActive}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="pretradeHistory" currentTab ={activeTab}>
                                <TradeHistory  currentTab={activeTab} isActive = {isActive}/>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
};

export default Perpetual;
