// import ArrowDown from "../assets/images/down_arw.svg";
// import ArrowUp from "../assets/images/up_arw.svg";

export const LTP_SIGNELS = {
  1: {
    color: "bidGreen",
    // icon: ArrowUp,
    icon: "",
  },
  2: {
    color: "askRed",
    // icon: ArrowDown,
    icon: "",
  },
  3: {
    color: "",
    icon: "",
  },
};
