import React, { memo, useEffect, useMemo, useState } from "react";
import "./AddProfitModal.scss";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import { MinusIcon, PlusIcon } from "../../../../../assets/Svg/SvgImages";
import RangeSlider from "../../../../../common/RangeSlider/RangeSlider";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import Select from "react-select";
import icon1 from "../../../../../assets/images/tether-usdt.svg";
import icon2 from "../../../../../assets/images/bnb.svg";
import icon3 from "../../../../../assets/images/btc.svg";
import CrossIcon from "../../../../../assets/icons/red_cross.svg";
import { useSelector } from "react-redux";
import TPSLModal from "./TPSLModal";
import { getFeeByPercent } from "../../../../../services/HelperServices";
import { showDecimal } from "../../../../../_utils/comman";
import { useTolalUsdBalance } from "../../../hooks/hooks.service";
import { ApiServices } from "../../../services/api.services";
import { toastMsg } from "../../../../../common/Toast/Toast";

const AddProfitModal = ({
  handleClose,
  show,
  componentType,
  orderPrice,
  quantity,
  ltp,
  orderPair,
  currentPosition,
}) => {
  const [profitAmount, setProfitAmount] = useState("");
  const [lossAmount, setLossAmount] = useState("");
  const [tPAmount, setTPAmount] = useState(0);
  const [sLAmount, setSLAmount] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [finalAmount, setFinalAmount] = useState(0);
  const [ROIValue, setROIValue] = useState("");
  const [profitUsdt, setProfitUsdt] = useState("");
  const [lossUsdt, setLossUsdt] = useState("");

  const [newROILoss, setNewROILoss] = useState("");
  const { totalUsdBalance } = useTolalUsdBalance("single");
  const makrTkrFee = useSelector((state) => state.exchange.makrTkrFee);

  const otherFee = useSelector((state) => state.exchange.otherFee);
  const orderSize = useSelector((state) => state.exchange.orderSize);
  const pairList = useSelector((state) => state.exchange.pairList);
  const leverage = useSelector((state) => state.user.leverage);
  const userAddress = useSelector((state) => state?.user?.walletAddress);
  const triggerByMemo = [
    {
      value: "LastPrice",
      label: "Last Traded Price",
    },
    {
      value: "MarkPrice",
      label: "Mark Price",
    },
    {
      value: "IndexPrice",
      label: "Index Price",
    },
  ];
  const [profitTriggerValue, setProfitTriggerValue] = useState(
    triggerByMemo[0]
  );
  const [lossTriggerValue, setLossTriggerValue] = useState(triggerByMemo[0]);

  const triggerBy = useMemo(() => triggerByMemo, []);

  const pairDetails = pairList?.find(
    (item) => item.pair_name.split("_").join("") == orderPair
  );

  const entry_price = currentPosition?.entry_price
    ? Number(currentPosition?.entry_price)
    : currentPosition?.price;

  let side = Number(currentPosition?.side);

  useEffect(() => {
    let tkPrice = 0;
    let slPrice = 0;
    if (pairDetails && Object.keys(pairDetails).length > 0) {
      tkPrice = Number(entry_price) + Number(pairDetails?.min_price);
      slPrice = Number(entry_price) - Number(pairDetails?.min_price);
      setProfitAmount(
        currentPosition?.take_profit > 0 ? currentPosition?.take_profit : ""
      );
      setLossAmount(
        currentPosition?.stop_loss > 0 ? currentPosition?.stop_loss : ""
      );
    }
  }, [currentPosition?.stop_loss, currentPosition?.take_profit]);

  useEffect(() => {
    if (profitAmount || lossAmount) {
      getFeeAmount();
    }
  }, [profitAmount, lossAmount]);

  const getFeeAmount = () => {
    let buyMaker = 0;
    let buyTaker = 0;

    let lossFee =
      currentPosition.side === 0 ? makrTkrFee?.cts : makrTkrFee?.glr;

    if (lossAmount) {
      buyTaker = getFeeByPercent(
        currentPosition?.order_size,
        lossAmount,
        lossFee,
        currentPosition?.leverage
      );
    }

    let finalSum =
      Number(showDecimal(buyMaker, 8)) + Number(showDecimal(buyMaker, 8));
    setFinalAmount(showDecimal(finalSum, 8));
    if (Number(showDecimal(finalSum, 8)) > totalUsdBalance) {
      toastMsg.error(
        "You have insufficient balance please add funds to your account!",
        { toastId: "error1" }
      );
    }
  };

  const setTpslHandler = async () => {
    const profitCompPrice =
      Number(orderPrice) > Number(ltp) ? Number(orderPrice) : Number(ltp);
    const lossCompPrice =
      Number(orderPrice) < Number(ltp) ? Number(orderPrice) : Number(ltp);

    const compProfitPriceName =
      Number(orderPrice) > Number(ltp) ? "OrderPrice" : "LTP";
    const compLossPriceName =
      Number(orderPrice) < Number(ltp) ? "OrderPrice" : "LTP";
    if (
      (profitAmount === "" || Number(profitAmount) > Number(profitCompPrice)) &&
      (lossAmount === "" || Number(lossAmount) < Number(lossCompPrice))
    ) {
      try {
        let dataObj = {
          isEdit:
            makrTkrFee?.stop_loss > 0 ||
            makrTkrFee?.stopLoss > 0 ||
            makrTkrFee?.take_profit > 0 ||
            makrTkrFee?.takeProfit > 0
              ? true
              : false,
          address: userAddress,
          takeProfit: profitAmount,
          stopLoss: lossAmount,
          symbol: currentPosition?.pair,
          slTriggerBy: lossTriggerValue.value,
          tpTriggerBy: profitTriggerValue.value,
        };

        const response = await ApiServices.takeProfiStoptLoss(dataObj);
        if (response) {
          toastMsg.success(response?.message);
          setLossAmount("");
          setProfitAmount("");
          setLossUsdt("");
          setProfitUsdt("");
          setNewROILoss("");
          setROIValue("");
          handleClose();
        }
      } catch (error) {
        //   setTimeout(() => {}, 2500);
        toastMsg.error(error.data.message);
      }
    } else {
      if (
        profitAmount !== "" &&
        Number(profitAmount) < Number(profitCompPrice)
      ) {
        toastMsg.error(
          `Take Profit USDT must be greater than ${compProfitPriceName}`
        );
      }
      if (lossAmount !== "" && Number(lossAmount) > Number(lossCompPrice)) {
        toastMsg.error(
          `Stop Loss USDT must be less than  ${compLossPriceName}`
        );
      }
      // toastMsg.error(
      //   "Entered take profit USDT or stop loss USDT value is not valid"
      // );
    }
  };

  return (
    <>
      <Modal
        className="close_position_modal add_profit_Modal"
        centered
        show={show}
        backdrop={"static"}
        onHide={() => {
          handleClose();
          setLossAmount("");
          setProfitAmount("");
          setProfitUsdt("");
          setLossUsdt("");
          setROIValue("");
          setNewROILoss("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add TP/SL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="text-center justify-content-between profit_Price gx-2">
            <Col xs={3}>
              <h5>
                Order Price <strong>{orderPrice}</strong>
              </h5>
            </Col>
            <Col xs={3}>
              <h5>
                Qty <strong className="text_blue">{quantity}</strong>
              </h5>
            </Col>
            <Col xs={3}>
              <h5>
                LTP<strong>{ltp}</strong>
              </h5>
            </Col>
            <Col xs={3}>
              <h5>
                Liq Price
                <strong>{currentPosition?.liquidation_price}</strong>
              </h5>
            </Col>
          </Row>
          {componentType !== "Positions" && (
            <div className="groupbtn d-flex mt-4 pt-2">
              <CommonButton title="Long" className="me-2 w-50 buy-btn" />
              <CommonButton title="Short" className="red-btn ms-2 w-50" />
            </div>
          )}

          <TPSLModal
            orderPrice={orderPrice}
            profitAmount={profitAmount}
            pairDetails={pairDetails}
            setProfitAmount={setProfitAmount}
            label="Take Profit Price"
            type="profit"
            currentPosition={currentPosition}
            triggerBy={triggerBy}
            setProfitTriggerValue={setProfitTriggerValue}
            setLossTriggerValue={setLossTriggerValue}
            profitTriggerValue={profitTriggerValue}
            lossTriggerValue={lossTriggerValue}
            modalType={"position"}
            type_field="TP_ET"
            activeTab={side}
            lastTradedPrice={entry_price}
            profitUsdt={profitUsdt}
            setProfitUsdt={setProfitUsdt}
            ROIValue={ROIValue}
            setROIValue={setROIValue}
            lossUsdt={lossUsdt}
            setLossUsdt={setLossUsdt}
            show={show}
            handleClose={handleClose}
          />
          <TPSLModal
            orderPrice={orderPrice}
            lossAmount={lossAmount}
            pairDetails={pairDetails}
            setLossAmount={setLossAmount}
            label="Stop Loss Price"
            type="loss"
            currentPosition={currentPosition}
            triggerBy={triggerBy}
            setProfitTriggerValue={setProfitTriggerValue}
            setLossTriggerValue={setLossTriggerValue}
            profitTriggerValue={profitTriggerValue}
            lossTriggerValue={lossTriggerValue}
            modalType={"position"}
            type_field="SL_ET"
            activeTab={side}
            lastTradedPrice={ltp}
            profitUsdt={profitUsdt}
            setProfitUsdt={setProfitUsdt}
            ROIValue={ROIValue}
            setROIValue={setROIValue}
            lossUsdt={lossUsdt}
            setLossUsdt={setLossUsdt}
            show={show}
            handleClose={handleClose}
          />

          {errorMsg && (
            <div className="error_msg d-flex align-items-center mt-4">
              <img src={CrossIcon} alt="cross" />
              <p className="mb-0 text_red ms-3">{errorMsg}</p>
            </div>
          )}

          <div className="groupbtn d-flex mt-4 pt-2">
            <CommonButton
              title="Confirm"
              className="me-2 w-50"
              onClick={setTpslHandler}
              disabled={Number(finalAmount) > totalUsdBalance}
            />
            <CommonButton
              title="Cancel"
              className="grey-btn2 ms-2 w-50"
              onClick={() => {
                handleClose();
                setLossAmount("");
                setProfitAmount("");
                setProfitUsdt("");
                setLossUsdt("");
                setROIValue("");
                setNewROILoss("");
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(AddProfitModal);
