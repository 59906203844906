import React, { useState } from "react";
import "react-rangeslider/lib/index.css";
import "./LeverageSlider.scss";
import Slider from "react-rangeslider";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hooks";
import { useEffect } from "react";
import { saveLeverage } from "../../redux/_slices/user.slice";

const LeverageSlider = ({ min, max, format, handleSetRange, rangeData }) => {
    const [range, setRange] = useState(min);

    const horizontalLabels = {
        // 0: `${min}${format}`,
        0: "",
        50: "",
        100: `${max}${format}`,
    };

    const formatkg = (value) => value + format;

    const handleSetRangeChange = (values) => {
        // setRange(value);
        handleSetRange(values);
    };

    useEffect(() => {
        if (rangeData) {
            const value = Math.abs(rangeData);
            if (value > max) {
                setRange([max]);
            } else if (value < min) {
                setRange([min]);
            } else {
                setRange([value]);
            }
        } else {
            setRange([min]);
        }
    }, [rangeData]);

    return (
        <>
            <div className="Range_Slider">
                <div className="slider custom-labels range-slider-inline">
                    <Slider
                        min={min}
                        max={max}
                        value={range || 0}
                        labels={horizontalLabels}
                        format={formatkg}
                        handleLabel={range}
                        onChange={handleSetRangeChange}
                    />
                    <div className="RangeSlider_Value">{formatkg(range)}</div>
                </div>
            </div>
        </>
    );
};

export default LeverageSlider;
