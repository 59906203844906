import React, { useTransition } from "react";
import "./MarginRatio.scss";
import kpiicon from "../../../../assets/images/kpi.svg";

const MarginRatio = () => {
 
  return (
    <div className="Margin_Ratio">
        <h4 className="h4_heading">Margin Ratio</h4>
        <ul className="Margin_Ratio_List">
          <li><span>Margin Ratio</span> <h5 className="h4_heading text_green"><img src={kpiicon} alt="icon" /> 0.00%</h5></li>
          <li><span>Maintenance Margin</span> <h5 className="h4_heading green_text">0.00 USDT</h5></li>
          <li><span>Margin Balance</span> <h5 className="h4_heading green_text">0.00 USDT</h5></li>
        </ul>
    </div>
  );
};

export default MarginRatio;
