import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  numberSystemFormate,
  ltpUpDown,
} from "../../../../_utils/comman";
import { LTP_SIGNELS } from "../../../../constants/exchange.constant";
// import { GLOWL_DECIMAL } from "../../../../../../constants/constants";
import { formattedPair } from "../../../../constants/constants";
import { isMobile } from "react-device-detect";
const OrderbookTicker = ({ selectedPairVal }) => {
  const orderBook = useSelector((state) => state.exchange.orderBook);
  // const markPrice = useSelector((state) => state.exchange.markPrice);
  // const currentPrice = useSelector((state) => state?.exchange?.currentPrice);

  const statistics = useSelector((state) => state.exchange.statistics);
  const markPrice =
    statistics && statistics?.mark_price
      ? statistics?.mark_price
      : statistics?.lastPrice
        ? statistics?.lastPrice
        : orderBook?.currentLtp;

  const currentPrice =
    statistics && statistics?.lastPrice
      ? statistics?.lastPrice
      : statistics?.mark_price;
  const selectedPair = useSelector((state) => state?.user?.selectedPair);
  const selectedPairKey = useSelector((state) => state.user.selectedPairKey);

  let currentLtpPrice =
    orderBook?.currentLtp != 0 ? orderBook?.currentLtp : currentPrice;

  useEffect(() => {
    if (!isMobile) {
      document.title =
        numberSystemFormate(currentLtpPrice, selectedPair.price_scale) +
        " | " +
        formattedPair(selectedPairKey);
    }
  }, [currentLtpPrice]);

  // console.log("currentLtp=", orderBook);
  return (
    <span>  </span>
  )
};

export default memo(OrderbookTicker);
