import { ORDER_BOOK_SIZE } from "../constants/constants";
export const convertDecimalToHex = (num) => {
  return `0x${Number(num).toString(16)}`;
};

export const getWalletAddressFormate = (data) => {
  // console.log('dataaa', data)
  return `${data?.substring(0, 4)}...${data?.substring(
    data.length - 4,
    data.length
  )}`;
};

export const amountValidationOnInput = (event, length) => {
  let t = event.currentTarget.value;

  event.currentTarget.value =
    t.indexOf(".") >= 0
      ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), length + 1)
      : t;
};

export const amountFormate = (amount, decimal, toFixDecimal) => {
  if (amount > 0) {
    return (amount / 10 ** decimal).toFixed(toFixDecimal);
  }
  return 0;
};

export const numberSystemFormate = (data, decimal) => {
    if (data) {
        return Number(data).toLocaleString("en-US", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
        });
    } else {
        return (0).toLocaleString("en-US", {
            minimumFractionDigits: decimal || 2,
            maximumFractionDigits: decimal || 2,
        });
    }
};


export const orderAdd = (order, orderData, side) => {
  let newOrders = orderData;
  const indexfind = find_index(orderData, orderData.length, "price", order);
  if (indexfind >= orderData.length) {
    newOrders.push(order);
  } else {
    if (newOrders[indexfind]["price"] === order["price"]) {
      newOrders[indexfind] = {
        price: order["price"],
        quantity: orderData[indexfind].quantity + order.quantity,
        total:
          order["price"] * (orderData[indexfind].quantity + order.quantity),
      };
    } else {
      const a1 = newOrders.slice(0, indexfind);
      const a2 = newOrders.slice(indexfind, newOrders.length);
      a1.push(order);
      newOrders = a1.concat(a2);
    }
  }

  if (newOrders.length > ORDER_BOOK_SIZE) {
    if (side === "B") {
      newOrders.pop();
    } else {
      newOrders.shift();
    }
  }

  return newOrders;
};

export const orderSub = (order, orderData) => {
  const indexfind = orderData.findIndex(
    (doc) => doc["price"] === order["price"]
  );

  if (indexfind !== -1) {
    const updatedQuntity = orderData[indexfind]["quantity"] - order["quantity"];
    if (updatedQuntity <= 0) {
      orderData.splice(indexfind, 1);
    } else {
      orderData[indexfind] = {
        price: order["price"],
        quantity: updatedQuntity,
        total: order["price"] * updatedQuntity,
      };
    }
  }
  return orderData;
};

export const find_index = (arr, n, key, K) => {
  // Traverse the array
  for (let i = 0; i < n; i++) {
    if (arr[i][key] == K[key]) return i;
    else if (arr[i][key] > K[key]) continue;
    else if (arr[i][key] < K[key]) return i;
  }
  // If K is found
  // If current array element
  // exceeds K

  // If all elements are smaller
  // than K
  return n;
};

export const ltpUpDown = (letast, previous) => {
  if (letast > previous) {
    return 1;
  }
  if (letast < previous) {
    return 2;
  }
  return 3;
};

// ======================== Not Used ============================

export const getDecimalString = (amount, decimal) => {
  const value = amountValueFormate(amount, decimal);
  let a = String(value);
  const b = a.split(".").length == 2 ? a.split(".")[1].length : 0;
  return b;
};

export const amountValidationOnKeyPress = (event, length) => {
  let newlength = 0;
  if (length === 0) {
    newlength = 0;
  } else {
    newlength = length - 1;
  }
  const valuePattern = new RegExp(`^(\\d{0,9}?.\\d{0,${newlength}})?$`);
  const { value } = event.currentTarget;
  if (value?.length > 0) {
    if (!valuePattern.test(value)) {
      event.preventDefault();
    }
  }

  const keyCodeArray = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
  if (length <= 0) {
  } else {
    keyCodeArray.push(46);
  }

  const keycheck = keyCodeArray.indexOf(event.which) > -1;
  if (!keycheck) {
    event.preventDefault();
  }
};

export const fetchWalletBalance = (assetId, decimal, data) => {
  if (assetId === 0) {
    if (data.balance > 0) {
      return Number((data.balance / 10 ** decimal).toFixed(decimal));
    }
    return 0;
  } else {
    if (data && data?.assets) {
      const asset = data?.assets.find((doc) => doc["asset-id"] === assetId);
      if (asset) {
        return Number((asset.amount / 10 ** decimal).toFixed(decimal));
      }
    }
    return 0;
  }
};

export const listDateFormat = (date) => {
  let newDAte = new Date(date);
  let parsedDate = newDAte.toLocaleDateString("default", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  let time = newDAte.toLocaleTimeString("en-US", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });

  return parsedDate + " " + time;
};

export const amountValueFormate = (amount, decimal) => {
  if (amount > 0) {
    return Number((amount / 10 ** decimal).toFixed(decimal));
  }
  return 0;
};

export const convertWithDecimals = (value, decimal) => {
  if (value > 0) {
    return Number((value * 10 ** decimal).toFixed(0));
  }
  return 0;
};
/**
 * Amount Volume Formation
 * 1K = 1,000
 * 1M = 1,000,000
 * 1B = 1,000,000,000
 * 1T = 1,000,000,000,000
 * @param {*} value
 * @param {*} decimal
 * @param {*} toFixDecimal
 * @returns
 */
export const amountVolumeFormation = (value, decimal, toFixDecimal) => {
  const bigToFix = 1;
  if (value <= 0 || value === undefined) {
    return 0;
  }
  const newValue = value / 10 ** decimal;
  if (newValue >= 10 ** 3 && newValue < 10 ** 6) {
    return `${(newValue / 10 ** 3).toFixed(bigToFix)}K`;
  } else if (newValue >= 10 ** 6 && newValue < 10 ** 9) {
    return `${(newValue / 10 ** 6).toFixed(bigToFix)}M`;
  } else if (newValue >= 10 ** 9 && newValue < 10 ** 12) {
    return `${(newValue / 10 ** 9).toFixed(bigToFix)}B`;
  } else if (newValue >= 10 ** 12) {
    return `${(newValue / 10 ** 12).toFixed(bigToFix)}T`;
  } else {
    return newValue.toFixed(toFixDecimal);
  }
};

export const walletBalanceFormate = (value) => {
  const bigToFix = 2;
  if (value <= 0 || value === undefined) {
    return 0;
  }

  const newValue = Number(value);
  if (newValue >= 10 ** 3 && newValue < 10 ** 6) {
    return `${(newValue / 10 ** 3).toFixed(bigToFix)}K`;
  } else if (newValue >= 10 ** 6 && newValue < 10 ** 9) {
    return `${(newValue / 10 ** 6).toFixed(bigToFix)}M`;
  } else if (newValue >= 10 ** 9 && newValue < 10 ** 12) {
    return `${(newValue / 10 ** 9).toFixed(bigToFix)}B`;
  } else if (newValue >= 10 ** 12) {
    return `${(newValue / 10 ** 12).toFixed(bigToFix)}T`;
  } else {
    return newValue;
  }
};

export const tradeValueFormate = (value, decimal) => {
  return value;
};

export const getAssetBalanceFromWalletData = (
  wallet_data,
  asset_id,
  asset_decimal
) => {
  if (asset_id === 0) {
    if (wallet_data.amount > 0) {
      return Number(
        (wallet_data.amount / 10 ** asset_decimal).toFixed(asset_decimal)
      );
    }
    return 0;
  } else {
    if (wallet_data && wallet_data?.assets) {
      const asset = wallet_data?.assets.find(
        (doc) => doc["asset-id"] === asset_id
      );
      if (asset) {
        return Number(
          (asset.amount / 10 ** asset_decimal).toFixed(asset_decimal)
        );
      }
    }
    return 0;
  }
};

export const changes24H = (currentPrice, price_24) => {
  let priceDiff = 0;
  if (currentPrice > price_24) {
    priceDiff = currentPrice - price_24;
  } else {
    priceDiff = price_24 - currentPrice;
  }

  if (
    price_24 === 0 ||
    price_24 === null ||
    currentPrice === 0 ||
    currentPrice === null
  ) {
    return 0;
  }

  if (priceDiff / price_24) {
    return ((priceDiff / price_24) * 100).toFixed(2);
  }
  return 0;
};

/**
 *  Value covert to US Formate
 * @param {Number || String} value
 * @param {Number} decimals
 * @param {Number} maxDecimal
 */
export const formateValue = (value, decimals, maxDecimal) => {
  if (value > 0) {
    const x = Number(value) / 10 ** decimals;
    const a = String(x);
    const b = a.split(".").length == 2 ? a.split(".")[1].length : 0;
    if (b > maxDecimal) {
      return numberSystemFormate(x, maxDecimal);
    }
    return numberSystemFormate(x, b);
  }

  return numberSystemFormate(value, maxDecimal);
};

/**
 * Convert aount to usd price
 * @param {*} amount
 * @param {*} usdPrice
 * @param {*} decimals
 * @param {*} maxDecimal
 * @returns
 */
export const convertToUSD = (amount, usdPrice, decimals, maxDecimal) => {
  if (amount > 0) {
    const x = Number(amount) / 10 ** decimals;
    const y = x * usdPrice;
    const a = String(y);
    const b = a.split(".").length == 2 ? a.split(".")[1].length : 0;
    if (b > maxDecimal) {
      return numberSystemFormate(y, maxDecimal);
    }
    return numberSystemFormate(y, b);
  }
  return numberSystemFormate(amount, maxDecimal);
};

export const calculatePnl = (entryPrice, ltp) => {
  if (entryPrice < ltp) {
    return {
      pnl: ltp - entryPrice,
      direction: "+",
    };
  } else if (entryPrice > ltp) {
    return {
      pnl: entryPrice - ltp,
      direction: "-",
    };
  } else if (entryPrice == ltp) {
    return {
      pnl: 0,
      direction: "",
    };
  }
};

export const scientificToDecimal = function (num) {
  var nsign = Math.sign(num);
  //remove the sign
  num = Math.abs(num);
  //if the number is in scientific notation remove it
  if (/\d+\.?\d*e[\+\-]*\d+/i.test(num)) {
    var zero = "0",
      parts = String(num).toLowerCase().split("e"), //split into coeff and exponent
      e = parts.pop(), //store the exponential part
      l = Math.abs(e), //get the number of zeros
      sign = e / l,
      coeff_array = parts[0].split(".");
    if (sign === -1) {
      l = l - coeff_array[0].length;
      if (l < 0) {
        num =
          coeff_array[0].slice(0, l) +
          "." +
          coeff_array[0].slice(l) +
          (coeff_array.length === 2 ? coeff_array[1] : "");
      } else {
        num = zero + "." + new Array(l + 1).join(zero) + coeff_array.join("");
      }
    } else {
      var dec = coeff_array[1];
      if (dec) l = l - dec.length;
      if (l < 0) {
        num = coeff_array[0] + dec.slice(0, l) + "." + dec.slice(l);
      } else {
        num = coeff_array.join("") + new Array(l + 1).join(zero);
      }
    }
  }

  return nsign < 0 ? "-" + num : num;
};

export const showDecimal = (amountVal, decimals) => {
  let decimalVal = scientificToDecimal(amountVal);
  // console.log("resultresult=", amountVal, decimalVal);
  let updateVal = decimalVal?.toString();
  if (updateVal?.includes(".")) {
    let getDicimal = updateVal.split(".");
    let result = getDicimal[1].substring(0, Number(decimals));
    decimalVal = getDicimal[0] + "." + result;
  }
  return decimalVal;
};
