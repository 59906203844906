/** seting action types */
import { saveFavPairList, saveSpotFavPairList } from "../_slices/user.slice";

/*
 * Action creators for login
 */

export const saveFavPairsList = (data) => (dispatch, getState) => {
  try {
    const { pair_name, type } = data;
    if (type === "perp") {
      const favList = getState().user.favPairList;
      let newfavList = [...favList];
      const oldFavindex = favList?.findIndex((doc) => doc === pair_name);
      if (oldFavindex === -1) {
        newfavList.push(pair_name);
      } else {
        newfavList.splice(oldFavindex, 1);
      }
      dispatch(saveFavPairList(newfavList));
    } else {
      const favList = getState().user.spotFavPairList;
      let newfavList = [...favList];
      const { pair_name, type } = data;
      const oldFavindex = favList?.findIndex((doc) => doc === pair_name);
      if (oldFavindex === -1) {
        newfavList.push(pair_name);
      } else {
        newfavList.splice(oldFavindex, 1);
      }
      dispatch(saveSpotFavPairList(newfavList));
    }
  } catch (error) {
    console.log("Error saveFavPairsList :", error);
  }
};
