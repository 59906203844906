import React from 'react'
import "./StatementChart.scss"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
    labels: ['BTC 24.5%', 'ETH 18.65%', 'LTC 15.90%', 'GRT 14.34%', 'USDT 12.56%', 'ROSE 10.78%'],
    datasets: [
        {
            label: '# of Votes',
            data: [24.5, 18.65, 15.9, 14.34, 12.56, 10.78],
            padding: "0px",
            backgroundColor: [
                '#5BC7E6',
                '#7963CD',
                '#9F00AD',
                '#51546F',
                '#F3BA2F',
                '#63CD81',
            ],
            // borderColor: [
            //     'rgba(255, 99, 132, 1)',
            //     'rgba(54, 162, 235, 1)',
            //     'rgba(255, 206, 86, 1)',
            //     'rgba(75, 192, 192, 1)',
            //     'rgba(153, 102, 255, 1)',
            //     'rgba(255, 159, 64, 1)',
            // ],
            borderWidth: 0,
        },
    ],

};

const StatementChart = () => {
    const options = {
        width: "200",
        height: "200",
        legend: {
            display: false,
            position: "right"
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    };
    return (
        <>
            <div className='Statement_Chart'>
                <Doughnut data={data} options={options} />
            </div>
        </>
    )
}

export default StatementChart
