import React, { memo, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, Tab, Tabs } from "react-bootstrap";
import "./UserLedger.scss";
import CommonButton from "../../../../common/CommonButton/CommonButton";
// import PositionCard from "./PositionCard/PositionCard";
import OrdersTable from "./OrdersTable/OrdersTable";
import OrderHistory from "./OrderHistory/OrderHistory";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import TradeHistory from "./TradeHistory/TradeHistory";
import DateCalenderCard from "./TradeHistory/DateCalenderCard/DateCalenderCard";
import PositionHistory from "./PositionHistory/PositionHistory";
import { ApiServices } from "../../services/api.services";
import { toastMsg } from "../../../../common/Toast/Toast";
import ConfirmModal from "../../../../common/ConfirmModal/ConfirmModal";

const UserLedger = () => {
  const userPosition = useAppSelector(
    (state) => state.spotExchange.userPosition
  );
  const orderHistory = useAppSelector(
    (state) => state.spotExchange.userOrderHistory
  );
  const openOrders = useAppSelector(
    (state) => state?.spotExchange?.userOpenOrder
  );
  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const [showModal, setShowModal] = useState(false);
  const [tabName, setTabName] = useState("orders");

  const handleCloseAllPosition = async () => {
    try {
      let response;
      if (tabName === "positions") {
        const dataObj = {
          address: walletAddress,
          userOrders: userPosition,
        };
        response = await ApiServices.closeAllPostions(dataObj);
      } else {
        const dataObj = {
          address: walletAddress,
        };
        response = await ApiServices.closeAllOrders(dataObj);
      }

      if (response && response.status === 200) {
        setShowModal(false);
        toastMsg.success("All open " + tabName + " closed");
      }
    } catch (error) {
      toastMsg.error(error.data.message, { toastId: "errormsg" });
    }
  };

  return (
    <div className="UserLedger_V2 nondraggable_card">
      <Tab.Container
        id="left-tabs-example"
        className="UserLedger_Tabs"
        defaultActiveKey="orders"
        onSelect={(e) => setTabName(e)}
        unmountOnExit={true}
        mountOnEnter={true}
        transition={false}
      >
        <div className="UserLedger_Tabs_Nav">
          <Nav variant="pills" className="Bottom_Border_Tabs">
            <Nav.Item>
              <Nav.Link eventKey="orders">
                Open Orders{" "}
                <span className="List_No">{openOrders?.length}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fills">
                Fills <span className="List_No">{orderHistory?.length}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tradeHistory">Trade History</Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link eventKey="positionsHistory">Positions History</Nav.Link>
            </Nav.Item> */}
            {/* <Nav.Item>
              <Nav.Link eventKey="liquidations">Liquidations</Nav.Link>
            </Nav.Item> */}
          </Nav>
          {(tabName === "positions" || tabName === "orders") && (
            <CommonButton
              onClick={() => setShowModal(true)}
              title={`Close all ${tabName}`}
              className="grey-border-btn"
            />
          )}
        </div>
        <div className="UserLedger_Tabs_Content">
          <Tab.Content>
            <Tab.Pane eventKey="orders">
              <OrdersTable />
            </Tab.Pane>
            <Tab.Pane eventKey="fills">
              <OrderHistory />
            </Tab.Pane>
            <Tab.Pane eventKey="tradeHistory">
              <DateCalenderCard />
              <TradeHistory />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="positionsHistory">
              <DateCalenderCard />
              <PositionHistory />
            </Tab.Pane> */}
            {/* <Tab.Pane eventKey="liquidations">
              <OrdersTable />
            </Tab.Pane> */}
          </Tab.Content>
        </div>
      </Tab.Container>
      <PerfectScrollbar></PerfectScrollbar>
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={handleCloseAllPosition}
        tab={tabName}
      />
    </div>
  );
};

export default memo(UserLedger);
