import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

import {
  osName,
  isDesktop,
  isMobile,
  isTablet,
  isSmartTV,
} from "react-device-detect";
import { toast } from "react-toastify";
import { ApiServices } from "../pages/Exchange/services/api.services";
// import { async } from "@firebase/util";

// import firebase from "firebase";

// Replace these values with your project's ones
// (you can find such code in the Console)

const webPushApiKye = process.env.REACT_APP_FCM_VAPIKEY;
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID,
};

const UrlFirebaseConfig = new URLSearchParams(
  {
    apiKey: process.env.REACT_APP_FCM_API_KEY,
    authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FCM_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FCM_APP_ID,
    measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID,
  }.toString()
);

const firebaseApp = !isMobile ? initializeApp(firebaseConfig) : null;
const messaging = !isMobile ? getMessaging(firebaseApp) : null;

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     // console.log("token=onMessageListener", payload);

//     onMessage(messaging, (payload) => {
//       console.log("token=onMessageListener", payload);

//       resolve(payload);
//     });
//   });

export const onMessageListener = async () => {
  if (!isMobile) {
    return new Promise(async (resolve) => {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
        onMessage(messaging, (payload) => {
          // toast.success(payload.notification.body);
          resolve(payload);
        });
      }
    });
  }
};

export const getTokenFirebase = async (walletAddress) => {
  if (!isMobile) {
    //   navigator.serviceWorker.register('./your-serviceworker-file.js')
    // .then((registration) => {
    //   messaging.useServiceWorker(registration);

    //   // Request permission and get token.....
    // });

    // rocess.env.PUBLIC_URL
    try {
      await Notification.requestPermission()
        .then((permission) => {
          if ("serviceWorker" in navigator) {
            window.navigator.serviceWorker
              .register(`/firebase-messaging-sw.js?${UrlFirebaseConfig}`)
              .then(function (registration) {
                console.log("checkkkk");
                if (permission === "granted") {
                  getToken(messaging, {
                    vapidKey: webPushApiKye,
                  })
                    .then((currentToken) => {
                      if (currentToken) {
                        console.log("this is token", currentToken);
                        saveToken(currentToken, walletAddress);
                      } else {
                        // console.log(
                        //   "No registration token available. Request permission to generate one."
                        // );
                      }
                    })
                    .catch((err) => {
                      // console.log("currentToken= ", err);
                      // catch error while creating client token
                    });
                } else {
                  // console.log("Notification not granted.");
                }
              });
          }
        })
        .catch((error) => {
          getTokenFirebase();
          console.log("here you go", error);
        });
    } catch (error) {
      getTokenFirebase();
      console.log("here you go", error);
      // Safari doesn't return a promise for requestPermissions and it
      // throws a TypeError. It takes a callback as the first argument
      // instead.
      // if (error instanceof TypeError) {
      //   Notification.requestPermission(() => {});
      // } else {
      //   throw error;
      // }
    }
  }
};

const saveToken = async (tokenVal, walletAddress) => {
  var navigator_info = window.navigator;
  var screen_info = window.screen;
  var uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent?.replace(/\D+/g, "");
  uid += navigator_info.plugins.length;

  let deviceType;
  if (isDesktop) {
    deviceType = `Desktop ${osName}`;
  } else if (isMobile) {
    deviceType = `Mobile ${osName}`;
  } else if (isTablet) {
    deviceType = `Tablet ${osName}`;
  } else if (isSmartTV) {
    deviceType = `SmartTV ${osName}`;
  } else {
    deviceType = `Other device ${osName}`;
  }

  try {
    let parma = {
      userAddress: walletAddress,
      token: tokenVal,
      deviceId: uid,
      deviceType: "web",
    };
    const response = await ApiServices.saveFirebaseToken(parma);
    if (response) {
      let responce = response.data;
      // console.log("responce", responce);
    }
  } catch (error) {}
};
