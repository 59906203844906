import { memo, useRef } from "react";
import "./CustomButtonStyle.scss";

const CustomButton = (props) => {
  return (
    <button
      {...props}
      className={`btn-style ${props.className}`}
      disabled={props?.disabled}
    >
      {props.title} <span className={`light_text ${props.textclass}`}>{props.text}</span>
      <img
        src={props?.Icon}
        className={`Btn_Icon ${props?.imgclass}`}
        alt="Icon"
      />
    </button>
  );
};

export default memo(CustomButton);
