import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonButton from '../../../../common/CommonButton/CommonButton';
import fireicon from "../../../../assets/images/fireicon.svg";
import ustdicon from "../../../../assets/images/tether-usdt.svg";
import ustdicon1 from "../../../../assets/images/btc.svg";
import CustomTooltip from '../../../../common/Tooltips/CustomTooltip';
import Arrowdown from "../../../../assets/images/big_arrow.svg"
import errorIcon from "../../../../assets/icons/red_cross.svg"
import ConversionSuccessfulModal from './ConversionSuccessfulModal';
import ConversionProcessingModal from './ConversionProcessingModal';

const ConfirmConversionModal = ({ show, handleClose }) => {
    const orderdata = [
        {
            heading: "Rate",
            text: "1 BTC ≈ 24,567.57 USDT",
            tooltipblock: "d-inline-block",
        },
        {
            heading: "Inverse Rate",
            text: "1 USDT ≈ 0.00000325 BTC",
        },
        {
            heading: "Transaction Fees",
            text: "",
            textnone: "d-none",
            textblock: "d-block",
            tooltipblock: "d-inline-block",
        },
        {
            heading: "Payment Method",
            text: "Spot Wallet",
        },
        {
            heading: "You will spend",
            text: "486.78 USDT",
        },
    ];

    const Usdtdata = [
        {
            tokenicon: ustdicon,
            tokenvalue: "486.78",
            tokentext: "USDT",
        },
        {
            tokenicon: ustdicon1,
            tokenvalue: "486.78",
            tokentext: "BTC",
        },
    ];

    const [showmodal, setShowmodal] = useState(false);

    const handleClosemodal = () => setShowmodal(false);
    const handleShowmodal = () => setShowmodal(true);

    return (
        <>
            <Modal className='ConfirmCon_Modal' centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Conversion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='Convert_Usdt_data'>
                        <ul>
                            {Usdtdata.map((item, i) => {
                                return (
                                    <li className='d-flex align-items-center justify-content-between' key={i}>
                                        <h3><img src={item.tokenicon} alt='icon' /> {item.tokenvalue}</h3>
                                        <h4>{item.tokentext}</h4>
                                    </li>
                                )
                            })}
                        </ul>
                        <span className='Arrow_down'><img src={Arrowdown} alt='img' /></span>
                    </div>
                    <div className='Convert_Order'>
                        <div className='Convert_Order_Information'>
                            <ul>
                                {orderdata.map((item, i) => {
                                    return (
                                        <li className='d-flex align-items-center justify-content-between' key={i}>
                                            <p>{item.heading} <CustomTooltip className={item.tooltipblock} text="Hello" /></p>
                                            <h4><span className={item.textnone}>{item.text}</span> <span className={`Text_Fee ${item.textblock}`}><img src={fireicon} alt='fire' /> <small>0 Fee</small></span></h4>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div className='error_msg d-flex align-items-center justify-content-center'>
                                <img src={errorIcon} alt='icon' />
                                <p>Your account has insufficient balance. Please fund your account.</p>
                            </div>
                            <CommonButton onClick={handleShowmodal} className="w-100" title="Confirm" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* <ConversionSuccessfulModal show={showmodal} handleClose={handleClosemodal} /> */}
            <ConversionProcessingModal show={showmodal} handleClose={handleClosemodal} />
        </>
    )
}

export default ConfirmConversionModal
