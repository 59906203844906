import React, { useState } from 'react'
import "./DetailCoin.scss"
import btiIcon from "../../../../../assets/images/btc.svg"
import { YellowStarIcon } from '../../../../../assets/Svg/SvgImages'
import { Link } from 'react-router-dom'
import linkicon from "../../../../../assets/icons/Links_grey_icon.svg"
import noteicon from "../../../../../assets/icons/note_icon.svg"
import { Col, ProgressBar, Row } from 'react-bootstrap'
import CustomTooltip from '../../../../../common/Tooltips/CustomTooltip'
import Select from "react-select";
import lineIcon from "../../../../../assets/icons/linechat_arrow.svg"
import updownicon from "../../../../../assets/icons/updown_icon.svg"
import DetailPriceCard from './DetailPriceCard'

const DetailCoin = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const now = 60;

    const options = [
        { value: "24h", label: "24h" },
        { value: "10 minute", label: "10 minute" },
        { value: "1 Hour", label: "1 Hour" },
        { value: "1 day", label: "1 day" },
        { value: "3 day", label: "3 day" },
    ];

    return (
        <>
            <div className='Trade_Detail'>
                <h2>Details</h2>
                <Row>
                    <Col xs={12} lg={6}>
                        <div className='Trade_Detail_bitCoin'>
                            <div className='Trade_Detail_bitCoin_Rating d-flex align-items-center'>
                                <img src={btiIcon} alt='Bit' />
                                <h5>Bitcoin</h5>
                                <span>BTC</span>
                                <YellowStarIcon />
                            </div>
                            <div className='Trade_Detail_bitCoin_Rank d-flex align-items-center'>
                                <span>Rank #1</span>
                                <span>Coin</span>
                                <span>On 2,771,773 watchlists</span>
                            </div>
                            <div className='Trade_Detail_bitCoin_Links d-flex align-items-center'>
                                <Link to="#"><img src={linkicon} alt='Bit' /> <span>bitcoin.org</span></Link>
                                <Link to="#"><img src={noteicon} alt='Bit' /> <span>Whitepaper</span></Link>
                            </div>
                            <div className='Trade_Detail_bitCoin_Tags'>
                                <h5>Tags:</h5>
                                <div className='d-flex align-items-center'>
                                    <div className='Tags_List flex-wrap d-flex align-items-center'>
                                        <span>Mineable</span>
                                        <span>PoW</span>
                                        <span>SHA-256</span>
                                        <span>Store of value</span>
                                        <span>View all</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className='Trade_bitCoin_Price mt-4 mt-lg-0'>
                            <div className='Trade_bitCoin_Price_Linevalue d-flex align-items-center justify-content-between'>
                                <h5>$43,975.72 <span className='text_green'>+12% <img src={lineIcon} alt='Bit' /></span></h5>
                                <p>Bitcoin Price(USD) <CustomTooltip text="Hello" /></p>
                            </div>
                            <div className='Trade_bitCoin_Price_highLow'>
                                <div className='mb-1 d-flex align-items-center justify-content-between'>
                                    <p><img src={updownicon} alt='Bit' /> High / Low Price</p>
                                    <div className='common_select'>
                                        <Select
                                            placeholder="1 minute"
                                            defaultValue={options}
                                            onChange={setSelectedOption}
                                            options={options}
                                            classNamePrefix="react-select"
                                            value={selectedOption}
                                            // menuIsOpen={true}
                                        />
                                    </div>
                                </div>

                                <ProgressBar now={now} label={`${now}%`} visuallyHidden />

                                <div className='mt-3 d-flex align-items-center justify-content-between'>
                                    <p><span>Low :</span> $36,889.19</p>
                                    <p><span>High :</span> $37,446.24</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} className="mt-4">
                        <DetailPriceCard />
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default DetailCoin
