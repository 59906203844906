import React, { memo, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { numberSystemFormate } from "../../utils/comman";
import { showDecimal } from "../../../../_utils/comman";
import dummyToken from "../../../../assets/images/dummyToken.png";
import { useNavigate } from "react-router-dom";
const TopPairs = ({ listOfPairs, handleClose }) => {
    const [topGaining, setTopGaining] = useState([]);
    const [topTraded, setTopTraded] = useState([]);
    const [topLosing, setTopLosing] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getTopGaining();
        getTopTraded();
        getTopLosing();
    }, []);

    const getTopGaining = () => {
        let obj = [...listOfPairs];
        obj.sort((a, b) => {
            if (Number(a.change) > Number(b.change)) {
                return -1;
            } else if (Number(a.change) < Number(b.change)) {
                return 1;
            }
            return 0;
        });
        setTopGaining(obj.slice(0, 10));
    };

    const getTopTraded = () => {
        let obj = [...listOfPairs];
        obj.sort((first, second) => {
            let a = { ...first };
            let b = { ...second };

            if (!first?.volume) {
                a["volume"] = 0;
            }
            if (!second?.volume) {
                b["volume"] = 0;
            }
            if (Number(a.volume) > Number(b.volume)) {
                return -1;
            } else if (Number(a.volume) < Number(b.volume)) {
                return 1;
            }
            return 0;
        });
        setTopTraded(obj.slice(0, 10));
    };

    const getTopLosing = () => {
        let obj = [...listOfPairs];
        obj.sort((a, b) => {
            if (Number(a.change) < Number(b.change)) {
                return -1;
            } else if (Number(a.change) > Number(b.change)) {
                return 1;
            }
            return 0;
        });
        setTopLosing(obj.slice(0, 10));
    };

    const addDefaultSrc = (ev) => {
        ev.target.src = dummyToken;
    };

    const goToPair = (pair) => {
        navigate(`/pair/${pair}`);
        handleClose();
    };

    return (
        <Tabs
            defaultActiveKey="gaining"
            id="uncontrolled-tab-example"
            className="Bottom_Border_Tabs mt-4"
        >
            <Tab eventKey="gaining" title="Top gaining">
                <div className="Top_Traded">
                    {topGaining.map((data, i) => {
                        const pair = data.pair
                            .replace("USDT", "")
                            .toLowerCase();
                        return (
                            <div
                                key={i}
                                className="Top_Traded_Box"
                                onClick={() => goToPair(pair)}
                            >
                                <figure>
                                    {data?.icon ? (
                                        <img
                                            src={data?.icon}
                                            alt="icon"
                                            onError={addDefaultSrc}
                                        />
                                    ) : (
                                        <img src={dummyToken} alt="icon" />
                                    )}
                                </figure>
                                <div className="ms-2">
                                    <h6>
                                        {data.pair} <span>Perp</span>
                                    </h6>
                                    <p
                                        className={
                                            data.change > 0
                                                ? "text_green"
                                                : "text_red"
                                        }
                                    >
                                        {numberSystemFormate(
                                            showDecimal(data?.change * 100, 2)
                                        )}
                                        %
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Tab>
            <Tab eventKey="traded" title="Top traded">
                <div className="Top_Traded">
                    {topTraded.map((data, i) => {
                        const pair = data.pair
                            .replace("USDT", "")
                            .toLowerCase();
                        return (
                            <div
                                className="Top_Traded_Box"
                                onClick={() => goToPair(pair)}
                            >
                                <figure>
                                    {data?.icon ? (
                                        <img
                                            src={data?.icon}
                                            alt="icon"
                                            onError={addDefaultSrc}
                                        />
                                    ) : (
                                        <img src={dummyToken} alt="icon" />
                                    )}
                                </figure>
                                <div className="ms-2">
                                    <h6>
                                        {data.pair} <span>Perp</span>
                                    </h6>
                                    <p
                                        className={
                                            data.change > 0
                                                ? "text_green"
                                                : "text_red"
                                        }
                                    >
                                        {numberSystemFormate(
                                            showDecimal(data?.change * 100, 2)
                                        )}
                                        %
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Tab>
            <Tab eventKey="losing" title="Top losing">
                <div className="Top_Traded">
                    {topLosing.map((data, i) => {
                        const pair = data.pair
                            .replace("USDT", "")
                            .toLowerCase();
                        return (
                            <div
                                key={i}
                                className="Top_Traded_Box"
                                onClick={() => goToPair(pair)}
                            >
                                <figure>
                                    {data?.icon ? (
                                        <img
                                            src={data?.icon}
                                            alt="icon"
                                            onError={addDefaultSrc}
                                        />
                                    ) : (
                                        <img src={dummyToken} alt="icon" />
                                    )}
                                </figure>
                                <div className="ms-2">
                                    <h6>
                                        {data.pair} <span>Perp</span>
                                    </h6>
                                    <p
                                        className={
                                            data.change > 0
                                                ? "text_green"
                                                : "text_red"
                                        }
                                    >
                                        {numberSystemFormate(
                                            showDecimal(data?.change * 100, 2)
                                        )}
                                        %
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Tab>
            <Tab eventKey="listings" title="New listings">
                <div className="Top_Traded">
                    {/* {Currencybtns.map((data, i) => {
            return (
              <div className="Top_Traded_Box">
                <figure>
                  <img src={coinIcon} alt="icon" />
                </figure>
                <div className="ms-2">
                  <h6>
                    APTOS <span>Perp</span>
                  </h6>
                  <p className="text_green">26.68%</p>
                </div>
              </div>
            );
          })} */}
                </div>
            </Tab>
        </Tabs>
    );
};

export default memo(TopPairs);
