import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import CommonButton from "../../../../common/CommonButton/CommonButton";
import Admin from "../../Admin";
import TopbarHeading from "../../common/TopbarHeading/TopbarHeading";
import "./UnifiedTrading.scss";
import infoicon from "../../../../assets/icons/info_blue_icon.svg";
import CoinsTable from "./CoinsTable/CoinsTable";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { ApiServices } from "../../../Exchange/services/api.services";
import { useDispatch } from "react-redux";
import { saveUserUnifiedTradingBalance } from "../../../../redux/_slices/user.slice";
import DashboardMoveFunds from "../../components/MoveFunds/DashboardMoveFunds";
import DepositModal from "../../../Exchange/components/BuySell/DepositModal/DepositModal";
import WithdrawModal from "../../../Exchange/components/BuySell/WithdrawModal/WithdrawModal";
import { saveUsdValue } from "../../../../redux/_slices/user.slice";
import Loader from "../../common/Loader/Loader";
import { updateLoader } from "../../../Exchange/redux/_slices/exchange.slice";

const UnifiedTrading = () => {
  const [showDeposit, setShowDeposit] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showMoveFunds, setShowMoveFunds] = useState(false);
  const [searchCoin, setSearchCoin] = useState("");
  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const hideBalance = useAppSelector((state) => state.user.hideBalance);
  const loader = useAppSelector((state) => state.exchange.loader);
  const userUnifiedBalance = useAppSelector(
    (state) => state.user.userUnifiedTradingBalance
  );
  const usdValue = useAppSelector((state) => state.user.usdValue);
  const dispatch = useDispatch();
  const getAllBalance = async () => {
    if (walletAddress) {
      let unifiedBalance = await ApiServices.getUnifiedTrading({
        address: walletAddress,
        coin: searchCoin,
      });
      if (unifiedBalance?.data) {
        dispatch(
          saveUserUnifiedTradingBalance({
            totalEquity: unifiedBalance?.data?.totalEquity,
            marginBalance: unifiedBalance?.data?.totalMarginBalance,
            PnL: unifiedBalance?.data?.unrealisedPnl,
            coins: unifiedBalance?.data?.coins,
            initialMargin: unifiedBalance?.data?.totalInitialMargin,
            maintenanceMargin: unifiedBalance?.data?.totalMaintenanceMargin,
            imRate: unifiedBalance?.data?.accountIMRate,
            mmRate: unifiedBalance?.data?.accountMMRate,
            usdValue: unifiedBalance?.data?.usdValue,
          })
        );
        dispatch(updateLoader(false));
      }
    } else {
      dispatch(
        saveUserUnifiedTradingBalance({
          totalEquity: 0,
          marginBalance: 0,
          PnL: 0,
          coins: [],
          initialMargin: 0,
          maintenanceMargin: 0,
          imRate: 0,
          mmRate: 0,
          usdValue: 0,
        })
      );
      dispatch(updateLoader(false));
    }

    try {
      let getUsdpPrice = await ApiServices.getUsdpPrice();
      if (getUsdpPrice) {
        dispatch(saveUsdValue(getUsdpPrice?.data?.usdtPrice));
      } else {
        dispatch(saveUsdValue(0));
      }
    } catch (error) {}
  };
  const roundToTwoDecimals = (num) => {
    return Math.round(num * 100) / 100;
  };

  useEffect(() => {
    getAllBalance();
  }, [walletAddress, searchCoin]);

  const handleSearchCoin = (data) => {
    setSearchCoin(data);
  };
  return (
    <Admin>
      {loader && <Loader />}
      <div className="Common_TopBar">
        <Row className="align-items-center Spot_Header">
          <Col xs={12} xl={7}>
            <TopbarHeading title="Unified Trading Account" />
          </Col>
          <Col xs={12} xl={5} className="d-md-flex justify-content-xl-end">
            <div className="Common_Header_Btns d-flex align-items-center">
              <CommonButton
                title="Transfer"
                className="grey-btn2"
                onClick={() => setShowMoveFunds(true)}
              />
              <CommonButton title="Convert" className="grey-btn2" />
              <CommonButton title="Transaction History" className="grey-btn2" />
            </div>
            <DepositModal
              show={showDeposit}
              handleClose={() => setShowDeposit(false)}
            />
            <WithdrawModal
              show={showWithdraw}
              handleClose={() => setShowWithdraw(false)}
            />
            <DashboardMoveFunds
              show={showMoveFunds}
              handleClose={() => setShowMoveFunds(false)}
            />
          </Col>
        </Row>
      </div>
      <div className="Common_admin_Contentarea future_assets_content">
        <div className="mmtSection">
          <div className="bar mmt">
            <span className="type">IM</span>
            <div className="progressBar">
              <div
                className="filledBar"
                style={{
                  width: `${
                    userUnifiedBalance?.imRate !== userUnifiedBalance?.imRate
                      ? userUnifiedBalance?.imRate
                      : 0
                  }%`,
                }}
              ></div>
            </div>
            <span className="percentage">
              {hideBalance
                ? "******"
                : `${
                    userUnifiedBalance?.imRate !== undefined
                      ? userUnifiedBalance?.imRate
                      : 0
                  }%`}
            </span>
            <span className="amount">
              {hideBalance
                ? "*****"
                : `${
                    userUnifiedBalance?.initialMargin !== undefined
                      ? roundToTwoDecimals(userUnifiedBalance?.initialMargin)
                      : 0
                  } USDT`}
            </span>
          </div>
          <div className="bar imt">
            <span className="type">MM</span>
            <div className="progressBar">
              <div
                className="filledBar"
                style={{
                  width: `${
                    userUnifiedBalance?.mmRate !== undefined
                      ? userUnifiedBalance?.mmRate
                      : 0
                  }%`,
                }}
              ></div>
            </div>
            <span className="percentage">
              {hideBalance
                ? "******"
                : `${
                    userUnifiedBalance?.mmRate !== undefined
                      ? userUnifiedBalance?.mmRate
                      : 0
                  }%`}
            </span>
            <span className="amount">
              {hideBalance
                ? "*****"
                : `${
                    userUnifiedBalance?.maintenanceMargin !== undefined
                      ? roundToTwoDecimals(
                          userUnifiedBalance?.maintenanceMargin
                        )
                      : 0
                  } USDT`}
            </span>
          </div>
        </div>

        <div className="Naviblue_Card d-flex align-items-center">
          <img src={infoicon} alt="eye" />
          <p className="font14 ps-3">
            To start trading, please transfers funds into your Trading Account
          </p>
        </div>
        <div className="d-flex flex-wrap width-3">
          <div className="Spot_blnc_Card">
            <div className="d-flex align-items-center">
              <h5>Total Equity</h5>
            </div>
            <h3 className="font22">
              {hideBalance ? (
                <>
                  {"********"}
                  <span className="d-block pt-3 font18">{"********"}</span>
                </>
              ) : (
                <>
                  {userUnifiedBalance?.totalEquity !== undefined
                    ? roundToTwoDecimals(userUnifiedBalance?.totalEquity)
                    : 0}{" "}
                  {"USDT"}
                  <span className="d-block pt-3 font18">
                    ≈{" "}
                    {`$${
                      userUnifiedBalance?.totalEquity !== undefined
                        ? roundToTwoDecimals(
                            userUnifiedBalance.totalEquity * usdValue
                          )
                        : 0
                    }`}
                  </span>
                </>
              )}
            </h3>
          </div>
          <div className="Spot_blnc_Card">
            <div className="d-flex align-items-center">
              <h5>Margin Balance</h5>
            </div>
            <h3 className="font22">
              {hideBalance ? (
                <>
                  {"********"}
                  <span className="d-block pt-3 font18">{"********"}</span>
                </>
              ) : (
                <>
                  {userUnifiedBalance?.marginBalance !== undefined
                    ? roundToTwoDecimals(userUnifiedBalance?.marginBalance)
                    : 0}{" "}
                  {"USDT"}
                  <span className="d-block pt-3 font18">
                    ≈{" "}
                    {`$${
                      userUnifiedBalance?.marginBalance !== undefined
                        ? roundToTwoDecimals(
                            userUnifiedBalance.marginBalance * usdValue
                          )
                        : 0
                    }`}
                  </span>
                </>
              )}
            </h3>
          </div>
          <div className="Spot_blnc_Card">
            <div className="d-flex align-items-center">
              <h5>Unrealized PnL of Perpetual and Futures</h5>
            </div>
            <h3 className="font22">
              {hideBalance ? (
                <>
                  {"********"}
                  <span className="d-block pt-3 font18"> {"********"}</span>
                </>
              ) : (
                <>
                  {userUnifiedBalance?.PnL !== undefined
                    ? roundToTwoDecimals(userUnifiedBalance?.PnL)
                    : 0}{" "}
                  {"USDT"}
                  <span className="d-block pt-3 font18">
                    ≈ ${roundToTwoDecimals(userUnifiedBalance?.PnL * usdValue)}
                  </span>
                </>
              )}
            </h3>
          </div>
        </div>
        <CoinsTable
          coins={userUnifiedBalance?.coins}
          search={handleSearchCoin}
        />

        {/* <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="Positions"
                >
                    <div className="Futures_Tabs_Container">
                        <Nav variant="pills" className="Common_button_tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="Positions">
                                    Positions
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Assets">Assets</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="Positions">
                                <PositionsTable />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Assets">
                                <PositionsTable />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container> */}
      </div>
    </Admin>
  );
};

export default UnifiedTrading;
