import Web3 from "web3";
import {
  CHAIN_ID_BINANCE,
  CHAIN_ID_ETHEREUM,
  DOLLARPRICE,
  walletConnectProjectId,
} from "../constants/constants";
import store from "../../../redux/_store/configureStore";
// import { convertDecimalToHex } from "../../../_utils/comman";
import { provider, wcprovider } from "./walletServices";

const wallets = [
  "metamask",
  "walletconnect",
  "binance_wallet",
  "trust_wallet",
  "Coinbase_Wallet",
  "Token_Pocket",
  "Brave",
  "metamask",
];

export const fixAndConvertFunction = (value) => {
  let newdata = value?.toString();
  newdata = Number(value) ? Web3.utils.fromWei(newdata, "ether") : 0;
  newdata = Number(newdata)?.toFixed(6)?.slice(0, -1);
  return newdata;
};

export const fixAndConvertFunction2 = (value) => {
  let newdata = value?.toString();
  newdata = value ? Web3.utils.fromWei(newdata, "ether") : 0;
  return newdata;
};

export const dollarFormat = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
  style: "currency",
  currency: "USD",
});

export const tofixFunction = (value) => {
  let newValue = value.toFixed(3)?.slice(0, -1);
  newValue = dollarFormat.format(newValue);
  // newValue = newValue.slice(0, newValue.indexOf(".") + 4);
  return newValue;
};

export const valueWithDollarFormat = (value) => {
  let originalValue = value?.toLocaleString("fullwide", {
    useGrouping: !1,
  });
  if (originalValue != 0 && !originalValue?.includes("-")) {
    let newValue = (originalValue * DOLLARPRICE)?.toLocaleString("fullwide", {
      useGrouping: !1,
    });
    newValue = Number(newValue)?.toFixed(3)?.slice(0, -1);
    newValue = dollarFormat.format(newValue);
    let amountValue =
      newValue == NaN || newValue == "$NaN" || newValue == "NaN"
        ? `${value}`
        : `${value}  (${newValue})`;
    return amountValue;
  } else {
    return `0.00 ($0.00)`;
  }
};

export const mergeTwoRandom = async (arr1, arr2) => {
  function extractRandom(arr) {
    let index = Math.floor(Math.random() * arr?.length);
    let result = arr[index];
    // remove item from the array
    arr.splice(index, 1);
    return result;
  }

  let result = [];
  while (arr1.length || arr2.length) {
    if (arr1.length) {
      result[result?.length] = extractRandom(arr1);
    }
    if (arr2.length) {
      result[result?.length] = extractRandom(arr2);
    }
  }
  return result;
};

export const tofixFunctionSliced = (value) => {
  let newValue = value?.toString();
  newValue = newValue.includes(".")
    ? newValue.slice(0, newValue.indexOf(".") + 5)
    : newValue;
  return newValue;
};
/**REMOVE e FORM BIG NUMBER */
export const toFixedDeposit = (x, network) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  x = x?.toString();
  let newX =
    network === "Ethereum"
      ? Number(x) / 10 ** 6
      : Web3.utils.fromWei(x, "ether");
  // let newX = Web3.utils.fromWei(x, "ether");
  // newX = Number(newX)?.toFixed(5)?.slice(0, -1);
  // console.log("in fixed deposit", x, newX);
  return newX;
  // return x;
};

/**REMOVE e FORM BIG NUMBER */
export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  x = x?.toString();
  // let newX = Web3.utils.fromWei(x, "ether");
  // newX = Number(newX)?.toFixed(5)?.slice(0, -1);
  return x;
  // return x;
};

export const valueFixedFunction = (value) => {
  return value?.toFixed(5)?.slice(0, -1);
};

export const ethereumProviderChainID = async (networkVersionChainId) => {
  const connect =
    networkVersionChainId == CHAIN_ID_ETHEREUM
      ? CHAIN_ID_ETHEREUM
      : networkVersionChainId == CHAIN_ID_BINANCE
      ? CHAIN_ID_BINANCE
      : 1;
  return connect;
};

export const getProvider = async () => {
  const wallettype = store.getState()?.user?.wallet;
  const { ethereum, BinanceChain, trustwallet, coinbaseWalletExtension } =
    window;
  const provider1 =
    wallettype == "binance_wallet"
      ? BinanceChain
      : wallettype == "trust_wallet"
      ? trustwallet
      : wallettype == "Coinbase_Wallet"
      ? coinbaseWalletExtension
      : wallettype == wallets[1]
      ? wcprovider()
      : ethereum;

  return provider1;
};

export const chainNumberProvide = async () => {
  const wallettype = store.getState()?.user?.wallet;
  const provider = await getProvider();
  // console.log("providerprovider", provider);
  switch (wallettype) {
    case wallets[2]:
      return Web3.utils.hexToNumber(provider?.chainId);
    case wallets[3]:
      return provider?.networkVersion;
    case wallets[0]:
      return provider?.networkVersion;
    case wallets[4]:
      return provider?.networkVersion;
    case wallets[5]:
      return provider?.networkVersion;
    case wallets[6]:
      return provider?.networkVersion;
    case wallets[0]:
      return provider?.networkVersion;
    case wallets[1]:
      return provider?.rpcProviders?.eip155?.chainId;
    default:
      return provider?.networkVersion;
  }
};

/**REMOVE e FORM BIG NUMBER & and handle Big Number Error Function*/
export const toFixedNumber = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x?.toString()?.split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x?.toString()?.substring(2);
    }
  } else {
    e = parseInt(x?.toString()?.split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  x = x?.toString();
  let newX = Web3?.utils?.fromWei(x, "ether");
  newX = Number(newX)?.toFixed(5)?.slice(0, -1);
  return newX;
};

export const toFunctionBigNumber = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  // x = x?.toString();
  // let newX = Web3.utils.fromWei(x, "ether");
  // newX = Number(newX)?.toFixed(5)?.slice(0, -1);
  return x;
};

export const toCustomFixedFunction = (num, fixed) => {
  num = num * DOLLARPRICE;
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  num = num.toString().match(re)[0];
  num = Number(num) != 0 ? "$" + num : "$0.00";
  return num;
};

// export const chainsNetwork = () => {
//   const { chainId } = store?.getState()?.user;
//   const chains = chainId == 1 ? [mainnet] : [bsc];
//   return chains;
// };

export const wagmiClient = () => {
  // const id = "06cd73c01555f937a6963abee3431312";
  // const { chainId } = store?.getState()?.user;
  // const chains = [bsc];
  // const projectId = id;
  // const { publicClient } = configureChains(chains, [
  //   w3mProvider({ projectId }),
  // ]);
  // const wagmiConfig = createConfig({
  //   autoConnect: true,
  //   connectors: w3mConnectors({ projectId, chains }),
  //   publicClient,
  // });
  // // 3. Configure modal ethereum client
  // const ethereumClient = new EthereumClient(wagmiConfig, chains);
  // return ethereumClient;
};
export const wagmiConfigfunction = () => {
  // const id = "06cd73c01555f937a6963abee3431312";
  // const { chainId } = store?.getState()?.user;
  // const projectId = id;
  // const chains = [bsc];
  // // const chains = chainId == 1 ? [mainnet] : [bsc];
  // const { publicClient } = configureChains(chains, [
  //   w3mProvider({ projectId }),
  // ]);
  // const wagmiConfig = createConfig({
  //   autoConnect: true,
  //   connectors: w3mConnectors({ projectId, chains }),
  //   publicClient,
  // });
  // return wagmiConfig;
};
