import React, { memo, useEffect } from "react";
import "./TradeHistory.scss";
import Table from "react-bootstrap/Table";
import "react-perfect-scrollbar/dist/css/styles.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { showDecimal } from "../../../../../_utils/comman";
import { numberSystemFormate } from "../../../utils/comman";
import { ApiServices } from "../../../services/api.services";
import {
  saveUserTradeHistory,
  savetrades,
} from "../../../redux/_slices/exchange.slice";

const TradeHistory = () => {
  const dispatch = useDispatch();
  const walletAddress = useSelector((state) => state?.user?.walletAddress);
  const pairList = useSelector((state) => state?.exchange?.listOfPairs);
  const listofTrades = useSelector((state) => state.exchange.listofTrades);
  const filters = useSelector((state) => state.user.userLedgerFilters);
  const limit = 10;

  useEffect(() => {
    if (walletAddress) {
      getTradeHistoryList();
    } else {
      dispatch(saveUserTradeHistory([]));
      dispatch(savetrades([]));
    }
  }, [walletAddress, filters]);

  const getTradeHistoryList = async () => {
    try {
      const dataObj = {
        address: walletAddress,
        limit,
        ...filters,
      };
      const response = await ApiServices.tradeHistoryList(dataObj);
      // console.log("trade historyyyyyyyyyyyyy", response);
      dispatch(saveUserTradeHistory(response?.data?.list));
      dispatch(savetrades(response?.data?.list));
    } catch (error) {}
  };

  return (
    <>
      <div className="Orders_Table trade_history_Table">
        {/* <PerfectScrollbar></PerfectScrollbar> */}
        <Table responsive>
          <thead>
            <tr>
              <th style={{ minWidth: "150px" }}>Date / Time</th>
              <th style={{ minWidth: "150px" }}>Symbol</th>
              <th>Side</th>
              {/* <th>Order Type</th> */}
              <th>Price</th>
              <th style={{ minWidth: "150px" }}>Quantity</th>
              {/* <th style={{ minWidth: "150px" }}>Fee</th> */}
              {/* <th style={{ minWidth: "150px" }}>Realized Profit</th> */}
            </tr>
          </thead>
          <tbody>
            {listofTrades.map((data, i) => {
              const pairDetails =
                pairList &&
                pairList.length > 0 &&
                pairList?.find((itemVal) => itemVal?.pair == data?.symbol);
              return (
                <tr key={i}>
                  <td style={{ minWidth: "150px" }} className="Good_Til">
                    {moment(Number(data?.execTime)).format(
                      "DD-MM-YYYY  HH:mm:ss"
                    )}
                  </td>
                  <td style={{ minWidth: "150px" }} className="text-white">
                    <strong>{data?.symbol} Perpetual</strong>
                  </td>
                  <td className={data?.side === 0 ? "text_green" : "text_red"}>
                    {data?.side === 0 ? "Buy" : "Sell"}
                  </td>
                  {/* <td>{data?.orderType}</td> */}
                  <td>{data?.execPrice}</td>
                  {/* <td style={{ minWidth: "150px" }}>{data?.execQty?.toString().includes(".")
                    ? numberSystemFormate(
                      data?.execQty,
                      pairDetails?.price_scale
                    )
                    : data?.cumExecQty
                      ? data?.cumExecQty
                      : data?.cum_exec_qty}</td> */}
                  <td>{data?.execQty}</td>
                  {/* <td style={{ minWidth: "150px" }}>{data?.fee}</td> */}
                  {/* <td style={{ minWidth: "150px" }}>142.9500000 USDT</td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default memo(TradeHistory);
