import { useState, useEffect } from "react";
import { socket } from "../../../App";
import { NETWORK_INFO } from "../../../constants/wallet";
import {
  updateOrderBookOnOrder,
  updateUserOpenOrders,
  saveListOfPairs,
} from "../../Exchange/redux/_actions/exchange.action";
import {
  saveActiveCoins,
  saveInitialState,
  saveUserBalances,
  saveUserOpenOrders,
  saveUserTradeHistory,
  saveFundingCountdown,
  saveUserSpotBalances,
} from "../../Exchange/redux/_slices/exchange.slice";
import {
  saveLeverage,
  saveLeverageInfo,
  saveMarginMode,
  saveCurrentPair,
  saveWalletBalance,
  saveUserFundingBalance,
  saveNotificationCount,
} from "../../../redux/_slices/user.slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hooks";
import {
  deleteSingleUserOpenOrder,
  saveCurrentPrice,
  saveMinMaxLeverage,
  saveOrderPrice,
  saveSingleUserOpenOrders,
  savetrades,
  saveUserPosition,
  addCancelOrder,
  updatePositionOrder,
  saveUserOrderHistory,
  saveUserData,
  saveAllMarkPrice,
} from "../../Exchange/redux/_slices/exchange.slice";
import { DEFAULT_PAIR } from "../../../constants/constants";
import { saveCountDown } from "../../../redux/_slices/xy.slice";
import { throttle } from "lodash";
import { ApiServices } from "../services/api.services";

export const useSocketService = (referCode) => {
  const [isActive, setIsActive] = useState(false);
  const [isSocket, setIsSocket] = useState(true);

  const dispatch = useAppDispatch();

  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const selectedPair = useAppSelector((state) => state.user.selectedPairKey);
  const selectedPairKey = selectedPair ? selectedPair : DEFAULT_PAIR;

  const chainId = useAppSelector((state) => state.user.chainId);

  /**
   * Emit Socket on chaingin wallet, pair, network
   */
  let timeoutId = null;
  const callEmitActions = () => {
    try {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      // console.log("timeoutId=1", timeoutId);
      // Use setTimeout to delay API call
      if (timeoutId === null) {
        timeoutId = setTimeout(async () => {
          const symbol = NETWORK_INFO.find(
            (doc) => doc.chain_id === chainId
          )?.symbol;
          let walletAddresss = walletAddress ? walletAddress.toLowerCase() : "";
          socket.emit("currentPair", {
            address: walletAddresss,
            pair: selectedPairKey?.toUpperCase(),
            chainId: chainId,
            symbol: symbol,
            referralId: referCode,
          });
        }, 500);
      }
    } catch (error) {}
  };

  /**
   * Public Socket Listeners On
   */
  const publicListenersOn = () => {
    socket.on("funding_rate_countdown", (response) => {
      // console.log("firstresponse", response);
      if (window.location.pathname == "/staking") {
        dispatch(saveCountDown(response?.funding_rate_countdown));
      }
    });
    // For Active Coins Information
    socket.on("ACTIVE_COINS", (response) => {
      dispatch(saveActiveCoins(response));
    });

    socket.on("funding_rate_countdown", (response) => {
      // console.log("firstresponse", response);
      if (window.location.pathname == "/staking") {
        dispatch(saveCountDown(response?.funding_rate_countdown));
      }
    });

    // For Funding Countdown Information
    socket.on("funding_rate_countdown", (response) => {
      if (
        response &&
        response.funding_rate_countdown &&
        response.funding_rate_countdown.length
      ) {
        dispatch(saveFundingCountdown(response.funding_rate_countdown));
      }
    });

    socket.on(selectedPairKey?.toUpperCase() + "_GRAPH_STATS", (response) => {
      if (response) {
        // dispatch(saveStatistics(response));
      }
    });

    // For Intial Orderbook and trades
    socket.on(
      selectedPairKey?.toUpperCase() + "_INIT_ORDERBOOK_TRADES",
      (response) => {
        // console.log("_INIT_ORDERBOOK_TRADES", response.minMaxLeverage[0]);
        if (response && response.userData) {
          dispatch(saveUserData(response.userData));
        }

        dispatch(saveCurrentPair(response?.minMaxLeverage[0]));
        dispatch(saveOrderPrice(response.trades[0]?.price));

        dispatch(saveCurrentPrice(response.trades[0]?.price));
        if (response && response.infoForLeverageTradeType) {
          // dispatch(saveBuyOrderBook(response.buyOrderBook));
          dispatch(saveLeverageInfo(response.infoForLeverageTradeType));
          dispatch(saveLeverage(response.infoForLeverageTradeType.leverage));
        }
        if (response && response.minMaxLeverage) {
          dispatch(saveMinMaxLeverage(response.minMaxLeverage[0]));
        }
        if (response && response.positions && response.positions.length) {
          // console.log("UserPosition", response.positions);
          dispatch(saveUserPosition(response.positions));
        }
        if (response && response.activeOrder && response.activeOrder.length) {
          // console.log("UserOpenOrders", response.activeOrder);
          dispatch(saveUserOpenOrders(response.activeOrder));
        }
        if (response && response.orderHistory && response.orderHistory.length) {
          // console.log("orderHistory", response.orderHistory);
          dispatch(saveUserOrderHistory(response.orderHistory));
        }
        if (
          response &&
          response.tradesHistory &&
          response.tradesHistory.length
        ) {
        }
      }
    );

    // For Buy or Sell side orderbook subtract single-2 order if any user cancel the order
    socket.on(
      selectedPairKey?.toUpperCase() + "_CANCEL_ORDER_BOOK",
      (response) => {
        if (response && Object.keys(response).length) {
          response.type = "sub";
          dispatch(updateOrderBookOnOrder(response));
        }
      }
    );

    socket.on("allPairsMarkPrice", (response) => {
      if (response && response.length) {
        // console.log("response=", response);
        dispatch(saveAllMarkPrice(response));
      }
    });
  };
  let timeoutIdPairs = null;
  let delayTimer = 5000;
  const changeValue = throttle(async () => {
    try {
      const response = await ApiServices.getPairStats();
      if (response?.data && response?.data.length) {
        let dataArr = response?.data.filter(
          (element) => !element?.pair_name.toLowerCase().includes("perp")
        );
        if (dataArr && dataArr.length > 0) {
          let obj = [...dataArr];
          obj.sort((a, b) => {
            if (Number(a.low) > Number(b.low)) {
              return -1;
            } else if (Number(a.low) < Number(b.low)) {
              return 1;
            }
            return 0;
          });

          let arryFilter = obj.filter((item, index) => !!item?.volume);
          dispatch(saveListOfPairs("socket", arryFilter));
        }
      }
    } catch (error) {
      console.log("response=catch", error);
    }
  }, delayTimer);

  let delayLiq = 2000;
  const liquidationFn = throttle(async (walletAddress) => {
    try {
      const response = await ApiServices.getAssestsBalance({
        address: walletAddress,
      });
      let fundingBalance = await ApiServices.getFundingBalance({
        address: walletAddress,
      });
      dispatch(
        saveUserFundingBalance({
          availableBalance: fundingBalance?.data?.totalWalletBalance,
        })
      );
      if (response?.data) {
        dispatch(saveWalletBalance(response?.data));
      }
      const notifResp = await ApiServices.getNotificationList({
        address: walletAddress,
        page: 1,
      });
      dispatch(saveNotificationCount(notifResp?.data?.unseenCount));
    } catch (error) {
      // changeValue();
    }
  }, delayLiq);

  /**
   * User Socket Listeners On
   */
  const userListenersOn = (val) => {
    socket.on("USER_WALLET_BALANCE", (response) => {
      console.log("userbalance>>>", response);
      if (response) {
        dispatch(saveUserBalances(response));
      }
    });

    socket.on("USER_SPOT_DERIVATIVE_WALLET_BALANCE", (response) => {
      console.log("userbalanceSpot>>>", response?.data, response);
      let resObj = response?.data;
      if (resObj && Object.keys(resObj).length > 0) {
        dispatch(saveUserSpotBalances(resObj));
      }
    });

    socket.on(
      selectedPairKey?.toUpperCase() + "_ORDER_POSITION",
      (response) => {
        if (response) {
          dispatch(saveUserPosition(response));
        }
      }
    );

    socket.on(selectedPairKey?.toUpperCase() + "_USER_ORDERS", (response) => {
      if (response) {
        dispatch(saveUserOpenOrders(response));
      }
    });

    socket.on(
      selectedPairKey?.toUpperCase() + "_USER_ORDER_TRADES",
      (response) => {
        if (response) {
          // console.log("_USER_ORDER_TRADES>>>>", response);
          dispatch(saveUserTradeHistory(response));
        }
      }
    );

    socket.on(
      selectedPairKey?.toUpperCase() + "_USER_ORDER_HISTORY",
      (response) => {
        if (response) {
          dispatch(saveUserOrderHistory(response));
        }
      }
    );

    // Add in open order a single-2 order
    socket.on(selectedPairKey?.toUpperCase() + "_ACTIVE_ORDERS", (response) => {
      if (response && Object.keys(response).length) {
        response.status = 1;
        dispatch(updateUserOpenOrders("add", response));
      }
    });

    socket.on(selectedPairKey?.toUpperCase() + "_TRADE_ORDERS", (response) => {
      if (response && Object.keys(response).length) {
        dispatch(updateUserOpenOrders("sub", response));
      }
    });

    // Subtract in open order a single-2 order
    socket.on(selectedPairKey?.toUpperCase() + "_CANCEL_ORDER", (response) => {
      if (response && Object.keys(response).length) {
        dispatch(updateUserOpenOrders("cancel", response));
      }
    });
  };

  // changing on pair
  useEffect(() => {
    dispatch(saveInitialState("pair"));
  }, [selectedPairKey]);

  // changing on user
  useEffect(() => {
    dispatch(saveInitialState("wallet"));
  }, [walletAddress]);

  let trya = 0;
  const retryConnect = () => {
    let aa = setInterval(() => {
      if (trya === 0) {
        socket.connect();
      } else {
        clearInterval(aa);
      }
    }, 1000);
  };
  let interval;
  let intervalLiq;

  useEffect(() => {
    if (walletAddress) {
      callEmitActions();
      intervalLiq = setInterval(() => {
        liquidationFn(walletAddress);
      }, 2000);
    }
    // console.log("delayTimer", interval);

    interval = setInterval(() => {
      changeValue();
    }, 3000);
    return () => {
      clearInterval(interval);
      clearInterval(intervalLiq);
      changeValue.cancel();
    };
  }, [selectedPairKey, walletAddress]);

  useEffect(() => {
    if (isSocket) {
      socket.removeAllListeners();
      socket.on("disconnect", () => {
        trya = 0;
        retryConnect();
      });
      socket.on("connect", () => {
        trya = 1;
        if (walletAddress) {
          timeoutId = null;
          callEmitActions();
        }
        publicListenersOn();
        userListenersOn("useEffect");
      });
      publicListenersOn();
      userListenersOn("useEffect");
      // setIsSocket(false);
    }
    setIsActive(true);
    return () => {
      socket.removeAllListeners();
      setIsActive(false);
    };
  }, [walletAddress, chainId, selectedPairKey, socket?.id]);
  return isActive;
};
