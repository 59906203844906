import { createSlice } from "@reduxjs/toolkit";
import { MARKET_TRADES_SIZE } from "../../constants/constants";

const initialState = {
  // Users Info
  userBalances: [],
  userSpotBalances: {},
  userPosition: [],
  allMarkPrice: [],
  userOpenOrder: [],
  userOrderHistory: [],
  userTradeHistory: [],
  userTransactionHistory: [],
  depositBalance: {},
  userDate: Date.now() - 24 * 60 * 60,

  // Orderbook
  buyOrders: [],
  sellOrders: [],
  orderBook: {
    currentLtp: 0,
    lastLtp: 0,
  },
  markPrice: 0,

  buyOrdersTolalCount: 0,
  sellOrdersTolalCount: 0,
  flashOrders: [],
  currentPrice: 0,

  // User Orders
  userPaymentCurrency: "",
  buySellTab: "B",
  marketType: "M",
  orderPrice: "",
  orderSize: "",
  priceDecimalLength: 0,
  baseDecimalLength: 0,

  // Market Trades
  marketTrades: [],

  // Market Pair
  listOfPairs: [],
  activeCoins: [],
  FundingCountdown: [],
  indexPrice: [],
  markPrice: [],
  funding: [],
  statistics: {},
  defaultStatistics: {},
  listofTrades: [],
  listofOrders: [],
  liquidation: 90,
  minMaxLeverage: {},
  orderPref: "",
  rangeSlider: 0,
  deletePostion: {},
  userData: {},
  makrTkrFee: {},
  otherFee: {},

  // orders
};

export const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {
    savePaymentCurrency: (state, action) => {
      state.userPaymentCurrency = action.payload;
    },
    saveBuySellTab: (state, action) => {
      state.buySellTab = action.payload;
    },
    saveMarketType: (state, action) => {
      state.marketType = action.payload;
    },
    saveOrderPrice: (state, action) => {
      state.orderPrice = action.payload;
    },
    saveMakrTkrFee: (state, action) => {
      state.makrTkrFee = action.payload;
    },
    saveOtherFee: (state, action) => {
      state.otherFee = action.payload;
    },

    saveOrderSize: (state, action) => {
      state.orderSize = action.payload;
    },
    saveBuyOrderBook: (state, action) => {
      state.buyOrders = action.payload;
    },
    saveSellOrderBook: (state, action) => {
      state.sellOrders = action.payload;
    },
    saveBuyOrdersTotalCount: (state, action) => {
      state.buyOrdersTolalCount = action.payload;
    },
    saveSellOrdersTotalCount: (state, action) => {
      state.sellOrdersTolalCount = action.payload;
    },
    saveFlashOrders: (state, action) => {
      state.flashOrders = action.payload;
    },
    // Save User data
    saveUserBalances: (state, action) => {
      state.userBalances = action.payload;
    },
    saveUserSpotBalances: (state, action) => {
      state.userSpotBalances = { ...action.payload };
    },
    saveUserData: (state, action) => {
      state.userData = { ...action.payload };
    },
    saveUserPosition: (state, action) => {
      state.userPosition = action.payload;
    },
    saveAllMarkPrice: (state, action) => {
      state.allMarkPrice = action.payload;
    },

    updatePositionOrder: (state, action) => {
      // let arrVal = [];
      let response = [...action.payload];
      let newPositonOrder = [...state.userPosition];
      response.length > 0 &&
        response.forEach((element) => {
          let dataVal = newPositonOrder.find(
            (item) => item.id == element.id && item.pair == element.pair
          );
          if (dataVal) {
            let dataValIndex = newPositonOrder.findIndex(
              (item) => item.id == element.id && item.pair == element.pair
            );
            newPositonOrder[dataValIndex] = element;
          } else {
            newPositonOrder.push(element);
          }
        });

      let positonsList = { ...state?.deletePostion };

      let checkIndex = newPositonOrder.findIndex(
        (element) =>
          element?.id == positonsList?.id && element?.pair == positonsList?.pair
      );
      // console.log(
      //   "newPositonOrder=",
      //   checkIndex,
      //   newPositonOrder,
      //   positonsList
      // );
      if (checkIndex > -1) {
        newPositonOrder.splice(checkIndex, 1);
        state.deletePostion = {};
      }
      state.userPosition = [...newPositonOrder];
    },

    saveDeletePostion: (state, action) => {
      state.deletePostion = action.payload;
    },
    saveCurrentPrice: (state, action) => {
      state.currentPrice = action.payload;
    },
    saveUserOpenOrders: (state, action) => {
      let resArray = [...action.payload];
      let openOrder = resArray.filter(
        (item) => item?.trigger_direction != 1 && item?.trigger_direction != 2
      );
      state.userOpenOrder = [...openOrder];
    },
    deleteSingleUserOpenOrder: (state, action) => {
      const openData = action.payload;
      const newPositonOrder = [...state.userPosition];
      const userOpenOrderArray = [...state.userOpenOrder];
      const listTrades = [...state.listofTrades];
      // console.log("openData=checkOpen", openData);

      let checkOpen = true;
      openData.forEach((data) => {
        const objIndex = userOpenOrderArray.findIndex(
          (item) =>
            item.id === data.id &&
            item.symbol === data.symbol &&
            item.side == data.side
        );
        if (objIndex > -1) {
          userOpenOrderArray.splice(objIndex, 1);
          checkOpen = true;
        }
      });

      if (checkOpen) {
        state.userOpenOrder = [...userOpenOrderArray];
      }

      let checkIndex = false;
      openData.forEach((data) => {
        const postionIndex = newPositonOrder.findIndex(
          (item) =>
            item.pair === data.symbol &&
            item.side != data.side &&
            data.order_status == "Filled" &&
            item.quantity == data.quantity
        );

        if (postionIndex > -1) {
          newPositonOrder.splice(postionIndex, 1);
          checkIndex = true;
        }
      });
      if (checkIndex) {
        state.userPosition = [...newPositonOrder];
      }

      let tradeData = [];
      openData.forEach((data) => {
        if (data.order_status == "Filled") {
          // data.executed_price = state.orderBook.currentLtp;
          tradeData.push(data);
        }
      });
      if (tradeData.length > 0) {
        let tradeList = [...tradeData, ...listTrades];
        const uniqueChars = tradeList.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        );
        state.listofTrades = [...uniqueChars];
      }
    },
    addCancelOrder: (state, action) => {
      const data = action.payload;
      const userOrderHistory = [...data, ...state.userOrderHistory];
      const uniqueChars = userOrderHistory.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
      state.userOrderHistory = [...uniqueChars];
    },
    saveSingleUserOpenOrders: (state, action) => {
      const resData = action.payload;
      const userOpenOrderArray = [...state.userOpenOrder];
      // const orderArray =  userOpenOrderArray.filter
      if (resData?.trigger_direction != 1 && resData?.trigger_direction != 2) {
        const objIndex = userOpenOrderArray.findIndex(
          (item) => item.id === resData.id
        );
        // console.log("_USER_ORDERS", resData);
        if (objIndex == -1) {
          state.userOpenOrder = [action.payload, ...state.userOpenOrder];
        } else if (objIndex > -1) {
          userOpenOrderArray[objIndex] = resData;
          state.userOpenOrder = [...userOpenOrderArray];
        }
      }
    },
    saveUserOrderHistory: (state, action) => {
      const resData = action.payload;

      const uniqueChars = resData.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
      // console.log("uniqueChars", uniqueChars);

      state.userOrderHistory = [...uniqueChars];
    },
    saveUserTradeHistory: (state, action) => {
      state.userTradeHistory = [...action.payload];
    },
    saveUserTransactionHistory: (state, action) => {
      state.userTransactionHistory = action.payload;
    },

    savePairDecimals: (state, action) => {
      state.priceDecimalLength = action.payload;
      state.baseDecimalLength = action.payload;
    },

    saveDepositBalance: (state, action) => {
      state.depositBalance = { ...action.payload };
    },

    saveUserDate: (state, action) => {
      state.userDate = action.payload;
    },
    saveLtp: (state, action) => {
      state.orderBook.currentLtp = action.payload.currentLtp;
      state.orderBook.lastLtp = action.payload.lastLtp;
    },

    updateMarkPrice: (state, action) => {
      state.markPrice = action.payload;
    },
    saveMarketTrades: (state, action) => {
      state.orderBook.currentLtp =
        action.payload && action.payload.length ? action.payload[0].price : 0;
      state.orderBook.lastLtp =
        action.payload && action.payload.length && action.payload[1]
          ? action.payload[1].price
          : 0;
      let markTradeList = [...action.payload, ...state.marketTrades];
      if (markTradeList.length > MARKET_TRADES_SIZE) {
        markTradeList.length = MARKET_TRADES_SIZE;
      }

      state.marketTrades = [...markTradeList];
    },

    saveListOfPair: (state, action) => {
      state.listOfPairs = [...action.payload];
    },
    savePairList: (state, action) => {
      state.pairList = [...action.payload];
    },
    saveActiveCoins: (state, action) => {
      state.activeCoins = [...action.payload];
    },
    saveFundingCountdown: (state, action) => {
      state.FundingCountdown = [...action.payload];
    },
    // saveIndexPrice: (state, action) => {
    //   state.indexPrice = [action.payload];
    // },
    // saveMarketPrice: (state, action) => {
    //   state.markPrice = [action.payload];
    // },
    // saveFunding: (state, action) => {
    //   state.funding = [action.payload];
    // },
    savetrades: (state, action) => {
      state.listofTrades = [...action.payload];
    },
    saveOrders: (state, action) => {
      state.listofOrders = [...action.payload];
    },
    saveStatistics: (state, action) => {
      state.statistics = { ...action.payload };
    },
    saveDefaultStatistics: (state, action) => {
      state.defaultStatistics = { ...action.payload };
    },

    saveLiquidation: (state, action) => {
      state.liquidation = action.payload;
    },
    saveMinMaxLeverage: (state, action) => {
      state.minMaxLeverage = action.payload;
    },
    saveOrderPref: (state, action) => {
      state.orderPref = action.payload;
    },
    resetRangeSlider: (state, action) => {
      state.rangeSlider = action.payload;
    },
    saveInitialState: (state, action) => {
      if (action.payload === "pair") {
        state.buySellTab = "B";
        state.limitMarketTab = "L";
        state.orderPrice = "";
        state.depositBalance = {};
        state.buyOrders = [];
        state.sellOrders = [];
        state.orderBook = {
          currentLtp: 0,
          lastLtp: 0,
        };
        state.markPrice = 27651;
        state.marketTrades = [];
        state.userPosition = [];
        state.userOpenOrder = [];
        state.userOrderHistory = [];
        state.userTradeHistory = [];
        state.userTransactionHistory = [];
      }
      if (action.payload === "wallet") {
        state.depositBalance = {};
        state.userPosition = [];
        state.userOpenOrder = [];
        state.userOrderHistory = [];
        state.userTradeHistory = [];
        state.userTransactionHistory = [];
      }
    },
    updateOrderBook: (state, action) => {
      let orderBook = action.payload;
      let buyOrders = state.buyOrders;
      let sellOrders = state.sellOrders;
      if (orderBook && orderBook.side === 0) {
        let updatebuyOrders = [...buyOrders];
        if (orderBook.quantity == 0) {
          const index = updatebuyOrders.findIndex(
            (item) => orderBook.price === item.price
          );
          updatebuyOrders.splice(index, 1);
          state.buyOrders = updatebuyOrders;
        } else {
          const index = updatebuyOrders.findIndex(
            (item) => orderBook.price === item.price
          );
          if (index === -1) {
            updatebuyOrders.push(orderBook);
            updatebuyOrders.sort((a, b) => {
              return b.price - a.price;
            });
            updatebuyOrders.pop();
            state.buyOrders = updatebuyOrders;
          } else {
            updatebuyOrders[index].quantity += orderBook.quantity;
            state.buyOrders = updatebuyOrders;
          }
        }
      }
      if (orderBook && orderBook.side === 1) {
        let updatesellOrders = [...sellOrders];
        if (orderBook.quantity == 0) {
          const index = updatesellOrders.findIndex(
            (item) => orderBook.price === item.price
          );
          updatesellOrders.splice(index, 1);
          state.sellOrders = updatesellOrders;
        } else {
          const index = updatesellOrders.findIndex(
            (item) => orderBook.price === item.price
          );
          if (index === -1) {
            updatesellOrders.push(orderBook);
            updatesellOrders.sort((a, b) => {
              return b.price - a.price;
            });
            updatesellOrders.pop();
            state.sellOrders = updatesellOrders;
          } else {
            updatesellOrders[index].quantity = orderBook.quantity;
            state.sellOrders = updatesellOrders;
          }
        }
      }
    },
  },
});

export const {
  savePaymentCurrency,
  saveBuySellTab,
  saveMarketType,
  saveOrderPrice,
  saveOrderSize,
  saveMakrTkrFee,
  saveOtherFee,
  saveBuyOrderBook,
  saveSellOrderBook,
  saveBuyOrdersTotalCount,
  saveSellOrdersTotalCount,
  saveFlashOrders,
  saveUserBalances,
  saveUserPosition,
  saveAllMarkPrice,
  saveUserOpenOrders,
  saveUserOrderHistory,
  saveUserTradeHistory,
  saveUserTransactionHistory,
  savePairDecimals,
  saveDepositBalance,
  saveLtp,
  updateMarkPrice,
  // saveMarketTrades,
  saveListOfPair,
  savePairList,
  saveActiveCoins,
  saveFundingCountdown,
  saveIndexPrice,
  saveMarketPrice,
  saveFunding,
  saveInitialState,
  saveUserDate,
  savetrades,
  saveOrders,
  saveStatistics,
  saveDefaultStatistics,
  saveLiquidation,
  deleteSingleUserOpenOrder,
  addCancelOrder,
  saveSingleUserOpenOrders,
  saveCurrentPrice,
  saveMinMaxLeverage,
  saveOrderPref,
  updateOrderBook,
  resetRangeSlider,
  updatePositionOrder,
  saveDeletePostion,
  saveUserData,
  saveUserSpotBalances,
} = exchangeSlice.actions;

export default exchangeSlice.reducer;
