import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonButton from '../../../../common/CommonButton/CommonButton';
import listicon1 from "../../../../assets/icons/api-listicon1.svg"
import listicon2 from "../../../../assets/icons/api-listicon2.svg"
import CreateApiModal from './CreateApiModal'; 

const ApiTypeModal = ({ show1, handleClose1 }) => {
    const orderdata = [
        {
            icon: listicon1,
            heading: "Self-generated",
            fullname: "Generate your own customized API key",
        },
        {
            icon: listicon2,
            heading: "System generated",
            fullname: "Have our system generate an API key for you",
        },
    ];

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Modal className='Admin_Modal Api_modal' centered show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title>Choose API Key type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='api_modal_Content'>
                        <ul>
                            {orderdata.map((item, i) => {
                                return (
                                    <li onClick={handleShow} className='d-flex align-items-center' key={i}>
                                        <img src={item.icon} alt='img' />
                                        <p>{item.heading} <span className='font14'>{item.fullname} </span></p>
                                    </li>
                                )
                            })}
                        </ul>
                        <CommonButton className="w-100" title="Confirm" />
                    </div>
                </Modal.Body>
            </Modal>
            <CreateApiModal show={show} handleClose={handleClose} />
        </>
    )
}

export default ApiTypeModal
