import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Admin from '../../Admin'
import TopbarHeading from '../../common/TopbarHeading/TopbarHeading'
import "./AccountStatement.scss"
import Select from "react-select";
import CommonButton from '../../../../common/CommonButton/CommonButton'
import OverviewAssetsTable from '../Overview/OverviewAssetsTable/OverviewAssetsTable'
import StatementChart from './StatementChart/StatementChart'

const AccountStatement = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const options = [
    { value: "Deposit", label: "Deposit" },
    { value: "5555", label: "5555" },
    { value: "999", label: "999" },
  ];
  const timeoptions = [
    { value: "1 minute", label: "1 minute" },
    { value: "10 minute", label: "10 minute" },
    { value: "1 Hour", label: "1 Hour" },
    { value: "1 day", label: "1 day" },
    { value: "3 day", label: "3 day" },
  ];
  const overviewData = [
    {
      name: "Date",
      number: "2023-06-09",
    },
    {
      name: "User ID",
      number: "29852567",
    },
    {
      name: "Wallet",
      number: "All",
    },
  ];
  const totalvalueData = [
    {
      name: "Spot",
      number: "0.94138700 BTC",
      value: "≈ 24,925.86 USDT",
    },
    {
      name: "Funding",
      number: "0.26713700 BTC",
      value: "≈ 14,925.86 USDT",
    },
    {
      name: "Futures",
      number: "-- BTC",
      value: "≈ 0.00 USDT",
    },
    {
      name: "Earn",
      number: "-- BTC",
      value: "≈ 0.00 USDT",
    },
  ];
  return (
    <Admin>
      <div className='Common_TopBar'>
        <Row className='align-items-center'>
          <Col xs={12} xl={8}>
            <TopbarHeading title="Account Statement" />
          </Col>
          <Col xs={12} xl={4} className="d-md-flex justify-content-xl-end">

          </Col>
        </Row>
      </div>
      <div className='Common_admin_Contentarea Account_Statement_Content'>
        <div className='Transaction_History_selectBox d-flex flex-wrap'>
          <div className='common_select'>
            <label>Time</label>
            <Select
              placeholder="1 minute"
              defaultValue={timeoptions}
              onChange={setSelectedOption1}
              options={timeoptions}
              classNamePrefix="react-select"
              value={selectedOption1}
            // menuIsOpen={true}
            />
          </div>
          <div className='common_select'>
            <label>Type</label>
            <Select
              placeholder="Deposit"
              defaultValue={options}
              onChange={setSelectedOption}
              options={options}
              classNamePrefix="react-select"
              value={selectedOption}
            // menuIsOpen={true}
            />
          </div>
          <div className='common_select'>
            <label>Wallet</label>
            <Select
              placeholder="Deposit"
              defaultValue={options}
              onChange={setSelectedOption2}
              options={options}
              classNamePrefix="react-select"
              value={selectedOption2}
            // menuIsOpen={true}
            />
          </div>
          <div className='common_select'>
            <label>Status</label>
            <Select
              placeholder="Deposit"
              defaultValue={options}
              onChange={setSelectedOption3}
              options={options}
              classNamePrefix="react-select"
              value={selectedOption3}
            // menuIsOpen={true}
            />
          </div>
          <CommonButton title="Search" className="Search_btn" />
        </div>

        <div className='Account_Statement_Content_Total'>
          <h3>Overview</h3>

          <ul className='d-flex overview_data mt-4'>
            {overviewData.map((data, i) => {
              return (
                <li key={i} >
                  <span>{data.name}</span>
                  <h5 className='font18 mb-0'>{data.number}</h5>
                </li>
              )
            })}
          </ul>
        </div>

        <div className='Account_Statement_Content_Total'>
          <Row className='align-items-center'>
            <Col xs={12} xl={7}>
              <div className='Account_Statement_Content_Total_Value'>
                <h5 className='font18'>Total value</h5>
                <h3>1.257890435 BTC  <span>≈  $24,567.53</span></h3>
              </div>
              <ul className='d-flex overview_data mt-4'>
                {totalvalueData.map((data, i) => {
                  return (
                    <li key={i}>
                      <h6 className='font16'>Spot</h6>
                      <h5 className='font18 mb-0'>{data.number}</h5>
                      <span>{data.value}</span>
                    </li>
                  )
                })}
              </ul>
            </Col>
            <Col xs={12} xl={5}>
                <StatementChart />
            </Col>
          </Row>
        </div>

        <div className='Account_Statement_Content_Total'>
          <h3>Spot</h3>
          <div className='Account_Statement_Content_Total_Value my-4 pt-2'>
            <h5 className='font18'>Total balance</h5>
            <h3>1.257890435 BTC  <span>≈  $24,567.53</span></h3>
          </div>

          <OverviewAssetsTable />
        </div>
      </div>
    </Admin>
  )
}

export default AccountStatement