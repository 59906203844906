import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Users Info
  supportedChainIds: [],
  tokenList: [],
  slippage: "0.5",
  swapDataFrom: {},
  swapDataTo: {},
  bestRoute: {},
  newTokenList: [],
  loader: false,
  inputValue: "",
  responseApi: false,
  chainTokenDetailsETH: "",
  chainTokenDetailsBSC: "",
  outputValue: "",
  manualSlippage: "",
  isRouteLoading: false,
  userBalance: 0,
  tokenSekectKey: "",
  recipientAddress: "",
  apiResponse: false,
  isParams: false,
  customApiData: [],
  activeKey: false,
  swapModleInitial: false,
  storedChain: "",
  Apidata: {},
  countDown: 0,
};

export const xySlice = createSlice({
  name: "xy_data",
  initialState,
  reducers: {
    saveSupportedChainIds: (state, action) => {
      state.supportedChainIds = action.payload;
    },
    setTokenList: (state, action) => {
      state.tokenList = action.payload;
    },
    setSlippage: (state, action) => {
      state.slippage = action.payload;
    },
    saveSwapDataFrom: (state, action) => {
      state.swapDataFrom = action.payload;
    },
    saveSwapDataTo: (state, action) => {
      state.swapDataTo = action.payload;
    },
    setBestRoute: (state, action) => {
      state.bestRoute = action.payload;
    },
    setNewTokenList: (state, action) => {
      state.newTokenList = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setInputAmount: (state, action) => {
      state.inputValue = action.payload;
    },
    setResponseApi: (state, action) => {
      state.responseApi = action.payload;
    },
    setChainTokenDetails: (state, action) => {
      state.chainTokenDetailsETH = action.payload.chainTokenDetailsETH;
      state.chainTokenDetailsBSC = action.payload.chainTokenDetailsBSC;
    },
    setOutputAmount: (state, action) => {
      state.outputValue = action.payload;
    },

    setIsRouteLoading: (state, action) => {
      state.isRouteLoading = action.payload;
    },
    setUserbalance: (state, action) => {
      state.userBalance = action.payload;
    },
    setTokenSelectedKey: (state, action) => {
      state.tokenSekectKey = action.payload;
    },
    setRecipientAddress: (state, action) => {
      state.recipientAddress = action.payload;
    },
    saveApiResponse: (state, action) => {
      state.apiResponse = action.payload;
    },
    setIsParams: (state, action) => {
      state.isParams = action.payload;
    },
    setCustomApi: (state, action) => {
      state.customApiData = action.payload;
    },
    setActiveKey: (state, action) => {
      state.activeKey = action.payload;
    },
    setswapModuleInitial: (state, action) => {
      state.swapModleInitial = action.payload;
    },

    saveApidata: (state, action) => {
      state.Apidata = action.payload;
    },
    saveStoreChain: (state, action) => {
      state.storedChain = action.payload;
    },
    saveCountDown: (state, action) => {
      state.countDown = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  saveSupportedChainIds,
  setTokenList,
  setSlippage,
  saveSwapDataFrom,
  saveSwapDataTo,
  setBestRoute,
  setNewTokenList,
  setLoader,
  setInputAmount,
  setResponseApi,
  setChainTokenDetails,
  setOutputAmount,
  setIsRouteLoading,
  setUserbalance,
  setTokenSelectedKey,
  setRecipientAddress,
  saveApiResponse,
  reset,
  setIsParams,
  setCustomApi,
  setActiveKey,
  setswapModuleInitial,
  saveApidata,
  saveStoreChain,
  saveCountDown,
} = xySlice.actions;

export default xySlice.reducer;
