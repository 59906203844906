import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import footerLogo from "../../assets/images/FooterLogo.svg";
import "./Footer.scss";
import {
  DiscordIcon,
  FacebookIcon,
  GlobalIcon,
  InstaIcon,
  RedditIcon,
  RightarrowIcon,
  TelegramIcon,
  TiktokIcon,
  TwitterIcon,
} from "../../assets/Svg/SvgImages";
import YoutubeIco from "../../assets/icons/youtube.svg";
import { Accordion, Form } from "react-bootstrap";
import CommonButton from "../CommonButton/CommonButton";

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="Inner-footer">
        <Container className="commn-container">
          <Col xs={12}>
            <div className="Footer_logo">
              {/* <img src={footerLogo} alt="footer-logo" /> */}
            </div>
          </Col>
          <Row>
            <Col lg={8} xl={9} xs={12}>
              <Accordion defaultActiveKey="">
                <Row>
                  <Col lg={3} xs={12}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Exchange</Accordion.Header>
                      <Accordion.Body>
                        <ul className="Inner-footer__list Inner-footer__list--location">
                          <li>
                            <Link to="#">Exchange Home</Link>
                            <Link to="#">Margin Tranding</Link>
                            <Link to="#">Derivatives Trading</Link>
                            <Link to="#">Powerplay</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Col>
                  <Col lg={3} xs={12}>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Support</Accordion.Header>
                      <Accordion.Body>
                        <ul className="Inner-footer__list">
                          <li>
                            {" "}
                            <Link to="#">Request form</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="#">Contact Support</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="#">FAQ</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="#">Security</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Col>
                  <Col lg={3} xs={12}>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Company</Accordion.Header>
                      <Accordion.Body>
                        <ul className="Inner-footer__list">
                          <li>
                            <Link to="#">About us</Link>
                            <Link to="#">Careers</Link>
                            <Link to="#">News</Link>
                            <Link to="#">Security</Link>
                            <Link to="#">Community</Link>
                            <Link to="#">Announcements</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Col>
                  <Col lg={3} xs={12}>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Resources</Accordion.Header>
                      <Accordion.Body>
                        <ul className="Inner-footer__list">
                          <li>
                            <Link to="#">Downloads</Link>
                            <Link to="#">Desktop Aplication</Link>
                            <Link to="#">Buy Crypto</Link>
                            <Link to="#">Referral</Link>
                            <Link to="#">Listing Tranding</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Col>
                </Row>
              </Accordion>
            </Col>
            <Col lg={4} xl={3} xs={12}>
              <div className="Inner-footer__list newsletter_footer">
                <h6>Newsletter</h6>
                <div className="d-flex">
                  <Form.Control
                    className="custom_input"
                    type="text"
                    placeholder="Enter your email"
                  />
                  <CommonButton title="Send" className="ms-1" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer_Copyright">
        <Container>
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="d-md-flex align-items-center justify-content-md-start justify-content-center">
              <p>© {new Date().getFullYear()}, Eterna. All rights reserved</p>
              <div className="footer_Copyright_Links d-flex align-items">
                <Link to="#">Privacy</Link>
                <Link to="#">Terms</Link>
                <Link to="#">Sitemap</Link>
              </div>
            </div>
            <div className="Community_Social">
              <ul className="Inner-footer__list Inner-footer__list--social pe-0 mt-0">
                <li>
                  <div className="Social_Icons">
                    <a href="https://twitter.com/Eterna_Hybrid" target="_blank">
                      <TwitterIcon />
                    </a>
                    <span>Twitter</span>
                  </div>
                  <div className="Social_Icons">
                    <a href="https://t.me/eterna_hybrid" target="_blank">
                      <TelegramIcon />
                    </a>
                    <span>Telegram</span>
                  </div>
                  <div className="Social_Icons">
                    <a href="https://medium.com/@Eterna_Hybrid" target="_blank">
                      <DiscordIcon />
                    </a>
                    <span>Discord</span>
                  </div>
                  <div className="Social_Icons">
                    <a href="#" target="_blank">
                      <RedditIcon />
                    </a>
                    <span>Reddit</span>
                  </div>
                  <div className="Social_Icons">
                    <a href="#" target="_blank">
                      <img src={YoutubeIco} alt="youtube" />
                    </a>
                    <span>YouTube</span>
                  </div>
                  <div className="Social_Icons">
                    <a href="#" target="_blank">
                      <TiktokIcon />
                    </a>
                    <span>TikTok</span>
                  </div>
                  <div className="Social_Icons">
                    <a
                      href="https://instagram.com/eterna__hybrid?igshid=YmMyMTA2M2Y="
                      target="_blank"
                    >
                      <InstaIcon />
                    </a>
                    <span>Instagram</span>
                  </div>
                  {/* <a
                      href="https://www.facebook.com/EternaHybridExchange/"
                      target="_blank"
                    >
                      <FacebookIcon />
                    </a> */}
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
