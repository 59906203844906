import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ExFooter.scss";
import { ChatDotIcon, CrownIcon } from "../../assets/Svg/SvgImages";
import ChatRoom from "../ChatRoom/ChatRoom";

const ExFooter = ({ className }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className={`ExFooter ${className}`}>
        <Container fluid px-0>
          <Row className="align-items-center">
            <Col md={3}>
              <p className="Stable_Connection"><span></span> Stable connection</p>
            </Col>
            <Col md={9}>
              <div className="Exchange_footer_links">
                <Link to="#"><span><ChatDotIcon /></span> Terms and Conditions</Link>
                <Link to="#"><span><CrownIcon /></span> Leaderboards</Link>
                <Link onClick={handleShow} to="#"><span><ChatDotIcon /></span> Chat Room</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ChatRoom show={show} handleClose={handleClose} />
    </>
  );
};

export default ExFooter
