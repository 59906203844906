import { Tab, Tabs, Form } from "react-bootstrap";
import CustomTooltip from "../../../common/StakingComponents/CustomTooltip/CustomTooltip";
import CustomInput from "../../../common/StakingComponents/CustomInput/CustomInput";
import CustomButton from "../../../common/StakingComponents/CustomButton/CustomButton";
import eth from "../../../assets/icons/eth.svg";
import plus from "../../../assets/icons/plus.svg";
import ehx from "../../../assets/icons/ehx.svg";
import lpIcon from "../../../assets/images/lp_icon.svg";
import "./StakingTabs.scss";
import { useDispatch, useSelector } from "react-redux";
import { toastMsg } from "../../../common/Toast/Toast";
import {
  approve,
  LpStakeDeposit,
  LPstakeDeposit,
  LPstakeWithdraw,
  stakeDeposit,
  stakeWithdraw,
  withdrawAndSplitUnstakedToken,
  WithdrawUnstakedTokensFunction,
} from "../services/ContractServices/ContractSendService/ContractSendService";
import { useEffect, useRef, useState } from "react";
import {
  amountWithdrawableFunction,
  balanceOfForReserveFunction,
  balanceOfFunction,
  getTotalSupplyLP,
  getUserLpToken,
  soloholderUnlockTime,
  stakeToken,
  TokenAllowance,
} from "../services/ContractServices/ContractCallService/ContractCallservices";
import walletServices, {
  callWeb3,
  ChangeNetwork,
  getAccountBalance,
  getChainid,
} from "../services/walletServices";
// import {
//   LPstake,
//   setUserTab,
//   totalstakeEXH,
// } from "../../../redux/_slices/defiUser.slice";
import {
  actualValue,
  fixAndConvertFunction,
  toCustomFixedFunction,
  toFixedNumber,
  toFunctionBigNumber,
  valueWithDollarFormat,
} from "../services/HelperServices";
import moment from "moment";
import Countdown from "react-countdown";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { CHAIN_ID } from "../constants/constants";
import { toFixed } from "../services/ContractServices/ContractHelper";
import { setUserTab, totalstakeEXH } from "../../../redux/_slices/user.slice";
import Web3 from "web3";
const localeStringFunction = (value) => {
  return value.toLocaleString("fullwide", {
    useGrouping: !1,
  });
};

const tokenKeys = ["REWARDTOKEN", "USDCTOKEN", "LPTOKEN", "SOLOSTAKE", "PAIR"];
const stakeKeys = ["SOLOSTAKE", "LPSTAKE"];

const StakingTabs = (props) => {
  const { walletAddress, wallet } = useSelector((state) => state.user);
  const userTab = useSelector((state) => state?.user?.usertab);
  // const { walletProvider } = useWeb3ModalProvider();

  const [amount, setAmount] = useState("");
  const [comp, setComp] = useState(1);
  const [StakeAmterror, setStakeAmterror] = useState(false);
  const [UnStakeAmterror, setUnStakeAmterror] = useState(false);
  const [LPStakeAmterror, setLPStakeAmterror] = useState(false);
  const [LPUnStakeAmterror, setLPUnStakeAmterror] = useState(false);
  const [EXH, setEXH] = useState(0);
  const [stakeEXH, setStakeEXH] = useState(0);
  const [setLPtoken, setLP] = useState(0);
  const [stakeLP, setLptakeLP] = useState("");
  const [ETHwallet, setETHbalance] = useState(0);
  const [key, setKey] = useState("stakeehx");
  const [unStakedLpAmount, setUnStakedLpAmount] = useState(0);
  const [ethAmount, setEthAmount] = useState(0);
  const [UnstakeEHX, setUnstakeEHX] = useState(0);
  const [maxState, setMaxState] = useState(false);
  const [isWithdrawable, setIsWithdrawable] = useState(false);
  const [isCollectable, setIsCollectable] = useState(false);
  const [isCollectableLp, setIsCollectableLp] = useState(false);
  const [miniFinalEHX, setMiniFinalEHX] = useState(0);
  const [miniFinalETH, setMiniFinalETH] = useState(0);
  const [LpToken, setLpToken] = useState(0);
  const [timerUnstake, setTimerUnstake] = useState(0);
  const [timerUnstakeLP, setTimerUnstakeLP] = useState(0);
  const [chainConfirmation, setChainConfirmation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [collectDisable, setCollectDisable] = useState(false);
  const [collectDisableLP, setCollectDisableLP] = useState(false);
  const [collectDisableLPStake, setCollectDisableLPStake] = useState(false);
  const [modalData, setModalData] = useState({
    approval: false,
    approvalError: false,
    mainFunction: false,
    status: "",
    message: "",
    transactionHash: "",
    error: false,
    sendFunction: "",
  });

  const confirmationModalRef = useRef();

  const dispatch = useDispatch();

  const [reserves, setReserves] = useState({
    EHXReserve: 0,
    WETHReserve: 0,
  });
  useEffect(() => {
    console.log("my wallet address", walletAddress);
    if (walletAddress) {
      getBalance();
      getWithdrawFunction();
      getReservesFunction();
      dispatch(setUserTab("stakeehx"));
    } else {
      setStakeEXH(0);
      setEXH(0);
      setLP(0);
      setLptakeLP(0);
      setETHbalance(0);
      setUnstakeEHX(0);
      dispatch(totalstakeEXH(0));
    }
  }, [walletAddress]);

  const handleModalClose = (value) => {
    setShowModal(value);
  };

  const handleInputdata = (e) => {
    const re = /^(?:\d{0,16}(?:\.\d{0,16})?|\.\d{1,16})$/;
    setMaxState(false);
    setStakeAmterror(false);
    setUnStakeAmterror(false);
    setLPStakeAmterror(false);
    setLPUnStakeAmterror(false);
    let data;
    if (re.test(e.target.value)) {
      data = e.target.value;
      setAmount(data);
    }
    let outputETHpre = localeStringFunction(
      Number(reserves?.WETHReserve) * Number(data)
    );
    let outputEth = (
      Number(outputETHpre) / Number(reserves?.EHXReserve)
    ).toFixed(8);
    setEthAmount(outputEth);
  };

  const handleETHdata = (e) => {
    setStakeAmterror(false);
    setUnStakeAmterror(false);
    let data = e.target.value;
    setEthAmount(data);
  };
  const stakeTab = (e, value) => {
    e.preventDefault();
    setStakeAmterror(false);
    setUnStakeAmterror(false);
    setComp(value);
    props.CompKeys(value);
  };

  //Solo_Stake Deposit Function Calling

  const handleDeposit = async (e) => {
    try {
      e.preventDefault();
      amount == 0.0 || amount == ""
        ? setStakeAmterror(true)
        : setStakeAmterror(false);
      if (
        walletAddress &&
        amount != "" &&
        amount != 0.0 &&
        Number(amount) <= Number(EXH)
      ) {
        const allowance = await TokenAllowance(
          walletAddress,
          tokenKeys[0],
          stakeKeys[0]
        );
        setChainConfirmation(true);
        setShowModal(true);
        setModalData({
          approval: Number(allowance) || allowance != 0 ? true : false,
          status: "",
          message: "",
          transactionHash: "",
        });
        if (!allowance || allowance < localeStringFunction(amount * 10 ** 18)) {
          const approveContract = await approve(
            walletAddress,
            tokenKeys[0],
            stakeKeys[0],
            amount
          );
          if (approveContract?.status) {
            setModalData({
              approval: true,
              mainFunction: false,
              status: approveContract?.status,
              transactionHash: "",
              sendFunction: handleDeposit,
            });

            const allowance = await TokenAllowance(
              walletAddress,
              tokenKeys[0],
              stakeKeys[0]
            );
            if (allowance <= localeStringFunction(amount * 10 ** 18)) {
              const res = await stakeDeposit(
                walletAddress,
                amount,
                1,
                stakeKeys[0]
              );
              if (res?.status) {
                setModalData({
                  approval: true,
                  mainFunction: true,
                  transactionHash: res?.transactionHash,
                  sendFunction: handleDeposit,
                });
                getBalance();
                getWithdrawFunction();
                props.totalStakeinPlatform();
                setAmount("");
                toastMsg.success("Your EHX is staked");
              } else {
                setModalData({
                  approval: true,
                  mainFunction: false,
                  transactionHash: "",
                  error: true,
                  sendFunction: handleDeposit,
                });
              }
            } else {
              setModalData({
                approval: true,
                mainFunction: false,
                transactionHash: "",
                error: true,
                sendFunction: handleDeposit,
              });
              toastMsg.error("Insufficient Approval for transfer Tokens!");
            }
          } else {
            setModalData({
              // approval: false,
              approvalError: true,
              mainFunction: false,
              transactionHash: "",
              error: true,
              sendFunction: handleDeposit,
            });
          }
        } else {
          setModalData({
            approval: true,
            status: "",
            message: "",
            transactionHash: "",
          });
          const res = await stakeDeposit(
            walletAddress,
            amount,
            1,
            stakeKeys[0]
          );
          if (res?.status) {
            setModalData({
              approval: true,
              mainFunction: true,
              transactionHash: res?.transactionHash,
            });
            getBalance();
            getWithdrawFunction();
            props.totalStakeinPlatform();
            setAmount("");
            toastMsg.success("Your EHX is staked");
          } else {
            setModalData({
              approval: true,
              mainFunction: false,
              transactionHash: "",
              error: true,
              sendFunction: handleDeposit,
            });
          }
        }
      } else {
        // console.log("amount > EXH", amount, EXH);
        !walletAddress
          ? toastMsg.info("Please connect your wallet to continue")
          : Number(amount) > Number(EXH)
          ? toastMsg.error("Transfer amount exceeds balance")
          : setStakeAmterror(true);
      }
    } catch (error) {
      setModalData({
        approval: true,
        mainFunction: false,
        transactionHash: "",
        error: true,
        sendFunction: handleDeposit,
      });
    }
  };

  //SOLO_UNstake Function Calling

  const handleWithdraw = async (e) => {
    try {
      e.preventDefault();
      amount === 0.0 || ""
        ? setUnStakeAmterror(true)
        : setUnStakeAmterror(false);
      if (
        walletAddress &&
        amount !== "" &&
        amount != 0.0 &&
        Number(amount) <= Number(stakeEXH)
      ) {
        setChainConfirmation(true);
        setShowModal(true);

        setModalData({
          approval: true,
          status: "",
          message: "",
          transactionHash: "",
        });
        const res = await stakeWithdraw(walletAddress, amount, stakeKeys[0]);
        if (res.status) {
          setModalData({
            approval: true,
            mainFunction: true,
            transactionHash: res?.transactionHash,
            error: false,
          });
          getBalance();
          getWithdrawFunction();
          props.totalStakeinPlatform();
          setAmount("");
          toastMsg.success("Your EHX is Unstaked");
        } else {
          setModalData({
            approval: true,
            mainFunction: false,
            transactionHash: "",
            error: true,
            sendFunction: handleWithdraw,
          });
        }
      } else {
        !walletAddress
          ? toastMsg.info("Please connect your wallet to continue")
          : Number(amount) > Number(stakeEXH)
          ? toastMsg.error("Your entered amount is more than the staked amount")
          : setUnStakeAmterror(true);
      }
    } catch (error) {
      setModalData({
        approval: true,
        mainFunction: false,
        transactionHash: "",
        error: true,
        sendFunction: handleWithdraw,
      });
    }
  };

  // LP_Stake Deposit Function Call

  const LPDeposit = async (e) => {
    try {
      e.preventDefault();
      amount === "" ? setLPStakeAmterror(true) : setLPStakeAmterror(false);
      if (walletAddress && amount !== "") {
        let percentAmountEXH = (amount * 98) / 100;
        let preEthAmount = ((ethAmount * 98) / 100)?.toString();
        let indeOfPer = preEthAmount?.indexOf(".") + 10;
        let percentAmountETH = preEthAmount?.slice(0, indeOfPer);
        // console.log("testpercent", percentAmountETH, preEthAmount);
        const allowance = await TokenAllowance(
          walletAddress,
          tokenKeys[0],
          stakeKeys[1]
        );
        setChainConfirmation(true);
        setShowModal(true);
        // console.log("alltestpercentowance", allowance);
        setModalData({
          approval: Number(allowance) || allowance != 0 ? true : false,
          status: "",
          message: "",
          transactionHash: "",
        });
        if (!allowance || allowance == 0) {
          const approveContract = await approve(
            walletAddress,
            tokenKeys[0],
            stakeKeys[1]
          );
          if (approveContract?.status) {
            setModalData({
              approval: true,
              mainFunction: false,
              status: approveContract?.status,
              transactionHash: "",
            });
            const res = await LPstakeDeposit(
              walletAddress,
              amount,
              Number(percentAmountEXH),
              Number(percentAmountETH),
              ethAmount
            );
            if (res?.status) {
              setModalData({
                approval: true,
                mainFunction: true,
                transactionHash: res?.transactionHash,
              });

              getBalance();
              getWithdrawFunction();
              setAmount("");
              setEthAmount("");
              toastMsg.success("Your LP is staked");
            } else {
              setModalData({
                approval: true,
                mainFunction: false,
                transactionHash: "",
                error: true,
                sendFunction: LPDeposit,
              });
            }
          } else {
            setModalData({
              approvalError: true,
              mainFunction: false,
              transactionHash: "",
              error: true,
              sendFunction: LPDeposit,
            });
          }
        } else {
          const res = await LPstakeDeposit(
            walletAddress,
            amount,
            Number(percentAmountEXH),
            Number(percentAmountETH),
            ethAmount
          );
          if (res?.status) {
            setModalData({
              approval: true,
              mainFunction: true,
              transactionHash: res?.transactionHash,
            });

            getBalance();
            getWithdrawFunction();
            setAmount("");
            setEthAmount("");
            toastMsg.success("Your LP is staked");
          } else {
            setModalData({
              approval: true,
              mainFunction: false,
              transactionHash: "",
              error: true,
              sendFunction: LPDeposit,
            });
          }
        }
      } else {
        !walletAddress
          ? toastMsg.info("Please connect your wallet to continue")
          : setLPStakeAmterror(true);
      }
    } catch (error) {
      setModalData({
        mainFunction: false,
        transactionHash: "",
        error: true,
        sendFunction: LPDeposit,
      });
    }
  };

  //WithdrawAndSplit Unstaked Token function calling

  const withdrawAndSplitUnstakedTokenFunction = async (e) => {
    try {
      e.preventDefault();
      if (walletAddress) {
        setChainConfirmation(true);
        setShowModal(true);
        setModalData({
          approval: true,
          status: "",
          message: "",
          transactionHash: "",
        });
        const res = await withdrawAndSplitUnstakedToken(walletAddress, 0, 0);
        if (res?.status) {
          setModalData({
            approval: true,
            mainFunction: true,
            transactionHash: res?.transactionHash,
          });
          getBalance();
          getWithdrawFunction();
          setAmount("");
          setEthAmount("");
          toastMsg.success("Your LP is staked");
        } else {
          setModalData({
            approval: true,
            mainFunction: false,
            transactionHash: "",
            error: true,
            sendFunction: withdrawAndSplitUnstakedTokenFunction,
          });
        }
      } else {
        !walletAddress
          ? toastMsg.info("Please connect your wallet to continue")
          : setLPStakeAmterror(true);
      }
    } catch (error) {
      setModalData({
        mainFunction: false,
        transactionHash: "",
        error: true,
        sendFunction: withdrawAndSplitUnstakedTokenFunction,
      });
    }
  };

  //LP_Add_UNstake Function Call

  const LPWithdraw = async (e) => {
    try {
      e.preventDefault();
      amount === "" ? setLPUnStakeAmterror(true) : setLPUnStakeAmterror(false);
      if (walletAddress && amount !== "") {
        setChainConfirmation(true);
        setShowModal(true);
        setModalData({
          approval: true,
          status: "",
          message: "",
          transactionHash: "",
        });
        const res = await LPstakeWithdraw(walletAddress, amount);

        if (res?.status) {
          setModalData({
            approval: true,
            mainFunction: true,
            transactionHash: res?.transactionHash,
          });
          getBalance();
          getWithdrawFunction();
          setAmount("");
          setEthAmount("");
          toastMsg.success("Your LP is Unstaked");
        } else {
          setModalData({
            approval: true,
            mainFunction: false,
            transactionHash: "",
            error: true,
            sendFunction: LPWithdraw,
          });
        }
      } else {
        !walletAddress
          ? toastMsg.info("Please connect your wallet to continue")
          : setLPUnStakeAmterror(true);
      }
    } catch (error) {
      setAmount("");
      setEthAmount("");
      setModalData({
        mainFunction: false,
        transactionHash: "",
        error: true,
        sendFunction: LPWithdraw,
      });
    }
  };

  // console.log("firsccccccccct", toFixed(340659188391345060000000));

  const getBalance = async () => {
    const res = await balanceOfFunction(walletAddress, tokenKeys[0]);
    setEXH(toFixedNumber(res));

    const userInfo = await stakeToken(walletAddress, stakeKeys[0]);
    dispatch(totalstakeEXH(toFixedNumber(Number(userInfo?.amount))));
    setStakeEXH(toFixedNumber(Number(userInfo?.amount)));

    const unstakeEhx = await stakeToken(walletAddress, stakeKeys[0]);
    setTimerUnstake(Number(unstakeEhx?.unstakeUnlock) * 1000);

    setUnstakeEHX(toFixedNumber(Number(unstakeEhx?.unstakeAmount)));
    const holderunlocktime = await soloholderUnlockTime(walletAddress);
    const date = new Date();
    let ts = moment(date).unix();
    let unixCurrentDate = moment.unix(ts);

    if (Number(unstakeEhx?.unstakeUnlock) <= unixCurrentDate?._i / 1000) {
      setIsCollectable(true);
    }
    if (Number(holderunlocktime) <= unixCurrentDate?._i / 1000) {
      setIsWithdrawable(true);
    }

    const metamaskbalance = await getAccountBalance(walletAddress);
    console.log("metamaskbalance", metamaskbalance);

    setETHbalance(Number(metamaskbalance)?.toFixed(4));
  };

  // ======================================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const getWithdrawFunction = async () => {
    // const walletBalanceLPresult = await balanceOfFunction(
    //   walletAddress,
    //   tokenKeys[4]
    // );
    // setLP(fixAndConvertFunction(walletBalanceLPresult));
    // const stakedLpTokens = await getUserLpToken(walletAddress);
    // const LpTokenBalance = await balanceOfFunction(walletAddress, tokenKeys[4]);
    // setLpToken(fixAndConvertFunction(LpTokenBalance));
    // const stakeLPTokenresult = await getUserLpToken(
    //   walletAddress,
    //   stakeKeys[1]
    // );
    // dispatch(LPstake(fixAndConvertFunction(stakeLPTokenresult?.amount)));
    // const unstakeEhxLP = await stakeToken(walletAddress, stakeKeys[1]);
    // setTimerUnstakeLP(Number(unstakeEhxLP?.unstakeUnlock) * 1000);
    // setLptakeLP(fixAndConvertFunction(unstakeEhxLP?.amount));
    // setUnStakedLpAmount(fixAndConvertFunction(unstakeEhxLP?.unstakeAmount));
    // const unstaketokenLp = await stakeToken(walletAddress, stakeKeys[1]);
    // const ehxReserve = await balanceOfForReserveFunction("REWARDTOKEN");
    // const wethReserve = await balanceOfForReserveFunction("WETH");
    // const totalSupply = await getTotalSupplyLP();
    // let unstakeAmount = localeStringFunction(unstaketokenLp?.unstakeAmount);
    // let reservesEHX = localeStringFunction(ehxReserve);
    // let reservesETH = localeStringFunction(wethReserve);
    // let valueEHX = unstakeAmount * reservesEHX;
    // setMiniFinalEHX(((valueEHX / totalSupply) * 98) / 100);
    // let valueETH = unstakeAmount * reservesETH;
    // setMiniFinalETH(((valueETH / totalSupply) * 98) / 100);
    // const date = new Date();
    // let ts = moment(date).unix();
    // let unixCurrentDate = moment.unix(ts);
    // if (Number(unstaketokenLp?.unstakeUnlock) <= unixCurrentDate?._i / 1000) {
    //   setIsCollectableLp(true);
    // }
    // const withdrawable_amount = await amountWithdrawableFunction(walletAddress);
  };

  const getReservesFunction = async () => {
    // const ehxReserve = await balanceOfForReserveFunction("REWARDTOKEN");
    // const wethReserve = await balanceOfForReserveFunction("WETH");
    // setReserves({
    //   EHXReserve: ehxReserve,
    //   WETHReserve: wethReserve,
    // });
  };

  // MAX Function Calling

  const setMaxFunction = async (value) => {
    setMaxState(value);
    if (value && comp == 1) {
      setAmount(EXH);
    } else if (value && comp == 2) {
      setAmount(stakeEXH);
    }
  };

  const LpStakeMaxFunction = (value) => {
    if (value && comp == 1) {
      setAmount(LpToken);
    } else if (value && comp == 2) {
      setAmount(stakeLP);
    }
  };

  //LpStake Deposit function

  const LpStakeDepositFunction = async (e) => {
    try {
      e.preventDefault();
      if (walletAddress) {
        const allowance = await TokenAllowance(
          walletAddress,
          tokenKeys[4],
          stakeKeys[1]
        );
        setChainConfirmation(true);
        setShowModal(true);
        setModalData({
          approval: Number(allowance) || Number(allowance) != 0 ? true : false,
          status: "",
          message: "",
          transactionHash: "",
        });
        if (!allowance || Number(allowance) == 0) {
          const approveContract = await approve(
            walletAddress,
            tokenKeys[4],
            stakeKeys[1]
          );
          if (approveContract?.status) {
            setModalData({
              approval: true,
              mainFunction: false,
              status: approveContract?.status,
              transactionHash: "",
            });
            const res = await LpStakeDeposit(walletAddress, amount);

            if (res?.status) {
              setModalData({
                approval: true,
                mainFunction: true,
                transactionHash: res?.transactionHash,
              });

              getBalance();
              getWithdrawFunction();
              setAmount("");
              setEthAmount("");
              toastMsg.success("Your LP is staked");
            } else {
              setModalData({
                approval: true,
                mainFunction: false,
                transactionHash: "",
                error: true,
              });
            }
          } else {
            setModalData({
              approvalError: true,
              approval: false,
              mainFunction: false,
              transactionHash: "",
              error: true,
              sendFunction: LpStakeDepositFunction,
            });
          }
        } else {
          const res = await LpStakeDeposit(walletAddress, amount);
          if (res?.status) {
            setModalData({
              approval: true,
              mainFunction: true,
              transactionHash: res?.transactionHash,
            });
            getBalance();
            getWithdrawFunction();
            setAmount("");
            setEthAmount("");
            toastMsg.success("Your LP is staked");
          } else {
            setModalData({
              approval: true,
              mainFunction: false,
              transactionHash: "",
              error: true,
              sendFunction: LpStakeDepositFunction,
            });
          }
        }
      } else {
        !walletAddress
          ? toastMsg.info("Please connect your wallet to continue")
          : setLPUnStakeAmterror(true);
      }
    } catch (error) {
      setModalData({
        mainFunction: false,
        transactionHash: "",
        error: true,
        sendFunction: LpStakeDepositFunction,
      });
    }
  };

  useEffect(() => {
    setAmount("");
    setEthAmount("");
  }, [comp, userTab]);

  //collect unstakeUnlock_Solo Function Calling

  const CollectUnstakeUnlock = async (e) => {
    try {
      e.preventDefault();
      if (walletAddress) {
        setChainConfirmation(true);
        setShowModal(true);
        setModalData({
          approval: true,
          status: "",
          message: "",
          transactionHash: "",
        });
        const withdrawUnstakeCollect = await WithdrawUnstakedTokensFunction(
          walletAddress,
          stakeKeys[0]
        );
        if (withdrawUnstakeCollect?.status) {
          setModalData({
            approval: true,
            mainFunction: true,
            transactionHash: withdrawUnstakeCollect?.transactionHash,
          });
          getBalance();
          getWithdrawFunction();
          toastMsg.success("Your EHX is Collected");
        } else {
          setModalData({
            approval: true,
            mainFunction: false,
            transactionHash: "",
            error: true,
            sendFunction: CollectUnstakeUnlock,
          });
        }
      } else {
        toastMsg.info("Please connect your wallet to continue");
      }
    } catch (error) {
      setModalData({
        mainFunction: false,
        transactionHash: "",
        error: true,
        sendFunction: CollectUnstakeUnlock,
      });
    }
  };

  //collect unstakeUnlock_LP

  const CollectUnstakeUnlockLPStake = async (e) => {
    try {
      e.preventDefault();
      if (walletAddress) {
        setChainConfirmation(true);
        setShowModal(true);
        setModalData({
          approval: true,
          status: "",
          message: "",
          transactionHash: "",
        });
        const withdrawUnstakeCollect = await WithdrawUnstakedTokensFunction(
          walletAddress,
          stakeKeys[1]
        );
        if (withdrawUnstakeCollect?.status) {
          setModalData({
            approval: true,
            mainFunction: true,
            transactionHash: withdrawUnstakeCollect?.transactionHash,
          });

          getBalance();
          getWithdrawFunction();
          toastMsg.success("Your LP is Collected");
        } else {
          setModalData({
            approval: true,
            mainFunction: false,
            transactionHash: "",
            error: true,
            sendFunction: CollectUnstakeUnlockLPStake,
          });
        }
      } else {
        toastMsg.info("Please connect your wallet to continue");
      }
    } catch (error) {
      setModalData({
        mainFunction: false,
        transactionHash: "",
        error: true,
        sendFunction: CollectUnstakeUnlockLPStake,
      });
    }
  };

  const handleKeyDown = (e) => {
    const { data } = e;
    if (data === "+" || data === "-") {
      e.preventDefault();
    }
  };

  return (
    <Tabs
      className="staking-tabs"
      transition={false}
      defaultActiveKey="stakeehx"
      id="uncontrolled-tab-example"
      activeKey={key}
      onSelect={(k) => {
        // console.log("ksssdd", k);
        setKey(k);
        dispatch(setUserTab(k));
      }}
    >
      <Tab eventKey="stakeehx" title="Stake EHX">
        <div className="staking-card">
          <Form>
            {chainConfirmation && (
              <ConfirmationModal
                ref={confirmationModalRef}
                modalData={modalData}
                showModal={showModal}
                handleModalClose={handleModalClose}
                comp={comp}
              />
            )}

            <div className="d-flex justify-content-between align-items-center mb-30">
              <div className="stake-btns">
                <CustomButton
                  className={`grey-btn ${comp == 1 ? "active" : null}`}
                  title="Stake"
                  onClick={(e) => stakeTab(e, 1)}
                />
                <CustomButton
                  className={`grey-btn ${comp == 2 ? "active" : null}`}
                  title="Unstake"
                  onClick={(e) => stakeTab(e, 2)}
                />
              </div>
              <CustomTooltip
                content="Once you initiate withdrawal,there is a 7-day waiting period until you can collect your tokens.
              During that wait period. you will receive no staking rewards or profit-shares."
              />
            </div>
            {comp == 2 && (
              <div className="time_Vesting">
                <p>
                  Time until vesting :
                  {timerUnstake ? (
                    <span>
                      <Countdown
                        date={timerUnstake}
                        intervalDelay={0}
                        key={timerUnstake}
                        renderer={({
                          days,
                          hours,
                          minutes,
                          seconds,
                          completed,
                        }) => {
                          if (completed) {
                            setCollectDisable(completed);
                          } else if (collectDisable && !completed) {
                            setCollectDisable(completed);
                          }
                          return (
                            <span>
                              {days || "00"}:{hours || "00"}:{minutes || "00"}:
                              {seconds || "00"}
                            </span>
                          );
                        }}
                      />
                    </span>
                  ) : (
                    <span>
                      {"00"}:{"00"}:{"00"}:{"00"}
                    </span>
                  )}
                </p>
              </div>
            )}
            <CustomInput
              value={amount}
              icon={ehx}
              // coin="0 EHX"
              className="mb-30"
              onChange={handleInputdata}
              handleKeyDown={handleKeyDown}
              max={true}
              setMaxFunction={setMaxFunction}
              dollarValue={true}
            />
            <div>
              {StakeAmterror == true ? (
                <p className="text-danger">
                  Stake Amount field can not be Blank or Zero.
                </p>
              ) : UnStakeAmterror == true ? (
                <p className="text-danger">
                  {" "}
                  Unstake Amount field can not be Blank or Zero.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="staking-card__btns staking-card__btns--single">
              <CustomButton
                title="Proceed"
                onClick={(e) =>
                  comp == 1
                    ? handleDeposit(e)
                    : comp == 2 && isWithdrawable
                    ? handleWithdraw(e)
                    : null
                }
                disabled={comp == 2 && !isWithdrawable}
              />
              {comp == 2 && (
                <CustomButton
                  title={`Collect ${UnstakeEHX} EHX`}
                  onClick={(e) =>
                    isCollectable && Number(UnstakeEHX) > 0
                      ? CollectUnstakeUnlock(e)
                      : e.preventDefault()
                  }
                  disabled={!collectDisable || Number(UnstakeEHX) <= 0}
                />
              )}
            </div>
            <ul className="staking-info__list">
              <li title={toCustomFixedFunction(EXH, 8) || "0.00"}>
                EHX in wallet:
                <span>
                  {valueWithDollarFormat(toFunctionBigNumber(EXH)) || "0.00"}
                </span>
              </li>
              <li>
                Your Staked EHX:
                <span title={toCustomFixedFunction(stakeEXH, 8) || "0.00"}>
                  {valueWithDollarFormat(toFunctionBigNumber(stakeEXH)) ||
                    "0.00"}
                </span>
              </li>
            </ul>
          </Form>
        </div>
      </Tab>
      <Tab eventKey="addlp" title="Add LP">
        <div className="staking-card">
          <Form>
            <div className="d-flex justify-content-between align-items-center mb-30">
              <div className="stake-btns">
                <CustomButton
                  className={`grey-btn ${comp == 1 ? "active" : null}`}
                  title="Stake"
                  onClick={(e) => stakeTab(e, 1)}
                />
                <CustomButton
                  className={`grey-btn ${comp == 2 ? "active" : null}`}
                  title="Unstake"
                  onClick={(e) => stakeTab(e, 2)}
                />
              </div>
              <CustomTooltip
                content="Once you initiate withdrawal,there is a 7-day waiting period until you can collect your tokens.
              During that wait period. you will receive no staking rewards or profit-shares."
              />
            </div>
            {comp == 2 && (
              <div className="time_Vesting">
                <p>
                  Time to Collect :
                  {timerUnstakeLP ? (
                    <span>
                      <Countdown
                        date={timerUnstakeLP}
                        intervalDelay={0}
                        key={timerUnstakeLP}
                        renderer={({
                          days,
                          hours,
                          minutes,
                          seconds,
                          completed,
                        }) => {
                          if (completed) {
                            setCollectDisableLP(completed);
                          } else if (collectDisableLP && !completed) {
                            setCollectDisableLP(completed);
                          }
                          return (
                            <span>
                              {days || "00"}:{hours || "00"}:{minutes || "00"}:
                              {seconds || "00"}
                            </span>
                          );
                        }}
                      />
                    </span>
                  ) : (
                    <span>
                      {"00"}:{"00"}:{"00"}:{"00"}
                    </span>
                  )}
                </p>
              </div>
            )}

            <CustomInput
              value={amount}
              icon={ehx}
              icon2={eth}
              comp={comp}
              userTab={userTab}
              className="mb-30"
              onChange={handleInputdata}
              max={true}
              setMaxFunction={setMaxFunction}
            />
            {comp == 1 && (
              <>
                <div className="d-flex align-items-center justify-content-center my-4">
                  <img src={plus} alt="plus-icon" />
                </div>
                <CustomInput
                  value={ethAmount}
                  icon={eth}
                  className="mb-30"
                  onChange={handleETHdata}
                  max={true}
                />
              </>
            )}
            <div>
              {LPStakeAmterror == true ? (
                <p className="text-danger">
                  Stake Amount field can not be Blank.
                </p>
              ) : LPUnStakeAmterror == true ? (
                <p className="text-danger">
                  {" "}
                  Unstake Amount field can not be Blank.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="staking-card__btns staking-card__btns--single d-flex">
              <CustomButton
                title="Proceed"
                onClick={
                  (e) => e.preventDefault()

                  // (comp == 1 ? LPDeposit(e) : LPWithdraw(e))
                }
              />
              {isCollectableLp && comp == 2 && (
                <CustomButton
                  title="Collect EHX + ETH"
                  onClick={
                    (e) => e.preventDefault()

                    // withdrawAndSplitUnstakedTokenFunction(e)
                  }
                  disabled={!collectDisableLP}
                />
              )}
            </div>

            <ul className="staking-info__list">
              <li>
                EHX in wallet:
                <span>{EXH || "0.00"}</span>
              </li>
              <li>
                ETH in wallet:
                <span>{ETHwallet || "0.00"}</span>
              </li>
              {/* <li>
                EHX LP Token in wallet:
                <span>{setLPtoken || "0.00"}</span>
              </li> */}
              <li>
                Your Staked LP Tokens:
                <span>{stakeLP || "0.00"}</span>
              </li>
              <li>
                Earned to date:
                <span>{unStakedLpAmount || "0.00"}</span>
              </li>
            </ul>
          </Form>
        </div>
      </Tab>
      <Tab eventKey="stakelp" title="Stake LP">
        <div className="staking-card">
          <Form>
            <div className="d-flex justify-content-between align-items-center mb-30">
              <div className="stake-btns">
                <CustomButton
                  className={`grey-btn ${comp == 1 ? "active" : null}`}
                  title="Stake"
                  onClick={(e) => stakeTab(e, 1)}
                />
                <CustomButton
                  className={`grey-btn ${comp == 2 ? "active" : null}`}
                  title="Unstake"
                  onClick={(e) => stakeTab(e, 2)}
                />
              </div>
              <CustomTooltip
                content="Once you initiate withdrawal,there is a 7-day waiting period until you can collect your tokens.
              During that wait period. you will receive no staking rewards or profit-shares."
              />
            </div>
            {comp == 2 && (
              <div className="time_Vesting">
                <p>
                  Time to Collect :
                  {timerUnstakeLP ? (
                    <span>
                      <Countdown
                        date={timerUnstakeLP}
                        intervalDelay={0}
                        key={timerUnstakeLP}
                        renderer={({
                          days,
                          hours,
                          minutes,
                          seconds,
                          completed,
                        }) => {
                          if (completed) {
                            setCollectDisableLPStake(completed);
                          } else if (collectDisableLPStake && !completed) {
                            setCollectDisableLPStake(completed);
                          }
                          return (
                            <span>
                              {days || "00"}:{hours || "00"}:{minutes || "00"}:
                              {seconds || "00"}
                            </span>
                          );
                        }}
                      />
                    </span>
                  ) : (
                    <span>
                      {"00"}:{"00"}:{"00"}:{"00"}
                    </span>
                  )}
                </p>
              </div>
            )}

            <CustomInput
              value={amount}
              icon={lpIcon}
              // coin="0 EHX"
              className="mb-30"
              onChange={handleInputdata}
              max={true}
              setMaxFunction={LpStakeMaxFunction}
            />

            <div className="staking-card__btns staking-card__btns--single">
              <CustomButton
                title="Proceed"
                onClick={
                  (e) => e.preventDefault()

                  // comp == 1 ? LpStakeDepositFunction(e) : LPWithdraw(e)
                }
              />
              {comp == 2 && (
                <CustomButton
                  title={`Collect ${unStakedLpAmount} LP`}
                  // onClick={(e) => CollectUnstakeUnlockLPStake(e)}
                  disabled={!collectDisableLPStake}
                />
              )}
            </div>
            <ul className="staking-info__list">
              <li>
                LP Tokens in wallet:
                <span>{LpToken || "0.00"}</span>
              </li>
              <li>
                Your Staked LP Tokens:
                <span>{stakeLP || "0.00"}</span>
              </li>
            </ul>
          </Form>
        </div>
      </Tab>
    </Tabs>
  );
};

export default StakingTabs;
