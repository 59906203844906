import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Admin from '../../Admin'
import TopbarHeading from '../../common/TopbarHeading/TopbarHeading'
import "./CopyTrading.scss"

const CopyTrading = () => {
  return (
    <Admin>
      <div className='Common_TopBar'>
        <Row className='align-items-center'>
          <Col xs={12} xl={8}>
            <TopbarHeading title="Copy Trading" />
          </Col>
          <Col xs={12} xl={4} className="d-md-flex justify-content-xl-end">
          </Col>
        </Row>
      </div>
      <div className='Common_admin_Contentarea'>

      </div>
    </Admin>
  )
}

export default CopyTrading