import React from "react";

const RightArrow = ({ onclick }) => {
    return (
        <button class="custom-scroll-btn right" onClick={onclick}>
            <svg
                width="5"
                height="9"
                viewBox="0 0 5 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1 1L3.93973 4.33511C3.95884 4.35675 3.97399 4.38246 3.98434 4.41075C3.99468 4.43904 4 4.46937 4 4.5C4 4.53063 3.99468 4.56096 3.98434 4.58925C3.97399 4.61754 3.95884 4.64325 3.93973 4.66489L1 8"
                    stroke="#E8E8E9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
            </svg>
        </button>
    );
};

export default RightArrow;
