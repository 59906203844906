import React, { useState, useEffect } from "react";
import { Pagination, Table } from "react-bootstrap";
import "./CurrentOrder.scss";
import CurrentOrderFilters from "./CurrentOrderFilters";
import iconSell from "../../../../../../assets/icons/redline_trade.svg";
import iconBuy from "../../../../../../assets/icons/linechat_arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { ApiServices } from "../../../../../Exchange/services/api.services";
import { saveUserCurrentOrderHistory } from "../../../../../Exchange/redux/_slices/exchange.slice";
import moment from "moment";
import { updateLoader } from "../../../../../Exchange/redux/_slices/exchange.slice";
import Loader
 from "../../../../common/Loader/Loader";
const CurrentOrder = ({currentTab, isActive}) => {
    const dispatch = useDispatch();
   const walletAddress = useSelector((state) => state?.user?.walletAddress);
    const listOpenOrderHistory = useSelector(
        (state) => state.exchange.userCurrentOrderHistory
    );
     const loader = useSelector((state) => state.exchange.loader);

    useEffect(() => {
        if (walletAddress && currentTab == 'spotCurrentHistory' && isActive) {
            dispatch(updateLoader(true));
            getCurrentOrderHistoryList();
        } else {
            dispatch(saveUserCurrentOrderHistory([]));
        }
    }, [walletAddress, currentTab, isActive]);

    const getCurrentOrderHistoryList = async () => {
        try {
            const dataObject = {
                address: walletAddress,
                category: "spot",
            };
            const response = await ApiServices.getCurrentOrderHistory(
                dataObject
            );
            dispatch(saveUserCurrentOrderHistory(response?.data));
            dispatch(updateLoader(false));
        } catch (err) {
            console.log(err);
            dispatch(updateLoader(false));
        }
    };

    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    return (
        <>
            {loader && <Loader/>}
            <div className="CryptoAssets_Table History_Table">
                <Table responsive>
                    <thead>
                        <tr>
                            <th style={{ minWidth: "130px" }}>
                                <div className="d-flex align-items-center">
                                    Pairs
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "130px" }}>
                                <div className="d-flex align-items-center">
                                    Trade Type
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "130px" }}>
                                <div className="d-flex align-items-center">
                                    Order Type
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    Direction
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center">
                                    Order Value
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center">
                                    Order Price
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "195px" }}>
                                <div className="d-flex align-items-center">
                                    Filled/Order Quantity
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-center">
                                    TP/SL
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "270px" }}>
                                <div className="d-flex align-items-center justify-content-center">
                                    Order Time
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "370px" }}>
                                <div className="d-flex align-items-center justify-content-center">
                                    Order ID
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            {/* <th>
                                <div className="d-flex align-items-center justify-content-end">
                                    Action
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {listOpenOrderHistory !== undefined &&
                        listOpenOrderHistory?.length > 0 ? (
                            listOpenOrderHistory.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div class="coin_data d-flex align-items-center">
                                                <div class="d-flex align-items-center">
                                                    <span>{data?.symbol}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <h6>
                                                {data?.side == 0
                                                    ? "Buy"
                                                    : "Sell"}
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {data?.type == 0
                                                    ? "Limit"
                                                    : "Market"}
                                            </h6>
                                        </td>
                                        <td>
                                            <div className="Type_Data d-flex align-items-center">
                                                <div
                                                    className={`type_Icon ${
                                                        data?.side == "Buy"
                                                            ? "greenbg"
                                                            : "redbg"
                                                    }`}
                                                >
                                                    <img
                                                        src={
                                                            data?.type == 0
                                                                ? iconBuy
                                                                : iconSell
                                                        }
                                                        alt="icon"
                                                    />
                                                </div>
                                                <div className="type_Text">
                                                    <h6>
                                                        {data?.type == 0
                                                            ? "Buy"
                                                            : "Sell"}
                                                    </h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {roundToTwoDecimals(
                                                data?.price * data?.quantity
                                            )}
                                        </td>
                                        <td>
                                            {roundToTwoDecimals(data?.price)}
                                        </td>
                                        <td>
                                            {roundToTwoDecimals(
                                                data?.filled_amount
                                            ) /
                                                roundToTwoDecimals(
                                                    data?.quantity
                                                )}
                                        </td>
                                        <td>
                                            {data?.take_profit}/
                                            {data?.stop_loss}
                                        </td>
                                        <td>
                                            {moment(
                                                Number(data?.created_at)
                                            ).format("DD-MM-YYYY  HH:mm:ss")}
                                        </td>
                                        <td>{data?.id}</td>

                                        {/* <td>Cancel</td> */}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={11}>
                                    <p style={{ textAlign: "center" }}>
                                        No result found
                                    </p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {/* <div className="transactions_Pagination d-flex align-items-center justify-content-between">
                    <p>52 transactions</p>

                    <Pagination className="common_pagination">
                        <Pagination.Prev />
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>

                        <Pagination.Ellipsis className="ellipsis_text" />
                        <Pagination.Item>{6}</Pagination.Item>
                        <Pagination.Next />
                    </Pagination>
                </div> */}
            </div>
        </>
    );
};

export default CurrentOrder;
