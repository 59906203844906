import React from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonButton from '../../../../common/CommonButton/CommonButton';
import listicon1 from "../../../../assets/icons/api-listicon1.svg"
import listicon2 from "../../../../assets/icons/api-listicon2.svg"

const CreateApiModal = ({ show, handleClose }) => {
    const orderdata = [
        {
            icon: listicon1,
            heading: "Self-generated",
            fullname: "Generate your own customized API key",
        },
        {
            icon: listicon2,
            heading: "System generated",
            fullname: "Have our system generate an API key for you",
        },
    ];
    return (
        <>
            <Modal className='Admin_Modal Api_modal' centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create API</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='api_modal_Content'>
                        <div className='FormGroup'>
                            <label>Label your API key to proceed</label>
                            <input type="text" placeholder='Test API' className="custom_input" />
                        </div>

                        <div className='d-flex'>
                            <CommonButton className="w-50 grey-btn2 me-2" title="Cancel" />
                            <CommonButton className="w-50 ms-2" title="Confirm" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateApiModal
