import React, { useEffect, useState, useImperativeHandle } from "react";
import "./CalculatorModal.scss";
import { Modal } from "react-bootstrap";
import { ReactCalculator } from "simple-react-calculator";

const CalculatorModal = ({ handleClose, show }) => {



  /**
   * handle on change for input amount
   * @param {*} event
   */

  const handleCloseSubmit = () => {
    handleClose();
    // setError(null);
  };



  return (
    <>
      <Modal
        className="add_profit_Modal Calculator_modal"
        centered
        show={show}
        onHide={handleCloseSubmit}
      >
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="calContainer">
            <ReactCalculator />
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default CalculatorModal;
