import React, { useEffect, useState } from "react";
import "./DateCalenderCard.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { saveUserLedgerFilter } from "../../../../../../redux/_slices/user.slice";

const DateCalenderCard = () => {
  const dispatch = useDispatch();
  const [valueType, setValueType] = useState(new Date());
  const [startEnd, setStartEnd] = useState({ startTime: "", endTime: "" });
  const filters = useSelector((state) => state.user.userLedgerFilters);

  useEffect(() => {
    handleFilterChange("duration", "1D");
  }, []);

  const handleFilterChange = (type, value) => {
    // console.log("calender>>>>>>>>>>", type, value);
    setValueType(value);
    let timeObj;
    if (type === "startTime" || type === "endTime") {
      setStartEnd({ ...startEnd, [type]: value });
      timeObj = {
        ...filters,
        [type]: value.getTime(),
      };
    } else {
      let dateNow = new Date();
      if (value === "1M") {
        timeObj = {
          startTime: new Date(
            new Date().setDate(dateNow.getDate() - 30)
          ).getTime(),
          // "endTime" : dateNow?.getTime()
        };
      }
      if (value === "3M") {
        timeObj = {
          startTime: new Date(
            new Date().setDate(dateNow.getDate() - 90)
          ).getTime(),
          // "endTime" : dateNow?.getTime()
        };
      }
      if (value === "1W") {
        timeObj = {
          // "startTime": (new Date(dateNow.getTime() - 7 * 24 * 60 * 60 * 1000)).getTime(),
          endTime: dateNow?.getTime(),
        };
      }
      if (value === "1D") {
        timeObj = {
          startTime: new Date(
            new Date().setDate(dateNow.getDate() - 1)
          ).getTime(),
          // "endTime" : dateNow?.getTime()
        };
      }
    }
    dispatch(saveUserLedgerFilter(timeObj));
  };

  return (
    <>
      <div className="calender_Card d-flex align-items-center">
        <ul className="d-flex align-items-center">
          <li
            className={"date_btn " + (valueType === "1D" ? "active" : "")}
            onClick={() => handleFilterChange("duration", "1D")}
          >
            1 Day
          </li>
          <li
            className={`date_btn ` + (valueType === "1W" ? "active" : "")}
            onClick={() => handleFilterChange("duration", "1W")}
          >
            1 Week
          </li>
          <li
            className={`date_btn ` + (valueType === "1M" ? "active" : "")}
            onClick={() => handleFilterChange("duration", "1M")}
          >
            1 Month
          </li>
          <li
            className={`date_btn ` + (valueType === "3M" ? "active" : "")}
            onClick={() => handleFilterChange("duration", "3M")}
          >
            3 Months
          </li>
        </ul>
        {/* <div className='date_calender_picker'>
                    <span className='date_text'>Date</span>
                    <span className='date_picker'>
                      
                        <DatePicker selected={startEnd?.startTime} onChange={(date) => handleFilterChange("startTime",date)} placeholderText="YYYY-MM-DD" />
                    </span>
                    <strong className='mx-2'>to</strong>
                    <span className='date_picker'>
                        <DatePicker selected={startEnd?.endTime} onChange={(date) => handleFilterChange("endTime",date)} placeholderText="YYYY-MM-DD" />
                    </span>
                </div> */}
      </div>
    </>
  );
};

export default DateCalenderCard;
