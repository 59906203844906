import React from 'react'
import Admin from '../../Admin';
import "./Dashboard.scss";
import Hicon from "../../../../assets/icons/menuicon6.svg"
import { RightarrowIcon, SearchIcon, UserIcon } from '../../../../assets/Svg/SvgImages';
import { Col, Row } from 'react-bootstrap';
import AssetsDashboard from './AssetsDashboard/AssetsDashboard';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <>
      <Admin>
        <div className='Common_TopBar'>
          <Row className='align-items-center'>
            <Col xs={12} xl={8}>
              <div className='UserId_Time d-md-flex align-items-center'>
                <figure className='mb-0 me-4'><UserIcon /></figure>
                <div className='UserId_Time_Rank mt-3 mt-md-0'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='TokenAdds'>0x476---5Cf1</span>
                    <p><span>User ID:</span> 19471924</p>
                    <h5>
                      <Link to="/referral">
                        <img src={Hicon} alt="icon" /> 
                        <span>RANK </span>
                        TIER 4 <RightarrowIcon /> 
                      </Link>
                    </h5>
                  </div>
                  <div className='d-sm-flex align-items-center UserId_Time_LastLogin mt-2 mt-md-0'>
                    <p><span>Last Login time:</span> 2022-06-17 17:48:59</p>
                    <p className='mt-2 mt-sm-0'><span>IP:</span> 152.98.228.210</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} xl={4} className="d-md-flex justify-content-xl-end mt-3 mt-xl-0">
              <div className='Dash_SearchBar d-md-flex align-items-center'>
                <label>Explore</label>
                <div className='searchBar_input'>
                  <input type="text" name="" placeholder='Coin, Function, Announcement, etc.' className='control_searchBar' />
                  <SearchIcon />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='Common_admin_Contentarea'>
          <AssetsDashboard />
        </div>
      </Admin>
    </>
  )
}

export default Dashboard