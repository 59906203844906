import { dynamicContractDetails } from "../../../assets/dynamicContract";
import { MAX_AMOUNT } from "../../../constants/constants";
import { errorHelperContract } from "../ContractHelper";
import { commonAbiInstances } from "../GlobalCall";

//SOLO_Deposit Function Call
export const localeStringFunction = (value) => {
  return value.toLocaleString("fullwide", {
    useGrouping: !1,
  });
};

export const stakeDeposit = async (walletAddress, amount, key, addresKey) => {
  try {
    const stakeData = dynamicContractDetails.filter(
      (a) => a.symbol == addresKey
    );
    const contract = await commonAbiInstances(
      stakeData[0]?.address,
      stakeData[0]?.abi
    );
    if (contract) {
      let outputAmount = Number(amount);
      let amtValue;
      if (key == 1) {
        amtValue = localeStringFunction(outputAmount * 10 ** 18);
      } else {
        amtValue = outputAmount;
      }
      let gasLimit = await contract.methods
        .deposit(amtValue)
        .estimateGas({ from: walletAddress });
      console.log("contractcontract", contract.methods, gasLimit);
      const result = await contract.methods
        ?.deposit(amtValue)
        .send({ from: walletAddress, gas:gasLimit });
      return result;
    }
  } catch (error) {
    console.log("rrrrrrrrrrrrr",error);
    errorHelperContract(error, "send", "deposit");
    return 100;
  }
};

//LP_Stake_ADD__Deposit Function Calling

export const LPstakeDeposit = async (
  walletAddress,
  amount,
  percentAmountEXH,
  percentAmountETH,
  ethAmount
) => {
  // console.log(percentAmountETH, "percentAmountETH");
  try {
    const stakeData = dynamicContractDetails.filter(
      (a) => a.symbol == "LPSTAKE"
    );
    const contract = await commonAbiInstances(
      stakeData[0]?.address,
      stakeData[0]?.abi
    );

    if (contract) {
      let ETHamount = Number(ethAmount);
      let outputAmount = Number(amount);
      let amtValue = localeStringFunction(outputAmount * 10 ** 18);
      let ETHamtValue = localeStringFunction(Number(ETHamount) * 10 ** 18);
      let EHXpercentvalue = localeStringFunction(percentAmountEXH * 10 ** 18);
      let ETHpercentvalue = localeStringFunction(percentAmountETH * 10 ** 18);
      // console.log(
      //   "fullwide",
      //   amtValue,
      //   EHXpercentvalue,
      //   ETHpercentvalue,
      //   ETHamtValue
      // );
      let gasLimit = await contract.methods
        .depositUnpaired(amtValue, EHXpercentvalue, ETHpercentvalue)
        .estimateGas({ from: walletAddress, value: ETHamtValue });
      // console.log("gasLimit", gasLimit);

      const result = await contract.methods
        .depositUnpaired(amtValue, EHXpercentvalue, ETHpercentvalue)
        .send({ from: walletAddress, gas: gasLimit, value: ETHamtValue });
      return result;
    }
  } catch (error) {
    errorHelperContract(error, "send", "depositUnpaired");
    return 100;
  }
};

//SOLO_Unstake Function Calling

export const stakeWithdraw = async (walletAddress, amount, key) => {
  try {
    const stakeData = dynamicContractDetails.filter((a) => a.symbol == key);
    const contract = await commonAbiInstances(
      stakeData[0]?.address,
      stakeData[0]?.abi
    );
    if (contract) {
      let outputAmount = Number(amount);
      let amtValue = localeStringFunction(outputAmount * 10 ** 18);

      let gasLimit = await contract.methods
        .withdraw(amtValue)
        .estimateGas({ from: walletAddress });
      const result = await contract.methods
        .withdraw(amtValue)
        .send({ from: walletAddress, gas: gasLimit });
      return result;
    }
  } catch (error) {
    errorHelperContract(error, "send", "withdraw");
    return null;
  }
};

//LP_Unstake Function Calling

export const LPstakeWithdraw = async (walletAddress, amount) => {
  try {
    const stakeData = dynamicContractDetails.filter(
      (a) => a.symbol == "LPSTAKE"
    );
    const contract = await commonAbiInstances(
      stakeData[0]?.address,
      stakeData[0]?.abi
    );
    if (contract) {
      let outputAmount = Number(amount);
      let amtValue = localeStringFunction(outputAmount * 10 ** 18);
      let gasLimit = await contract.methods
        .withdraw(amtValue)
        .estimateGas({ from: walletAddress });
      const result = await contract.methods
        .withdraw(amtValue)
        .send({ from: walletAddress, gas: gasLimit });
      return result;
    }
  } catch (error) {
    errorHelperContract(error, "send", "withdraw");
    return 100;
  }
};

export const approve = async (walletAddress, tokenKeys, key, amount) => {
  try {
    const approve = dynamicContractDetails.filter((a) => a.symbol == tokenKeys);
    const stakeData = dynamicContractDetails.filter((a) => a.symbol == key);
    const contract = await commonAbiInstances(
      approve[0]?.address,
      approve[0]?.abi
    );

    if (contract) {
      const approveAmount = localeStringFunction(amount * 10 ** 18);
      let gasLimit;
      gasLimit = await contract.methods
        .approve(stakeData[0]?.address, approveAmount)
        .estimateGas({ from: walletAddress });
      const result = await contract.methods
        .approve(stakeData[0]?.address, approveAmount)
        .send({ from: walletAddress, gas: gasLimit });
      return result;
    }
  } catch (error) {
    errorHelperContract(error, "send", "approve");
    return 100;
  }
};

export const CollectUSDC = async (walletAddress) => {
  try {
    const stakeData = dynamicContractDetails.filter(
      (a) => a.symbol == "SOLOSTAKE"
    );
    const contract = await commonAbiInstances(
      stakeData[0]?.address,
      stakeData[0]?.abi
    );
    if (contract) {
      let gasLimit;
      gasLimit = await contract.methods
        .claim()
        .estimateGas({ from: walletAddress, value: 0 });

      const result = await contract.methods
        .claim()
        .send({ from: walletAddress, gas: gasLimit });
      return result;
    }
  } catch (error) {
    errorHelperContract(error, "send", "claim");
    return 100;
  }
};

export const CollectEXH = async (walletAddress, stakedata) => {
  try {
    const stakeData = dynamicContractDetails.filter(
      (a) => a.symbol == stakedata
    );
    const contract = await commonAbiInstances(
      stakeData[0]?.address,
      stakeData[0]?.abi
    );
    if (contract) {
      let gasLimit;
      gasLimit = await contract.methods
        .withdraw(0)
        .estimateGas({ from: walletAddress, value: 0 });

      const result = await contract.methods
        .withdraw(0)
        .send({ from: walletAddress, gas: gasLimit });
      return result;
    }
  } catch (error) {
    errorHelperContract(error, "send", "collectEHX");
    return 100;
  }
};

//LP_Stake Deposit Function Calling

export const LpStakeDeposit = async (walletAddress, amount) => {
  try {
    const stakeData = dynamicContractDetails.filter(
      (a) => a.symbol == "LPSTAKE"
    );
    const contract = await commonAbiInstances(
      stakeData[0]?.address,
      stakeData[0]?.abi
    );
    if (contract) {
      let outputAmount = Number(amount);
      let amtValue = localeStringFunction(outputAmount * 10 ** 18);
      let gasLimit;
      gasLimit = await contract.methods
        .deposit(amtValue)
        .estimateGas({ from: walletAddress, value: 0 });

      const result = await contract.methods
        .deposit(amtValue)
        .send({ from: walletAddress, gas: gasLimit });
      return result;
    }
  } catch (error) {
    errorHelperContract(error, "send", "deposit");
    return 100;
  }
};

//Add_Lp_Collect withdrawAndSplitUnstakedToken function calling

export const withdrawAndSplitUnstakedToken = async (
  walletAddress,
  amount,
  ethAmount
) => {
  // console.log(amount, ethAmount, "ethAmount");
  try {
    const stakeData = dynamicContractDetails.filter(
      (a) => a.symbol == "LPSTAKE"
    );
    const contract = await commonAbiInstances(
      stakeData[0]?.address,
      stakeData[0]?.abi
    );
    if (contract) {
      let outputAmountEth = Number(ethAmount);
      let amtValueeth = localeStringFunction(outputAmountEth * 10 ** 18);
      let outputAmount = Number(amount);
      let amtValue = localeStringFunction(outputAmount * 10 ** 18);
      let gasLimit = await contract.methods
        .withdrawAndSplitUnstakedTokens(amtValue, amtValueeth)
        .estimateGas({ from: walletAddress });
      const result = await contract.methods
        .withdrawAndSplitUnstakedTokens(amtValue, amtValueeth)
        .send({ from: walletAddress, gas: gasLimit });
      return result;
    }
  } catch (error) {
    errorHelperContract(error, "send", "withdrawAndSplitUnstakedTokens");
    return 100;
  }
};

//WithdrawUnstakedTokens Function calling

export const WithdrawUnstakedTokensFunction = async (
  walletAddress,
  stakeKeys
) => {
  try {
    const stakeData = dynamicContractDetails.filter(
      (a) => a.symbol == stakeKeys
    );
    const contract = await commonAbiInstances(
      stakeData[0]?.address,
      stakeData[0]?.abi
    );
    if (contract) {
      let gasLimit;
      gasLimit = await contract.methods
        .withdrawUnstakedTokens()
        .estimateGas({ from: walletAddress, value: 0 });

      const result = await contract.methods
        .withdrawUnstakedTokens()
        .send({ from: walletAddress, gas: gasLimit });
      return result;
    }
  } catch (error) {
    errorHelperContract(error, "send", "collectEHX");
    return 100;
  }
};
