import React from "react";
import hideEyeIcon from "../../../../assets/images/admin/eye-slash.svg";
import eyeIcon from "../../../../assets/images/admin/eyeshow.svg";
import { useDispatch } from "react-redux";
import { saveHideBalance } from "../../../../redux/_slices/user.slice";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import  "./HideShowBalance.scss";

const HideShowBalance = ({extraClasss}) => {

    const hideBalance = useAppSelector((state) => state.user.hideBalance);
    const dispatch = useDispatch();

    const hideBalanceHandler = () => {
        dispatch(saveHideBalance(!hideBalance));
    };

    return(
        <>
        <span className={`Show_Blnc ${extraClasss}`} onClick={hideBalanceHandler}>
            <img src={hideBalance ? hideEyeIcon : eyeIcon} alt="eye"/>
        </span>
        </>
    )
}

export default HideShowBalance;