import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NETWORK_INFO, NETWORK_INFOMATION } from "../../../constants/wallet";
import {
  saveNetworkChainId,
  savePrevSelectedPairKey,
  saveSelectedPair,
  saveSelectedPairKey,
  saveUserWalletAddress,
  saveWallet,
} from "../../../redux/_slices/user.slice";
import { amountFormate, getDecimalString } from "../../../_utils/comman";
import { useAppSelector } from "../../../hooks/redux.hooks";
import {
  savePairDecimals,
  saveUserTransactionHistory,
} from "../redux/_slices/spotExchange.slice";
import { useLocation } from "react-router-dom";
import { DEFAULT_PAIR } from "../../../constants/constants";

import { getProvider } from "../../../services/HelperServices";
import {
  getUSDTBalance,
  getUSDTDecimals,
} from "../../../redux/_actions/web3.actions";
import {
  callWeb3,
  provider,
  wallets,
  wcprovider,
} from "../../../services/walletServices";
import { web3Services } from "../../../services/web3.service";
import store from "../../../redux/_store/configureStore";
import { ApiServices } from "../services/api.services";
import { saveListOfPairs } from "../redux/_actions/exchange.action";
import { useDisconnect } from "wagmi";

const wallettype = store?.getState()?.user?.wallet;
const { ethereum, BinanceChain, trustwallet, coinbaseWalletExtension } = window;
const provider1 =
  wallettype == "binance_wallet"
    ? BinanceChain
    : wallettype == "trust_wallet"
    ? trustwallet
    : wallettype == "Coinbase_Wallet"
    ? coinbaseWalletExtension
    : wallettype == wallets[1]
    ? "wcprovider()"
    : ethereum;

/**
 * This hook provide the current provider for loggedIn wallet.
 * @returns
 */
export const useCurrentProvider = () => {
  const wallet = useAppSelector((state) => state.user.wallet);
  const chainId = useAppSelector((state) => state.user.chainId);
  const wallettype = store.getState()?.user?.wallet;
  const { ethereum, BinanceChain, trustwallet, coinbaseWalletExtension } =
    window;
  const provider1 =
    wallettype == "binance_wallet"
      ? BinanceChain
      : wallettype == "trust_wallet"
      ? trustwallet
      : wallettype == "Coinbase_Wallet"
      ? coinbaseWalletExtension
      : ethereum;
  // : wallettype == wallets[1]
  // ? wcprovider()

  const [currentProvider, setCurrentProvider] = useState(provider1);

  const fetchProvider = async (walletData, chain_id) => {
    const { ethereum } = window;
    const new_provider = await getProvider();
    console.log("new_provider", new_provider);
    setCurrentProvider(new_provider);
  };

  useEffect(() => {
    // if (wallet && chainId) {
    fetchProvider(wallet, chainId);
    // }
  }, []);

  return currentProvider; // wallet provider
};

/**
 * Get user wallet balance
 * @param {String} type // type = deposit or withdrawal
 * @param {*} currentProvider
 * @param {String} assetAddress
 * @param {String} userAddress
 * @returns
 */
export const useFetchWalletBalance = (
  type,
  currentProvider,
  assetAddress,
  userAddress
) => {
  const [balance, setbalance] = useState(0);
  const [decimals, setDecimal] = useState(0);

  const userBalances = useAppSelector(
    (state) => state.spotExchange.userBalances
  );

  const getNativeCurrencyBalance = async (current_provider, user_address) => {
    const wallettype = store.getState()?.user?.wallet;
    const web3Instance = await callWeb3(wallettype);
    const responce = await web3Instance.eth.getBalance(user_address);
    setbalance(responce);
    setDecimal(18);
  };

  const getAssetBalance = async (
    current_provider,
    asset_address,
    user_address
  ) => {
    let balanceData = 0;
    if (asset_address && user_address) {
      balanceData = await web3Services.getAssetBalance(
        current_provider,
        asset_address,
        user_address
      );
    }
    setbalance(balanceData);
    setDecimal(18);
  };

  const getWithdrawalBalance = (asset_address) => {
    let balanceData = userBalances.find(
      (doc) => doc.currency_address === asset_address
    )?.balance;

    setbalance(balanceData);
    setDecimal(8);
  };

  useEffect(() => {
    if (type === "deposit") {
      if (currentProvider) {
        if (assetAddress === "0x0000000000000000000000000000000000000000") {
          getNativeCurrencyBalance(currentProvider, userAddress);
        } else {
          getAssetBalance(currentProvider, assetAddress, userAddress);
        }
      }
    } else {
      if (assetAddress) {
        getWithdrawalBalance(assetAddress);
      }
    }
  }, [currentProvider, assetAddress, userBalances]);

  return { balance, decimals };
};

/**
 * Handle Wallet Events using current Provider
 * @returns
 */
export const useWalletEvents = () => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const currentProvider = useCurrentProvider();
  const disconnect = useDisconnect();

  const wallet = useSelector((state) => state.user.wallet);

  const memoizedDisconnect = useCallback(() => {
    disconnect();
  }, [disconnect]);

  /**
   * Handle disconnect connect wallet and close modale
   */
  const handleRemoveWallet = () => {
    // dispatch(saveUserWalletAddress(""));
    // dispatch(saveWallet(""));
  };

  const handleWalletEvent = async (currentProvider) => {
    const path = location?.pathname;
    const networkInfo = NETWORK_INFO; //path != "/staking" ? NETWORK_INFO : NETWORK_INFOMATION;

    currentProvider.on("chainChanged", async (chain_id) => {
      const chain = networkInfo.find(
        (doc) => doc.chain_id === Number(chain_id)
      );
      // console.log("chainchain", chain);
      if (chain) {
        dispatch(saveNetworkChainId(chain.chain_id));
      } else {
        handleRemoveWallet();
      }
    });

    currentProvider.on("accountsChanged", (accounts) => {
      if (accounts.length == 0) {
        // handleRemoveWallet();
        dispatch(saveUserWalletAddress(""));
        memoizedDisconnect();
      } else if (
        accounts.length > 0
        // accounts[0].toLowerCase() != walletAddress.toLowerCase()
      ) {
        dispatch(saveUserWalletAddress(accounts[0]));
        // handleRemoveWallet();
      }
    });

    currentProvider.on("disconnect", (respo) => {
      if (wallet !== "metamask") {
        // Delete connector
        localStorage.removeItem("walletconnect");
        handleRemoveWallet();
      }
    });

    currentProvider.on("disconnect", () => {
      // dispatch(saveUserWalletAddress(""));
    });
    setIsActive(true);
  };

  useEffect(() => {
    if (currentProvider) {
      handleWalletEvent(currentProvider);
    }
  }, [currentProvider]);

  return isActive;
};

/**
 * Create hook for the api service
 */
export const usePairListAPi = () => {
  const dispatch = useDispatch();
  const selectedPairKey = useSelector((state) => state.user.selectedPairKey);

  /**
   * Fetch pair list
   */
  const getPairList = async () => {
    try {
      const response = await ApiServices.getPairList();
      if (response) {
        let responce = response.data;
        if (responce && responce.length > 0) {
          dispatch(saveListOfPairs("api", responce));
        }
        // console.log(">>>>>>>>>>>>>>>>>>>>>", selectedPairKey);
        if (selectedPairKey) {
          let pairData = responce.find((doc) => doc.pair === selectedPairKey);
          if (pairData) {
            const priceDecimalLength = getDecimalString(
              pairData?.min_price_increment,
              pairData?.price_decimal
            );
            const baseDecimalLength = getDecimalString(
              pairData?.min_size_increment,
              pairData?.base_decimal
            );
            dispatch(
              savePairDecimals({ priceDecimalLength, baseDecimalLength })
            );
            dispatch(saveSelectedPair(pairData));
          } else {
            let tempPair = responce.find(
              (doc) => doc.pair_key === DEFAULT_PAIR
            );

            const priceDecimalLength = getDecimalString(
              tempPair?.min_price_increment,
              tempPair?.price_decimal
            );

            const baseDecimalLength = getDecimalString(
              tempPair?.min_size_increment,
              tempPair?.base_decimal
            );

            dispatch(
              savePairDecimals({ priceDecimalLength, baseDecimalLength })
            );
            dispatch(savePrevSelectedPairKey(tempPair.pair));
            dispatch(saveSelectedPair(tempPair));
            dispatch(saveSelectedPairKey(tempPair.pair));
          }
        } else {
          let tempPair = responce.find((doc) => doc.pair_key === DEFAULT_PAIR);
          const priceDecimalLength = getDecimalString(
            tempPair?.min_price_increment,
            tempPair?.price_decimal
          );
          const baseDecimalLength = getDecimalString(
            tempPair?.min_size_increment,
            tempPair?.base_decimal
          );
          dispatch(savePairDecimals({ priceDecimalLength, baseDecimalLength }));
          dispatch(savePrevSelectedPairKey(tempPair.pair));
          dispatch(saveSelectedPair(tempPair));
          dispatch(saveSelectedPairKey(tempPair.pair));
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPairList();
  }, []);
};

export const useTolalUsdBalance = (type) => {
  const [totalUsdBalance, setTotalUsdBalance] = useState(0);
  const [totalvalue, settotalUsd] = useState(0);
  const chainId = useSelector((state) => state.user.chainId);
  const userBalances = useAppSelector(
    (state) => state.spotExchange.userBalances
  );
  const activeCoins = useAppSelector((state) => state.spotExchange.activeCoins);
  const userPaymentCurrency = useAppSelector(
    (state) => state.spotExchange.userPaymentCurrency
  );

  const getTotalBalance = (_chainId, _userBalances, _activeCoins) => {
    let totalUsd = 0;
    for (const doc of _userBalances) {
      if (doc.chain_id === _chainId) {
        totalUsd += doc.usd_balance - doc.usd_locked_balance;
      }
    }
    settotalUsd(totalUsd / 10 ** 8);
    setTotalUsdBalance(amountFormate(totalUsd, 8, 4));
  };

  const getBalance = (
    _chainId,
    _userBalances,
    _activeCoins,
    _currencyAddress
  ) => {
    let totalUsd = 1;
    for (const doc of _userBalances) {
      totalUsd += doc.usd_balance - doc.usd_locked_balance;
    }
    settotalUsd(totalUsd / 10 ** 8);
    setTotalUsdBalance(amountFormate(totalUsd, 8, 4));
  };

  useEffect(() => {
    if (type === "single") {
      getBalance(chainId, userBalances, activeCoins, userPaymentCurrency);
    } else {
      getTotalBalance(chainId, userBalances, activeCoins);
    }
  }, [chainId, userBalances, activeCoins, userPaymentCurrency]);
  return { totalUsdBalance, totalvalue };
};

export const useTotalUsdBalanceFromContract = () => {
  const [totalUsdBalance, setTotalUsdBalance] = useState(0);
  const [decimals, setDecimals] = useState(0);
  const chainId = useSelector((state) => state.user.chainId);
  const walletAddress = useSelector((state) => state.user.walletAddress);
  const currentProvider = useCurrentProvider();

  const getDecimals = async () => {
    await getUSDTDecimals(currentProvider, chainId).then((response) => {
      setDecimals(response);
    });
  };

  const getTotalBalance = async () => {
    setTotalUsdBalance(0);
    getDecimals();

    let balance = await getUSDTBalance(walletAddress, chainId, currentProvider);
    console.log("balancebalance", balance);
    if (balance && balance > 0) {
      setTotalUsdBalance(balance);
    } else {
      setTotalUsdBalance(0);
    }
  };

  useEffect(() => {
    getTotalBalance();
  }, [chainId, currentProvider, walletAddress]);

  return { decimals, totalUsdBalance };
};

export const useChainInfo = () => {
  const [dataObj, setDataObj] = useState([]);

  const getChainInfo = async () => {
    let list = await ApiServices.getChainInfo();
    if (list) {
      setDataObj(list.data);
    }
  };

  useEffect(() => {
    getChainInfo();
  }, []);

  return { dataObj };
};

export const useTransactionHistory = () => {
  const [dataObj, setDataObj] = useState([]);
  const walletAddress = useSelector((state) => state.user.walletAddress);
  const dispatch = useDispatch();

  const getTxnHistory = async () => {
    let dataObj = {
      address: walletAddress,
      page: 1,
      limit: 20,
    };
    let list = await ApiServices.transactionHistory(dataObj);
    if (list) {
      setDataObj(list.data);
      dispatch(saveUserTransactionHistory(list.data));
    }
  };

  useEffect(() => {
    getTxnHistory();
  }, []);

  return { dataObj };
};

export const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
      if (document.getElementById("HeaderAdded") !== null) {
        document.getElementById("HeaderAdded").className = "";
      }
    }
  };

  useEffect(() => {
    document?.addEventListener("click", handleClickOutside, true);
    return () => {
      document?.removeEventListener("click", handleClickOutside, true);
    };
  }, [initialIsVisible]);

  return { ref, isComponentVisible, setIsComponentVisible };
};

export const useUserAllCoinBalances = () => {
  const [userBalance, setUserBalance] = useState(null);
  const chainId = useSelector((state) => state.user.chainId);
  const currentProvider = useCurrentProvider();
  const walletAddress = useSelector((state) => state.user.walletAddress);

  const getBalances = async () => {
    let balances = await ApiServices.getAssestsBalance({
      address: walletAddress,
    });
    console.log("balances>", balances);
    if (balances?.status === 200) {
      setUserBalance(balances?.data);
    } else {
      setUserBalance(0);
    }
  };

  useEffect(() => {
    getBalances();
  }, [chainId, currentProvider, walletAddress]);

  return { userBalance, getBalances };
};
