import React, { forwardRef, useImperativeHandle, useRef } from "react";

import "./DepositPopStyle.scss";

import ModalComponet from "../../../../common/ModalComponet";
import DepositAndWithdrawalFrom from "./DepositAndWithdrawalFrom";

const DepositAndWithdrawal = forwardRef((props, ref) => {
  const { title } = props;

  const modalRef = useRef(null);

  useImperativeHandle(ref, () => ({
    handleModal(value) {
      modalRef.current.handleModal(value);
    },
  }));

  const handleCloseModal = () => {
    modalRef.current.handleModal(false);
  };

  return (
    <ModalComponet
      dataTestid={title}
      backdrop="static"
      ref={modalRef}
      title={title === "deposit" ? "Deposit" : "Withdraw"}
      className="select_lang_box depsite_wdr"
    >
      <DepositAndWithdrawalFrom handleClose={handleCloseModal} title={title} />
    </ModalComponet>
  );
});

export default DepositAndWithdrawal;
