import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // Users Info
    chainId: 56,
    wallet: "metamask",
    walletAddress: "",
    selectedPairKey: "",
    prevSelectedPairKey: "",
    selectedPair: {},
    favPairList: [],
    spotFavPairList: [],
    marketViewCheckBox: [], // remove items are store in this array
    marginMode: "Isolated",
    leverage: 0,
    leverageInfo: {},
    userWallets: [],
    selectedPairIndex: 0,
    decimal: 4,
    depthOption: true,
    selectedPairScroll: "",
    usertab: "",
    errorCounter: false,
    metaAddress: "",
    chainName: "",
    blockTimestamp: "",
    timeusdc: "",
    totalEXHstake: "",
    totalLPstake: "",
    tab: "",
    route: "",
    userUSDTBalance: "",
    provider: null,
    userLeveragee: 0,
    userWalletBalances: {},
    userLedgerFilters: { dateFilter: "3M" },
    userTradeFilters: { dateFilter: "3M" },
    userSpotTradeFilters: { dateFilter: "3M" },
    notificationCount: "",
    hideBalance: false,
    userFundingBalance: {},
    userUnifiedTradingBalance: {},
    userSpotBalance: {},
    userProfile: [],
    userCoinFilter: "",
    usdValue: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveNetworkChainId: (state, action) => {
      state.chainId = action.payload;
    },
    saveWallet: (state, action) => {
      state.wallet = action.payload;
    },
    saveUserWalletAddress: (state, action) => {
      state.walletAddress = action.payload;
    },
    saveSelectedPairKey: (state, action) => {
      state.selectedPairKey = action.payload;
    },
    savePrevSelectedPairKey: (state, action) => {
      state.prevSelectedPairKey = action.payload;
    },
    saveSelectedPair: (state, action) => {
      state.selectedPair = { ...action.payload };
    },
    saveFavPairList: (state, action) => {
      state.favPairList = [...action.payload];
    },
    saveSpotFavPairList: (state, action) => {
      state.spotFavPairList = [...action.payload];
    },
    saveCheckBox: (state, action) => {
      state.marketViewCheckBox = [...action.payload];
    },
    saveMarginMode: (state, action) => {
      state.marginMode = action.payload;
    },
    saveLeverage: (state, action) => {
      state.leverage = action.payload;
    },
    saveLeverageInfo: (state, action) => {
      state.leverageInfo = action.payload;
    },
    saveUserLeverage: (state, action) => {
      state.userLeveragee = action.payload;
    },
    setUserTab: (state, action) => {
      state.usertab = action.payload;
    },
    setErrorCounter: (state, action) => {
      state.errorCounter = action.payload;
    },
    saveCurrentPair: (state, action) => {
      state.currentPair = action.payload;
    },
    addAddress: (state, action) => {
      state.metaAddress = action.payload;
    },
    addChain: (state, action) => {
      state.chainName = action.payload;
    },
    blockTime: (state, action) => {
      state.blockTimestamp = action.payload;
    },
    usdcTime: (state, action) => {
      state.timeusdc = action.payload;
    },
    totalstakeEXH: (state, action) => {
      state.totalEXHstake = action.payload;
    },
    LPstake: (state, action) => {
      state.totalLPstake = action.payload;
    },
    routeSwitch: (state, action) => {
      state.route = action.payload;
    },
    saveUserUSDTBalance: (state, action) => {
      state.userUSDTBalance = action.payload;
    },
    saveProvider: (state, action) => {
      state.provider = action.payload;
    },
    saveWalletBalance: (state, action) => {
      state.userWalletBalances = action.payload;
    },
    saveUserLedgerFilter: (state, action) => {
      state.userLedgerFilters = action.payload;
    },
    saveUserTradeFilter: (state, action) => {
      state.userTradeFilters = action.payload;
    },
    saveUserSpotTradeFilters: (state, action) => {
      state.userSpotTradeFilters = action.payload;
    },
    saveHideBalance: (state, action) => {
      state.hideBalance = action.payload;
    },
    resetUser: () => initialState,
    saveNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    saveUserFundingBalance: (state, action) => {
      state.userFundingBalance = action.payload;
    },
    saveUserUnifiedTradingBalance: (state, action) => {
      state.userUnifiedTradingBalance = action.payload;
    },
    saveUserSpotBalance: (state, action) => {
      state.userSpotBalance = action.payload;
    },
    saveUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    saveUserCoinFilter: (state, action) => {
      state.userCoinFilter = action.payload;
    },
    saveUsdValue: (state, action) => {
      state.usdValue = action.payload;
    },
  },
});

export const {
  saveProvider,
  saveCurrentPair,
  saveNetworkChainId,
  saveUserWalletAddress,
  saveWallet,
  saveSelectedPairKey,
  saveSelectedPair,
  saveFavPairList,
  saveSpotFavPairList,
  saveCheckBox,
  saveMaginMode,
  saveLeverage,
  saveLeverageInfo,
  saveUserLeverage,
  saveMarginMode,
  setErrorCounter,
  savePrevSelectedPairKey,
  setUserTab,
  addAddress,
  addChain,
  blockTime,
  usdcTime,
  totalstakeEXH,
  LPstake,
  routeSwitch,
  resetUser,
  saveUserUSDTBalance,
  saveWalletBalance,
  saveUserLedgerFilter,
  saveNotificationCount,
  saveHideBalance,
  saveUserFundingBalance,
  saveUserUnifiedTradingBalance,
  saveUserSpotBalance,
  saveUserProfile,
  saveUserCoinFilter,
  saveUsdValue,
  saveUserTradeFilter,
  saveUserSpotTradeFilters,
} = userSlice.actions;

export default userSlice.reducer;
