import React, { useState, useEffect } from "react";
import { Col, Nav, Row, Tab, Form } from "react-bootstrap";
import CommonButton from "../../../../common/CommonButton/CommonButton";
import Admin from "../../Admin";
import TopbarHeading from "../../common/TopbarHeading/TopbarHeading";
import "./Spot.scss";
import infoicon from "../../../../assets/icons/info_blue_icon.svg";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { ApiServices } from "../../../Exchange/services/api.services";
import { SearchIcon } from "../../../../assets/Svg/SvgImages";
import SpotCoinsTable from "./SpotCoinsTable/SpotCoinsTable";
import { saveUserSpotBalance } from "../../../../redux/_slices/user.slice";
import { useDispatch } from "react-redux";
import { saveUsdValue } from "../../../../redux/_slices/user.slice";
import Loader from "../../common/Loader/Loader";
import { updateLoader } from "../../../Exchange/redux/_slices/exchange.slice";

const InverseDerivatives = () => {
    const walletAddress = useAppSelector((state) => state.user.walletAddress);
    const hideBalance = useAppSelector((state) => state.user.hideBalance);
    const [searchCoin, setSearchCoin] = useState("");
    const userSpotBalance = useAppSelector(
        (state) => state.user.userSpotBalance
    );
    const usdValue = useAppSelector((state) => state.user.usdValue);
    const loader = useAppSelector((state) => state.exchange.loader);
    const dispatch = useDispatch();

    const getAllBalance = async () => {
        if (walletAddress) {
            let spotResponse = await ApiServices.getUnifiedTrading({
                address: walletAddress,
                coin: searchCoin,
            });


            const filteredCoins = spotResponse?.data?.coins.filter(
                (coin) => !coin.coin.includes("USDT")
            );

            const totalEquityValue = filteredCoins.reduce((total, coin) => {
                const equity = Number(coin.usdValue) || 0;
                return total + equity;
            }, 0);

            const unrealisedPnl = filteredCoins.reduce((total, coin) => {
                const pnl = Number(coin.unrealisedPnl) || 0;
                return total + pnl;
            }, 0);

            if (spotResponse?.data) {
                dispatch(
                    saveUserSpotBalance({
                        totalEquity: totalEquityValue,
                        unrealizedPnl: unrealisedPnl,
                        coins: filteredCoins,
                    })
                );
                dispatch(updateLoader(false));
            }
        } else {
            dispatch(
                saveUserSpotBalance({
                    totalEquity: 0,
                    unrealizedPnl: 0,
                    coins: [],
                })
            );
            dispatch(updateLoader(false));
        }
        try {
            let getUsdpPrice = await ApiServices.getUsdpPrice();
            if (getUsdpPrice) {
                dispatch(saveUsdValue(getUsdpPrice?.data?.usdtPrice));
            } else {
                dispatch(saveUsdValue(0));
            }
        } catch (error) {}
    };
    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    useEffect(() => {
        getAllBalance();
    }, [walletAddress, searchCoin]);

    const handleSearchCoin = (data) => {
        setSearchCoin(data);
    };

    return (
        <Admin>
            {loader && <Loader />}
            <div className="Common_TopBar">
                <Row className="align-items-center Spot_Header">
                    <Col xs={12} xl={7}>
                        <TopbarHeading title="Spot Account" />
                    </Col>
                    <Col
                        xs={12}
                        xl={5}
                        className="d-md-flex justify-content-xl-end"
                    >
                        <div className="Common_Header_Btns d-flex align-items-center">
                            <CommonButton
                                title="Transfer"
                                className="grey-btn2"
                            />
                            <CommonButton
                                title="Convert"
                                className="grey-btn2"
                            />
                            <CommonButton
                                title="Transaction History"
                                className="grey-btn2"
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="Common_admin_Contentarea future_assets_content">
                <div className="Naviblue_Card d-flex align-items-center">
                    <img src={infoicon} alt="eye" />
                    <p className="font14 ps-3">
                        To start trading, please transfers funds into your
                        Trading Account
                    </p>
                </div>
                <div className="d-flex flex-wrap width-2">
                    <div className="Spot_blnc_Card">
                        <div className="d-flex align-items-center">
                            <h5>Total Equity</h5>
                        </div>
                        <h3>
                            {hideBalance ? (
                                <>{"********"}</>
                            ) : (
                                <>
                                    {/* {userSpotBalance?.totalEquity !== undefined
                                        ? roundToTwoDecimals(
                                              userSpotBalance?.totalEquity
                                          )
                                        : 0}{" "}
                                    {"USDT"}
                                    <span>
                                        {" "}
                                        ≈ $
                                        {userSpotBalance?.totalEquity > 0
                                            ? roundToTwoDecimals(userSpotBalance?.totalEquity *
                                              usdValue)
                                            : 0}
                                    </span> */}
                                    {"$"}{" "}
                                    {userSpotBalance?.totalEquity !== undefined
                                        ? roundToTwoDecimals(
                                              userSpotBalance?.totalEquity
                                          )
                                        : 0}
                                </>
                            )}
                        </h3>
                    </div>
                    <div className="Spot_blnc_Card">
                        <div className="d-flex align-items-center">
                            <h5>Unrealized P&L</h5>
                        </div>
                        <h3>
                            {hideBalance ? (
                                <>
                                    {"********"}
                                    <span> {"********"} </span>
                                </>
                            ) : (
                                <>
                                    {"$ "}
                                    {saveUserSpotBalance?.unrealizedPnl !==
                                    undefined
                                        ? roundToTwoDecimals(
                                              saveUserSpotBalance?.unrealizedPnl
                                          )
                                        : 0}{" "}
                                    {/* {saveUserSpotBalance?.unrealizedPnl !==
                                    undefined
                                        ? roundToTwoDecimals(
                                              saveUserSpotBalance?.unrealizedPnl
                                          )
                                        : 0}{" "}
                                    {"USDT"}
                                    <span>
                                        {" "}
                                        ≈ $
                                        {saveUserSpotBalance?.unrealizedPnl > 0
                                            ? saveUserSpotBalance?.unrealizedPnl *
                                              usdValue
                                            : 0}
                                    </span> */}
                                </>
                            )}
                        </h3>
                    </div>
                </div>
                <SpotCoinsTable
                    coins={userSpotBalance.coins}
                    search={handleSearchCoin}
                />
            </div>
        </Admin>
    );
};

export default InverseDerivatives;
