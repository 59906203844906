import React, { useState } from 'react'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import CommonButton from '../../../../common/CommonButton/CommonButton';
import Admin from '../../Admin';
import TopbarHeading from '../../common/TopbarHeading/TopbarHeading';
import "./Convert.scss";
import Noteicon from "../../../../assets/icons/note_icon.svg";
import walletIcon from "../../../../assets/images/admin/wallet.svg";
import TokenIcon from "../../../../assets/images/tether-usdt.svg";
import TokenIcon1 from "../../../../assets/images/btc.svg";
import Select, { components } from "react-select";
import IconConvert from "../../../../assets/icons/updown_icon.svg";
import fileIcon from "../../../../assets/icons/file-list-check.svg";
import fireicon from "../../../../assets/images/fireicon.svg";
import ConfirmConversionModal from './ConfirmConversionModal';

const Convert = () => {
  const options = [
    { value: "USDT", label: "USDT", icon: TokenIcon },
    { value: "BTC", label: "BTC", icon: TokenIcon1 },
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const { SingleValue } = components;

  const ValueOption = (props) => (
    <SingleValue {...props}>
      <img src={props.data.icon} />
      {props.data.label}
    </SingleValue>
  );

  const handleSelect = (e) => {
    setSelectedOption(e)
  }

  const orderdata = [
    {
      heading: "Single limit",
      text: "0.001  - 20 BTC",
    },
    {
      heading: "Fee",
      text: "",
      textnone: "d-none",
      textblock: "d-block",
    },
    {
      heading: "Receiving",
      text: "-- --",
    },
  ];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Admin>
      <div className='Common_TopBar'>
        <TopbarHeading title="Convert OTC" />
      </div>
      <div className='Common_admin_Contentarea'>
        <Row className='align-items-center'>
          <Col xs={8}>
            <div className="Convert_Market">
              <CommonButton title="Market" />
              <CommonButton title="Limit" className="grey-btn2 ms-3" />
            </div>
          </Col>
          <Col xs={4} className="d-md-flex justify-content-sm-end">
            <CommonButton icon={Noteicon} btnicon="d-block" title="Orders" className="grey-btn2" />
          </Col>
        </Row>
        <div className='Convert_Wallet'>
          <div className='Convert_Wallet_FromTo'>
            <div className='Convert_Wallet_FromTo_Label d-flex justify-content-between'>
              <label>From</label>
              <p><img src={walletIcon} alt='icon' /> Spot Wallet Available: <strong>3894.58</strong> USDT</p>
            </div>
            <div className='Convert_Wallet_FromTo_Inputselect'>
              <div className='InputBox d-flex justify-content-between'>
                <input type="text" placeholder='Please anount amount 0.0004 - 50' className="without_bgInput" />
                <span className='Maxbtn text_blue'>MAX</span>
              </div>
              <div className='common_select arrow_options'>
                <Select
                  // placeholder=""
                  defaultValue={options}
                  onChange={(event) => handleSelect(event)}
                  options={options}
                  classNamePrefix="react-select"
                  components={{ SingleValue: ValueOption }}
                  value={selectedOption}
                // menuIsOpen={true}
                />
              </div>
            </div>
          </div>
          <div className='ConverBtn'>
            <img src={IconConvert} alt='' />
          </div>
          <div className='Convert_Wallet_FromTo'>
            <div className='Convert_Wallet_FromTo_Label d-flex justify-content-between'>
              <label>To</label>
            </div>
            <div className='Convert_Wallet_FromTo_Inputselect'>
              <div className='InputBox d-flex justify-content-between'>
                <input type="text" placeholder='Please anount amount 0.0004 - 50' className="without_bgInput" />
                <span className='Maxbtn text_blue'>MAX</span>
              </div>
              <div className='common_select arrow_options'>
                <Select
                  // placeholder=""
                  defaultValue={options}
                  onChange={(event) => handleSelect(event)}
                  options={options}
                  classNamePrefix="react-select"
                  components={{ SingleValue: ValueOption }}
                  value={selectedOption}
                // menuIsOpen={true}
                />
              </div>
            </div>
          </div>

          <div className='Convert_Order'>
            <h5>1 BTC ≈ 24,567.57 USDT</h5>
            <div className='Convert_Order_Information'>
              <h6><img src={fileIcon} alt='icon' /> Order information</h6>
              <ul>
                {orderdata.map((item, i) => {
                  return (
                    <li className='d-flex align-items-center justify-content-between' key={i}>
                      <p>{item.heading}</p>
                      <h4><span className={item.textnone}>{item.text}</span> <span className={`Text_Fee ${item.textblock}`}><img src={fireicon} alt='fire' /> <small>0 Fee</small></span></h4>
                    </li>
                  )
                })}
              </ul>
              <CommonButton onClick={handleShow} className="w-100 mt-3" title="Preview Conversion" />
            </div>
          </div>
        </div>
      </div>
      <ConfirmConversionModal show={show} handleClose={handleClose} />
    </Admin>
  )
}

export default Convert