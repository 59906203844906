import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import footerLogo from "../../../assets/images/footerLogo.svg";
import location from "../../../assets/icons/location.svg";
import "./StakingFooter.scss";
import {
  DiscordIcon,
  FacebookIcon,
  GlobalIcon,
  InstaIcon,
  RedditIcon,
  RightarrowIcon,
  TelegramIcon,
  TiktokIcon,
  TwitterIcon,
} from "../../../assets/Svg/SvgImages";
import YoutubeIco from "../../../assets/icons/youtube.svg";
import { Accordion } from "react-bootstrap";

const StakingFooter = () => {
  return (
    <footer className="site-footer">
      <div className="staking-footer">
        <Container className="commn-container">
          <Row>
            <Col lg={4} xs={12}>
              <div className="staking-footer__list footer_list_logo mb-30 mb-lg-0">
                <div className="Footer_logo">
                  {/* <img src={footerLogo} alt="footer-logo" /> */}
                </div>
                <p>
                  Eterena Hybrid Exchange is a perpetual futures DEX that draws
                  its liquidity from the market’s largest centralized exchanges.
                  Eterna provides a host of unique trading tools and distributes
                  a portion of its trading fees to EHX token stakers.
                </p>
                <button
                  type="button"
                  className="btn-style Get_started_btn mb-4"
                >
                  Get started - <span>It’s free!</span>
                </button>

                <div className="Community_Social">
                  <h6>Community</h6>
                  <ul className="staking-footer__list staking-footer__list--social pe-0">
                    <li>
                      <div className="Social_Icons">
                        <a
                          href="https://twitter.com/Eterna_Hybrid"
                          target="_blank"
                        >
                          <TwitterIcon />
                        </a>
                        <span>Twitter</span>
                      </div>
                      <div className="Social_Icons">
                        <a href="https://t.me/eterna_hybrid" target="_blank">
                          <TelegramIcon />
                        </a>
                        <span>Telegram</span>
                      </div>
                      <div className="Social_Icons">
                        <a
                          href="https://medium.com/@Eterna_Hybrid"
                          target="_blank"
                        >
                          <DiscordIcon />
                        </a>
                        <span>Discord</span>
                      </div>
                      <div className="Social_Icons">
                        <a href="#" target="_blank">
                          <RedditIcon />
                        </a>
                        <span>Reddit</span>
                      </div>
                      <div className="Social_Icons">
                        <a href="#" target="_blank">
                          <img src={YoutubeIco} alt="youtube" />
                        </a>
                        <span>YouTube</span>
                      </div>
                      <div className="Social_Icons">
                        <a href="#" target="_blank">
                          <TiktokIcon />
                        </a>
                        <span>TikTok</span>
                      </div>
                      <div className="Social_Icons">
                        <a
                          href="https://instagram.com/eterna__hybrid?igshid=YmMyMTA2M2Y="
                          target="_blank"
                        >
                          <InstaIcon />
                        </a>
                        <span>Instagram</span>
                      </div>
                      {/* <a
                      href="https://www.facebook.com/EternaHybridExchange/"
                      target="_blank"
                    >
                      <FacebookIcon />
                    </a> */}
                    </li>
                  </ul>
                </div>
                <div className="Community_Social mt-4">
                  <h6>Language</h6>
                  <button type="button" className="btn-style Footer_Lang_btn">
                    <GlobalIcon /> <span>English/USD</span> <RightarrowIcon />
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={8} xs={12}>
              <Accordion defaultActiveKey="">
                <Row>
                  <Col lg={3} xs={12}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Company</Accordion.Header>
                      <Accordion.Body>
                        <ul className="staking-footer__list staking-footer__list--location">
                          <li>
                            <Link to="#">About us</Link>
                            <Link to="#">Contact</Link>
                            <Link to="#">Work with us</Link>
                            {/* <Link to="#">Referrals</Link> */}
                            <Link to="#">Security</Link>
                            <Link to="#">Blog</Link>
                            <Link to="#">Sitemap</Link>
                            <Link to="#">Terms & Conditions</Link>
                            <Link to="#">KYC/KYT Policy</Link>
                            <Link to="#">AML Policy</Link>
                            <Link to="#">Chainanalysis</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Col>
                  <Col lg={3} xs={12}>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Products</Accordion.Header>
                      <Accordion.Body>
                        <ul className="staking-footer__list">
                          <li>
                            {" "}
                            <Link to="#">Perpetual trading</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="#">Spot trading</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="#">Copy trading</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="#">Buy & Sell signals</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="#">Market data</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="#">Guilds</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="#">Academy</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="#">EHX token</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Col>
                  <Col lg={3} xs={12}>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Trade</Accordion.Header>
                      <Accordion.Body>
                        <ul className="staking-footer__list">
                          <li>
                            <Link to="#">BTC/USDT</Link>
                            <Link to="#">ETH/USDT</Link>
                            <Link to="#">SOL/USDT</Link>
                            <Link to="#">FIL/USDT</Link>
                            <Link to="#">MATIC/USDT</Link>
                            <Link to="#">APT/USDT</Link>
                            <Link to="#">SHIB/USDT</Link>
                            <Link to="#">XRP/USDT</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Col>
                  <Col lg={3} xs={12}>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Service</Accordion.Header>
                      <Accordion.Body>
                        <ul className="staking-footer__list">
                          <li>
                            <Link to="#">Help desk</Link>
                            <Link to="#">FAQ</Link>
                            <Link to="#">24/7 Live support</Link>
                            <Link to="#">Desktop App</Link>
                            <Link to="#">Mobile App</Link>
                            <Link to="#">Beginner’s Guide</Link>
                            <Link to="#">KYC Defi Wallet</Link>
                            <Link to="#">Eterna Ventures</Link>
                            <Link to="#">Bug Bounty</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Col>
                </Row>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer_Copyright text-center">
        <p>© {new Date().getFullYear()}, eterna.exchange</p>
      </div>
    </footer>
  );
};

export default StakingFooter;
