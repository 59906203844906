import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CommonButton from "../../../../../../common/CommonButton/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { saveUserTradeFilter } from "../../../../../../redux/_slices/user.slice";
import { updateLoader } from "../../../../../Exchange/redux/_slices/exchange.slice";
import PnlHistoryExport from "./Export";
const FiltersClosedProfitandLoss = () => {
    const [selectedDate, setSelectedDate] = useState("3M");
    const filters = useSelector((state) => state.user.userTradeFilters);
    const dispatch = useDispatch();

    const getOneWeekTimestamp = () => {
        const now = new Date();
        const last7Days = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        return last7Days.getTime();
    };

    const optionsDate = [
        { value: getOneWeekTimestamp(), label: "1 Week" },
        { value: "1M", label: "1 Month" },
        { value: "3M", label: "3 Month" },
    ];

    useEffect(() => {
        const timeObj = {
            ...filters,
            closedPnLFilter: selectedDate,
        };
        dispatch(saveUserTradeFilter(timeObj));
    }, [selectedDate]);

    const handleReset = () => {
        setSelectedDate(null);
        dispatch(updateLoader(true));
    };

    const handleDateChange = (selectedOption) => {
        setSelectedDate(selectedOption ? selectedOption.value : null);
        dispatch(updateLoader(true));
    };

    return (
        <div className="Transaction_History_selectBox d-flex flex-wrap mb-4 mb-xl-12 mt-4 align-items-end">
            <div className="common_select col-xl-4 col-xs-12">
                <label>Time</label>
                <Select
                    placeholder="1 Week"
                    value={
                        optionsDate.find(
                            (option) => option.value === selectedDate
                        ) || null
                    }
                    onChange={handleDateChange}
                    options={optionsDate}
                    classNamePrefix="react-select"
                />
            </div>
            <div className="buttonWrapper col-xl-4 col-xs-6">
                <CommonButton
                    title="Reset"
                    className={`btn-primary fullHieght ${
                        !selectedDate && "disabled"
                    }`}
                    onClick={handleReset}
                />
            </div>
            <div className="buttonWrapper col-xl-4 col-xs-6">
                <PnlHistoryExport />
            </div>

            {/* <div className="common_select">
                    <label>Contracts</label>
                    <Select
                        placeholder="All Assets"
                        defaultValue={contract}
                        onChange={setContract}
                        options={optionsContracts}
                        classNamePrefix="react-select"
                        value={contract}
                        // menuIsOpen={true}
                    />
                </div>
                <div className="common_select">
                    <label>Filled Type</label>
                    <Select
                        placeholder="Limit"
                        defaultValue={filledType}
                        onChange={setFilledType}
                        options={optionsFilledType}
                        classNamePrefix="react-select"
                        value={filledType}
                        // menuIsOpen={true}
                    />
                </div> */}
            {/* <div className="common_select">
                <label>Date</label>
                <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="y-MM-dd"
                    placeholderText="Start Date"
                    value={startDate}
                    defaultValue={startDate}
                    maxDate={new Date()}
                />
            </div>
            <div className="common_select">
                <DatePicker
                    selectsEnd
                    selected={endDate ? new Date(endDate) : null}
                    onChange={handleEndDateChange}
                    dateFormat="y-MM-dd"
                    placeholderText="End Date"
                    maxDate={new Date()}
                />
            </div> */}
        </div>
    );
};

export default FiltersClosedProfitandLoss;
