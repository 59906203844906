import React from 'react'
import "./DashboardSlider.scss"
import Slider from "react-slick";
import { Container } from 'react-bootstrap';
import logoimg from "../../../../../assets/images/Logo.svg";
import CommonButton from '../../../../../common/CommonButton/CommonButton';

const DashboardSlider = () => {
    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <>
            <div className='Dashboard_Slider'>
                <Container>
                    <Slider {...settings}>
                        <div className='Slider_Box Slider_One'>
                            <img className='Slider_logo' src={logoimg} alt='logo'></img>
                            <div className='Slider_Content text-center'>
                                <h3>Trade Perps</h3>
                                <span><i>with</i></span>
                                <h2>Unlimited Possibilities</h2>
                                <CommonButton title="LEARN MORE" className="btn-radius" />
                            </div>
                        </div>
                        <div className='Slider_Box Slider_Two'>
                            <img className='Slider_logo' src={logoimg} alt='logo'></img>
                            <div className='Slider_Content text-center'>
                                <h3>Stake EHX</h3>
                                <span><i>to</i></span>
                                <h2>Earn rewards</h2>
                                <CommonButton title="EARN NOW" className="btn-radius" />
                            </div>
                        </div>
                        <div className='Slider_Box Slider_Three'>
                            <img className='Slider_logo' src={logoimg} alt='logo'></img>
                            <div className='Slider_Content text-center'>
                                <h3>Become a top trader</h3>
                                <span><i>with</i></span>
                                <h2>Competitive leaderboards</h2>
                                <CommonButton title="SEE THE TOP TRADERS" className="btn-radius" />
                            </div>
                        </div>
                        <div className='Slider_Box Slider_Four'>
                            <img className='Slider_logo' src={logoimg} alt='logo'></img>
                            <div className='Slider_Content text-center'>
                                <h3>Get free airdrops</h3>
                                <span><i>when</i></span>
                                <h2>you refer your friends</h2>
                                <CommonButton title="REFER SOMEONE" className="btn-radius" />
                            </div>
                        </div>
                        <div className='Slider_Box Slider_Five'>
                            <img className='Slider_logo' src={logoimg} alt='logo'></img>
                            <div className='Slider_Content text-center'>
                                <h3>Own your future</h3>
                                <span><i>with</i></span>
                                <h2>decentralized finance</h2>
                                <CommonButton title="FIND OUT HOW" className="btn-radius" />
                            </div>
                        </div>
                    </Slider>
                </Container>
            </div>
        </>
    )
}

export default DashboardSlider
