import React, { useState, useEffect, forwardRef } from "react";
import { Col, Row, Form, InputGroup } from "react-bootstrap";

import "./DepositPopStyle.scss";
import {
  useCurrentProvider,
  useTotalUsdBalanceFromContract,
} from "../../hooks/hooks.service";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { amountValidationOnInput } from "../../../../_utils/comman";
import { toastAlert } from "../../../../common/Toast/Toast";
import DepositFeeModal from "../DepositFeeModal/DepositFeeModal";
import { ApiServices } from "../../services/api.services";
import check_cross from "../../../../assets/images/check_cross.svg";
import checkok from "../../../../assets/icons/checkIcon.svg";

const DepositAndWithdrawalFrom = forwardRef((props, ref) => {
  const { title, handleClose } = props;

  const userBalances = useAppSelector((state) => state.exchange.userBalances);
  const chainId = useAppSelector((state) => state.user.chainId);
  const walletAddress = useAppSelector((state) => state.user.walletAddress);

  const [coin, setCoin] = useState(null);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  // const confirmationModalRef = useRef();
  const [confModal, setConfModal] = useState(false);
  const [address, setAddress] = useState(walletAddress);
  const [spotBalance, setSpotBalance] = useState(0);

  // useImperativeHandle(ref, () => ({
  //   handleModal(value) {
  //     confirmationModalRef.current.handleModal(value);
  //   },
  // }));

  /** Use Coustume Hooks   */
  const currentProvider = useCurrentProvider();
  // const usdtBalance = useTotalUsdBalanceFromContract()
  const { decimals, totalUsdBalance } = useTotalUsdBalanceFromContract();

  const getSpotBalance = async () => {
    let spotBalance = await ApiServices.getUserSpotBalance(walletAddress);
    if (spotBalance.data.result.balance) {
      let transferBalance = spotBalance.data.result.balance[0]?.transferBalance;
      setSpotBalance(transferBalance);
    }
  };

  const handleDeposit = () => {
    // handleClose();
    setConfModal(true);
  };

  const handleModalClose = () => {
    setConfModal(false);
  };

  /**
   * Handle User withdral amount to Contract Address
   */
  const handleWithdral = async () => {
    try {
      setIsLoader(true);
      let dataObj = {
        address: address,
        amount: Number(amount),
      };
      const res = await toastAlert.waitingPromis(
        ApiServices.withdraw(dataObj),
        "Awaiting confirmation...",
        "Withdraw Successful."
      );

      if (res) {
        setIsLoader(false);
        handleClose();
      }
    } catch (error) {
      setIsLoader(false);
      // throw error;
    }
  };

  /**
   * Handle Select Coin for payment
   * @param {} data
   */
  // const handleOnCoin = (data) => {
  //   setCoin(userBalances[0]);
  // };

  /**
   * Set max Balace for deposit or withdral
   */
  const handleMax = async () => {
    console.log("max", totalUsdBalance);
    // setAmount(amountFormate(totalUsdBalance, decimals, 8));
    setAmount(title === "deposit" ? totalUsdBalance : spotBalance);
  };

  /**
   * handle on change for input amount
   * @param {*} event
   */
  const handleOnChangeAmount = (event) => {
    let maxAmount;
    if (title === "deposit") {
      maxAmount = totalUsdBalance;
    } else {
      maxAmount = spotBalance;
    }
    const { value } = event.target;
    if (Number(value) === 0) {
      setError("Please enter amount greater than 0");
    } else if (value > maxAmount) {
      if (maxAmount === 0) {
        setError(
          `You have no balance to ${
            title === "deposit" ? "deposit" : "withdraw"
          }`
        );
      } else {
        setError(
          `Please enter an amount less than max ${
            title === "deposit" ? "deposit" : "withdraw"
          } amount.`
        );
      }
    } else {
      setError(null);
    }
    setAmount(value);
  };

  useEffect(() => {
    getSpotBalance();
  }, []);

  useEffect(() => {
    if (userBalances?.length) {
      setCoin(userBalances[0]);
    }
  }, [userBalances, totalUsdBalance]);

  useEffect(() => {
    if (!walletAddress) {
      handleClose();
    }
  }, [walletAddress]);

  const handleAddressChange = (event) => {
    const { value } = event.target;
    setAddress(value);
  };

  return (
    <Form className="deposite_withdrw nondraggable_card" data-testid={title}>
      <div className="deposite_set">
        <Row>
          {/* <Col lg={12}>
            <div className="wrap_input">
              <span className="label">
                {" "}
                {title === "deposit" ? "Deposit" : "Withdraw"} to
              </span>
              <div className="deposit_select common_select">
                <Select
                  defaultValue={TRADE_OPTIONS[0]}
                  classNamePrefix="react-select"
                  options={TRADE_OPTIONS}
                />
              </div>
            </div>
          </Col> */}
          <Col lg={12}>
            <div className="wrap_input">
              <span className="label">Coin</span>
              {/* <div className="deposit_select common_select">
                <Select
                  value={coin}
                  formatOptionLabel={fromateCoinOptionLable}
                  onChange={handleOnCoin}
                  classNamePrefix="react-select"
                  options={userBalances}
                />
              </div> */}
              <InputGroup>
                <Form.Control
                  autoComplete="off"
                  placeholder=""
                  type="text"
                  name="coin"
                  value="USDT"
                  disabled
                />
              </InputGroup>
            </div>
          </Col>
          {title !== "deposit" && (
            <Col lg={12}>
              <div className="wrap_input">
                <span className="label">Wallet address</span>
                <InputGroup>
                  <Form.Control
                    autoComplete="off"
                    placeholder=""
                    type="text"
                    name="address"
                    defaultValue={address}
                    onChange={(event) => handleAddressChange(event)}
                    // value="USDT"
                  />
                </InputGroup>
              </div>
            </Col>
          )}
          <Col lg={12}>
            <div className="input_inner">
              {title === "deposit" ? (
                <div className="input_label">
                  Amount{" "}
                  <span>
                    Available:{" "}
                    {totalUsdBalance !== undefined &&
                    totalUsdBalance !== null ? (
                      totalUsdBalance + " " + "USDT"
                    ) : (
                      <Loader className="des_inner" />
                    )}
                  </span>
                </div>
              ) : (
                <div className="input_label">
                  Amount{" "}
                  <span>
                    Available (Funding):{" "}
                    {spotBalance !== undefined ? (
                      spotBalance + " " + "USDT"
                    ) : (
                      <Loader className="des_inner" />
                    )}
                  </span>
                </div>
              )}

              <div className="Max_Value_Input">
                <InputGroup>
                  <Form.Control
                    autoComplete="off"
                    placeholder="0.0"
                    data-testid="dw-input-amount"
                    type="text"
                    name="amount"
                    value={amount}
                    onChange={(event) => {
                      if (/^\d*(\.\d{0,8})?$/.test(event.target.value)) {
                        handleOnChangeAmount(event);
                      }
                    }}
                    onInput={(event) => {
                      amountValidationOnInput(event, 8);
                    }}
                  />
                </InputGroup>
                <button
                  type="button"
                  className="maximum_btn"
                  onClick={handleMax}
                >
                  MAX
                </button>
              </div>
            </div>
            {error && <Form.Text>{error}</Form.Text>}

            <div className="Add_Funds">
              <small className="form-text mt-2">
                Adding funds to your exchange account may take a few minutes, so
                please don't close this window. You'll receive a notification
                once it's complete.
              </small>
            </div>

            <div className="Deposit_Move_funds d-none">
              <div className="text-center py-4 my-md-2">
                <Loader />
                <h5>Confirm the transaction in your wallet.</h5>
              </div>
              <h6 className="Funds_Status mb-0">
                <span>Status</span> Waiting for you to confirm
              </h6>
            </div>

            <div className="Deposit_Move_funds d-none">
              <div className="text-center py-4 my-md-2">
                <img src={check_cross} alt="" />
                <h5 className="mt-3 mt-md-4">Deposit failed</h5>
              </div>
              <h6 className="Funds_Status mb-0">
                <span>Status</span> Waiting for you to confirm
              </h6>
            </div>

            <div className="Deposit_Move_funds d-none">
              <div className="text-center py-4 my-md-2">
                <img src={checkok} alt="" />
                <h5 className="mt-3 mt-md-4">
                  Confirm the transaction in your wallet.
                </h5>
              </div>
              <h6 className="Funds_Status mb-0">
                <span>Status</span> Deposit confirmed
              </h6>
            </div>
          </Col>
          {/* <Col lg={12} className="mt-4">
            {title === "withdrawal" && (
              <span>Daily withdrawal amount remaining: 500.000 USD</span>
            )}
          </Col> */}
          <Col lg={12} className="mt-2 text-end">
            <button
              data-testid="dw-confirm-button"
              type="button"
              className="btn-primary Arrow_Animate_Btn w-100"
              disabled={error || amount <= 0 ? true : false}
              onClick={() =>
                title === "deposit" ? handleDeposit() : handleWithdral()
              }
            >
              Confirm
              {/* (Move funds) - (Deposit again) */}
            </button>
          </Col>
        </Row>
      </div>
      {/* <ModalComponet 
      dataTestid={title}
      backdrop="static"
      ref={confirmationModalRef}
      title="Confirmation"
      className="select_lang_box depsite_wdr"
    > */}
      <DepositFeeModal
        amount={amount}
        coin={coin}
        showModal={confModal}
        handleClose={handleModalClose}
        currentProvider={currentProvider}
      />
      {/* </ModalComponet> */}
    </Form>
  );
});

const Loader = ({ className }) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center loader loader--${className}`}
    >
      <div className="dbl-spinner" />
    </div>
  );
};

export default DepositAndWithdrawalFrom;
