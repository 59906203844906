import React, { useState, useEffect } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { RightarrowIcon } from '../../../../../assets/Svg/SvgImages';
import CommonButton from '../../../../../common/CommonButton/CommonButton';
import "./AssetsDashboard.scss";
import eysIcon from "../../../../../assets/images/admin/eye-slash.svg"
import Select from "react-select";
import RecentTransactionTable from './RecentTransactionTable';
import AssetsProgressbar from './AssetsProgressbar';
import ReferIcon from "../../../../../assets/images/gfx-rewards.svg";
import ehxIcon from "../../../../../assets/icons/menuicon6.svg"
import newsicon from "../../../../../assets/icons/menuicon12.svg"
import TrendingPairs from '../TrendingPairs/TrendingPairs';
import OverviewAssetsTable from '../../Overview/OverviewAssetsTable/OverviewAssetsTable';
import { useAppSelector } from '../../../../../hooks/redux.hooks';
import { ApiServices } from '../../../../Exchange/services/api.services';
import { Link } from "react-router-dom";

const AssetsDashboard = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [spotBalance, setSpotBalance] = useState(0);
    const [derivativeBalance, setDerivativeBalance] = useState(0);

    const walletAddress = useAppSelector((state) => state.user.walletAddress);

    const options = [
        { value: "BTC", label: "BTC" },
        { value: "USDT", label: "USDT" },
    ];

    const getAllBalance = async () => {
        let AllBalances = await ApiServices.getAssestsBalance({address: walletAddress});
        if (AllBalances?.data) {
          setSpotBalance(AllBalances?.data?.spotWalletBalance);
          setDerivativeBalance(AllBalances?.data?.derivativeWalletBalance)
          setSelectedOption(AllBalances?.data?.coin)
        }
    };

    useEffect(() => {
        getAllBalance();
    }, []);

    

    return (
        <>
            <Row>
                <Col xs={12} lg={7} xl={8}>
                    <div className="Assets_Overview">
                        <div className="Assets_Overview_Header">
                            <h3>Assets Overview</h3>
                            <div className="Assets_Overview_Header_Btns">
                                <CommonButton
                                    title="Deposit"
                                    className="grey-btn2"
                                />
                                <CommonButton
                                    title="Withdraw"
                                    className="grey-btn2"
                                />
                                <CommonButton
                                    title="Transfer"
                                    className="grey-btn2"
                                />
                                <span className="Assets_Arrow">
                                    <RightarrowIcon />
                                </span>
                            </div>
                        </div>
                        <div className="Assets_Overview_Tabs">
                            <Tabs
                                defaultActiveKey="spot"
                                id="uncontrolled-tab-example"
                                className="Bottom_Border_Tabs"
                            >
                                <Tab eventKey="spot" title="Spot">
                                    <Row>
                                        <Col xs={12} xl={7} xxl={8}>
                                            <div className="Spot_Account">
                                                <div className="d-flex align-items-center">
                                                    <h6>Account Balance</h6>
                                                    <span className="hide_Blnc">
                                                        <img
                                                            src={eysIcon}
                                                            alt="eye"
                                                        />{" "}
                                                        Hide Balance
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center mt-2">
                                                    <h5>{spotBalance}</h5>
                                                    <div className="common_select">
                                                        <Select
                                                            placeholder="1 minute"
                                                            defaultValue={
                                                                selectedOption
                                                            }
                                                            onChange={
                                                                setSelectedOption
                                                            }
                                                            options={options}
                                                            classNamePrefix="react-select"
                                                            value={
                                                                selectedOption
                                                            }
                                                        />
                                                    </div>
                                                    <h4>≈ USD$28,730.10</h4>
                                                </div>
                                            </div>
                                            <RecentTransactionTable />
                                        </Col>
                                        <Col xs={12} xl={5} xxl={4}>
                                            <AssetsProgressbar />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="margin" title="Margin">
                                    dhsdshu
                                </Tab>
                                <Tab eventKey="futures" title="Futures">
                                    <Row>
                                        <Col xs={12} xl={7} xxl={8}>
                                            <div className="Spot_Account">
                                                <div className="d-flex align-items-center">
                                                    <h6>Trading Balance</h6>
                                                    <span className="hide_Blnc">
                                                        <img
                                                            src={eysIcon}
                                                            alt="eye"
                                                        />{" "}
                                                        Hide Balance
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center mt-2">
                                                    <h5>
                                                        {derivativeBalance}{" "}
                                                        <span>
                                                            {selectedOption}
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <RecentTransactionTable />
                                        </Col>
                                        <Col xs={12} xl={5} xxl={4}>
                                            <AssetsProgressbar />
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={5} xl={4}>
                    <div className="Refer_Code">
                        <div className="Refer_Code_Box">
                            <Link
                                to={"https://eterna.exchange/referral-system/"}
                                target="_blank"
                            >
                                <h3>
                                    Refer your friends and <em>level up</em>
                                </h3>
                                <img src={ReferIcon} alt="icon" />
                            </Link>
                        </div>
                        <div className="Refer_Code_Join">
                            <img src={ehxIcon} alt="icon" />
                            <h4>JOIN THE EHX GUILD</h4>
                            <RightarrowIcon />
                        </div>

                        <div className="Refer_Code_Newscontent">
                            <div className="Refer_News">
                                <img src={newsicon} alt="icon" />
                                <h4> News & Announcements</h4>
                                <RightarrowIcon />
                            </div>
                            {/* <ul className='News_List'>
                                <li>
                                    <p>EHX lists new trading pairs: <strong>EHX, CGPT, MMX and GRM</strong></p>
                                    <p>New trading pairs will be listed 2023-04-05 at 08:01:10 UTC</p>
                                </li>
                                <li>
                                    <p>EHX to delists trading pairs: <strong>EVA, TRX, DRM</strong></p>
                                    <p>Please note that these trading pairs will be delisted 2023-04-05 at 08:01:10 UTC</p>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </Col>
            </Row>
            <TrendingPairs />
            <Row className="mt-4">
                <Col xs={12} xl={7}>
                    <OverviewAssetsTable />
                </Col>
                <Col
                    xs={12}
                    xl={5}
                    className="OverviewTransaction_Table mt-4 mt-xl-0"
                >
                    <RecentTransactionTable />
                </Col>
            </Row>
        </>
    );
}

export default AssetsDashboard