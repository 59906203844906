import React from 'react'
import { Link } from 'react-router-dom'
import "./RecentDepositTable.scss"
import BlueIcon from "../../../../../assets/icons/arrow-right-square.svg"
import ustdicon from "../../../../../assets/images/tether-usdt.svg";
import ustdicon1 from "../../../../../assets/images/btc.svg";
import LinkIcon from "../../../../../assets/images/admin/link.svg"
import CopyIcon from "../../../../../assets/images/admin/copy.svg"
import RightarrowIcon from "../../../../../assets/images/admin/arrow-right-circle.svg"

const RecentDepositTable = () => {
    const Usdtdata = [
        {
            tokenicon: ustdicon,
            tokenvalue: "2000 USDT",
            status: "Completed",
            timenetwork: "2023-15-11 20:18",
            tokenname: "ETH",
            walletname: "Spot Wallet",
            taxid: "0x8572e2d.....488a222a",
            tokenaddress: "0x612e2daddc89d91409e40f946f9f7cfe422e777e",
        },
        {
            tokenicon: ustdicon1,
            tokenvalue: "2000 USDT",
            status: "Completed",
            timenetwork: "2023-15-11 20:18",
            tokenname: "ETH",
            walletname: "Spot Wallet",
            taxid: "0x8572e2d.....488a222a",
            tokenaddress: "0x612e2daddc89d91409e40f946f9f7cfe422e777e",
        },
    ];
    return (
        <>
            <div className='RecentDeposit_Table'>
                <div className='RecentDeposit_Table_Heading d-flex justify-content-between'>
                    <h3>Recent Deposits</h3>
                    <Link to="#"><img src={BlueIcon} alt='icon' /></Link>
                </div>
                <div className='RecentDeposit_Table_data'>
                    <ul>
                        {Usdtdata.map((item, i) => {
                            return (
                                <li key={i}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='RecentDeposit_Network'>
                                            <div className='d-flex align-items-center'>
                                                <h3><img src={item.tokenicon} alt='icon' /> {item.tokenvalue}</h3>
                                                <span className='textgreen_btn'>{item.status}</span>
                                            </div>
                                            <div className='d-flex align-items-center py-3'>
                                                <p>{item.timenetwork} <span className='ps-2'>Network <strong>{item.tokenname}</strong></span></p>
                                                <p>
                                                    Address <strong>{item.tokenaddress}</strong>
                                                    <img className='LinkIcon px-2' src={LinkIcon} alt='icon' />
                                                    <img className='CopyIcon' src={CopyIcon} alt='icon' />
                                                </p>
                                                <p>TxID <strong>{item.taxid}</strong> <img className='CopyIcon ps-2' src={CopyIcon} alt='icon' /></p>
                                            </div>
                                            <p>Deposit Wallet <span> <strong>{item.walletname}</strong></span></p>
                                        </div>
                                        <div className='Table_Arrow'>
                                            <img src={RightarrowIcon} alt='icon' />
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default RecentDepositTable
