import binance_wallet_icon from "../assets/images/binancewallet.svg";
import metamask_wallet_icon from "../assets/images/metamask.svg";
import walletconnect_wallet_icon from "../assets/images/wallet_connect_new.svg";
import trust_wallet_icon from "../assets/images/trus_wallet.svg";
import bsc_network_icon from "../assets/images/binancewallet.svg";
import cronos_network_icon from "../assets/images/cronos.png";
import eterna_wallet_icon from "../assets/images/logo_icon.svg";
import safePal_wallet_icon from "../assets/images/safepal.svg";
import pocket_wallet_icon from "../assets/images/toknpocket.svg";
import coinbase_wallet_icon from "../assets/images/CoinbaseWallet.svg";
import brave_wallet_icon from "../assets/images/brave.svg";
import ethereum_icon from "../assets/images/ethereum_icon2.svg";
import ledger_wallet_icon from "../assets/images/ledger-live.svg";
import USDT_ABI from "../ABI/p2pTestnet/USDT_ABI.abi.json";
import EHX_ABI from "../ABI/p2pTestnet/EHX_ABI.abi.json";
import USDT_MAINNET_ABI from "../ABI/p2pMainnet/USDT_ABI.abi.json";
import EHX_MAINNET_ABI from "../ABI/p2pMainnet/EHX_ABI.abi.json";

import {
  BINANCE_CONTRACT_ADDRESS,
  BSC_CONTRACT_ADDRESS,
  BSC_TOKEN_ADDRESS,
  CRONOS_CONTRACT_ADDRESS,
  ETH_CONTRACT_ADDRESS,
  ETH_TOKEN_ADDRESS,
  GOERLI_CONTRACT_ADDRESS,
  EHX_TOKEN_ADDRESS,
  NETWORK,
  P2P_CONTRACT_ADDRESS,
  P2P_NETWORK
} from "./constants";
// import { RPCURL } from "../pages/Staking/constants/constants";
import store from "../redux/_store/configureStore";
export const USDT_TOKEN_ADDRESS = process.env.REACT_APP_USDT_TOKEN_ADDRESS;

export const WALLET_INFO = [
  {
    name: "Eterna App",
    key: "eterna_wallet",
    icon: eterna_wallet_icon,
    url: "",
  },
  {
    name: "walletconnect",
    key: "walletconnect",
    icon: walletconnect_wallet_icon,
    url: "",
  },
  {
    name: "Metamask",
    key: "metamask",
    icon: metamask_wallet_icon,
    url: "https://metamask.io/",
  },
  {
    name: "Coinbase Wallet",
    key: "Coinbase_Wallet",
    icon: coinbase_wallet_icon,
    url: "",
  },
  {
    name: "Trust Wallet",
    key: "trust_wallet",
    icon: trust_wallet_icon,
    url: "",
  },
  // {
  //   name: "Trezor",
  //   key: "trezor_wallet",
  //   icon: trezor_wallet_icon,
  //   url: "",
  // },
  {
    name: "SafePal",
    key: "SafePal",
    icon: safePal_wallet_icon,
    url: "",
  },
  {
    name: "Brave",
    key: "Brave",
    icon: brave_wallet_icon,
    url: "",
  },
  {
    name: "Token Pocket",
    key: "Token_Pocket",
    icon: pocket_wallet_icon,
    url: "",
  },
  {
    name: "Binance Wallet",
    key: "binance_wallet",
    icon: binance_wallet_icon,
    url: "https://academy.binance.com/en/articles/how-to-use-binance-chain-wallet",
  },
  {
    name: "Ledger",
    key: "ledger_wallet",
    icon: ledger_wallet_icon,
    url: "",
  },
];

const TESTNET_TOKENS = [
  {
    name: "USDT",
    token_address: USDT_TOKEN_ADDRESS,
    token_abi: USDT_ABI,
  },
  {
    name: "EHX",
    token_address: EHX_TOKEN_ADDRESS,
    token_abi: EHX_ABI,
  },
];

const MAINNET_TOKENS = [
  {
    name: "USDT",
    token_address: USDT_TOKEN_ADDRESS,
    token_abi: USDT_MAINNET_ABI,
  },
  {
    name: "EHX",
    token_address: EHX_TOKEN_ADDRESS,
    token_abi: EHX_MAINNET_ABI,
  },
];

export const TOKEN_INFO =
  P2P_NETWORK == "mainnet" ? MAINNET_TOKENS : TESTNET_TOKENS;

const TESTNET_NETWORK_INFO = [
  {
    name: "Binance Smart Chain - Testnet",
    value: "binance",
    label: "BNB Chain",
    smallLabel: "BNB",
    icon: bsc_network_icon,
    symbol: "BNB",
    rpc_url: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    chain_id: 97,
    explorer_url: "https://testnet.bscscan.com",
    decimals: 18,
    binance_wallet_network_id: "bsc-testnet",
    contract_address: BSC_CONTRACT_ADDRESS,
    depositValue: 3,
    tokenAddress: "",
  },
  // {
  //   name: "Goerli Testnet",
  //   value: "goerli",
  //   label: "Goerli",
  //   smallLabel: "Goerli",
  //   icon: bsc_network_icon,
  //   symbol: "GoerliETH",
  //   rpc_url: "https://goerli.infura.io/v3/",
  //   chain_id: 5,
  //   explorer_url: "https://goerli.etherscan.io",
  //   decimals: 18,
  //   contract_address: GOERLI_CONTRACT_ADDRESS,
  //   depositValue: 5,
  //   tokenAddress: "",
  // },
  {
    name: "Ethereum Mainnet",
    value: "eth",
    label: "Ethereum",
    smallLabel: "Ethereum",
    icon: ethereum_icon,
    symbol: "ETH",
    rpc_url: "https://mainnet.infura.io/v3/",
    chain_id: 1,
    explorer_url: "https://etherscan.io",
    decimals: 18,
    contract_address: ETH_CONTRACT_ADDRESS,
    depositValue: 5,
    tokenAddress: ETH_TOKEN_ADDRESS,
  }
];

const MAINNET_NETWORK_INFO = [
  {
    name: "Ethereum Mainnet",
    value: "eth",
    label: "Ethereum",
    smallLabel: "Ethereum",
    icon: ethereum_icon,
    symbol: "ETH",
    rpc_url: "https://mainnet.infura.io/v3/",
    chain_id: 1,
    explorer_url: "https://etherscan.io",
    decimals: 18,
    contract_address: ETH_CONTRACT_ADDRESS,
    depositValue: 5,
    tokenAddress: ETH_TOKEN_ADDRESS,
  },
  {
    name: "Binance Smart Chain",
    value: "binance",
    label: "BSC",
    smallLabel: "BNB",
    icon: bsc_network_icon,
    symbol: "BSC",
    rpc_url: "https://bsc-dataseed.binance.org",
    chain_id: 56,
    decimals: 18,
    explorer_url: "https://bscscan.com",
    binance_wallet_network_id: "bsc-mainnet",
    contract_address: BSC_CONTRACT_ADDRESS,
    depositValue: 3,
    tokenAddress: BSC_TOKEN_ADDRESS,
  },
];

const MAINNET_NETWORK = [
  {
    name: "Ethereum Mainnet",
    value: "eth",
    label: "Ethereum",
    smallLabel: "Ethereum",
    icon: ethereum_icon,
    symbol: "ETH",
    rpc_url: "https://mainnet.infura.io/v3/",
    chain_id: 1,
    explorer_url: "https://etherscan.io",
    decimals: 18,
    contract_address: ETH_CONTRACT_ADDRESS,
    depositValue: 5,
    tokenAddress: ETH_TOKEN_ADDRESS,
  },
];

export const NETWORK_INFO =
  NETWORK == "mainnet" ? MAINNET_NETWORK_INFO : TESTNET_NETWORK_INFO;

export const NETWORK_INFOMATION =
  NETWORK == "mainnet" ? MAINNET_NETWORK : TESTNET_NETWORK_INFO;

export const PAYMENT_COINS = [
  {
    name: "BNB",
    symbol: "BNB",
    chain_id: NETWORK === "mainnet" ? 56 : 97,
    decimals: 18,
    icon: bsc_network_icon,
    contract_address: BINANCE_CONTRACT_ADDRESS,
    currency_address: "0x0000000000000000000000000000000000000000",
  },
  {
    name: "USDT",
    symbol: "USDT",
    chain_id: NETWORK === "mainnet" ? 56 : 97,
    decimals: 18,
    icon: bsc_network_icon,
    contract_address: BINANCE_CONTRACT_ADDRESS,
    currency_address: "0x6BDfc47f639bEa9b3e0A9C76fc1099E7c2d32d63",
  },

  {
    name: "CRO",
    symbol: "CRO",
    chain_id: NETWORK === "mainnet" ? 25 : 338,
    decimals: 18,
    icon: cronos_network_icon,
    contract_address: CRONOS_CONTRACT_ADDRESS,
    currency_address: "0x0000000000000000000000000000000000000000",
  },
];

export const TRADE_OPTIONS = [
  {
    value: "spot",
    label: "Funding Wallet",
  },
  {
    value: "derivative",
    label: "Derivative Wallet",
  },
];
