import React, { useState } from "react";
import ProgressBar from "react-customizable-progressbar";

const AssetsProgressbar = ({ progress }) => {
    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };
    return (
        <>
            <div className="Assets_Progressbar mt-4 mt-xl-0">
                {/* <div className="Assets_Progressbar_tabs">
                    <span className="active">PORTFOLIO</span>
                    <span>aLLOCATION</span>
                </div> */}
                <ProgressBar
                    radius={100}
                    progress={progress}
                    strokeWidth={18}
                    strokeColor="#1745F5"
                    strokeLinecap="round"
                    trackStrokeWidth={18}
                    counterClockwise
                    className="ProgressBar"
                >
                    <div className="ProgressBar_value">
                        <h6>
                            USDT{" "}
                            <span className="text_blue">
                                {progress <= 100
                                    ? roundToTwoDecimals(progress)
                                    : 100}
                                %
                            </span>
                        </h6>
                    </div>
                </ProgressBar>

                {/* <div className="Assets_Progressbar_Value">
                    <span>1M</span>
                    <span>6M</span>
                    <span>1Y</span>
                    <span className="active">all time</span>
                </div> */}
            </div>
        </>
    );
};

export default AssetsProgressbar;
