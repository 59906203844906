import React, { memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  InfoIcon,
  MinusIcon,
  PlusIcon,
} from "../../../../../assets/Svg/SvgImages";
import RangeSlider from "../../../../../common/RangeSlider/RangeSlider";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import PositionClosedModal from "./PositionClosedModal";
import { useAppSelector } from "../../../../../hooks/redux.hooks";
import { showDecimal } from "../../../../../_utils/comman";
import {
  calUnrealizedPNL,
  getMarkPrice,
  getProfitPostion,
} from "../../../utils/comman";
import {
  getFeeCalculationsBuy,
  getFeeCalculationsSell,
} from "../../../../../services/HelperServices";
import LeverageSlider from "../../../../../common/LeverageSlider/LeverageSlider";
import { set } from "js-cookie";

const LimitPositionModal = ({
  handleClose,
  show,
  marketInfo,
  closePositionType,
  handleSubmit,
  lastPrice,
}) => {
  const step = 0.5;
  const [showclosed, setShowclosed] = useState(false);
  const handleClosed = () => setShowclosed(false);
  const handleShowclosed = () => setShowclosed(true);
  const [price, setPrice] = useState(0);
  const [sizeRange, setSizeRange] = useState(100);
  const [size, setSize] = useState();
  const [currentPair, setCurrentPair] = useState({});
  const [expectedLoss, setExpectedLoss] = useState(0);
  const [pnlPercentage, setPnlPercentage] = useState(0);
  const pairList = useAppSelector((state) => state.exchange.pairList);
  const otherFee = useAppSelector((state) => state.exchange.otherFee);
  const makrTkrFee = useAppSelector((state) => state.exchange.makrTkrFee);
  const allMarkPrice = useAppSelector((state) => state?.exchange?.allMarkPrice);

  useEffect(() => {
    console.log(
      "position current",
      marketInfo,
      Number(getMarkPrice(marketInfo?.currentPosition?.pair, allMarkPrice))
    );
    if (closePositionType === "Limit") {
      setPrice(
        Number(getMarkPrice(marketInfo?.currentPosition?.pair, allMarkPrice))
      );
    } else {
      setPrice(
        Number(getMarkPrice(marketInfo?.currentPosition?.pair, allMarkPrice))
      );
    }
    // if (marketInfo?.marketSize) {
    //   setSize(marketInfo?.marketSize);
    //   setSizeRange(
    //     (
    //       (marketInfo?.marketSize / marketInfo?.currentPosition?.order_size) *
    //       100
    //     ).toFixed(2)
    //   );
    // } else {
    setSize(marketInfo?.currentPosition?.order_size);
    setSizeRange(100);
    // }
    // setPrice(marketInfo?.currentPosition?.price);

    if (pairList && pairList?.length && marketInfo?.currentPosition?.pair) {
      let pair = pairList.find(
        (item) =>
          item?.pair_key?.replace("_", "").toLowerCase() ==
          marketInfo?.currentPosition?.pair.toLowerCase()
      );
      if (pair) {
        setCurrentPair(pair);
      }
    }
  }, [
    marketInfo?.currentPosition?.order_size,
    marketInfo?.currentPosition?.mark_price,
    show,
  ]);

  const calculateUnrealizedPnl = (itemPosition, last_price, size) => {
    try {
      const positionSize =
        closePositionType === "Limit"
          ? price
          : Number(getMarkPrice(itemPosition?.pair, allMarkPrice));
      console.log(">>>>>>>>>>>>>>??", positionSize, itemPosition, size);
      let unrealisedPnl;
      if (itemPosition?.side !== 1) {
        unrealisedPnl = showDecimal(
          // Number(getMarkPrice(itemPosition?.pair, allMarkPrice)) -
          (Number(positionSize) - itemPosition?.entry_price) * size,
          4
        );
      } else {
        unrealisedPnl = showDecimal(
          (itemPosition?.entry_price - Number(positionSize)) * size,
          4
        );
      }
      console.log(
        "im>>>>>>>>>>>>>>>>>>>>>>",
        Number(positionSize),
        itemPosition?.entry_price
      );
      const initialMargin = showDecimal(
        (Number(itemPosition?.entry_price) * size) / itemPosition?.leverage,
        4
      );
      setPnlPercentage(
        showDecimal(
          (unrealisedPnl / initialMargin) * 100 * (sizeRange / 100),
          4
        )
      );

      return showDecimal(unrealisedPnl, 4);
    } catch (error) {}
  };

  useEffect(() => {
    const positionSize =
      closePositionType === "Limit"
        ? price
        : marketInfo?.currentPosition?.entry_price;
    if (size && size > 0 && price && price > 0) {
      let unrealizedPnlAmount = calculateUnrealizedPnl(
        marketInfo?.currentPosition,
        lastPrice,
        size
      );
      let makerTakerFee =
        marketInfo?.currentPosition?.side == 0
          ? showDecimal(
              getFeeCalculationsBuy(
                positionSize,
                size,
                marketInfo?.currentPosition?.leverage,
                makrTkrFee?.cbl,
                makrTkrFee?.cts
              ),
              4
            )
          : showDecimal(
              getFeeCalculationsSell(
                positionSize,
                size,
                marketInfo?.currentPosition?.leverage,
                makrTkrFee?.pls,
                makrTkrFee?.glr
              ),
              4
            );
      let pnlAmount = Number(unrealizedPnlAmount) - Number(makerTakerFee);
      setExpectedLoss(pnlAmount);
    } else {
      setPnlPercentage(0);
    }
  }, [size, show, lastPrice, price]);

  const handleOnChange = (e) => {
    setPrice(e.target.value);
  };

  const handelRange = (range) => {
    if (range > 0) {
      let decimalLimitAmount = currentPair?.min_order_qty
        ?.toString()
        .includes(".")
        ? currentPair?.min_order_qty?.toString().split(".")
        : "";
      const size = (range / 100) * marketInfo?.currentPosition?.order_size;
      if (decimalLimitAmount.length > 0) {
        setSize(showDecimal(size, decimalLimitAmount[1].length));
      } else {
        setSize(Math.ceil(size));
      }
      setSizeRange(range);
    }
  };

  const handleIncrement = () => {
    let incVal = Number(sizeRange) + Number(step);
    console.log(incVal);
    if (incVal <= 100) {
      setSizeRange(incVal);
      handelRange(incVal);
    }
  };

  const handleDecrement = () => {
    let decVal = Number(sizeRange) - Number(step);
    if (decVal > 0) {
      setSizeRange(decVal);
      handelRange(decVal);
    }
  };

  const handleSetQuantity = (range) => {
    if (range > 0) {
      let decimalLimitAmount = currentPair?.min_order_qty
        ?.toString()
        .includes(".")
        ? currentPair?.min_order_qty?.toString().split(".")
        : "";

      const size = (range / 100) * marketInfo?.currentPosition?.order_size;

      if (decimalLimitAmount.length > 0) {
        setSize(showDecimal(size, decimalLimitAmount[1].length));
      } else {
        setSize(Math.ceil(size));
      }
      setSizeRange(range);
    }
  };

  const handleConfirm = () => {
    handleSubmit(closePositionType === "Limit" ? 0 : 1, closePositionType, {
      marketPrice: price,
      marketSize: size,
    });
    setSizeRange(100);
    setSize("");
    setExpectedLoss(0);
    setPnlPercentage(0);
  };

  return (
    <>
      <Modal
        className="close_position_modal"
        centered
        show={show}
        backdrop={"static"}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Close Position <span>{closePositionType} Price</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {closePositionType === "Limit" && (
            <div className="Input_Group mb-3">
              <label>Price</label>
              <input
                type="text"
                className="input-type2"
                placeholder="Input"
                value={price}
                defaultValue={marketInfo?.currentPosition?.price}
                onChange={(event) => {
                  if (/^\d*(\.\d{0,8})?$/.test(event.target.value)) {
                    handleOnChange(event);
                  }
                }}
              />
            </div>
          )}
          <div className="Input_Group">
            <label>Quantity</label>
            <div className="quantity_box">
              <span onClick={handleIncrement}>
                <PlusIcon />
              </span>
              <input
                type="text"
                className="quantity_input"
                // placeholder="1000.00"
                value={size}
                // defaultValue={size}
                disabled
              />
              <span>
                <span onClick={handleDecrement}>
                  <MinusIcon />
                </span>
              </span>
            </div>
          </div>
          <LeverageSlider
            min={0}
            max={100}
            format={"%"}
            handleSetRange={handleSetQuantity}
            rangeData={sizeRange}
          />
          {size < currentPair?.min_order_qty ? (
            <span
              className="errorMsgSlider"
              style={{ color: "red", marginLeft: "30%" }}
            >
              {`Minimum amount should be ${currentPair?.min_order_qty}`}
            </span>
          ) : (
            ""
          )}
          <hr className="common_hr my-5" />
          <div className="position_info d-flex align-items-center">
            <span>
              <InfoIcon />
            </span>
            <p>
              {size} contract will be closed at{" "}
              {closePositionType === "Limit" ? price : "the Market Trade"}{" "}
              Price, and your expected PNL will be:
            </p>
          </div>
          <h2
            className={`text-center ${
              expectedLoss > 0 ? "text_green" : "text_red"
            }`}
          >
            {expectedLoss
              ? closePositionType === "Limit" && Number(price) === 0
                ? 0
                : expectedLoss
              : 0}{" "}
            USDT <br /> ({isFinite(pnlPercentage) ? pnlPercentage : 0}%)
          </h2>
          <div className="groupbtn d-flex mt-4 pt-2">
            <CommonButton
              disabled={size < currentPair?.min_order_qty}
              onClick={handleConfirm}
              title="Confirm"
              className="me-2 w-50"
            />
            <CommonButton
              title="Cancel"
              className="grey-btn2 ms-2 w-50"
              onClick={handleClose}
            />
          </div>
        </Modal.Body>
      </Modal>
      <PositionClosedModal show={showclosed} handleClose={handleClosed} />
    </>
  );
};

export default memo(LimitPositionModal);
