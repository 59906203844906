import React, { useEffect, useState } from 'react'
import "./DateCalenderCard.scss"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { saveUserLedgerFilter } from '../../../../../../redux/_slices/user.slice';

const DateCalenderCard = () => {
    const dispatch = useDispatch()
    const [valueType, setValueType] = useState('3M');
    const [startEnd, setStartEnd] = useState({startTime:null, endTime:null})
    const filters = useSelector((state) => state.user.userLedgerFilters)

    const handleFilterChange = (type, value) => {
        let selectedDate;

        if(value == '1D'){
            selectedDate = getOneDayTimestamp(); 
            setValueType('1D');
        } else if(value == '1W'){
            selectedDate = getOneWeekTimestamp(); 
            setValueType("1W");
        } else{
            selectedDate = value;
            setValueType(value);
        }

        let timeObj
        timeObj = {
            ...filters,
            dateFilter: selectedDate,
            endTime: null,
            startDate: null,
        };
        dispatch(saveUserLedgerFilter(timeObj))
    }

    const getOneWeekTimestamp = () => {
        const now = new Date();
        const last7Days = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        setValueType('1W');
        return last7Days.getTime();
    };
    const getOneDayTimestamp = () => {
        const now = new Date();
        const last1Day = new Date(now.getTime() - 1 * 24 * 60 * 60 * 1000);
        setValueType("1D");
        return last1Day.getTime();
    };    

    return (
        <>
            <div className="calender_Card d-flex align-items-center">
                <ul className="d-flex align-items-center">
                    <li
                        className={
                            "date_btn " + (valueType === "1D" ? "active" : "")
                        }
                        onClick={() =>
                            handleFilterChange("duration", '1D')
                        }
                    >
                        1 Day
                    </li>
                    <li
                        className={
                            `date_btn ` + (valueType === "1W" ? "active" : "")
                        }
                        onClick={() =>
                            handleFilterChange("duration", '1W')
                        }
                    >
                        1 Week
                    </li>
                    <li
                        className={
                            `date_btn ` + (valueType === "1M" ? "active" : "")
                        }
                        onClick={() => handleFilterChange("duration", "1M")}
                    >
                        1 Month
                    </li>
                    <li
                        className={
                            `date_btn ` + (valueType === "3M" ? "active" : "")
                        }
                        onClick={() => handleFilterChange("duration", "3M")}
                    >
                        3 Months
                    </li>
                </ul>
                {/* <div className='date_calender_picker'>
                    <span className='date_text'>Date</span>
                    <span className='date_picker'>
                      
                        <DatePicker selected={startEnd?.startTime} onChange={(date) => handleFilterChange("startTime",date)} placeholderText="YYYY-MM-DD" />
                    </span>
                    <strong className='mx-2'>to</strong>
                    <span className='date_picker'>
                        <DatePicker selected={startEnd?.endTime} onChange={(date) => handleFilterChange("endTime",date)} placeholderText="YYYY-MM-DD" />
                    </span>
                </div> */}
            </div>
        </>
    );
}

export default DateCalenderCard