import React from 'react'
import { Col, Row } from 'react-bootstrap'
import beaticon from "../../../../../assets/icons/beaticon.svg"
import clokIcon from "../../../../../assets/icons/clockicon.svg"
import lineIcon from "../../../../../assets/icons/linechat_arrow.svg"
import lineredIcon from "../../../../../assets/icons/redline_trade.svg"
import infoIcon from "../../../../../assets/icons/info_blue_icon.svg"

const DetailPriceCard = () => {
    const Pricedata = [
        {
            beaticon: beaticon,
            title: "Market Cap",
            price: "$826,445,951,378",
            lineIcon: lineIcon,
            value: "+12%",
        },
        {
            beaticon: infoIcon,
            title: "Market Cap",
            price: "$915,435,574,336",
            lineIcon: lineredIcon,
            value: "-18%",
        },
        {
            beaticon: clokIcon,
            title: "Market Cap",
            price: "$826,445,951,378",
            lineIcon: lineIcon,
            value: "+12%",
        },
        {
            beaticon: infoIcon,
            title: "Market Cap",
            price: "18,958,437.00 BTC",
            lineIcon: lineIcon,
            value: "+12%",
        },
        {
            beaticon: clokIcon,
            title: "Market Cap",
            price: "$826,445,951,378",
            lineIcon: lineIcon,
            value: "+12%",
        },
        {
            beaticon: clokIcon,
            title: "Market Cap",
            price: "$826,445,951,378",
            lineIcon: lineIcon,
            value: "+12%",
        },
    ];

    return (
        <>
            <div className='DetailPrice_Card'>
                <Row>
                    {Pricedata.map((item, i) => {
                        return (
                            <Col xs={6} sm={6} md={4} xxl={3} className='Price_Card_Col' key={i} >
                                <div className='Price_CardBox'>
                                    <p><img src={item.beaticon} alt='Bit' /> {item.title}</p>
                                    <h5>{item.price} <span className='text_green'>{item.value} <img src={item.lineIcon} alt='Bit' /></span></h5>
                                </div>
                            </Col>
                        )
                    })}
                </Row>

            </div>
        </>
    )
}

export default DetailPriceCard
