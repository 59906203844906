import React from "react";
import Admin from "../../Admin";
import TopbarHeading from "../../common/TopbarHeading/TopbarHeading";
import { Alert, Col, Nav, Row, Tab } from "react-bootstrap";
import CoinDetailTable from "./CoinDetailTable/CoinDetailTable";
const CoinDetails = () => {
    return(
        <Admin>
            <div className="Common_TopBar">
                <Row className="align-items-center">
                    <Col xs={12} sm={6}>
                        <TopbarHeading
                            title="Coin Details"
                            hideEyeIcon={true}
                        />
                    </Col>
                </Row>
            </div>
             <div className="Common_admin_Contentarea">
                <div className="Transaction_History">
                    <CoinDetailTable />
                </div>
            </div>           
        </Admin>
    )
}

export default CoinDetails;