import React from "react";
import { useSelector } from "react-redux";
import { numberSystemFormate } from "../../../../_utils/comman";
import moment from "moment/moment";

const TradeBook = () => {
  const marketTrades = useSelector((state) => state.exchange?.marketTrades);
  const selectedPair = useSelector((state) => state.user.selectedPair);
  const listofTrades = useSelector((state) => state.exchange.listofTrades);

  const tradeBarColor = (index) => {
    if (marketTrades.length) {
      if (index < marketTrades.length - 1) {
        if (marketTrades[index].price > marketTrades[index + 1]?.price) {
          return 0;
        } else if (marketTrades[index].price < marketTrades[index + 1]?.price) {
          return 1;
        }
      }
      return 0;
    }
  };

  return (
    <>
      <div className="orderBookData_V2">
        <ul className="orderBook_Heading">
          <li>
            <span>Price USD</span>
          </li>
          <li>
            <span>Size</span>
          </li>
          <li>
            <span>Time</span>
          </li>
        </ul>
        <div className="orderBook_data">
          {marketTrades &&
            marketTrades.map((item, i) => {
              return (
                // className="Buyprice_data"
                <ul key={i}>
                  <li>
                    <span
                      className={
                        tradeBarColor(i) === 0 ? "text_green" : "text_red"
                      }
                    >
                      {numberSystemFormate(
                        item?.price,
                        selectedPair.price_scale
                      )}
                    </span>
                  </li>
                  <li>
                    <span>{numberSystemFormate(item?.filled_amount)}</span>
                  </li>
                  <li>
                    <span>{moment(item.created_at).format("hh:mm:ss")}</span>
                  </li>
                </ul>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default TradeBook;
