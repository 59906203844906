import React, { useState, useEffect } from "react";
import { Pagination, Table } from "react-bootstrap";
import "./TradeHistory.scss";
import { ApiServices } from "../../../../../Exchange/services/api.services";
import {
    savetrades,
    saveUserSpotTradeHistory,
} from "../../../../../Exchange/redux/_slices/exchange.slice";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import TradeHistoryFilters from "./TradeHistoryFilters";
import iconSell from "../../../../../../assets/icons/redline_trade.svg";
import iconBuy from "../../../../../../assets/icons/linechat_arrow.svg";
import { updateLoader } from "../../../../../Exchange/redux/_slices/exchange.slice";
import Loader from "../../../../common/Loader/Loader";

const TradeHistory = ({currentTab, isActive}) => {
    const dispatch = useDispatch();
    const walletAddress = useSelector((state) => state?.user?.walletAddress);
    const listofTrades = useSelector(
        (state) => state.exchange.userSpotTradeHistory
    );
    const tradeFilter = useSelector(
        (state) => state.user.userSpotTradeFilters?.tradeFilter
    );
     const loader = useSelector((state) => state.exchange.loader);

    const limit = 2;

    useEffect(() => {
        if (walletAddress && currentTab == 'spotTradeHistory' && isActive) {
             dispatch(updateLoader(true));
            getTradeHistoryList();
        } else {
            dispatch(saveUserSpotTradeHistory([]));
            dispatch(savetrades([]));
        }
    }, [tradeFilter, walletAddress, currentTab, isActive]);

    const getTradeHistoryList = async () => {
        try {
            const dataObject = {
                address: walletAddress,
                category: "spot",
                dateFilter: tradeFilter,
            };
            const response = await ApiServices.tradeHistoryList(dataObject);
            if(response){
                dispatch(saveUserSpotTradeHistory(response?.data?.list));
                dispatch(updateLoader(false));
            }
            
            //  dispatch(savetrades(response?.data?.list));
        } catch (err) {
            console.log(err);
            dispatch(updateLoader(false));
        }
    };

    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };
    return (
        <>
            {loader && <Loader/>}
            <TradeHistoryFilters />
            <div className="CryptoAssets_Table History_Table">
                <Table responsive>
                    <thead>
                        <tr>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Spot Pairs
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Trade Type
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Order Type
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    Direction
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Filled Value
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Filled Price
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Filled Qty
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Trading Fee
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "240px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Filled Time
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "370px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Transaction ID
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "370px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Order ID
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {listofTrades !== undefined &&
                        listofTrades?.length > 0 ? (
                            listofTrades.map((data, index) => (
                                <tr key={`trade_history_tr_${index}`}>
                                    <td>{data?.symbol}</td>
                                    <td>{data?.execType}</td>
                                    <td>{data?.orderType}</td>
                                    <td>
                                        <div className="Type_Data d-flex align-items-center">
                                            <div
                                                className={`type_Icon ${
                                                    data?.side == "Buy"
                                                        ? "greenbg"
                                                        : "redbg"
                                                }`}
                                            >
                                                <img
                                                    src={
                                                        data?.side == "Buy"
                                                            ? iconBuy
                                                            : iconSell
                                                    }
                                                    alt="icon"
                                                />
                                            </div>
                                            <div className="type_Text">
                                                <h6>{data?.side}</h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {roundToTwoDecimals(data?.execValue)}
                                    </td>
                                    <td>
                                        {roundToTwoDecimals(data?.execPrice)}
                                    </td>
                                    <td>{roundToTwoDecimals(data?.execQty)}</td>
                                    <td>{roundToTwoDecimals(data?.execFee)}</td>
                                    <td>
                                        {moment(Number(data?.execTime)).format(
                                            "DD-MM-YYYY  HH:mm:ss"
                                        )}
                                    </td>
                                    <td>{data?.execId}</td>
                                    <td>{data?.orderId}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={11}>
                                    <p style={{ textAlign: "center" }}>
                                        No result found
                                    </p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {/* <div className="transactions_Pagination d-flex align-items-center justify-content-between">
                    <Pagination className="common_pagination">
                        <Pagination.Prev />
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>

                        <Pagination.Ellipsis className="ellipsis_text" />
                        <Pagination.Item>{6}</Pagination.Item>
                        <Pagination.Next />
                    </Pagination>
                </div> */}
            </div>
        </>
    );
};

export default TradeHistory;
