import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import "./SpotCoinsTable.scss";
import { Form } from "react-bootstrap";
import ConvertIcon from "../../../../../assets/images/admin/ehx_icon.svg";
import { SearchIcon } from "../../../../../assets/Svg/SvgImages";
import { updateLoader } from "../../../../Exchange/redux/_slices/exchange.slice"
import { useDispatch } from "react-redux";

const InnverseCoinsTable = ({ coins, search }) => {
    const [searchCoin, setSearchCoin] = useState("");
    const dispatch = useDispatch();
    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    const onSearchChange = (e) => {
        setSearchCoin(e.target.value);
    };

    const onClickHandler = () => {
        search(searchCoin);
        dispatch(updateLoader(true));
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onClickHandler();
        }
    };

    return (
        <>
            <div className="Convert_Coin d-md-flex align-items-center mt-4 pt-xl-2">
                <div className="Dash_SearchBar">
                    <div className="searchBar_input ms-0">
                        <input
                            type="text"
                            name=""
                            placeholder="Search Coin"
                            className="control_searchBar"
                            onChange={onSearchChange}
                            value={searchCoin}
                            onKeyDown={handleKeyDown}
                        />
                        <SearchIcon onClick={onClickHandler} tabIndex="0" />
                    </div>
                </div>
            </div>
            <div className="positionTableSection  mt-4">
                <div className="Positions_Table">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Currency</th>
                                <th>Equity</th>
                                <th>
                                    Wallet Balance
                                    <span className="block">
                                        (Bonus Inclusive)
                                    </span>
                                </th>
                                <th>USD Value</th>
                                <th>Borrowed Amount</th>
                                {/* <th>Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {coins && coins.length > 0 ? (
                                coins?.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>
                                                <div className="coin_data d-flex align-items-center">
                                                    {/* <img
                                                        className="coin_Icon"
                                                        src={`https://non-prod-eterna-backend-bucket.s3.eu-west-1.amazonaws.com/icon/${data.coin}USDT.svg`}
                                                        alt={data?.coin}
                                                    /> */}
                                                    <span>
                                                        <strong>
                                                            {data.coin}
                                                        </strong>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                {roundToTwoDecimals(
                                                    data.equity
                                                )}
                                            </td>
                                            <td>
                                                {roundToTwoDecimals(
                                                    data.walletBalance
                                                )}
                                            </td>
                                            <td>
                                                {roundToTwoDecimals(
                                                    data.usdValue
                                                )}
                                            </td>
                                            <td>
                                                {roundToTwoDecimals(
                                                    data.borrowAmount
                                                )}
                                            </td>
                                            {/* <td>
                                            <div className="action_links d-flex align-items-center">
                                                <Link to="#">Trade</Link>
                                                <Link to="#">Transfer</Link>
                                                <Link to="#">Stake</Link>
                                                <Link to="#">Convert</Link>
                                            </div>
                                        </td> */}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5}>
                                        <p style={{ textAlign: "center" }}>
                                            No result found
                                        </p>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default InnverseCoinsTable;
