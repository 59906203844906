import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { socket_p2p } from "../../../../App";

function CountDownTimer({ expiresTime, index, orderId }) {
  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const walletAddress = useSelector((state) => state.user.walletAddress);

  /************** FUNCTION FOR EXPIRE TIME ***************/
  var x = setInterval(function () {
    var now = new Date().getTime();
    var dataaaaa = moment(expiresTime).valueOf();
    var distance = dataaaaa * 1000 - now;
    if (distance > 0) {
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      setDays(days);
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      setHours(hours);
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      setMinutes(minutes);
      // console.log('countDownDate',minutes)
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setSeconds(seconds);
    } else {
      clearInterval(x);
    }
  }, 1000);
  /*************************** END ********************************/

  /************** SOCKET EXPIRE TIME ***************/
  // updateOrderExpiry

  const expireTimeSocket = async () => {
    socket_p2p.emit("updateOrderExpiry", {
      address: walletAddress,
      orderId: orderId
    });
  };

  // console.log("timeer", days === "00", orderId);

  if (days === "00" && hours === "00" && minutes === "00" && seconds === "00") {
    expireTimeSocket();
  }

  return (
    <>
      <div className="countdown_timer text-end w-100" index={index}>
        <p className="w-100">
          {days && days == 0 ? 0 + " D" : days + " D"} :{" "}
          {hours && hours == 0
            ? "00"
            : hours < 10
              ? "0" + hours + " H"
              : hours + " H"}{" "}
          :{" "}
          {minutes && minutes == 0
            ? "00"
            : minutes < 10
              ? "0" + minutes + " M"
              : minutes + " M"}{" "}
          :{" "}
          {seconds && seconds == 0
            ? "00"
            : seconds < 10
              ? "0" + seconds + " S"
              : seconds + " S"}
        </p>
      </div>
    </>
  );
}

export default CountDownTimer;
