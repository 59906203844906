import React, { memo, useEffect, useState } from "react";
import "./AddProfitModal.scss";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import { MinusIcon, PlusIcon } from "../../../../../assets/Svg/SvgImages";
import RangeSlider from "../../../../../common/RangeSlider/RangeSlider";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import Select from "react-select";
import CrossIcon from "../../../../../assets/icons/red_cross.svg";
import icon1 from "../../../../../assets/images/tether-usdt.svg";
import { useSelector } from "react-redux";
import { numberSystemFormate, showDecimal } from "../../../../../_utils/comman";
import { inputDecimal } from "../../../../../services/HelperServices";
import SwitchButton from "../../../../../common/SwitchButton/SwitchButton";
import { ApiServices } from "../../../services/api.services";
import { toastMsg } from "../../../../../common/Toast/Toast";

const TPSLModal = ({
  orderPrice,
  profitAmount,
  setProfitAmount,
  lossAmount,
  setLossAmount,
  pairDetails,
  label,
  type,
  currentPosition,
  triggerBy,
  profitTriggerValue,
  lossTriggerValue,
  setLossTriggerValue,
  setProfitTriggerValue,
  modalType,
  type_field,
  activeTab,
  lastTradedPrice,
  profitUsdt,
  setProfitUsdt,
  ROIValue,
  setROIValue,
  lossUsdt,
  setLossUsdt,
  show,
  handleClose,
}) => {
  const OPTION = [
    { text: "USDT", value: "usdt", icon: icon1 },
    { text: "%", value: "percentage", icon: "" },
  ];
  const [selectedValue, setSelectedValue] = useState(OPTION[0]);
  const [range, setRange] = useState(0);
  const [triggeredprice, settriggeredprice] = useState("");
  const [roiLoss, setLossRoi] = useState("");
  const [tpslOrders, setTpslOrders] = useState([]);
  const [turnOffTPSL, setTurnOffTPSL] = useState(false);
  const [roi, setRoi] = useState("");
  const mkrTkrFee = useSelector((state) => state.exchange.makrTkrFee);
  const currentPair = useSelector((state) => state.user.currentPair);
  const walletAddress = useSelector((state) => state.user.walletAddress);
  const chainId = useSelector((state) => state.user.chainId);
  // const orderLeverage = useSelector((state) => state.user.leverage);
  const orderLeverage = currentPosition?.leverage;
  const GLOWL_DECIMAL = currentPair?.price_scale || 4;

  const toFix = (value, n) => {
    const v = value ? value.toString().split(".") : "0";
    if (n <= 0) return v[0];
    if (v.length == 1) return Number(v[0]).toFixed(n);
    let f = v[1] || "";
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < 2) f += "0";
    return `${v[0]}.${f}`;
  };

  const subHandler = () => {
    if (type == "profit") {
      // if (profitAmount >= orderPrice) {
      let tkPrice = 0;
      if (profitAmount && profitAmount != NaN && profitAmount > 0) {
        tkPrice = Number(profitAmount) - Number(pairDetails?.min_price);
      } else {
        tkPrice = Number(orderPrice) + Number(pairDetails?.min_price);
      }
      setProfitAmount(showDecimal(tkPrice, pairDetails?.price_scale));
      exitPriceFunctionToROI(Number(tkPrice));
      // }
    } else if (type == "loss") {
      // if (lossAmount <= orderPrice) {
      let slPrice = 0;
      if (lossAmount && lossAmount > 0) {
        slPrice = Number(lastTradedPrice) - Number(pairDetails?.min_price);
      } else {
        slPrice = Number(lastTradedPrice) - Number(pairDetails?.min_price);
      }
      setLossAmount(showDecimal(slPrice, pairDetails?.price_scale));
      exitPriceFunctionToROI(Number(slPrice));
      // }
    }
  };

  const addHandler = () => {
    if (type == "profit") {
      // if (profitAmount >= orderPrice) {
      let tkPrice = 0;
      if (profitAmount && profitAmount != NaN && profitAmount > 0) {
        tkPrice = Number(profitAmount) + Number(pairDetails?.min_price);
      } else {
        tkPrice = Number(orderPrice) + Number(pairDetails?.min_price);
      }
      setProfitAmount(showDecimal(tkPrice, pairDetails?.price_scale));
      exitPriceFunctionToROI(Number(tkPrice));
      // }
    } else if (type == "loss") {
      // if (lossAmount < orderPrice) {
      let slPrice = 0;
      if (lossAmount && lossAmount > 0) {
        slPrice = Number(lossAmount) + Number(pairDetails?.min_price);
      } else {
        slPrice = Number(orderPrice) - Number(pairDetails?.min_price);
      }
      setLossAmount(showDecimal(slPrice, pairDetails?.price_scale));
      exitPriceFunctionToROI(Number(slPrice));
      // }
    }
  };

  const handelRange = (values) => {
    setRange(values);
    const getPercent = 1 * values; //geting for 25 percent
    let rangeAmount = (getPercent / 100) * Number(orderPrice);

    let amount;
    if (type === "profit") {
      // let rangeAmount = (values / 100) * Number(orderPrice);
      if (currentPosition?.side == 0) {
        amount = Number(orderPrice) + Number(rangeAmount);
      } else {
        amount = Number(orderPrice) - Number(rangeAmount);
      }
      setProfitAmount(showDecimal(amount, pairDetails.price_scale));
      exitPriceFunctionToROI(
        Number(showDecimal(amount, pairDetails.price_scale))
      );
    } else if (type === "loss") {
      // let rangeAmount = (values / 100) * Number(orderPrice);
      if (currentPosition?.side == 0) {
        amount = Number(lastTradedPrice) - Number(rangeAmount);
      } else {
        amount = Number(lastTradedPrice) + Number(rangeAmount);
      }
      setLossAmount(showDecimal(amount, pairDetails.price_scale));
      exitPriceFunctionToROI(
        Number(showDecimal(amount, pairDetails.price_scale))
      );
    }
  };

  const handleChange = (type, value) => {
    if (type === "profit") {
      // if (value >= orderPrice) {
      const trimedValue = inputDecimal(value, pairDetails?.price_scale);
      exitPriceFunctionToROI(Number(trimedValue));
      setProfitAmount(trimedValue);
      let percentValue;
      if (value > 0) {
        percentValue = Math.round(
          (Number(value) / Number(orderPrice)) * 100 - 100
        );
      } else {
        percentValue = 0;
      }
      setRange(percentValue);
      // }
    } else if (type === "loss") {
      // if (value <= orderPrice) {
      const trimedValue = inputDecimal(value, pairDetails?.price_scale);
      exitPriceFunctionToROI(Number(trimedValue));
      setLossAmount(trimedValue);
      let percentValue;
      if (Number(value) > 0 && Number(value) <= Number(orderPrice)) {
        percentValue = Math.round(
          (Number(value) / Number(orderPrice)) * 100 - 100
        );
      } else {
        percentValue = 0;
      }
      setRange(Math.abs(percentValue));
    }
    // }
  };

  const exitPriceFunctionToROI = (exitPrice) => {
    const exitPrice_data = Number(exitPrice);
    const InitialMarginTPSL = !exitPrice_data
      ? 0
      : !Number(activeTab)
      ? ((Number(toFix(exitPrice_data, pairDetails?.price_scale)) -
          Number(toFix(lastTradedPrice, pairDetails?.price_scale))) /
          Number(toFix(lastTradedPrice, pairDetails?.price_scale))) *
        Number(orderLeverage) *
        100
      : ((Number(toFix(lastTradedPrice, pairDetails?.price_scale)) -
          Number(toFix(exitPrice_data, pairDetails?.price_scale))) /
          Number(toFix(lastTradedPrice, pairDetails?.price_scale))) *
        Number(orderLeverage) *
        100;
    if (type === "profit") {
      setROIValue(Number(toFix(InitialMarginTPSL, 2)));
    } else {
      setLossRoi(Number(toFix(InitialMarginTPSL, 2)));
    }
    setRoi(Number(toFix(InitialMarginTPSL, 2)));
  };

  useEffect(() => {
    if (ROIValue && type === "profit") {
      if (activeTab === 0) {
        //buy/long
        const val =
          (Number(toFix(profitAmount, pairDetails?.price_scale)) -
            Number(toFix(lastTradedPrice, pairDetails?.price_scale))) *
          currentPosition?.quantity;

        setProfitUsdt(Number(toFix(val, pairDetails?.price_scale)));
      } else {
        const val =
          (Number(toFix(lastTradedPrice, pairDetails?.price_scale)) -
            Number(toFix(profitAmount, pairDetails?.price_scale))) *
          currentPosition?.quantity;
        setProfitUsdt(Number(toFix(val, pairDetails?.price_scale)));
      }
    }
  }, [ROIValue, type]);

  useEffect(() => {
    if (roiLoss && type === "loss") {
      if (activeTab === 0) {
        const val =
          (Number(toFix(lossAmount, pairDetails?.price_scale)) -
            Number(toFix(lastTradedPrice, pairDetails?.price_scale))) *
          currentPosition?.quantity;
        setLossUsdt(Number(toFix(val, pairDetails?.price_scale)));
      } else {
        const val =
          (Number(toFix(lastTradedPrice, pairDetails?.price_scale)) -
            Number(toFix(lossAmount, pairDetails?.price_scale))) *
          currentPosition?.quantity;
        setLossUsdt(Number(toFix(val, pairDetails?.price_scale)));
      }
    }
  }, [roiLoss, type]);

  const usdtRange = new RegExp(`^-?\\d*(\\.\\d{0,2})?$`);

  const handleROIchange = (e) => {
    if (usdtRange.test(e.target.value)) {
      const roiValue = e.target.value;
      let negativeRoi = e.target.value.replace(/-/g, "");
      const negativeValue = `-${negativeRoi}`;
      if (negativeValue === -0 || roiValue === "-" || negativeValue === "-") {
        setLossRoi("");
      }
      const positiveNegativeVal =
        type_field !== undefined && type_field === "SL_ET"
          ? negativeValue
          : roiValue;
      setROIValue(roiValue);
      setLossRoi(negativeValue);
      setRange(type_field !== "SL_ET" ? roiValue : negativeValue);
      rangeAndROIValue(positiveNegativeVal);
      setRoi(type_field === "SL_ET" ? negativeValue : roiValue);
      if (roiValue === "" || roiValue === "-") {
        setROIValue("");
        setLossRoi("");
        setRange(0);
        setRoi("");
      }
    }
  };

  const rangeAndROIValue = (ROI_VALUE) => {
    const exitPrice = !Number(activeTab)
      ? (Number(toFix(lastTradedPrice, pairDetails?.price_scale)) *
          Number(ROI_VALUE / 100)) /
          Number(orderLeverage) +
        Number(toFix(lastTradedPrice, pairDetails?.price_scale))
      : Number(toFix(lastTradedPrice, pairDetails?.price_scale)) -
        (Number(toFix(lastTradedPrice, pairDetails?.price_scale)) *
          Number(toFix(ROI_VALUE / 100, pairDetails?.price_scale))) /
          Number(orderLeverage);

    const newValue = Number(toFix(exitPrice, pairDetails?.price_scale));
    if (type === "profit") {
      setProfitAmount(newValue);
    } else {
      setLossAmount(newValue);
    }
  };

  useEffect(() => {
    if (show) {
      getOpenTPSLOrders();
    }
  }, [show]);

  const getOpenTPSLOrders = async () => {
    try {
      let dataObj = {
        address: walletAddress,
        symbol: pairDetails?.pair_name,
      };
      const response = await ApiServices.getOpenPositionTPSLOrdersList(dataObj);
      if (response.status === 200) {
        // setTpslOrders((prevVal) =>
        //   response.data[0].id !== prevVal[0].id ? response.data : {}
        // );
        setTpslOrders(response.data);
        if (response?.data?.length > 0) {
          setTurnOffTPSL(true);
        }
      }
    } catch (error) {}
  };

  const handleTurnOffTPSL = async () => {
    try {
      const paramsData = {
        pairName: pairDetails?.pair_name,
        orderId: tpslOrders[0]?.id,
        address: walletAddress,
        chainId: chainId,
        symbol: pairDetails?.pair_name,
      };
      const res = await ApiServices.cancelOrder(paramsData);
      if (res && res.status === 200) {
        setTurnOffTPSL(!turnOffTPSL);
        getOpenTPSLOrders();
        handleClose();
        paramsData = {};
        toastMsg.success("TP/SL turned Off");
      }
    } catch (error) {
      toastMsg.error(error?.data?.message, { toastId: "errormsg" });
    }
  };

  return (
    <>
      <hr className="common_hr my-4" />
      <Row className="pt-2 gx-2">
        <Col xs={12} md={6}>
          <div className="Input_Group">
            <label>{label}</label>
            <div className="quantity_box mb-3">
              <span onClick={addHandler}>
                <PlusIcon />
              </span>
              <input
                type="text"
                className="quantity_input"
                value={
                  selectedValue.value == "usdt"
                    ? type === "profit"
                      ? profitAmount
                      : lossAmount
                    : type === "profit"
                    ? range
                    : range
                }
                onChange={(e) => {
                  if (
                    selectedValue.value == "usdt" ||
                    selectedValue.value === "percentage"
                  ) {
                    if (/^(\d)*(\.)?([0-9]{1,8})?$/.test(e.target.value)) {
                      type === "profit"
                        ? handleChange("profit", e.target.value)
                        : handleChange("loss", e.target.value);
                    }
                  }
                  // else if (selectedValue.value == "percentage") {
                  //   if (/^(\d)*(\.)?([0-9]{1,8})?$/.test(e.target.value)) {
                  //     type === "profit" ? setRange(e.target.value) : setRange(e.target.value);
                  //   }
                  // }
                }}
              />
              <span onClick={subHandler}>
                <MinusIcon />
              </span>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="Input_Group mb-3">
            <label>
              <div className="common_select Profit_select">
                <Select
                  //   placeholder="Last Traded Price"
                  defaultValue={
                    type === "profit"
                      ? profitTriggerValue.value
                      : lossTriggerValue.value
                  }
                  onChange={(e) =>
                    type === "profit"
                      ? setProfitTriggerValue(e.value)
                      : setLossTriggerValue(e.value)
                  }
                  options={triggerBy}
                  classNamePrefix="react-select"
                  value={
                    type === "profit"
                      ? profitTriggerValue.value
                      : lossTriggerValue.value
                  }
                  // menuIsOpen={true}
                />
              </div>
            </label>
            {/* <Dropdown className="profit_dropdown">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                {selectedValue.icon && (
                  <span>
                    <img src={icon1} alt="coin icon" />
                  </span>
                )}
                <p>{selectedValue.text}</p>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {OPTION.map((data, i) => {
                  return (
                    <Dropdown.Item
                      key={i}
                      to="javascript:;"
                      onClick={() => {
                        if (type == "profit") {
                          setProfitAmount("");
                        } else if (type == "loss") {
                          setLossAmount("");
                        }
                        setRange("");
                        setSelectedValue(data);
                      }}
                    >
                      {data.icon && (
                        <span>
                          <img src={data.icon} alt="coin icon" />
                        </span>
                      )}
                      <p>{data.text}</p>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown> */}
            <div className="quantity_box mb-3">
              <input
                type="text"
                className="quantity_input"
                // placeholder="ROI"
                value={
                  // ROIValue !== ""
                  // type_field === "SL_ET"
                  //   ? numberSystemFormate(roiLoss, GLOWL_DECIMAL)
                  //   : numberSystemFormate(ROIValue, GLOWL_DECIMAL)
                  roi
                }
                onChange={handleROIchange}
                maxLength={20}
              />
              <span>%</span>
            </div>
          </div>
        </Col>
      </Row>
      <RangeSlider
        min={0}
        max={type === "profit" ? 150 : 75}
        format="%"
        handleSetRange={setRange}
        rangeData={range}
        rangeAndROIValue={rangeAndROIValue}
        setLossRoi={setLossRoi}
        setROIValue={setROIValue}
        type_field={type_field}
        setRoi={setRoi}
      />
      <h6 style={{ userSelect: "none" }}>
        {profitUsdt !== "" && type === "profit"
          ? `Last Traded Price to ${profitAmount} will trigger market Take Profit order, your expected ${
              Math.sign(profitUsdt) === -1 ? "loss" : "profit"
            } will be ${profitUsdt} USDT`
          : ""}
        {lossUsdt !== "" && type === "loss"
          ? `Last Traded Price to ${lossAmount} will trigger market Stop Loss order, your expected ${
              Math.sign(lossUsdt) === -1 ? "loss" : "profit"
            } will be ${lossUsdt} USDT`
          : ""}
      </h6>
      {/* <h6>Please enter your desired order value</h6> */}

      {type === "loss" && <hr className="common_hr my-4" />}
      {type === "loss" && tpslOrders.length > 0 && (
        <div className="d-flex align-items-center">
          <label className="turnOff_switch">Turn Off TP/SL :</label>{" "}
          <SwitchButton
            // title="Slider"
            handleSwitchChange={handleTurnOffTPSL}
            checked={turnOffTPSL}
          />
        </div>
      )}
    </>
  );
};

export default memo(TPSLModal);
