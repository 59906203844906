import { BASE_URL, BASE_URL_P2P } from "../../../constants/constants";
import { fetch } from "../../../services/fetch";

/************** CREATE ORDER API ***************/
const createP2POrder = async (data) => {
  try {
    const responce = await fetch("post", `${BASE_URL}/p2p/create_order`, data);
    return responce.data;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

/************** OUTDOUND ORDER LIST API ***************/
const listP2POrders = async (data) => {
  try {
    const responce = await fetch(
      "post",
      `${BASE_URL}/p2p/outbound_order_details`,
      data
    );
    return responce.data;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

/************** OUTBOUND CANCEL API ***************/
const SingleCancelOrder = async (data) => {
  try {
    const responce = await fetch(
      "put",
      `${BASE_URL}/p2p/cancel_outbound_order`,
      data
    );
    return responce.data;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

/************** INBOUND CANCEL API ***************/
const SingleCancelOrderInbound = async (data) => {
  try {
    const responce = await fetch(
      "put",
      `${BASE_URL}/p2p/cancel_inbound_order`,
      data
    );
    return responce.data;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

/************** OUTBOUND CONFIRM API ***************/
const outboundConfirmOrder = async (data) => {
  try {
    const responce = await fetch(
      "put",
      `${BASE_URL}/p2p/outbound_confirmed_order`,
      data
    );
    return responce.data;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

/************** GET EHX PRICE API ***************/
const getEHXDollarPrice = async (data) => {
  try {
    const responce = await fetch(
      "get",
      `${BASE_URL}/p2p/get_ehx_dollar_price`,
      data
    );
    return responce.data;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

/************** GET USDT CONVERSION PRICE FROM THIRD PARTY API ***************/
const getUSDTDollarPrice = async (data) => {
  try {
    // const responce = await fetch(
    //   "get",
    //   `https://web-api.coinmarketcap.com/v1/tools/price-conversion?&amount=${data}&convert=usd&symbol=usdt`,
    //   data
    // );
    const response = data;
    return response;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

/************** INBOUND ORDER LIST API ***************/
const inboundOrderList = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${BASE_URL}/p2p/inbound_order_details`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

/************** INBOUND ORDER CONFIRM API ***************/
const InboundOrderConfirm = async (data) => {
  try {
    const response = await fetch(
      "put",
      `${BASE_URL}/p2p/inbound_confirmed_order`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

/************** HISTORY ORDER LIST API ***************/
const HistoryAllList = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${BASE_URL_P2P}/p2p/inbound_order_details`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

export const ApiServices = {
  createP2POrder,
  listP2POrders,
  SingleCancelOrder,
  outboundConfirmOrder,
  getEHXDollarPrice,
  getUSDTDollarPrice,
  inboundOrderList,
  InboundOrderConfirm,
  HistoryAllList,
  SingleCancelOrderInbound,
};
