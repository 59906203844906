import React from 'react'
import "./PaginationCommon.scss"
import Pagination from 'react-bootstrap/Pagination';
import { RightarrowIcon } from '../../assets/Svg/SvgImages';
 
const PaginationCommon = ({className}) => {
    return (
        <>
            <Pagination className={`Common_Table_Pagination ${className}`}>
                <li className='prev_icon'><span className='page-link'><RightarrowIcon /></span></li>
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>

                <Pagination.Ellipsis />
                <Pagination.Item>{100}</Pagination.Item>
                <li className='next_icon'><span className='page-link'><RightarrowIcon /></span></li>
            </Pagination>
        </>
    )
}

export default PaginationCommon 