import React from 'react'
import { Modal } from 'react-bootstrap'
import CommonButton from '../../../../../common/CommonButton/CommonButton'
import checkicon from "../../../../../assets/icons/check-double.svg"

const PositionClosedModal = ({ handleClose, show }) => {
    return (
        <>
            <Modal className='close_position_modal' centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Position Closed</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-top'>
                    <div className='position_closed_info text-center pt-3'>
                        <img src={checkicon} alt='check' className='mb-4' />
                        <p>Contract successfully closed at a PNL of <span className='text_red'>+545.67 USDT</span></p>
                        <p>Congratulations on the successful trade!</p>
                    </div>
                    <div className='mt-4 pt-3'>
                        <CommonButton title="Close" className="w-100" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PositionClosedModal
