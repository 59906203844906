import React from 'react'
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import "./NewsLetter.scss"

const NewsLetter = () => {
    return (
        <>
            <div className='NewsLetter_Sec'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={5} lg={4}>
                            <div className='text-center text-md-start News_Heading'>
                                <h2>Keep up with our <em>newsletter</em></h2>
                            </div>
                        </Col>
                        <Col xs={12} md={7} lg={5}>
                            <div className='Newsletter_Box'>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="E-mail address"
                                        aria-label="E-mail address"
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Text id="basic-addon2">Sign up</InputGroup.Text>
                                </InputGroup>
                                <Form.Check
                                    label="I’m okay getting e-mails and having that activity tracked to improve my experience."
                                    name=""
                                    type="checkbox"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default NewsLetter