import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import CommonButton from '../../../../common/CommonButton/CommonButton';
import Admin from '../../Admin';
import "./Referrals.scss";
import ReferralsImg from "../../../../assets/images/admin/ReferralsImg.svg";
import userIcon from "../../../../assets/images/admin/user_icon.svg";
import tier_saphire from "../../../../assets/images/admin/tier_saphire.svg"
import PaginationCommon from '../../../../common/PaginationCommon/PaginationCommon';
import UnlockProgressCard from './UnlockProgressCard';
import EhxStakingModal from './EhxStakingModal';

const Referrals = () => {

  const refferalData = [
    {
      tokenadds: "0x2f...c23",
      hvolume: "$1.330,05",
      dvolume: "$1.330,05",
      mvolume: "$1.330,05",
      level: "1",
      earned: "$100",
    },
    {
      tokenadds: "0x2f...c23",
      hvolume: "$1.330,05",
      dvolume: "$1.330,05",
      mvolume: "$1.330,05",
      level: "1",
      earned: "$100",
    },
    {
      tokenadds: "0x2f...c23",
      hvolume: "$1.330,05",
      dvolume: "$1.330,05",
      mvolume: "$1.330,05",
      level: "1",
      earned: "$100",
    },
    {
      tokenadds: "0x2f...c23",
      hvolume: "$1.330,05",
      dvolume: "$1.330,05",
      mvolume: "$1.330,05",
      level: "1",
      earned: "$100",
    },
    {
      tokenadds: "0x2f...c23",
      hvolume: "$1.330,05",
      dvolume: "$1.330,05",
      mvolume: "$1.330,05",
      level: "1",
      earned: "$100",
    },
    {
      tokenadds: "0x2f...c23",
      hvolume: "$1.330,05",
      dvolume: "$1.330,05",
      mvolume: "$1.330,05",
      level: "1",
      earned: "$100",
    },
    {
      tokenadds: "0x2f...c23",
      hvolume: "$1.330,05",
      dvolume: "$1.330,05",
      mvolume: "$1.330,05",
      level: "1",
      earned: "$100",
    },
    {
      tokenadds: "0x2f...c23",
      hvolume: "$1.330,05",
      dvolume: "$1.330,05",
      mvolume: "$1.330,05",
      level: "1",
      earned: "$100",
    },
    {
      tokenadds: "0x2f...c23",
      hvolume: "$1.330,05",
      dvolume: "$1.330,05",
      mvolume: "$1.330,05",
      level: "1",
      earned: "$100",
    },
  ];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Admin>
      <div className='Admin_Referrals_Page'>
        <section className="Refferal_info_Banner">
          <Container className="commn-container">
            <Row className="justify-content-center align-items-center">
              <Col
                xs={12}
                lg={6}
                xl={5}
                className="text-center text-lg-start"
              >
                <div className="Refferal_Banner_content">
                  <h1>
                    Invite Your <em className="ms-0 ms-md-2">Friends</em> to use Eterna
                  </h1>
                  <div className='Refferal_Banner_Btns'>
                    <CommonButton title="Copy affiliate link" className="affilliate_btn" />
                    <CommonButton title="Share on Social Media" className="border-btn share_btn" />
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={6} xl={5}>
                <div className="Banner_coin text-center text-lg-center">
                  <img src={ReferralsImg} alt="img" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="py-60 Refferal_Sec">
          <Container>
            <div className="Refferal_Row">
              <div className="Refferal_Account">
                <h4>Your Account</h4>
                <div className="Refferal_Account_Box">
                  <div className="d-flex justify-content-between mb-4">
                    <div className="Col_50">
                      <img className="userIcon" src={userIcon} alt="img" />
                    </div>
                    <div className="Col_50 Sapphire_text">
                      <img className="Sapphire_Icon" src={tier_saphire} alt="img" />
                      <span>Sapphire Tier</span>
                    </div>
                  </div>
                  <div className="Refferal_Common_Row">
                    <div className="Col_100">
                      <h6>Wallet address</h6>
                      <h3>0x2f...C245 <span></span></h3>
                    </div>
                  </div>
                  <div className="Refferal_Common_Row">
                    <div className="Col_50">
                      <h6>Staked</h6>
                      <h3>167.000 EHX</h3>
                    </div>
                    <div className="Col_50">
                      <h6>Earned</h6>
                      <h3>$670</h3>
                    </div>
                  </div>
                  <div className="Refferal_Common_Row">
                    <div className="Col_25">
                      <h6>Current Tier</h6>
                      <h5>3</h5>
                    </div>
                    <div className="Col_25">
                      <h6>Symbol</h6>
                      <img src={tier_saphire} alt="img" />
                    </div>

                    <div className="Col_50">
                      <h6>Percentage of share</h6>
                      <h5>80% share</h5>
                    </div>
                  </div>
                  <div className="Refferal_Common_Row">
                    <div className="Col_100">
                      <h6>Total Volume of your Referrals</h6>
                      <h3>$1.333,00</h3>
                    </div>
                  </div>
                  <div className="Refferal_Common_Row">
                    <div className="Col_25">
                      <h6>24h Volume</h6>
                      <h5>$1.333,00</h5>
                    </div>
                    <div className="Col_25">
                      <h6>7 days Volume</h6>
                      <h5>$1.333,00</h5>
                    </div>

                    <div className="Col_50">
                      <h6>30 days Volume</h6>
                      <h5>$100.091.333,00</h5>
                    </div>
                  </div>
                  <div className="d-block d-sm-flex Stak_more_Btn">
                    <CommonButton onClick={handleShow} title="Learn more" className="border-btn w-50 me-1" />
                    <CommonButton title="Stake to earn more" className="w-50 ms-1" />
                  </div>
                </div>

                <UnlockProgressCard />
              </div>
              <div className="Refferal_Affiliaties">
                <h4>Your Affiliates</h4>
                <div className="Refferal_Table_Row">
                  <div className="Refferal_Table_Heading">
                    <ul>
                      <li><span></span></li>
                      <li><span>24h Volume</span></li>
                      <li><span>7 days Volume</span></li>
                      <li><span>30 days Volume</span></li>
                      <li><span>Level</span></li>
                      <li><span>Earned</span></li>
                    </ul>
                  </div>
                  <div className="Refferal_Table_Data">
                    {refferalData.map((item, i) => {
                      return (
                        <ul key={i}>
                          <li><span className="mobile_Content"></span> <span>{item.tokenadds}</span></li>
                          <li><span className="mobile_Content">24h Volume</span> <span>{item.hvolume}</span></li>
                          <li><span className="mobile_Content">7 days Volume</span> <span>{item.dvolume}</span></li>
                          <li><span className="mobile_Content">30 days Volume</span> <span>{item.mvolume}</span></li>
                          <li><span className="mobile_Content">Level</span> <span>{item.level}</span></li>
                          <li><span className="mobile_Content">Earned</span> <span>${item.earned}</span></li>
                        </ul>
                      )
                    })}

                    <ul className='justify-content-center'><li>No records found</li></ul>

                  </div>
                </div>
                <PaginationCommon className="mt-4 pt-md-1" />
              </div>
            </div>
          </Container>
        </section>
      </div >
      <EhxStakingModal show={show} handleClose={handleClose} />
    </Admin >
  )
}

export default Referrals