import { combineReducers } from "redux";
import { userSlice } from "./user.slice";
import { layoutSlice } from "./layout.slice";
import { xySlice } from "./xy.slice";
import { exchangeSlice } from "../../pages/Exchange/redux/_slices/exchange.slice";
import { spotExchangeSlice } from "../../pages/SpotExchange/redux/_slices/spotExchange.slice";
// import referralSlice from "../../pages/RefferalInfo/redux/_slices/referral.slice";

const appReducer = combineReducers({
  user: userSlice.reducer,
  layout: layoutSlice.reducer,
  swapData: xySlice.reducer,
  exchange: exchangeSlice.reducer,
  spotExchange: spotExchangeSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USERS_PERSIST") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
