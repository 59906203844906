import React, { useEffect, useState } from "react";
import "./AccountUpdate.scss";
import HrsImg from "../../../../assets/images/hrsimg.svg";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { ApiServices } from "../../services/api.services";
import { showDecimal } from "../../../../_utils/comman";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "../../common/Separator";

const AccountUpdate = () => {
  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const walletBalance = useAppSelector((state) => state.user.userWalletBalances)
  const percentage = 45;

  return (
    <>
      <div className="AccountUpdate_V2 nondraggable_card">
        <div className="Ticker_data">
          <ul>
            <li>
              <span>Available Balance</span>
              <p
                title={`$ ${walletAddress
                  ? showDecimal(walletBalance?.spotTransferBalance, 6)
                  : "0"
                  }`}
              >
                $
                {walletAddress
                  ? showDecimal(walletBalance?.spotTransferBalance, 6)
                  : "0"}
              </p>
            </li>
            <li>
              <span>Account risk</span>
              <p>45.25%</p>
            </li>
            {/* <li>
              <img src={HrsImg} alt="img" />
            </li> */}
            <li style={{ width: 30, height: 30 }}>
              <CircularProgressbarWithChildren
                value={percentage}
                text={percentage}
                strokeWidth={6}
                // counterClockwise={false}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textSize: "44px",
                  pathTransitionDuration: 0.5,
                  pathColor: `#34D399`,
                  textColor: "#34D399",
                  trailColor: "#232323",
                  backgroundColor: "#3e98c7",
                  textFont: "digital-clock-font",
                })}
              >
                <RadialSeparators
                  count={56}
                  style={{
                    background: "rgba(8,10,18)",
                    width: "1px",
                    // This needs to be equal to props.strokeWidth
                    height: `${6}%`,
                  }}
                />
              </CircularProgressbarWithChildren>
            </li>
            <li className="Hr_Pl">
              <span>24HR P&L:</span>
              <p className="text_green">+$120.62</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default AccountUpdate;
