import { API_HOST, INDEXER_API } from "../../../constants/constants";
import { fetch } from "../../../services/fetch";

const getPairList = async () => {
  try {
    let response = await fetch("get", `${API_HOST}/socket/spot/pair_list`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getChainInfo = async () => {
  try {
    let response = await fetch("post", `${API_HOST}/socket/query_coin_info`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAccountAlgoMinBalance = async (address) => {
  try {
    const response = await fetch(
      "get",
      `${INDEXER_API}/v2/accounts/${address}?include-all=true`
    );
    return response.data;
  } catch (error) {
    throw { message: "Something went wrong." };
  }
};

/**
 *
 * @param {Object} data  type : Object
 *     address : String,
 *     chainId : Number,
 *     symbol : string,
 *     currency : address,
 *     amount : Number
 * @returns
 */
const witdrawalRequest = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/orders/v1/order/user/withdraw_request`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * 
 * @param {Object} data 
 *  pairName : string,
    side: number, Buy ==> 0, Sell ==> 1
    type: number, Limit ==> 0, Market ==> 1
    price: number , Order Price
    leverage:number Order leverage
    trigger_price:number , same as  Order Price
    orderSize : number , Order Size
    address : string, user address
    chainId: number, Network chain Id 
    symbol: string, Selected asset symbole
 * @returns 
 */
const createOrder = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/spot/create_order`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const mkrTkrFee = async (data) => {
  try {
    const response = await fetch("post", `${API_HOST}/socket/etom`, "");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const otherFee = async (data) => {
  try {
    const response = await fetch("get", `${API_HOST}/socket/eoath`, "", "", "");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const cancelOrder = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/spot/cancel_order`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const closeOrderPosition = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/close_order_position`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const orderHistory = async (data) => {
  return new Promise((resolve, reject) => {
    fetch(
      "get",
      `${API_HOST}/orders/v1/order/user/user_order_history`,
      "",
      "",
      data
    )
      .then((response) => {
        resolve(response.data.data.orderlist);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const tradeHistory = async (data) => {
  return new Promise((resolve, reject) => {
    fetch("get", `${API_HOST}/orders/v1/order/user/trade_history`, "", "", data)
      .then((response) => {
        resolve(response.data.data.tradeList);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getGraphData = async (data) => {
  return new Promise((resolve, reject) => {
    // fetch("get", `${API_HOST}/graph/v1/graph/user/graph_data`, "", "", data)
    fetch("get", `${API_HOST}/socket/spot/graph_data`, "", "", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAssestsBalance = async (data) => {
  return new Promise((resolve, reject) => {
    fetch("get", `${API_HOST}/socket/get_user_allcoin_balance`, "", "", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getUserSpotBalance = async (address) => {
  try {
    let response = await fetch(
      "get",
      `${API_HOST}/socket/get_allcoin_balance?address=${address}&accountType=SPOT&coin=USDT&withBonus=1`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const transferSpotToContract = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/internal_transfer`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const setTradeMode = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/switch_trade`,
      data
    );
    return response.data;
  } catch (error) {
    // throw error;
  }
};

const setLeverage = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/set_leverage`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const withdraw = async (data) => {
  try {
    const response = await fetch("post", `${API_HOST}/socket/withdraw`, data);
    return response.data;
  } catch (error) {
    console.log("withdraw error>>>>>>>>>>>>", error);
    let error1 = {
      message: "",
    };
    throw error;
    // throw error;
  }
};

const transactionHistory = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/transaction_history`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const saveFirebaseToken = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/notification/save-token`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const takeProfiStoptLoss = async (data) => {
  try {
    const response = await fetch("post", `${API_HOST}/socket/set_tpsl`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const trailingStop = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/tralling_stop`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getNotificationList = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/notification/user/get-notifications`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getReadNotification = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/notification/user/read-notifications`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getWithdrawFee = async () => {
  try {
    let response = await fetch("get", `${API_HOST}/socket/dew`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const reportIssue = async (data) => {
  try {
    let response = await fetch(
      "post",
      `${API_HOST}/notification/user/raise-issue`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPairStats = async () => {
  try {
    const response = await fetch("get", `${API_HOST}/socket/spot/pair_stats`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getUserPostions = async (data) => {
  try {
    const response = await fetch("post", `${API_HOST}/socket/positions`, {
      address: data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const closeAllPostions = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/position/close_all`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const closeAllOrders = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/spot/cancel_all_orders`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const tradeHistoryList = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/user/history/trades`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const ordersHistoryList = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/user/history/orders`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const positionsHistoryList = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/user/closed-pnl`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const editSpotOpenOrder = async (data) => {
  try {
    const response = await fetch(
      "post",
      `${API_HOST}/socket/spot/edit_order`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//exporting
export const ApiServices = {
  getPairList,
  getAccountAlgoMinBalance,
  witdrawalRequest,
  createOrder,
  getGraphData,
  cancelOrder,
  orderHistory,
  tradeHistory,
  closeOrderPosition,
  getUserSpotBalance,
  transferSpotToContract,
  setTradeMode,
  setLeverage,
  withdraw,
  getChainInfo,
  transactionHistory,
  saveFirebaseToken,
  takeProfiStoptLoss,
  getNotificationList,
  trailingStop,
  getWithdrawFee,
  getAssestsBalance,
  getReadNotification,
  mkrTkrFee,
  otherFee,
  reportIssue,
  getPairStats,
  getUserPostions,
  closeAllPostions,
  closeAllOrders,
  tradeHistoryList,
  ordersHistoryList,
  positionsHistoryList,
  editSpotOpenOrder,
};
