import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import { getTokenFirebase } from "./_utils/firebase";
import Application from "./Application";
import { ToastContainerComponent } from "./common/Toast/Toast";
import { P2P_SOCKET_URL, SOCKET_URL } from "./constants/constants";
import store from "./redux/_store/configureStore";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

export const socket = io(SOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionAttempts: 9999,
  transports: ["websocket"],
});

/************** P2P SOCKET CONNECTION ***************/
// export const socket_p2p = {
//   emit: () => { },
//   removeAllListeners: () => { },
//   on: () => { },
// };
console.log("P2P_SOCKET_URL", P2P_SOCKET_URL);
export const socket_p2p = io(P2P_SOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionAttempts: 9999,
  transports: ["websocket"],
});

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // const socketHook = useSocketService(splitLocation[1]);
  // const spotSocketHook = useSpotSocketService(splitLocation[1]);
  const [remaining, setRemaining] = useState(0);
  // const { disconnect } = useDisconnect();

  const walletAddress = useSelector((state) => state.user.walletAddress);

  useEffect(() => {
    if (!isMobile && walletAddress) {
      getTokenFirebase(walletAddress);
      // onMessageListener()
    }
  }, [walletAddress]);

  useEffect(() => {
    const checkTrustWallet = () => {
      // Method 1: Check window.ethereum
      const isTrustEthereum =
        window.ethereum && window.ethereum.isTrust === true;

      // Method 2: Check user agent
      const isTrustUserAgent = navigator.userAgent
        .toLowerCase()
        .includes("trust wallet");

      if (isTrustEthereum || isTrustUserAgent) {
        alert(
          "Please use Safari or Chrome browser with Walletconnect, as Trust Wallet browser is unstable after their last update."
        );
      }
    };

    checkTrustWallet();
  }, []);

  const onIdle = () => {
    // store.dispatch(saveUserWalletAddress(""));
    // disconnect();
  };

  const onActive = () => {
    // setState('Active')
  };

  const onAction = () => {
    // setCount(count + 1)
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 36000_000, //10hrs
    throttle: 500,
  });

  return (
    <>
      {/* <DynamicContextProvider
        settings={{
          environmentId: "2bb6f338-6a14-406b-9464-a6de28a58ea8",
          walletConnectors: [EthereumWalletConnectors],
        }}
      >
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <DynamicWagmiConnector>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <BrowserRouter> */}
      <ToastContainerComponent />
      {/* <IdleTimer timeout={1800 * 1000} onIdle={handleIdle} /> */}
      <Application />
      {/* <ReactCalculator />; */}
      {/* </BrowserRouter>
                </PersistGate>
              </Provider>
            </DynamicWagmiConnector>
          </QueryClientProvider>
        </WagmiProvider>
      </DynamicContextProvider> */}
    </>
  );
};

export const storeInstance = store;

export default App;
