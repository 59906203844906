export const SMALLESTUNIT = 100000000;
export const totallimit = 10000000000000000;

export const GRID_LAYOUT = {
  lg: [
    // {
    //   i: "AllMarket",
    //   x: 0,
    //   y: 0,
    //   h: 1.63,
    //   w: 6,
    //   minW: 1,
    //   minH: 1,
    //   resizeHandles: ["se"],
    //   isResizable: false,
    // },
    {
      i: "SubHeader",
      x: 0,
      y: 0,
      h: 1.63,
      w: 24,
      minW: 1,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
    {
      i: "AccountUpdate",
      x: 25,
      y: 0,
      h: 1.63,
      w: 6,
      minW: 1,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
    {
      i: "TradeChart",
      x: 0,
      y: 4.4,
      h: 20.5,
      w: 18.45,
      minW: 1,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
    {
      i: "OrderBook",
      x: 18.45,
      y: 4.4,
      h: 20.5,
      w: 5.55,
      minW: 1,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
    {
      i: "BuySell",
      x: 24,
      y: 4.4,
      h: 33,
      w: 6,
      minW: 1,
      minH: 1,
      isResizable: false,
      resizeHandles: ["se"],
    },
    {
      i: "UserLedger",
      x: 0,
      y: 24,
      h: 12.5,
      w: 24,
      minW: 1,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
  ],

  md: [
    // {
    //   i: "AllMarket",
    //   x: 0,
    //   y: 0,
    //   h: 1.63,
    //   w: 6,
    //   minW: 1,
    //   minH: 1,
    //   resizeHandles: ["se"],
    //   isResizable: false,
    // },
    {
      i: "SubHeader",
      x: 0,
      y: 0,
      h: 1.63,
      w: 23.5,
      minW: 1,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
    {
      i: "AccountUpdate",
      x: 24.5,
      y: 0,
      h: 1.63,
      w: 6.5,
      minW: 1,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
    {
      i: "TradeChart",
      x: 0,
      y: 4.4,
      h: 20.5,
      w: 17.45,
      minW: 1,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
    {
      i: "UserLedger",
      x: 0,
      y: 24,
      h: 12.5,
      w: 23.50,
      minW: 1,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
    {
      i: "OrderBook",
      x: 17.45,
      y: 4.4,
      h: 20.5,
      w: 6.05,
      minW: 1,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
    {
      i: "BuySell",
      x: 24,
      y: 4.4,
      h: 33,
      w: 6.5,
      minW: 1,
      minH: 1,
      isResizable: false,
      resizeHandles: ["se"],
    },
  ],
};

export const CUSTOMISE_VIEW_LIST = [
  // {
  //   id: "Market",
  //   title: "filter_options.market_label",
  // },

  // {
  //   id: "QuickTrading",
  //   title: "filter_options.quick_label",
  // },
  {
    id: "FavoriteBar",
    title: "filter_options.favorites_label",
  },
  // {
  //   id: "MarketTrades",
  //   title: "filter_options.market_label",
  // },
  // {
  //   id: "Chart",
  //   title: "filter_options.chart_label",
  // },
  // {
  //   id: "Depthv",
  //   title: "filter_options.depth_label",
  // },
  {
    id: "OrderBook",
    title: "filter_options.orderbook_label",
  },
  {
    id: "TradeChart",
    title: "filter_options.tradechart_label",
  },
  {
    id: "UserLedger",
    title: "filter_options.Orderschart_label",
  },
  {
    id: "BuySellTab",
    title: "filter_options.placeorderchart_label",
  },
  {
    id: "MarginRatio",
    title: "filter_options.marginratiochart_label",
  },
  {
    id: "Asset",
    title: "filter_options.assetschart_label",
  },
  {
    id: "AnalysisWidget",
    title: "filter_options.analysiswidget_label",
  },
  // {
  //   id: "OpenOrder",
  //   title: "filter_options.openorder_label",
  // },
  // {
  //   id: "MarketTrades",
  //   title: "filter_options.markettrade_label",
  // },
];

export const FONT_LAYOUT = {
  openOrder: 11,
  tableHead: 11,
  tableBody: 11,
  tickerHead: 13,
  tickerArrow: 15,
  tickerPrice: 12,
  minFont: 9,
};
