import React, { memo, useEffect, useRef } from "react";
import PlusIcon from "../../../../assets/icons/plus_icon.svg";
import MinusIcon from "../../../../assets/icons/minus_icon.svg";
import { numberSystemFormate } from "../../../../_utils/comman";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux.hooks";
import { saveCurrentPrice } from "../../redux/_slices/exchange.slice";

const OrderBookData = ({
  tab,
  side,
  // buyOrderBook,
  // sellOrderBook,
  totalDepth,
}) => {
  // console.log("orderbook>>>>>", buyOrderBook, sellOrderBook);

  // Redux State
  const selectedPairKey = useAppSelector((state) => state.user.selectedPairKey);
  const selectedPair = useAppSelector((state) => state.user.selectedPair);
  const currentPair = useAppSelector((state) => state.user.currentPair);
  const buyOrders = useAppSelector((state) => state.exchange.buyOrders);
  const sellOrders = useAppSelector((state) => state.exchange.sellOrders);
  let devpthCalc = 0;
  let devpthCalc1 = 0;
  let buyOrderBook = [];
  let sellOrderBook = [];
  if (side === "BS") {
    buyOrderBook = buyOrders?.slice(0, 8);
    sellOrderBook = sellOrders?.slice(-8);
  } else if (side === "B") {
    buyOrderBook = buyOrders?.slice(0, 18);
    sellOrderBook = sellOrders?.slice(-18);
  } else if (side === "S") {
    buyOrderBook = buyOrders?.slice(0, 18);
    sellOrderBook = sellOrders?.slice(-18);
  }

  const sumBuy = buyOrders
    ?.slice(0, 20)
    .reduce((acc, curr) => acc + curr[3], 0);

  const sumSell = sellOrders
    ?.slice(-20)
    .reduce((acc, curr) => acc + curr[3], 0);

  const BuyPerc = ((sumBuy / (sumBuy + sumSell)) * 100).toFixed(2);
  const SellPer = ((sumSell / (sumBuy + sumSell)) * 100).toFixed(2);

  // console.log(">>>>>>>>>>>>>>!!!!!!!!!", buyOrderBook);
  let decimalLimitAmount = selectedPair?.min_order_qty
    ?.toString()
    ?.includes(".")
    ? selectedPair?.min_order_qty?.toString()?.split(".")
    : 0;
  // console.log("decimalLimitAmount", decimalLimitAmount);
  const orderRef = useRef(null);
  const dispatch = useAppDispatch();

  /**
   * Get price and depth on clicking row
   * @param {*} item
   */
  const handleRow = (item) => {
    if (item) {
      // dispatch(saveOrderSize(item.depth));
      dispatch(saveCurrentPrice(item[1]));
    }
  };

  const getSellOrderData = (updatesellOrders) => {
    let cumulativeQuantity = 0;
    let reveseData = updatesellOrders
      .map((item) => ({ ...item }))
      .reverse()
      .map((item) => {
        cumulativeQuantity += item[3];
        item[5] = cumulativeQuantity;
        return item;
      })
      .reverse();
    return reveseData;
  };

  /**
   * get percentage on depth according to total depth
   * @param {*} item
   * @returns
   */
  const handleDepthBar = (item) => {
    const percentage = ((item[3] / totalDepth) * 100).toFixed(2);
    return percentage;
  };
  return (
      <>
          <div className="orderBookData_V2">
              <ul className="orderBook_Heading">
                  <li>
                      <span>Price USDT</span>
                  </li>
                  <li>
                      <span>Size {selectedPairKey?.split("_")[0]}</span>
                  </li>
                  <li>
                      <span>Total {selectedPairKey?.split("_")[0]}</span>
                  </li>
              </ul>
              {/* {console.log("orderBook", orderBook)} */}
              {(side === "BS" || side === "S") && (
                  <div
                      className={`orderBook_data ${side === "BS" && "allData"}`}
                  >
                      {sellOrderBook &&
                      sellOrderBook.length > 0 &&
                      getSellOrderData(sellOrderBook) &&
                      getSellOrderData(sellOrderBook)?.length > 0
                          ? getSellOrderData(sellOrderBook).map((item, i) => {
                                devpthCalc += item[3] ? item[3] : 0;
                                return (
                                    <ul
                                        key={i}
                                        className="Sellprice_data"
                                        style={{
                                            background: `linear-gradient(to right, rgba(224, 76, 86, 0.1), rgba(224, 76, 86, 0.1) ${handleDepthBar(
                                                item
                                            )}%, rgba(0, 0, 0, 0) ${handleDepthBar(
                                                item
                                            )}%)`,
                                        }}
                                    >
                                        <li
                                            onClick={() => handleRow(item)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span className="text_red">
                                                {numberSystemFormate(
                                                    item[1],
                                                    selectedPair.price_scale
                                                )}
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                {decimalLimitAmount &&
                                                decimalLimitAmount.length > 0
                                                    ? numberSystemFormate(
                                                          item[3],
                                                          decimalLimitAmount[1]
                                                              .length
                                                      )
                                                    : numberSystemFormate(
                                                          item[3]
                                                      )}
                                            </span>
                                        </li>
                                        <li>
                                            {/* {console.log("decimalLimitAmount", decimalLimitAmount, devpthCalc)} */}

                                            <span>
                                                {decimalLimitAmount &&
                                                decimalLimitAmount.length > 0
                                                    ? numberSystemFormate(
                                                          item[5],
                                                          decimalLimitAmount[1]
                                                              .length
                                                      )
                                                    : numberSystemFormate(
                                                          item[5]
                                                      )}
                                            </span>
                                        </li>
                                    </ul>
                                );
                            })
                          : ""}
                  </div>
              )}

              {side === "BS" && (
                  <div className="Order_Spread">
                      <ul className="d-flex align-items-center">
                          <li>Spread</li>
                          <li>0.33</li>
                          <li>0.13%</li>
                      </ul>
                  </div>
              )}
              {(side === "BS" || side === "B") && (
                  <div
                      className={`orderBook_data ${side === "BS" && "allData"}`}
                  >
                      {buyOrderBook && buyOrderBook?.length
                          ? buyOrderBook.map((item, i) => {
                                devpthCalc1 += item[3] ? item[3] : 0;
                                return (
                                    <ul
                                        key={i}
                                        className="Buyprice_data"
                                        style={{
                                            background: `linear-gradient(to right, rgba(70, 167, 151, 0.1), rgba(70, 167, 151, 0.1) ${handleDepthBar(
                                                item
                                            )}%, rgba(0, 0, 0, 0) ${handleDepthBar(
                                                item
                                            )}%)`,
                                        }}
                                    >
                                        <li
                                            onClick={() => handleRow(item)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span className="text_green">
                                                {numberSystemFormate(
                                                    item[1],
                                                    selectedPair.price_scale
                                                )}
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                {decimalLimitAmount &&
                                                decimalLimitAmount.length > 0
                                                    ? numberSystemFormate(
                                                          item[3],
                                                          decimalLimitAmount[1]
                                                              .length
                                                      )
                                                    : numberSystemFormate(
                                                          item[3]
                                                      )}
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                {decimalLimitAmount &&
                                                decimalLimitAmount.length > 0
                                                    ? numberSystemFormate(
                                                          devpthCalc1,
                                                          decimalLimitAmount[1]
                                                              .length
                                                      )
                                                    : numberSystemFormate(
                                                          devpthCalc1
                                                      )}
                                            </span>
                                        </li>
                                    </ul>
                                );
                            })
                          : ""}
                  </div>
              )}

              <div class="depth-container">
                  <div class="buy-bar" style={{ width: `${BuyPerc}%` }}>
                      <span>{BuyPerc}%</span>
                  </div>
                  <div class="sell-bar" style={{ width: `${SellPer}%` }}>
                      <span>{SellPer}%</span>
                  </div>
              </div>

              {/* <div className="Order_Minus_Plus">
          <span className="me-4">
            <img src={PlusIcon} alt="Plus" />
          </span>
          <span>
            <img src={MinusIcon} alt="Minus" />
          </span>
        </div> */}
          </div>
      </>
  );
};

export default memo(OrderBookData);
