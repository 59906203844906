import React from "react";
import Modal from "react-bootstrap/Modal";
import CommonButton from "../../../common/CommonButton/CommonButton";
import SuccessfulIcon from "../../../assets/images/admin/transaction-success.svg";
import usdtIcon from "../../../assets/images/tether-usdt.svg";

const TxnSuccessfulModal = ({ show, handleClose, amount, modalType }) => {
  return (
    <>
      <Modal
        className="Admin_Modal Conversion_successful_modal"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header
          className="text-center justify-content-center"
          closeButton
        >
          <Modal.Title>{modalType} successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Conversion_successful mb-0 text-center">
            <img className="my-5" src={SuccessfulIcon} alt="icon" />
            <div className="d-flex align-items-center justify-content-center">
              <p className="font24 me-3">
                {modalType === "Deposit" ? "Received" : "Sent"}
              </p>
              <h3 className="mb-0">
                <img src={usdtIcon} alt="icon" /> {amount}
              </h3>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <CommonButton
              className="w-50 me-2"
              title="Done"
              onClick={handleClose}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TxnSuccessfulModal;
