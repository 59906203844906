import { EXCHANGE_NAME } from "../../../../../constants/constants";
import { ApiServices } from "../../../services/api.services";
import { tradeValueFormate } from "../../../utils/comman";

const channelToSubscription = new Map();
const lastBarsCache = new Map();

const config = {
  supports_time: true,
  supported_resolutions: [
    // "1S",
    "1",
    "3",
    "5",
    "15",
    "30",
    "60",
    "120",
    "240",
    "360",
    "720",
    "D",
    "W",
    "M",
  ],
};

const getResolutionInMinute = (resolution) => {
  if (resolution === "1D") {
    return 24 * 60;
  } else if (resolution === "1W") {
    return 60 * 24 * 7;
  } else if (resolution === "1M") {
    return 60 * 24 * 30;
  }
  return resolution;
};

export const dataFeedObject = (
  pricescaleValue,
  price_decimal,
  base_decimal,
  selectedPairKey
) => {
  const dataFeed = {
    onReady: (callback) => {
      setTimeout(() => callback(config), 0);
    },

    resolveSymbol: (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback,
      extension
    ) => {
      setTimeout(() => {
        if (!selectedPairKey) {
          onResolveErrorCallback("cannot resolve symbol");
          return;
        }
        const selectedPairName = selectedPairKey
          .replace("_", "/")
          .toUpperCase();
        const symbolInfo = {
          name: selectedPairName,
          description: selectedPairName,
          type: "crypto",
          session: "24x7",
          timezone: "Etc/UTC",
          exchange: EXCHANGE_NAME,
          ticker: selectedPairName,
          minmov: 1,
          pricescale: pricescaleValue,
          has_intraday: true,
          intraday_multipliers: config.supported_resolutions,
          supported_resolutions: config.supported_resolutions,
          has_weekly_and_monthly: true,
          volume_precision: 8,
          data_status: "streaming",
          has_seconds: true, // Ensure this is set to true
        };
        onSymbolResolvedCallback(symbolInfo);
      }, 0);
    },

    getBars: async (
      symbolInfo,
      resolution,
      periodParams,
      onHistoryCallback,
      onErrorCallback
    ) => {
      const { from, to, firstDataRequest } = periodParams;
      const split_data = symbolInfo?.name?.split("/");
      const checkPair =
        split_data.length > 1
          ? split_data[0] + "_" + split_data[1]
          : split_data[0];

      // console.log("barss", resolution);

      const paramsData = {
        interval: resolution,
        pairName: checkPair,
        fromDate: from,
        toDate: to,
        // limit: 100,
        isfirst: firstDataRequest,
      };

      try {
        const { data } = await ApiServices.getGraphData(paramsData);
        if (!data || data === "Error") {
          onHistoryCallback([], { noData: true });
          return;
        }

        const bars = data.t.map((time, index) => ({
          time: time * 1000,
          low: tradeValueFormate(data.l[index], price_decimal),
          high: tradeValueFormate(data.h[index], price_decimal),
          open: tradeValueFormate(data.o[index], price_decimal),
          close: tradeValueFormate(data.c[index], price_decimal),
          volume: tradeValueFormate(data.v[index], base_decimal),
        }));

        if (firstDataRequest) {
          lastBarsCache.set(symbolInfo.name, { ...bars[bars.length - 1] });
        }

        onHistoryCallback(bars, { noData: bars.length === 0 });
      } catch (err) {
        onHistoryCallback([], { noData: true });
      }
    },

    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    ) => {
      dataFeed.onRealtimeCallback = (kline) => {
        // console.log('kline data', formatDate(new Date(kline.time * 1000)))
        const newKlineData = {
          selectedPair: selectedPairKey,
          price: tradeValueFormate(kline?.price, price_decimal),
          volume: tradeValueFormate(kline?.volume, base_decimal),
          time: getNextBarTime(resolution, kline?.time),
          open: tradeValueFormate(Number(kline.price)),
          close: tradeValueFormate(Number(kline.price)),
          high: tradeValueFormate(Number(kline.price)),
          low: tradeValueFormate(Number(kline.price)),
        };
        subscribeBars(
          symbolInfo,
          resolution,
          onRealtimeCallback,
          subscribeUID,
          onResetCacheNeededCallback,
          lastBarsCache.get(symbolInfo.name),
          newKlineData
        );

        // console.log('ressolution', resolution);

        if (resolution == "1M") {
          unsubscribeBars(subscribeUID);
        }
      };
    },

    unsubscribeBars: (subscribeUID) => {
      unsubscribeBars(subscribeUID);
    },

    onRealtimeCallback: (kline) => {},
  };

  return dataFeed;
};

const createChannelString = (symbolInfo) => {
  var channel = symbolInfo?.name?.split(/[:_/]/);
  const to = channel[1];
  const from = channel[0];
  return `0~${EXCHANGE_NAME}~${to}~${from}`;
};

const getNextBarTime = (resolution, barTime) => {
  return barTime;
  let coeff = resolution;

  if (resolution?.includes("1D")) {
    // 1 day in minutes === 1440
    resolution = 1440;
    coeff = resolution * 60;
  } else if (resolution?.includes("1W")) {
    // 1 week in minutes === 10080
    resolution = 10080;
    coeff = resolution * 60;
  } else if (resolution?.includes("1M")) {
    // 1 month in minutes === 43800
    resolution = 43800;
    coeff = resolution * 60;
  } else if (resolution?.includes("1S")) {
    // 1 month in minutes === 43800
    resolution = 1;
    coeff = resolution;
  } else {
    coeff = resolution * 60;
  }

  return Math.floor(barTime / coeff) * coeff;
};

// const getNextBarTime = (resolution, barTime) => {
//   let coeff;
//   // console.log('resolution', resolution);
//   if (resolution === "1S") {
//     coeff = 1; // 1 second in seconds
//   } else if (resolution.includes("1D")) {
//     coeff = 1440 * 60; // 1 day in seconds
//   } else if (resolution.includes("1W")) {
//     coeff = 10080 * 60; // 1 week in seconds
//   } else if (resolution.includes("1M")) {
//     coeff = 43800 * 60; // 1 month in seconds
//   } else if (resolution.includes("1")) {
//     coeff = 60; // 1 month in seconds
//   } else {
//     coeff = resolution * 60; // Other resolutions are in minutes, convert to seconds
//   }

//   const nextBarTime = Math.floor(barTime / coeff) * coeff * 1000;
//   const nextBarTimeDate = new Date(nextBarTime);

//   const bartimeDate = new Date(barTime);
//   console.log(`Resolution: ${resolution}, Bar Time: ${formatDate(bartimeDate)}, Next Bar Time: ${formatDate(nextBarTimeDate)}`);
//   return nextBarTime;
//   // return Math.floor(barTime / coeff) * coeff * 1000; // Convert back to milliseconds
// };

const formatDate = (date) => {
  const padZero = (num) => String(num).padStart(2, "0");

  const day = padZero(date.getDate());
  const month = padZero(date.getMonth() + 1); // Months are zero-indexed
  const year = date.getFullYear();

  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

const subscribeBars = (
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastDailyBar,
  newKlineData
) => {
  const channelString = createChannelString(symbolInfo);
  // console.log("channelstring", channelString);
  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };

  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    subscriptionItem.handlers.push(handler);
    onGetData(newKlineData);
    return;
  }

  subscriptionItem = {
    subscribeUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  };

  channelToSubscription.set(channelString, subscriptionItem);

  onGetData(newKlineData);
};

const onGetData = (_data) => {
  const data = {
    sub_type: parseInt("0", 10),
    exchange: _data?.selectedPair,
    to: _data?.selectedPair?.split("_")[1]?.toUpperCase(),
    from: _data?.selectedPair?.split("_")[0]?.toUpperCase(),
    trade_id: _data.selectedPair,
    ts: _data?.time,
    volume: _data?.volume,
    price: _data?.close,
    close: _data?.close,
    open: _data?.open,
    high: _data?.high,
    low: _data?.low,
  };
  // console.log("dataaaaaaaaaaaaaaaa", data);
  const tradePrice = _data?.price;
  const tradeTime = _data?.time;
  const tradeVolume = _data?.volume;

  const channelString = `0~${EXCHANGE_NAME}~${data?.to}~${data?.from}`;

  const subscriptionItem = channelToSubscription.get(channelString);
  // console.log("subscriptionItem", subscriptionItem);
  if (subscriptionItem === undefined) {
    return;
  }

  const lastDailyBarData = subscriptionItem.lastDailyBar;

  const nextDailyBarTime = lastDailyBarData?.time / 10 ** 3;

  let bar;
  if (
    (lastDailyBarData && Object.keys(lastDailyBarData).length === 0) ||
    tradeTime > nextDailyBarTime
  ) {
    bar = {
      time: tradeTime * 10 ** 3,
      open: tradePrice,
      high: tradePrice,
      low: tradePrice,
      close: tradePrice,
      volume: tradeVolume,
    };
  } else {
    bar = {
      ...lastDailyBarData,
      high: Math.max(lastDailyBarData?.high, tradePrice),
      low: Math.min(lastDailyBarData?.low, tradePrice),
      close: tradePrice,
      volume: lastDailyBarData?.volume + tradeVolume,
      time: nextDailyBarTime * 10 ** 3,
    };
  }
  // console.log("baaaaaaaaaaaaaaar", bar);
  subscriptionItem.lastDailyBar = bar;

  // send data to every subscriber of that symbol
  subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
};

const unsubscribeBars = (subscriberUID) => {
  // console.log('unsubscribing bars', subscriberUID);

  for (const [
    channelString,
    subscriptionItem,
  ] of channelToSubscription.entries()) {
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      // Remove handler
      subscriptionItem.handlers.splice(handlerIndex, 1);

      // If no more handlers, unsubscribe and exit loop
      if (subscriptionItem.handlers.length === 0) {
        channelToSubscription.delete(channelString);
      }
      break;
    }
  }
};
