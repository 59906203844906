import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const options = {
  position: "top-center",
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
class ToasterMsg {
  success = (message) => {
    toast.success(message, options);
  };

  error = (message) => {
    toast.error(message, options);
  };

  info = (message) => {
    toast.info(message, options);
  };
}

export const toastMsg = new ToasterMsg();

class Toaster {
  waitingPromis = async (callBackFuction, pendingMsg, successMsg) => {
    let options = {
      pending: pendingMsg,
      success: successMsg,
      error: {
        render({ data }) {
          if (data !== undefined) {
            return `${data?.message}`;
          } else {
            return "Something went wrong!"
          }

        },
      },
    };
    return await toast.promise(callBackFuction, options);
  };
}

export const toastAlert = new Toaster();

export const ToastContainerComponent = () => {
  return <ToastContainer position="top-center" autoClose={3000} />;
};
