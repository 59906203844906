import EN from "../assets/images/flags/en.svg"
import FR from "../assets/images/flags/fr.svg"

export const LANGUAGE = [
  {
    code: "en",
    name: "English",
    country_code: "EN",
    icon: EN,
  },
  {
    code: "fr",
    name: "Français",
    country_code: "FR",
    icon: FR,
  },
];

export const MARKET_TYPES = {
  L: 0,
  M: 1,
};

export const MARGIN_MODE = {
  Cross: 0,
  Isolated: 1,
};

export const ORDER_STATUS = {
  1: "OPEN",
  2: "CANCELLED",
  3: "MATCHED",
  4: "PARTIAL",
};

export const ORDER_MARKET_TYPE = {
  0: "Limit",
  1: "market",
};

export const ORDER_SIDE_TYPE = {
  0: "Buy",
  1: "Sell",
};
export const ORDER_TYPES = {
  0: "Limit",
  1: "Market",
  2: "Stop"
}
