import Web3 from "web3";

import store from "../redux/_store/configureStore";
import { convertDecimalToHex, showDecimal } from "../_utils/comman";
// import { provider } from "./walletServices";

const wallets = [
  "metamask",
  "walletconnect",
  "binance_wallet",
  "trust_wallet",
  "Coinbase_Wallet",
  "Token_Pocket",
  "Brave",
  "metamask",
];

export const mergeTwoRandom = async (arr1, arr2) => {
  function extractRandom(arr) {
    let index = Math.floor(Math.random() * arr?.length);
    let result = arr[index];
    // remove item from the array
    arr.splice(index, 1);
    return result;
  }

  let result = [];
  while (arr1.length || arr2.length) {
    if (arr1.length) {
      result[result?.length] = extractRandom(arr1);
    }
    if (arr2.length) {
      result[result?.length] = extractRandom(arr2);
    }
  }
  return result;
};

export const tofixFunctionSliced = (value) => {
  let newValue = value?.toString();
  newValue = newValue.includes(".")
    ? newValue.slice(0, newValue.indexOf(".") + 5)
    : newValue;
  return newValue;
};
/**REMOVE e FORM BIG NUMBER */
export const toFixedDeposit = (x, network) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x?.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x?.toString().substring(2);
    }
  } else {
    e = parseInt(x?.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  x = x?.toString();
  let newX =
    network === "Ethereum"
      ? Number(x) / 10 ** 6
      : Web3.utils.fromWei(x, "ether");
  // let newX = Web3.utils.fromWei(x, "ether");
  // newX = Number(newX)?.toFixed(5)?.slice(0, -1);
  console.log("in fixed deposit", x, newX);
  return newX;
  // return x;
};

/**REMOVE e FORM BIG NUMBER */
export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  x = x?.toString();
  // let newX = Web3.utils.fromWei(x, "ether");
  // newX = Number(newX)?.toFixed(5)?.slice(0, -1);
  return x;
  // return x;
};

export const valueFixedFunction = (value) => {
  return value?.toFixed(5)?.slice(0, -1);
};

export const getProvider = async () => {
  const wallettype = store.getState()?.user?.wallet;
  console.log("wallettype == wallets[1]", wallettype == wallets[1]);
  const { ethereum, BinanceChain, trustwallet, coinbaseWalletExtension } =
    window;
  const provider1 =
    wallettype == "binance_wallet"
      ? BinanceChain
      : wallettype == "trust_wallet"
      ? trustwallet
      : wallettype == "Coinbase_Wallet"
      ? coinbaseWalletExtension
      : wallettype == wallets[1]
      ? ethereum
      : ethereum;

  return provider1;
};

export const chainNumberProvide = async () => {
  const wallettype = store.getState()?.user?.wallet;
  console.log("wallettype", wallettype);
  const provider = await getProvider();
  switch (wallettype) {
    case wallets[2]:
      return Web3.utils.hexToNumber(provider?.chainId);
    case wallets[3]:
      return provider?.networkVersion;
    case wallets[0]:
      return provider?.networkVersion;
    case wallets[4]:
      return provider?.networkVersion;
    case wallets[5]:
      return provider?.networkVersion;
    case wallets[6]:
      return provider?.networkVersion;
    case wallets[0]:
      return provider?.networkVersion;
    case wallets[1]:
      return provider?.chainId;
    default:
      return provider?.networkVersion;
  }
};

/**REMOVE e FORM BIG NUMBER & and handle Big Number Error Function*/
export const toFixedNumber = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  x = x?.toString();
  let newX = Web3.utils.fromWei(x, "ether");
  newX = Number(newX)?.toFixed(5)?.slice(0, -1);
  return newX;
};

export const toFunctionBigNumber = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  // x = x?.toString();
  // let newX = Web3.utils.fromWei(x, "ether");
  // newX = Number(newX)?.toFixed(5)?.slice(0, -1);
  return x;
};

export const getFeeCalculationsBuy = (
  orderPrice,
  orderQty,
  leverage,
  feeMaker,
  feeTaker
) => {
  let makerFee = 0;
  let takerFee = 0;
  if (feeMaker > 0) {
    makerFee = Number(feeMaker) / Number(100);
  }

  if (feeTaker > 0) {
    takerFee = Number(feeTaker) / Number(100);
  }

  const feeOpenPosition = orderQty * orderPrice * takerFee;
  // Buy liquidation
  const bankruptcyPriceBuy = (orderPrice * (leverage - 1)) / leverage;

  const feeClosePositionBuy = orderQty * bankruptcyPriceBuy * makerFee;
  const closeFeeBuy = feeClosePositionBuy
    ? showDecimal(feeClosePositionBuy, 8)
    : 0;
  // Sell liquidation
  const positionFee = feeOpenPosition ? showDecimal(feeOpenPosition, 8) : 0;
  console.log("positionFee", closeFeeBuy, positionFee);
  return Number(positionFee) + Number(closeFeeBuy);
};

export const getFeeCalculationsSell = (
  orderPrice,
  orderQty,
  leverage,
  feeMaker,
  feeTaker
) => {
  const shortMaker = feeMaker;
  const shortTaker = feeTaker;

  let makerFee = 0;
  let takerFee = 0;
  if (shortMaker > 0) {
    makerFee = Number(shortMaker) / Number(100);
  }
  if (shortTaker > 0) {
    takerFee = Number(shortTaker) / Number(100);
  }
  const feeOpenPosition = orderQty * orderPrice * takerFee;
  const bankruptcyPriceSell = (orderPrice * (leverage + 1)) / leverage;
  const feeClosePositionSell = orderQty * bankruptcyPriceSell * takerFee;
  const closeFeeSell = feeClosePositionSell
    ? showDecimal(feeClosePositionSell)
    : 0;
  const positionFee = feeOpenPosition ? showDecimal(feeOpenPosition, 8) : 0;
  console.log(
    "positionFee",
    closeFeeSell,
    positionFee,
    Number(positionFee) + Number(closeFeeSell)
  );
  return Number(positionFee) + Number(closeFeeSell);
};

export const inputDecimal = (value, decimals) => {
  let valData = value;
  // console.log("inputDecimal", value?.toString().split(".")[1] !== "");
  if (
    value?.toString().includes(".") &&
    value?.toString().split(".")[1] !== ""
  ) {
    let getDicimal = value?.toString().split(".");
    let result = getDicimal[1].substring(0, Number(decimals));
    valData = getDicimal[0] + "." + result;
  }
  return valData;
};

export const getFeeByPercent = (size, price, feePercent, leverage) => {
  const getFinalAmount = (Number(size) * Number(price)) / Number(leverage);
  const finalFee = getFinalAmount * (Number(feePercent) / 100);
  return finalFee;
};

export const showDecimalHead = (amountVal, decimals) => {
  let decimalVal = amountVal;
  let updateVal = amountVal?.toString();
  if (updateVal?.includes(".")) {
    let getDicimal = updateVal.split(".");
    let result = getDicimal[1].substring(0, Number(decimals ? decimals : 5));
    decimalVal = getDicimal[0] + "." + result;
  }
  return Number(decimalVal);
};

export const convertToInternationalCurrencySystem = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
};

export const calculateLiquidationPrice = ({
  entryPrice,
  leverage,
  size,
  maintenanceMargin = 0.005, // 0.5% default maintenance margin
  extraMargin = 0, // Additional margin added to the position
  isLong = true,
}) => {
  // Validate inputs
  if (leverage <= 0 || entryPrice <= 0 || size <= 0) {
    throw new Error("Invalid input parameters");
  }
  if (extraMargin < 0) {
    throw new Error("Extra margin cannot be negative");
  }

  // Calculate initial margin required (position value / leverage)
  const positionValue = size * entryPrice;
  const initialMargin = positionValue / leverage;

  // Calculate maintenance margin requirement
  const maintenanceMarginRequired = positionValue * maintenanceMargin;

  // Calculate liquidation price according to Bybit's formulas
  let liquidationPrice;

  if (isLong) {
    // Formula for Long positions:
    // Liquidation Price = Entry Price - [(Initial Margin - Maintenance Margin)/Position Size] - (Extra Margin/Position Size)
    liquidationPrice =
      entryPrice -
      (initialMargin - maintenanceMarginRequired) / size -
      extraMargin / size;
  } else {
    // Formula for Short positions:
    // Liquidation Price = Entry Price + [(Initial Margin - Maintenance Margin)/Position Size] + (Extra Margin/Position Size)
    liquidationPrice =
      entryPrice +
      (initialMargin - maintenanceMarginRequired) / size +
      extraMargin / size;
  }

  return {
    liquidationPrice,
    initialMargin,
    maintenanceMarginRequired,
    positionValue,
    effectiveMargin: initialMargin + extraMargin,
    riskMetrics: {
      liquidationDistance: isLong
        ? ((entryPrice - liquidationPrice) / entryPrice) * 100
        : ((liquidationPrice - entryPrice) / entryPrice) * 100,
      warning:
        leverage >= 50
          ? "HIGH RISK: Very high leverage increases the risk of liquidation substantially"
          : "Warning: Trading with leverage involves significant risk of loss",
    },
  };
};
