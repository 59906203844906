import React from 'react'
import "./CustomTooltip.scss";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import infoIcon1 from "../../assets/icons/info.svg"
import { InfoIcon, RefreshIcon } from '../../assets/Svg/SvgImages';

const CustomTooltip = ({ text, className, IconClass, tooltipClass, align }) => {
    return (
        <>
            <OverlayTrigger
                delay={{ hide: 450, show: 200 }}
                overlay={(props) => (
                    <Tooltip className={tooltipClass} {...props}>
                        {text} 
                    </Tooltip> 
                )}
                placement={align}                
            >
                <span className={`tooltipIcon ${className}`}>
                    {/* <img src={infoIcon1} alt='img' /> */}
                    <InfoIcon />
                    <span className={`d-none ${IconClass}`}><RefreshIcon /></span>
                </span>
            </OverlayTrigger>
        </>
    )
}

export default CustomTooltip