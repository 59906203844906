import React from 'react'
import CommonButton from '../../../../../common/CommonButton/CommonButton'
import "./TrendingPairs.scss"
import arrowicon from "../../../../../assets/icons/blue_arrow.svg"
import FireIcon from "../../../../../assets/icons/fire-icon.svg"

const TrendingPairs = () => {
    const Recentdata = [
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
        {
            tokenname: "BTC",
            tokenname1: "USDT",
            price: "28,844.80",
            pricevalue: "+1.48%",
            trade: "Trade",
        },
    ];
    return (
        <>
            <div className='Trending_Pair' >
                <div className='Overview_Heading d-flex align-items-center justify-content-between'>
                    <h6><img src={FireIcon} alt='fire icon' /> Trending Pairs</h6>
                    <CommonButton title="View all" className="grey-btn2" />
                </div>

                <ul className='Trending_Pair_Box'>
                    {Recentdata.map((data, i) => {
                        return (
                            <li key={i}>
                                <div className='Trending_Box_Card'>
                                    <p>{data.tokenname}/<span>{data.tokenname1}</span></p>
                                    <h6 className='text_green'>
                                        {data.price}
                                        <small>{data.pricevalue}</small>
                                    </h6>

                                    <span className='LineChar_icon'>{data.trade} <img src={arrowicon} alt='icon' /></span>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

export default TrendingPairs
