import { Container, Row, Col } from "react-bootstrap";
import { Header } from "../../common";

import CustomButton from "../../common/StakingComponents/CustomButton/CustomButton";
import ConnectViewWrap from "../../common/StakingComponents/ConnectViewWrap/ConnectViewWrap";
import coin from "../../assets/icons/coin.svg";
import share from "../../assets/icons/share.svg";
import gift from "../../assets/icons/gift.svg";

import StakingFooter from "../../common/Footer/StakingFooter/StakingFooter";
import StakingTabs from "./StakingTabs/StakingTabs";
import StakingReward from "./StakingReward";
import "./Staking.scss";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getAPY,
  TotalStaked,
  USDCdistributed,
} from "./services/ContractServices/ContractCallService/ContractCallservices";

import BannerCoin from "../../assets/images/staking_coins.svg";

import {
  dollarFormat,
  fixAndConvertFunction,
  tofixFunction,
} from "./services/HelperServices";
import { useDispatch } from "react-redux";
import Countdown, { zeroPad } from "react-countdown";
import { DOLLARPRICE } from "./constants/constants";
import {
  resetUser,
  saveNetworkChainId,
  saveUserWalletAddress,
  saveWallet,
} from "../../redux/_slices/user.slice";
import walletServices from "./services/walletServices";
import store from "../../redux/_store/configureStore";
import { toCustomFixed } from "./services/ContractServices/ContractHelper";
import eth from "../../assets/images/animate_eth.svg";
import sejf from "../../assets/images/sejf_animate.png";
import ehx1 from "../../assets/images/ehx1_animate.png";
import arrowicon from "../../assets/images/animate_arrow.svg";
import mobImg from "../../assets/images/GFX_stake_icon.svg";
import Arrowicon from "../../assets/icons/blue_arrow.svg";
import { switchWalletChain } from "../../services/wallet.services";
import { useDisconnect } from "wagmi";

let x;

const renderer = ({ hours, minutes, seconds }) => (
  <span>
    {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
  </span>
);

const Staking = () => {
  const stakeKeys = ["SOLOSTAKE", "LPSTAKE"];
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const [currentAPY, setCurrentAPY] = useState(0);
  const [totalEHXstakePlatform, settotalEHXstakePlatform] = useState(0);
  const [compvalue, setCompvalue] = useState(1);
  const [profitSharingTimestam, setProfitSharingTimestam] = useState(0);
  const [updateUsdcDistrubuted, setUpdateUsdcDistrubuted] = useState(0);
  const [convertUpdateUsdcDistrubuted, setconvertUpdateUsdcDistrubuted] =
    useState(0);

  const [countFunding, setCountFounding] = useState(true);
  const [fundingCountDown, setFundingCountDown] = useState(0);
  const [switchNetworkKey, setSwitchNetworkKey] = useState("");
  const chainId = useSelector((state) => state.user.chainId);

  const exhstake = useSelector((state) => state?.user?.totalEXHstake);
  const lpstake = useSelector((state) => state?.user?.totalLPstake);
  const userTab = useSelector((state) => state?.user?.usertab);
  const walletAddress = useSelector((state) => state.user.walletAddress);
  const { countDown } = useSelector((state) => state?.swapData);
  const wallettype = store.getState()?.user?.wallet;

  const memoizedDisconnect = useCallback(() => {
    disconnect();
  }, [disconnect]);

  useEffect(() => {
    updateapy();
    totalStakeinPlatform();
    getusdcDistrubuted();
  }, [userTab, switchNetworkKey, walletAddress]);

  const getusdcDistrubuted = async (value) => {
    const USDCdistribute = await USDCdistributed();
    value == true
      ? setUpdateUsdcDistrubuted(value)
      : setUpdateUsdcDistrubuted(dollarFormat.format(Number(USDCdistribute))); // /10**6

    setconvertUpdateUsdcDistrubuted(Number(USDCdistribute)); // /10**6
  };

  const updateapy = async () => {
    const apy = await getAPY();
    setCurrentAPY(Number(apy));
  };

  const totalStakeinPlatform = async () => {
    let stakedata = stakeKeys[0];
    // !userTab || userTab == "stakeehx" ? stakeKeys[0] : stakeKeys[1];
    const totalStake = await TotalStaked(stakedata);
    settotalEHXstakePlatform(fixAndConvertFunction(totalStake));
  };

  const CompKeys = (value) => {
    setCompvalue(value);
  };

  const timerState = useMemo(
    (data) => {
      return Date.now() + 3600000;
    },
    [x]
  );

  // socket.on("funding_rate_countdown", (response) => {
  //   setFundingCountDown(response?.funding_rate_countdown);
  // });
  // const getCounDown = () => {
  //   socket.on("funding_rate_countdown", (response) => {
  //     setFundingCountDown(response?.funding_rate_countdown);
  //   });
  // };
  // useEffect(() => {
  //   if (countFunding && socket?.id) {
  //     getCounDown();
  //     setCountFounding(false);
  //   }
  // }, [countFunding, socket?.id, walletAddress]);

  // useEffect(() => {
  //   socket.on("funding_rate_countdown", (response) => {
  //     console.log("firstresponse", response);
  //     setFundingCountDown(response?.funding_rate_countdown);
  //   });
  // }, []);

  // useEffect(() => {
  //   if (countDown) {
  //     setFundingCountDown(countDown);
  //   }
  // }, [countDown]);

  // useEffect(() => {
  //   if (window.location.pathname == "/staking") {
  //     dispatch(saveNetworkChainId(1));
  //   }
  // }, [window.location.pathname]);

  const forceSwitchNetwork = async () => {
    let path = "/staking";
    // const response = await walletServices.metaMaskConnect(path);
    // if ("response" === true) {
    //   dispatch(saveUserWalletAddress(""));
    // }
    //  else {
    // dispatch(saveNetworkChainId(1));
    // const result = await switchWalletChain("metamask", 1);
    dispatch(saveWallet("metamask"));
    // dispatch(saveUserWalletAddress(response));
    // setSwitchNetworkKey(response);
    // }
  };
  // useEffect(() => {
  //   if (
  //     window.ethereum &&
  //     window.location.pathname == "/staking" &&
  //     window.ethereum.networkVersion != chainId &&
  //     wallettype == "metamask"
  //   ) {
  //     forceSwitchNetwork();
  //   } else if (chainId != 1) {
  //     dispatch(saveUserWalletAddress(""));
  //     dispatch(saveNetworkChainId(1));
  //     disconnect();
  //   }
  // }, [window.location.pathname]);

  return (
    <>
      {!userTab || userTab == "stakeehx" ? (
        <>
          <Header />
          <section className="section-top staking_banner">
            <Container className="commn-container">
              <Row className="align-items-center justify-content-center">
                <Col
                  xs={12}
                  lg={6}
                  xl={5}
                  className="text-center text-lg-start mb-4 pb-2 pb-lg-0 mb-lg-0"
                >
                  <h1>
                    EHX <em className="ms-0 ms-md-2">Staking</em> <br />& Profit{" "}
                    <em className="ms-0 ms-md-2">Sharing</em>
                  </h1>
                  <p>Stake EHX for token rewards and profit-sharing in USDT</p>
                  <CustomButton
                    title="Stake now"
                    text="- Earn profit!"
                    className="learn-more"
                    Icon={Arrowicon}
                    imgclass="d-inline-block"
                  />
                </Col>
                <Col xs={12} lg={6} xl={5}>
                  <div className="Banner_coin d-none d-lg-block">
                    <div className="token_card_background"></div>
                    <img src={eth} alt="" />
                    <img src={sejf} alt="" />
                    <img src={ehx1} alt="" />
                    <img src={arrowicon} alt="" />
                  </div>
                  <div className="d-block d-lg-none text-center mt-4">
                    <img className="" src={mobImg} alt="img" />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="staking-section">
            <Container className="commn-container">
              <Row className="mb-80">
                <Col lg={6} className="mb-30 mb-lg-0">
                  <ul className="staking-content">
                    <li className="staking-content__item staking-content__item--title">
                      <span className="staking-content__icon">
                        <img src={coin} alt="coin" />
                      </span>
                      <h2>Eterna EHX Staking</h2>
                      <strong className="d-block text-blue ms-0">
                        {`APR ${currentAPY ? currentAPY : 0} %`}
                      </strong>
                    </li>
                    <li className="staking-content__item">
                      Total EHX staked:
                      <strong>
                        {totalEHXstakePlatform ? totalEHXstakePlatform : 0}
                        {"    "}
                        {`(${
                          totalEHXstakePlatform
                            ? tofixFunction(
                                Number(totalEHXstakePlatform) * DOLLARPRICE || 0
                              )
                            : 0
                        })`}
                      </strong>
                    </li>
                    <li className="staking-content__item">
                      Stake your EHX tokens for a fixed APY.{" "}
                      <br className="d-inline" /> The more EHX you stake,the
                      higher your reward tier in our Referral System.
                    </li>
                  </ul>
                </Col>
                <Col lg={6}>
                  {!walletAddress ? (
                    <ConnectViewWrap AccessStaking={"Access staking"} />
                  ) : (
                    <StakingTabs
                      CompKeys={CompKeys}
                      totalStakeinPlatform={totalStakeinPlatform}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-30 mb-lg-0">
                  <ul className="staking-content">
                    <li className="staking-content__item staking-content__item--title">
                      <span className="staking-content__icon">
                        <img src={share} alt="share" />
                      </span>
                      <h2>Eterna EHX Profit Sharing</h2>
                    </li>
                    <li className="staking-content__item"></li>
                    <li className="staking-content__item">
                      Total USDT distributed:
                      <strong
                        title={` ${
                          convertUpdateUsdcDistrubuted
                            ? "$" +
                              toCustomFixed(convertUpdateUsdcDistrubuted, 8)
                            : 0
                        }`}
                      >{` ${
                        updateUsdcDistrubuted ? updateUsdcDistrubuted : 0
                      }`}</strong>
                      <br />
                      Time until next distribution of profit-sharing:
                      <strong>
                        {countDown ? countDown : 0}
                        {/* {profitSharingTimestam ? (
                          <Countdown
                            date={profitSharingTimestam}
                            key={profitSharingTimestam}
                            intervalDelay={0}
                            renderer={renderer}
                          />
                        ) : (
                          <Countdown date={1675250000} renderer={renderer} />
                        )} */}
                      </strong>
                      {/* <br />
                      Days to the end of current pool:<strong>22</strong> */}
                    </li>
                    <li className="staking-content__item">
                      {/* Eterna rewards its long-term holders with income sharing.
                      Once every hour, fifty percent (50%) of Eterna’s net
                      income from trading fees is deposited in the profit pool,
                      and shared with our token stakers. Income shares are
                      automatically distributed as ERC-20 USDT. */}
                      Eterna rewards its long-term holders with income sharing.
                      Periodically, Eterna's taker-maker fees are distributed
                      among our token stakers, as ERC-20 USDT.
                    </li>
                  </ul>
                </Col>
                <Col lg={6}>
                  {!walletAddress ? (
                    <ConnectViewWrap AccessStaking={"Access profit-sharing"} />
                  ) : (
                    <StakingReward
                      getusdcDistrubuted={getusdcDistrubuted}
                      totalStakeinPlatform={totalStakeinPlatform}
                      compvalue={compvalue}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </section>
          <StakingFooter />
        </>
      ) : (
        <>
          <Header />
          <section className="section-top staking_banner">
            <Container className="commn-container">
              <Row className="align-items-center justify-content-center">
                <Col
                  xs={12}
                  lg={6}
                  xl={5}
                  className="text-center text-lg-start mb-4 mb-lg-0"
                >
                  <h1>
                    EHX <em className="ms-0 ms-md-2">Staking</em> <br />& Profit{" "}
                    <em className="ms-0 ms-md-2">Sharing</em>
                  </h1>
                  <p>Stake EHX for token rewards and profit-sharing in USDT</p>
                  <CustomButton
                    title="Stake now"
                    text="- Earn profit!"
                    className="learn-more"
                    Icon={Arrowicon}
                    imgclass="d-inline-block"
                  />
                </Col>
                <Col xs={12} lg={6} xl={5}>
                  <div className="Banner_coin d-none d-lg-block">
                    <div className="token_card_background"></div>
                    <img src={eth} alt="" />
                    <img src={sejf} alt="" />
                    <img src={ehx1} alt="" />
                    <img src={arrowicon} alt="" />
                  </div>
                  <div className="d-block d-lg-none text-center mt-4">
                    <img className="" src={mobImg} alt="img" />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="staking-section">
            <Container className="commn-container">
              <Row className="mb-80">
                <Col lg={6} className="mb-30 mb-lg-0">
                  <ul className="staking-content">
                    <li className="staking-content__item staking-content__item--title">
                      <span className="staking-content__icon">
                        <img src={coin} alt="coin" />
                      </span>
                      <h2>Eterna LP Staking</h2>
                      {/* <strong className="d-block text-blue ms-0">APY 5%</strong> */}
                      <strong className="d-block text-blue ms-0">
                        {/* {"APY" + currentAPY + "%"} */}
                        {`APY ${0} %`}
                      </strong>
                    </li>
                    <li className="staking-content__item">
                      Total LP staked:
                      <strong>{`${
                        !totalEHXstakePlatform ? totalEHXstakePlatform : 0
                      }`}</strong>
                    </li>
                    {userTab == "addlp" ? (
                      <li className="staking-content__item">
                        LP staking allows Eterna investors to add Ethereum + an
                        equal USD amount of EHX tokens to Eterna's LP, and as a
                        reward, you will receive a higher APY compared to
                        standard staking.
                        {/* depend on the total amount of tokens staked. <br className="d-none d-sm-inline" />{" "}
                      tempor incididunt ut labore et dolore magna in voluptate. */}
                      </li>
                    ) : (
                      <li className="staking-content__item">
                        Investors who want greater control over the staking
                        process can manually add ETH + EHX tokens to Eterna's LP
                        on Uniswap, and then stake the resulting LP tokens here.
                      </li>
                    )}
                  </ul>
                </Col>
                <Col lg={6}>
                  {!walletAddress ? (
                    <ConnectViewWrap AccessStaking={"Access staking"} />
                  ) : (
                    <StakingTabs
                      CompKeys={CompKeys}
                      totalStakeinPlatform={totalStakeinPlatform}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-30 mb-lg-0">
                  <ul className="staking-content">
                    <li className="staking-content__item staking-content__item--title">
                      <span className="staking-content__icon">
                        <img src={gift} alt="share" />
                      </span>
                      <h2>Eterna LP Reward</h2>
                    </li>
                    <li className="staking-content__item"></li>
                    <li className="staking-content__item">
                      Total LP reward in EHX:
                      <strong>{`$ ${
                        !updateUsdcDistrubuted ? updateUsdcDistrubuted : 0
                      }`}</strong>
                      {/* <br />
                      Total USDT to distribute in current pool:
                      <strong>{`$ ${
                        updateUsdcDistrubuted ? updateUsdcDistrubuted : 0
                      }`}</strong>
                      <br />
                      Days to the end of current pool:<strong>22</strong> */}
                    </li>
                    <li className="staking-content__item">
                      LP staking rewards are variable and depend on the total
                      amount of tokens staked. EHX tokens received from LP
                      staking can be added to the standard staking pool, for an
                      increase share of Eterna’s net income.
                    </li>
                  </ul>
                </Col>
                <Col lg={6}>
                  {!walletAddress ? (
                    <ConnectViewWrap AccessStaking={"Access profit-sharing"} />
                  ) : (
                    <StakingReward
                      getusdcDistrubuted={getusdcDistrubuted}
                      totalStakeinPlatform={totalStakeinPlatform}
                      compvalue={compvalue}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </section>
          <StakingFooter />
        </>
      )}
    </>
  );
};

export default Staking;
