import React, { memo, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { NotificationIcon } from "../../assets/Svg/SvgImages";
import infoIcon from "../../assets/icons/chart-line.svg";
// import infoIcon1 from "../../assets/icons/diamond-exclamation.svg";
// import infoIcon2 from "../../assets/icons/diamond-exclamation_red.svg";
import BlueArrow from "../../assets/images/big_arrow.svg";

function ToastNotification(props) {
  const { show, setShow, notification, newCount, handleRead, walletAddress } =
    props;
  //   getToken(setTokenFound);

  //   onMessageListener()
  //     .then((payload) => {
  //       setShow(true);
  //       setNotification({
  //         title: payload.notification.title,
  //         body: payload.notification.body,
  //       });
  //       console.log(payload);
  //     })
  //     .catch((err) => console.log("failed: ", err));
  const setToggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [newCount]);
  const handleBellIcon = () => {
    handleRead();
  };
  return (
    <Dropdown
      className="Notification_Dropdown testDeop"
      show={walletAddress ? show : false}
      align="end"
      onClick={setToggle}
    >
      <Dropdown.Toggle variant="" id="dropdown-basic">
        <span onClick={handleBellIcon}>
          <NotificationIcon />
          {newCount > 0 ? (
            <span className="Show_Notification_No">{newCount}</span>
          ) : (
            ""
          )}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu show={show}>
        <ul>
          <li>
            <span className="Info_Icon">
              <img src={infoIcon} alt="Icon" />
            </span>
            <h5>
              {notification?.title} <img src={BlueArrow} alt="Icon" />{" "}
              <small>{notification?.body}</small>
            </h5>
          </li>
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default memo(ToastNotification);
