import { Container, Row, Col } from "react-bootstrap";
import "./P2p.scss";
import { io } from "socket.io-client";

import BannerCoin from "../../../assets/images/p2p_coin.png";
import CommonButton from "../../../common/CommonButton/CommonButton";
import { Header } from "../../../common";
import SetOrderCard from "./SetOrderCard/SetOrderCard";
import NewsLetter from "./NewsLetter/NewsLetter";
import StakingFooter from "../../../common/Footer/StakingFooter/StakingFooter";

const P2p = () => {
  return (
    <>
      <Header />
      <section className="section-top p2p_page">
        <Container className="commn-container">
          <Row className="align-items-center justify-content-center">
            <Col xs={12} lg={6} xl={5} className="text-center text-lg-start">
              <h1>
                Trade <em className="">EHX</em> P2P
              </h1>
              <p>Agree on the Price and trade, without an intermediary.</p>
              <a href="https://eterna.exchange/universal-swap" target="_blank">
                <CommonButton
                  title="Get started"
                  text="- It’s free!"
                  className="p2p_start_btn"
                />
              </a>
            </Col>
            <Col xs={12} lg={6} xl={5}>
              <div className="Banner_coin text-center text-lg-end">
                <img src={BannerCoin} alt="img" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="Set_Order_Sec">
        <Container>
          <SetOrderCard />
        </Container>
      </section>
      <NewsLetter />
      <StakingFooter />
    </>
  );
};

export default P2p;
