import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import "./OverviewAssetsTable.scss";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../../../Exchange/redux/_slices/exchange.slice";
import {
    RightarrowIcon,
    SearchIcon,
} from "../../../../../assets/Svg/SvgImages";

const OverviewAssetsTable = ({ coins, search }) => {
    const [searchCoin, setSearchCoin] = useState("");
     const dispatch = useDispatch();


    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    const onSearchChange = (e) => {
        setSearchCoin(e.target.value);
    };

    const onClickHandler = () => {
        search(searchCoin);
        dispatch(updateLoader(true));
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onClickHandler();
        }
    };

    return (
        <>
            <div className="CryptoAssets_Table Overview_MyAssets">
                <div className="d-sm-flex align-items-center justify-content-between">
                    <h2 className="heading mb-0">My Assets</h2>
                    <div className="Dash_SearchBar mt-4 mt-sm-0">
                        <div className="searchBar_input ms-0">
                            <input
                                type="text"
                                name=""
                                placeholder="Search Coin"
                                className="control_searchBar"
                                onChange={onSearchChange}
                                value={searchCoin}
                                onKeyDown={handleKeyDown}
                            />
                            <SearchIcon onClick={onClickHandler} tabIndex="0" />
                        </div>
                    </div>
                </div>

                <Table responsive size="sm">
                    <thead>
                        <tr>
                            <th>
                                <div className="d-flex align-items-center">
                                    Coin
                                </div>
                            </th>
                            <th className="text-end">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coins !== undefined && coins?.length > 0 ? (
                            coins?.map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            <div className="coin_data d-flex align-items-center">
                                                {/* <img
                                                    className="coin_Icon"
                                                    src={data.btcicon}
                                                    alt="eye"
                                                /> */}
                                                <div className="upDown_arrow">
                                                    <h6>{data.coin}</h6>
                                                    {/* <span>{data.coinname}</span> */}
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{ minWidth: 250 }}>
                                            <div className="btc_vlaue d-flex align-items-center justify-content-end">
                                                {roundToTwoDecimals(
                                                    data.equity
                                                )}
                                                <p className="mt-0 ms-2 me-3">
                                                    {`≈ $${roundToTwoDecimals(
                                                        data.usdValue
                                                    )}`}
                                                </p>
                                                <RightarrowIcon />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <p style={{ textAlign: "center" }}>
                                        No result found
                                    </p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default OverviewAssetsTable;
