import { MARKET_TRADES_SIZE } from "../../../../constants/constants";
import { orderAdd, orderSub } from "../../../../_utils/comman";
import {
  saveLtp,
  saveMarketTrades,
  saveListOfPair,
  saveSellOrderBook,
  saveBuyOrderBook,
  saveUserOpenOrders,
  savePairList,
} from "../_slices/exchange.slice";

/**
 * Save list or pairs, merge with api and socket data, and also update the data to change according to socket data
 * @param {*} type : String ==> api || socket
 * @param {*} data : Array of object
 * @returns
 */
export const saveListOfPairs = (type, data) => (dispatch, getState) => {
  const favPairList = getState().user.favPairList;
  const listOfPairs = getState().exchange.listOfPairs;
  let pairsData = [...listOfPairs];
  for (const i in data) {
    if (type === "api") {
      const oldItemIndex = listOfPairs.findIndex(
        (doc) => doc?.pair_name === data[i]?.pair_name
      );
      if (oldItemIndex === -1) {
        pairsData[i] = data[i];
      } else {
        pairsData[oldItemIndex] = {
          ...pairsData[oldItemIndex],
          ...data[i],
        };
      }

      // console.log("saveListOfPairs=socket", data[i]?.pair_name);
      // if (favPairList && favPairList.length == 0) {
      //   if (pairNameList.includes(data[i]?.pair_name)) {
      //     dispatch(saveFavPairsList({ pair_name: data[i]?.pair_name }));
      //   }
      // }
    } else {
      const oldItemIndex = listOfPairs.findIndex(
        (doc) => doc?.pair_name === data[i]?.symbol
      );
      if (oldItemIndex === -1) {
        pairsData[i] = data[i];
      } else {
        pairsData[oldItemIndex] = {
          ...pairsData[oldItemIndex],
          ...data[i],
        };
      }
    }
  }

  // hide PERP coins
  let pairsDataList =
    pairsData &&
    pairsData.length > 0 &&
    pairsData.filter(
      (element) => !element?.pair_name.toLowerCase().includes("perp")
    );
  // console.log("type", type);
  if (type === "api") {
    dispatch(savePairList(pairsDataList));
  }
  let obj = [...pairsDataList];
  obj.sort((a, b) => {
    if (Number(a.low) > Number(b.low)) {
      return -1;
    } else if (Number(a.low) < Number(b.low)) {
      return 1;
    }
    return 0;
  });

  let arryFilter = obj.filter((item, index) => !!item?.volume);
  dispatch(saveListOfPair(arryFilter));
};

/**
 * Update the Orderbook on order
 * @param {*} orderRes : Object
 * @returns
 */
// export const updateOrderBookOnOrder = (orderRes) => (dispatch, getState) => {
//   const order = {
//     price: orderRes.price,
//     quantity: orderRes.quantity,
//     total: orderRes.total,
//   };

//   if (orderRes.side === 0) {
//     let buyOrders = getState().exchange.buyOrders.slice();
//     if (orderRes.type === "add") {
//       buyOrders = orderAdd(order, buyOrders, "B");
//     } else {
//       buyOrders = orderSub(order, buyOrders);
//     }

//     dispatch(saveBuyOrderBook(buyOrders));
//   } else {
//     let sellOrders = getState().exchange.sellOrders.slice();
//     if (orderRes.type === "add") {
//       sellOrders = orderAdd(order, sellOrders, "S");
//     } else {
//       sellOrders = orderSub(order, sellOrders);
//     }
//     dispatch(saveSellOrderBook(sellOrders));
//   }
// };

// export const updateOrderBookOnOrder = (orderRes) => (dispatch, getState) => {

//   // console.log('Order _add -> ', orderRes)
//   let order = [];
//   orderRes.forEach(ele => {
//     order.push({
//       price: ele.price,
//       quantity: ele.quantity,
//       total: ele.total,
//     });
//   });
//   // console.log('Order _add -< ', order);
//   orderRes.forEach(ele => {
//     console.log("dskjdksj",ele)
//     if (ele.side === 0) {
//       let buyOrders = getState().exchange.buyOrders.slice();
//       if (ele.type === "add") {
//         buyOrders = orderAdd(ele, buyOrders, "B");
//       } else {
//         buyOrders = orderSub(ele, buyOrders);
//       }

//       dispatch(saveBuyOrderBook(buyOrders));
//     } else {
//       let sellOrders = getState().exchange.sellOrders.slice();

//       if (ele.type === "add") {
//         sellOrders = orderAdd(ele, sellOrders, "S");
//       } else {
//         sellOrders = orderSub(ele, sellOrders);
//       }
//       dispatch(saveSellOrderBook(sellOrders));
//     }
//   })
// };

export const updateOrderBookOnOrder = (orderRes) => (dispatch, getState) => {
  // console.log('Order _add -> ', orderRes)
  let order = [];
  orderRes.forEach((ele) => {
    order.push({
      price: ele.price,
      quantity: ele.quantity,
      total: ele.total,
    });
  });
  // console.log('Order _add -< ', order);
  orderRes.forEach((ele) => {
    console.log("dskjdksj", ele);
    if (ele.side === 0) {
      let buyOrders = getState().exchange.buyOrders.slice();
      if (ele.type === "add") {
        buyOrders = orderAdd(ele, buyOrders, "B");
      } else {
        buyOrders = orderSub(ele, buyOrders);
      }

      dispatch(saveBuyOrderBook(buyOrders));
    } else {
      let sellOrders = getState().exchange.sellOrders.slice();

      if (ele.type === "add") {
        sellOrders = orderAdd(ele, sellOrders, "S");
      } else {
        sellOrders = orderSub(ele, sellOrders);
      }
      dispatch(saveSellOrderBook(sellOrders));
    }
  });
};

/**
 * Update the orderbook on trade
 * @param {*} response : Object
 * @returns
 */
export const updateOrderbookOnTrade = (response) => (dispatch, getState) => {
  const { buyerPrice, sellerPrice, filled_amount, total } = response;
  const orderBuy = {
    price: buyerPrice,
    quantity: filled_amount,
    total,
  };
  const orderSell = {
    price: sellerPrice,
    quantity: filled_amount,
    total,
  };
  const buyOrders = getState().exchange.buyOrders.slice();
  const sellOrders = getState().exchange.sellOrders.slice();

  dispatch(saveBuyOrderBook(orderSub(orderBuy, buyOrders)));
  dispatch(saveSellOrderBook(orderSub(orderSell, sellOrders)));
};

/**
 * Update the market trades on trade
 * @param {*} response : Object
 * @returns
 */
export const updateMarketTradesOnTrade = (response) => (dispatch, getState) => {
  try {
    let markTradData = getState().exchange?.marketTrades?.slice();
    const trade = {
      price: response[0].price,
      filled_amount: response[0].filled_amount,
      created_at: response[0].created_at,
    };
    let currentLtp = trade.price;
    let lastLtp = trade.price;

    markTradData.unshift(trade);
    if (markTradData.length > MARKET_TRADES_SIZE) {
      // markTradData.pop();
      markTradData.length = MARKET_TRADES_SIZE;
    }
    // console.log("markTradData", response, currentLtp, lastLtp);

    dispatch(saveLtp(currentLtp, lastLtp));
    dispatch(saveMarketTrades(response));
  } catch (error) {
    // console.log("updateMarketTradesOnTrade Error :", { error });
  }
};

/**
 * Pair search acording to the input serch string
 * @param {*} data
 * @returns
 */
export const searchListOfPairs = (data) => (dispatch, getState) => {
  const listOfPairs = getState().exchange.listOfPairs;
  let pairsData = data;
  for (const i in data) {
    const oldItemIndex = listOfPairs.findIndex(
      (doc) => doc.pair_name === data[i].pair_name
    );
    pairsData[i] = listOfPairs[oldItemIndex];
  }
  return pairsData;
};

export const updateUserOpenOrders = (type, data) => (dispatch, getState) => {
  let oldData = getState().exchange.userOpenOrder.slice();
  if (type === "add") {
    oldData.unshift(data);
  } else if (type === "cancel") {
    const indexfind = oldData.findIndex((doc) => doc["id"] === data["id"]);
    if (indexfind !== -1) {
      oldData.splice(indexfind, 1);
    }
  } else {
    const indexfind = oldData.findIndex((doc) => doc["id"] === data["id"]);
    if (indexfind !== -1) {
      const updatedQuntity =
        oldData[indexfind]["filled_amount"] + data["filled_amount"];
      if (updatedQuntity >= oldData[indexfind]["quantity"]) {
        oldData.splice(indexfind, 1);
      } else {
        oldData[indexfind] = {
          ...oldData[indexfind],
          status: 4,
          filled_amount:
            oldData[indexfind].filled_amount + data["filled_amount"],
        };
      }
    }
  }
  dispatch(saveUserOpenOrders(oldData));
};
