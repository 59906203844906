import React from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonButton from '../../../../common/CommonButton/CommonButton';
import TierImg1 from "../../../../assets/images/admin/tier-aquamarine.svg"
import TierImg2 from "../../../../assets/images/admin/tier-emerald.svg"
import TierImg3 from "../../../../assets/images/admin/tier-ruby.svg"
import TierImg4 from "../../../../assets/images/admin/tier_saphire.svg"
import TierImg5 from "../../../../assets/images/admin/tier-diamond.svg"

const EhxStakingModal = ({ show, handleClose }) => {
    const orderdata = [
        {
            heading: "Aquamarine",
            name: "Tier 0",
            tiericon: TierImg1,
            stake: "Stake 0 EHX Tokens",
            receive: "Receive",
            number: "20%",
            receivetext: "of your referrals trading fees",

        },
        {
            heading: "Aquamarine",
            name: "Tier 0",
            tiericon: TierImg2,
            stake: "Stake 0 EHX Tokens",
            receive: "Receive",
            number: "20%",
            receivetext: "of your referrals trading fees",

        },
        {
            heading: "Aquamarine",
            name: "Tier 0",
            tiericon: TierImg3,
            stake: "Stake 0 EHX Tokens",
            receive: "Receive",
            number: "20%",
            receivetext: "of your referrals trading fees",

        },
        {
            heading: "Aquamarine",
            name: "Tier 0",
            tiericon: TierImg4,
            stake: "Stake 0 EHX Tokens",
            receive: "Receive",
            number: "20%",
            receivetext: "of your referrals trading fees",

        },
        {
            heading: "Aquamarine",
            name: "Tier 0",
            tiericon: TierImg5,
            stake: "Stake 0 EHX Tokens",
            receive: "Receive",
            number: "20%",
            receivetext: "of your referrals trading fees",

        },
    ];
    return (
        <>
            <Modal className='Admin_Modal EhxStake_Modal' centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>EHX Staking tiers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='EhxStake_Modal_Content'>
                        <div className='EhxStake_info mt-4 pt-2 pt-md-3'>
                            {orderdata.map((item, i) => {
                                return (
                                    <ul className='d-flex align-items-center' key={i}>
                                        <li>
                                            <div className='d-flex align-items-center'>
                                                <img src={item.tiericon} alt='icon' />
                                                <div className='EhxStake_text'>
                                                    <p>{item.heading}</p>
                                                    <span>{item.name}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li> <p>{item.stake}</p></li>
                                        <li><p className='d-flex align-items-center'><span>{item.receive}</span> <span className='text_blue px-2'>{item.number}</span> {item.receivetext}</p></li>

                                    </ul>
                                )
                            })}                            
                        </div>                        
                    </div>
                    <CommonButton className="w-50 mx-auto d-table" title="Okay" />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EhxStakingModal
