import React, { useEffect } from "react";
import { PublicRoutes } from "./routes";
import packageJson from "../package.json";
import store from "./redux/_store/configureStore";
import {
  saveUserWalletAddress,
  setErrorCounter,
} from "./redux/_slices/user.slice";
// import useWebSocket from "./modules/exchange/hooks/socket.bybit.service";
import { useSelector } from "react-redux";

const Application = () => {
  const selectedPairKey = useSelector((state) => state?.user?.selectedPairKey);
  // custom hook for sockets
  // useWebSocket(selectedPairKey)


  /**
   * Clear cache for updated build version
   *
   */
  const checkIfVersionNotSame = () => {
    let version = localStorage.getItem("version");
    console.log("localVersion", version);
    console.log("build version", packageJson.version);

    if (version != packageJson.version) {
      console.log("Cache cleared");

      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }

      localStorage.clear(); // clear local storage
      localStorage.setItem("version", packageJson.version); // update leatest version
    }
  };

  useEffect(() => {
    store.dispatch(setErrorCounter(false));
    checkIfVersionNotSame();
  }, []);


  return (
    <>
      <PublicRoutes />
    </>
  );
};

export default Application;
