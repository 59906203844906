import React, { useEffect } from "react";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import redlineIcon from "../../../../../assets/icons/redline_trade.svg";
import linechartIcon from "../../../../../assets/images/admin/linechat_arrow.svg";
import { ApiServices } from "../../../../Exchange/services/api.services";
import { useSelector, useDispatch } from "react-redux";
import { saveUserTransactionHistory } from "../../../../Exchange/redux/_slices/exchange.slice";
import moment from "moment";
const RecentTransactionTable = () => {
    const walletAddress = useSelector((state) => state?.user?.walletAddress);
    const recentTransactions = useSelector(
        (state) => state?.exchange?.userTransactionHistory
    );
    const dispatch = useDispatch();
    const limit = 10;

    useEffect(() => {
        if (walletAddress) {
            getTransactionHistory();
        } else {
            dispatch(saveUserTransactionHistory([]));
        }
    }, []);

    const getTransactionHistory = async () => {
        try {
            const dataObject = {
                address: walletAddress,
                limit,
            };
            const response = await ApiServices.transactionHistory(dataObject);
            dispatch(saveUserTransactionHistory(response?.data));
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="Recent_Transaction">
                <div className="Overview_Heading d-flex align-items-center justify-content-between">
                    <h6>Recent transactions</h6>
                    <CommonButton title="View all" className="grey-btn2" />
                </div>
                <ul className="Recent_Transaction_Table">
                    {recentTransactions.length ? (
                        recentTransactions.map((data, i) => {
                            let amount = data?.amount / 100000000;
                            return (
                                <li key={i}>
                                    <div className="Recent_Transaction_Table_Deposit">
                                        <span className={`LineChar_icon`}>
                                            <img
                                                src={
                                                    data.transaction_type ==
                                                    "DEPOSIT"
                                                        ? linechartIcon
                                                        : redlineIcon
                                                }
                                                alt="icon"
                                            />
                                        </span>
                                        <div>
                                            <h5>{data.transaction_type}</h5>
                                            <p>
                                                {moment(
                                                    data?.created_at
                                                ).format(
                                                    "DD-MM-YYYY  HH:mm:ss"
                                                )}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="Recent_Transaction_Table_UstToekn">
                                        <h5>
                                            {data.transaction_type == "DEPOSIT"
                                                ? "+"
                                                : "-"}
                                            {amount} {data.token_name}
                                        </h5>
                                        <p>
                                            <span className="GreenDot"></span>{" "}
                                            {data.status == 2
                                                ? "Completed"
                                                : "Pending"}
                                        </p>
                                    </div>
                                </li>
                            );
                        })
                    ) : (
                        <h5>No transaction found</h5>
                    )}
                </ul>
            </div>
        </>
    );
};

export default RecentTransactionTable;
