import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./SideBar.scss";
import DIcon1 from "../../../../assets/images/admin/grid-square.svg";
import DIcon2 from "../../../../assets/images/admin/credit-card.svg";
import DIcon3 from "../../../../assets/images/admin/rocket.svg";
import DIcon4 from "../../../../assets/images/admin/convert_icon.svg";
import DIcon5 from "../../../../assets/images/admin/copy-plus.svg";
import DIcon6 from "../../../../assets/images/admin/gift.svg";
import DIcon7 from "../../../../assets/images/admin/api_icon.svg";
import DIcon8 from "../../../../assets/images/admin/Transaction.svg";
import DIcon9 from "../../../../assets/images/admin/dollar-circle.svg";
import DIcon10 from "../../../../assets/images/admin/chat-question.svg";
import DIcon11 from "../../../../assets/images/admin/Botsicon.svg";
import DIcon12 from "../../../../assets/images/admin/user-circle.svg";
import { CrossIcon } from "../../../../assets/Svg/SvgImages";

const SideBar = ({ toggleClass }) => {
  return (
      <>
          <div className="Admin_Sidebar">
              <span className="Closebtn_sidebar" onClick={toggleClass}>
                  <CrossIcon />
              </span>
              <ul className="Admin_Sidebar_List">
                  <li>
                      <NavLink
                          className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                          }
                          to="/dashboard/overview"
                      >
                          <img src={DIcon1} alt="icon" /> <span>Overview</span>
                      </NavLink>
                  </li>
                  <li>
                      <NavLink
                          className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                          }
                          to="/dashboard/funding"
                      >
                          <img src={DIcon2} alt="icon" /> <span>Funding</span>
                      </NavLink>
                  </li>
                  <li>
                      <NavLink
                          className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                          }
                          to="/dashboard/unifiedtrading"
                      >
                          <img src={DIcon3} alt="icon" />{" "}
                          <span>Unified Trading</span>
                      </NavLink>
                  </li>
                  <li>
                      <NavLink
                          className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                          }
                          to="/dashboard/spot-account"
                      >
                          <img src={DIcon4} alt="icon" />{" "}
                          <span>Spot</span>
                      </NavLink>
                  </li>
                  {/* <li>
                        <NavLink
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active" : ""
                            }
                            to="/referral"
                        >
                            <img src={DIcon6} alt="icon" />{" "}
                            <span>Referrals</span>
                        </NavLink>
                    </li> */}
                  <li>
                      <NavLink
                          className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                          }
                          to="/dashboard/transaction-history"
                      >
                          <img src={DIcon8} alt="icon" />{" "}
                          <span>Transaction History</span>
                      </NavLink>
                  </li>
                  <li>
                      <NavLink
                          className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                          }
                          to="/dashboard/orders"
                      >
                          <img src={DIcon7} alt="icon" /> <span>Orders</span>
                      </NavLink>
                  </li>
                  {/* <li>
                      <NavLink
                          className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                          }
                          to="/dashboard/coin-details"
                      >
                          <img src={DIcon8} alt="icon" />{" "}
                          <span>Coin Details</span>
                      </NavLink>
                  </li> */}
              </ul>
          </div>
      </>
  );
};

export default SideBar;
