import React, { memo, useState } from "react";
import "./OrdersTable.scss";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ORDER_TYPES } from "../../../../../constants/order.constant";
import moment from "moment/moment";
import { useAppSelector } from "../../../../../hooks/redux.hooks";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import { toastAlert, toastMsg } from "../../../../../common/Toast/Toast";
import { ApiServices } from "../../../services/api.services";
import { NETWORK_INFO } from "../../../../../constants/wallet";
import EditIcon from "../../../../../assets/icons/pencil.svg";
import Tick from "../../../../../assets/icons/checkIcon.svg";
import { amountValidationOnInput } from "../../../utils/comman";
import { inputDecimal } from "../../../../../constants/constants";

const OrdersTable = () => {
  const [editInput, setEditInput] = useState("");
  const [inputError, setInputError] = useState({
    price: false,
    size: false,
  });
  const [price, setPrice] = useState("");
  const [size, setSize] = useState("");

  // {status: "Market",
  //         type: "Buy",
  //         amountbtc1: "0.0678",
  //         amountbtc2: "$126.87",
  //         amountbtc3: "22.48%",
  //         price: "Market",
  //         trigger: "38,766.00",
  //         goodtil: "Tue 14:54",
  //         til: "72h 09m",}
  // const Orderdata = [
  //   {
  //     id: "06d483ff-5914-4ab3-bf1f-ff0d290cf066",
  //     symbol: "DOGEUSDT",
  //     side: 0,
  //     type: 0,
  //     quantity: "149",
  //     price: "0.06200",
  //     total: 0,
  //     fee: 0,
  //     filled_amount: "0",
  //     executed_price: 0,
  //     time_in_force: "GoodTillCancel",
  //     trigger_price: "0.00000",
  //     take_profit: "0.00000",
  //     stop_loss: "0.00000",
  //     tp_trigger_by: "UNKNOWN",
  //     sl_trigger_by: "UNKNOWN",
  //     trigger_by: "UNKNOWN",
  //     reduce_only: false,
  //     close_on_trigger: false,
  //     trigger_direction: 0,
  //     leaves_qty: "149",
  //     last_exec_qty: 0,
  //     last_exec_price: 0,
  //     cum_exec_qty: "0",
  //     cum_exec_value: "0",
  //     avg_price: 0,
  //     block_trade_id: "",
  //     cancel_type: "UNKNOWN",
  //     cum_exec_fee: "0",
  //     leaves_value: "9.238",
  //     position_idx: 0,
  //     status: 1,
  //     order_status: "New",
  //     is_exchange_order: 0,
  //     created_at: "1688031834936",
  //   },
  // ];
  const [isCancel, setIsCancel] = useState(false);
  const chainId = useAppSelector((state) => state.user.chainId);
  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const selectedPairKey = useAppSelector((state) => state.user.selectedPairKey);
  const listOfPairs = useAppSelector((state) => state.exchange.pairList);
  const Orderdata = useAppSelector((state) => state?.exchange?.userOpenOrder);

  const getPairDetail = (pairName) => {
    const pairDetails = listOfPairs?.find((item) => item.pair == pairName);
    return pairDetails;
  };

  const handleCancelorder = async (data) => {
    try {
      if (walletAddress) {
        const symbol = NETWORK_INFO.find((doc) => doc.chain_id === chainId);
        const paramsData = {
          pairName: selectedPairKey,
          orderId: data.id,
          address: walletAddress,
          chainId: chainId,
          symbol: selectedPairKey,
        };
        setIsCancel(true);
        const res = await ApiServices.cancelOrder(paramsData);
        if (res && res.status === 200) {
          toastMsg.success("Order cancelled!");
        }
        setTimeout(() => {
          setIsCancel(false);
        }, 2500);
      }
    } catch (error) {
      toastMsg.error(error.data.message, { toastId: "errormsg" });
      setTimeout(() => {
        setIsCancel(false);
      }, 2500);
    }
  };

  const isCloseButtonShow = (status) => {
    if (
      status == "Created" ||
      status == "New" ||
      status == "PartiallyFilled" ||
      status == "PendingCancel" ||
      status == "Active" ||
      status == "Untriggered"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnChange = (event, order) => {
    const pairInfo = getPairDetail(order.symbol);
    let decimalLimitAmount = pairInfo?.min_order_qty?.toString().includes(".")
      ? pairInfo?.min_order_qty?.toString().split(".")
      : "";
    const { name, value } = event.target;
    let valData = "";
    if (decimalLimitAmount.length > 0 && name == "size") {
      valData = inputDecimal(value, decimalLimitAmount[1].length);
    } else if (value?.toString().includes(".") && name == "size") {
      return;
    } else if (value != "") {
      valData = inputDecimal(value, pairInfo?.price_scale);
    }

    if (name === "price") {
      valData = inputDecimal(value, pairInfo?.price_scale);
      setPrice(valData);
      setInputError({ ...inputError, price: false });
    } else {
      setInputError({ ...inputError, size: false });
      setSize(valData);
    }
  };

  const handleKeyPress = (e, order) => {
    //don't allow decimal in qty if min_order_qty is whole number
    const pairInfo = getPairDetail(order.symbol);
    // console.log("pairinfo", pairInfo);
    if (Number.isInteger(pairInfo.min_order_qty)) {
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  const handleEditOrder = async (type, order) => {
    try {
      const dataObj = {
        address: walletAddress,
        orderId: order.id,
        symbol: order.symbol.slice(0, order.symbol.length - 4) + "_USDT",
        [type]: type === "price" ? Number(price) : Number(size),
      };
      // console.log("dataobjjjjjjjjjjjjjjjjjjj", dataObj);

      const response = await ApiServices.editOpenOrder(dataObj);
      if (response && response.status === 200) {
        setEditInput("");
        toastMsg.success(`Order ${type} updated successfully`);
      }
    } catch (error) {
      toastMsg.error(error.data.message, { toastId: "errormsg" });
    }
  };

  return (
    <>
      <div className="Orders_Table">
        <PerfectScrollbar></PerfectScrollbar>
        <Table responsive>
          <thead>
            <tr>
              <th>Side</th>
              <th>Symbol</th>
              <th>Type</th>
              <th style={{ minWidth: "150px" }}>Amount / Filled</th>
              <th>Price</th>
              <th>Trigger</th>
              <th style={{ minWidth: "135px" }}>Date / Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Orderdata.map((data, i) => {
              return (
                // data?.status === 1 && (
                <tr key={i}>
                  <td>
                    <Form.Check
                      label={data.side === 0 ? "Buy" : "Sell"}
                      name=""
                      type="radio"
                      className="Radio_btn"
                    />
                  </td>
                  <td>{data?.symbol}</td>
                  <td className="text_green">{ORDER_TYPES[data.type]}</td>
                  {editInput === "size" ? (
                    <td style={{ minWidth: "150px" }} className="Amount_Data">
                      <Button onClick={() => setEditInput("")}>close</Button>{" "}
                      <input
                        type="text"
                        name="size"
                        value={size}
                        className="custom_input"
                        onChange={(event) => {
                          if (/^\d*(\.\d{0,8})?$/.test(event.target.value)) {
                            handleOnChange(event, data);
                          }
                        }}
                        onInput={(event) => amountValidationOnInput(event, 8)}
                        onKeyPress={(event) => handleKeyPress(event, data)}
                      />{" "}
                      <Button
                        onClick={() => handleEditOrder("qty", data)}
                        disabled={Number(size) === 0}
                      >
                        ok
                      </Button>
                    </td>
                  ) : (
                    <td>
                      {data.quantity} / {data.filled_amount}{" "}
                      <span
                        onClick={() => {
                          setEditInput("size");
                          setSize(data.quantity);
                        }}
                      >
                        <img src={EditIcon} />
                      </span>
                    </td>
                  )}
                  {editInput === "price" ? (
                    <td style={{ minWidth: "150px" }} className="Amount_Data">
                      <Button onClick={() => setEditInput("")}>close</Button>{" "}
                      <input
                        type="text"
                        name="price"
                        value={price}
                        className="custom_input"
                        onChange={(event) => {
                          if (/^\d*(\.\d{0,8})?$/.test(event.target.value)) {
                            handleOnChange(event, data);
                          }
                        }}
                        onInput={(event) => amountValidationOnInput(event, 8)}
                        onKeyPress={(event) => handleKeyPress(event, data)}
                      />{" "}
                      <Button
                        onClick={() => handleEditOrder("price", data)}
                        disabled={Number(price) === 0}
                      >
                        ok
                      </Button>
                    </td>
                  ) : (
                    <td>
                      {data.price}{" "}
                      <span
                        onClick={() => {
                          setEditInput("price");
                          setPrice(data.price);
                        }}
                      >
                        <img src={EditIcon} />
                      </span>
                    </td>
                  )}
                  <td>{data.price <= 0 ? data.trigger_price : "--"}</td>
                  <td style={{ minWidth: "135px" }} className="Good_Til">
                    {moment(Number(data?.created_at)).format(
                      "DD-MM-YYYY  HH:mm:ss"
                    )}{" "}
                    <span>{data.til}</span>
                  </td>
                  <td>
                    {isCloseButtonShow(data?.order_status) && (
                      <CommonButton
                        title="Cancel"
                        disabled={isCancel}
                        onClick={() => handleCancelorder(data)}
                      />
                    )}
                  </td>
                </tr>
              );
              // );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default memo(OrdersTable);
