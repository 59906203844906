import React, { useState, useRef, memo, useEffect } from "react";
import { Image, Row, Col, Tabs, Tab } from "react-bootstrap";
// import OrderbookTicker from "./OrderbookTicker";
import OrderBookComponent from "./OrderBook";
import { useTranslation } from "react-i18next";
import "./Orderbook.scss";
import { useSelector } from "react-redux";
import { DEFAULT_PAIR } from "../../../../constants/constants";
import OrderBookData from "./OrderBookData";

const SocketOrderBooks = ({ activeTab, handleOnSelect, totalDepth }) => {
  const [sellOrderBook, setSellOrderBook] = useState([]);
  const sellOrderBookRef = useRef(sellOrderBook);
  sellOrderBookRef.current = sellOrderBook;

  const [buyOrderBook, setBuyOrderBook] = useState([]);
  const buyOrderBookRef = useRef(buyOrderBook);
  buyOrderBookRef.current = buyOrderBook;

  const [sellDepth, setSellDepth] = useState([]);
  const sellDepthRef = useRef(sellOrderBook);
  sellDepthRef.current = sellDepth;

  const [buyDepth, setBuyDepth] = useState(false);
  const buyDepthRef = useRef(buyDepth);
  buyDepthRef.current = buyDepth;

  const { t } = useTranslation();

  const selectPair = useSelector((state) => state?.user?.selectedPairKey);
  const selectedPairKey = selectPair ? selectPair : DEFAULT_PAIR;

  /**
   * Compare depth
   * @param {*} buyDepth
   * @param {*} sellDepth
   * @returns
   */
  const compareTotalDepth = (buyDepth, sellDepth) => {
    if (buyDepth >= sellDepth) {
      return buyDepth;
    } else {
      return sellDepth;
    }
  };

  /**
   * 
    @param {} selectedprName 
   */

  return (
    <div className="orderbookHeader">
      <Tabs
        defaultActiveKey={activeTab}
        onSelect={handleOnSelect}
        className=""
        transition={false}
        unmountOnExit={true}
    mountOnEnter={true}
      >
        <Tab eventKey="all" title="All">
          <OrderBookData
            tab={activeTab}
            side="BS"
            buyOrderBook={buyOrderBookRef.current}
            sellOrderBook={sellOrderBookRef.current}
            totalDepth={totalDepth}
          />
        </Tab>
        <Tab className="Asks_tab" eventKey="asks" title="Asks">
          <OrderBookData orderBookSell={sellOrderBookRef.current} selectedPairVal={selectedPairKey}
            tab={activeTab}
            side="S"
            sellOrderBook={sellOrderBookRef.current}
            totalDepth={totalDepth} />
        </Tab>
        <Tab className="Bids_tab" eventKey="bids" title="Bids">
          <OrderBookData orderBookBuy={buyOrderBookRef.current} selectedPairVal={selectedPairKey}
            tab={activeTab}
            side="B"
            buyOrderBook={buyOrderBookRef.current}
            totalDepth={totalDepth} />
        </Tab>
      </Tabs>
    </div>
    // <Tab eventKey="orderbook" title={t("orderBook.orderBook_text")}>
    // <Row className="orderbookHeader m-0">
    //   {/* {console.log("prevSelectedPairKey", "btc", prevSelectedPairKey)} */}
    //   <Col md={12} className="h-100 px-0">
    //     <Tabs
    //       defaultActiveKey={activeTab}
    //       onSelect={handleOnSelect}
    //       className="mr-0 d-flex pd-0 changeMode"
    //     >
    //       <div className="market_card_style d-block">
    //         <Row className="exch_thead d-none d-lg-flex m-0">
    //           <Col className="exch_thead_th">
    //             {t("comman_word.price")}
    //             <span>({selectedPairKey?.split("_")[1]?.toUpperCase()})</span>
    //           </Col>
    //           <Col className="exch_thead_th text-right">
    //             {t("comman_word.size")}
    //             <span>({selectedPairKey?.split("_")[0]?.toUpperCase()})</span>
    //           </Col>
    //           <Col className="exch_thead_th text-right">
    //             {t("comman_word.total")}
    //             <span> ({selectedPairKey?.split("_")[0]?.toUpperCase()})</span>
    //           </Col>
    //         </Row>
    //       </div>
    //       <Tab
    //         eventKey="ALL"
    //         title={
    //           <Image
    //             src={require("../../../../../../assets/images/defaultmode.png")}
    //           />
    //         }
    //       >
    //         {activeTab === "ALL" && (
    //           <div className={activeTab === "ALL" ? "allMode" : ""}>
    //             <OrderBookComponent
    //               selectedPairVal={selectedPairKey}
    //               tab={activeTab}
    //               side="S"
    //               orderData={sellOrderBookRef.current}
    //               totalDepth={compareTotalDepth(
    //                 buyDepthRef.current,
    //                 sellDepthRef.current
    //               )}
    //             />
    //             <OrderbookTicker selectedPairVal={selectedPairKey} />
    //             <OrderBookComponent
    //               selectedPairVal={selectedPairKey}
    //               tab={activeTab}
    //               side="B"
    //               orderData={buyOrderBookRef.current}
    //               totalDepth={compareTotalDepth(
    //                 buyDepthRef.current,
    //                 sellDepthRef.current
    //               )}
    //             />
    //           </div>
    //         )}
    //       </Tab>
    //       <Tab
    //         className="buy_tab"
    //         eventKey="BUY"
    //         title={
    //           <Image
    //             src={require("../../../../assets/images/buyMode.png")}
    //           />
    //         }
    //       >
    //         {activeTab === "BUY" && (
    //           <div
    //             className={activeTab === "BUY" ? "buyMode" : ""}
    //             id="buy_tab_inr"
    //           >
    //             <OrderbookTicker selectedPairVal={selectedPairKey} />
    //             <OrderBookComponent
    //               selectedPairVal={selectedPairKey}
    //               tab={activeTab}
    //               side="B"
    //               orderData={buyOrderBookRef.current}
    //               totalDepth={buyDepthRef.current}
    //             />
    //             {/* buyDepth */}
    //           </div>
    //         )}
    //       </Tab>
    //       <Tab
    //         className="sell_tab"
    //         eventKey="SELL"
    //         title={
    //           <Image
    //             src={require("../../../../assets/images/sellMode.png")}
    //           />
    //         }
    //       >
    //         {activeTab === "SELL" && (
    //           <div
    //             className={activeTab === "SELL" ? "sellMode" : ""}
    //             id="sell_tab_inr"
    //           >
    //             <OrderBookComponent
    //               selectedPairVal={selectedPairKey}
    //               tab={activeTab}
    //               side="S"
    //               orderData={sellOrderBookRef.current}
    //               totalDepth={sellDepthRef.current}
    //             />
    //           </div>
    //         )}
    //       </Tab>
    //     </Tabs>
    //   </Col>
    // </Row>
  );
};

export default memo(SocketOrderBooks);
