import React from 'react'
import CommonButton from '../../../../../common/CommonButton/CommonButton'
import iconup from "../../../../../assets/images/admin/line-up-square.svg"
import icondown from "../../../../../assets/images/admin/line-down-square.svg"

const RecentTransactionTable = () => {

    const Recentdata = [
        {
            icon:iconup,
            amount:"Deposit",
            time:"2023-04-05 08:01:10", 
            usdt:"+500.00 USDT",
            status:"Completed",
        },
        {
            icon:icondown,
            amount:"Deposit",
            time:"2023-04-05 08:01:10",
            usdt:"+500.00 USDT",
            status:"Completed",
        },
        {
            icon:iconup,
            amount:"Deposit",
            time:"2023-04-05 08:01:10",
            usdt:"+500.00 USDT",
            status:"Completed",
        },
        {
            icon:icondown,
            amount:"Deposit",
            time:"2023-04-05 08:01:10",
            usdt:"+500.00 USDT",
            status:"Completed",
        },
        {
            icon:iconup,
            amount:"Deposit",
            time:"2023-04-05 08:01:10",
            usdt:"+500.00 USDT",
            status:"Completed",
        },
        {
            icon:iconup,
            amount:"Deposit",
            time:"2023-04-05 08:01:10",
            usdt:"+500.00 USDT",
            status:"Completed",
        },
        {
            icon:iconup,
            amount:"Deposit",
            time:"2023-04-05 08:01:10",
            usdt:"+500.00 USDT",
            status:"Completed",
        },
        {
            icon:icondown,
            amount:"Deposit",
            time:"2023-04-05 08:01:10",
            usdt:"+500.00 USDT",
            status:"Completed",
        },
        {
            icon:iconup,
            amount:"Deposit",
            time:"2023-04-05 08:01:10",
            usdt:"+500.00 USDT",
            status:"Completed",
        },
    ];

    return (
        <>
            <div className='Recent_Transaction'>
                <div className='Overview_Heading d-flex align-items-center justify-content-between'>
                    <h6>Recent transactions</h6>
                    <CommonButton title="View all" className="grey-btn2" />
                </div>
                <ul className='Recent_Transaction_Table'>
                    {Recentdata.map((data, i) => {
                        return (
                            <li key={i}>
                                <div className='Recent_Transaction_Table_Deposit'>
                                    <span className='LineChar_icon'><img src={data.icon} alt='icon'/></span>
                                    <div>
                                        <h5>{data.amount}</h5>
                                        <p>{data.time}</p>
                                    </div>
                                </div>

                                <div className='Recent_Transaction_Table_UstToekn'>
                                    <h5>{data.usdt}</h5>
                                    <p><span className='GreenDot'></span> {data.status}</p>
                                </div>
                            </li>
                        )
                    })}

                </ul>
            </div>
        </>
    )
}

export default RecentTransactionTable