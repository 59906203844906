import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalComponet from "../ModalComponet";
import { WALLET_INFO } from "../../constants/wallet";
import { saveWalletInfo } from "../../services/wallet.services";
import "./ConnectModel.scss";
import walletServices, {
  getAccountBalance,
} from "../../services/walletServices";
import { RightarrowIcon } from "../../assets/Svg/SvgImages";
import { setUserbalance } from "../../redux/_slices/xy.slice";
import { saveWallet } from "../../redux/_slices/user.slice";

const wallets = [
  "metamask",
  "walletconnect",
  "binance_wallet",
  "trust_wallet",
  "Coinbase_Wallet",
  "Token_Pocket",
  "Brave",
  "SafePal",
];
const ConnectModel = forwardRef((props, ref) => {
  const chainId = useSelector((state) => state.user.chainId);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const modalRef = useRef();
  // const { open, close } = useWeb3Modal();

  const handleConnectWallet = async (doc, chain_id) => {
    let response;
    if (doc.key == wallets[0]) {
      dispatch(saveWallet(wallets[0]));
      response = await walletServices.metaMaskConnect();
      dispatch(saveWalletInfo(wallets[0], response));
      const balance = await getAccountBalance(response);
      dispatch(setUserbalance(balance));
    } else if (doc.key == wallets[1]) {
      // const res = await open();
      // response = await walletServices.walletConnect();
      // dispatch(saveWalletInfo(wallets[1], response));
      // const balance = await getAccountBalance(response);
      // dispatch(setUserbalance(balance));
    } else if (doc.key == wallets[2]) {
      response = await walletServices.BinanceWalletconnect();
      dispatch(saveWalletInfo(wallets[2], response));
      const balance = await getAccountBalance(response);
      dispatch(setUserbalance(balance));
    } else if (doc.key == wallets[3]) {
      response = await walletServices.TrustWallet();
      dispatch(saveWalletInfo(wallets[3], response));
      const balance = await getAccountBalance(response);
      dispatch(setUserbalance(balance));
    } else if (doc.key == wallets[4]) {
      response = await walletServices.coinbaseconnect();
      dispatch(saveWalletInfo(wallets[4], response));
      const balance = await getAccountBalance(response);
      dispatch(setUserbalance(balance));
    } else if (doc.key == wallets[5]) {
      dispatch(saveWallet(wallets[5]));
      response = await walletServices.tokenPocket();
      dispatch(saveWalletInfo(wallets[5], response));
      const balance = await getAccountBalance(response);
      dispatch(setUserbalance(balance));
    } else if (doc.key == wallets[6]) {
      dispatch(saveWallet(wallets[6]));
      response = await walletServices.braveWallet();
      dispatch(saveWalletInfo(wallets[6], response));
      const balance = await getAccountBalance(response);
      dispatch(setUserbalance(balance));
    } else if (doc.key == wallets[7]) {
      dispatch(saveWallet(wallets[7]));
      response = await walletServices.safpalWallet();
      dispatch(saveWalletInfo(wallets[7], response));
      const balance = await getAccountBalance(response);
      dispatch(setUserbalance(balance));
    }
    if (response) {
      modalRef.current.handleModal(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleModal(value) {
      setShow(value);
      modalRef.current.handleModal(value);
    },
  }));
  const handleModalClose = (value) => {
    setShow(value);
  };

  return (
    <ModalComponet
      backdrop={true}
      ref={modalRef}
      show={show}
      hide={handleModalClose}
      title={"Connect Wallet"}
      className="connect_wallet"
    >
      <div className="token_list">
        <ul>
          {WALLET_INFO.map((doc, index) => (
            <li key={index}>
              <button
                type="button"
                onClick={() => {
                  handleConnectWallet(doc, chainId);
                }}
              >
                <img src={doc.icon} alt="icon" /> <span>{doc.name}</span>
              </button>
              {/* <span className="ClickArrow">
                <RightarrowIcon />
              </span> */}
              {/* <WagmiWalletConnectV2 /> */}
            </li>
          ))}
        </ul>
      </div>
    </ModalComponet>
  );
});

export default ConnectModel;
