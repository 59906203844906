import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import "./LiveSupport.scss"
import TopbarHeading from '../../../common/TopbarHeading/TopbarHeading'
import Admin from '../../../Admin'
import Breadcrumbs from '../../../common/Breadcrumbs/Breadcrumbs'
import nodataicon from "../../../../../assets/images/admin/no_data_icon.svg";
import CommonButton from '../../../../../common/CommonButton/CommonButton'
import { SearchIcon } from '../../../../../assets/Svg/SvgImages'

const LiveSupport = () => {

  return (
    <Admin>
      <div className='Common_TopBar'>
        <Row className='align-items-center'>
          <Col xs={12} xl={4}>
            <TopbarHeading title="Live Support" />
          </Col>
          <Col xs={12} xl={8} className="d-md-flex justify-content-xl-end">
            
          </Col>
        </Row>
      </div>
      <Breadcrumbs pagelink="/helpdesk" mainpage="Helpdesk" pagename="Live Support" />
      <div className='Common_admin_Contentarea LiveSupport'>
        <div className='No_Order_data text-center pt-xl-5 mt-4 mt-md-5'>
          <img src={nodataicon} alt='icon' />
          <p className='text-white h5 mt-4'>No Data</p>
        </div>
      </div >
    </Admin >
  )
}

export default LiveSupport