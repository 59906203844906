import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  memo,
} from "react";
import { useTranslation } from "react-i18next";

import "./Orderbook.scss";
import OrderBookData from "./OrderBookData";
import { WebsocketClient } from "bybit-api";
import { useDispatch } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { useAppSelector } from "../../../../hooks/redux.hooks";

import {
  saveStatistics,
  saveDefaultStatistics,
  saveLtp,
  saveMarketTrades,
} from "../../redux/_slices/spotExchange.slice";
import { updateMarketTradesOnTrade } from "../../redux/_actions/exchange.action";
// import { updateOrderBook } from "../../../../../../redux/_slices/exchange.slice";
import { useSelector } from "react-redux";
import { DEFAULT_PAIR } from "../../../../constants/constants";
import { savePrevSelectedPairKey } from "../../../../redux/_slices/user.slice";
import { throttle } from "lodash";

let setPairName = "";
const WEBSOCKET_URL = {
  CONTRACT: {
    PUBLIC: "wss://stream.bybit.com/contract/usdt/public/v3",
    PRIVATE: "wss://stream.bybit.com/contract/private/v3",
  },
};

const BYBIT_API_TOPIC_NAMES = {
  LINEAR: {
    ORDER_BOOK_200: "orderBook_200.100ms.",
    ORDER_BOOK_25: "orderBookL2_25.",
    KLINE: "candle.1.",
    INSTRUMENT_INFO: "instrument_info.100ms.",
    TRADE: "trade.",
    POSITION: "position",
    EXECUTION: "execution",
    ORDER: "order",
    STOP_ORDER: "stop_order",
    WALLET: "wallet",
  },
  DERIVATIVES: {
    ORDER_BOOK_1: "orderbook.1.",
    ORDER_BOOK_25: "orderbook.25.",
    ORDER_BOOK_50: "orderbook.50.",
    TRADE: "publicTrade.",
    TICKERS: "tickers.",
    KLINE: "kline.1.", //kline.{interval}.{symbol}

    // KLINE for derivatives API and websockets
    KLINE_1: "kline.1.",
    KLINE_3: "kline.3.",
    KLINE_5: "kline.5.",
    KLINE_15: "kline.15.",
    KLINE_30: "kline.30.",
    KLINE_60: "kline.60.",
    KLINE_120: "kline.120.",
    KLINE_240: "kline.240.",
    KLINE_360: "kline.360.",
    KLINE_720: "kline.720.",
    KLINE_D: "kline.D.",
    KLINE_W: "kline.W.",
    KLINE_M: "kline.M.",
  },
  MARKET: {
    CONTRACT_USDT: "contractUSDT",
  },
};

// let wsconfig = {
//   // key: "AU91jr4hzIze9J9pfF",
//   key: "6yYjVA2N41KBrrks80",
//   //   secret: "",
//   market: BYBIT_API_TOPIC_NAMES.MARKET.CONTRACT_USDT,
//   wsUrl: WEBSOCKET_URL.CONTRACT.PUBLIC,
// };
// let ws = new WebsocketClient(wsconfig);

const SocketOrderBooks = ({
  activeTab,
  handleOnSelect,
  orderBookRecord,
  parentTab,
}) => {
  const [defaultStats, setDefaultStats] = useState(false);
  const [sellOrderBook, setSellOrderBook] = useState([]);
  const sellOrderBookRef = useRef(sellOrderBook);
  sellOrderBookRef.current = sellOrderBook;
  const [buyOrderBook, setBuyOrderBook] = useState([]);
  const buyOrderBookRef = useRef(buyOrderBook);
  buyOrderBookRef.current = buyOrderBook;

  const [sellDepth, setSellDepth] = useState([]);
  const sellDepthRef = useRef(sellOrderBook);
  sellDepthRef.current = sellDepth;

  const [buyDepth, setBuyDepth] = useState(false);
  const buyDepthRef = useRef(buyDepth);
  buyDepthRef.current = buyDepth;

  const [ltpPrice, setLtpPrice] = useState(0);
  const ltpPriceRef = useRef(buyDepth);
  ltpPriceRef.current = ltpPrice;

  const [sellOrderBookList, setSellOrderBookList] = useState([]);
  const sellOrderBookListRef = useRef(sellOrderBookList);
  sellOrderBookListRef.current = sellOrderBookList;

  const [buyOrderBookList, setBuyOrderBookList] = useState([]);
  const buyOrderBookListRef = useRef(buyOrderBookList);
  buyOrderBookListRef.current = buyOrderBookList;
  const { t } = useTranslation();
  const selectPair = useSelector((state) => state?.user?.selectedPairKey);
  const selectedPairKey = selectPair ? selectPair : DEFAULT_PAIR;
  const prevSelectedPairKey = useAppSelector(
    (state) => state.user.prevSelectedPairKey
  );
  const statistics = useAppSelector((state) => state.spotExchange.statistics);

  /**
   * Compare depth
   * @param {*} buyDepth
   * @param {*} sellDepth
   * @returns
   */

  let myTimeout;
  let pName = "";
  const dispatch = useDispatch();
  //set default stats
  // useEffect(() => {
  //   if (statistics && Object.keys(statistics).length > 9 && (defaultStats === true)) {
  //     dispatch(saveDefaultStatistics(statistics));
  //     setDefaultStats(false);
  //   }
  // }, [defaultStats, statistics]);

  // useLayoutEffect(() => {
  //   dispatch(saveStatistics({}));
  //   dispatch(saveLtp(0, 0));
  //   dispatch(saveMarketTrades([]));
  //   initalizePair();
  //   return () => {
  //     initalizePair();
  //   };
  // }, [selectedPairKey]);

  // const initalizePair = () => {
  //   setBuyOrderBookList([]);
  //   setSellOrderBookList([]);
  //   ws.removeAllListeners();
  //   dispatch(saveLtp(0, 0));
  //   dispatch(saveMarketTrades([]));
  //   unsubscribeByBit(selectedPairKey);
  //   ws.unsubscribe("update");
  //   setSellOrderBook([]);
  //   setBuyOrderBook([]);
  //   window.clearTimeout(myTimeout);
  // };

  // useLayoutEffect(() => {
  //   if (
  //     sellOrderBookRef?.current?.length == 0 &&
  //     buyOrderBookRef?.current?.length == 0
  //   ) {
  //     window.clearTimeout(myTimeout);
  //     callWebSocket();
  //   } else if (
  //     sellOrderBookRef?.current?.length < 19 ||
  //     buyOrderBookRef?.current?.length < 19
  //   ) {
  //     unsubscribeByBit(selectedPairKey, "initial 1");
  //     ws.unsubscribe("update");
  //     callWebSocket();
  //   }
  // }, [sellOrderBookRef?.current, buyOrderBookRef?.current, selectedPairKey]);

  // let callWebSocket = async () => {
  //   await initeveryTimeWebSocket(selectedPairKey);
  // };

  const formattedPair = (pairName) => {
    let pair = pairName.split("_");
    if (pair && pair.length > 0) {
      if (pair.length > 1) {
        return (pName = `${pair[0]}${pair[1]}`);
      } else {
        return (pName = pair[0]);
      }
    }
  };
  /**
   * 
    @param {} selectedprName 
   */

  // const unsubscribeByBit = async (selectedPair, initialParam) => {
  //   await ws.removeAllListeners();
  //   let setPairName = formattedPair(selectedPair);
  //   let prevPair = formattedPair(prevSelectedPairKey);
  //   if (prevSelectedPairKey != selectedPairKey) {
  //     let subcribedtopicNameArray = [
  //       "orderbook.1." + setPairName,
  //       "orderbook.50." + setPairName,
  //       BYBIT_API_TOPIC_NAMES.DERIVATIVES.TRADE + setPairName,
  //       BYBIT_API_TOPIC_NAMES.DERIVATIVES.TICKERS + setPairName,
  //     ];
  //     let unSubcribedtopicNameArray = [
  //       "orderbook.1." + prevPair,
  //       "orderbook.50." + prevPair,
  //       BYBIT_API_TOPIC_NAMES.DERIVATIVES.TRADE + prevPair,
  //       BYBIT_API_TOPIC_NAMES.DERIVATIVES.TICKERS + prevPair,
  //     ];
  //     await ws.unsubscribe(unSubcribedtopicNameArray);
  //     await ws.unsubscribe(subcribedtopicNameArray);

  //     await ws.subscribe(subcribedtopicNameArray);
  //     dispatch(savePrevSelectedPairKey(selectedPairKey));
  //   } else {
  //     let unSubcribedtopicNameArray = [
  //       "orderbook.1." + prevPair,
  //       "orderbook.50." + prevPair,
  //       BYBIT_API_TOPIC_NAMES.DERIVATIVES.TRADE + prevPair,
  //       BYBIT_API_TOPIC_NAMES.DERIVATIVES.TICKERS + prevPair,
  //     ];
  //     let subcribedtopicNameArray = [
  //       "orderbook.1." + setPairName,
  //       "orderbook.50." + setPairName,
  //       BYBIT_API_TOPIC_NAMES.DERIVATIVES.TRADE + setPairName,
  //       BYBIT_API_TOPIC_NAMES.DERIVATIVES.TICKERS + setPairName,
  //     ];
  //     await ws.unsubscribe(unSubcribedtopicNameArray);
  //     await ws.unsubscribe(subcribedtopicNameArray);

  //     await ws.subscribe(subcribedtopicNameArray);
  //   }

  //   // Listen to events coming from websockets. This is the primary data source
  // };

  // const initeveryTimeWebSocket = async (selectedPair) => {
  //   let currentPair = formattedPair(selectedPair);
  //   setPairName = currentPair;
  //   window.clearTimeout(myTimeout);
  //   let timer = 1;
  //   ws.on("update", async (data) => {
  //     switch (data?.topic) {
  //       case `${BYBIT_API_TOPIC_NAMES.DERIVATIVES.ORDER_BOOK_50}${setPairName}`:
  //         const getDtOne = data?.data;
  //         if (data && data.type === "snapshot") {
  //           getOrderBookDataFormatted(getDtOne);
  //         }
  //         break;
  //       case "orderbook.1." + setPairName:
  //         const getDtOneSingle = data?.data;
  //         if (
  //           getDtOneSingle &&
  //           data.type === "delta" &&
  //           selectedPairKey == selectedPair &&
  //           sellOrderBookRef?.current?.length > 0 &&
  //           buyOrderBookRef?.current?.length > 0
  //         ) {
  //           // if (ltpPriceRef.current) {
  //           //   let removeRecord = await buyOrderBookRef.current.filter(function (
  //           //     item
  //           //   ) {
  //           //     return item.price <= ltpPriceRef.current;
  //           //   });
  //           //   if (removeRecord.length > 18) {
  //           //     setBuyOrderBook(removeRecord.sort());
  //           //   } else {
  //           //     setBuyOrderBook([]);
  //           //   }
  //           //   let removeRecordSell = await sellOrderBookRef.current.filter(
  //           //     function (item) {
  //           //       return item.price > ltpPriceRef.current;
  //           //     }
  //           //   );
  //           //   if (removeRecordSell.length > 18) {
  //           //     setSellOrderBook(removeRecordSell.sort());
  //           //   } else {
  //           //     setSellOrderBook([]);
  //           //   }
  //           // }

  //           if (getDtOneSingle.b && getDtOneSingle.b.length > 0) {
  //             handleBuyThrottled(getDtOneSingle);
  //             // myTimeout = setTimeout(() => {
  //             //   getOrderBookDataFormattedSingle(
  //             //     getDtOneSingle,
  //             //     "buy",
  //             //     ltpPriceRef.current
  //             //   );
  //             //   if (timer > 5) {
  //             //     window.clearTimeout(myTimeout);
  //             //     timer = 1;
  //             //   }
  //             // }, 1000 * timer);
  //             // timer++;
  //           } else if (getDtOneSingle.a && getDtOneSingle.a.length > 0) {
  //             handleClickThrottled(getDtOneSingle);
  //             // myTimeout = setTimeout(() => {
  //             //   getOrderBookDataFormattedSingle(
  //             //     getDtOneSingle,
  //             //     "sell",
  //             //     ltpPriceRef.current
  //             //   );
  //             //   if (timer > 5) {
  //             //     window.clearTimeout(ssellCountsellCountsellCountsellCountsellCountellCount);
  //             //     timer = 1;
  //             //   }
  //             // }, 1000 * timer);
  //             // timer++;
  //           }
  //         }

  //         break;
  //       case BYBIT_API_TOPIC_NAMES.DERIVATIVES.TRADE + setPairName:
  //         let getDta = data?.data;
  //         handleTradeThrottled(getDta);

  //         break;
  //       case BYBIT_API_TOPIC_NAMES.DERIVATIVES.TICKERS + setPairName:
  //         const getData = data?.data;
  //         handleTickerThrottled(getData, data.type);

  //         break;
  //       default:
  //         break;
  //     }
  //   });

  //   // Optional: Listen to websocket connection open event (automatic after subscribing to one or more topics)
  //   ws.on("open", ({ wsKey, event }) => {
  //     console.log(":::: Connection open for websocket with ID ::::" + wsKey);
  //   });

  //   // Optional: Listen to responses to websocket queries (e.g. the response after subscribing to a topic)
  //   ws.on("response", (response) => {
  //     //   console.log(":::: Response ::::", response);
  //   });

  //   // Optional: Listen to connection close event. Unexpected connection closes are automatically reconnected.
  //   ws.on("close", () => {
  //     console.log(":::: Connection closed ::::::");
  //   });

  //   // Optional: Listen to raw error events. Recommended.
  //   ws.on("error", (err) => {
  //     console.error(":::: error ::::", err);
  //   });
  // };
  let delayTimer = 130;
  const handleBuyThrottled = throttle((getDtOneSingle) => {
    if (ltpPriceRef.current) {
      let removeRecord = buyOrderBookRef.current.filter(function (item) {
        return item?.price <= ltpPriceRef.current;
      });
      if (removeRecord.length > 18) {
        let buySortRecord = removeRecord.sort();
        buySortRecord.length = 19;
        setBuyOrderBook(buySortRecord);
        setBuyOrderBookList(buySortRecord);
      } else {
        // let buyCount = [...buyOrderBookRef.current];
        // buyCount.length = 19;
        setBuyOrderBook([]);
      }
    }
    getOrderBookDataFormattedSingle(getDtOneSingle, "buy", ltpPriceRef.current);
  }, delayTimer);

  const handleClickThrottled = throttle((getDtOneSingle) => {
    let removeRecordSell = sellOrderBookRef.current.filter(function (item) {
      return item.price > ltpPriceRef.current;
    });
    if (removeRecordSell.length > 18) {
      let sellSortRecord = removeRecordSell.sort();
      sellSortRecord.length = 19;
      setSellOrderBook(sellSortRecord);
      setSellOrderBookList(sellSortRecord);
    } else {
      // let sellCount = [...sellOrderBookRef.current];
      // sellCount.length = 19;
      setSellOrderBook([]);
    }
    getOrderBookDataFormattedSingle(
      getDtOneSingle,
      "sell",
      ltpPriceRef.current
    );
  }, delayTimer);

  // export default ws;
  const getTradeDataFormatted = async (dataObj) => {
    try {
      if (dataObj && dataObj.length > 0) {
        const newArr = dataObj.map(({ p, v, S: side, T: created_at }) => ({
          price: p,
          total: p * v,
          filled_amount: v,
          side: side === "Buy" ? 0 : 1,
          created_at,
        }));
        dispatch(updateMarketTradesOnTrade(newArr));
      }
    } catch (error) {
      // Handle the error here
    }
  };
  const updateOrderBookDelta = async (orderBook, orderData) => {
    if (
      orderBook &&
      orderBook?.side === 0 &&
      buyOrderBookRef.current.length > 0
    ) {
      let updatebuyOrders = [...orderData];
      const orderIndex = updatebuyOrders.findIndex(
        (item) => orderBook?.price === item?.price
      );
      if (orderIndex === -1) {
        updatebuyOrders.push(orderBook);
        updatebuyOrders
          .sort((a, b) => b.price - a.price)
          .slice(0, orderBookRecord);
      } else {
        updatebuyOrders.splice(orderIndex, 1, orderBook);
        if (updatebuyOrders.length > orderBookRecord) {
          updatebuyOrders.length = orderBookRecord;
        }
      }
      setBuyOrderBook(updatebuyOrders);
    } else if (orderBook && orderBook?.side === 1 && orderData.length > 0) {
      //sell orderbook code start
      let updatesellOrders = [...orderData];
      const orderIndex = updatesellOrders.findIndex(
        (item) => orderBook?.price === item?.price
      );
      if (orderIndex === -1) {
        updatesellOrders.push(orderBook);
        updatesellOrders
          .sort((a, b) => b.price - a.price)
          .slice(0, orderBookRecord);
      } else {
        updatesellOrders.splice(orderIndex, 1, orderBook);
      }
      setSellOrderBook(updatesellOrders);
    }
  };

  const getOrderBookDataFormattedSingle = async (
    data,
    orderType,
    ltpPriceRef
  ) => {
    let buyArr = [];
    if (orderType == "buy") {
      for (let index = 0; index < data.b.length; index++) {
        if (data.b[index] && data.b[index].length > 0) {
          const price = data.b[index][0] ? data.b[index][0] : 0;
          const quantity = data.b[index][1] ? data.b[index][1] : 0;
          let obj = {
            price: price,
            quantity: Number(quantity),
            depth: Number(quantity),
            side: 0,
            type: "add",
          };
          buyArr.push(obj);
          // }
        }
      }

      let buyArrData = buyArr.filter((item) => item?.quantity != 0);
      let buyArrQty = buyArr.find((item) => item?.quantity == 0);
      let updatebuyOrders = [...buyOrderBookRef.current];

      if (buyArrQty?.quantity == 0) {
        const index = updatebuyOrders.findIndex(
          (item) => buyArrQty?.price === item?.price
        );
        if (index >= 0) {
          updatebuyOrders.splice(index, 1);
        }
      }
      let valueBuy =
        updatebuyOrders &&
        updatebuyOrders.find(
          (item) =>
            item?.price == buyArrData[0]?.price &&
            item?.quantity == buyArrData[0]?.quantity
        );

      if (!valueBuy && buyArrData[0]?.price < ltpPriceRef) {
        await updateOrderBookDelta(buyArrData[0], updatebuyOrders);
      }
    }

    if (orderType == "sell") {
      let sellArr = [];
      for (let index = 0; index < data.a.length; index++) {
        if (data.a[index] && data.a[index].length > 0) {
          const price = data.a[index][0] ? data.a[index][0] : 0;
          const quantity = data.a[index][1] ? data.a[index][1] : 0;
          let obj = {
            price: price,
            quantity: Number(quantity),
            depth: Number(quantity),
            side: 1,
            type: "add",
          };
          sellArr.push(obj);
        }
      }

      let sellArrData = sellArr.filter((item) => item?.quantity != 0);
      let sellArrQty = sellArr.find((item) => item?.quantity == 0);
      let updateSellOrders = [...sellOrderBookRef.current];
      if (sellArrQty?.quantity == 0) {
        const index = updateSellOrders.findIndex(
          (item) => sellArrQty?.price === item?.price
        );
        if (index >= 0) {
          updateSellOrders.splice(index, 1);
        }
      }
      let valueSell =
        updateSellOrders &&
        updateSellOrders.find(
          (item) =>
            item?.price == sellArrData[0]?.price &&
            item?.quantity == sellArrData[0]?.quantity
        );

      if (!valueSell && sellArrData[0]?.price > ltpPriceRef) {
        await updateOrderBookDelta(sellArrData[0], updateSellOrders);
      }
    }
  };

  const getOrderBookDataFormatted = async (data) => {
    try {
      let buyArr = [];
      let sellArr = [];
      // b == buy
      if (data.b && data.b.length > 0) {
        for (let index = 0; index < data.b.length; index++) {
          if (data.b[index] && data.b[index].length > 0) {
            const price = data.b[index][0] ? data.b[index][0] : 0;
            const quantity = data.b[index][1] ? data.b[index][1] : 0;
            let obj = {
              price: price,
              quantity: Number(quantity),
              depth: Number(quantity),
              side: 0,
            };
            buyArr.push(obj);
          }
        }
      }
      // a == sell
      if (data.a && data.a.length > 0) {
        // console.log("::::::::::::: sell length 1111111111111111 :::::::::::::", data.a.length);
        for (let index = 0; index < data.a.length; index++) {
          // console.log("::::::::::::: data.a[index] length " + index + ":::::::::::::", data.a[index].length);
          if (data.a[index] && data.a[index].length > 0) {
            const price = data.a[index][0] ? data.a[index][0] : 0;
            const quantity = data.a[index][1] ? data.a[index][1] : 0;
            let obj = {
              price: price,
              quantity: Number(quantity),
              depth: Number(quantity),
              side: 1,
            };
            sellArr.push(obj);
          }
        }
      }

      if (buyArr && buyArr.length > 0) {
        buyArr.sort((a, b) => b.price - a.price).slice(0, orderBookRecord);
        const findDepth = buyArr.reduce((a, c) => a + Number(c?.quantity), 0);
        setBuyDepth(findDepth);
        setBuyOrderBookList(buyArr);
        setBuyOrderBook(buyArr);
      }

      if (sellArr && sellArr.length > 0) {
        sellArr.sort((a, b) => b.price - a.price).slice(0, orderBookRecord);
        const findDepth = sellArr.reduce((a, c) => a + Number(c?.quantity), 0);
        setSellOrderBookList(sellArr);
        setSellDepth(findDepth);
        setSellOrderBook(sellArr);
      }
    } catch (error) {
      console.log(
        "=================== websocket socket catch error ============",
        error
      );
    }
  };

  let delayTime = 100;
  const handleTradeThrottled = throttle((getDta) => {
    getTradeDataFormatted(getDta);
  }, 150);

  const handleTickerThrottled = throttle((extdata, type) => {
    getTickerDataFormatted(extdata, type);
  }, delayTime);

  let retdata = {};
  const getTickerDataFormatted = async (extdata, type = "") => {
    try {
      if (extdata) {
        retdata.symbol = extdata.symbol;
        if (extdata.lastPrice) {
          retdata.lastPrice = extdata.lastPrice;
          setLtpPrice(retdata.lastPrice);
        }
        if (extdata.tickDirection) {
          retdata.last_tick_direction = extdata.tickDirection;
        }

        if (extdata.markPrice) {
          retdata.mark_price = extdata.markPrice;
        }
        if (extdata.indexPrice) {
          retdata.index_price = extdata.indexPrice;
        }

        if (extdata.highPrice24h) {
          retdata.high_24h = extdata.highPrice24h;
        }

        if (extdata.lowPrice24h) {
          retdata.low_24h = extdata.lowPrice24h;
        }

        if (extdata.volume24h) {
          retdata.volume_24h = extdata.volume24h;
        }

        if (extdata.nextFundingTime) {
          retdata.funding_countdown = extdata.nextFundingTime;
        }

        if (extdata.fundingRate) {
          retdata.funding_rate = extdata.fundingRate;
          retdata.predicted_funding_rate = extdata.fundingRate;
        }

        if (extdata.price24hPcnt) {
          retdata.change_24_h = extdata.price24hPcnt;
        }

        if (extdata.turnover24h) {
          retdata.turnover = extdata.turnover24h;
        }
        if (retdata && Object.keys.length > 0) {
          dispatch(saveStatistics(retdata));
          setDefaultStats(true);
        }
      }
    } catch (error) {
      console.log(
        "=================== websocket socket catch error instrument data 2222222222222222222222222 ============",
        error
      );
    }
  };

  return (
    <div className="orderbookHeader">
      <Tabs
        defaultActiveKey={activeTab}
        onSelect={handleOnSelect}
        className=""
        transition={false}
        unmountOnExit={true}
        mountOnEnter={true}
      >
        <Tab eventKey="all" title="All">
          <OrderBookData
            orderBookSell={sellOrderBookRef.current}
            orderBookBuy={buyOrderBookRef.current}
            side="BS"
          />
        </Tab>
        <Tab className="Asks_tab" eventKey="asks" title="Asks">
          <OrderBookData orderBookSell={sellOrderBookRef.current} side="S" />
        </Tab>
        <Tab className="Bids_tab" eventKey="bids" title="Bids">
          <OrderBookData orderBookBuy={buyOrderBookRef.current} side="B" />
        </Tab>
      </Tabs>
    </div>
  );
};

export default memo(SocketOrderBooks);
