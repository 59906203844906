import React, { useState } from 'react'
import { MenuToggleIcon } from '../../assets/Svg/SvgImages';
import Footer from '../../common/Footer/Footer'
import { Header } from '../../common/Header/Header'
import "./admin.scss";
import SideBar from './common/SideBar/SideBar';

const Admin = (props) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
      <Header />
      <div className='AdminMain_Layout'>
        <div className={isActive ? 'Admin_Main_SideBar Open_Sidebar' : "Admin_Main_SideBar"}>
          <div className='MenuToggle' onClick={toggleClass}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <SideBar toggleClass={toggleClass} />
        </div>
        <div className='Admin_Content_layout'>
          {props.children}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Admin