import React, { useState, useRef, useEffect } from "react";
import { Dropdown, Nav, Tab, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import MenuIcon1 from "../../assets/icons/menuicon1.svg";
import MenuIcon2 from "../../assets/icons/menuicon2.svg";
import MenuIcon3 from "../../assets/icons/menuicon3.svg";
import MenuIcon4 from "../../assets/icons/menuicon4.svg";
import MenuIcon5 from "../../assets/icons/menuicon5.svg";
import MenuIcon6 from "../../assets/icons/menuicon6.svg";
import MenuIcon7 from "../../assets/icons/referalIcon.svg";
import MenuIcon8 from "../../assets/icons/menuicon8.svg";
import MenuIcon9 from "../../assets/icons/menuicon9.svg";
import MenuIcon10 from "../../assets/icons/menuicon10.svg";
import MenuIcon11 from "../../assets/icons/menuicon11.svg";
import MenuIcon12 from "../../assets/icons/menuicon12.svg";
import ChainIcon from "../../assets/icons/chainicon.svg";
import { getWalletAddressFormate } from "../../_utils/comman";
import CommonButton from "../CommonButton/CommonButton";
import tokenLogo from "../../assets/images/ethereum_icon2.svg";
// import { useAppSelector } from '../../hooks/redux.hooks';
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/redux.hooks";
// import { useDispatch, useSelector } from "react-redux";
import { saveUserWalletAddress } from "../../redux/_slices/user.slice";
import { EXHV1_URL } from "../../constants/constants";
// import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import { toastMsg } from "../Toast/Toast";

const NavbarMenu = () => {
  const rightNavRef = useRef(null);
  const dispatch = useDispatch();
  const walletAddress = useAppSelector((state) => state.user.walletAddress);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // const { open } = useWeb3Modal();
  // const { selectedNetworkId } = useWeb3ModalState();
  const [show, setShow] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleDropdown = (eventType, menu) => {
    switch (eventType) {
      case "MouseEnter":
        if (document.body.clientWidth > 1199) {
          setShow(menu);
        }
        break;
      case "MouseLeave":
        if (document.body.clientWidth > 1199) {
          setShow("");
        }
        break;
      case "MouseClick":
        if (document.body.clientWidth < 1199) {
          setShow(menu);
        }
        break;
      default:
        setShow("");
        return;
    }
  };

  const comingSoonHandler = () => {
    setShowModal(true);
  };

  const handlerClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="NabBar_Menu">
        {!walletAddress ? (
          <CommonButton
            className="d-block d-xl-none mobilenav"
            title="Connect"
            onClick={() => {
              // open();
            }}
          />
        ) : (
          <CommonButton
            className="d-block d-xl-none mobilenav"
            title={getWalletAddressFormate(walletAddress)}
          />
        )}
        <ul className="navbar-nav">
          <li>
            <Dropdown
              id="basic-nav-dropdown"
              className="inner_submenu"
              show={show === "Exchange" ? true : false}
              onMouseEnter={() => handleDropdown("MouseEnter", "Exchange")}
              onClick={() => handleDropdown("MouseClick", "Exchange")}
              onMouseLeave={() => handleDropdown("MouseLeave", "Exchange")}
            >
              <Dropdown.Toggle id="dropdown-autoclose-true">
                Exchange
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown
                  className="inner_to_submenu"
                  show={show === "Perpetual" ? true : false}
                  onMouseEnter={() => handleDropdown("MouseEnter", "Perpetual")}
                  onClick={() => handleDropdown("MouseClick", "Perpetual")}
                  onMouseLeave={() => handleDropdown("MouseLeave", "Perpetual")}
                >
                  <Link
                    to={`/`}
                    className={
                      splitLocation[1] === `${EXHV1_URL}/`
                        ? "active nav-link "
                        : "nav-link "
                    }
                  >
                    <img src={MenuIcon1} alt="img" />
                    <span>
                      Perpetual Futures
                      <small>
                        Trade 200+ futures pairs on the most liquid perpetual
                        DEX in crypto markets
                      </small>
                    </span>
                  </Link>

                  {/* <Dropdown.Menu>
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="usdt"
                    >
                      <Nav variant="pills" className="">
                        <Nav.Item>
                          <Nav.Link eventKey="usdt">USDT</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <div className="Tabs_Content_Box">
                        <Tab.Content>
                          <Tab.Pane eventKey="usdt">
                            <div className="Content_links_list">
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "active nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </Dropdown.Menu> */}
                </Dropdown>

                <Dropdown
                  className="inner_to_submenu"
                  show={show === "Spot" ? true : false}
                  onMouseEnter={() => handleDropdown("MouseEnter", "Spot")}
                  onClick={() => handleDropdown("MouseClick", "Spot")}
                  onMouseLeave={() => handleDropdown("MouseLeave", "Spot")}
                >
                  <Link
                    to={`#`}
                    className={
                      splitLocation[1] === `${EXHV1_URL}/spot`
                        ? "active nav-link soonTag disabled"
                        : "nav-link soonTag disabled"
                    }
                  >
                    <img src={MenuIcon2} alt="img" />
                    <span>
                      Spot Trading
                      <small>
                        Trade 300+ pairs in one of crypto's largest spot markets
                      </small>
                    </span>
                  </Link>

                  {/* <Dropdown.Menu>
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="usdt"
                    >
                      <Nav variant="pills" className="">
                        <Nav.Item>
                          <Nav.Link eventKey="usdt">USDT</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <div className="Tabs_Content_Box">
                      
                        <Tab.Content>
                          <Tab.Pane eventKey="usdt">
                            <div className="Content_links_list">
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "active nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                              <Link
                                className={
                                  splitLocation[1] === ""
                                    ? "nav-link"
                                    : "nav-link"
                                }
                              >
                                <img src={tokenLogo} alt={"token-icon"} />
                                <h4>
                                  ETH <span>/ USDT</span>
                                  <span className="token_full_name">
                                    Ethereum
                                  </span>
                                </h4>
                              </Link>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </Dropdown.Menu> */}
                </Dropdown>

                {/* <Link
                                  to={`${EXHV1_URL}/universal-swap/`}
                                  target="_blank"
                                  className={
                                      splitLocation[1] ===
                                      `${EXHV1_URL}/universal-swap/`
                                          ? "active nav-link "
                                          : "nav-link "
                                  }
                              >
                                  <img src={MenuIcon3} alt="img" />
                                  <span>
                                      Universal Swap
                                      <small>
                                          Trade across 12 blockchains, and find
                                          the shortest path to profits
                                      </small>
                                  </span>
                              </Link> */}
                {/* <Link
                                  className={
                                      splitLocation[1] === "/"
                                          ? "active nav-link"
                                          : "nav-link"
                                  }
                              >
                                  <img src={MenuIcon4} alt="img" />
                                  <span>
                                      Copy Trading
                                      <small>
                                          Copy the best traders in the market
                                          for potentially superior profits
                                      </small>
                                  </span>
                              </Link> */}
              </Dropdown.Menu>
            </Dropdown>
          </li>
          {/* <li>
            <Link
              to={`${EXHV1_URL}/details/`}
              target="_blank"
              className={
                splitLocation[1] === `${EXHV1_URL}/details/`
                  ? "active nav-link"
                  : "nav-link"
              }
            >
              Markets
            </Link>
          </li> */}
          <li>
            <Dropdown
              id="basic-nav-dropdown"
              className="inner_submenu"
              show={show === "Earn" ? true : false}
              onMouseEnter={() => handleDropdown("MouseEnter", "Earn")}
              onClick={() => handleDropdown("MouseClick", "Earn")}
              onMouseLeave={() => handleDropdown("MouseLeave", "Earn")}
            >
              <Dropdown.Toggle id="dropdown-autoclose-true">
                Earn
              </Dropdown.Toggle>

              <Dropdown.Menu renderMenuOnMount={true}>
                <Link
                  to={"/staking"}
                  className={
                    splitLocation[1] === "#" ? "active nav-link" : "nav-link"
                  }
                >
                  <img src={MenuIcon5} alt="img" />
                  <span>
                    Staking & Profit-sharing
                    <small>
                      Stake EHX tokens to receive your share of our staking
                      rewards and trading fees.
                    </small>
                  </span>
                </Link>
                {/* <Link
                  to="#"
                  className={
                    splitLocation[1] === "#" ? "active nav-link" : "nav-link"
                  }
                >
                  <img src={MenuIcon6} alt="img" />
                  <span>
                    Referrals
                    <small>
                      Refer your friends and earn a % of their trading fees
                    </small>
                  </span>
                </Link> */}
                <Link
                  to="#"
                  className={
                    splitLocation[1] === "#"
                      ? "active nav-link disabled soonTag"
                      : "nav-link disabled soonTag"
                  }
                >
                  <img src={MenuIcon7} alt="img" />
                  <span>
                    Referrals
                    <small>
                      Refer your friends and earn a % of their trading fees
                    </small>
                  </span>
                </Link>
                {/* <Link
                                  to="#"
                                  className={
                                      splitLocation[1] === "#"
                                          ? "active nav-link disabled"
                                          : "nav-link disabled"
                                  }
                              >
                                  <img src={MenuIcon7} alt="img" />
                                  <span>
                                      Guild
                                      <small>
                                          Participate in Eterna’s DAO based
                                          guild system
                                      </small>
                                  </span>
                              </Link> */}
                {/* <Link
                                  to="#"
                                  className={
                                      splitLocation[1] === "#"
                                          ? "active nav-link"
                                          : "nav-link"
                                  }
                              >
                                  <img src={MenuIcon5} alt="img" />
                                  <span>
                                      Swap Bridge
                                      <small>Swap Bridge EHX tokens.</small>
                                  </span>
                              </Link> */}
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li>
            <Dropdown
              id="basic-nav-dropdown"
              className="inner_submenu"
              show={show === "Knowledge" ? true : false}
              onMouseEnter={() => handleDropdown("MouseEnter", "Knowledge")}
              onClick={() => handleDropdown("MouseClick", "Knowledge")}
              onMouseLeave={() => handleDropdown("MouseLeave", "Knowledge")}
            >
              <Dropdown.Toggle id="dropdown-autoclose-true">
                Knowledge Center
              </Dropdown.Toggle>

              <Dropdown.Menu className="Mega_Submenu">
                <Link
                  to={`${EXHV1_URL}/about-us/`}
                  target="_blank"
                  className={
                    splitLocation[1] === `${EXHV1_URL}/about-us/`
                      ? "active nav-link "
                      : "nav-link "
                  }
                >
                  <img src={MenuIcon8} alt="img" />
                  <span>
                    About Eterna
                    <small>
                      Explore Eterna Hybrid Exchange's unique features
                    </small>
                  </span>
                </Link>
                <Link
                  to={"/staking"}
                  target="_blank"
                  className={
                    splitLocation[1] === `${EXHV1_URL}/ehx-staking/`
                      ? "active nav-link "
                      : "nav-link "
                  }
                >
                  <img src={MenuIcon5} alt="img" />
                  <span>
                    Staking & Profit-sharing
                    <small>
                      Explore staking, staking rewards, and how to participate
                      in Eterna profit-sharing
                    </small>
                  </span>
                </Link>
                <Link
                  to={`${EXHV1_URL}/ehx/`}
                  target="_blank"
                  className={
                    splitLocation[1] === `${EXHV1_URL}/ehx/`
                      ? "active nav-link "
                      : "nav-link "
                  }
                >
                  <img src={MenuIcon9} alt="img" />
                  <span>
                    EHX
                    <small>
                      Your gateway to staking rewards and profit-sharing
                    </small>
                  </span>
                </Link>
                <Link
                  to={`${EXHV1_URL}/referral-system/`}
                  target="_blank"
                  className={
                    splitLocation[1] === `${EXHV1_URL}/referral-system/`
                      ? "active nav-link "
                      : "nav-link "
                  }
                >
                  <img src={MenuIcon6} alt="img" />
                  <span>
                    Refferals
                    <small>
                      Learn about Eterna referral system, and how to earn
                      referral rewards
                    </small>
                  </span>
                </Link>
                {/* <Link
                  to="#"
                  className={
                    splitLocation[1] === "#" ? "active nav-link disabled" : "nav-link disabled"
                  }
                >
                  <img src={MenuIcon10} alt="img" />
                  <span>
                    Buy & Sell Signals
                    <small>
                      Eterna’s unique market indicators can help provide a
                      competitive trading edge
                    </small>
                  </span>
                </Link> */}
                <Link
                  to={`${EXHV1_URL}/perpetual-futures/`}
                  target="_blank"
                  className={
                    splitLocation[1] === `${EXHV1_URL}/perpetual-futures/`
                      ? "active nav-link "
                      : "nav-link "
                  }
                >
                  <img src={MenuIcon1} alt="img" />
                  <span>
                    Perpetual Trading
                    <small>
                      Learn how to trade 200+ perpetual futures contracts on
                      Eterna’s innovative exchange
                    </small>
                  </span>
                </Link>
                <Link
                  to="https://eterna.exchange/cyber-security/"
                  className={
                    splitLocation[1] === "#" ? "active nav-link " : "nav-link "
                  }
                >
                  <img src={MenuIcon11} alt="img" />
                  <span>
                    Cybersecurity
                    <small>
                      Eterna is protected by Chainalysis and Palo Alto Networks
                    </small>
                  </span>
                </Link>
                <Link
                  to={`/spot`}
                  target="_blank"
                  className={
                    splitLocation[1] === `/spot`
                      ? "active nav-link disabled"
                      : "nav-link disabled"
                  }
                >
                  <img src={MenuIcon2} alt="img" />
                  <span>
                    Spot Trading
                    <small>
                      Learn about how to engage in spot trading of over 300
                      trading pairs on Eterna
                    </small>
                  </span>
                </Link>
                <Link
                  to={`https://eterna.exchange/chainalysis/`}
                  target="_blank"
                  className={
                    splitLocation[1] === `${EXHV1_URL}/universal-swap/`
                      ? "active nav-link "
                      : "nav-link "
                  }
                >
                  <img src={ChainIcon} alt="img" />
                  <span>
                    Chainalysis
                    <small>Learn about Chainalysis and AML protocols</small>
                  </span>
                </Link>
                {/* <Link
                                  to={`${EXHV1_URL}/blog/`}
                                  target="_blank"
                                  className={
                                      splitLocation[1] === `${EXHV1_URL}/blog/`
                                          ? "active nav-link "
                                          : "nav-link "
                                  }
                              >
                                  <img src={MenuIcon12} alt="img" />
                                  <span>
                                      Eterna Blog
                                      <small>
                                          Explore a wide variety of crypto
                                          investment topics
                                      </small>
                                  </span>
                              </Link> */}
              </Dropdown.Menu>
            </Dropdown>
          </li>

          {!pathname.includes("dashboard") && (
            <li>
              <Link
                reloadDocument
                to={splitLocation[1] === `spot` ? "/" : "/spot"}
                className={
                  splitLocation[1] === `${EXHV1_URL}/details/`
                  ? "active nav-link"
                  : "nav-link"
                }
                >
                {splitLocation[1] === `spot`
                  ? "Futures"
                  : splitLocation[1] === ``
                  ? "Spot"
                : ""}
              </Link>
            </li>
          )}
          {pathname.includes("dashboard") && (
            <>
              <li>
                <Link
                to={"/spot"}
                  className={
                    pathname.includes("dashboard/spot")
                      ? "active nav-link"
                      : "nav-link"
                  }
                >
                  Spot
                </Link>
              </li>
              {/* <li>
                <Link
                  reloadDocument
                  to={"/"}
                  className={
                    pathname.includes("/") ? "active nav-link" : "nav-link"
                  }
                >
                  Futures
                </Link>
              </li> */}
            </>
          )}
        </ul>
      </div>
      <Modal
        className="comingSoon"
        centered
        show={showModal}
        onHide={handlerClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h2>Coming Soon</h2>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NavbarMenu;
