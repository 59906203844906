import React from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonButton from '../../../../common/CommonButton/CommonButton';
import errorIcon from "../../../../assets/icons/info_redIcon.svg"
import { WalletIcon } from '../../../../assets/Svg/SvgImages';
import blockchainconfirm from "../../../../assets/images/admin/blockchain-confirm.svg"
import checkdbl from "../../../../assets/images/admin/check-double.svg"
import timescircle from "../../../../assets/images/admin/times-circle.svg"

const ConfirmTransactionModal = ({ show, handleClose }) => {
    return (
        <>
            <Modal className='Admin_Modal Confirm_modal' centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='Confirm_modal_Content'>
                        <div className='Confirm_transaction d-none align-items-center'>
                            <WalletIcon />
                            <p>Please confirm the transaction within your Metamask wallet, then click proceed when you are ready.</p>
                        </div>
                        <div className='Confirm_transaction'>
                            <div className='d-flex align-items-center'>
                                <img src={blockchainconfirm} alt='icon' />
                                <p>We are confirming transaction on the blockchain, please be patient....</p>
                            </div>
                            <div className='Confirmation_Network d-none'>
                                <h4><span>Network:</span> Ethereum (ERC20) Detected</h4>
                            </div>
                            <div className='Confirmation_No d-none'>
                                <h3><span>0</span> of <span>12</span> Confirmations <img className='ms-2 d-none' src={checkdbl} alt='check' /></h3>
                            </div>
                            <div className='No_Transaction text-center d-none'>
                                <img src={timescircle} alt='icon' />
                                <h4>No transaction detected or incorrect network</h4>
                                <p>Please check that you:</p>
                                <ul>
                                    <li>are connected to Metamask wallet</li>
                                    <li>have connected to the right network</li>
                                    <li>have initiated the deposit / transaction</li>
                                </ul>
                            </div>
                        </div>
                        <CommonButton className="w-100" title="Proceed" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConfirmTransactionModal
