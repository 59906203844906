import React, { memo, useEffect } from "react";
import "./OrderHistory.scss";
import Table from "react-bootstrap/Table";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useAppSelector } from "../../../../../hooks/redux.hooks";
import { ORDER_TYPES } from "../../../../../constants/order.constant";
import moment from "moment/moment";
import { Form } from "react-bootstrap";
import { ApiServices } from "../../../services/api.services";
import { saveUserOrderHistory } from "../../../redux/_slices/spotExchange.slice";
import { useDispatch, useSelector } from "react-redux";

const OrderHistory = () => {
  const dispatch = useDispatch();
  const walletAddress = useSelector((state) => state?.user?.walletAddress);
  const orderHistory = useAppSelector(
    (state) => state.spotExchange.userOrderHistory
  );
  const limit = 10;

  useEffect(() => {
    if (walletAddress) {
      getOrderHistoryList();
    } else {
      dispatch(saveUserOrderHistory([]));
    }
  }, [walletAddress]);

  const getOrderHistoryList = async () => {
    try {
      const dataObj = {
        category: "spot",
        address: walletAddress,
        limit,
        // ...filters,
      };
      const response = await ApiServices.ordersHistoryList(dataObj);
      // console.log("order historyyyyyyyyyyyyy", response);
      dispatch(saveUserOrderHistory(response?.data?.list));
    } catch (error) {}
  };

  return (
    <>
      <div className="Orders_Table">
        <PerfectScrollbar></PerfectScrollbar>
        <Table responsive>
          <thead>
            <tr>
              <th>Side</th>
              <th>Type</th>
              <th style={{ minWidth: "150px" }}>
                Amount / Filled <span>BTC</span>
              </th>
              <th>Price</th>
              <th>Trigger</th>
              <th style={{ minWidth: "135px" }}>Date / Time</th>
            </tr>
          </thead>
          <tbody>
            {orderHistory.map((data, i) => {
              return (
                <tr key={i}>
                  <td>
                    <Form.Check
                      label={data.side === 0 ? "Buy" : "Sell"}
                      name=""
                      type="radio"
                      className="Radio_btn"
                    />
                  </td>
                  <td className="text_green">{ORDER_TYPES[data.type]}</td>
                  <td style={{ minWidth: "150px" }} className="Amount_Data">
                    {data.quantity} / {data.filled_amount}
                    {/* <div className='d-flex align-items-center'>
                                            <div>
                                                <p>{data.filled_amount}</p>
                                                <p>{data.amountbtc2}</p>
                                            </div>
                                            <p className='ms-3'>{data.amountbtc3}</p>
                                        </div> */}
                  </td>
                  <td>{data.price}</td>
                  <td>{data.price <= 0 ? data.trigger_price : "--"}</td>
                  <td style={{ minWidth: "135px" }} className="Good_Til">
                    {moment(Number(data?.created_at)).format(
                      "DD-MM-YYYY  HH:mm:ss"
                    )}{" "}
                    <span>{data.til}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default memo(OrderHistory);
