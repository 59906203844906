import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { DOLLARPRICE } from "../../../pages/Staking/constants/constants";
import {
  toCustomFixedFunction,
  tofixFunction,
} from "../../../pages/Staking/services/HelperServices";
import "./CustomInput.scss";
import { memo } from "react";
import { toCustomFixed } from "../../../pages/Staking/services/ContractServices/ContractHelper";

const CustomInput = ({
  icon,
  icon2,
  comp,
  coin,
  className,
  onChange,
  value,
  disabled,
  max,
  setMaxFunction,
  userTab,
  dollarValue,
  handleKeyDown,
}) => {
  return (
    <InputGroup className={`custom-input ${className}`}>
      {/* <InputGroup.Text>
        <img src={icon} alt="icon" className="custom-input__icon" />
        <div className="custom-input__text">
          {coin}
          <span>($0,00)</span>
        </div>
      </InputGroup.Text> */}
      <InputGroup.Text className="w-100 justify-content-between">
        <div className="d-flex align-items-center">
          <img src={icon} alt="icon" className="custom-input__icon" />
          {userTab == "addlp" && comp == 2 ? (
            <img src={icon2} alt="icon" className="custom-input__icon" />
          ) : null}

          <div className="custom-input__text">
            {coin ? (
              coin
            ) : (
              <Form.Control
                type="number"
                onChange={onChange}
                value={value}
                disabled={disabled}
                onBeforeInput={handleKeyDown}
              />
            )}
          </div>
        </div>
        <div className="d-flex align-items-center">
          {coin && coin.includes("USDT") ? (
            <span
              title={`${dollarValue && Number(value) > 0
                  ? toCustomFixed(Number(value), 8)
                  : "$0.00"
                }`}
            >
              (
              {`${dollarValue && Number(value) > 0
                  ? toCustomFixed(Number(value), 2)
                  : "$0.00"
                }`}
              )
            </span>
          ) : (
            <span
              title={`${dollarValue && Number(value) > 0
                  ? toCustomFixedFunction(Number(value), 8)
                  : "$0.00"
                }`}
            >
              (
              {`${dollarValue && Number(value) > 0
                  ? toCustomFixedFunction(Number(value), 2)
                  : "$0.00"
                }`}
              )
            </span>
          )}
          {max ? (
            <div
              className="cursor-pointer ms-2"
              onClick={() => setMaxFunction(true)}
            >
              MAX
            </div>
          ) : null}
        </div>
      </InputGroup.Text>
      {/* <Form.Control type="number" /> */}
      {/* <InputGroup.Text className="cursor-pointer">MAX</InputGroup.Text> */}
    </InputGroup>
  );
};

export default memo(CustomInput);
