import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import tick from "../../assets/icons/tick.svg";
import errortick from "../../assets/images/check_cross_c.svg";
import { TXN_HASH_URL } from "../../pages/Staking/constants/constants";
import CommonButton from "../CommonButton/CommonButton";
import ModalComponet from "../ModalComponet";
import "./ConfirmationModal.scss";
// import { useSelector } from "react-redux";

const ConfirmationModal = forwardRef((props, ref) => {
  const modalRef = useRef();
  // const chainId = useSelector((state) => state?.user?.chainId);
  // const txnUrl = chainId == 1 ? TXN_HASH_URL : TXN_HASH_URL_BSC;

  useImperativeHandle(ref, () => ({
    handleModal(value) {
      modalRef.current.handleModal(value);
    },
  }));

  const [isCheckNewtork, setIsCheckNewtork] = useState(true);
  useEffect(() => {
    const network = setTimeout(() => {
      setIsCheckNewtork(false);
    }, 3000);
  }, []);

  const transactionConfirmationFunction = (e, key) => {
    if (key == 1) {
      e.preventDefault();
      window.open(TXN_HASH_URL + modalData?.transactionHash, "_blank");
      // props?.handleModalClose(false);
    }
  };

  const modalData = useMemo(() => {
    return props?.modalData;
  }, [props]);

  return (
    <>
      <ModalComponet
        hide={props?.handleModalClose}
        backdrop={false}
        // ref={modalRef}
        show={props?.showModal}
        title={!modalData?.mainFunction ? "Confirmation" : "Confirm Wallet"}
        className="confirm_modal"
        direction="staking"
      >
        {isCheckNewtork ? (
          <div className="text-center confirm_modal__content confirm_modal__content--loading">
            <Loader />
            <p>Confirm the transaction in your wallet.</p>
          </div>
        ) : (
          <ul className="confirm_modal__content">
            <li>
              <div className="confirm_modal__check">
                {modalData?.approval ? (
                  <img src={tick} alt="icon" />
                ) : modalData?.approvalError ? (
                  <img src={errortick} alt="icon" />
                ) : (
                  <Loader className="inner" />
                )}
              </div>
              <div className="confirm_modal__text">
                <p>Enable contract</p>
              </div>
            </li>
            <li>
              <div className="confirm_modal__check">
                {modalData?.mainFunction ||
                  modalData?.stakeEhxReward?.stakeEhxWithdraw ? (
                  <img src={tick} alt="icon" />
                ) : modalData?.error ? (
                  <img src={errortick} alt="icon" />
                ) : (
                  <Loader className="inner" />
                )}
              </div>
              <div className="confirm_modal__text confirm_modal__text--confirm">
                <p className="text-blue">
                  {modalData?.stakeEhxReward?.stakeEhx
                    ? "Confirm Withdraw"
                    : props?.comp == 2
                      ? "Confirm Unstake"
                      : "Confirm Stake"}
                </p>
                <p>
                  {modalData?.mainFunction ||
                    modalData?.stakeEhxReward?.stakeEhxWithdraw
                    ? "Transaction Confirmed"
                    : "Confirm the transaction in your wallet."}
                </p>
              </div>
            </li>{" "}
            {modalData?.stakeEhxReward?.stakeEhx ? (
              <li>
                <div className="confirm_modal__check">
                  {modalData?.stakeEhxReward?.stakeEhxdeposit ? (
                    <img src={tick} alt="icon" />
                  ) : modalData?.stakeEhxReward?.stakeEhxDepositError ? (
                    <img src={errortick} alt="icon" />
                  ) : (
                    <Loader className="inner" />
                  )}
                </div>
                <div className="confirm_modal__text confirm_modal__text--confirm">
                  <p className="text-blue">{"Confirm Deposit"}</p>
                  <p>
                    {modalData?.stakeEhxReward?.stakeEhxdeposit
                      ? "Transaction Confirmed"
                      : "Confirm the transaction in your wallet."}
                  </p>
                </div>
              </li>
            ) : null}
          </ul>
        )}

        <div className="d-flex justify-content-between confirm_modal__status">
          <span>Status</span>
          {/* <span className="text-danger">Error</span> */}
          <span className={modalData?.error ? "text-danger" : "text-light"}>
            {modalData?.mainFunction
              ? "Confirmed"
              : modalData?.error
                ? "Reject"
                : "Waiting for you to confirm"}
          </span>
        </div>
        <a
          onClick={(e) => {
            modalData?.mainFunction
              ? transactionConfirmationFunction(e, 1)
              : modalData?.error
                ? modalData?.sendFunction(e)
                : transactionConfirmationFunction(e, 2);
          }}
        >
          <CommonButton
            title={
              modalData?.stakeEhxReward?.stakeEhx &&
                modalData?.stakeEhxReward?.stakeEhxdeposit
                ? "View Transaction"
                : !modalData?.stakeEhxReward?.stakeEhx &&
                  modalData?.mainFunction
                  ? "View Transaction"
                  : modalData?.error
                    ? "Confirm transaction again"
                    : "In Progress"
            }
            className="w-100"
          />
        </a>
      </ModalComponet>
    </>
  );
});

const Loader = ({ className }) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center loader loader--${className}`}
    >
      <div className="dbl-spinner" />
    </div>
  );
};

export default ConfirmationModal;
