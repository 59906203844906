import React, { useRef, useState, useEffect } from "react";
import stagIcon from "../../../../../assets/icons/star-icon.svg";
import "./TrendingPairs.scss";
import { useDispatch, useSelector } from "react-redux";
import {
    savePrevSelectedPairKey,
    saveSelectedPair,
    saveSelectedPairKey,
} from "../../../../../redux/_slices/user.slice";
import { showDecimalHead } from "../../../../../services/HelperServices";
import {
    saveOrderSize,
    savePairDecimals,
} from "../../../../SpotExchange/redux/_slices/spotExchange.slice";

import { getDecimalString } from "../../../../../_utils/comman";
import arrowicon from "../../../../../assets/icons/blue_arrow.svg";
import FireIcon from "../../../../../assets/icons/fire-icon.svg";
import CommonButton from "../../../../../common/CommonButton/CommonButton";

const TrendingPairs = () => {
    const dispatch = useDispatch();
    const scrollContainerRef = useRef(null);
    const favPairList = useSelector((state) => state.user?.favPairList);
    const pairList = useSelector((state) => state.exchange.listOfPairs);
    const selectedPairKey = useSelector((state) => state.user.selectedPairKey);

    const handleOnSelect = (value) => {
        const pairData = pairList?.find((doc) => doc.pair === value);
        const priceDecimalLength = getDecimalString(
            pairData?.min_price_increment,
            pairData?.price_decimal
        );

        const baseDecimalLength = getDecimalString(
            pairData?.min_size_increment,
            pairData?.base_decimal
        );

        dispatch(savePairDecimals({ priceDecimalLength, baseDecimalLength }));
        dispatch(savePrevSelectedPairKey(selectedPairKey));
        dispatch(saveSelectedPairKey(value));
        dispatch(saveOrderSize(""));

        dispatch(saveSelectedPair(pairData));
    };

    const getPrices = (pairKey) => {
        return pairList?.find((data) => data.pair_name === pairKey);
    };
    const handleOnChanges = (currentPrice, price24) => {
        let priceDiff = 0;
        if (currentPrice > price24) {
            priceDiff = currentPrice - price24;
        } else {
            priceDiff = price24 - currentPrice;
        }
        if (price24 === 0) {
            return 0;
        }
        if (currentPrice && priceDiff) {
            return ((priceDiff / price24) * 100).toFixed(2);
        }

        return 0;
    };

    return (
        <>
            <div className="Trending_Pair">
                <div className="Overview_Heading d-flex align-items-center justify-content-between">
                    <h6>
                        <img src={FireIcon} alt="fire icon" /> Favourite Pairs
                    </h6>
                    {/* <CommonButton title="View all" className="grey-btn2" /> */}
                </div>

                <ul className="Trending_Pair_Box">
                    {favPairList?.map((pairKey, i) => {
                        return (
                            <li key={i}>
                                <div className="Trending_Box_Card">
                                    <p>
                                        {pairKey
                                            ?.replace("_", "")
                                            ?.toUpperCase()}
                                    </p>
                                    <h6
                                        className={`${
                                            getPrices(pairKey)?.change > 0
                                                ? "text_green"
                                                : "text_red"
                                        } `}
                                    >
                                        {handleOnChanges(
                                            getPrices(pairKey)?.lastPrice,
                                            getPrices(pairKey)?.close_24hour
                                        ) !== 0
                                            ? getPrices(pairKey)?.lastPrice >
                                              getPrices(pairKey)?.close_24hour
                                                ? "+"
                                                : "-"
                                            : ""}{" "}
                                        {getPrices(pairKey)?.change
                                            ? showDecimalHead(
                                                  getPrices(pairKey)?.change *
                                                      100,
                                                  2
                                              )
                                            : 0}
                                        %
                                    </h6>

                                    {/* <span className="LineChar_icon">
                                        {data.trade}{" "}
                                        <img src={arrowicon} alt="icon" />
                                    </span> */}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
};

export default TrendingPairs;
