import React, { useState, useEffect, memo } from "react";
import { Pagination, Table } from "react-bootstrap";
import "./TradeHistory.scss";
import FiltersTradeHistoryPrepetual from "./FiltersTradeHistoryPrepetual";
import { ApiServices } from "../../../../../Exchange/services/api.services";
import {
    saveUserTradeHistory,
    savetrades,
} from "../../../../../Exchange/redux/_slices/exchange.slice";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import iconSell from "../../../../../../assets/icons/redline_trade.svg";
import iconBuy from "../../../../../../assets/icons/linechat_arrow.svg";
import Loader from "../../../../common/Loader/Loader";
import { updateLoader } from "../../../../../Exchange/redux/_slices/exchange.slice";

const TradeHistory = ({currentTab, isActive}) => {
    const dispatch = useDispatch();
    const walletAddress = useSelector((state) => state?.user?.walletAddress);
    const listofTrades = useSelector(
        (state) => state.exchange.userTradeHistory
    );
    const tradeFilter = useSelector(
        (state) => state.user.userTradeFilters?.tradeFilter
    );
    const loader = useSelector((state) => state.exchange.loader);

    useEffect(() => {
        if (walletAddress && currentTab === 'pretradeHistory' && isActive) {
            dispatch(updateLoader(true));
            getTradeHistoryList();
        } else {
            dispatch(saveUserTradeHistory([]));
            dispatch(savetrades([]));
        }
    }, [tradeFilter, walletAddress, currentTab, isActive]);

    const getTradeHistoryList = async () => {
        try {
            const dataObject = {
                address: walletAddress,
                dateFilter: tradeFilter,
            };
            const response = await ApiServices.tradeHistoryList(dataObject);
            if(response){
                dispatch(saveUserTradeHistory(response?.data?.list));
                dispatch(updateLoader(false));
            }
        } catch (err) {
            console.log(err);
            dispatch(updateLoader(false));
        }
    };

    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    return (
        <>
            {loader && <Loader/>}
            <FiltersTradeHistoryPrepetual data = {listofTrades}/>
            <div className="CryptoAssets_Table History_Table">
                <Table responsive>
                    <thead>
                        <tr>
                            <th style={{ minWidth: "150px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Contracts
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "150px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Filled Type
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    Filled/Total
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "210px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Filled Price/Order Price
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    Fee Rate
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Trading Fee
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Trade Type
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "140px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Order Type
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "370px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Order ID
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "370px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Transaction ID
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: "270px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    Transaction Time
                                    <div className="upDown_arrow">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {listofTrades !== undefined &&
                        listofTrades?.length > 0 ? (
                            listofTrades.map((data, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <div class="coin_data d-flex align-items-center">
                                                <div class="d-flex align-items-center">
                                                    <span>{data?.symbol}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{data?.execType}</td>
                                        <td>{data?.execQty} / {data?.execQty}</td>
                                        <td>
                                            {roundToTwoDecimals(
                                                data?.execPrice
                                            )}
                                            /
                                            {roundToTwoDecimals(
                                                data?.orderPrice
                                            )}
                                        </td>
                                        <td>
                                            {roundToTwoDecimals(data?.feeRate)}
                                        </td>
                                        <td>
                                            {roundToTwoDecimals(data?.execFee)}
                                        </td>
                                        <td>{data?.execType}</td>
                                        <td>{data?.orderType}</td>
                                        <td>{data?.orderId}</td>
                                        <td>{data?.execId}</td>
                                        <td>
                                            {moment(
                                                Number(data?.execTime)
                                            ).format("DD-MM-YYYY  HH:mm:ss")}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={11}>
                                    <p style={{ textAlign: "center" }}>
                                        No result found
                                    </p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {/* <div className="transactions_Pagination d-flex align-items-center justify-content-between">
                    <Pagination className="common_pagination">
                        <Pagination.Prev />
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>

                        <Pagination.Ellipsis className="ellipsis_text" />
                        <Pagination.Item>{6}</Pagination.Item>
                        <Pagination.Next />
                    </Pagination>
                </div> */}
            </div>
        </>
    );
};

export default memo(TradeHistory);
