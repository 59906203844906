import React, { memo } from "react";
import { Container } from "react-bootstrap";
import "./AnalysisWidget.scss";
import { API_HOST } from "../../../../constants/constants";
import { useAppSelector } from "../../../../hooks/redux.hooks";

const AnalysisWidget = () => {
  const selectedPairKey = useAppSelector((state) => state.user.selectedPairKey);
  // console.log("selectedPairKey", selectedPairKey, EXCHANGE_SITE_URL);
  const coinArray = selectedPairKey.split("_");
  // const widgetSrc = `${EXCHANGE_SITE_URL}/technical-analysis/${selectedPairKey}`
  const widgetSrc = `/technical.html?pair=${selectedPairKey}&apiUrl=${API_HOST}`;

  return (
    <>
      <div className="Technical_Analysis_Box">
        <Container fluid className="px-10">
          <div className="Technical_Analysis_card">
            {/* <h2 className="card_hdr_title">
              Technical Analysis for {coinArray[0]}
            </h2> */}
            {/* <iframe
              width="100%"
              height="260"
              type="text/html"
              src={widgetSrc}
            ></iframe> */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default memo(AnalysisWidget);
