import { toastMsg } from "../common/Toast/Toast";
import { NETWORK_INFO } from "../constants/wallet";
import { convertDecimalToHex } from "../_utils/comman";

import { saveUserWalletAddress, saveWallet } from "../redux/_slices/user.slice";
import { web3Services } from "./web3.service";

/**
 * Wallet Intregation with network
 * @param {*} walletInfo
 * @param {*} chainId
 * @returns
 */
export const connectWallet = (walletInfo, chainId) => (dispatch) => {
  switch (walletInfo.key) {
    case "metamask":
      return dispatch(connectMetamask(walletInfo, chainId));
    case "binance_wallet":
      return dispatch(connectBinanceWallet(walletInfo, chainId));
    // case "walletconnect":
    // return dispatch(connectWalletConnect(walletInfo, chainId));
    // case "trust_wallet":
    // return dispatch(connectWalletConnect(walletInfo, chainId));

    default:
      break;
  }
};

/**
 * Switch wallet chain network
 * @param {*} wallet
 * @param {*} chainId
 * @returns
 */
export const switchWalletChain = (wallet, chainId) => {
  switch (wallet) {
    case "metamask":
      const { ethereum } = window;
      return switchMetamaskNetwork(ethereum, chainId);
    case "binance_wallet":
      return switchBinanceWalletNetwork(chainId);
    default:
      break;
  }
};
/**
 * Connect Metamask Wallet
 * @param {*} walletInfo
 * @param {*} chainId
 * @returns
 */
const connectMetamask = (walletInfo, chainId) => async (dispatch) => {
  try {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      // get Account address
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      // check network and switch
      const chain = await checkNetwork(ethereum, chainId);
      if (chain) {
        await web3Services.signAppWithWallet(ethereum, accounts[0]);
        dispatch(saveWalletInfo(walletInfo.key, accounts[0]));
        return true;
      } else {
        return false;
      }
    } else {
      window.open(walletInfo.url, "_blank");
      return false;
    }
  } catch (error) {
    console.log("connectMetamask Error : ", error);
    return false;
  }
};

/**
 * Connect Binance wallet
 * @param {*} walletInfo
 * @param {*} chainId
 * @returns
 */
const connectBinanceWallet = (walletInfo, chainId) => async (dispatch) => {
  try {
    const chain = NETWORK_INFO.find((doc) => doc.chain_id === chainId);
    if (chain.value === "binance") {
      const { BinanceChain } = window;
      if (BinanceChain) {
        // get Account address
        const accounts = await BinanceChain.request({
          method: "eth_requestAccounts",
        });

        // check switch the network
        await BinanceChain.switchNetwork(
          chain.binance_wallet_network_id
        );
        await web3Services.signAppWithWallet(BinanceChain, accounts[0]);
        dispatch(saveWalletInfo(walletInfo.key, accounts[0]));
        return true;
      } else {
        window.open(walletInfo.url, "_blank");
        return false;
      }
    } else {
      toastMsg.error(
        `Wallet is not supported this ${chain.name} chain. Please switch to the Binance chain.`
      );
      return false;
    }
  } catch (error) {
    console.log("connectBinanceWallet Error : ", error);
    return false;
  }
};

/**
 * Connect Network with metamask wallet
 * @param {*} ethereum
 * @param {*} chainId
 * @returns
 */
const checkNetwork = async (ethereum, chainId) => {
  try {
    const currentChainId = await ethereum.request({ method: "eth_chainId" });
    if (Number(currentChainId) === chainId) {
      return true;
    } else {
      return await switchMetamaskNetwork(ethereum, chainId);
    }
  } catch (error) {
    return false;
  }
};

/**
 * Switch metamask wallet network
 * @param {*} ethereum
 * @param {*} chain_id
 * @returns
 */
const switchMetamaskNetwork = async (ethereum, chain_id) => {
  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: convertDecimalToHex(chain_id) }],
    });

    return true;
  } catch (error) {
    if (error && error.code === 4902) {
      const network = NETWORK_INFO.find((doc) => doc.chain_id === chain_id);
      await ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: convertDecimalToHex(network.chain_id),
            chainName: network.name,
            nativeCurrency: {
              name: network.name,
              symbol: network.symbol,
              decimals: Number(network.decimals),
            },
            rpcUrls: [network.rpc_url],
            blockExplorerUrls: [network.explorer_url],
            iconUrls: [network.icon],
          },
        ],
      });

      return true;
    }
    return false;
  }
};

/**
 * Switch Binance Wallet network
 * @param {*} chain_id
 * @returns
 */
const switchBinanceWalletNetwork = async (chain_id) => {
  try {
    const chain = NETWORK_INFO.find((doc) => doc.chain_id === chain_id);
    if (chain.value === "binance") {
      const { BinanceChain } = window;
      if (BinanceChain) {
        // check switch the network
        await BinanceChain.switchNetwork(
          chain.binance_wallet_network_id
        );

        return true;
      } else {
        return false;
      }
    } else {
      toastMsg.error(`Wallet is not supported ${chain.name} chain.`);
      return false;
    }
  } catch (error) {
    console.log("switchBinanceWalletNetwork Error : ", error);
    return false;
  }
};

/**
 * Connect Trust Wallet, Metamask Wallet, WalletConnect
 * @param {*} walletInfo
 * @param {*} chainId
 *
 *
 */

// export let provider = new WalletConnectProvider({
//   rpc: {
//     1: RPCURL,
//   },
//   qrcode: true,
//   qrcodeModalOptions: {
//     mobileLinks: ["rainbow", "metamask", "argent", "trust"],
//   },
// });
// const connectWalletConnect = (walletInfo, chain_id) => async (dispatch) => {
//   try {
//     const walletconnect = localStorage.getItem("walletconnect");
//     if (walletconnect) {
//       localStorage.removeItem("walletconnect");
//     }

//     //  Create WalletConnect Provider
//     // let provider = new WalletConnectProvider({
//     //   rpc: {
//     //     1: "https://eth-mainnet.gateway.pokt.network/v1/5f3453978e354ab992c4da79",
//     //     [chain_id]: NETWORK_INFO?.find((doc) => doc.chain_id === chain_id)
//     //       ?.rpc_url,
//     //   },
//     // });
//     //  Enable session (triggers QR Code modal)
//     // await provider.enable();

//     ///////////>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>/////////
//     // try {

//     if (provider.connected == false && provider.isConnecting == true) {
//       provider.qrcodeModal.open(provider.connector.uri, {
//         bridge: provider.bridge,
//       });
//     }
//     const account = await provider.enable();

//     console.log(account, "account");
//     // return account[0];
//     // }
//     // catch (error) {
//     //   if (error?.message == "User closed modal") await provider?.disconnect();
//     // }

//     // wallect connect with right chain
//     const { accounts, chainId } = provider;

//     if (chainId === chain_id) {
//       await web3Services.signAppWithWallet(provider, accounts[0]);
//       dispatch(saveWalletInfo(walletInfo.key, accounts[0]));
//       return true;
//     } else {
//       provider = null;
//       localStorage.removeItem("walletconnect");
//       toastMsg.error(
//         "Please connect to a supported network in the dropdown menu or in your wallet"
//       );
//       return false;
//     }
//   } catch (error) {
//     console.log("web3WalletConnect Error :", error);
//     // if (error?.message == "User closed modal") await provider?.disconnect();

//     return false;
//   }
// };

export const saveWalletInfo = (walletKey, userAddress) => (dispatch) => {
  dispatch(saveWallet(walletKey));
  dispatch(saveUserWalletAddress(userAddress));
};
