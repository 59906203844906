import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import info from "../../../assets/icons/info.svg";
import { InfoIcon } from "../../../assets/Svg/SvgImages";
import "./CustomTooltip.scss";
import { memo } from "react";

const CustomTooltip = ({ content, content1, Placement, className, tooltipclass }) => {
  // const renderTooltip = (props) => (
  //   <Tooltip className="tooltip-style" {...props} positionRight={200}>
  //     {content}
  //   </Tooltip>
  // );
  return (
    // <OverlayTrigger
    //   placement="bottom-end" 
    //   delay={{ show: 250, hide: 400 }}
    //   overlay={renderTooltip}
    // >
    //   <span className="tootip-icon">
    //     <img src={info} alt="info-icon" />
    //   </span>
    // </OverlayTrigger>
    // bottom-end
    <OverlayTrigger
      key={content1}
      placement={Placement}
      className={tooltipclass}
      // delay={{ show: 280, hide: 4000999 }}
      overlay={
        <Tooltip className={className} id={`tooltip-${content1}`}>
          {content}
        </Tooltip>
      }
    >
      {/* <Button variant="secondary">Tooltip on </Button> */}
      <span className="tootip-icon">
        {/* <img src={info} alt="info-icon" /> */}
        {/* {content} */}
        <InfoIcon />
      </span>
    </OverlayTrigger>
  );
};

export default memo(CustomTooltip);
