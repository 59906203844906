import Web3 from "web3";
import { toastMsg } from "../../../../common/Toast/Toast";
import { setErrorCounter } from "../../../../redux/_slices/user.slice";
import store from "../../../../redux/_store/configureStore";
import { dynamicContractDetails } from "../../assets/dynamicContract";
// import { setloader } from "../../redux/reducers/Loader/loaderslice";
import { commonAbiInstances } from "./GlobalCall";

export const errorHelperContract = async (error, errorType, functionName) => {
  if (errorType == "call") {
    // console.log("store", store.getState().user.errorCounter);
    const errorVariable = error?.message.includes(
      "Returned values aren't valid"
    );

    const errorVariable1 = error?.message.includes(
      "You have reached the maximum API usage limit of public"
    );
    // console.log(`${functionName} `, error);
    let msg = error.message.split(",")[1];
    if (!store.getState().user.errorCounter) {
      if (errorVariable) {
        window.location.pathname != "/staking" &&
          toastMsg.error("Wrong network !");
      } else if (errorVariable1) {
        toastMsg.error("Rpc Limit Reached for binance wallet !");
      } else {
        toastMsg.error(
          error?.message?.includes("MetaMask") ? error?.message : msg
        );
      }
    }
    if (errorVariable || errorVariable1) {
      store.dispatch(setErrorCounter(true));
    }
    return;
  } else {
    const err = getError(error);
    toastMsg.error(err);
    return;
  }
};

export const getDecimalFunction = async (tokenAddress, amount) => {
  let inputAmount = [];
  const stakeData = dynamicContractDetails.filter(
    (a) => a.symbol == "REWARDTOKEN"
  );
  const contract = await commonAbiInstances(
    stakeData[0]?.address,
    stakeData[0]?.abi
  );
  const decimals = await contract.methods.decimals().call();
  let amountstake = (Number(amount) * 10 ** decimals).toLocaleString(
    "fullwide",
    {
      useGrouping: !1,
    }
  );
  return amountstake;
};

/**GET ERROR MESSAGE FORM ERROR OBJECT */
export const getError = (error) => {
  let errorMsg =
    error && error.message ? error.message : "Something went wrong";
  // console.log("errorMsg", error);
  if (errorMsg.indexOf("execution reverted") > -1) {
    let msg = errorMsg;
    // console.log(
    //   'msg.split("execution reverted:")[1]',
    //   msg.split("execution reverted:")[1].split(",")[0]
    // );
    msg = msg =
      msg.indexOf("execution reverted:") > -1
        ? msg.split("execution reverted:")[1].split(",")[0]
        : msg;
    return msg;
  } else if (errorMsg.indexOf("INVALID_ARGUMENT") > -1) {
    return errorMsg.split("(")[0];
  } else if (errorMsg.indexOf("MetaMask Tx Signature") > -1) {
    let msg = errorMsg?.replace("MetaMask Tx Signature:", "");
    return msg;
  } else {
    let err = errorMsg.split("*")[0].split(":")[1];
    if (err?.trim() === "insufficient funds for gas") {
      return err;
    } else {
      return errorMsg;
    }
  }
};

/**REMOVE e FORM BIG NUMBER & and handle Big Number Error Function*/
export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  x = x?.toString();
  let newX = Web3.utils.fromWei(x, "ether");
  newX = Number(newX)?.toFixed(5)?.slice(0, -1);
  return newX;
};

export const toFunctionBigNumber = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  // x = x?.toString();
  // let newX = Web3.utils.fromWei(x, "ether");
  // newX = Number(newX)?.toFixed(5)?.slice(0, -1);
  return x;
};

export const toCustomFixed = (num, fixed) => {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
};
