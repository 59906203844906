import React from "react";
import { Link } from "react-router-dom";
import {
  ApiIcon,
  DollarCircleIcon,
  GiftBoxIcon,
  HelpIcon,
  UserCircleIcon,
} from "../../assets/Svg/SvgImages";

const UserAdminList = () => {
  return (
    <>
      <div className="UserAdmin_List">
        <ul>
          {/* <li><Link to="/dashboard"><UserCircleIcon /> <span>Dashboard</span></Link></li> */}
          <li>
            <Link to="/dashboard/overview">
              <UserCircleIcon /> <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/landing-dashboard">
              <UserCircleIcon /> <span>Landing Dashboard</span>
            </Link>
          </li>
          {/* <li><Link to="/referral"><GiftBoxIcon /> <span>Referrals</span></Link></li> */}
          <li>
            <Link to="/api-management">
              <ApiIcon /> <span>API Management</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/dashboard/account-statement">
              <DollarCircleIcon /> <span>Account Statement</span>
            </Link>
          </li> */}
          <li>
            <Link to="/helpdesk">
              <HelpIcon /> <span>Helpdesk</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserAdminList;
