import React, { useState } from "react";
import coinIcon1 from "../../../../assets/images/exicon.svg";
import coinIcon2 from "../../../../assets/images/tether-usdt.svg";
import CommonButton from "../../../../common/CommonButton/CommonButton";
import CountDownTimer from "../ExpireOrder/ExpireOrder";
import { ApiServices } from "../../services/api.srvices";
import { toast } from "react-toastify";
import Web3 from "web3";
import { P2P_CONTRACT_ADDRESS, convert } from "../../../../constants/constants";
import P2PABI from "../../../../ABI/p2pTestnet/P2P.abi.json";
import Modal from "react-bootstrap/Modal";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { TOKEN_INFO } from "../../../../constants/wallet";

const OutBound = ({ userOrderList, tab, outboundSocketfunc }) => {
  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showmodal, sethowModal] = useState(false);
  const [isLoader, setIsloader] = useState(false);

  /************** CONFIRAM INBOUND MODAL ***************/
  const confirmInboundFun = async (e, outBound) => {
    e.preventDefault();
    try {
      // calling smart contract method approval
      ApproveTokenBuyer(outBound);
      return;
    } catch (error) {
      throw error;
    }
  };
  /*************************** END ********************************/

  /************** INBOUND APPROVE PROCESS THROUGH METAMASK ***************/
  const ApproveTokenBuyer = async (outBound) => {
    try {
      setIsloader(true);

      const { ethereum } = window;
      // const web3 = new Web3(ethereum);
      const fromAddress = await ethereum.request({
        method: "eth_requestAccounts",
      });

      let buyer = fromAddress[0];
      const instanceWeb3 = new Web3(ethereum);
      const contract =
        outBound.buy_token_name === "EHX"
          ? new instanceWeb3.eth.Contract(
            TOKEN_INFO[1].token_abi,
            TOKEN_INFO[1].token_address
          )
          : new instanceWeb3.eth.Contract(
            TOKEN_INFO[0].token_abi,
            TOKEN_INFO[0].token_address
          );

      const allowance = await contract.methods
        .allowance(buyer, P2P_CONTRACT_ADDRESS)
        .call({ from: buyer });

      // checking that only USDT allowance need to be set zero if its not.
      if (outBound.buy_token_name === "USDT") {
        if (Number(allowance) > 0) {
          const instanceWeb3 = new Web3(ethereum);
          const contract = new instanceWeb3.eth.Contract(
            TOKEN_INFO[0].token_abi,
            TOKEN_INFO[0].token_address
          );

          await contract.methods
            .approve(P2P_CONTRACT_ADDRESS, 0)
            .send({ from: buyer });
        }
      }

      let newAmountApproved =
        outBound.buy_token_name === "EHX"
          ? convert(outBound.buy_amount * 10 ** 18).toString()
          : convert(outBound.buy_amount * 10 ** 6).toString();

      const estimatedGas1 = await contract.methods
        .approve(P2P_CONTRACT_ADDRESS, newAmountApproved)
        .estimateGas({ from: buyer });

      const res = await contract.methods
        .approve(P2P_CONTRACT_ADDRESS, newAmountApproved)
        .send({ from: buyer, gas: estimatedGas1 });
      // after success of approve then we are calling lock method from smart contract to lock the balance

      LockTheBalance(outBound);
      return res;
    } catch (error) {
      toast.error(error.message);
      setIsloader(false);
      throw error;
    }
  };
  /*************************** END ********************************/

  /************** INBOUND LOCKING THE AMOUNT THROUGH METAMASK ***************/
  const LockTheBalance = async (outBound) => {
    try {
      const { ethereum } = window;
      const fromAddress = await ethereum.request({
        method: "eth_requestAccounts",
      });
      let buyer = fromAddress[0];
      const instanceWeb3 = new Web3(window.ethereum);
      const contract = new instanceWeb3.eth.Contract(
        P2PABI,
        P2P_CONTRACT_ADDRESS
      );

      const estimatedGas1 = await contract.methods
        .lock([
          outBound.seller_address,
          outBound.sell_token_name === "EHX"
            ? TOKEN_INFO[1].token_address
            : TOKEN_INFO[0].token_address,
          outBound.buy_token_name === "USDT"
            ? TOKEN_INFO[0].token_address
            : TOKEN_INFO[1].token_address,
          outBound.sell_token_name === "EHX"
            ? convert(outBound.sell_amount * 10 ** 18).toString()
            : convert(outBound.sell_amount * 10 ** 6).toString(),
          outBound.buy_token_name === "USDT"
            ? convert(outBound.buy_amount * 10 ** 6).toString()
            : convert(outBound.buy_amount * 10 ** 18).toString(),
          outBound.expire_utc_time,
          outBound.salt_value,
          outBound.signature,
        ])
        .estimateGas({ from: buyer });

      const res = await contract.methods
        .lock([
          outBound.seller_address,
          outBound.sell_token_name === "EHX"
            ? TOKEN_INFO[1].token_address
            : TOKEN_INFO[0].token_address,
          outBound.buy_token_name === "USDT"
            ? TOKEN_INFO[0].token_address
            : TOKEN_INFO[1].token_address,
          outBound.sell_token_name === "EHX"
            ? convert(outBound.sell_amount * 10 ** 18).toString()
            : convert(outBound.sell_amount * 10 ** 6).toString(),
          outBound.buy_token_name === "USDT"
            ? convert(outBound.buy_amount * 10 ** 6).toString()
            : convert(outBound.buy_amount * 10 ** 18).toString(),
          outBound.expire_utc_time,
          outBound.salt_value,
          outBound.signature,
        ])
        .send({ from: buyer, gas: estimatedGas1 });
      // after success of lock method then we are calling api for saving data in api
      let data = {
        id: outBound.id,
        address: outBound.buyer_address,
      };
      inboundConfirmOrder(data);
      return res;
    } catch (error) {
      toast.error(error.message);
      setIsloader(false);
      throw error;
    }
  };
  /*************************** END ********************************/

  /************** INBOUND CONFIRM ORDER API ***************/
  const inboundConfirmOrder = async (data) => {
    try {
      const response = await ApiServices.InboundOrderConfirm(data);
      if (response && response.status === 200) {
        handleShowmodal();
        toast.success(response?.message);
        // outboundSocketfunc();
        setIsloader(false);
      }
    } catch (error) {
      throw error;
    }
  };
  /*************************** END ********************************/

  /************** INBOUND CANCEL ORDER API ***************/
  const handleInboundCancel = (e, outBound) => {
    e.preventDefault();
    try {
      setIsloader(true);

      CancelTokenInbound(outBound);
    } catch (error) {
      setIsloader(false);

      throw error;
    }
  };
  /************** INBOUND CANCEL METHODE SMART CONTRACT ***************/
  const CancelTokenInbound = async (outBound) => {
    try {
      const { ethereum } = window;

      const fromAddress = await ethereum.request({
        method: "eth_requestAccounts",
      });
      let buyer = fromAddress[0];
      const instanceWeb3 = new Web3(window.ethereum);
      const contract = new instanceWeb3.eth.Contract(
        P2PABI,
        P2P_CONTRACT_ADDRESS
      );

      const estimatedGas1 = await contract.methods
        .cancel([
          outBound.seller_address,
          outBound.sell_token_name === "EHX"
            ? TOKEN_INFO[1].token_address
            : TOKEN_INFO[0].token_address,
          outBound.buy_token_name === "USDT"
            ? TOKEN_INFO[0].token_address
            : TOKEN_INFO[1].token_address,
          outBound.sell_token_name === "EHX"
            ? convert(outBound.sell_amount * 10 ** 18).toString()
            : convert(outBound.sell_amount * 10 ** 6).toString(),
          outBound.buy_token_name === "USDT"
            ? convert(outBound.buy_amount * 10 ** 6).toString()
            : convert(outBound.buy_amount * 10 ** 18).toString(),
          outBound.expire_utc_time,
          outBound.salt_value,
          outBound.signature,
        ])
        .estimateGas({ from: buyer });

      const res = await contract.methods
        .cancel([
          outBound.seller_address,
          outBound.sell_token_name === "EHX"
            ? TOKEN_INFO[1].token_address
            : TOKEN_INFO[0].token_address,
          outBound.buy_token_name === "USDT"
            ? TOKEN_INFO[0].token_address
            : TOKEN_INFO[1].token_address,
          outBound.sell_token_name === "EHX"
            ? convert(outBound.sell_amount * 10 ** 18).toString()
            : convert(outBound.sell_amount * 10 ** 6).toString(),
          outBound.buy_token_name === "USDT"
            ? convert(outBound.buy_amount * 10 ** 6).toString()
            : convert(outBound.buy_amount * 10 ** 18).toString(),
          outBound.expire_utc_time,
          outBound.salt_value,
          outBound.signature,
        ])
        .send({ from: buyer, gas: estimatedGas1 });

      // after success of cancel from contract then we are calling api to update the data
      let data = {
        id: outBound.id,
        address: outBound.buyer_address,
      };
      SingleCancelOrderInbound(data);
      return res;
    } catch (error) {
      toast.error(error.message);
      setIsloader(false);
      throw error;
    }
  };
  /************** INBOUND CANCEL API CALLING ***************/
  const SingleCancelOrderInbound = async (data) => {
    const response = await ApiServices.SingleCancelOrderInbound(data);
    if (response && response.status === 200) {
      toast.success(response?.message);
      // outboundSocketfunc();
      setIsloader(false);
    } else {
      // setIsloader(true);
      toast.error(response?.message);
    }
  };
  /*************************** END ********************************/

  /************** OUTBOUND CONFIRM ORDER METAMASK ***************/
  const OutboundConfirmOrder = async (e, outbound) => {
    e.preventDefault();

    try {
      // after clicking confirm we are calling trad method from contract to place the order and transform the money between the users
      setIsloader(true);
      trade(outbound);
    } catch (error) {
      toast.error(error.message);
      setIsloader(false);
      throw error;
    }
  };

  /************** SMARTCONTRACT METHOD FOR OUTBOUND CONFIRMATION ***************/
  const trade = async (outbound) => {
    let estimatedGas1;
    try {
      const { ethereum } = window;
      const web3 = new Web3(ethereum);
      const fromAddress = await ethereum.request({
        method: "eth_requestAccounts",
      });
      let seller = fromAddress[0];
      const instanceWeb3 = new Web3(window.ethereum);

      // const tokenContract = new instanceWeb3

      // p2p contract instance
      const contract = new instanceWeb3.eth.Contract(
        P2PABI,
        P2P_CONTRACT_ADDRESS
      );
      estimatedGas1 = await contract.methods
        .trade([
          outbound.seller_address,
          outbound.sell_token_name === "EHX"
            ? TOKEN_INFO[1].token_address
            : TOKEN_INFO[0].token_address,
          outbound.buy_token_name === "USDT"
            ? TOKEN_INFO[0].token_address
            : TOKEN_INFO[1].token_address,
          outbound.sell_token_name === "EHX"
            ? convert(outbound.sell_amount * 10 ** 18).toString()
            : convert(outbound.sell_amount * 10 ** 6).toString(),
          outbound.buy_token_name === "USDT"
            ? convert(outbound.buy_amount * 10 ** 6).toString()
            : convert(outbound.buy_amount * 10 ** 18).toString(),
          outbound.expire_utc_time,
          outbound.salt_value,
          outbound.signature,
        ])
        .estimateGas({ from: seller });

      const res = await contract.methods
        .trade([
          outbound.seller_address,
          outbound.sell_token_name === "EHX"
            ? TOKEN_INFO[1].token_address
            : TOKEN_INFO[0].token_address,
          outbound.buy_token_name === "USDT"
            ? TOKEN_INFO[0].token_address
            : TOKEN_INFO[1].token_address,
          outbound.sell_token_name === "EHX"
            ? convert(outbound.sell_amount * 10 ** 18).toString()
            : convert(outbound.sell_amount * 10 ** 6).toString(),
          outbound.buy_token_name === "USDT"
            ? convert(outbound.buy_amount * 10 ** 6).toString()
            : convert(outbound.buy_amount * 10 ** 18).toString(),
          outbound.expire_utc_time,
          outbound.salt_value,
          outbound.signature,
        ])
        .send({ from: seller, gas: estimatedGas1 });

      const data = {
        id: outbound.id,
        address: outbound.seller_address,
      };
      setIsloader(true);
      confirmTransaction(data);
      return res;
    } catch (error) {
      toast.error(error.message);
      setIsloader(false);

      throw error;
    }
  };
  /************** OUTBOUND CONFIRM ORDER API CALL ***************/
  const confirmTransaction = async (data) => {
    const response = await ApiServices.outboundConfirmOrder(data);
    if (response && response.status === 200) {
      // outboundSocketfunc();
      setIsloader(false);
      toast.success(response?.message);
    } else {
      setIsloader(false);
      toast.error(response?.message);
    }
  };
  /*************************** END ********************************/

  /************** OUTBOUND CANCEL METHOD ***************/
  const handleCancelFunction = (e, outBound) => {
    e.preventDefault();
    try {
      setIsloader(true);
      // calling cancel function after clicking on cancel
      CancelToken(outBound);
    } catch (error) {
      setIsloader(false);

      throw error;
    }
  };

  /************** OUTBOUND CANCEL METHODE SMART CONTRACT ***************/
  const CancelToken = async (outBound) => {
    try {
      const { ethereum } = window;

      const fromAddress = await ethereum.request({
        method: "eth_requestAccounts",
      });
      let buyer = fromAddress[0];
      const instanceWeb3 = new Web3(window.ethereum);
      const contract = new instanceWeb3.eth.Contract(
        P2PABI,
        P2P_CONTRACT_ADDRESS
      );

      const estimatedGas1 = await contract.methods
        .cancel([
          outBound.seller_address,
          outBound.sell_token_name === "EHX"
            ? TOKEN_INFO[1].token_address
            : TOKEN_INFO[0].token_address,
          outBound.buy_token_name === "USDT"
            ? TOKEN_INFO[0].token_address
            : TOKEN_INFO[1].token_address,
          outBound.sell_token_name === "EHX"
            ? convert(outBound.sell_amount * 10 ** 18).toString()
            : convert(outBound.sell_amount * 10 ** 6).toString(),
          outBound.buy_token_name === "USDT"
            ? convert(outBound.buy_amount * 10 ** 6).toString()
            : convert(outBound.buy_amount * 10 ** 18).toString(),
          outBound.expire_utc_time,
          outBound.salt_value,
          outBound.signature,
        ])
        .estimateGas({ from: buyer });
      const res = await contract.methods
        .cancel([
          outBound.seller_address,
          outBound.sell_token_name === "EHX"
            ? TOKEN_INFO[1].token_address
            : TOKEN_INFO[0].token_address,
          outBound.buy_token_name === "USDT"
            ? TOKEN_INFO[0].token_address
            : TOKEN_INFO[1].token_address,
          outBound.sell_token_name === "EHX"
            ? convert(outBound.sell_amount * 10 ** 18).toString()
            : convert(outBound.sell_amount * 10 ** 6).toString(),
          outBound.buy_token_name === "USDT"
            ? convert(outBound.buy_amount * 10 ** 6).toString()
            : convert(outBound.buy_amount * 10 ** 18).toString(),
          outBound.expire_utc_time,
          outBound.salt_value,
          outBound.signature,
        ])
        .send({ from: buyer, gas: estimatedGas1 });
      // after success of cancel from contract then we are calling api to update the data
      let data = {
        id: outBound.id,
        address: outBound.seller_address,
      };
      cancelFunction(data);
      return res;
    } catch (error) {
      toast.error(error.message);
      setIsloader(false);
      throw error;
    }
  };

  /************** OUTBOUND CANCEL API CALLING ***************/
  const cancelFunction = async (data) => {
    const response = await ApiServices.SingleCancelOrder(data);
    if (response && response.status === 200) {
      toast.success(response?.message);
      // outboundSocketfunc();
      setIsloader(false);
    } else {
      toast.error(response?.message);
    }
  };
  /*************************** END ********************************/

  /************** POPUP ON CONFIRMATION ORDER ***************/
  const handleClosemodal = () => sethowModal(false);
  const handleShowmodal = () => {
    sethowModal(true);
    setTimeout(() => {
      sethowModal(false);
    }, 5000);
  };
  /*************************** END ********************************/

  const handleClick = () => {
    setIsDisabled(true);
  };

  return (
    <>
      {isLoader === true && <Loader className="inner" />}
      <div className="Out_Bound">
        <div className="Out_Bound_Received">
          <div className="d-flex justify-content-between align-items-center">
            <div className="Out_Bound_Exh d-flex flex-column w-100">
              {userOrderList && userOrderList.length ? (
                userOrderList.length &&
                userOrderList.map((outBound, index) => {
                  // {
                  //   console.log("sdfasdfasdfasdfasdf", outBound.id);
                  // }
                  return (
                    <div className="Out_Bound_Box" index={index}>
                      <span className="Two_Icon">
                        <img src={coinIcon1} alt="icon" />
                        <img src={coinIcon2} alt="icon" />
                      </span>
                      <h5>EHX-USDT</h5>
                      <ul className="Out_Bound_List">
                        <li>
                          {/* <p>{outBound.id}</p> */}
                          <p>You sell</p>{" "}
                          {outBound.seller_address == walletAddress ? (
                            <h5>
                              <span>{outBound.amountInUsdtEHX} $</span>{" "}
                              {outBound.sell_amount} {outBound.sell_token_name}
                            </h5>
                          ) : (
                            <h5>
                              <span>{outBound.amountInUsdtUsd} $</span>
                              {outBound.buy_amount} {outBound.buy_token_name}{" "}
                            </h5>
                          )}
                        </li>
                        <li>
                          <p>You buy</p>{" "}
                          {outBound.seller_address == walletAddress ? (
                            <h5>
                              <span>{outBound.amountInUsdtUsd} $</span>{" "}
                              {outBound.buy_amount} {outBound.buy_token_name}
                            </h5>
                          ) : (
                            <h5>
                              <span>{outBound.amountInUsdtEHX} $</span>
                              {outBound.sell_amount} {outBound.sell_token_name}{" "}
                            </h5>
                          )}
                        </li>
                        <li>
                          <p>EHX per USDT</p> <h5>{outBound.ehx_per_usdt}</h5>
                        </li>
                        <li>
                          <p>Taker address</p>{" "}
                          {outBound.seller_address.toLowerCase == walletAddress.toLowerCase ? (
                            <h5
                              title={outBound.buyer_address}
                              className="text_blue"
                            >
                              {" "}
                              {outBound.buyer_address}
                            </h5>
                          ) : outBound.buyer_address.toLowerCase() ==
                            walletAddress ? (
                            <h5
                              title={outBound.seller_address}
                              className="text_blue"
                            >
                              {" "}
                              {outBound.seller_address}
                            </h5>
                          ) : (
                            "Invalide wallet address"
                          )}
                        </li>
                        {tab === "outbound" ? (
                          outBound.is_expire == 0 &&
                            outBound.seller_cancelled === 0 &&
                            outBound.buyer_confirmed == 0 ? (
                            <li>
                              <p>Expiration</p>
                              {outBound?.expire_utc_time && (
                                <CountDownTimer
                                  expiresTime={outBound?.expire_utc_time}
                                  index={index}
                                />
                              )}
                            </li>
                          ) : (
                            ""
                          )
                        ) : tab === "inbound" ? (
                          outBound.is_expire == 0 &&
                            outBound.buyer_cancelled === 0 &&
                            outBound.buyer_confirmed == 0 ? (
                            <li>
                              <p>Expiration</p>
                              {/* {console.log('expiretime',outBound)} */}
                              {outBound?.expire_utc_time && (
                                <CountDownTimer
                                  expiresTime={outBound?.expire_utc_time}
                                  orderId={outBound?.id}
                                  index={index}
                                />
                              )}
                            </li>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {tab === "outbound" ? (
                          outBound.is_expire !== 1 ? (
                            outBound.buyer_confirmed === 1 ||
                              outBound.buyer_cancelled === 1 ? (
                              <li>
                                <p>Buyer status</p>
                                <h5>
                                  {outBound.buyer_cancelled === 1
                                    ? "Cancelled"
                                    : outBound.buyer_confirmed === 1
                                      ? "Confirmed"
                                      : outBound.is_expire === 1
                                        ? "Expired"
                                        : ""}
                                </h5>
                              </li>
                            ) : (
                              ""
                            )
                          ) : (
                            outBound.is_expire === 1 && (
                              <li>
                                <p>status</p>
                                <h5>
                                  {outBound.is_expire === 1 ? "Expired" : ""}
                                </h5>
                              </li>
                            )
                          )
                        ) : (
                          ""
                        )}
                        {tab === "outbound" ? (
                          outBound.seller_cancelled === 1 ||
                            outBound.seller_confirmed === 1 ? (
                            <li>
                              <p>Status</p>{" "}
                              <h5>
                                {outBound.is_expire === 1
                                  ? "Expired"
                                  : outBound.seller_cancelled === 1
                                    ? "Cancelled"
                                    : "Confirmed"}
                              </h5>
                            </li>
                          ) : outBound.is_expire !== 1 &&
                            outBound.buyer_confirmed === 1 &&
                            outBound.seller_cancelled !== 1 &&
                            outBound.buyer_cancelled !== 1 ? (
                            <>
                              <li>
                                <div className="mt-3 d-flex justify-content-end">
                                  <CommonButton
                                    title={"Cancel"}
                                    // disabled={isDisabled}
                                    // style={isDisabled ? styles.buttonDisabled : styles.button}
                                    onClick={(e) => {
                                      if (tab === "outbound") {
                                        handleCancelFunction(e, outBound);
                                      }
                                    }}
                                  />
                                </div>

                                <div className="mt-3 d-flex justify-content-end">
                                  <CommonButton
                                    title={"Confirm"}
                                    disabled={isDisabled}
                                    // style={{isDisabled ? styles.buttonDisabled : styles.button}}
                                    onClick={(e) => {
                                      OutboundConfirmOrder(e, outBound);
                                      handleClick();
                                    }}
                                  />
                                </div>
                              </li>
                            </>
                          ) : outBound.is_expire === 1 &&
                            outBound.seller_cancelled !== 1 ? (
                            <>
                              <li>
                                <div className="mt-3 d-flex justify-content-end">
                                  <CommonButton
                                    title={"Cancel"}
                                    onClick={(e) => {
                                      if (tab === "outbound") {
                                        handleCancelFunction(e, outBound);
                                      }
                                    }}
                                  />
                                </div>
                              </li>
                            </>
                          ) : (
                            <li>
                              <div className="mt-3 d-flex justify-content-end">
                                <CommonButton
                                  title={"Cancel"}
                                  onClick={(e) => {
                                    if (tab === "outbound") {
                                      handleCancelFunction(e, outBound);
                                    }
                                  }}
                                />
                              </div>
                            </li>
                          )
                        ) : tab === "inbound" ? (
                          outBound.is_expire !== 1 ? (
                            outBound.buyer_cancelled === 1 ||
                              outBound.buyer_confirmed === 1 ? (
                              <>
                                <li>
                                  <p>Status</p>{" "}
                                  <h5>
                                    {outBound.seller_cancelled === 1
                                      ? "Cancelled"
                                      : "Confirmed"}
                                  </h5>
                                </li>
                                <li>
                                  {outBound.buyer_confirmed === 1 &&
                                    outBound.buyer_cancelled !== 1 ? (
                                    <li>
                                      <div className="mt-3 d-flex justify-content-end">
                                        <CommonButton
                                          title={"Cancel"}
                                          onClick={(e) => {
                                            if (tab === "inbound") {
                                              handleInboundCancel(e, outBound);
                                            }
                                          }}
                                        />
                                      </div>
                                    </li>
                                  ) : outBound.buyer_cancelled !== 1 ||
                                    outBound.buyer_confirmed !== 1 ? (
                                    <li>
                                      <div className="mt-3 d-flex justify-content-end">
                                        <CommonButton
                                          title={"Cancel"}
                                          onClick={(e) => {
                                            if (tab === "inbound") {
                                              handleInboundCancel(e, outBound);
                                            }
                                          }}
                                        />
                                      </div>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              </>
                            ) : (
                              outBound.buyer_confirmed !== 1 &&
                              outBound.buyer_cancelled !== 1 && (
                                <li>
                                  <div className="mt-3 d-flex justify-content-end">
                                    <CommonButton
                                      title={"Confirm"}
                                      onClick={(e) =>
                                        confirmInboundFun(e, outBound)
                                      }
                                    />
                                  </div>
                                </li>
                              )
                            )
                          ) : (
                            outBound.is_expire === 1 && (
                              <>
                                <li>
                                  <p>Status</p>{" "}
                                  <h5>
                                    {outBound.is_expire === 1 ? "Expired" : ""}
                                  </h5>
                                </li>
                              </>
                            )
                          )
                        ) : tab === "history" ? (
                          outBound.is_expire === 1 ||
                            (outBound.seller_cancelled === 1 &&
                              outBound.buyer_cancelled === 1) ||
                            (outBound.seller_confirmed === 1 &&
                              outBound.buyer_confirmed == 1) ? (
                            <li>
                              <p>Status</p>{" "}
                              <h5>
                                {outBound.is_expire === 1
                                  ? "Expired"
                                  : outBound.seller_cancelled === 1
                                    ? "Cancelled"
                                    : "Confirmed"}
                              </h5>
                            </li>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  );
                })
              ) : tab === "outbound" ? (
                <div className="Not_Orders ">
                  <h5>You haven’t created any order yet</h5>
                </div>
              ) : tab === "inbound" ? (
                <div className="Not_Orders ">
                  <h5>You haven’t received any order yet</h5>
                </div>
              ) : (
                tab === "history" && (
                  <div className="Not_Orders ">
                    <h5>No data found</h5>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="Order_modal"
        centered
        show={showmodal}
        onHide={handleClosemodal}
      >
        <Modal.Body className="text-center">
          <h6>
            Yor order confirmed, Please contact your counterpart to confirm the
            order.
          </h6>
        </Modal.Body>
      </Modal>
    </>
  );
};
const Loader = ({ className }) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center loader loader--${className}`}
    >
      <h2>Waiting confirmation...</h2>
    </div>
  );
};
export default OutBound;
