import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import TopbarHeading from '../../../common/TopbarHeading/TopbarHeading'
import Admin from '../../../Admin'
import Breadcrumbs from '../../../common/Breadcrumbs/Breadcrumbs'
import CommonButton from '../../../../../common/CommonButton/CommonButton'
import { SearchIcon } from '../../../../../assets/Svg/SvgImages'
import Select from "react-select";

const SupportTicketNew = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const options = [
    { value: "Deposit", label: "Deposit" },
    { value: "5555", label: "5555" },
    { value: "999", label: "999" },
  ];
  const timeoptions = [
    { value: "1 minute", label: "1 minute" },
    { value: "10 minute", label: "10 minute" },
    { value: "1 Hour", label: "1 Hour" },
    { value: "1 day", label: "1 day" },
    { value: "3 day", label: "3 day" },
  ];

  return (
    <Admin>
      <div className='Common_TopBar'>
        <Row className='align-items-center'>
          <Col xs={12} xl={4}>
            <TopbarHeading title="Support Tickets" />
          </Col>
          <Col xs={12} xl={8} className="d-md-flex justify-content-xl-end">
            <div className='Common_Header_Btns d-sm-flex align-items-center'>
              <CommonButton title="Create ticket" className="grey-btn2" />
              <CommonButton title="Delete all API’s" className="grey-btn2 ms-2 ms-md-3 me-sm-3" />
              <div className='Common_search'>
                <Form.Control className="Common_search_Input" placeholder="Search for a ticket" />
                <SearchIcon />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Breadcrumbs pagelink="/helpdesk" mainpage="Helpdesk" pagelinktwo="/support-ticket" pagename="New" className="d-block" pagenametwo="Support Tickets" />
      <div className='Common_admin_Contentarea Support_Tickets'>
        <div className='Support_Tickets_New'>
          <h3 className='text-center font24'>New support ticket</h3>
          <Form className='Support_Tickets_Form'>
            <Form.Group className="mb-4">
              <Form.Label>Subject</Form.Label>
              <Form.Control type="text" placeholder="Enter subject..." />
            </Form.Group>
            <Form.Group className="mb-4">
              <div className='common_select'>
                <label>Time</label>
                <Select
                  placeholder="1 minute"
                  defaultValue={timeoptions}
                  onChange={setSelectedOption1}
                  options={timeoptions}
                  classNamePrefix="react-select"
                  value={selectedOption1}
                // menuIsOpen={true}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-4">
              <div className='common_select'>
                <label>Type</label>
                <Select
                  placeholder="Deposit"
                  defaultValue={options}
                  onChange={setSelectedOption}
                  options={options}
                  classNamePrefix="react-select"
                  value={selectedOption}
                // menuIsOpen={true}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-4">
              <div className='common_select'>
                <label>Wallet</label>
                <Select
                  placeholder="Deposit"
                  defaultValue={options}
                  onChange={setSelectedOption2}
                  options={options}
                  classNamePrefix="react-select"
                  value={selectedOption2}
                // menuIsOpen={true}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Describe your problem</Form.Label>
              <Form.Control type="text" placeholder="What do you need help with?" />
            </Form.Group>

            <div className='d-flex justify-content-center mt-5'>
              <CommonButton title="Submit ticket" className="me-3" />
              <CommonButton title="Cancel" className="grey-btn" />
            </div>
          </Form>
        </div>
      </div >
    </Admin >
  )
}

export default SupportTicketNew