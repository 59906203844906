import React from "react";
import { Routes, Route } from "react-router-dom";
import AccountStatement from "../../pages/admin/pages/AccountStatement/AccountStatement";
import ApiManagement from "../../pages/admin/pages/ApiManagement/ApiManagement";
import Convert from "../../pages/admin/pages/Convert/Convert";
import CopyTrading from "../../pages/admin/pages/CopyTrading/CopyTrading";
import Dashboard from "../../pages/admin/pages/Dashboard/Dashboard";
import DepositCrypto from "../../pages/admin/pages/DepositCrypto/DepositCrypto";
import UnifiedTrading from "../../pages/admin/pages/UnifiedTrading/UnifiedTrading";
import Spot from "../../pages/admin/pages/Spot/Spot";
import HelpDesk from "../../pages/admin/pages/HelpDesk/HelpDesk";
import Overview from "../../pages/admin/pages/Overview/Overview";
import Referrals from "../../pages/admin/pages/Referrals/Referrals";
import Funding from "../../pages/admin/pages/Funding/Funding";
import TradingBots from "../../pages/admin/pages/TradingBots/TradingBots";
import TransactionHistory from "../../pages/admin/pages/TransactionHistory/TransactionHistory";

import Exchange from "../../pages/Exchange/components/Exchange";
import SpotExchange from "../../pages/SpotExchange/components/Exchange";
import P2p from "../../pages/P2p/components/P2p";
import Staking from "../../pages/Staking/Staking";
import Faq from "../../pages/admin/pages/HelpDesk/Faq/Faq";
import SupportTicket from "../../pages/admin/pages/HelpDesk/SupportTicket/SupportTicket";
import LiveSupport from "../../pages/admin/pages/HelpDesk/LiveSupport/LiveSupport";
import SupportTicketNew from "../../pages/admin/pages/HelpDesk/SupportTicket/SupportTicketNew";
import LandingDashboard from "../../pages/admin/pages/LandingDashboard/LandingDashboard";
import Orders from "../../pages/admin/pages/Orders/Orders";
import CoinDetails from "../../pages/admin/pages/CoinDetails/CoinDetails";
const PublicRoutes = () => {
    return (
        <Routes>
            <Route index element={<Exchange />} />
            <Route path="/pair/:pair" element={<Exchange />} />
            <Route exact path="/spot" element={<SpotExchange />} />
            <Route exact path="/spot/pair/:pair" element={<SpotExchange />} />
            <Route path="/p2p" element={<P2p />} />
            <Route exact path="/staking" element={<Staking />} />
            <Route path="/landing-dashboard" element={<LandingDashboard />} />
            <Route path="/convert" element={<Convert />} />
            <Route path="/copy-trading" element={<CopyTrading />} />
            <Route path="/referral" element={<Referrals />} />
            <Route path="/api-management" element={<ApiManagement />} />
            <Route path="/trading-bots" element={<TradingBots />} />
            <Route path="/deposit-crypto" element={<DepositCrypto />} />
            <Route path="/helpdesk" element={<HelpDesk />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/support-ticket" element={<SupportTicket />} />
            <Route path="/support-ticket-new" element={<SupportTicketNew />} />
            <Route path="/live-support" element={<LiveSupport />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/overview" element={<Overview />} />
            <Route path="/dashboard/coin-details" element={<CoinDetails />} />
            <Route path="/dashboard/spot-account" element={<Spot />} />
            <Route path="/dashboard/orders" element={<Orders />} />
            <Route path="/dashboard/funding" element={<Funding />} />
            <Route
                path="/dashboard/account-statement"
                element={<AccountStatement />}
            />
            <Route
                path="/dashboard/unifiedtrading"
                element={<UnifiedTrading />}
            />
            <Route
                path="/dashboard/transaction-history"
                element={<TransactionHistory />}
            />
        </Routes>
    );
};

export default PublicRoutes;
