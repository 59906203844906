import React from 'react'
import Modal from 'react-bootstrap/Modal';
import ConvertingIcon from "../../../../assets/images/admin/convt-success.svg"

const ConversionProcessingModal = ({ show, handleClose }) => {
    return (
        <>
            <Modal className='Admin_Modal Conversion_successful_modal' centered show={show} onHide={handleClose}>
                <Modal.Header className='text-center justify-content-center' closeButton>
                    <Modal.Title className='font28'>Converting now</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='Conversion_successful mb-0 text-center'>
                        <img className='my-5' src={ConvertingIcon} alt='icon' />
                        <h3 className='mb-0 font18'>Please wait a moment...</h3>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConversionProcessingModal
