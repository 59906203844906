import Web3 from "web3";
import ContractABI from "../ABI/contract.abi.json";
import TokenABI from "../ABI/token.abi.json";
import store from "../redux/_store/configureStore";
import { callWeb3 } from "./walletServices";
import { NETWORK_INFO } from "../constants/wallet";
import { commonAbiInstances } from "./GlobalCall";

import EHX_ABI_TEST from "../ABI/p2pTestnet/EHX_ABI.abi.json";
import USDT_ABI_TEST from "../ABI/p2pTestnet/USDT_ABI.abi.json";
import EHX_ABI_MAIN from "../ABI/p2pMainnet/EHX_ABI.abi.json";
import USDT_ABI_MAIN from "../ABI/p2pMainnet/USDT_ABI.abi.json";
import { P2P_NETWORK } from "../constants/constants";

export const EHX_ABI_BASEDON_NETWORK =
  P2P_NETWORK === "testnet" ? EHX_ABI_TEST : EHX_ABI_MAIN;
export const USDT_ABI_BASEDON_NETWORK =
  P2P_NETWORK === "testnet" ? USDT_ABI_TEST : USDT_ABI_MAIN;

/**
 * Sign Application with connected wallet account
 * @param {*} currentProvider
 * @param {*} walletAddress
 * @returns
 */
const signAppWithWallet = async (currentProvider, walletAddress) => {
  try {
    const web3Instance = new Web3(currentProvider);
    const sign = await web3Instance.eth.personal.sign(
      "You are signing into Eterna ",
      walletAddress
    );
    return sign;
  } catch (error) {
    throw error;
  }
};

const deposit = async (amount, feeValue, userAddress) => {
  try {
    const chainId = store.getState()?.user?.chainId;
    const walletAddress = store.getState()?.user?.walletAddress;
    const networkInfo = NETWORK_INFO.find(
      (doc) => doc.chain_id === Number(chainId)
    );
    const tokenAddress = networkInfo?.tokenAddress;
    const contractAddress = networkInfo?.contract_address;

    const contractInstance = await commonAbiInstances(
      contractAddress,
      ContractABI
    );
    const USDTInstance = await commonAbiInstances(tokenAddress, TokenABI);

    const decimals = await USDTInstance.methods.decimals().call();

    // console.log(">>>>>>>>>>>>>>>gaslimit", amount, Number(decimals), feeValue);
    const gasLimit = await contractInstance.methods
      .deposit((amount * 10 ** Number(decimals))?.toString(), tokenAddress)
      .estimateGas({ from: walletAddress, value: feeValue })
      .toString()
      .replace("n", "");
    console.log(">>>>>>>>>>>>>>>gaslimit", gasLimit);
    const response = await contractInstance.methods
      .deposit((amount * 10 ** Number(decimals)).toString(), tokenAddress)
      .send({
        from: walletAddress,
        value: feeValue,
        gas: Math.round(Number(gasLimit) * 1.5),
      });

    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * User Deposit amount to contract
 * @param {*} currentProvider
 * @param {String} fromAddress
 * @param {String} contrctaAddress
 * @param {Number} amount
 * @returns
 */
const depositAmountToContract = async (
  currentProvider,
  fromAddress,
  contrctaAddress,
  amount
) => {
  try {
    const web3Object = new Web3(currentProvider);
    const response = await web3Object.eth.sendTransaction({
      from: fromAddress,
      to: contrctaAddress,
      value: Web3.utils.toWei(amount, "ether"),
    });
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Deposit Assets to contract
 * @param {*} currentProvider
 * @param {*} assetAddress
 * @param {*} userAddress
 * @param {*} contrctaAddress
 * @param {*} amount
 * @returns
 */
const depositAssetAmountToContract = async (
  currentProvider,
  assetAddress,
  userAddress,
  contrctaAddress,
  amount
) => {
  try {
    const web3Instance = new Web3(currentProvider);
    const contractInstance = new web3Instance.eth.Contract(
      ContractABI,
      assetAddress
    );

    const gasPrice = await web3Instance.eth.getGasPrice();
    const gas = await contractInstance.methods
      .transfer(contrctaAddress, Web3.utils.toWei(amount, "ether"))
      .estimateGas({ from: userAddress, value: 0 });

    const response = await contractInstance.methods
      .transfer(contrctaAddress, Web3.utils.toWei(amount, "ether"))
      .send({ from: userAddress, gas, gasPrice, value: 0 });

    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * User Withdral from the Contract address using signature and message has bytes
 * @param {*} currentProvider
 * @param {String} contrctaAddress
 * @param {String} userAddress
 * @param {String} messageHasBytes
 * @param {String} signature
 * @returns
 */
const withdrawalFromContract = async (
  currentProvider,
  contrctaAddress,
  userAddress,
  messageHasBytes,
  signature
) => {
  try {
    const web3Instance = new Web3(currentProvider);
    const contractInstance = new web3Instance.eth.Contract(
      ContractABI,
      contrctaAddress
    );

    const gasPrice = await web3Instance.eth.getGasPrice();
    const gas = await contractInstance.methods
      .claim(messageHasBytes, signature)
      .estimateGas({ from: userAddress, value: 0 });
    return await contractInstance.methods
      .claim(messageHasBytes, signature)
      .send({ from: userAddress, gas, gasPrice, value: 0 });
  } catch (error) {
    throw error;
  }
};

/**
 * Get User Asset balance
 * @param {*} currentProvider
 * @param {String} assetAddress
 * @param {String} userAddress
 * @returns
 */
const getAssetBalance = (currentProvider, assetAddress, userAddress) => {
  try {
    const web3Instance = new Web3(currentProvider);
    const contractInstance = new web3Instance.eth.Contract(
      ContractABI,
      assetAddress
    );

    return contractInstance.methods.balanceOf(userAddress).call();
  } catch (error) {
    throw error;
  }
};

const getGasPrice = async () => {
  const wallettype = store.getState()?.user?.wallet;
  const web3Instance = await callWeb3(wallettype);
  const gasPrice = (await web3Instance.eth.getGasPrice())
    .toString()
    .replace("n", "");
  console.log("gasprice", gasPrice);
  return Number(gasPrice);
};

const getUSDTBalance = async (walletAddress, currentProvider, tokenAddress) => {
  try {
    // console.log("get usdt balance", tokenAddress, walletAddress);
    const provider = store.getState()?.user?.provider;
    const web3Instance = new Web3(provider);
    const contractInstance = new web3Instance.eth.Contract(
      TokenABI,
      String(tokenAddress)
    );
    const decimals = await contractInstance.methods.decimals().call();
    console.log("decimal", decimals);

    const response = await contractInstance.methods
      .balanceOf(walletAddress)
      .call();
    console.log("response,", response);
    let balance;
    if (Number(response) > 0) {
      balance = Number(response) / 10 ** decimals;
    } else {
      balance = 0;
    }
    console.log("balance>", balance);
    return balance;
  } catch (error) {
    throw error;
  }
};

const getUSDTDecimals = async (currentProvider, tokenAddress) => {
  // console.log("get usdt decimal", tokenAddress,);
  try {
    const web3Instance = new Web3(currentProvider);
    const contractInstance = new web3Instance.eth.Contract(
      TokenABI,
      tokenAddress
    );
    const decimals = await contractInstance.methods.decimals().call();
    return decimals;
  } catch (error) {
    throw error;
  }
};

const checkAllowance = async () => {
  try {
    const chainId = store.getState()?.user?.chainId;
    const walletAddress = store.getState()?.user?.walletAddress;
    const networkInfo = NETWORK_INFO.find(
      (doc) => doc.chain_id === Number(chainId)
    );
    const tokenAddress = networkInfo?.tokenAddress;
    const contractAddress = networkInfo?.contract_address;

    const contract = await commonAbiInstances(tokenAddress, TokenABI);
    const allowance = await contract.methods
      .allowance(walletAddress, contractAddress)
      .call();
    return Number(allowance);
  } catch (error) {
    throw error;
  }
};

const sendAllowance = async (depositAmount) => {
  try {
    const chainId = store.getState()?.user?.chainId;
    const walletAddress = store.getState()?.user?.walletAddress;
    const networkInfo = NETWORK_INFO.find(
      (doc) => doc.chain_id === Number(chainId)
    );
    const tokenAddress = networkInfo?.tokenAddress;
    const contractAddress = networkInfo?.contract_address;

    const contract = await commonAbiInstances(tokenAddress, TokenABI);
    const decimals = await contract.methods.decimals().call();
    // .toString()
    // .replace("n", "");
    console.log(">>>>>>>>>>>>approving", depositAmount, Number(decimals));
    const allowance = await contract.methods
      .approve(
        contractAddress,
        (Number(depositAmount) * 10 ** Number(decimals)).toLocaleString(
          "fullwide",
          {
            useGrouping: !1,
          }
        )
      )
      .send({ from: walletAddress });
    return true;
  } catch (error) {
    throw error;
  }
};

const getUserUSDTBalance = async () => {
  try {
    const chainId = store.getState()?.user?.chainId;
    const walletAddress = store.getState()?.user?.walletAddress;

    const tokenAddress = NETWORK_INFO.find(
      (doc) => doc.chain_id === Number(chainId)
    )?.tokenAddress;
    console.log("this is tokenaddress", tokenAddress, chainId);
    const contract = await commonAbiInstances(tokenAddress, TokenABI);
    const decimals = await contract.methods.decimals().call();
    const balance = await contract.methods.balanceOf(walletAddress).call();
    let balanceFinal = balance?.toString().replace("n", "");
    let decimalFinal = decimals?.toString().replace("n", "");

    return Number(balanceFinal) / 10 ** Number(decimalFinal);
  } catch (error) {
    console.log("balance usdt error", error);
  }
};

/************** GETTING FIRST SELECTED COIN BALANCE FROM CONTRACT ***************/
const getp2pFristTokenBalances = async (
  walletAddress,
  currentProvider,
  tokenAddress,
  tokenName
) => {
  // MAKING CONDITION TO CHECK WHICH TOKEN NEED TO CALL THE BALANCE
  let Conditional_ABI =
    tokenName && tokenName == "EHX"
      ? EHX_ABI_BASEDON_NETWORK
      : USDT_ABI_BASEDON_NETWORK;

  try {
    console.log("get usdt balance", tokenAddress, walletAddress, tokenName);
    const wallettype = store.getState()?.user?.wallet;
    const web3Instance = await callWeb3(wallettype);
    // const web3Instance = new Web3(currentProvider);
    const contractInstance = new web3Instance.eth.Contract(
      Conditional_ABI,
      String(tokenAddress)
    );
    const response = await contractInstance.methods
      .balanceOf(String(walletAddress))
      .call();

    // console.log("balance-==1============== response", response);
    return response;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

/************** GETTING SECOND SELECTED COIN BALANCE FROM CONTRACT ***************/
const getp2pSecondTokenBalances = async (
  walletAddress,
  currentProvider,
  tokenAddress,
  tokenName
) => {
  // MAKING CONDITION TO CHECK WHICH TOKEN NEED TO CALL THE BALANCE
  let Conditional_ABI =
    tokenName && tokenName == "EHX"
      ? EHX_ABI_BASEDON_NETWORK
      : USDT_ABI_BASEDON_NETWORK;

  try {
    const web3Instance = new Web3(currentProvider);

    const contractInstance = new web3Instance.eth.Contract(
      Conditional_ABI,
      String(tokenAddress)
    );
    const response = await contractInstance.methods
      .balanceOf(String(walletAddress))
      .call();
    // console.log("balance-==2============== response", response);
    return response;
  } catch (error) {
    throw error;
  }
};
/*************************** END ********************************/

export const web3Services = {
  signAppWithWallet,
  depositAmountToContract,
  depositAssetAmountToContract,
  withdrawalFromContract,
  getAssetBalance,
  getGasPrice,
  deposit,
  getUSDTBalance,
  getUSDTDecimals,
  checkAllowance,
  sendAllowance,
  getUserUSDTBalance,
  getp2pSecondTokenBalances,
  getp2pFristTokenBalances,
};
