export const APIURL = {
  SUPPORTED_CHAINS: "/supportedChains", //for supported chains endpoints
  RECOMMENDED_TOKENS: "/recommendedTokens", //for recommended Tokens endpoints
  QUOTE: "/quote", // This API returns the best quotes
  ALLOWANCE: "/approval", //
  APPROVAL: "/approve",
  SWAP: "/swap", // for single chain or cross chain swap
  CROSS_CHAIN_STATUS: "/crossChainStatus", // for checking status of the status of a cross-chain swap
};

export const RPCURL = process.env.REACT_APP_RPC_URL;

export const RPCURLEXCHETH = process.env.REACT_APP_RPC_ETH_URL;
export const RPCURLEXCHBSC = process.env.REACT_APP_RPC_BSC_URL;

export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export const CHAIN_ID_BSC = process.env.REACT_APP_CHAIN_ID_BSC;

export const CHAIN_ID_CRONOS = 338;
export const CHAIN_ID_ETHEREUM = 1;
export const CHAIN_ID_BINANCE = 97;
export const MAX_AMOUNT = "0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF";
// export const TXN_HASH_URL = process.env.REACT_APP_TXN_HASH_URL;
// export const TXN_HASH_URL_BSC = process.env.REACT_APP_TXN_HASH_URL_BSC;
export const TXN_HASH_URL = "https://etherscan.io/tx/";
export const TXN_HASH_URL_BSC = "https://bscscan.com/tx/";
export const DOLLARPRICE = 0.0039;
export const SOLO_STAKE = process.env.REACT_APP_SOLO_STAKE;
export const LP_STAKE = process.env.REACT_APP_LP_STAKE;
export const REWARD_TOKEN = process.env.REACT_APP_REWARD_TOKEN;
export const WETH = process.env.REACT_APP_WETH;
export const LP_TOKEN = process.env.REACT_APP_LP_TOKEN;
// export const Dividend_Tracker = process.env.REACT_APP_Dividend_Tracker;
export const Dividend_Tracker = "0xDBb19cB5a5b60D4e57617b547bc090053E7Bdd3f";
//0x6B7cfB6c41a8E15144a1e7581954BF682F779f9e
export const Dividend_paying_token =
  process.env.REACT_APP_Dividend_paying_token;
export const USDC_TOKEN = process.env.REACT_APP_USDC_TOKEN;
export const PAIR = process.env.REACT_APP_PAIR;
export const SITE_URL = process.env.REACT_APP_SITE_URL;
// export const LINK_URL = "https://qa.ehx.exchange/swapbridge?";
export const LINK_URL = process.env.REACT_APP_LINK_URL;
export const walletConnectProjectId = "06cd73c01555f937a6963abee3431312";

// export const LINK_URL = "http://localhost:3000/swapbridge?";
export const spenderAddressBsc = "0x7d26f09d4e2d032efa0729fc31a4c2db8a2394b1";
export const spenderAddressEth = "0x4315f344a905dC21a08189A117eFd6E1fcA37D57";

export const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};

// 3. Create modal
export const metadata = {
  name: "Eterna Exchange",
  description: "Eterna Exchange",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};
export const projectId = "06cd73c01555f937a6963abee3431312";
