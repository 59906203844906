import React, { useState, useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CommonButton from "../../../../common/CommonButton/CommonButton";
import Admin from "../../Admin";
import TopbarHeading from "../../common/TopbarHeading/TopbarHeading";
import "./Funding.scss";
import CryptoAssetsTable from "./CryptoAssetsTable/CryptoAssetsTable";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { ApiServices } from "../../../Exchange/services/api.services";
import DepositModal from "../../../Exchange/components/BuySell/DepositModal/DepositModal";
import WithdrawModal from "../../../Exchange/components/BuySell/WithdrawModal/WithdrawModal";
import DashboardMoveFunds from "../../components/MoveFunds/DashboardMoveFunds";
import { useDispatch } from "react-redux";
import { saveUserFundingBalance } from "../../../../redux/_slices/user.slice";
import { saveUserCoinFilter } from "../../../../redux/_slices/user.slice";
import { saveUsdValue } from "../../../../redux/_slices/user.slice";
import Loader from "../../common/Loader/Loader";
import { updateLoader } from "../../../Exchange/redux/_slices/exchange.slice";

const Funding = () => {
    const [searchCoin, setSearchCoin] = useState("");
    const walletAddress = useAppSelector((state) => state.user.walletAddress);
    const hideBalance = useAppSelector((state) => state.user.hideBalance);
    const coinFilter = useAppSelector((state) => state.user.userCoinFilter);
    const loader = useAppSelector((state) => state.exchange.loader);
    const userFundingBalance = useAppSelector(
        (state) => state.user.userFundingBalance
    );
    const usdValue = useAppSelector((state) => state.user.usdValue);
    const [showDeposit, setShowDeposit] = useState(false);
    const [showWithdraw, setShowWithdraw] = useState(false);
    const [showMoveFunds, setShowMoveFunds] = useState(false);

    const dispatch = useDispatch();

    const getAllBalance = async () => {
        if (walletAddress) {
            let fundingBalance = await ApiServices.getFundingBalance({
                address: walletAddress,
                coin: searchCoin,
            });

            if (fundingBalance?.data) {
                dispatch(
                    saveUserFundingBalance({
                        totalEquity: fundingBalance?.data?.totalEquity,
                        availableBalance:
                            fundingBalance?.data?.totalWalletBalance,
                        coins: fundingBalance?.data?.coins,
                    })
                );
                dispatch(updateLoader(false));
            }
        } else {
            dispatch(
                saveUserFundingBalance({
                    totalEquity: 0,
                    availableBalance: 0,
                    coins: [],
                })
            );
        }
        try {
            let getUsdpPrice = await ApiServices.getUsdpPrice();
            if (getUsdpPrice) {
                dispatch(saveUsdValue(getUsdpPrice?.data?.usdtPrice));
            } else {
                dispatch(saveUsdValue(0));
            }
        } catch (error) {}
    };

    useEffect(() => {
        getAllBalance();
    }, [walletAddress, searchCoin]);

    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };
    const handleSearchCoin = (data) => {
        setSearchCoin(data);
    };
    return (
        <Admin>
            {loader && <Loader/>}
            <div className="Common_TopBar">
                <Row className="align-items-center Spot_Header">
                    <Col xs={12} xl={7}>
                        <TopbarHeading title="Funding Assets" />
                    </Col>
                    <Col
                        xs={12}
                        xl={5}
                        className="d-md-flex justify-content-xl-end mt-3 mt-xl-00"
                    >
                        <div className="Common_Header_Btns d-lg-flex align-items-center">
                            <CommonButton
                                onClick={() => setShowDeposit(true)}
                                title="Deposit"
                                className="grey-btn2"
                            />
                            <CommonButton
                                title="Withdraw"
                                className="grey-btn2"
                                onClick={() => setShowWithdraw(true)}
                            />
                            <CommonButton
                                title="Transfer"
                                className="grey-btn2"
                                onClick={() => setShowMoveFunds(true)}
                            />
                            <DepositModal
                                show={showDeposit}
                                handleClose={() => setShowDeposit(false)}
                            />
                            <WithdrawModal
                                show={showWithdraw}
                                handleClose={() => setShowWithdraw(false)}
                            />
                            <DashboardMoveFunds
                                show={showMoveFunds}
                                handleClose={() => setShowMoveFunds(false)}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="Common_admin_Contentarea">
                <div className="d-md-flex align-items-center width-2">
                    <div className="Spot_blnc_Card">
                        <div className="d-flex align-items-center">
                            <h5>Total Equity</h5>
                        </div>
                        <h3>
                            {hideBalance ? (
                                <>
                                    {"********"}
                                    <span> {"********"} </span>
                                </>
                            ) : (
                                <>
                                    {userFundingBalance?.totalEquity !==
                                    undefined
                                        ? roundToTwoDecimals(
                                              userFundingBalance?.totalEquity
                                          )
                                        : 0}{" "}
                                    {"USDT"}
                                    <span>
                                        ≈ $
                                        {roundToTwoDecimals(
                                            userFundingBalance?.totalEquity *
                                                usdValue
                                        )}
                                    </span>
                                </>
                            )}
                        </h3>
                    </div>
                    <div className="Spot_blnc_Card">
                        <div className="d-flex align-items-center">
                            <h5> Available Balance</h5>
                        </div>
                        <h3>
                            {hideBalance ? (
                                <>
                                    {"********"}
                                    <span> {"********"} </span>
                                </>
                            ) : (
                                <>
                                    {userFundingBalance?.availableBalance !==
                                    undefined
                                        ? roundToTwoDecimals(
                                              userFundingBalance?.availableBalance
                                          )
                                        : 0}{" "}
                                    {"USDT"}
                                    <span>
                                        ≈ $
                                        {roundToTwoDecimals(
                                            userFundingBalance?.availableBalance *
                                                usdValue
                                        )}
                                    </span>
                                </>
                            )}
                        </h3>
                    </div>
                </div>

                <CryptoAssetsTable
                    coins={userFundingBalance.coins}
                    search={handleSearchCoin}
                />
            </div>
        </Admin>
    );
};

export default Funding;
